import postValidation from './views/postValidation'
import Home from './views/home'
import ChangeVisit from './views/visits'
import BlankView from './views/blankView'
import TermAndCondition from './tango-react-base/components/termsAndCondition'
import CreateNewVisit from './views/createVisit'

const routes = [
  {
    path: '/login',
    component: BlankView,
  },
  {
    path: '/sign_up/:email',
    component: BlankView,
  },
  {
    path: '/sign_up',
    component: BlankView,
  },
  {
    path: '/forget_password/:email/token/:token',
    component: BlankView,
  },
  {
    path: '/forget_password/:email',
    component: BlankView,
  },
  {
    path: '/forget_password',
    component: BlankView,
  },
  {
    path: '/post/:id/calendar/:idVisit',
    component: ChangeVisit,
  },
  {
    path: '/postsValidation/:id',
    component: postValidation,
  },
  {
    path: '/terms-and-conditions',
    component: TermAndCondition,
  },
  {
    path: '/create-visit',
    component: CreateNewVisit,
  },
  {
    path: '',
    component: Home,
  },
]

export default routes
