import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { Field } from 'formik';
import intl from 'react-intl-universal';
import { useHistory, Link } from 'react-router-dom';

interface inputProps {
    'aria-label': string;
}

interface SwitchProps {
    inputProps: inputProps;
    options: Array<String>;
    name: string;
    value: string;
    label?: string;
    disabled?: boolean;
    classNameContainer?: string;
    position?: string;
    info?: string;
    infoDrop?: string;
    classNameLabel?: string;
    classNameInput?: string;
    classSpan?: string;
}

interface SwitchOptionProps {
    field: any;
    form: any;
    checked: boolean;
    setChecked: any;
}

const SwitchOption = ({
    field,
    form,
    inputProps,
    options,
    name,
    value,
    label,
    checked,
    setChecked,
    disabled,
    classNameContainer,
    classNameLabel = 'col-12 col-md-4 pl-0',
    classNameInput = 'col-md-8 col-12 p-sm-0',
    position = 'start',
    classSpan = 'col-12 switch-span align-self-center span-text',
    info,
    infoDrop,
}: SwitchOptionProps & SwitchProps) => {
    useEffect(() => {
        if (!value) {
            form.setFieldValue(name, options[options.length - 1]); //Negative option start.
        }
    }, []);

    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];

    return (
        <div className={`row switch-container d-flex align-items-start ${classNameContainer}`}>
            {label !== undefined && (
                <>
                    <div className={currentLocation === 'advanced-search' ? ' ' : classNameLabel}>
                        <span className={`${classSpan}`}>{intl.getHTML(`${label}`)}</span>
                        <p className='p-info-label'>{intl.getHTML(`${info}`)}</p>
                    </div>
                </>
            )}
            <div className='col-md-6 col-12 plm-0'>
                <div className={`${classNameInput} d-flex justify-content-${position} align-items-center`}>
                    <span className='switch-span-option'>{intl.get(options[1].toUpperCase())}</span>
                    <Switch
                        checked={checked}
                        onChange={(e) => {
                            field.value[name] = e.target.checked ? options[0] : options[1];
                            form.setFieldValue(name, field.value[name]);
                            setChecked(e.target.checked);
                        }}
                        value={checked}
                        inputProps={inputProps}
                        disabled={disabled}
                    />
                    <span className='switch-span-option'>{intl.get(options[0].toUpperCase())}</span>
                </div>
                {name === 'animals' && value === 'yes' && (
                    <div className='col-12 pl-0'>
                        <p className='p-info-label'>
                            <span>{intl.get('DEFINITION_PET')}</span>
                        </p>
                    </div>
                )}
                {name === 'cosigners' && value === 'yes' && (
                    <div className='col-12 pl-0'>
                        <p className='p-info-label'>
                            <span>{intl.get('DEFINITION_CONSIGNE')}</span>
                        </p>
                    </div>
                )}
                {name === 'owner_type' && value === 'no' && (
                    <div className='col-12 pl-0'>
                        <p className='p-info-label'>
                            <span>{intl.get('IS_OWNER')}</span>
                        </p>
                    </div>
                )}
                {name === 'leased' && value === 'no' && (
                    <div className='col-12 pl-0'>
                        <p className='p-info-label'>
                            {intl.get('PROPERTY_NOT_LEASED_1')}
                            <Link to='/new-unit' className='text-color-secondary'>
                                link
                            </Link>
                            {intl.get('PROPERTY_NOT_LEASED_2')}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

const SwitchString = ({ ...props }: SwitchProps) => {
    const [checked, setChecked] = useState(props.value === props.options[0]);
    return (
        <Field>
            {({ field, form }: any) => {
                return <SwitchOption {...props} field={field} form={form} checked={checked} setChecked={setChecked} />;
            }}
        </Field>
    );
};

export default SwitchString;
