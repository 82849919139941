export const getCountriesCodesStart = () => {
    return {
        type: 'GET_CODES_STARTED',
    };
};

export const getCountriesCodesSuc = (codes: any) => {
    return {
        type: 'GET_CODES_SUCCESS',
        payload: codes,
    };
};

export const getCountriesCodesError = (error: any) => {
    return {
        type: 'GET_CODES_ERROR',
        payload: {
            error,
        },
    };
};
