const initialState: any = {
  codes: [],
  fetchingCodes: 'NO_CODES',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_CODES_STARTED':
      return {
        ...state,
        codes: [],
        fetchingCodes: 'FETCHING_CODES',
      }

    case 'GET_CODES_SUCCESS':
      return {
        ...state,
        codes: action.payload,
        fetchingCodes: 'FETCHED_CODES',
      }
    case 'GET_CODES_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingCodes: 'ERROR_FETCHING_CODES',
      }
    default:
      return state
  }
}
