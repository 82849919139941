import { multipartPost } from '../../helpers/apiConnections';
import { uploadPhotoStart, uploadPhotoSuccess, uploadPhotoError } from './types';
import { toast } from 'react-toastify';
import { getPhotos } from '../index';
import { handleError } from '../../helpers/handleErrors';
const uploadPhoto = (data: FormData, index: string, postId: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(uploadPhotoStart(index));
        multipartPost('photos', data)
            .then((result) => {
                dispatch(uploadPhotoSuccess(result.data, index));
                dispatch(getPhotos(postId));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(uploadPhotoError(e));
            });
    };
};
export default uploadPhoto;
