import React, { useState, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { InputMap } from '..';
import Map from '../map';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import './style.scss';
import intl from 'react-intl-universal';
import { getCommunes, getCities } from '../../../tango-react-base/reduxActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isHomePage } from '../../helpers/roleComparison';

/**
 * Here it is defined the type of the property, 'selectPropsDoc' is for the documentation
 * @typedef selectPropsDocu
 * @type {(string|any)}
 * @property {string} description - is an string.
 * @property {function} structured_formatting - is an function.
 */
interface selectProps {
    description: string;
    structured_formatting: any;
}

/**
 * Here it is defined the type of the property, 'FieldPropsDocu' is for the documentation
 * @typedef FieldPropsDocu
 * @type {(any)}
 * @property {any} form - is an any.
 * @property {any} field - is an any.
 */
interface FieldProps {
    form?: any;
    field?: any;
}

/**
 * Here it is defined the type of the property, 'inputPropsDocu' is for the documentation
 * @typedef inputPropsDocu
 * @type {(string|any|boolean|number|Function)}
 * @property {any}  values - is an any
 * @property {string} id - is a string
 * @property {string} label - is a string
 * @property {string} requiredMessage - is a string
 * @property {any} touched - is an any
 * @property {string} type - is a string
 * @property {boolean} required - is a boolean
 * @property {boolean} disabled: boolean
 * @property {any} error - is an any
 * @property {any} setFieldValue - is an any
 * @property {number} latitude - is a number
 * @property {number} longitude - is a number
 * @property {number} zoom - is a number
 * @property {boolean} hiddenMap - is a boolean
 * @property {string} className - is a string
 * @property {string} classNameInput - is a string
 * @property {boolean} variant - is a boolean
 * @property {string} labelInput - is a string
 * @property {string} classNameContainer - is a string
 * @property {boolean} showOnlySecondText - is a boolean
 * @property {string} info - is a string
 * @property {string} typeMap - is a string
 * @property {any} allValues - is a any
 * @property {boolean} validAddress - is a boolean
 * @property {boolean} stateValidAddress - is a boolean
 * @property {string | undefined} stateUnit - is a string | undefined
 * @property {boolean} onlyCommune - is a boolean
 * @property {boolean} detectSelectGMap - is a boolean
 * @property {Function} onGetCommunes - is a Function
 * @property {Function} onGetCities - is a Function
 * @property {any} communes - is an any
 * @property {any} cities - is an any
 * @property {any} fetchingCommunes - is an any
 * @property {any} currentCommune - is an any
 * @property {string} classNameLabel - is a string
 * @property {any} setInvalidAddress - is an any
 * @property {boolean} isNewRentedUnit - is a boolean
 * @property {any} setValidAddresRented - is an any
 */
interface inputProps {
    values: any;
    id: string;
    label?: string;
    requiredMessage?: string;
    touched: any;
    type: string;
    required?: boolean;
    disabled: boolean;
    error: any;
    setFieldValue?: any;
    latitude?: number;
    longitude?: number;
    zoom?: number;
    hiddenMap?: boolean;
    className?: string;
    classNameInput?: string;
    variant?: boolean;
    labelInput?: string;
    classNameContainer?: string;
    showOnlySecondText?: boolean;
    info?: string;
    typeMap?: string;
    allValues?: any;
    validAddress?: boolean;
    stateValidAddress?: boolean;
    stateUnit?: string | undefined;
    onlyCommune?: boolean;
    detectSelectGMap?: boolean;
    onGetCommunes: () => void;
    onGetCities: () => void;
    communes: any;
    cities?: any;
    fetchingCommunes: any;
    currentCommune?: any;
    classNameLabel?: string;
    setInvalidAddress?: any;
    // isNewRentedUnit?: boolean
    setValidAddresRented?: any;
}

/**
 * Autocomplete is a functional component
 *@function
 *@param {inputPropsDocu} values - returns the input values
 *@param {inputPropsDocu} id - component id (e.g full_address)
 *@param {inputPropsDocu} label - component label for intl (e.g ADDRESS)
 *@param {inputPropsDocu} requiredMessage - if the component is required it indicates a message
 *@param {inputPropsDocu} touched - check if component value is touched
 *@param {inputPropsDocu} type - component type (e.g text, email, color)
 *@param {inputPropsDocu} required - check if component value is required
 *@param {inputPropsDocu} disabled - check if component value is disabled
 *@param {inputPropsDocu} error - returns message error
 *@param {FieldPropsDocu} form - returns form props
 *@param {inputPropsDocu} hiddenMap - hide the map (true/false)
 *@param {inputPropsDocu} className - returns a string with the class of the div
 *@param {inputPropsDocu} classNameInput - returns a string with the class of the input
 *@param {inputPropsDocu} variant - returns a boolean and detect if is a variant
 *@param {inputPropsDocu} labelInput - string for intl (e.g ADDRESS_INPUT)
 *@param {inputPropsDocu} classNameContainer - returns a string with the class of the container(div or section)
 *@param {inputPropsDocu} onlyCommune - show only communes and country (e.g Santiago, Chile)
 *@param {inputPropsDocu} latitude - returns the latitude
 *@param {inputPropsDocu} longitude - returns the longitude
 *@param {inputPropsDocu} info - returns a string for intl (e.g ADDRESS_INSTRUCTIONS)
 *@param {inputPropsDocu} typeMap - returns a string with typeMap
 *@param {inputPropsDocu} allValues - returns all values of the form
 *@param {inputPropsDocu} stateValidAddress - returns if address is valid
 *@param {inputPropsDocu} stateUnit - returns string with state of the unit
 *@param {inputPropsDocu} detectSelectGMap - detect if google map was selected
 *@param {inputPropsDocu} onGetCommunes - it is a function that executes an action
 *@param {inputPropsDocu} onGetCities - it is a function that executes an action
 *@param {inputPropsDocu} communes - returns an array with all communes availables
 *@param {inputPropsDocu} cities - returns an array with all cities availables
 *@param {inputPropsDocu} fetchingCommunes - returns a string with fetch status
 *@param {inputPropsDocu} currentCommune - detect the current commune selected
 *@param {inputPropsDocu} classNameLabel - returns a string with the class for the label
 *@param {inputPropsDocu} setInvalidAddress - is a useState and detect invalid address
 *@param {inputPropsDocu} isNewRentedUnit - detect if the current location is new-rented-unit
 *@param {inputPropsDocu} setValidAddresRented - is a useState and detect invalid address in is new-rented-unit
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const Autocomplete = ({
    values,
    id,
    label,
    requiredMessage,
    touched,
    type,
    required,
    disabled,
    error,
    form,
    hiddenMap,
    className,
    classNameInput,
    variant,
    labelInput,
    typeMap,
    classNameContainer,
    onlyCommune,
    latitude = -33.447487,
    longitude = -70.673676,
    info,
    allValues,
    stateValidAddress,
    stateUnit,
    detectSelectGMap,
    onGetCommunes,
    onGetCities,
    communes,
    cities,
    fetchingCommunes,
    currentCommune,
    classNameLabel,
    setInvalidAddress,
    // isNewRentedUnit,
    setValidAddresRented,
}: inputProps & FieldProps) => {
    const {
        ready,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {
                country: ['cl'],
            },
        },
        debounce: 300,
    });

    const [zoom, setZoom] = useState(stateUnit === 'created' ? 16 : 11);
    const [validAddress, setValidAddress] = useState(stateValidAddress);
    const [validCommunes, setValidCommunes] = useState([]);
    const [validCommuneOrProvince, setValidCommuneOrProvince] = useState(true);
    const [allowedCities, setAllowedCities] = useState([]);
    const [validHouse, setValidHouse] = useState(true);
    const [addressType, setAddressType] = useState([]);
    const ref = useOnclickOutside(() => clearSuggestions());
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const { fetchingCities, cities: allCities } = cities || [];
    const isNewUnitCreationView =
        currentLocation === 'new-unit-creation' || currentLocation === 'new-unit-creation-instapage';
    const isRoleHome = process.env.REACT_APP_ROLE === 'home';

    useEffect(() => {
        setValidAddresRented && setValidAddresRented(validAddress);
    }, [validAddress]);

    useEffect(() => {
        if (currentCommune) {
            getGeocode({ address: currentCommune }).then((results) => {
                form.setFieldValue('selectOneGMap', true);
                getAddressComponents(results);
            });
        }
    }, [currentCommune]);

    const statusCommunnes = fetchingCommunes === 'FETCHED_COMMUNES';
    const statusCities = fetchingCities === 'FETCHED_CITIES';

    useEffect(() => {
        if (validCommunes.length <= 0 && !isHomePage) {
            onGetCommunes();
            statusCommunnes && setValidCommunes(communes);
        }
        if (allowedCities.length <= 0 && !isHomePage) {
            onGetCities();
            statusCities && setAllowedCities(allCities);
        }
    }, [statusCommunnes, statusCities]);
    /**
     * formatGoogleMapsAddress: This function receives a string parameter
     * Its main purpose is to return a formatted string equal to the values from the communes resource
     * @function
     * @returns {(string)} Returns a string with the formatted commune
     */
    const formatGoogleMapsAddress = (value: any) => {
        return value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(' ', '_')
            .toLowerCase();
    };
    useEffect(() => {
        setInvalidAddress && setInvalidAddress(!validAddress);
    }, [validAddress]);

    const handleErrorMessage = () => {
        if (typeMap === 'marker' || typeMap === 'no-map') {
            if (!validCommuneOrProvince) return intl.get('INVALID_COMMUNE');
        } else {
            return '';
        }

        const containAddress = ['street_address', 'premise', 'subpremise'];

        if (
            (addressType[0] && !containAddress.includes(addressType[0])) ||
            values?.length === 0 ||
            !validHouse ||
            !validAddress
        )
            return intl.get('INVALID_ADDRESS_GENERIC');
        else return '';
    };

    const handleInput = (e: any) => {
        detectSelectGMap && form.setFieldValue('selectOneGMap', false);
        const value = e.target.value;
        const valueSlice = value.slice(0, -1);
        if (allValues?.full_address) {
            if (valueSlice !== allValues.full_address || !values.includes(',')) {
                setValidAddress(false);
            } else {
                setValidAddress(true);
            }
        }
        setValue(value);
        form.setFieldValue(id, value);
    };
    const handleSelect =
        ({ description, structured_formatting: { main_text, secondary_text } }: selectProps) =>
        () => {
            detectSelectGMap && form.setFieldValue('selectOneGMap', true);
            const textCommune = secondary_text.split(' ').length === 1 ? `${main_text}, ` : '';
            const textValue = onlyCommune ? `${textCommune}${secondary_text}` : description;
            form.setFieldValue(id, textValue);
            clearSuggestions();
            getGeocode({ address: textCommune + description })
                .then((results) => {
                    const addressComponents = results[0].address_components;

                    // * TODO:  Revisar eLiminación de funciones obsoletas -> Revisar cambios en PR PSE-1756
                    const findValidHouse = addressComponents.find((house: any) => {
                        return house.types.includes('administrative_area_level_2');
                    });

                    const isValidProvince = allowedCities.some(
                        (province: any) => province.value === formatGoogleMapsAddress(findValidHouse?.short_name),
                    );
                    if (findValidHouse) setValidCommuneOrProvince(isValidProvince);

                    const containAddress = ['street_address', 'premise', 'route', 'geocode', 'subpremise'];
                    const validProvinceOrCommune = containAddress.includes(results[0].types.join()) && isValidProvince;

                    onlyCommune
                        ? getAddressComponents(results)
                        : validProvinceOrCommune
                        ? validActions(results)
                        : invalidActions(results);
                })
                .catch((error) => {
                    console.error('Error: ', error);
                });

            !onlyCommune &&
                getGeocode({ address: description })
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        getLatitude(lat);
                        getLongitude(lng);
                        setZoom(16);
                    })
                    .catch((error) => {
                        console.error('Error: ', error);
                    });
        };

    const getLatitude = (lat: number) => form.setFieldValue('latitude', lat);
    const getLongitude = (lng: number) => form.setFieldValue('longitude', lng);

    const getAddressComponents = (fullData: any) => {
        const address_components = fullData[0].address_components;
        const communeTypes = ['administrative_area_level_3', 'locality'];
        address_components.forEach((comp: any) => {
            const types = comp.types;
            if (!onlyCommune) {
                types.includes('street_number') && getAddressNumber(comp);
                types.includes('route') && getAddressStreet(comp);
            }
            if (communeTypes.some((item) => types.indexOf(item) >= 0)) {
                getCommune(comp);
            }

            types.includes('administrative_area_level_2') && getProvince(comp);
            types.includes('administrative_area_level_1') && getCity(comp);
            types.includes('country') && getCountry(comp);
        });
    };

    const getAddressNumber = (data: any) => form.setFieldValue('address_number', data.long_name);

    const getAddressStreet = (data: any) => form.setFieldValue('address_street', data.long_name);

    const getCommune = (data: any) => {
        form.setFieldValue('commune', data.long_name);
    };

    const getProvince = (data: any) => form.setFieldValue('province', data.long_name);

    const getCity = (data: any) => form.setFieldValue('city', data.long_name);

    const getCountry = (data: any) => form.setFieldValue('country_name', data.long_name);

    const renderSuggestions = () =>
        data
            .filter(function (suggestion) {
                return ['street_address', 'premise', 'route', 'geocode', 'subpremise'].some((el) =>
                    suggestion.types.includes(el),
                );
            })
            .map((suggestion) => {
                const {
                    place_id,
                    structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                    <MenuItem key={place_id} onClick={handleSelect(suggestion)}>
                        {onlyCommune ? (
                            <strong>
                                {secondary_text && secondary_text.split(' ').length === 1 && `${main_text}, `}
                                {secondary_text}
                            </strong>
                        ) : (
                            <span className={currentLocation === 'new-unit-creation-instapage' ? 'd-inline-grid' : ''}>
                                <strong>{main_text}</strong> <small>{secondary_text}</small>
                            </span>
                        )}
                    </MenuItem>
                );
            });

    const validActions = (results: any) => {
        getAddressComponents(results);
        setValidAddress(true);
        setAddressType(results[0].types);
    };

    const invalidActions = (results: any) => {
        const fields = ['address_number', 'address_street', 'commune', 'province', 'city', 'country_name'];

        fields.forEach((field) => {
            form.setFieldValue(field, '');
        });

        setAddressType(results[0].types);
        setValidAddress(false);
    };

    const klassContainerSuggestions = () => {
        let klass = '';
        if (typeMap !== 'marker') {
            return klass;
        }
        klass = typeMap === 'marker' && !validAddress ? 'with-error' : '';
        return klass;
    };

    return (
        <div className={classNameContainer} ref={ref}>
            <InputMap
                className={className}
                classNameInput={classNameInput}
                disabled={!ready || disabled}
                disabledAutocomplete={true}
                error={error}
                id={id}
                info={info}
                label={label}
                labelInput={labelInput}
                messageAdditionalValidation={handleErrorMessage()}
                onChange={(e: any) => handleInput(e)}
                required={required}
                requiredMessage={requiredMessage}
                type='address'
                value={values}
                variant={variant}
                classNameLabel={classNameLabel}
            >
                {status === 'OK' && (
                    <div className='d-flex justify-content-start'>
                        <div className={`${klassContainerSuggestions()} list-container`}>
                            <ul className='p-0'>{renderSuggestions()}</ul>
                        </div>
                    </div>
                )}
            </InputMap>
            {!isNewUnitCreationView && (
                <div hidden={hiddenMap}>
                    {typeMap && <Map newCenter={{ lat: latitude, lng: longitude }} newZoom={zoom} typeMap={typeMap} />}
                </div>
            )}
        </div>
    );
};

const PlacesAutocomplete = ({ ...props }: inputProps) => {
    return <Field>{({ field, form }: any) => <Autocomplete {...props} form={form} field={field} />}</Field>;
};

const mapStateToProps = ({ communes, cities }: any) => ({
    ...communes,
    cities,
});
const mapDispatchToProps = (dispatch: any, getState: any) => ({
    onGetCommunes: () => {
        return dispatch(getCommunes());
    },
    onGetCities: () => {
        return dispatch(getCities());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacesAutocomplete);
