import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { getRestitutionsStart, getRestitutionsSuccess, getRestitutionsError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
const getRestitutions = (state_restoration_voucher: string, filter?: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getRestitutionsStart(state_restoration_voucher));
        getData(
            filter
                ? `unit_visits/restoration_vouchers?state_voucher=${state_restoration_voucher}${filter}`
                : `unit_visits/restoration_vouchers?state_voucher=${state_restoration_voucher}`,
        )
            .then((result) => {
                return dispatch(getRestitutionsSuccess(state_restoration_voucher, result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRestitutionsError(state_restoration_voucher, e));
            });
    };
};
export default getRestitutions;
