import React from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { isRequired } from '../../helpers/fieldValidations';
import { Field } from 'formik';
import Select from '../select';
interface InputProps {
    label?: string;
    placeholder_intl?: string;
    bottomLabel?: string;
    id: string;
    id_currency: string;
    placeholder?: string;
    onChange: any;
    value: any;
    name?: string;
    error?: string;
    touched?: any;
    onBlur?: any;
    min?: string;
    max?: string;
    className?: string;
    requiredMessage?: string;
    multiline?: boolean;
    disabled: boolean;
    currency_options: Array<any>;
    currency_iso_value: string;
}
const BootstrapInput = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);

const PriceInput = ({
    placeholder,
    touched,
    bottomLabel,
    placeholder_intl,
    label,
    className,
    error,
    requiredMessage,
    currency_options,
    currency_iso_value,
    onChange,
    disabled,
    id_currency,
    ...inputProps
}: InputProps) => {
    return (
        <Field name={inputProps.id} validate={!!requiredMessage && isRequired(requiredMessage)}>
            {({ field, form }: any) => {
                return (
                    <div className={`row input-container col-12 ${className} pl-0`}>
                        <>
                            <span className='col-md-12 col-12 pl-0'>{intl.getHTML(`${label}`)}</span>
                            <div className='col-md-9 col-12 d-inline-flex p-6 pr-0 plm-0'>
                                <Select
                                    options={currency_options}
                                    onChange={onChange}
                                    id={id_currency}
                                    value={currency_iso_value}
                                    disabled={true}
                                    className='price-select'
                                />
                                <BootstrapInput
                                    className={`col ${error && touched ? 'input-error' : ''}`}
                                    id={`bootstrap-input-${inputProps.id}`}
                                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                    inputProps={{
                                        type: 'number',
                                        min: 0,
                                        ...inputProps,
                                    }}
                                    error={!!error}
                                    helperText={error}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    onChange={onChange}
                                />
                            </div>
                        </>

                        {bottomLabel && <span>{intl.get(`${bottomLabel}`)}</span>}
                    </div>
                );
            }}
        </Field>
    );
};

export default PriceInput;
