import { UnitState } from '../../../tango'

const initialState: UnitState = {
  unit: [],
  fetch: 'LOADING',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_UNIT_STARTED':
      return {
        ...state,
        fetch: 'LOADING',
      }
    case 'GET_UNIT_SUCCESS':
      return {
        ...state,
        unit: action.payload.data,
        fetch: 'LOADED',
      }
    case 'GET_UNIT_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
    default:
      return state
  }
}
