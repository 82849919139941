/* istanbul ignore file */
import Input from './input'
import PostCard from './postCard'
import IconButton from './iconButton'
import DropZone from './dropzone'
import Select from './select'
import Switch from './switch'
import SwitchString from './switchString'
import SwitchStringInput from './switchStringInput'
import DatePicker from './datePicker'
import MultipleDropzone from './multipleDropzone'
import ImageViewer from './imageViewer'
import PostForm from './postForm'
import CheckBox from './checkBox'
import AutomaticUploadButton from './automaticUploadButton'
import ViewIDUser from './viewIDUser'
import IncomeDataForm from './forms/incomeDataForm'
import IncomeDataFormConsignee from './formsConsignee/incomeDataFormConsignee'
import PostDetail from './postDetail'
import DocumentValidation from './documentValidation'
import VisitorCalendar from './visitorCalendar'
import RequesterCalendar from './requesterCalendar'
import PriceInput from './priceInput'
import VisitsList from './visitsList'
import OfferDetail from './offerDetail'
import RenterCalendar from './renterCalendar'
import Calendar from './calendar'
import CalendarRefactor from './calendar/calendarRefactor'
import PostProperty from './postProperty'
import LoginForm from './user/loginForm'
import InputGroup from './inputGroup'
import TimePicker from './timePicker'
import ForgetPasswordEmailForm from './user/forgetPasswordEmailForm'
import PutTokenForm from './user/putTokenForm'
import CardContainer from './cardContainer'
import NewPasswordForm from './user/newPasswordForm'
import ErrorView from './errorView'
import UserForm from './user/userForm'
import CreateUser from './user/createUser'
import CreateUserToken from './user/createUserToken'
import ForgetPasswordToken from './user/forgetPasswordToken'
import NavBar from './navBar'
import ScheduledVisits from './scheduledVisits'
import Footer from './footer'
import SelectFilterIndex from './selectFilterIndex'
import PostCardRenter from './postCardRenter'
import RadioButtonTango from './radioButtonTango'
import RadioButtonInputTango from './radioButtonInputTango'
import CheckBoxTango from './checkBoxTango'
import AllPhotos from './allPhotos'
import ButtonsHeader from './buttonsHeader'
import AccordionItem from './accordionItem'
import FilterAdvancedForm from './filterAdvancedForm'
import PostCardTimer from './postCardTimer'
import TimeLineTango from './timeLineTango'
import CardMountContainer from './cardMountContainer'
import DialogModal from './dialogModal'
import NewDialogModal from './newDialogModal'
import WelcomeRenter from './welcomeRenter'
import WelcomeInstructions from './welcomeInstructions'
import RedirectTo from './redirectTo'
import InputRange from './inputRange'
import FooterHome from './footerHome'
import PostCardPlan from './postCardPlan'
import Map from './map'
import MapAutoComplete from './mapAutoComplete'
import Modal from './modal'
import CircularLoading from './circularLoading'
import TermsAndConditions from './termsAndCondition'
import AboutUs from './aboutUs'
import ThankYou from './thankYou'
import CommunesCard from './communesCard'
import DropZoneUnitDocument from './dropZoneUnitDocument'
import VideosCarousel from './carouselVideo'
import QrCode from './qrCode'
import MapAutoCompleteProfile from './mapAutoCompleteProfiles'
import FiguresCard from './figuresCard'
import InputMap from './inputMap'
import RejectInventoryModal from './rejectInventoryModal'
import NavbarMenuOptions from './navbarMenuOptions'
import ButtonRedirect from './redirectButton'
import InputDataShow from './inputDataShow'
import InputDataShowNew from './inputDataShowNew'
import TableTango from './tableTango'
import TangoData from './tangoData'
import RadioButtonTangoLink from './radioButtonTangoLink'
import HandleFetch from './handleFetchs'
import BankAccountDataForm from './bankAccountDetail'
import ButtonLoading from './buttonLoading'
import CalendarColorMeaning from './calendarColorMeaning'
import PopupWithIcon from './popupWithIcon'
import ButtonTooltip from './buttonTooltip'
import RenewedLeaseView from './renewedLeaseView'
import PendingLeaseRenewal from './pendingLeaseRenewalView'
import RejectLeaseRenewalView from './rejectLeaseRenewalView'
import NegotiatingLeaseRenewalView from './negotiatingLeaseRenewalView'
import CustomToolTip from './customTooltip'
import MediationResponse from './mediationResponse'
import PendingMediation from './pendingMediation'
import RenewalOptionsCard from './renewalOptionsCard'
import PaymentMethodComponent from './paymentMethodComponent'
import StaticTable from './tables'
import Search from './search'
import SearchComponent from './search/searchComponent'
import PlanOptionsCard from './planOptionsCard'
import PopUpTermsAndCondition from './popupTermsAndContition'
import TangoCard from './tangoCard'
import Backdrop from './backdrop'
import Sidebar from './navBar/sidebar'
import IconCard from './iconCard'
import CardMountContainerTango from './cardMountContainerTango'
import SelectInputPrice from './selectInputPrice'
import InputMachine from './inputMachine'
import PopUpUserPhoneInput from './popupPhoneInput'
import ShortRegister from './shortRegister'
import NotAvailableVisitInfo from './notAvailableVisit'
import PopUpTocResponse from './popupTocResponse'
import CloseLeaseEarlyComponent from './closeLeaseEarlyComponent' 
import CardPromotions from './cardPromotions'
import PromotionCard from './promotionCard'
import PopupWithIconButtons from './popupWithIcon/variantWithButtons'
import CardComponent from './CardComponent'
import {ButtonGroup, CustomDot} from './carousel'
import AlertMessage from './alertMessage'
import StatusModal from './statusModal'
import InputFee from './inputFee'

export {
  Input,
  AlertMessage,
  PostCard,
  IconButton,
  DropZone,
  Select,
  Switch,
  SwitchString,
  SwitchStringInput,
  DatePicker,
  MultipleDropzone,
  ImageViewer,
  PostForm,
  CheckBox,
  AutomaticUploadButton,
  ViewIDUser,
  IncomeDataForm,
  PostDetail,
  DocumentValidation,
  VisitorCalendar,
  RequesterCalendar,
  PriceInput,
  VisitsList,
  OfferDetail,
  RenterCalendar,
  PostProperty,
  Calendar,
  CalendarRefactor,
  LoginForm,
  InputGroup,
  TimePicker,
  ForgetPasswordEmailForm,
  CardContainer,
  NewPasswordForm,
  PutTokenForm,
  ErrorView,
  UserForm,
  CreateUser,
  ForgetPasswordToken,
  CreateUserToken,
  NavBar,
  ScheduledVisits,
  Footer,
  SelectFilterIndex,
  PostCardRenter,
  RadioButtonTango,
  RadioButtonInputTango,
  CheckBoxTango,
  AllPhotos,
  ButtonsHeader,
  AccordionItem,
  FilterAdvancedForm,
  PostCardTimer,
  TimeLineTango,
  CardMountContainer,
  DialogModal,
  NewDialogModal,
  WelcomeRenter,
  WelcomeInstructions,
  StatusModal,
  InputRange,
  FooterHome,
  PostCardPlan,
  Map,
  MapAutoComplete,
  Modal,
  IncomeDataFormConsignee,
  CircularLoading,
  TermsAndConditions,
  AboutUs,
  CommunesCard,
  RedirectTo,
  DropZoneUnitDocument,
  VideosCarousel,
  ThankYou,
  QrCode,
  MapAutoCompleteProfile,
  FiguresCard,
  InputMap,
  NavbarMenuOptions,
  RejectInventoryModal,
  ButtonRedirect,
  InputDataShow,
  TableTango,
  TangoData,
  RadioButtonTangoLink,
  HandleFetch,
  BankAccountDataForm,
  ButtonLoading,
  CalendarColorMeaning,
  PopupWithIcon,
  ButtonTooltip,
  RenewedLeaseView,
  PendingLeaseRenewal,
  RejectLeaseRenewalView,
  CustomToolTip,
  NegotiatingLeaseRenewalView,
  MediationResponse,
  PendingMediation,
  RenewalOptionsCard,
  PaymentMethodComponent,
  StaticTable,
  Search,
  SearchComponent,
  PlanOptionsCard,
  PopUpTermsAndCondition,
  TangoCard,
  Backdrop,
  Sidebar,
  IconCard,
  CardMountContainerTango,
  SelectInputPrice,
  InputMachine,
  PopUpUserPhoneInput,
  ShortRegister,
  NotAvailableVisitInfo,
  PopUpTocResponse,
  CloseLeaseEarlyComponent,
  CardPromotions,
  PromotionCard,
  PopupWithIconButtons,
  CardComponent,
  ButtonGroup,
  CustomDot,
  InputFee
}
