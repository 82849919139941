import React from 'react';

interface IconAndDescriptionProps {
    img: any;
    text: string;
    mobile?: boolean;
}

const IconAndDescription = ({ img, text, mobile }: IconAndDescriptionProps) => {
    return (
        <>
            <div className='info-sign-up'>
                <img className='info-img' alt='info-signup' src={img} width={35} height={mobile ? 35 : 60} />
                <span className='info-text font-size-signup'>{text}</span>
            </div>
        </>
    );
};

export default IconAndDescription;
