import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';

interface InputFeeProps {
    id: any;
    onClick?: any;
    justifyCenter?: string;
    className?: any;
    classNameLabel?: any;
    classNameInput?: string;
    label: string;
    value: any;
    noBorderRadius?: any;
    applyGrayStyle?: any;
    info: string;
    onBlur: any;
    onChange: any;
    disabled: boolean;
}

const InputFee = ({
    noBorderRadius = false,
    applyGrayStyle = false,
    onClick,
    className,
    classNameLabel = 'input-label-container',
    classNameInput = 'input-select-container',
    justifyCenter = 'align-items-start',
    label,
    value,
    info,
    onBlur, 
    onChange,
    disabled,
    ...inputProps
}: InputFeeProps) => {
    const { currentLocale } = intl.getInitOptions();
    const noBorders = noBorderRadius ? ' no-border-radius' : '';

    return (
        <Field name={inputProps.id}>
            {({ field, form }: any) => {
                const changeValue = (value: any) => {
                    form.setFieldValue(inputProps.id, value.floatValue);
                };

                return (
                    <>
                        <div onClick={onClick} className={`row input-container d-flex ${justifyCenter} ${className}`}>
                            {label !== undefined && label !== '' && (
                                <div className={classNameLabel}>
                                    <p className={'text-color-secondary'}>{intl.get(`${label}`).toUpperCase()}</p>
                                    <p className='p-info-label'>{intl.get(`${info}`)}</p>
                                </div>
                            )}
                            <div className={classNameInput}>
                                <div className='d-flex'>
                                    <div className={`percentage-icon-container d-flex col-4 col-sm-3 col-lg-4 p-0`}>
                                        %
                                    </div>

                                    <NumberFormat
                                        allowNegative={false}
                                        className={'price-validation-color-secondary' + noBorders}
                                        decimalScale={2}
                                        decimalSeparator={currentLocale === 'en-US' ? '.' : ','}
                                        defaultValue={value ? value : 0}
                                        disabled={disabled}
                                        helperText={'error && intl.get(error)'}
                                        id={inputProps.id}
                                        onValueChange={(value) => changeValue(value)}
                                        required={true}
                                        thousandSeparator={currentLocale === 'en-US' ? ',' : '.'}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

export default InputFee;
