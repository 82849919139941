import axios from 'axios';
export default async (url: string, data?: any) => {
    let api_url = `${process.env.REACT_APP_API_URL}`;
    let role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const urlsWithoutApp = ['auth', 'user', 'users'];
    const baseUrl = url.split('/')[0];
    const uri = urlsWithoutApp.includes(baseUrl)
        ? api_url.replace(role.toLowerCase(), `${url}`)
        : `${process.env.REACT_APP_API_URL}/${url}`;
    let headers: any = {
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };

    return axios.put(uri, data, { headers });
};
