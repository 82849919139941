import { rejectMediationStart, rejectMediationSuccess, rejectMediationError } from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const rejectMediation = (id: number, setConfirmationPopup: (state: boolean) => void) => {
    return (dispatch: any) => {
        dispatch(rejectMediationStart());
        postData(`renewal_periods/${id}/reject_mediation_price`)
            .then((result) => {
                dispatch(rejectMediationSuccess());
                setConfirmationPopup(true);
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(rejectMediationError(e));
            });
    };
};
export default rejectMediation;
