import React, { useEffect } from 'react'
import { VisitsList } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**
 * Here it is defined the type of the VisitsAllTable Component
 * @typedef VisitsAllTablePropsTypes
 * @type {(function|object)}
 * @property {object} visitsPending - is a object.
 * @property {object} visitFinished - is a object.
 * @property {object} visitsCanceled - is a object.

 */

interface VisitsAllTableProps {
  visitsPending: { data: Array<any>, fetch: String }
  visitsFinished: { data: Array<any>, fetch: String }
  visitsCanceled: { data: Array<any>, fetch: String }
}

/**
 * VisitsAllTable is a functional component
 * @function
 * @param {VisitsAllTablePropsTypes} visitsPending - is a object with pending visits.
 * @param {VisitsAllTablePropsTypes} visitFinished - is a object with finished visits.
 * @param {VisitsAllTablePropsTypes} visitsCanceled - is a object with canceled visits.
 * @param {VisitsAllTablePropsTypes} submitBlocksTaken - this function send blocks taken.
 * @returns {(ReactComponent)} Returns a react component with types of visit tables displayed by the photographer or tanguero.
 */

const VisitsAllTable = ({
  visitsPending,
  visitsFinished,
  visitsCanceled,
}: VisitsAllTableProps) => {
  const { visitsFinished: allVisitsFinished } = useSelector(
    (state: any) => state.visitFinished,
  )

  const history = useHistory()
  useEffect(() => {
    if (allVisitsFinished.length >= 1) {
      history.go(0)
    }
  }, [allVisitsFinished])

  return (
    <>
      <div>
        <div className="template-line" />
        <h2 className="new-subtitle pd-1">{intl.get('MY_VISITS')}</h2>
        <div className="pd-1">
          <h2 className="text text-color-secondary">
            {intl.get('TITLE_PENDING')}
          </h2>
          <VisitsList visits={visitsPending.data} fetch={visitsPending.fetch} />
          <h2 className="text text-color-secondary">
            {intl.get('TITLE_FINISHED')}
          </h2>
          <VisitsList visits={visitsFinished.data} fetch={visitsFinished.fetch} />
          <h2 className="text text-color-secondary">
            {intl.get('TITLE_CANCELED')}
          </h2>
          <VisitsList visits={visitsCanceled.data} fetch={visitsCanceled.fetch}/>
        </div>
      </div>
    </>
  )
}
export default VisitsAllTable
