import { PostsState } from '../../../tango'

const initialState: PostsState = {
  posts: [],
  fetch: 'LOADING',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_POSTS_STARTED':
      return {
        ...state,
        fetch: 'LOADING',
      }
    case 'GET_POSTS_SUCCESS':
      return {
        ...state,
        posts: action.payload.data,
        fetch: 'LOADED',
      }
    case 'GET_POSTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
    default:
      return state
  }
}
