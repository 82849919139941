/** @function capitalize
 * This is a function.
 * @param {string} string - String to capitalize
 * @return {string} returns the formatted string
 *
 * @example
 *     capitalize('test')
 *   returns "Test"
 */
export const capitalize = (string: any) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};
