import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import IconAndDescription from './IconAndDescription';
import ModalSignUp from './ModalSignUp';
import { useBlankView } from '../../helpers/hookBlankView/index';
import { detectMob } from '../../helpers/detectMobile';
import {
    tangoWhiteGreyLogo,
    tangoBlackRedLogo,
    SignupDeskRegistroRenterTango,
    SingupDeskRegistro2RenterTango,
    SingupDeskLayerRenterTango,
    SingupDeskRenterTango,
} from '../../resources';
import { CREATION_STEPS } from './steps';
import { useLastLocation } from 'react-router-last-location';
import { isRoleAdmin, isRolePhotographer, isRoleSuperAdmin, isRoleTanguero } from '../../helpers/roleComparison';

type ButtonChangeView = {
    label: string;
    to: string;
};

interface CardMountContainerProps {
    children?: any;
    grid_card?: number;
    buttonsActionView?: Array<ButtonChangeView>;
    activeViewLabel?: string; // Only use when you neeed change view
    infoTitle?: string;
    descriptionTitle?: string;
    textBody?: string;
    textBodySize?: string;
    heightCard?: string;
    IsOwner?: boolean;
    newUnitCreation?: boolean;
    creationStep?: string;
    emailField?: boolean;
    chooseRegister?: boolean;
}

const CardMountContainerTango = ({
    children,
    IsOwner,
    grid_card = 11,
    buttonsActionView,
    activeViewLabel,
    infoTitle,
    descriptionTitle,
    textBody,
    textBodySize,
    heightCard,
    newUnitCreation = false,
    creationStep,
    emailField = false,
    chooseRegister = false,
}: CardMountContainerProps) => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const CURRENT_LOCATION = pathname.split('/')[1];
    const ref: any = useRef(null);
    useBlankView(ref);

    const routesWithExpection = ['/new-unit-creation-instapage', '/sign-up-instapage', '/search-instapage'];

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation?.pathname || '';
    const isEmployee = isRolePhotographer || isRoleSuperAdmin || isRoleTanguero || isRoleAdmin;
    const currentAC = routesWithExpection.includes(pathname) || routesWithExpection.includes(lastPathname);

    const formValidation = ['/new-unit-creation-instapage', '/sign_up', '/new-unit-creation', '/sign-up-instapage'];
    const idValidation = formValidation.includes(pathname) ? 'form' : '';
    const isNewUnitCreationInstapage = history.location.pathname === '/new-unit-creation-instapage';

    return (
        <div className='mount-container-tango-view-app-tango'>
            <div className={`col-md-${grid_card} p-0 col-11`}>
                <div
                    className={`${
                        currentAC && !isNewUnitCreationInstapage
                            ? 'card-little-margin-top'
                            : isNewUnitCreationInstapage
                            ? 'm0-without-shadow'
                            : ''
                    } card-container-mount-app-tango`}
                    ref={ref}
                >
                    <section
                        className={`${!chooseRegister && 'info-card'} ${
                            newUnitCreation && !chooseRegister && 'new-unit-creation'
                        } ${chooseRegister && 'choose-register'}`}
                    >
                        <div className='text'>
                            {infoTitle && (
                                <div
                                    className='template-line'
                                    style={{
                                        backgroundColor: newUnitCreation ? '#a8123e' : `#ffffff`,
                                    }}
                                />
                            )}
                            <div className={`${newUnitCreation ? 'text-color-secondary pb-4' : 'text-color-white'}`}>
                                {infoTitle && <h1>{intl.get(infoTitle)}</h1>}
                                {descriptionTitle && <p className='m-0'>{intl.get(descriptionTitle)}</p>}
                            </div>
                            {newUnitCreation && !chooseRegister && (
                                <div className='mt-5'>
                                    {CREATION_STEPS.map((step: { number: string; content: string; state: string }) => (
                                        <h2
                                            className={`${
                                                creationStep === step.state && 'active-step'
                                            } creation-step-app-tango`}
                                            key={step.number}
                                        >
                                            <span className='step-number'>{step.number}</span>
                                            <span className='step-text'>{intl.get(step.content)}</span>
                                        </h2>
                                    ))}
                                    <h2 className='ml-4 text-left'>{intl.get('AND_THATS_IT')}</h2>
                                </div>
                            )}
                        </div>
                        {pathname === '/sign_up' && (
                            <>
                                {detectMob() && <ModalSignUp isOwner={IsOwner} mobile={detectMob()} />}
                                <div className='info-body upper-body'>
                                    <IconAndDescription
                                        img={SingupDeskRenterTango}
                                        text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_1`)}
                                    />
                                    <IconAndDescription
                                        img={SignupDeskRegistroRenterTango}
                                        text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_2`)}
                                    />
                                    <IconAndDescription
                                        img={SingupDeskRegistro2RenterTango}
                                        text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_3`)}
                                    />
                                    <IconAndDescription
                                        img={SingupDeskLayerRenterTango}
                                        text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_4`)}
                                    />
                                </div>
                                {IsOwner && <p className='info-owner-singup'>{intl.get('SIGNUP_INFO_OWNER')}</p>}
                            </>
                        )}
                        <div className={chooseRegister ? 'choose-register-logo-tango' : ''}>
                            <img
                                src={newUnitCreation && !chooseRegister ? tangoBlackRedLogo : tangoWhiteGreyLogo}
                                alt='Logo'
                                className='big-img-logo'
                            />
                        </div>
                    </section>
                    <section className='button-group-change'>
                        {buttonsActionView && (
                            <div className='button-group-selected'>
                                {buttonsActionView.map((value: ButtonChangeView, key: number) => (
                                    <button
                                        className={`button-change-view${
                                            activeViewLabel === value.label ? '-active' : ''
                                        }`}
                                        onClick={() => `/${CURRENT_LOCATION}` !== value.to && history.push(value.to)}
                                        key={`button-${key}`}
                                    >
                                        {intl.get(value.label)}
                                    </button>
                                ))}
                            </div>
                        )}
                    </section>
                    <section
                        id={idValidation}
                        className={`${currentAC && 'bg-color-secondary'} ${
                            newUnitCreation && !emailField && 'new-unit-creation'
                        } body-card`}
                    >
                        <div className={`${heightCard}`}>
                            {textBody && (
                                <div className='title-body '>
                                    <div className='template-line' style={{ backgroundColor: `#A8123E` }} />
                                    {CURRENT_LOCATION === 'sign_up' ||
                                        (CURRENT_LOCATION === '/sign-up-instapage' && (
                                            <h1 className='d-md-none d-sm-block mb-3'>{intl.get('CODE_VALIDATE')}</h1>
                                        ))}
                                    {CURRENT_LOCATION === 'forget_password' && (
                                        <h1 className='d-md-none d-sm-block mb-3'>{intl.get('RECOVER_PASSWORD')}</h1>
                                    )}
                                    <p className={`${textBodySize} m-0 mt-2`}>{intl.getHTML(textBody)}</p>
                                </div>
                            )}
                            <div className='data-body text'>{children}</div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default CardMountContainerTango;
