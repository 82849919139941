import { getBlocksAvailableStart, getBlocksAvailableSuccess, getBlocksAvailableError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getBlocksAvailable = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getBlocksAvailableStart());
        getData(`units/${id}/time_blocks_without_unavailables`)
            .then((result) => {
                return dispatch(getBlocksAvailableSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getBlocksAvailableError(e));
            });
    };
};
export default getBlocksAvailable;
