import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularLoading } from '..';
import intl from 'react-intl-universal';

interface RedirectToProps {
    summary: { attributes: { register_redirection_route: string } };
    fetchingSummary: string;
}

const RedirectTo = ({ summary, fetchingSummary }: RedirectToProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { register_redirection_route: afterRegisterView } = summary.attributes || '';
    const APP_ROLE = process.env.REACT_APP_API_ROLE_APP;
    const COMPARE_APP_ROLE = process.env.REACT_APP_OWNEREQUAL;
    const currentLocation = history.location.pathname.split('/')[1];
    const canShowLoading = currentLocation !== 'login';

    useEffect(() => {
        if (APP_ROLE === COMPARE_APP_ROLE) {
            let { getOwnerSummary } = require('../../../redux/actions');
            dispatch(getOwnerSummary());
        }
    }, [fetchingSummary]);

    const redirectTo = () => {
        const pendingIdDocument = afterRegisterView === 'profile/documents' ? '' : '/home/';

        return <>{history.push(`${pendingIdDocument}${afterRegisterView}`)}</>;
    };
    return (
        <div>
            {fetchingSummary === 'FETCHING' && canShowLoading && <CircularLoading />}
            {fetchingSummary === 'FETCHED' && redirectTo()}
            {fetchingSummary === 'ERROR' && <>{intl.get('ERROR')}</>}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { summary, fetchingSummary } = state.ownerSummary;
    return {
        summary,
        fetchingSummary,
    };
};
export default connect(mapStateToProps)(RedirectTo);
