import React from 'react';
import MarkerImg from '../../resources/icons/MarkerMap.svg';
import './style.scss';

interface MarkerProps {
    lat: number;
    lng: number;
    title: string;
    onClick?: any;
}

const Marker = ({ title, onClick }: MarkerProps) => {
    return (
        <div className='marker-container' title={title} onClick={() => (onClick ? onClick() : null)}>
            <img className='marker-img' src={MarkerImg} alt='|' />
            <div className='pulse' />
        </div>
    );
};

export default Marker;
