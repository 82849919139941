import { referredIcon } from '../../../resources'
import ManualReferredCodeForm from './manualReferredCodeForm'
import { PopupWithIcon } from '../..'
import React from 'react'
import intl from 'react-intl-universal'

/** Here it is defined the type of the props for the interface "ReferredCodePopupsProps"
 * @property {function} setReferredCodeConfirmation - is a function.
 * @property {function} setReferredCodeManual - is a function.
 * @property {function} setReferredCodeLocalStorage - is a function.
 * @property {function} setReferredInformation - is a function.
 * @property {boolean} referredCodeConfirmation - is a boolean.
 * @property {boolean} referredCodeManual - is a boolean.
 * @property {string} fullNameNewReference - is a string.
 * @property {string} fetchingNewReference - is a string.
 * @typedef ReferredCodePopupsProps
 * @type {(function|boolean|string)}
 */
interface ReferredCodePopupsProps {
  setReferredCodeConfirmation: Function
  setReferredCodeManual: Function
  setReferredCodeLocalStorage: Function
  setReferredInformation: Function
  referredCodeConfirmation: boolean
  referredCodeManual: boolean
  referredInformation: boolean
  fullNameNewReference: string
  fetchingNewReference: string
}

/**
 * ReferredCodePopups is a functional component
 * The main function of this component is to render popups corresponding to the Owner reference stream
 * when the code is correct and incorrect.
 * @function
 * @param {ReferredCodePopupsProps} setReferredCodeConfirmation - Update the value referredCodeConfirmation
 * @param {ReferredCodePopupsProps} setReferredCodeManual - Update the value referredCodeManual
 * @param {ReferredCodePopupsProps} setReferredCodeLocalStorage - Update the value referredCodeLocalStorage with the stored in localStorage
 * @param {ReferredCodePopupsProps} setReferredInformation - Update the value referredInformation
 * @param {ReferredCodePopupsProps} referredCodeConfirmation - Hide or show the popup to confirm referral code
 * @param {ReferredCodePopupsProps} referredCodeManual - Hide or show the popup to enter the referral code manually
 * @param {ReferredCodePopupsProps} referredInformation -  Hide or show the popup to information referral code
 * @param {ReferredCodePopupsProps} fullNameNewReference - Informs about the name of who the reference code belongs to
 * @param {ReferredCodePopupsProps} fetchingNewReference - Informs about the current status of the request to back
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const ReferredCodePopups = ({
  setReferredCodeConfirmation,
  setReferredCodeManual,
  setReferredCodeLocalStorage,
  setReferredInformation,
  referredCodeConfirmation,
  referredCodeManual,
  referredInformation,
  fullNameNewReference,
  fetchingNewReference
}: ReferredCodePopupsProps) => {

  const handleRejectionReferredCode = () => {
    localStorage.removeItem('referredCode')
    setReferredCodeConfirmation(false)
    setReferredCodeManual(false)
  }
  
  const handleConfirmationReferredCode = () => {
    setReferredCodeConfirmation(false)
    setReferredInformation(true)
  }
  
  const referredCodeInformationPopup = () => {
    return (
      <PopupWithIcon
      title='REFERRED'
      open={referredInformation}
      handleClose={() => setReferredInformation(false)}
      lineUp
      classNameTitle='confirmation-referred-popup-title'
      className='confirmation-referred-popup'
    >
      <div className='confirmation-referred-text-container mt-0'>
        <p>{intl.getHTML('REFERRED_INFORMATION_1')}</p>
        <p>{intl.getHTML('REFERRED_INFORMATION_2')}</p>
        <p>{intl.getHTML('REFERRED_INFORMATION_3')}</p>
      </div>
      <div className='confirmation-referred-button-container'>
        <button
          className='button-primary col-md-5 mr-md-2 mb-3 col-sm-8'
          onClick={() => setReferredInformation(false)}
        >
          {intl.get('GO_TO_REGISTRATION')}
        </button>
      </div>
    </PopupWithIcon>
    )
  }
  
  const referredCodeConfirmationPopup = () => {
    return (
      <PopupWithIcon
        title='REFERRED'
        open={referredCodeConfirmation}
        handleClose={() => handleRejectionReferredCode()}
        icon={referredIcon}
        lineUp
        classNameTitle='confirmation-referred-popup-title'
        className='confirmation-referred-popup'
      >
        <div className='confirmation-referred-text-container'>
          <p>{intl.getHTML('REFERRED_FULL_NAME',{ full_name: fullNameNewReference })}</p>
          <p>{intl.get('KNOW_PERSON')}</p>
        </div>
        <div className='confirmation-referred-button-container'>
          <button
            className='button-primary col-md-5 mr-md-2 mb-3 col-sm-8'
            onClick={() => handleConfirmationReferredCode()}
          >
            {intl.get('REFERRED_CONFIRMATION_BUTTON')}
          </button>
          <button
            className='button-five col-md-5 col-sm-8'
            onClick={() => handleRejectionReferredCode()}
          >
            {intl.get('REFERRED_REJECTION_BUTTON')}
          </button>
        </div>
      </PopupWithIcon>
    )
  }
  
  const referredCodeManualPopup = () => {
    return (
      <PopupWithIcon
        title='REFERRED'
        open={referredCodeManual}
        handleClose={() => handleRejectionReferredCode()}
        lineUp
        classNameTitle='confirmation-referred-popup-title m-0'
        className='confirmation-referred-popup referred-code-popup'
      >
      <ManualReferredCodeForm
        handleRejectionReferredCode={handleRejectionReferredCode}
        setReferredCodeLocalStorage={setReferredCodeLocalStorage}
        fetchingNewReference={fetchingNewReference}
      />
    </PopupWithIcon>
    )
  }

  return (
    <>
      {referredCodeConfirmationPopup()}
      {referredCodeInformationPopup()}
      {referredCodeManualPopup()}
    </>
  )
}

export default ReferredCodePopups
