export const ChangeVisitStart = () => {
  return {
    type: 'SUBMIT_CHANGE_HOUR_STARTED',
  }
}

export const ChangeVisitSuc = (result: any) => {
  return {
    type: 'SUBMIT_CHANGE_HOUR_SUCCESS',
    payload: { result },
  }
}

export const ChangeVisitError = (error: any) => {
  return {
    type: 'SUBMIT_CHANGE_HOUR_ERROR',
    payload: {
      error,
    },
  }
}
