import React from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import Input from '../../input'
import { DatePicker } from '../..'
import SwitchString from '../../switchString'
import intl from 'react-intl-universal'
import {
  EMPLOYEE_OPTIONS,
  BANK_ACCOUNT_OPTIONS,
  BOOLEAN_OPTIONS,
  RELATIONSHIP_OPTIONS,
  FAMILY_GROUP,
} from '../../../../tango-react-base/helpers/selectOptions/options'
import Select from '../../select'
import { IncomeData } from './IncomeData.type'
import RadioButtonTango from '../../radioButtonTango'
import FormCosignee from './formValidates'
import { validate as validaterut, format } from 'rut.js'
import MapAutoCompleteProfile from '../../mapAutoCompleteProfiles'
interface IncomeDataFormConsigneeProps {
  onSubmit: any
  idUser?: string
  children: any
  disabled: boolean
  keyNumber?: number
  data: any
  countriesCodes: any
  fetchingCodes: string
  onGetCountriesCodes: () => void
  requiredInCosignee?: any
  bankResource: any
}

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18)

const InnerForm: React.FunctionComponent<
  IncomeDataFormConsigneeProps & FormikProps<IncomeData>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  keyNumber = 0,
  countriesCodes,
  onGetCountriesCodes,
  fetchingCodes,
  data, // the countries from the api
  requiredInCosignee,
  bankResource,
}) => {
  const BANK_RESOURCE = bankResource
  return (
    <div>
      <Form className='pd-1'>
        {values.profile_type === 'cosigner' && (
          <>
            <div
              className='template-line'
              style={{ backgroundColor: `#A8123E` }}
            />
            <h2 className='new-subtitle'>{intl.get('CONSIGNEE_FORM')}</h2>
            <Select
              options={RELATIONSHIP_OPTIONS}
              id='original_renter_relationship'
              onChange={handleChange}
              label={'RENTER_RELATIONSHIP'}
              value={values.original_renter_relationship}
              disabled={disabled}
              classNameSelect={
                disabled
                  ? 'col-12 select-style disabled'
                  : 'col-12 select-style'
              }
            />
            {values.original_renter_relationship === 'OTHER' && (
              <Input
                id='renter_relationship_other'
                label={'RENTER_RELATIONSHIP'}
                type='text'
                value={values.renter_relationship_other}
                error={errors.renter_relationship_other}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.renter_relationship_other}
                disabled={disabled}
              />
            )}
            <SwitchString
              value={values.living_in_property}
              options={BOOLEAN_OPTIONS}
              name='living_in_property'
              label='LIVE_IN_THE_PROPERTY'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              disabled={disabled}
            />

            <Input
              id='names'
              label='NAMES'
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.names.trim()}
              error={errors.names}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.names}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <Input
              id='first_surname'
              label='FIRST_SURNAME'
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.first_surname.trim()}
              error={errors.first_surname}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.first_surname}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <Input
              id='second_surname'
              label='SECOND_SURNAME'
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.second_surname.trim()}
              error={errors.second_surname}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.second_surname}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <Input
              id='rut'
              label='RUT'
              type='text'
              maxLength={12}
              required={!requiredInCosignee ? false : true}
              value={format(values.rut)}
              error={errors.rut}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.rut}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <DatePicker
              name='birth_date'
              disableToolbar={disabled}
              value={values.birth_date}
              label='BIRTHDATE'
              maxDate={oneYearFromNow}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              info={intl.get('MUST_BE_OVER_18')}
            />

            <Select
              options={data}
              id='country_of_residence'
              onChange={handleChange}
              label={'COUNTRY_OF_RESIDENCE'.toUpperCase()}
              value={values.country_of_residence}
              disabled={disabled}
              classNameInput='col-md-6 p-sm-0'
              classNameSelect={
                disabled
                  ? 'col-12 select-style disabled'
                  : 'col-12 select-style'
              }
              resource={true}
            />

            <Select
              options={data}
              id='nationality'
              onChange={handleChange}
              label={'NATIONALITY'.toUpperCase()}
              value={values.nationality}
              disabled={disabled}
              classNameInput='col-md-6 p-sm-0'
              classNameSelect={
                disabled
                  ? 'col-12 select-style disabled'
                  : 'col-12 select-style'
              }
              resource={true}
            />
            <MapAutoCompleteProfile
              values={values.street_address}
              id={'street_address'}
              label={'EXACT_ADDRESS'}
              touched={touched.street_address}
              type='text'
              required={!requiredInCosignee ? false : true}
              disabled={disabled}
              error={errors.street_address}
              allValues={values}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              countrySelected={values.country_of_residence}
              address={values.street_address}
              info={'SELECT_ADDRESS'}
            />
            <Input
              id='street_address_extra'
              label='STREET_ADDRESS_EXTRA'
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.street_address_extra}
              error={errors.street_address_extra}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.street_address_extra}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <Input
              id='email'
              label={'EMAIL'}
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.email.toLocaleLowerCase().trim()}
              error={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              requiredMessage='Email is required'
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
            />

            <div className='col-12 p-0 text-left'>
              <div className='row col-12 p-0'>
                <div className='col-4 p-0'>
                  <label className='text-color-secondary'>
                    {intl.get('PHONE_NUMBER').toUpperCase()}
                  </label>
                </div>
                <div className='col-2 p-0'>
                  {fetchingCodes === 'FETCHED_CODES' ? (
                    <Select
                      options={countriesCodes}
                      id='countryCode'
                      onChange={handleChange}
                      value={values.countryCode}
                      disabled={disabled}
                      classNameInput='col-md-12 col-12 plm-0 pr-0'
                      classNameSelect={'col-12 select-no-border'}
                      resource={true}
                      className='m-0 pr-0'
                    />
                  ) : (
                    <select className='col-12 select-no-border'>
                      <option label='---'></option>
                    </select>
                  )}
                  <div className='template-line-input' />
                </div>
                <div className='col-4 p-0'>
                  <Input
                    id='phone_number'
                    required={!requiredInCosignee ? false : true}
                    type='text'
                    value={values.phone_number}
                    error={errors.phone_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.phone_number}
                    disabled={disabled}
                    className='col-12 mt-1 '
                    classNameInput='col-md-12 p-sm-0'
                    variant={false}
                  />
                </div>
              </div>
            </div>

            <RadioButtonTango
              id='family_group'
              options={FAMILY_GROUP}
              required={!requiredInCosignee ? false : true}
              label='FAMILY_GROUP'
              valueChecked={values.family_group}
              onChange={handleChange}
              disabled={disabled}
              keyNumber={keyNumber}
              classNameInputContainer='col-md-8 pl-0'
            />
            <Input
              id='ocupation'
              label='OCUPATION'
              type='text'
              required={!requiredInCosignee ? false : true}
              value={values.ocupation}
              error={errors.ocupation}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.ocupation}
              disabled={disabled}
              className='my-4'
              classNameInput='col-md-6 p-sm-0'
              justifyCenter='align-items-center'
            />
            <RadioButtonTango
              id='employee'
              required={!requiredInCosignee ? false : true}
              options={EMPLOYEE_OPTIONS}
              label='EMPLOYEE'
              valueChecked={values.employee}
              onChange={handleChange}
              disabled={disabled}
              keyNumber={keyNumber}
              classNameInputContainer='col-md-8 pl-0'
            />
            {values.employee === 'dependent' ||
            values.employee === 'independent' ? (
              <>
                <Input
                  id='employee_age'
                  label='YEARS_EMPLOYEE'
                  type='number'
                  min='0'
                  value={values.employee_age}
                  error={errors.employee_age}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.employee_age}
                  disabled={disabled}
                  className='my-4'
                  classNameInput='col-md-6 col-12 plm-0'
                  justifyCenter='align-items-center'
                >
                  <span className='span-text'>{intl.get('YEAR')}</span>
                </Input>
              </>
            ) : null}

            <SwitchString
              value={values.bank_account}
              options={BOOLEAN_OPTIONS}
              name='bank_account'
              label='BANK_ACCOUNT'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              disabled={disabled}
            />
            {values.bank_account === 'yes' && (
              <>
                <RadioButtonTango
                  id='bank_account_type'
                  options={BANK_ACCOUNT_OPTIONS}
                  label='BANK_ACCOUNT_TYPE'
                  valueChecked={values.bank_account_type}
                  onChange={handleChange}
                  disabled={disabled}
                  keyNumber={keyNumber}
                  className='my-4'
                  classNameInput='col-md-6 p-sm-0'
                />

                <Select
                  options={BANK_RESOURCE}
                  id='bank_name'
                  onChange={handleChange}
                  label={'BANK_NAME'}
                  value={values.bank_name}
                  disabled={disabled}
                  classNameInput='col-md-6 p-sm-0'
                  resource={true}
                  classNameSelect={
                    disabled
                      ? 'col-12 select-style disabled'
                      : 'col-12 select-style'
                  }
                />
              </>
            )}

            {children}
          </>
        )}
      </Form>
    </div>
  )
}

const IncomeDataFormConsignee = withFormik<
  IncomeDataFormConsigneeProps,
  IncomeData
>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      country_of_residence: process.env.REACT_APP_DEFAULT_COUNTRY,
      phone_number: props.phone_number.substr(3, props.phone_number.length),
      countryCode: props.phone_number.substr(0, 3),
    }
    return {
      ...props,
      ...initialValues,
      idUser: props.idUser,
      onSubmit: props.onSubmit,
      last_payment_due: props.last_payment_due
        ? props.last_payment_due
        : new Date(),
      report_date: props.report_date ? props.report_date : new Date(),
    }
  },

  validateOnChange: false,
  validationSchema: FormCosignee,

  handleSubmit(
    values: IncomeData,

    { props, setFieldTouched, setSubmitting, setErrors },
  ) {
    if (values.phone_number.toString().indexOf(values.countryCode) === -1) {
      values.phone_number = values.countryCode + values.phone_number
    }
    props.onSubmit(values)
  },
})(InnerForm)

export default IncomeDataFormConsignee
