export const updateScheduleConfigStart = () => {
    return {
        type: 'UPDATE_SCHEDULE_STARTED',
    };
};

export const updateScheduleConfigSuc = (res: any) => {
    return {
        type: 'UPDATE_SCHEDULE_SUCCESS',
        payload: res,
    };
};

export const updateScheduleConfigError = (error: any) => {
    return {
        type: 'UPDATE_SCHEDULE_ERROR',
        payload: {
            error,
        },
    };
};
