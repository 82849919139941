import React, { useEffect } from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { isRequired } from '../../helpers/fieldValidations';
import { Field } from 'formik';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import { includeInstapagePath } from '../../helpers/routesForInstapage';
import { Select, MenuItem } from '@material-ui/core';
import { useCurrencyConvertor } from '../../hooks';
import { isRoleAdmin } from '../../helpers/roleComparison';
/**
 * SelectInputPriceTypes defines the property types for documentation
 * @interface SelectInputPriceTypes
 * @type {(number | function | Array<any> | Array<number> | string | boolean)}
 * @prop {string} className - Is a string
 * @prop {string} classNameInput - Is a string
 * @prop {string} classNameLabel - Is string
 * @prop {string} currencyIsoValue - Is a string
 * @prop {Array<any>} currencyOptions - Is an Array of any
 * @prop {boolean} disabled - Is a boolean
 * @prop {string} error - Is a string
 * @prop {string} id - Is a string
 * @prop {string} idCurrency - Is a string
 * @prop {string} info - Is a string
 * @prop {boolean} isVerifier - Is a boolean
 * @prop {string} justifyCenter - Is a string
 * @prop {string} label - Is a string
 * @prop {string} messageAdditionalValidation - Is a string
 * @prop {function} onBlur - Is a function
 * @prop {function} onChange -Is a function
 * @prop {function} onClick - Is a function
 * @prop {string} placeholderIntl - Is a string
 * @prop {string} placeholder -Is a string
 * @prop {boolean} required - Is a boolean
 * @prop {boolean} requiredInstapage - Is a boolean
 * @prop {string} requiredMessage - Is a string
 * @prop {function} touched - Is a function
 * @prop {string | number} value - Is a any
 */
interface SelectInputPriceProps {
    applyGrayStyle?: boolean;
    className?: string;
    classNameInput?: string;
    classNameLabel?: string;
    classNameSelect?: string;
    classNameSelectInput?: string;
    currencyIsoValue: string;
    currencyOptions: Array<any>;
    disabled?: boolean;
    error?: string;
    id: string;
    idCurrency: string;
    info?: string;
    isVerifier?: boolean;
    justifyCenter?: string;
    label?: string;
    messageAdditionalValidation: string;
    onBlur?: any;
    onChange: any;
    onClick?: any;
    placeholder?: string;
    placeholderIntl?: string;
    required?: boolean;
    requiredInstapage?: boolean;
    requiredMessage?: string;
    setViewCLFInfo?: any;
    touched?: any;
    value: any;
    classNameCurrency?: string;
    labelUpperCase?: boolean;
    noBorderRadius?: boolean;
}

const borderColor = includeInstapagePath() ? '#FFFFFF' : '#A8123E';

/**
 * SelectInputPrice is a functional component which has many props. The main function of this component is to render a currency selector
 * and input that allows you to enter a value, and validations relevant to the case.
 *@function
 *@param {SelectInputPriceTypes} currencyIsoValue - Returns a currency
 *@param {SelectInputPriceTypes} currencyOptions - Returns an array of currency
 *@param {SelectInputPriceTypes} id - Identifier of the input field in the form
 *@param {SelectInputPriceTypes} idCurrency - Identifier of the selector field in the form
 *@param {SelectInputPriceTypes} info - Information in the forms located under the label
 *@param {SelectInputPriceTypes} messageAdditionalValidation - Information in the forms located under the input
 *@param {SelectInputPriceTypes} value - Returns a currency amount
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const SelectInputPrice = ({
    applyGrayStyle = false,
    classNameCurrency = 'col-4 col-sm-3 col-lg-4',
    className,
    classNameInput = '',
    classNameLabel = 'input-label-container',
    classNameSelect = '',
    classNameSelectInput = 'input-select-container',
    currencyIsoValue = 'CLP',
    currencyOptions,
    disabled = false,
    error,
    idCurrency,
    info,
    isVerifier = false,
    justifyCenter = 'align-items-start',
    label,
    messageAdditionalValidation = '',
    onBlur,
    onChange,
    onClick,
    placeholder,
    placeholderIntl,
    requiredInstapage = false,
    requiredMessage,
    setViewCLFInfo,
    touched,
    value,
    labelUpperCase = false,
    noBorderRadius = false,
    ...inputProps
}: SelectInputPriceProps) => {
    const [priceValidation, , ElementPrice] = useCurrencyConvertor({
        messageAdditionalValidation,
        currencyIsoValue,
        value,
        minimunPrice: 200000,
    });
    const { currentLocale } = intl.getInitOptions();
    useEffect(() => {
        if (setViewCLFInfo) {
            setViewCLFInfo(currencyIsoValue === 'CLF');
        }
    }, [currencyIsoValue]);

    const noBorders = noBorderRadius ? ' no-border-radius' : '';

    /** applySelectStyle: This function returns depending on the conditions the style that will have Select
     * @function
     */
    const applySelectStyle = () => {
        if (applyGrayStyle) {
            if (priceValidation) return 'select-currency select-currency-alert';
            else return 'select-currency';
        } else return 'select-currency-color-secondary';
    };

    /** applyInputStyle: This function returns depending on the conditions the style that will have Input
     * @function
     */
    const applyInputStyle = () => {
        if (applyGrayStyle) {
            if (priceValidation) return `price-validation-enabled ${classNameInput}`;
            else return `price-validation ${classNameInput}`;
        } else return 'price-validation-color-secondary';
    };

    return (
        <Field name={inputProps.id} validate={!!requiredMessage && isRequired(requiredMessage)}>
            {({ field, form }: any) => {
                const changeValue = (value: any) => {
                    form.setFieldValue(inputProps.id, value.floatValue);
                };
                return (
                    <>
                        <div onClick={onClick} className={`row input-container d-flex ${justifyCenter} ${className}`}>
                            {label !== undefined && label !== '' && (
                                <div className={classNameLabel}>
                                    <p
                                        className={
                                            includeInstapagePath()
                                                ? 'text-white-bold'
                                                : applyGrayStyle
                                                ? 'input-color-tertiary'
                                                : 'span-text'
                                        }
                                    >
                                        {`${
                                            labelUpperCase ? intl.get(`${label}`).toUpperCase() : intl.get(`${label}`)
                                        }${isVerifier || requiredInstapage ? '*' : ''}`}
                                    </p>
                                    <p className='p-info-label'>
                                        {currencyIsoValue === 'CLF' && info && intl.getHTML(`${info}`)}
                                    </p>
                                </div>
                            )}
                            <div className={`${classNameSelectInput}`}>
                                <div className='d-flex'>
                                    <div
                                        className={`input-container d-flex align-items-start ${classNameCurrency} p-0 ${classNameSelect}`}
                                    >
                                        <Select
                                            IconComponent={ExpandMoreIcon}
                                            labelId={`${idCurrency}-label-select`}
                                            value={currencyIsoValue}
                                            onChange={onChange(`${idCurrency}`)}
                                            id={idCurrency}
                                            className={applySelectStyle() + noBorders}
                                        >
                                            {currencyOptions.map((option, index) => (
                                                <MenuItem
                                                    value={option.value}
                                                    key={`${label}-${option.label}-${index}`}
                                                >
                                                    {intl.get(`${option.label}`)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <NumberFormat
                                        allowNegative={false}
                                        className={applyInputStyle() + noBorders}
                                        decimalScale={2}
                                        decimalSeparator={currentLocale === 'en-US' ? '.' : ','}
                                        defaultValue={value ? value : ''}
                                        disabled={disabled}
                                        helperText={error && intl.get(error)}
                                        id={inputProps.id}
                                        onValueChange={(value) => changeValue(value)}
                                        placeholder={placeholder}
                                        required={inputProps.required}
                                        thousandSeparator={currentLocale === 'en-US' ? ',' : '.'}
                                    />
                                </div>
                                {priceValidation ? (
                                    <div className='text col-12 pl-0'>
                                        <p
                                            className={`${
                                                includeInstapagePath() ? 'text-color-white' : 'text-color-error'
                                            } small m-0`}
                                        >
                                            <span className='information-price error-validation'>
                                                {priceValidation}
                                            </span>
                                        </p>
                                    </div>
                                ) : (
                                    <div className='text col-12 pl-0'>
                                        <p
                                            className={`${
                                                includeInstapagePath() ? 'text-color-white' : 'text-color-error'
                                            } small m-0`}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: ElementPrice }} />
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

export default SelectInputPrice;
