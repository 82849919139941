import { isRoleOwner, isRoleRenter } from '../../../helpers/roleComparison/index';
export const SubmitCalendarStart = (date: any) => {
  if (isRoleOwner) {
    return {
      type: 'SUBMIT_CALENDAR_STARTED_A',
      payload: { date },
    }
  } else {
    return {
      type: 'SUBMIT_CALENDAR_STARTED',
      payload: { date },
    }
  }
}

export const SubmitCalendarSuc = (result: any) => {
  if (
    isRoleRenter
  ) {
    return {
      type: 'SUBMIT_CALENDAR_SUCCESS_RENTER',
      payload: { result },
    }
  } else if (
    isRoleOwner
  ) {
    return {
      type: 'SUBMIT_CALENDAR_SUCCESS_A',
      payload: { result },
    }
  } else {
    return {
      type: 'SUBMIT_CALENDAR_SUCCESS_A',
      payload: { result },
    }
  }
}

export const SubmitCalendarError = (error: any) => {
  return {
    type: 'SUBMIT_CALENDAR_ERROR_A',
    payload: {
      error,
    },
  }
}
