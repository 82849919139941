export const sendForm = () => {
    return {
        type: 'SEND_FORM_FOOTER',
    };
};

export const sendFormSuccess = (email: any) => {
    return {
        type: 'SEND_FORM_SUCCESS',
        payload: { email },
    };
};

export const sendFormError = (error: any) => {
    return {
        type: 'SEND_FORM_ERROR',
        payload: {
            error,
        },
    };
};
