export const pressNotificationStart = () => {
    return {
        type: 'SET_NOTIFICATION_STARTED',
    };
};
export const pressNotificationSuc = (data: any) => {
    return {
        type: 'SET_NOTIFICATION_SUCCESS',
        payload: data,
    };
};

export const pressNotificationError = (error: any) => {
    return {
        type: 'NOTIFICATION_ERROR',
        payload: {
            error,
        },
    };
};
