import { multipartPost } from '../../../tango-react-base/helpers/apiConnections';
import { uploadAnnexStart, uploadAnnexSuccess, uploadAnnexError } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
import { getOffersRented, getRestitutions } from '../index';

const uploadAnnex = (visitId: any, data: any, url: string, typeTable: string) => {
    let formData = new FormData();
    typeTable === 'pending_delivered'
        ? formData.append('delivery_voucher[file]', data)
        : formData.append('restoration_voucher[file]', data);
    return (dispatch: any) => {
        dispatch(uploadAnnexStart(visitId));
        multipartPost(url, formData)
            .then((result) => {
                toast(intl.get('DOCUMENT_SUCCESSFULLY_UPLOADED'));
                dispatch(uploadAnnexSuccess(result.data, visitId));

                if (typeTable === 'pending_delivered') {
                    dispatch(getOffersRented('pending'));
                    dispatch(getOffersRented('approved'));
                } else {
                    dispatch(getRestitutions('pending'));
                    dispatch(getRestitutions('approved'));
                }
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(uploadAnnexError(e, visitId));
            });
    };
};
export default uploadAnnex;
