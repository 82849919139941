import * as Yup from 'yup'

export const FormRegister = Yup.object().shape({
  phone_number: Yup.string().min(9).required(),
  names: Yup.string().required(),
  surnames: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  repeat_password: Yup.string().required(),
})

export const NewFormRegister = Yup.object().shape({
  email: Yup.string().email().required('IS_REQUIRED'),
  password: Yup.string().required('IS_REQUIRED'),
  repeat_password: Yup.string().required('IS_REQUIRED'),
})
