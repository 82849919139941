import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import accessValueByString from '../../helpers/accessValueByString';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { isRoleOwner, isRoleRenter } from '../../helpers/roleComparison';
export class Option {
    value: string;
    label: string;
    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}

export interface OptionsInterface {
    value: string;
    label: string;
}

export interface CheckBoxProps {
    options: Array<OptionsInterface>;
    label: string;
    name: string;
    disabled: boolean;
    classNameContainer?: string;
}
const CheckBox = ({ options, label, name, disabled, classNameContainer = 'col-9' }: CheckBoxProps) => {
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const routesForColorTertiary = ['login', 'about-us', 'sign_up', 'forget_password', 'new-unit-creation'];
    const colorTertiaryPathIncluded = routesForColorTertiary.includes(currentLocation);

    const colorStyle = (isRoleRenter || isRoleOwner) && colorTertiaryPathIncluded;

    const handleChange = (actualValue: Array<any>, option: any, form: any) => {
        if (actualValue.includes(option.value)) {
            const nextValue = actualValue.filter((value: string) => value !== option.value);
            form.setFieldValue(name, nextValue);
        } else {
            const nextValue = actualValue.concat(option.value);
            form.setFieldValue(name, nextValue);
        }
    };

    return (
        <Field>
            {({ field, form }: any) => {
                let actualValue = accessValueByString(field.value, name);
                if (!actualValue) {
                    form.setFieldValue(name, []);
                }
                return (
                    <div className='checkbox-container row'>
                        {label.trim.length > 0 && <span className='col-3 p-0'> {intl.get(label)} </span>}
                        <div className={`options-wrap d-flex flex-column ${classNameContainer}`}>
                            <div className='row'>
                                {options.map((option, index) => (
                                    <div className='form-check form-check-inline' key={`opt-${index}-${option.label}`}>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            checked={actualValue && actualValue.includes(option.value)}
                                            id={`inlineCheck-${name}-${index}`}
                                            onChange={() => handleChange(actualValue, option, form)}
                                            disabled={disabled}
                                        />
                                        <label
                                            className={`form-check-label ${
                                                !colorStyle
                                                    ? 'text-color-secondary-inline'
                                                    : 'text-color-tertiary-inline'
                                            }`}
                                            htmlFor={`inlineCheck-${name}-${index}`}
                                        >
                                            {intl.get(option.label)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            }}
        </Field>
    );
};

export default CheckBox;
