import React, { useEffect } from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import intl from 'react-intl-universal';
import InputSearch from './inputSearch';

interface SearchProps {
    label: string;
    placeholderText: string;
    setSearchField: any;
    onGetData: any;
    parameterOnGetData: string;
}

interface SearchForm {
    search: string;
}

const InnerForm: React.FunctionComponent<SearchProps & FormikProps<SearchForm>> = ({
    values,
    handleChange,
    handleSubmit,
    label,
    placeholderText,
    setSearchField,
    onGetData,
    parameterOnGetData,
}) => {
    useEffect(() => {
        if (values.search === '') {
            onGetData(parameterOnGetData);
            setSearchField('');
        }
    }, [values.search]);

    return (
        <div className='search-container my-4'>
            <div className='search-card-container center-search'>
                <Form className='row-search' autoComplete='off'>
                    <div className='container-25 '>
                        <p className='user-search-label'>{label}</p>
                    </div>
                    <div className='container-50 mb-2'>
                        <div className='input-center-search no-line-bottom'>
                            <InputSearch
                                id='search'
                                value={values.search}
                                label={placeholderText}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className='container-25 button-color d-flex flex-column justify-content-center align-items-center'>
                        <button className='btn-user-search' type='submit' onClick={() => handleSubmit()}>
                            {intl.get('SEARCH')}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
const Search = withFormik<SearchProps, SearchForm>({
    mapPropsToValues: (props: any) => {
        return {
            search: props.search,
        };
    },

    handleSubmit(values: SearchForm, { props }) {
        props.setSearchField(values.search);
    },
})(InnerForm);

export default Search;
