export const uploadVideoStart = (title: string) => {
  return {
    type: 'UPLOAD_VIDEO_STARTED',
    payload: { title }
  }
}

export const uploadVideoSuccess = (video: any, title: string) => {
  return {
    type: 'UPLOAD_VIDEO_SUCCESS',
    payload: { video, title },
  }
}

export const uploadVideoError = (error: any) => {
  return {
    type: 'UPLOAD_VIDEO_ERROR',
    payload: { error },
  }
}