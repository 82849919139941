import * as Yup from 'yup';

export const validateEstimatePriceAmountYup = (clf: any) => {
    const minimumPrice = 200000;
    const maximumPrice = 5000000;
    const minimumPriceCLF = Number(Math.abs(minimumPrice / clf).toFixed(2));
    const maximumPriceCLF = Number(Math.abs(maximumPrice / clf).toFixed(2));
    return Yup.number()
        .required('REQUIRED_FIELD')
        .nullable()
        .when('estimate_price_currency', {
            is: 'CLP',
            then: Yup.number().min(minimumPrice).max(maximumPrice),
        })
        .when('estimate_price_currency', {
            is: 'CLF',
            then: Yup.number().min(minimumPriceCLF).max(maximumPriceCLF),
        });
};

export const validateEstimatePriceAmount = (clf: any, estimatePriceAmount: any, estimatePriceCurrency: any) => {
    const minimumPrice = 200000;
    const maximumPrice = 5000000;
    const minimumPriceCLF = Number(Math.abs(minimumPrice / clf).toFixed(2));
    const maximumPriceCLF = Number(Math.abs(maximumPrice / clf).toFixed(2));

    if (estimatePriceCurrency === 'CLP') {
        return estimatePriceAmount >= minimumPrice && estimatePriceAmount <= maximumPrice;
    } else {
        return estimatePriceAmount >= minimumPriceCLF && estimatePriceAmount <= maximumPriceCLF;
    }
};
