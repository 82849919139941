import React, { FC, useState } from 'react';
import intl from 'react-intl-universal';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { TableSortLabel } from '@material-ui/core';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
    order: any,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface PropsBody {
    index: number;
    arrayId: any;
    redirectTo: () => void;
    row: Object;
    styleCenteredText: string;
}

const RenderBody: FC<PropsBody> = ({ index, arrayId, redirectTo, row, styleCenteredText }) => {
    return (
        <TableRow
            key={index}
            onClick={() => arrayId && redirectTo()}
            className={`table-height ${arrayId && 'pointer'}`}
        >
            {Object.entries(row).map(([key, value]: any) => (
                <TableCell key={`table-content-${key}`} className={`${styleCenteredText}`}>
                    {value}
                </TableCell>
            ))}
        </TableRow>
    );
};

interface TableTangoProps {
    listTitle: Array<any>;
    listBody?: Array<any> | undefined;
    arrayId?: Array<any> | any;
    redirecTo?: string;
    children?: React.ReactNode;
    rowperPage?: number;
    rowsPerPageOptions?: Array<number>;
    elevation?: number;
    paginator?: boolean;
    minHeight?: number;
    verticalCentered?: boolean;
    sortByHeader?: boolean;
    disableSort?: boolean;
    maxHeightBody?: string;
}

/**
 * Here the type of each Table is defined, this prop is similar to 'TableTangoProps' but 'TableTangoTypes' is for the documentation
 *  @typedef TableTangoTypes
 *  @type {(children | string | number)}
 *  @property {array} listTitle - is an Array.
 *  @property {array} listBody - is an Array.
 *  @property {array} arrayId, - is an Array.
 *  @property {string} redirecTo, - is an String.
 *  @property {children} children - is a Children.
 *  @property {number} rowperPage, - is a number.
 *  @property {array} rowsPerPageOptions - is an array.
 *  @property {number} elevation - is a number.
 *  @property {number} minHeight - is a number.
 *  @property {boolean} paginator - is a boolean.
 *  @property {children} children - is a Children.
 *  @property {children} sortByHeader - is a boolean
 *  @property {boolean} verticalCentered - is a boolean.
 *  @property {boolean} disableSort - is a boolean.
 *  @property {boolean} maxHeightBody - is an string.
 */

/**
 * TableTango is a functional component with props.
 * The main function of this component is to render the tables in general.
 *  @function
 *  @param {TableTangoTypes}  listTitle - It is an array with all the titles of the header
 *  @param {TableTangoTypes} arrayId, - It is an array with all id
 *  @param {TableTangoTypes} listBody - It is an array with all rows
 *  @param {TableTangoTypes} children - return children with de rows body
 *  @param {TableTangoTypes} redirecTo - route
 *  @param {TableTangoTypes} rowperPage - is the quantity table row per page, if it is empty the user can select the quantity rows per page
 *  @param {TableTangoTypes} rowsPerPageOptions - is the option of the select quantity rows, if it is empty the select option is showed
 *  @param {TableTangoTypes} elevation - paper lift, values ​​1-3
 *  @param {TableTangoTypes} minHeight - min height
 *  @param {TableTangoTypes} paginator - with or without paging
 *  @param {TableTangoTypes} verticalCentered - vertical centered
 *  @param {TableTangoTypes} sortByHeader - sort header by click
 *  @param {TableTangoTypes} disableSort - disable sort header
 *  @param {TableTangoTypes} maxHeightBody - max height body table
 *  @returns {(ReactComponent)} Returns a TableMui
 */

const TableTango = ({
    listTitle,
    listBody,
    redirecTo,
    children,
    arrayId,
    rowperPage = 5,
    rowsPerPageOptions = [5, 10, 15],
    minHeight,
    paginator = true,
    elevation = 1,
    verticalCentered = false,
    sortByHeader = false,
    disableSort = false,
    maxHeightBody = '',
}: TableTangoProps) => {
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowperPage);
    const [orderBy, setOrderBy] = useState(100);
    const [order, setOrder] = useState<any>('asc');

    const hasPaginatorTable = paginator
        ? listBody?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : listBody;

    const useStyles = makeStyles({
        container: {
            maxHeight: 550,
            minHeight: minHeight,
        },
    });
    const styleCenteredText = verticalCentered ? 'table-content-vertical-center' : 'table-content';

    const classes = useStyles();
    /** handleChangePage: This function handles number rows per page
     * @function
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    /** handleChangeRowsPerPage: This function handles paginator
     * @function
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    /** redirectTo: This function redirects to a new view
     * @function
     */
    const redirectTo = (key: number) => {
        history.push(`${redirecTo}/${key}`);
    };

    const createSortHandler = (index: number) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(index);
    };

    const handleRequestSort = (index: number) => {
        const isAsc = orderBy === index && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(index);
    };

    const dates = ['17/06/2022', '31/05/22', '17/05/2022', '18/06/2022'];

    return (
        <Paper elevation={elevation} className='table m-0 p-0'>
            <TableContainer className={classes.container} style={{ maxHeight: maxHeightBody }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {listTitle.map((title, index: number) => (
                                <TableCell
                                    style={{ width: title.sizes }}
                                    className={`table-header table-header-padding ${title.class}`}
                                    key={index}
                                >
                                    {sortByHeader && title.name !== 'NAME' && !disableSort ? (
                                        <TableSortLabel
                                            active
                                            direction={orderBy === index ? order : 'asc'}
                                            onClick={createSortHandler(index)}
                                            className={
                                                orderBy === index ? 'arrow-table-active' : 'arrow-table-disabled'
                                            }
                                        >
                                            {intl.get(title.name)}
                                        </TableSortLabel>
                                    ) : (
                                        intl.get(title.name)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listBody ? (
                            !listBody.length ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={listTitle.length + 1}
                                        className={`text-center ${styleCenteredText}`}
                                    >
                                        {intl.get('NO_DATA')}
                                    </TableCell>
                                </TableRow>
                            ) : sortByHeader ? (
                                stableSort(hasPaginatorTable || [], getComparator(order, orderBy)).map(
                                    (row: any, index: number) => (
                                        <RenderBody
                                            key={index}
                                            index={index}
                                            arrayId={arrayId?.length}
                                            redirectTo={() => redirectTo(arrayId[index])}
                                            row={row}
                                            styleCenteredText={styleCenteredText}
                                        />
                                    ),
                                )
                            ) : (
                                hasPaginatorTable?.map((row: any, index: number) => (
                                    <RenderBody
                                        key={index}
                                        index={index}
                                        arrayId={arrayId?.length}
                                        redirectTo={() => redirectTo(arrayId[index])}
                                        row={row}
                                        styleCenteredText={styleCenteredText}
                                    />
                                ))
                            )
                        ) : (
                            children
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!!listBody?.length && paginator && (
                <>
                    <Hidden smUp>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component='div'
                            count={listBody.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Hidden>
                    <Hidden smDown>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component='div'
                            count={listBody.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonText={intl.get('PREVIOUS_PAGE')}
                            labelRowsPerPage={intl.get('ROWS_PER_PAGE')}
                            nextIconButtonText={intl.get('NEXT_PAGE')}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Hidden>
                </>
            )}
        </Paper>
    );
};

export default TableTango;
