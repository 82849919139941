import {
  validateEmailPresenceStart,
  validateEmailPresenceSuccess,
  validateEmailPresenceError,
} from './types'
import { getData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
import intl from 'react-intl-universal'
import { setSession, createBasicUser, resendRegistrationEmail } from '../../index'
import { isRoleOwner, isRoleRenter } from '../../../helpers/roleComparison/index';

const validateEmailPresence = (values: any, emailField?: boolean, path?: string, history?: any) => {
  const email = values.email.replace('+', '%2B')
  const isPathLogin = path === 'login'
  return (dispatch: any) => {
    dispatch(validateEmailPresenceStart())
    getData(`auth/users/user_lookup?user[email]=${email}`)
      .then((result: any) => {
        dispatch(validateEmailPresenceSuccess(result.data))

        if(isRoleOwner){
          const { register_step } = result.data.data?.attributes || ''
          const options = ['unit-data', 'schedule-visit']
          // If the user exists, register step is ' unit-data' or 'schedule-visit' and the user is in the 'new-unit-creation' path
          // A toast is shown when the user is guided to the step
          if (options.includes(register_step) && !isPathLogin) {
            toast(intl.get('CONTINUE_FROM_THIS_STEP'))
            // If the user exists, register step is ' unit-data' or 'schedule-visit' and the user is in the login form path
            // A toast is shown while redirecting them to the 'new-unit-creation' path
          } else if (options.includes(register_step) && isPathLogin) {
            toast(intl.get('REGISTRATION_STEP_PENDING'))
            history.push('/new-unit-creation')
            // If the user exists, register step is 'completed' and the user is in the login form path
            // A toast is shown informing that the user needs to finish their registration
            // resendRegistrationEmail is dispatch which send the user an email to finish the process
          } else if (register_step === 'completed' && isPathLogin) {
            toast(intl.get('REGISTRATION_PENDING'))
            dispatch(resendRegistrationEmail(values.email))
            // The default behaviour is to login so the setSession action is dispatched
          } else if (path === 'login') {
            dispatch(setSession(values))
          }
        }

      })
      .catch((e: any) => {
        const errors = { ...e }
        const { response } = errors || ''
        const { status } = response || ''
        // If the user is not found (it does not exist in the database) and is in the 'new-unit-creation' path
        // the createBasicUser action is dispatched to create the user
        if(isRoleOwner){
          if (status === 404 && !emailField && !isPathLogin ) {
            dispatch(createBasicUser(values))
            // If the user is not found (it does not exist in the database) and is in the 'login' path
            // A toast is shown informing that the user does not exist
          } else if (404 && emailField) {
            toast(intl.get('USER_DOES_NOT_EXIST'))
          } else {
            toast(handleError(e))
          }
        }
        return dispatch(validateEmailPresenceError(isRoleOwner ? e : intl.get('USER_DOES_NOT_EXIST')))
      })
  }
}
export default validateEmailPresence
