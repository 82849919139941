import React, { useState, useEffect } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import es from 'date-fns/esm/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { calendar } from '../../resources/index';
import ToggleButton from '@material-ui/lab/ToggleButton';
import intl from 'react-intl-universal';
import { CircularLoading } from '..';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#A8123E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);
const today = moment();

type CalendarProps = {
    onChangeDate: any;
    isDisabled: any;
    isSelected: any;
    blockType: any;
    dayDate: any;
    fetchingBlocks: any;
    blocksTaken: any;
    onSubmit: any;
    isScheduledId: any;
};

export const CalendarMobileAgent = ({
    onChangeDate,
    isDisabled,
    isSelected,
    blockType,
    dayDate,
    fetchingBlocks,
    blocksTaken,
    onSubmit,
    isScheduledId,
}: CalendarProps) => {
    let threeMonths = moment().add(3, 'month');

    const [selectedDate, setSelectedDate] = useState(today.startOf('week'));
    const [showBlocks, setShowBlocks] = useState(false);

    useEffect(() => {
        if (!showBlocks && selectedDate) {
            setShowBlocks(true);
        }
    }, [selectedDate]);

    const classBtnSchedule = (action: any) => {
        return isDisabled(action) && disabledBtn ? 'toggle-btn-schedule-mobile' : 'available';
    };

    const disabledBtn = selectedDate.day() !== 0 && threeMonths > selectedDate && selectedDate > today;

    const DaysBlock = () =>
        showBlocks ? (
            <div className='calendar-mobile-visit'>
                {blockType.map(({ name, block, action }: any) => {
                    const aasm_state = isScheduledId(action(selectedDate)).length > 0 ? 'scheduled' : '';
                    return (
                        <div className={`${classBtnSchedule(action(selectedDate))}`}>
                            <ToggleButton
                                value='check'
                                selected={isSelected(action(selectedDate))}
                                disabled={!disabledBtn || !showBlocks}
                                onChange={() => {
                                    onChangeDate(action(selectedDate), aasm_state, isScheduledId(action(selectedDate)));
                                }}
                                className={`h-sm-4 mt-1 mr-2 ${!disabledBtn && 'btn-disabled'} ${classBtnSchedule(
                                    dayDate(selectedDate),
                                )}`}
                            >
                                <p className='columns m-0'>
                                    <span>{intl.get(name.toUpperCase())}</span>
                                    <br />
                                    <span>{block.split(' ').join('')}</span>
                                </p>
                            </ToggleButton>
                        </div>
                    );
                })}
            </div>
        ) : (
            <></>
        );
    const disableWeekends = (date: any) => {
        return date.getDay() === 0 || threeMonths < date;
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(moment(date));
        // isSelected(dayDate(date)
        setShowBlocks(true);
    };

    let now = moment();

    return (
        <>
            {fetchingBlocks === 'FETCHED_BLOCKS' ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <Grid container justify='space-around'>
                        <BootstrapInput
                            margin='normal'
                            disableToolbar={true}
                            id='date-picker-dialog'
                            variant='static'
                            format='dd/MM/yyyy'
                            value={selectedDate}
                            minDate={now}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            shouldDisableDate={disableWeekends}
                            keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='Calendar' />}
                        />
                        <DaysBlock />
                    </Grid>
                </MuiPickersUtilsProvider>
            ) : (
                <CircularLoading />
            )}
        </>
    );
};
