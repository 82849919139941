import React, { useEffect, useState } from 'react';
import {
    leaseRenewalIcon,
    newPriceIcon,
    noLeaseRenewalIcon,
    clock,
    phoneCall,
    leaseOffer,
    sendIcon,
} from '../../resources';
import { ButtonLoading, Input, PopupWithIcon, SelectInputPrice } from '../index';
import intl from 'react-intl-universal';
import { Form } from 'formik';
import { formatNumber } from '../../helpers/numberFormat';
import { isRoleOwner } from '../../helpers/roleComparison/index';
import Carousel from 'react-multi-carousel';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { capitalize } from '../../helpers/capitalize/index';
import { connect } from 'react-redux';
import { getCurrentRenewalPeriod, getCLF } from '../../reduxActions';
import { CURRENCY_OPTIONS } from '../../../tango-react-base/helpers/selectOptions/options';
import { validateEstimatePriceAmount } from '../../../tango-react-base/helpers/validateEstimatePriceAmount';
import { useCurrencyConvertor, useCurrencyFormater } from '../../../tango-react-base/hooks';

/** Here the types of the props for the interface 'RenewalOptionsCardProps' are defined
 * @typedef RenewalOptionsCardTypes
 * @type {(function|boolean||number|string|React.ReactNode|object)}
 * @property {boolean} disableButton - is a boolean.
 * @property {function} handleChange - is a function.
 * @property {function} onSubmit - is a function.
 * @property {number} proposedPrice - is a number.
 * @property {number} inflation - is a number.
 * @property {number} fetchingAcceptance - is a string.
 * @property {number} priceProposalStatus - is a string.
 * @property {string} currentCurrency - is a string
 * @property {React.ReactNode} children - is a TSX element.
 */

interface RenewalOptionsCardProps {
    children?: React.ReactNode;
    clf: any;
    disableButton: boolean;
    fetchingAcceptance: string;
    handleChange: (e: any) => void;
    inflation?: number;
    inflationWithFormat?: string;
    onGetCLF: any;
    onGetCurrentRenewalPeriod: (id: number) => void;
    onSubmit: (e: any) => void;
    priceProposalStatus: string;
    currentCurrency?: string;
    proposedPrice?: number;
    renewalConfirmation: boolean;
    renewalPeriod: any;
    renewalPeriodId: number;
    setRenewalConfirmation: (state: boolean) => void;
    status: string;
    statusRejectLeaseRenewal: string;
    values: {
        option: string;
        proposed_price?: any;
        reason: string;
        currency?: any;
    };
    validationInflationZero?: boolean;
}

/**
 * RenewalOptionsCard is a functional component that renders a a number of option cards for owner and renter
 *@function
 *@param {PopupWithIconTypes} disableButton - state that validates the disabling of a button
 *@param {PopupWithIconTypes} handleChange - function that reacts to input change
 *@param {PopupWithIconTypes} children - TSX elements
 *@param {PopupWithIconTypes} values - object that contains form options
 *@param {PopupWithIconTypes} onSubmit - function that submits the form
 *@param {PopupWithIconTypes} proposedPrice - number for price value
 *@param {PopupWithIconTypes} inflation - number for percentage difference
 *@param {PopupWithIconTypes} fetchingAcceptance - string that displays the fetching status when owner accepts the lease renewal
 *@param {PopupWithIconTypes} priceProposalStatus - string that displays the fetching status when owner proposes new lease price
 *@param {PopupWithIconTypes} currentCurrency - string that informs the value of the current state in which the currency
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const RenewalOptionsCard = ({
    children,
    clf,
    disableButton,
    fetchingAcceptance,
    handleChange,
    inflation,
    inflationWithFormat,
    onGetCLF,
    onGetCurrentRenewalPeriod,
    onSubmit,
    priceProposalStatus,
    currentCurrency,
    proposedPrice = 0,
    renewalConfirmation,
    renewalPeriod,
    renewalPeriodId,
    setRenewalConfirmation,
    status,
    statusRejectLeaseRenewal,
    values,
    validationInflationZero = false,
}: RenewalOptionsCardProps) => {
    const currentCurrencyUpperCase = currentCurrency?.toUpperCase();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [newPriceModal, setNewPriceModal] = useState(false);
    const [renewalRejectionModal, setRenewalRejectionModal] = useState(false);
    const [priceValidation, setPriceValidation] = useState(false);
    const [priceConfirmationModal, setPriceConfirmationModal] = useState(false);
    const [rejectionWarningPopup, setRejectionWarningPopup] = useState(false);
    const [rejectionConfirmationPopup, setRejectionConfirmationPopup] = useState(false);
    const [rejectionSentModal, setRejectionSentModal] = useState(false);
    const fetchingStates =
        statusRejectLeaseRenewal === 'STARTED' ||
        priceProposalStatus === 'SENDING' ||
        fetchingAcceptance === 'FETCHING';

    const size = useWindowSize();
    const {
        global_state: globalState,
        aasm_negotiation: negotiationState,
        type_proposed_price_by_owner: ownerProposalType,
    } = renewalPeriod.attributes || 0;
    const negotiationStates = ['negotiating_waiting_renter', 'negotiating_waiting_owner'];

    const [formatCurrency] = useCurrencyFormater({
        currency: values.currency,
        proposedPrice: values.proposed_price,
    });

    const renewOption = values.option === 'renew';
    const setOptionValue = (currentSlide: number) => {
        if (currentSlide === 0) {
            values.option = 'renew';
        } else if (currentSlide === 1 && negotiationState !== 'renter_price_counterproposed') {
            values.option = 'proposeNewPrice';
        } else {
            values.option = 'doNotRenew';
        }
    };
    /* This Hook convert the values ​​that come by default or from a proposal
     It is used in the text reference value
  */
    const [, priceConversion, ,] = useCurrencyConvertor({
        currencyIsoValue: currentCurrencyUpperCase,
        value: proposedPrice,
    });

    useEffect(() => {
        if (statusRejectLeaseRenewal === 'SUCCESS') {
            setRejectionSentModal(true);
        }
    }, [statusRejectLeaseRenewal]);

    useEffect(() => {
        if (status === 'NO_FETCH') {
            onGetCLF();
        }
    }, [status]);

    /* This useEffect enable the button to send the price proposed form.
     Use the values ​​that come from the form.
     values.proposed_price is a new price proposed
     values.currency is a new currency
  */
    useEffect(() => {
        const proposedPrice = values.proposed_price;
        const currency = values.currency;
        setPriceValidation(validateEstimatePriceAmount(clf, proposedPrice, currency));
    }, [values]);

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 300,
            },
            items: 1,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464,
            },
            items: 1,
        },
    };
    const secondOwnerOptions = [
        {
            title: intl.get('ACCEPT_NEW_PRICE'),
            id: 'renew',
            icon: leaseRenewalIcon,
        },
        {
            title: intl.get('REJECT_PROPOSED_PRICE'),
            id: 'doNotRenew',
            icon: noLeaseRenewalIcon,
            adminText: intl.get('AN_ADMIN_WILL_CONTACT_YOU_LATER'),
        },
    ];
    // It is used with the values ​​that come by default or from a proposal.
    const mainOptions = [
        {
            title: intl.get('RENEW_WITH_PROPOSED_PRICE'),
            id: 'renew',
            price: `${currentCurrencyUpperCase === 'CLP' ? '$' : 'UF'} ${proposedPrice && formatNumber(proposedPrice)}`,
            inflation:
                currentCurrencyUpperCase === 'CLP'
                    ? intl.getHTML('INFLATION_EXPLANATION', {
                          variation:
                              inflation && capitalize(intl.get(inflation > 0 ? 'INCREASE_TEXT' : 'DECREASE_TEXT')),
                          percentage: inflationWithFormat,
                      })
                    : null,
            priceDifference: !validationInflationZero
                ? intl.getHTML('PRICE_DIFFERENCE', {
                      variation: inflation && capitalize(intl.get(inflation > 0 ? 'INCREASE_TEXT' : 'DECREASE_TEXT')),
                      percentage: inflationWithFormat,
                  })
                : null,
            icon: leaseRenewalIcon,
            conversion:
                currentCurrencyUpperCase === 'CLF'
                    ? intl.getHTML('REF_VALUE_INFO_NO_PARENTHESES', {
                          price: priceConversion,
                      })
                    : null,
        },
        {
            title: intl.get('PROPOSE_NEW_PRICE'),
            id: 'proposeNewPrice',
            icon: newPriceIcon,
            waitResponseText: intl.get('WAIT_RESPONSE_TEXT', {
                other_part: isRoleOwner ? intl.get('PART_RENTER') : intl.get('PART_OWNER'),
            }),
        },
        {
            title: intl.get('NO_RENEW_LEASE_AGREEMENT'),
            id: 'doNotRenew',
            icon: noLeaseRenewalIcon,
            restitutionText: intl.get('RESTITUTION_WARNING'),
            adminText: intl.get('AN_ADMIN_WILL_CONTACT_YOU_LATER'),
        },
    ];
    const handleSubmission = (e: any) => {
        e.preventDefault();
        if (values.option === 'doNotRenew') {
            setRenewalRejectionModal(true);
        } else if (values.option === 'proposeNewPrice') {
            setNewPriceModal(true);
        } else {
            onSubmit(e);
        }
    };
    const NewPriceModal = () => {
        return (
            <PopupWithIcon
                title='PROPOSE_A_PRICE'
                open={newPriceModal}
                handleClose={() => {
                    setNewPriceModal(false);
                }}
                icon={leaseOffer}
                lineUp
            >
                <div className={`new-price-container ${size.width > 768 ? 'desktop' : 'mobile'}`}>
                    <div className='new-price-input-container'>
                        <div className='row'>
                            <p className='new-price-input-label col'>{intl.get('NEW_PRICE')}</p>
                        </div>
                        <SelectInputPrice
                            className='input-container-class'
                            classNameInput='mt-0'
                            classNameSelect='mt-0'
                            classNameSelectInput='input-class p-0'
                            currencyIsoValue={values.currency}
                            currencyOptions={CURRENCY_OPTIONS}
                            id='proposed_price'
                            idCurrency='currency'
                            messageAdditionalValidation='MINIMUM_PRICE'
                            onChange={handleChange}
                            value={values.proposed_price}
                            applyGrayStyle
                        />
                    </div>
                </div>
                <button
                    className='button-primary col-6 col-md-6'
                    disabled={!priceValidation}
                    onClick={() => {
                        setPriceConfirmationModal(true);
                        setNewPriceModal(false);
                    }}
                >
                    {intl.get('CONFIRM')}
                </button>
            </PopupWithIcon>
        );
    };
    const rejectionModal = () => {
        return (
            <PopupWithIcon
                title='REJECTION_REASONS'
                open={renewalRejectionModal}
                handleClose={() => {
                    setRenewalRejectionModal(false);
                }}
                lineUp
            >
                <div className='text-left'>
                    <p className='font-weight-bold'>
                        {intl.get(
                            negotiationState === 'renter_price_counterproposed'
                                ? 'WE_ARE_SORRY_COUNTERPROPOSAL_REJECTION'
                                : 'WE_ARE_SORRY',
                        )}
                    </p>
                    <p>{intl.get('TELL_YOUR_REASONS')}</p>
                    <small>{intl.get('WE_REMIND_YOU')}</small>
                    <div className='row mt-2'>
                        <Input
                            id='reason'
                            label=''
                            type='text'
                            value={values.reason}
                            onChange={handleChange}
                            placeholder={intl.get('INDICATE_YOUR_REASONS')}
                            requiredMessage='Property comments is required'
                            multiline={true}
                            rows={5}
                            classNameInput='col p-0'
                            classNameLabel='col-6'
                            className='w-100 p-0'
                            classNameMultiline='col'
                        />
                    </div>
                </div>
                <button
                    className='button-primary col-md-6'
                    disabled={!values.reason}
                    onClick={() => {
                        setRenewalRejectionModal(false);
                        setRejectionWarningPopup(true);
                    }}
                >
                    {intl.get('CONFIRM')}
                </button>
            </PopupWithIcon>
        );
    };

    const RejectionModals = () => {
        return (
            <>
                <PopupWithIcon
                    open={rejectionWarningPopup}
                    handleClose={() => setRejectionWarningPopup(false)}
                    title={
                        negotiationState === 'renter_price_counterproposed' ? 'MEDIATION_PROCESS' : 'RENEWAL_REFUSAL'
                    }
                    icon={noLeaseRenewalIcon}
                    lineUp
                >
                    <>
                        <p className='popup-paragraph'>
                            {intl.get(
                                negotiationState === 'renter_price_counterproposed'
                                    ? 'MEDIATION_CONFIRMATION'
                                    : 'ARE_YOU_SURE_TO_REJECT',
                            )}
                        </p>
                        {negotiationState === 'renter_price_counterproposed' && (
                            <p className='text-left-with-margin'>{intl.get('AN_ADMIN_WILL_CONTACT_YOU')}</p>
                        )}
                        <div className='mt-4'>
                            <button
                                className='button-tertiary col-md-5 mr-3 mb-2'
                                onClick={() => setRejectionWarningPopup(false)}
                            >
                                {intl.get('CANCEL')}
                            </button>
                            <button
                                className='button-primary col-md-5'
                                onClick={(e) => {
                                    setRejectionWarningPopup(false);
                                    onSubmit(e);
                                }}
                            >
                                {intl.get('CONTINUE')}
                            </button>
                        </div>
                    </>
                </PopupWithIcon>
                <PopupWithIcon
                    open={rejectionConfirmationPopup}
                    handleClose={() => {
                        setRejectionConfirmationPopup(false);
                        onGetCurrentRenewalPeriod(renewalPeriodId);
                    }}
                    title={
                        negotiationState === 'renter_price_counterproposed'
                            ? 'RENEWAL_REJECTION_SENT_MEDIATION'
                            : 'RENEWAL_REJECTION_SENT'
                    }
                    icon={sendIcon}
                    lineUp
                >
                    <>
                        <p className='mt-4 text-left'>{intl.get('WE_HAVE_SENT_YOUR_INFORMATION')}</p>
                    </>
                </PopupWithIcon>
                <PopupWithIcon
                    open={rejectionSentModal}
                    handleClose={() => {
                        setRejectionSentModal(false);
                        onGetCurrentRenewalPeriod(renewalPeriodId);
                    }}
                    title={
                        negotiationState === 'renter_price_counterproposed'
                            ? 'RENEWAL_REJECTION_SENT_MEDIATION'
                            : 'RENEWAL_REJECTION_SENT'
                    }
                    icon={sendIcon}
                    lineUp
                >
                    <>
                        <p className='mt-4 text-left'>{intl.get('WE_HAVE_SENT_YOUR_INFORMATION')}</p>
                    </>
                </PopupWithIcon>
            </>
        );
    };

    /* Use the values ​​that come from the form.
     values.proposed_price is a new price proposed
     values.currency is a new currency
  */
    const NewPriceConfirmationModal = () => {
        return (
            <PopupWithIcon
                title='NEW_LEASE_PROPOSAL'
                open={priceConfirmationModal}
                handleClose={() => {
                    setPriceConfirmationModal(false);
                }}
                lineUp
            >
                <div className='col-12'>
                    <h2 className='text-color-secondary font-weight-bold py-3'>{formatCurrency}</h2>
                    <p>{intl.get('ARE_YOU_SURE_TO_PROPOSE_ADMIN')}</p>
                </div>
                <button
                    className='button-primary col-md-6'
                    disabled={!priceValidation}
                    onClick={(e) => {
                        onSubmit(e);
                        setPriceConfirmationModal(false);
                    }}
                >
                    {intl.get('SEND')}
                </button>
                <div className='text-center col-12 mt-2'>
                    <p
                        className='underline-link'
                        onClick={() => {
                            setPriceConfirmationModal(false);
                            setNewPriceModal(true);
                        }}
                    >
                        {intl.get('BACK')}
                    </p>
                </div>
            </PopupWithIcon>
        );
    };
    const ConfirmationPopup = () => {
        return (
            <PopupWithIcon
                title={
                    renewOption && negotiationStates.includes(globalState) ? 'RENEWAL_SENT' : 'RENEWAL_PROPOSAL_SENT'
                }
                open={renewalConfirmation}
                handleClose={() => {
                    setRenewalConfirmation(false);
                    onGetCurrentRenewalPeriod(renewalPeriodId);
                }}
                icon={sendIcon}
                lineUp
            >
                <div className='confirmation-text-container'>
                    {renewOption && negotiationStates.includes(globalState) ? (
                        <p>{intl.get('THANKS_FOR_CONTINUING_WITH_US')}</p>
                    ) : (
                        <p>
                            {intl.get('WAIT_FOR_OTHER_PART', {
                                other_part: isRoleOwner ? intl.get('PART_RENTER') : intl.get('PART_OWNER'),
                            })}
                        </p>
                    )}
                    <p>{intl.get('ADMIN_COMPANY')}</p>
                </div>
            </PopupWithIcon>
        );
    };
    const ResponseButton = () => {
        return (
            <>
                <Form className='lease-renewal-container' onSubmit={handleSubmission}>
                    {NewPriceModal()}
                    <div className='submit-button-container'>
                        <button className='button-primary col-md-4' disabled={disableButton}>
                            {fetchingStates ? <ButtonLoading /> : intl.get('CONFIRM')}
                        </button>
                    </div>
                </Form>
            </>
        );
    };
    const cardOptions = isRoleOwner && globalState === 'negotiating_waiting_owner' ? secondOwnerOptions : mainOptions;
    return (
        <>
            {children}
            {size.width < 768 ? (
                <div className='item-carousel-container show-arrows mx-auto'>
                    <Carousel
                        containerClass='container-with-dots'
                        showDots
                        deviceType='desktop'
                        infinite={false}
                        keyBoardControl={true}
                        arrows={false}
                        swipeable
                        responsive={responsive}
                        afterChange={(previousSlide, { currentSlide }) => {
                            setOptionValue(currentSlide);
                            setCurrentSlide(currentSlide);
                        }}
                    >
                        {cardOptions.map((option: any, index: number) => (
                            <div className={`renewal-card m-0 ${currentSlide === index ? 'active' : ''}`}>
                                <label
                                    className={`renewal-card-input-container ${cardOptions.length === 2 ? 'px-3' : ''}`}
                                >
                                    {globalState !== 'negotiating_waiting_owner' && index === 0 && (
                                        <div className='diagonal badge red'>{intl.get('RECOMMENDED')}</div>
                                    )}
                                    <input
                                        type='radio'
                                        name='option'
                                        id={option.id}
                                        className='mr-3'
                                        value={option.id}
                                        onChange={handleChange}
                                        checked={values.option === option.id}
                                    />
                                    <div className='p-1'>
                                        <img src={option.icon} className='m-2' alt='leaseRenewalIcon' width='45' />
                                        <h2 className='font-weight-bold'>{option.title}</h2>
                                        {option.price && (
                                            <p className='price-suggestion'>
                                                {intl.get('NEW_PRICE')}
                                                <span className='font-weight-bold text-color-secondary'>
                                                    {option.price}
                                                </span>
                                            </p>
                                        )}
                                        {option.conversion && <p className='second-price'>{option.conversion}</p>}
                                        {option.inflation &&
                                            (ownerProposalType === 'inflation' || !ownerProposalType) && (
                                                <p className='px-2 my-3'>{option.inflation}</p>
                                            )}
                                        {option.priceDifference &&
                                            (ownerProposalType === 'no_inflation' || !isRoleOwner) && (
                                                <p className='px-2 my-3'>{option.priceDifference}</p>
                                            )}
                                        {option.waitResponseText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard'>{option.waitResponseText}</p>
                                            </div>
                                        )}
                                        {option.restitutionText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard'>{option.restitutionText}</p>
                                            </div>
                                        )}
                                        {option.adminText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={phoneCall} alt='phone-icon' width='30' />
                                                <p className='font-standard'>{option.adminText}</p>
                                            </div>
                                        )}
                                        {isRoleOwner && index === 0 && globalState !== 'negotiating_waiting_owner' && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard '>
                                                    {intl.get('WAIT_RESPONSE_TEXT', {
                                                        other_part: intl.get('PART_RENTER'),
                                                    })}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                        ))}
                    </Carousel>
                </div>
            ) : (
                <div className='renewal-cards-container'>
                    {cardOptions.map((option: any, index: number) => (
                        <div className='col-md-4' key={index}>
                            <div
                                className={`renewal-card ${values.option === option.id ? 'active' : ''} ${
                                    !values.option && index === 0 ? 'intitial-border' : ''
                                } ${cardOptions.length === 2 ? 'renewal-card-response' : ''}`}
                            >
                                <label
                                    className={`${
                                        cardOptions.length === 2 ? 'd-flex pt-3' : 'renewal-card-input-container'
                                    }`}
                                >
                                    {globalState !== 'negotiating_waiting_owner' && index === 0 && (
                                        <div className='diagonal badge red'>{intl.get('RECOMMENDED')}</div>
                                    )}
                                    <input
                                        type='radio'
                                        name='option'
                                        id={option.id}
                                        className='renewal-option'
                                        value={option.id}
                                        onChange={handleChange}
                                        checked={values.option === option.id}
                                    />
                                    <div className='p-1'>
                                        <img src={option.icon} className='m-2' alt='leaseRenewalIcon' width='45' />
                                        <h2 className='font-weight-bold'>{option.title}</h2>
                                        {option.price && (
                                            <p className='price-suggestion'>
                                                {intl.get('NEW_PRICE')}
                                                <span className='font-weight-bold text-color-secondary'>
                                                    {option.price}
                                                </span>
                                            </p>
                                        )}
                                        {option.conversion && <p className='second-price'>{option.conversion}</p>}
                                        {option.inflation &&
                                            (ownerProposalType === 'inflation' || !ownerProposalType) && (
                                                <p className='px-2 my-3'>{option.inflation}</p>
                                            )}
                                        {option.priceDifference &&
                                            ownerProposalType === 'no_inflation' &&
                                            !isRoleOwner && <p className='px-2 my-3'>{option.priceDifference}</p>}
                                        {option.waitResponseText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard'>{option.waitResponseText}</p>
                                            </div>
                                        )}
                                        {isRoleOwner && index === 0 && globalState !== 'negotiating_waiting_owner' && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard'>
                                                    {intl.get('WAIT_RESPONSE_TEXT', {
                                                        other_part: intl.get('PART_RENTER'),
                                                    })}
                                                </p>
                                            </div>
                                        )}
                                        {option.restitutionText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={clock} alt='clock-icon' width='30' />
                                                <p className='font-standard'>{option.restitutionText}</p>
                                            </div>
                                        )}
                                        {option.adminText && (
                                            <div className='info-container'>
                                                <img className='info-img' src={phoneCall} alt='phone-icon' width='30' />
                                                <p className='font-standard'>{option.adminText}</p>
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {ResponseButton()}
            {rejectionModal()}
            <NewPriceConfirmationModal />
            <ConfirmationPopup />
            <RejectionModals />
        </>
    );
};
const mapStateToProps = (state: any) => {
    const { fetchingAcceptance } = state.acceptance;
    const { priceProposalStatus } = state.proposePrice;
    const { renewalPeriod } = state.renewalPeriod;
    const { status, clf } = state.CLFResource;
    return {
        statusRejectLeaseRenewal: state.rejectLeaseRenewal.status,
        fetchingAcceptance: fetchingAcceptance,
        priceProposalStatus: priceProposalStatus,
        renewalPeriod: renewalPeriod,
        status,
        clf,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCurrentRenewalPeriod: (id: number) => {
            dispatch(getCurrentRenewalPeriod(id));
        },
        onGetCLF: () => {
            return dispatch(getCLF());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RenewalOptionsCard);
