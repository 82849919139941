import axios from 'axios';

export default async (url: string, data?: any) => {
    const uri = `${process.env.REACT_APP_API_URL}/${url}`;
    const headers: any = {
        'content-type': 'multipart/form-data',
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };
    return axios.put(uri, data, { headers });
};
