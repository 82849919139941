import { multipartPost } from '../../../tango-react-base/helpers/apiConnections'
import { uploadVideoStart, uploadVideoSuccess, uploadVideoError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import intl from 'react-intl-universal'
const uploadVideo = (data: FormData, title: string) => {
  return (dispatch: any) => {
    dispatch(uploadVideoStart(title))
    multipartPost('videos', data)
      .then((result) => {
        dispatch(uploadVideoSuccess(result.data, title))
      })
      .catch((e) => {
        const errors = { ...e }
        const { request } = errors || ''
        request ? toast(intl.get('VIDEO_REQUEST_ERROR')) : toast(handleError(e))
        return dispatch(uploadVideoError(e))
      })
  }
}
export default uploadVideo
