import React, { useState } from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { withStyles, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';
import accessValueByString from '../../helpers/accessValueByString';
interface InputProps {
    label?: string;
    placeholder_intl?: string;
    bottomLabel?: string;
    id: string;
    placeholder?: string;
    onChange: any;
    value: any;
    name?: string;
    type?: string;
    error?: string;
    touched?: any;
    onBlur?: any;
    min?: string;
    max?: string;
    className?: string;
    requiredMessage?: string;
    multiline?: boolean;
    disabled: boolean;
    rows?: number;
    maxLength?: number | string;
    variant?: boolean;
    labelInput?: string;
    unit?: string;
    info?: string;
    classNameLabel?: string;
    classNameInput?: string;
    required?: boolean;
    currencyOption?: boolean;
    currencyOptionBtn?: boolean;
}

const Input = ({
    placeholder,
    touched,
    bottomLabel,
    placeholder_intl,
    label,
    className,
    error,
    requiredMessage,
    variant = true,
    unit,
    labelInput,
    info,
    multiline = false,
    classNameLabel = 'col-12',
    classNameInput = 'col-3',
    type = 'text',
    maxLength,
    id,
    value,
    currencyOption = false,
    currencyOptionBtn = false,
}: InputProps) => {
    const [valueMaxMin, setValueMaxMin] = useState({
        max: 0,
        min: 0,
    });
    const [currentCurrancy, setCurrentCurrancy] = useState('CLP');

    return (
        <Field>
            {({ field, form }: any) => {
                let actualValue = accessValueByString(field.value, id);
                if (!actualValue) {
                    if (id == 'current_price') {
                        field.value['range_min_price'] = 0;
                        field.value['range_max_price'] = null;
                        field.value['current_currency'] = currentCurrancy;
                    }
                    form.setFieldValue(id, [0, 0]);
                }
                const changeValue = (typeInput: number, val: any) => {
                    const changeValueConst = field.value[id];
                    changeValueConst[typeInput] = val.floatValue;
                    typeInput === 0 && setValueMaxMin({ ...valueMaxMin, min: val.floatValue });
                    typeInput === 1 && setValueMaxMin({ ...valueMaxMin, max: val.floatValue });
                    if (id == 'current_price' && typeInput === 0) {
                        field.value['range_min_price'] = val.floatValue;
                        field.value['current_currency'] = currentCurrancy;
                    } else if (id == 'current_price' && typeInput === 1) {
                        field.value['range_max_price'] = val.floatValue == 0 ? null : val.floatValue;
                        field.value['current_currency'] = currentCurrancy;
                    }
                    form.setFieldValue(id, changeValueConst);
                };

                let variantText: any = variant ? 'outlined' : undefined;

                return (
                    <div className={`d-flex row input-container ${className}`}>
                        {label !== undefined && (
                            <div className={classNameLabel + (currencyOptionBtn ? ' select-currancy-filter' : '')}>
                                <span className={`span-text`}>{intl.getHTML(`${label}`)}</span>
                                {currencyOptionBtn && (
                                    <div>
                                        <IconButton
                                            className={
                                                'p-1 mx-1 ' +
                                                (currentCurrancy === 'CLP' ? 'active-currency-filter' : '')
                                            }
                                            size='small'
                                            onClick={() => {
                                                setCurrentCurrancy('CLP');
                                            }}
                                        >
                                            $
                                        </IconButton>
                                        <IconButton
                                            className={
                                                'p-1 mx-1 ' +
                                                (currentCurrancy === 'CLF' ? 'active-currency-filter' : '')
                                            }
                                            size='small'
                                            onClick={() => {
                                                setCurrentCurrancy('CLF');
                                            }}
                                        >
                                            UF
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={`col-6 p-0 pr-1`}>
                            <NumberFormat
                                allowNegative={false}
                                decimalScale={2}
                                prefix={currencyOption ? (currentCurrancy === 'CLP' ? '$' : 'UF ') : ''}
                                className={
                                    'input-number-range ' +
                                    (valueMaxMin.max != 0 && valueMaxMin.max < valueMaxMin.min
                                        ? 'input-border-error'
                                        : '')
                                }
                                decimalSeparator={currentCurrancy === 'CLP' ? false : ','}
                                defaultValue={Array.isArray(value) ? value[0] : undefined}
                                id={`bootstrap-input-${id}`}
                                onValueChange={(val) => changeValue(0, val)}
                                placeholder={
                                    (currencyOption ? (currentCurrancy === 'CLP' ? '$ ' : 'UF ') : '') +
                                    intl.get('FROM')
                                }
                                required={false}
                                thousandSeparator={currentCurrancy === 'CLF' ? false : '.'}
                            />
                        </div>
                        <div className={`col-6 p-0 pl-1`}>
                            <NumberFormat
                                allowNegative={false}
                                decimalScale={2}
                                className={
                                    'input-number-range ' +
                                    (valueMaxMin.max != 0 && valueMaxMin.max < valueMaxMin.min
                                        ? 'input-border-error'
                                        : '')
                                }
                                prefix={currencyOption ? (currentCurrancy === 'CLP' ? '$' : 'UF ') : ''}
                                decimalSeparator={currentCurrancy === 'CLP' ? false : ','}
                                defaultValue={Array.isArray(value) ? value[1] : undefined}
                                id={`bootstrap-input-${id}`}
                                onValueChange={(val) => changeValue(1, val)}
                                placeholder={
                                    (currencyOption ? (currentCurrancy === 'CLP' ? '$ ' : 'UF ') : '') + intl.get('TO')
                                }
                                required={false}
                                thousandSeparator={currentCurrancy === 'CLF' ? false : '.'}
                            />
                        </div>
                        <div className={`col-12 p-0 pl-1`}>
                            {valueMaxMin.max !== 0 && valueMaxMin.max < valueMaxMin.min ? (
                                <span className={`error-text-helper`}>{intl.get(`ERROR_MAX_VALUE`)}</span>
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </Field>
    );
};

export default Input;
