import React from 'react';
import intl from 'react-intl-universal';

/**
 * ButtonLoading is a functional component which renders a loading circle together with a loading message
 *@function
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const ButtonLoading = () => {
    return (
        <div className='center-child-elements m-1'>
            <div className='spinner-border text-secondary' role='status' />
            <span className='ml-2 p-2'>{intl.get('LOADING')}</span>
        </div>
    );
};
export default ButtonLoading;
