import React from 'react'
import { useDropzone } from 'react-dropzone'
import '../../tango-react-base/scss/main.scss'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import uploadVideos from '../../redux/actions/uploadVideos'
import deleteVideos from '../../redux/actions/deleteVideos'
import { deleteLogo } from '../../tango-react-base/resources'
import { Tooltip } from '@material-ui/core'
import { CircularLoading } from '../../tango-react-base/components'

interface MultipleDropzoneVideoProps {
  postId: number
  onUploadVideo: any
  onDeleteVideo: (id: any, postId: number) => void
  videosUploading: Array<any>
  videos: Array<any>
  setVideoCounter: any
  videoCounter: number
  fetchVideo: string
}

const MultipleDropzoneVideo = ({
  postId,
  onUploadVideo,
  onDeleteVideo,
  videosUploading,
  videos,
  setVideoCounter,
  videoCounter,
  fetchVideo,
}: MultipleDropzoneVideoProps) => {
  function makeid(length: number) {
    let result = ''
    let randomNumber = new Uint8Array(0)
    crypto.getRandomValues(randomNumber)
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(randomNumber[0] % 62))
    }
    return result
  }

  const uploadVideo = (title: string, file: File) => {
    let form_data = new FormData()
    form_data.append('video[post_id]', `${postId}`)
    form_data.append('video[title]', title)
    form_data.append('video[file]', file)
    onUploadVideo(form_data, title)
    setVideoCounter(videoCounter + 1)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'video/*',
    maxSize: 7097152000,
    onDrop: (acceptedFiles: any) => {
      acceptedFiles.map((file: any) => {
        let index = makeid(15)
        uploadVideo(`${postId}-video-${index}`, file)
      })
    },
    disabled: videoCounter >= 10,
  })

  // TODO: Realizar la funcionalidad de eliminar vídeo
  const deleteVideo = (id: any, postId: number) => {
    if (fetchVideo === 'LOADED' || fetchVideo === 'NO_ERROR')
      onDeleteVideo(id, postId)
  }

  const allVideos = videos.map((file: any, index) => (
    <div className='thumb-inner img-container my-3' key={`file-${index}`}>
      <div className='tooltip-delete-logo text-right mr-3'>
        <Tooltip title={intl.get('DELETE_VIDEO')} placement='top' arrow>
          <img
            src={deleteLogo}
            alt='deleteLogo'
            className='delete-logo-img-video'
            onClick={() => deleteVideo(file.id, postId)}
          />
        </Tooltip>
      </div>
      <video className='img col grid-image' src={file.video_url} />
    </div>
  ))

  const textMultipleDropZoneVideo = videoCounter > 9 ? intl.get('DROP_VIDEOS_LIMIT') : intl.get('DROP_VIDEOS_HERE')
  
  return (
    <div className='multiple-dropzone-container'>
      <div className='template-line'></div>
      <h2 className='new-subtitle'>{intl.get('UPLOAD_VIDEOS')}</h2>
      <p className='col-12 p-info-label mb-4'>
        {intl.get('UPLOAD_VIDEOS_INFO')}
      </p>
      <section
        {...getRootProps({ className: 'dropzone' })}
        className='multiple-dropzone-box'
      >
        {videosUploading.length > 0 ? (
          <CircularLoading />
        ) : (
          <>
            <input {...getInputProps()} disabled={videoCounter > 9} />
            { isDragActive
              ? intl.get('DROP_FILE')
              : textMultipleDropZoneVideo}
          </>
        )}
      </section>
      <div className='thumbs-container'>{allVideos}</div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  postId: state.postValidation.post.id,
  videosUploading: state.postValidation.videosUploading,
  videosUploaded: state.postValidation.videosUploaded,
  videos: state.postValidation.videos,
  fetchVideo: state.postValidation.fetchVideo,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    onUploadVideo: (data: FormData, title: string) => {
      dispatch(uploadVideos(data, title))
    },
    onDeleteVideo: (id: any, postId: number) => {
      dispatch(deleteVideos(id, postId))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleDropzoneVideo)
