export const uploadFileStart = (fileIndex: string) => {
    return {
        type: 'UPLOAD_FILE_STARTED',
        payload: { fileIndex },
    };
};
export const uploadFileSuccess = (file: any, fileIndex: string) => {
    return {
        type: 'UPLOAD_FILE_SUCCESS',
        payload: { file, fileIndex },
    };
};
export const uploadFileError = (error: any, fileIndex: string) => {
    return {
        type: 'UPLOAD_FILE_ERROR',
        payload: {
            error,
            fileIndex,
        },
    };
};
