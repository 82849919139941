import React from 'react';
import { CardContainer } from '..';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import CardMountContainer from '../cardMountContainer';

interface ButtonProps {}

const MyButton = ({}: ButtonProps) => {
    let history = useHistory();
    return (
        <div className='card-view card-without-shadow col-md-6'>
            <div className='row'>
                <h2 className='title text-left'>{intl.get('PAGE_NOT_FOUND')}</h2>
            </div>
            <div className='template-line mb-5'></div>
            <div className='logo-padding'>
                <button className='button-primary col-12 mt-5 mx-sm-0' onClick={() => history.push('/')}>
                    {intl.get('BACK_HOME')}
                </button>
            </div>
        </div>
    );
};
export default MyButton;
