import {
  isRoleAdmin,
  isRoleTanguero,
  isRolePhotographer,
} from '../../../helpers/roleComparison/index'
export const verifierFormPermissions = () => {
  return isRolePhotographer || isRoleTanguero
}

export const adminFormPermissions = () => {
  return isRoleAdmin
}

export const verifierOrAdminPermissions = () => {
  return isRolePhotographer || isRoleAdmin || isRoleTanguero
}

export const propertyStatusPermissions = (status: string | undefined) => {
  return status && status !== 'created'
}

export const newRentedView = () => {
  return window.location.pathname === '/new-rented-unit'
}
