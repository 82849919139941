import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { CircularLoading, NotAvailableVisitInfo } from '..';
import { calendar } from '../../resources/index';
import es from 'date-fns/esm/locale/es';
import moment from 'moment';
import { isRoleOwner, isRoleRenter } from '../../helpers/roleComparison/index';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#A8123E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);

type CalendarMobileProps = {
    onSubmit: any;
    hourTaken: Array<any>;
    unitId?: any;
    id: any;
    setOpen: any;
    fetchingBlocks: any;
    blocksAvailable: any;
    onChangeDate: any;
    isSelected: any;
    dayDate: any;
    moment: any;
    history?: any;
    DialogModalInfo: any;
    ToggleButton: any;
    backArrow: any;
    blocksDate: any;
    blockType?: any;
    userId?: number;
    shortRegisterUser?: boolean;
    wasVisitScheduled?: boolean;
    resultAddNewUnit?: any;
    currentAC?: boolean;
    newWantToLease?: boolean;
    personalData?: Object;
};
const today = moment();

export const CalendarMobile = ({
    hourTaken,
    onSubmit,
    unitId,
    id,
    setOpen,
    fetchingBlocks,
    blocksAvailable,
    onChangeDate,
    ToggleButton,
    isSelected,
    dayDate,
    moment,
    history,
    DialogModalInfo,
    backArrow,
    blocksDate,
    blockType,
    userId,
    shortRegisterUser,
    wasVisitScheduled = false,
    resultAddNewUnit,
    currentAC = false,
    newWantToLease = false,
    personalData,
}: CalendarMobileProps) => {
    const [selectedDate, setSelectedDate] = useState(today);
    const [showBlocks, setShowBlocks] = useState(false);
    const { semantic_url, full_address } = resultAddNewUnit?.attributes || '';

    useEffect(() => {
        if (!showBlocks && selectedDate) setShowBlocks(true);
    }, [selectedDate]);

    const handleDateChange = (date: any) => {
        setShowBlocks(true);
        setSelectedDate(moment(date));
        isSelected(dayDate(date));
    };

    const DaysBlock = () =>
        showBlocks ? (
            <div className={`${newWantToLease ? 'small-button' : ''} mb-2`}>
                {blockType.map(({ name, block, action, show }: any) => {
                    const blocksAvailable = blocksDate(selectedDate, name.toLowerCase(), true)?.includes(false);
                    return (
                        <>
                            <ToggleButton
                                value='check'
                                selected={isSelected(dayDate(selectedDate))}
                                onClick={blocksAvailable && action}
                                disabled={!blocksAvailable}
                                onChange={() => {
                                    onChangeDate(dayDate(selectedDate));
                                }}
                                className={`col-12 toggle-button-hour ${!show && 'bg-transparent'}`}
                            >
                                <p className='columns btn-day-owner'>
                                    <span>{intl.get(name.toUpperCase())}</span>
                                    <br />
                                    <span>{block}</span>
                                </p>
                                {blocksAvailable && (
                                    <img src={backArrow} alt='arrow' className='arrow-section ml-2' width='10' />
                                )}
                            </ToggleButton>
                            <section className={!show ? 'd-none' : ''}>
                                {isSelected(dayDate(selectedDate)) ? (
                                    <div className='list-hours-new'>
                                        {show && blocksDate(selectedDate, name.toLowerCase())}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </section>
                        </>
                    );
                })}
            </div>
        ) : (
            <></>
        );

    let tenDays = moment().add(13, 'day');
    const disableWeekends = (date: any) => {
        return date.getDay() === 0 || tenDays < date;
    };

    const now = moment();
    const maxDate = moment(now).add(1, 'week').add(2, 'days');

    const classValidation = `mt-3 ${!!blocksAvailable.length ? '' : 'w-100 d-flex justify-content-center'}`;

    return (
        <div
            className={`${currentAC ? 'bg-calendar-instapage' : ''} ${
                newWantToLease ? 'w-100 d-flex justify-content-center' : 'row'
            } p-0`}
        >
            <div className={classValidation}>
                <DialogModalInfo />

                {blocksAvailable.length > 0 && fetchingBlocks === 'FETCHED_BLOCKS' && (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                            <Grid container justify='space-around'>
                                <BootstrapInput
                                    margin='normal'
                                    disableToolbar={true}
                                    id='date-picker-dialog'
                                    variant='static'
                                    format='dd/MM/yyyy'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    minDate={now}
                                    maxDate={maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    shouldDisableDate={disableWeekends}
                                    keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='Calendar' />}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <DaysBlock />
                        {!newWantToLease && (
                            <section>
                                <div>
                                    <button
                                        className='button-primary btn-block mb-3'
                                        onClick={() => {
                                            onSubmit(unitId, setOpen, id, userId, history);
                                        }}
                                        disabled={hourTaken.length < 1}
                                    >
                                        {process.env.REACT_APP_API_ROLE_APP === process.env.REACT_APP_RENTEREQUAL
                                            ? intl.get('SCHEDULE_TOUR')
                                            : intl.get('SCHEDULE_VISIT')}
                                    </button>
                                </div>
                                {!currentAC && (
                                    <span className='text-short-register small'>
                                        {intl.getHTML('OWNER_HELP_TO_SCHEDULE_VISIT')}
                                    </span>
                                )}
                            </section>
                        )}
                    </>
                )}

                {blocksAvailable.length === 0 &&
                    fetchingBlocks === 'FETCHED_BLOCKS' &&
                    (shortRegisterUser ? (
                        <NotAvailableVisitInfo />
                    ) : !isRoleRenter ? (
                        <p>{intl.get('SCHEDULE_NOT_AVAILABLE')}</p>
                    ) : (
                        <NotAvailableVisitInfo personalData={personalData} />
                    ))}

                {wasVisitScheduled && <span>{intl.get('SCHEDULED_VISIT')}</span>}
            </div>
        </div>
    );
};
