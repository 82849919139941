export const deletePhotoStart = (photo: any, type: any) => {
    return {
        type: 'DELETE_PHOTO_STARTED',
        payload: { photo, type },
    };
};

export const deletePhotoSuccess = (photo: any, type: any) => {
    return {
        type: 'DELETE_PHOTO_SUCCESS',
        payload: { photo, type },
    };
};

export const deletePhotoError = (error: any, type: any) => {
    return {
        type: 'DELETE_PHOTO_ERROR',
        payload: { error, type },
    };
};
