import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { getNewTokenStarted, getNewTokenSuccess, getNewTokenError } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const getNewToken = (email: string) => {
    const role = process.env.REACT_APP_ROLE;
    const data = {
        user: {
            email: email,
            app: role,
        },
    };
    return (dispatch: any) => {
        dispatch(getNewTokenStarted());
        putData(`auth/users/generate_confirmation_token`, data)
            .then((result) => {
                dispatch(getNewTokenSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getNewTokenError(e));
            });
    };
};
export default getNewToken;
