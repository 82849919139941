import React from 'react';
import { Field } from 'formik';
import { FormControl, Select, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import intl from 'react-intl-universal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { includeInstapagePath } from '../../helpers/routesForInstapage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

interface Option {
    value: number;
    label?: string;
}

interface SelectProps {
    options: Array<any>;
    onChange: any;
    label?: string;
    id: string;
    value: number | string;
    className?: string;
    disabled: boolean;
}

const SelectComponent = ({ options, className, label, value, onChange, ...inputProps }: SelectProps) => {
    const classes = useStyles();
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const notNewUnitCreationView = currentLocation !== 'new-unit-creation';

    return (
        <Field>
            {({ field, form }: any) => {
                return (
                    <FormControl className={`${classes.formControl} ${className}`}>
                        <Select
                            IconComponent={ExpandMoreIcon}
                            labelId={`${inputProps.id}-label`}
                            value={value}
                            onChange={onChange(`${inputProps.id}`)}
                            id={inputProps.id}
                            disableUnderline={notNewUnitCreationView}
                            className={
                                includeInstapagePath() && currentLocation !== 'search-instapage' ? 'white-select' : ''
                            }
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={`${label}-${option.label}-${index}`}>
                                    {intl.get(`${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        </Field>
    );
};

export default SelectComponent;
