export const prioritizePhotosStart = () => {
    return {
      type: 'PRIORITIZE_PHOTOS_STARTED',
    }
  }
  
  export const prioritizePhotosSuccess = () => {
    return {
      type: 'PRIORITIZE_PHOTOS_SUCCESS',
    }
  }
  
  export const prioritizePhotosError = (error: any) => {
    return {
      type: 'PRIORITIZE_PHOTOS_ERROR',
      payload: error,
    }
  }
  