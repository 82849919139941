import React from 'react';

interface IconButtonProps {
    icon: string;
    onClick?: any;
    type?: any;
    className?: string;
    disabled?: boolean;
    hidden?: boolean;
    classColor?: any;
}

const getTypeButton = (type: any) => {
    switch (type) {
        case 'submit':
            return 'submit';
        default:
            return 'button';
    }
};

const IconButton = ({ icon, type, className, onClick, disabled, hidden, classColor }: IconButtonProps) => {
    return (
        <div className={`circle-btn-cnt ${className}`}>
            <button
                type={getTypeButton(type)}
                onClick={onClick}
                disabled={disabled}
                hidden={hidden}
                className='button-transparent'
            >
                <i className={`fa ${icon}  ${classColor}`} />
            </button>
        </div>
    );
};

export default IconButton;
