import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';

interface NegotiatingLeaseRenewalViewProps {
    negotationSteps?: any;
    state?: any;
}
/**
 * NegotiatingLeaseRenewalView is a functional component which has many props. The main function of this component is to render a lease renewal in a negotiation state
 *@function
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const NegotiatingLeaseRenewalView = ({ negotationSteps, state }: NegotiatingLeaseRenewalViewProps) => {
    const steps = ['WAIT_OWNER_RESPONSE'];
    const stateIntl = intl.get(`${state.toUpperCase()}_STATE`);
    return (
        <>
            <div className='template-line mt-5' />
            <h1 className='new-subtitle mb-5 font-weight-bold'>{intl.get('LEASE_RENEWAL')}</h1>
            <div className='text-left'>
                <h2 className='font-weight-bold mt-5 mb-4'>
                    {intl.getHTML('CONTRACT_STATUS_RENEWED', { state: stateIntl })}
                </h2>
                <ol className='ol-custom my-5'>
                    {steps.map((step, i) => (
                        <li className={`${negotationSteps === i + 1 && 'current-step'}`} key={step}>
                            {intl.get(step)}
                        </li>
                    ))}
                </ol>
            </div>
        </>
    );
};
const mapStateToProps = (state: any) => {};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetStepRejectLeaseRenewal: (data: {}) => {},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NegotiatingLeaseRenewalView);
