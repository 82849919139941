export const getUserPhoneStart = () => {
    return {
        type: 'GET_USER_PHONE_PRESENT_STARTED',
    };
};

export const getUserPhoneSuccess = (data: any) => {
    return {
        type: 'GET_USER_PHONE_PRESENT_SUCCESS',
        payload: data,
    };
};

export const getUserPhoneError = (error: any) => {
    return {
        type: 'GET_USER_PHONE_PRESENT_ERROR',
        payload: {
            error,
        },
    };
};
