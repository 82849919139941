
const termsAndConditions = 
'<div class="document-html-container">'
    +'<section class="document_title">'
        +'<header>'
        +'<h1 class="title_underline document_title">TÉRMINOS Y CONDICIONES, Y POLÍTICA DE PRIVACIDAD DE DATOS</h1>'
        +'</header>'
        +'<h3>"TANGO"</h3>'
    +'</section>'
    +'<section>'
        +'<ol>'
        +'<h2><li>ANTECEDENTES GENERALES</h2>'
            +'<p>Tango es una plataforma digital de acceso online, y solución tecnológica para servicios de corretaje inmobiliario, colocación de propiedades en arriendo, gestión comercial inmobiliaria, administración de inmuebles para renta, y gestión de pagos, que le pertenece en propiedad a la sociedad comercial “Tango Administración SpA”, rol único tributario número 77.282.031-3, sociedad legalmente constituida conforme a las leyes de la República de Chile, y con domicilio legal en Cerro el Plomo número 5855, oficina 907A, comuna de Las Condes, ciudad de Santiago, Región Metropolitana, República de Chile.</p>'
            +'<p>Tango, copyright © 2020, Tango Administración SpA. Todos los derechos reservados.</p>'
            +'<p>Este documento contiene los términos y condiciones de uso general de Tango, así como la política de privacidad y manejo de datos a través de la misma plataforma, para todos y cada uno de los servicios prestados a través de ella, o en conjunción con ella, por todas y cada una de las entidades que presten dichos servicios, a todos y cualesquiera usuarios registrados en ella.</p>'
            +'<p>El presente documento, debidamente aceptado por un usuario, ya sea expresa o tácitamente, constituye tanto (i) un contrato válido y legalmente vinculante con el usuario para la prestación de los servicios descritos y regulados en el mismo documento, y (ii) un contrato válido y vinculante, anexo a todo otro contrato suscrito expresamente por el usuario, para servicios de carácter directo, conexo, consecuencial o alternativo a los descritos y regulados en el mismo documento. Todo ello conforme a la versión vigente de este documento, a la época de uso de Tango, y conforme a la legislación aplicable en su oportunidad, respectivamente.</p>'
            +'</li>'
        +'</ol>'
    +'</section>'
    +'<section>'
        +'<ol style="counter-reset: item 1;">'
            +'<h2><li>DEFINICIONES</h2>'
                +'<p>Los siguientes conceptos, en cuanto sean usados con su primera letra capitalizada o mayúscula en este documento, tendrán las definiciones que se detallan a continuación:</p>'
                +'<ol>'
                    +'<li><p><strong>Tango</strong>, se refiere a la plataforma digital y solución tecnológica de cuyo uso es el objeto de estos términos y condiciones, alojada como se encuentre en línea, y entendida como el conjunto de herramientas y programas computacionales, propios y de terceros pero integrados, así como equipos, servidores, infraestructura de almacenamiento, bases de datos, algoritmos y fórmulas, procesos y procedimientos, y otros de similar naturaleza, debidamente destinados a un acceso por vía de un dispositivo móvil, computador, u otro equipo similar debidamente adecuado conforme a las condiciones técnicas mínimas adecuadas, y por vía de una red de telecomunicaciones suficiente y efectiva.</p></li>'
                    +'<li><p><strong>Empresa(s)</strong>, se refiere, en uso singular, a la sociedad comercial que sea titular y propietaria de Tango en su respectivo momento, y se refiere, en uso plural, a dos, más, o todas, de las sociedades comerciales que corresponden a la Empresa, a la gestora de arriendos, a la administradora de arriendos, y a la gestora de pagos, indistintamente.</p></li>'
                    +'<li><p><strong>Corredora</strong>, se refiere a la sociedad comercial Tango Administración SpA, rol único tributario número 77.282.031-3, con domicilio legal en Cerro El Plomo número 5855, oficina 907A, comuna de Las Condes, ciudad de Santiago, Región Metropolitana, y en su calidad de proveedora de un servicio de corretaje inmobiliario, conforme a los alcances de estos términos de uso.</p></li>'
                    +'<li><p><strong>Gestora de Arriendos</strong>, se refiere a la sociedad comercial Tango Administración SpA, rol único tributario número 77.282.031-3, con domicilio legal en Cerro El Plomo número 5855, oficina 907A, comuna de Las Condes, ciudad de Santiago, Región Metropolitana, y en su calidad de proveedora de un servicio de colocación de propiedades en arriendo y de gestión comercial e inmobiliaria, conforme a los alcances de estos términos de uso.</p></li>'
                    +'<li><p><strong>Administradora de Arriendos</strong>, se refiere a la sociedad comercial Tango Administración SpA, rol único tributario número 77.282.031-3, con domicilio legal en Cerro El Plomo número 5855, oficina 907A, comuna de Las Condes, ciudad de Santiago, Región Metropolitana, y en su calidad de proveedora de servicios de administración de arriendos, conforme a los alcances de estos términos de uso.</p></li>'
                    +'<li><p><strong>Gestora de Pagos</strong>, se refiere a la sociedad comercial Tango Pagos SpA, rol único tributario número 77.282.029-1, con domicilio legal en Cerro El Plomo número 5855, oficina 907A, comuna de Las Condes, ciudad de Santiago, Región Metropolitana, y en su calidad de proveedora de un servicio de gestión en cobros de rentas y otros servicios asociados, conforme a los alcances de estos términos de uso.</p></li>'
                    +'<li><p><strong>Usuario(s)</strong>, se refiere, indistintamente, a cualquier persona natural mayor de edad y legalmente capaz que, en calidad de titular de una cuenta de usuario en Tango, haga uso de esta conforme a la destinación natural de la misma, según se describe en los presentes términos y condiciones, y sin respecto del tipo de usuario que le corresponda.</p></li>'
                    +'<li><p><strong>Cuenta(s) de Usuario/a(s)</strong>, se refiere al perfil personal e individual de un Usuario en Tango, generado conforme a estos términos y condiciones, para permitir el debido uso del sistema por parte de un respectivo Usuario.</p></li>'
                    +'<li><p><strong>Uso</strong>, se refiere a todo acto o acción por la cual un Usuario haga uso de Tango conforme a sus funcionalidades, finalidades, y capacidades, conforme se hayan descritas en estos términos y condiciones.</p></li>'
                    +'<li><p><strong>Propiedad(es)</strong>, se refiere a todo bien inmueble de uso domiciliario, sea departamento, casa, u otro, que sea dispuesto por un Usuario para la prestación de los servicios de la Corredora, de la Gestora de Arriendos, de la Administradora de Arriendos, y/o de la Gestora de Pagos, según sea el caso, siempre a través de Tango, y conforme a estos términos y condiciones.</p></li>'
                    +'<li><p><strong>Propietario/a(s)</strong>, se refiere a todo Usuario que haga Uso de Tango en calidad de propietario, o de mandatario del (de los) propietario(s) legal(es), de una o más Propiedades, para su eventual arrendamiento, a través de Tango, y conforme a estos términos y condiciones.</p></li>'
                    +'<li><p><strong>Arrendatario/a(s)</strong>, se refiere a todo Usuario que haga Uso de Tango en calidad de potencial o actual arrendatario de una o más Propiedades, para arrendarlas para sí, a través de Tango, y conforme a estos términos y condiciones.</p></li>'
                    +'<li><p><strong>Términos</strong>, se refiere al presente documento de términos y condiciones de uso, y política de privacidad de datos, en su versión vigente a la época de uso respectivo por parte de un Usuario.</p></li>'
                +'</ol>'
            +'</li>'
        +'</ol>'
    +'</section>'
    +'<section>'
        +'<ol style="counter-reset: item 2;">'
            +'<h2><li>FUNCIÓN Y OBJETO DE TANGO</h2>'
            +'<p>Tango es una plataforma digital de acceso online, y solución tecnológica que opera por medio de una aplicación para dispositivos móviles y un sitio web de navegación en línea; la cual posee las siguientes funciones principales, prestadas por las distintas Empresas:</p>'
            +'<ol>'
                +'<li><p><strong>Sobre la Corredora</strong></p>'
                    +'<p>La Corredora prestará, por medio de Tango, y conforme a las condiciones establecidas en un contrato de servicios de corretaje inmobiliario con el Propietario, suscrito por ambas partes conforme a los alcances de estos Términos, y con aplicación de estos últimos como anexo a dicho contrato, los siguientes servicios generales:</p>'
                        +'<ol>'
                            +'<li><p>El registro fotográfico de Propiedades para su oferta, y la creación de fichas técnicas y documentación promocional pertinente y necesaria para su debido corretaje;</p></li>'
                            +'<li><p>La publicación de Propiedades dentro de Tango;</p></li>'
                            +'<li><p>La promoción de Propiedades dentro de Tango;</p></li>'
                            +'<li><p>El perfilamiento de potenciales Arrendatarios para Propiedades;</p></li>'
                            +'<li><p>El agendamiento y la ejecución de visitas a Propiedades por parte de potenciales Arrendatarios;</p></li>'
                            +'<li><p>La redacción de los contratos y de la demás documentación legal necesaria para el arrendamiento de las Propiedades y la gestión de su entrega a eventuales Arrendatarios;</p></li>'
                            +'<li><p>La entrega de las Propiedades a los Arrendatarios;</p></li>'
                            +'<li><p>La creación del acta de entrega por las Propiedades una vez entregadas.</p></li>'
                        +'</ol>'
                +'</li>'
                +'<li><p><strong>Sobre la Gestora de Arriendos</strong></p>'
                    +'<p>La Gestora de Arriendos prestará, por medio de Tango, y conforme a las condiciones establecidas en estos Términos, los siguientes servicios a los Usuarios debidamente registrados y con Cuentas de Usuario válidas y vigentes, según sea requerido por cada Usuario mediante el Uso de Tango:</p>'
                        +'<ol>'
                            +'<li><p>La puesta a disposición de Usuarios de un mercado digital dentro de Tango, en el cual poder publicar y/o navegar Propiedades, para su eventual arrendamiento.</p></li>'
                            +'<li><p>La ejecución, por personal de su contratación, cuenta, costo y riesgo, de los procesos de verificación, inventariado, fotografiado, publicación, agendamiento de visitas, visitas, la redacción, coordinación firma de documentos y contratos, de Propiedades de los Propietarios, para su debido arrendamiento, por parte de los Arrendatarios, a través de Tango.</p></li>'
                            +'<li><p>La recepción y almacenamiento de información de Usuarios en y a través de Tango, y el uso de la información para procesos de revisión, análisis y filtro para aprobación de ofertas de arrendamientos de Propiedades, y de Arrendatarios, conforme al perfil personal y financiero de los respectivos Usuarios.</p></li>'
                            +'<li><p>La verificación directa de información de avales y codeudores solidarios, garantes en arrendamiento de Propiedades a través de Tango.</p></li>'
                            +'<li><p>La redacción, entrega, coordinación de firma, y validación, de contratos de arrendamiento sobre Propiedades arrendadas a través de Tango, y de servicios de administración de arriendos a ser prestados por la Administradora de Arriendos, y de servicios de gestión de pagos para ser prestados por la Gestora de Pagos, respecto de tales contratos de arrendamiento sobre tales Propiedades.</p></li>'
                        +'</ol>'
                +'</li>'     
                +'<li><p><strong>Sobre la Administradora de Arriendos</strong></p>'
                +'<p>La Administradora de Arriendos prestará, por medio de Tango, y conforme a las condiciones establecidas en un contrato de servicios de administración con el Propietario, suscrito por ambas partes conforme a los alcances de estos Términos, y con aplicación de estos últimos como anexo a dicho contrato, los siguientes servicios generales:</p>'
                    +'<ol>'
                        +'<li><p>El notificar y ser notificado del vencimiento y término de contratos de arrendamiento suscritos a través de Tango con Arrendatarios sobre sus Propiedades.</p></li>'
                        +'<li><p>El redactar el acta de recepción en restitución de las Propiedades, respecto de los contratos de arrendamiento que existan sobre ellas con Arrendatarios, a través de Tango.</p></li>'
                        +'<li><p>El recibir en restitución las Propiedades, al término de los contratos de arrendamiento suscritos a través de Tango con Arrendatarios.</p></li>'
                    +'</ol>'  
                +'</li>' 
                +'<li><p><strong>Sobre la Gestora de Pagos</strong></p>'
                +'<p>La Gestora de Pagos prestará, por medio de Tango, y conforme a las condiciones establecidas en un contrato de servicios de administración con el Propietario, suscrito por ambas partes conforme a los alcances de estos Términos, y con aplicación de estos últimos como anexo a dicho contrato, los siguientes servicios generales:</p>'
                    +'<ol>'
                        +'<li><p>El informar al Propietario de cualesquiera incumplimientos de pagos detectados como cometidos por un Arrendatario sobre cualesquiera de sus Propiedades arrendadas a través de Tango.</p></li>'
                        +'<li><p>El redactar y gestionar la debida documentación que fuere pertinente y necesaria para la correcta gestión de los pagos correspondientes a sus Propiedades, conforme a los contratos de arrendamiento que existan sobre ellas con Arrendatarios, a través de Tango.</p></li>'
                        +'<li><p>El realizar el cobro de rentas de arrendamiento a los Arrendatarios sobre Propiedades arrendadas a través de Tango, percibir los pagos realizados, informar de ellos al respectivo Propietario, realizar los descuentos y pagos de todas las sumas que el Propietario deba a terceros contra dichas rentas de arrendamiento, según fuere procedente contractualmente, y restituir al Usuario que corresponda los saldos restantes que correspondan.</p></li>'
                        +'<li><p>El realizar las devoluciones de garantías pagadas por arrendamientos a través de Tango a sus respectivos Arrendatarios, cuando fuere procedente.</p></li>'
                        +'<li><p>Iniciar y llevar a cabo todas y cada una de las acciones legales que correspondan para verificar el debido cumplimiento de los contratos de arrendamiento suscritos a través de Tango sobre las Propiedades.</p></li>'
                    +'</ol>'  
                +'</li>' 
            +'</ol>' 
        +'</li>' 
    +'</ol>'
+'</section>'
+'<section>'
    +'<ol style="counter-reset: item 3;">'
        +'<h2><li>CONDICIONES MÍNIMAS DE USO</h2>'
        +'<ol>'
            +'<li><p><strong>Aceptación continua de los Términos</strong></p>'
                +'<p>El Uso de Tango requerirá siempre, y en todo momento, la aceptación (sea expresa o tácita) de estos Términos por parte del Usuario, en su totalidad. La no aceptación, manifestada de forma expresa por un Usuario, a los Términos, y ya sea total o parcialmente, hará entender entonces que no existe voluntad real y efectiva de cumplir con las condiciones establecidas para el Uso del sistema. Podrán las Empresas, en tal caso, y sin necesidad de mayor trámite, proceder a negar el Uso de Tango a dicho Usuario y rehusar los servicios respectivos; y podrá la Empresa cancelar su Cuenta de Usuario, conforme lo disponen estos mismos Términos al efecto.</p>'
                +'<p>El Uso de Tango, sin perjuicio de los prompts, avisos, e instancias de verificación de aceptación de los Términos que le regulan, será en todo momento entendido por las partes contratantes como un acto de aceptación tácita de los propios Términos, conforme a una versión vigente a la fecha de cada acto de Uso, para todos y cada uno de los efectos legales.</p>'     
            +'</li>'     
            +'<li><p><strong>Acceso y accesibilidad</strong></p>'
                +'<p>Tango se encuentra debidamente habilitada y optimizada para operar mediante el uso de dispositivos que cumplan con las siguientes capacidades técnicas mínimas, de hardware y de software:</p>'
                    +'<ol>'
                    +'<div class="list-wrap">'
                        +' <div class="list">'
                                +'<p>Android 4.4 o superior, con el uso de Chrome como navegador predeterminado</p>'
                                +'<p>iOS13 o superior, con el uso de Safari como navegador predeterminado</p>'
                                +'<p>Procesador de 1,2GHz o superior</p>'
                                +'<p>512MB mínimo de RAM</p>'
                        +' </div>'
                    +'</div>'
                +'</ol>'
                +'<p>Tango requiere, adicionalmente, y para su Uso, de una conexión estable y permanente a internet, sea a través de una conexión directa a una red habilitada, o a través del uso de un sistema de datos de telefonía con un plan de datos suficiente al efecto en el dispositivo respectivo; no estando así habilitada para un Uso en modo <i>offline</i>.</p>'
            +'</li>' 
            +'<li><p><strong>Permisos y autorizaciones</strong></p>'
                +'<p>Tango requiere, de forma necesaria, el otorgamiento de acceso por parte del Usuario a los siguientes sistemas, archivos y/o ficheros digitales del dispositivo o equipo empleado para su Uso:</p>'
                    +'<ol style="counter-reset: item 1;list-style-type: lower-roman">'
                    +'<div class="list-wrap">'
                        +' <div class="list">'
                                +'<p>Cámara</p>'
                                +'<p>Carpetas</p>'
                                +'<p>Ubicación</p>'
                        +' </div>'
                    +'</div>'
                    +'</ol>'
            +'</li>'
            +'<li><p><strong>Herramientas de terceros</strong></p>'
            +'<p>Para el Uso de Tango las Empresas podrán hacer uso de las siguientes herramientas o servicios de terceros, sea o no como digitalmente integrados a Tango, y cuyas políticas de uso, privacidad y manejo de datos, son de redacción, confección, y control, exclusivos de cada empresa o persona proveedora de las mismas; todo lo cual se entiende como aceptado por el Usuario por medio de la aceptación de estos Términos:</p>'
                +'<ol>'
                    +'<div class="list-wrap">'
                    +' <div class="list">'
                        +'<p>Informe Comercial Platinum 360, de la empresa EQUIFAX</p>'
                        +'<p>Background Report, de la empresa TRUORA</p>'
                        +'<p>Floid, de la empresa FLOID SpA</p>'
                    +' </div>'
                +'</div>'
                +'</ol>'
                +'<p>Las respectivas políticas de uso, privacidad y manejo de datos de cada herramienta o servicio antes indicado pueden ser obtenidas por el Usuario mediante los siguientes enlaces:</p>'
                +'<ol>'
                    +'<div class="list-wrap">'
                    +' <div class="list">'
                        +'<p>EQUIFAX: xxxxxxx</p>'
                        +`<p>TRUORA: <a href='https://assets.website-files.com/5b559a554de48fbcb01fd277/5e7d447e86f74dbfdd048c81_Poli%CC%81tica%20de%20Privacidad%20Truora%20VF%2023_03_30%20.pdf'>Politicas TRUORA</a></p>`
                        +`<p>Floid: <a href='https://www.floid.app/terminos-y-condiciones/'>Politicas Floid</a></p>`
                    +' </div>'
                +'</div>'
                +'</ol>'
                +'<p>La negativa del Usuario a las políticas de uso, privacidad y manejo de datos de cada una de estas herramientas o servicios podría conducir a que todo o parte de Tango resulte inaccesible, conforme a los límites de responsabilidad establecidos en los Términos, y constituirá una negativa a los mismos, para todos los efectos legales y contractuales.</p>'
            +'</li>'
            +'<li><p><strong>Restricciones de Uso</strong></p>'
                +'<p>En el Uso de Tango el Usuario se encontrará siempre y en todo momento obligado a:</p>'
                +'<ol>'
                    +'<li><p>Hacer un uso de Tango que no escape de la destinación y finalidad naturales del sistema, debiendo así abstenerse de usarlo para fines ilícitos o ajenos a su naturaleza, o en modo contrario a estos Términos.</p></li>'
                    +'<li><p>Aportar siempre información efectiva y veraz en todo aquello que sea solicitado por las Empresas, para el Uso de Tango, sin importar la forma en que esta información sea solicitada. Con ello, deberá siempre hacer entrega y/o uso de información, e incorporar y operar medios de pago, que le pertenezcan legalmente, o sobre los cuales cuente con autorización legal suficiente de su respectivo propietario o titular. El Usuario es exclusivo responsable de la falsedad y/o falta de integridad en la información aportada, y de la legitimidad o falta de legitimidad en el uso de los medios de pago empleados en el Uso de Tango.</p></li>'
                    +'<li><p>Abstenerse de hacer uso de Tango de cualquier manera que pueda constituir o arriesgar constituir un uso contrario a la ley.</p></li>'
                    +'<li><p>Abstenerse de incorporar a Tango, de cualquier manera, información o contenidos contrarios a la ley, tales como contendidos maliciosa o dolosamente falsos, de propiedad de terceros sin derecho alguno a su uso, ofensivos, de carácter sexual explícito, de carácter delictual, u otros similares.</p></li>'
                    +'<li><p>Abstenerse de causar daño a Tango o a las Empresas, de cualquier forma.</p></li>'
                    +'<li><p>Abstenerse de acceder o intentar acceder a datos e información de otros Usuarios, o de las Empresas, fuera del marco de lo permitido en el Uso de Tango, y de intentar manipular los mismos datos.</p></li>'
                    +'<li><p>Abstenerse de hacer ejercicios de ingeniería inversa, modificación, o de acceso, a todo o parte de Tango.</p></li>'
                    +'<li><p>Abstenerse de acceder a estos Términos, y alterarlos y/o modificarlos en cualquier manera o medida.</p></li>'                               
                +'</ol>'
            +'</li>'
            +'<li><p><strong>Exenciones de responsabilidad y garantías mínimas</strong></p>'
                +'<p>Sin perjuicio de las demás condiciones propias de estos Términos, las Empresas no serán en ningún momento responsables por:</p>'
                +'<ol>'
                    +'<li><p>La continua y permanente disponibilidad de Tango, pudiendo este sistema ser deshabilitado en cualquier momento, a libre discreción de la Empresa.</p></li>'
                    +'<li><p>La continua y permanente disponibilidad de las Propiedades dentro de los catálogos de Tango, o las condiciones de arrendamiento (tales como renta, plazo, u otros) de cada uno, encontrándose todo ello completamente sujeto a la puesta a disposición de las Propiedades, las condiciones mínimas de arrendamiento que Usuarios dispongan sobre los mismos, y los términos legales que la Gestora de Arriendos establezca en los contratos de arriendo que vayan a regir sobre las Propiedades.</p></li>'
                    +'<li><p>Las características, calidades, condiciones, u otros atributos de las Propiedades, en cuanto fueran elementos que se vieran afectados en forma ajena a la gestión que correspondiera a las Empresas al respecto, y conforme los contratos que existan con sus respectivos Propietarios.</p></li>'
                    +'<li><p>La legitimidad, veracidad y efectividad de toda información que fuere aportada por terceros ajenos a las Empresas, incluyendo otros Usuarios, y terceros cuyos productos pudieran ser promocionados, directa o indirectamente en Tango, mediante elementos de promoción o de marketing.</p></li>'
                    +'<li><p>La existencia material de arrendatarios o Arrendatarios para Propiedades sobre las cuales se presten los servicios de la Corredora, o resultados específicos de cualquier índole sobre tal servicio, siendo aquel de naturaleza esencialmente aleatoria.</p></li>'
                    +'<li><p>La funcionalidad de Tango en dispositivos o equipos que no cumplan con las capacidades mínimas de software y/o de hardware que dispongan al efecto estos Términos, o que en circunstancias de no existir acceso suficiente a internet o un plan de datos adecuado para el Uso, o en un contexto donde no se hubieran permitido los accesos y permisos referidos en estos Términos, y en particular, respecto de acceso a ficheros digitales.</p></li>'
                    +'<li><p>La absoluta ausencia de virus computacionales u otros elementos nocivos en Tango, capaces de producir daños o desperfectos en la información, o en los sistemas físicos y/o lógicos de los equipos, dispositivos, o programas del Usuario, intervenir sus documentos electrónicos almacenados en ellos, o en su información de pagos y/o de medios de pago, conforme hubiera existido un acto de terceros por sobre las medidas razonables de seguridad de información implementadas por la Empresa, para impedir actos de intervención de terceros, conforme a los estándares determinados en éstos Términos.</p></li>'
                +'</ol>' 
                +'</li>'  
                +'<p>El Usuario, por medio de su aceptación de los Términos, renuncia en tal acto a todo derecho, pretensión, reclamación o acción, sea administrativa o judicial, sea civil, penal, infraccional, u otra, ante cualquier entidad o tribunal de cualquier competencia o jurisdicción, contra cualquiera de las Empresas, por daños o perjuicios alegados, y cuyo fundamento corresponda a hechos de los descritos en cualquiera de los puntos precedentes.</p>' 
                +'<li><p><strong>Obligación general del Usuario</strong></p>'
                    +'<p>El Usuario estará obligado en todo momento a colaborar, de buena fe, oportunamente, y al máximo de sus competencias, capacidades y disponibilidad, con las Empresas, en cuanto estas lo requiriesen, para la debida prestación de sus respectivos servicios.</p>'
                +'</li>'  
        +'</ol>' 
    +'</li>' 
    +'</ol>'
+'</section>'
+'<section>'
    +'<ol style="counter-reset: item 4;">'
        +'<h2><li>CONDICIONES GENERALES DE USO</h2>'
        +'<ol>'
            +'<li><p><strong>Medios de pago</strong></p>'
                +'<p>Tango mantiene integrado como medio de pago el uso del sistema ServiPag, PadPow y Neat (servicio de recaudación y pagos de servicios prestado por la Sociedad de Recaudación y Pagos de Servicios Limitada), herramienta sujeta a sus propios manuales, términos y condiciones de uso, a las cuales se encuentra igualmente sujeto y obligado el Usuario al usar dicho medio de pago, conforme a estos Términos.</p>'
                +'</li>'     
            +'<li><p><strong>Documentos tributarios digitales o electrónicos</strong></p>'
                +'<p>El Usuario, en calidad de receptor de todos y cualesquiera documentos tributarios electrónicos que pudieran ser emitidos por causa del Uso de Tango, y de conformidad a lo dispuesto en la Resolución Exenta número 11 del 14 de Febrero del año 2003 del Servicio de Impuestos Internos, que estableció el procedimiento para que contribuyentes autorizados puedan emitir documentos electrónicos y puedan enviarlos a receptores manuales, declara que con la aprobación y aceptación de estos Términos autoriza a las Empresas para que todo documento tributario correspondiente a toda operación realizada en Tango le sea entregada por medios electrónicos, solamente, y que el aviso respectivo le sea enviado por correo electrónico a la casilla indicada en su Cuenta de Usuario.</p>'
                +'<p>En la creación de respaldos físicos de tales documentos, conforme sean solicitados y pertinentes, se estarán a las condiciones y restricciones establecidas al efecto en la normativa vigente y respectiva, imprimiéndolos en el tamaño, papel, color, formato y calidad, que corresponda conforme a la ley, a la época de su impresión.</p>'
            +'</li>' 
                +'<li><p><strong>Cuentas de Usuarios</strong></p>'
                    +'<p>El Uso de Tango requiere, de forma necesaria, de que el Usuario tenga una Cuenta de Usuario activa, vigente y habilitada; generada a través de un proceso de registro para el cual se le requerirá al Usuario aportar la siguiente información de registro:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +' <div class="list">'
                                    +'<p>Nombres</p>'
                                    +'<p>Apellido paterno</p>'
                                    +'<p>Apellido materno</p>'
                                    +'<p>Número de cédula de identidad</p>'
                                    +'<p>Número de teléfono celular</p>'
                                    +'<p>Fecha de nacimiento</p>'
                                    +'<p>Casilla de correo electrónico, a la cual la Cuenta de Usuario estará asociada</p>'
                                    +'<p>Clave de acceso personal del Usuario a su Cuenta de Usuario</p>'
                                    +'<p>Confirmación de la clave de acceso personal del Usuario a su Cuenta de Usuario</p>'
                            +' </div>'
                        +' </div>'
                    +'</ol>'
                        +'<p>Una vez generada, la Cuenta de Usuario requiere de un proceso de verificación por medio de la casilla de correo electrónico ingresada, para su debida activación. Verificada la Cuenta de Usuario, esta se encontrará activa, pendiente su debida habilitación por parte de la Empresa, conforme lo disponen los Términos al efecto.</p>'
                        +'<p>La Cuenta de Usuario es propia y personal del Usuario. Es así intransferible, y de su propia responsabilidad en cuanto a la seguridad de la clave de acceso a la misma. Todo ello conforme a los estándares de responsabilidad y protección de datos contenidos dentro de estos Términos.</p>'
                        +'<p>La Cuenta de Usuario permitirá al Usuario hacer Uso de Tango conforme a los alcances de estos Términos, ya sea en calidad de Propietario o en calidad de Arrendatario, funcionando estas dos calidades como perfiles alternativos de un mismo Usuario dentro de la misma Cuenta de Usuario, pero con funcionalidades distintas, según los servicios respectivamente dispuestos por las Empresas para cada uno de ellos. </p>'
                        +'<p>Será requisito para hacer Uso de Tango en calidad de Arrendatario, sin embargo, aportar —previo a cualquier oferta que el Usuario quiera hacer para arrendar una Propiedad en Tango—, de forma adicional a lo ya indicado, y cada vez, los siguientes datos o antecedentes, en su respectivo perfil:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div style="counter-reset: list-roman 9;" class="list">'
                                +'<p>Nacionalidad</p>'
                                +'<p>Estado civil completo, y con indicación de su régimen patrimonial, en su caso</p>'
                                +'<p>Dirección</p>'
                                +'<p>Composición de su grupo familiar directo, y de misma residencia</p>'
                                +'<p>Existencia o no de mascotas dentro de su grupo familiar</p>'
                                +'<p>Ocupación, profesión u oficio principal</p>'
                                +'<p>Tipo de contrato por el cual mantiene su actual ocupación, ya sea como independiente, como dependiente, como trabajador informal, como no empleado, o como jubilado o pensionado</p>'
                                +'<p>Antigüedad laboral en su actual ocupación, profesión u oficio principal</p>'
                                +'<p>Si posee cuenta bancaria, sea corriente, a la vista, u otra, y con qué banco o institución financiera</p>'
                            +'</div>'
                        +'</div>'
                    +'</ol>'
                    +'<p>Será igualmente requisito que el Arrendatario aporte o cargue a su Cuenta de Usuario los siguientes documentos, en su respectivo perfil:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div style="counter-reset: list-roman 18;" class="list">'
                                +'<p>Copia por ambos lados de su cédula de identidad</p>'
                                +'<p>Documentos que acrediten sus ingresos mínimos, conforme los listan estos Términos</p>'
                            +'</div>'
                        +'</div>'
                    +'</ol>'
                    +'<p>Junto con ello, deberá el Arrendatario aportar la información y documentos de los números i. al xx. precedentes, y con excepción únicamente de los números viii. y ix., ahora respecto de sus avales y codeudores solidarios, e indicando la relación que mantiene con ellos. Los que habrán de contratar en el arrendamiento de las Propiedades como garantes de las eventuales obligaciones del Arrendatario, en los casos a los correspondientes según estos términos.</p>'
                    +'<p>Las Empresas, previo a visualizar, revisar, o analizar la información de cualesquiera avales y codeudores solidarios indicados por el Arrendatario, procederán a contactarlos directamente, para efectos de verificar la verdadera voluntad de estos para obligarse como avales y codeudores solidarios del Arrendatario, y obtener expresa autorización para solicitar los informes comerciales a los que refieren estos Términos.</p>'
                +'<li><p><strong>Documentos de acreditación de ingresos</strong></p>'
                    +'<p>Para los efectos de acreditar ingresos por parte de un Usuario, para los fines pertinentes que contemplan estos Términos, se requerirá que el respectivo Usuario aporte uno o más de los siguientes antecedentes, haciendo uso para ello de las herramientas propias de Tango y/o de las herramientas integradas a Tango, e indicadas en estos mismos Términos, para tal efecto:</p>'
                    +'<ol>'
                        +'<li><p><strong>Usuarios que sean trabajadores dependientes</strong></p></li>'
                        +'<br>'
                        +'<p>Para el caso Usuarios que sean trabajadores dependientes, se requerirá que cuenten con, y aporten o dispongan, un contrato de trabajo indefinido de una antigüedad de al menos tres meses (o, en su defecto, un certificado extendido por su empleador donde se señale su cargo, fecha de contrato, tipo de contrato, y antigüedad laboral), sus tres (3) últimas liquidaciones de sueldo, y certificados de cotizaciones previsionales al día y de afiliación extendidos por su AFP.</p>'
                        +'<br>'
                        +'<li><p><strong>Usuarios que sean trabajadores independientes</strong></p></li>'
                        +'<br>'
                        +'<p>Para el caso de Usuarios que sean trabajadores independientes, se requerirá que aporten o dispongan su más reciente formulario 22 del Servicio de Impuestos internos, los Pagos Provisionales Mensuales de los últimos seis (6) meses o las boletas de honorarios emitidas durante los últimos tres (3) meses, y certificados de cotizaciones previsionales al día y de afiliación extendidos por su AFP.</p>'
                    +'</ol>' 
                +'</li>'
                +'<li><p><strong>Avales y codeudores solidarios</strong></p>'
                +'<p>Todo Usuario que desee hacer Uso de Tango en calidad de Arrendatario, deberá contar con un mínimo de uno y un máximo de dos avales y codeudores solidarios para poder arrendar Propiedades a través de Tango, en los siguientes casos:</p>'
                    +'<ol>'
                    +'<div class="list-wrap">'
                        +' <div class="list">'
                                +'<p>Cuando el Usuario sea un trabajador dependiente, extranjero, y sin visa y permanencia definitiva en Chile, válidas y vigentes</p>'
                                +'<p>Cuando el Usuario sea un trabajador independiente, extranjero</p>'
                                +'<p>Cuando el Usuario sea un nacional chileno, cuyo ingreso acreditado conforme a los documentos requeridos en estos Términos, sea inferior a tres veces el valor de la renta de arrendamiento de la Propiedad que busque arrendar</p>'
                        +' </div>'
                    +' </div>'
                    +'</ol>'
                +'</li>'
                +'<li><p><strong>Informes comerciales y habilitación del perfil de Arrendatario</strong></p>'
                    +'<p>El perfil de Arrendatario de todo Usuario en Tango requerirá ser habilitado por la Empresa, previo a poder enviar una propuesta u oferta de arrendamiento para arrendar una Propiedad, y cada vez que desee enviar una propuesta u oferta.</p>'
                    +'<p>Para ello, la Empresa, una vez que el Usuario termine de aportar todos los antecedentes que le solicitan estos Términos para su perfil de Arrendatario, e incluyendo los antecedentes de sus avales y codeudores solidarios, en su caso, procederá a realizar solicitudes de informes, reportes, estudios y otros, para recabar y verificar la información comercial, crediticia, tributaria y financiera del Usuario, y antecedentes sobre litigios actuales que este pudiera tener, en cualquier calidad. </p>'
                    +'<p>Para lo antes dicho, la Empresa procederá en lo particular a solicitar los siguientes informes o documentos:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                                +'<p>Informe de Arriendo DICOM, extendido a través de la empresa EQUIFAX</p>'
                                +'<p>Reporte Completo, obtenido a través de la empresa TRUORA</p>'
                            +'</div>'
                        +' </div>'
                    +'</ol>'
                    +'<p>La Empresa se entenderá autorizada, en virtud de estos Términos, para requerir los antecedentes antes descritos, revisarlos, analizarlos, sistematizarlos, y compartir lo recabado con las Empresas; todo ello cuantas veces estime necesario. Las Empresas entienden que corresponde a ellas la debida obligación a tratar, administrar, y custodiar esta información del Usuario como información de carácter confidencial, de acuerdo con las políticas de manejo de información contenidas en estos Términos.</p>'
                    +'<p>Tras la obtención de estos informes, y sin perjuicio de las demás condiciones establecidas en estos Términos sobre otorgamiento o negativa al otorgamiento de servicios, otorgamiento o cierre de Cuentas de Usuarios, y otros, la Empresa procederá a:</p>'
                     +'<ol>'
                    +'<div class="list-wrap">'
                        +' <div style="counter-reset: list-roman 2;" class="list">'
                                +'<p>habilitar el perfil de Arrendatario del Usuario; momento a partir del cual podrá hacer debido Uso de Tango para ofrecer u ofertar arrendar la respectiva Propiedad que desee arrendar</p>'
                                +'<p>fijar un monto máximo de arriendo pactable para el Arrendatario en el arrendamiento de la Propiedad</p>'
                                +'<p>asignar a su perfil el puntaje Tango correspondiente del Arrendatario, calculado conforme a lo disponen los presentes Términos</p>'
                        +' </div>'
                    +'</div>'
                     +'</ol>'
                +'<p>El perfil del Arrendatario, una vez habilitado, se mantendrá por un plazo máximo de dos (2) meses. El Arrendatario podrá hacer Uso de Tango a través de este perfil, con la finalidad de poder ofertar u ofrecer arrendar cualquier Propiedad que se ajuste a su monto máximo de arriendo, asignado a su perfil según lo dicho precedentemente. Vencido este plazo, el perfil se deshabilitará, de forma automática, y será necesario que el Arrendatario repita el proceso completo para poder volver a habilitarlo, para su Uso.</p>'   
                +'</li>'
                +'<li><p><strong>Informes comerciales respecto de avales y codeudores solidarios</strong></p>'
                    +'<p>La Empresa procederá, respecto de todo aval y codeudor solidario indicado por el Arrendatario, y durante o con posterioridad al proceso de verificación de sus datos, a requerir de estos una autorización para proceder a la obtención de los mismos informes detallados en el punto precedente, pero respecto de tales avales y codeudores solidarios.</p>'
                    +'<p>Las Empresas tendrán derecho para rehusar la prestación de servicios a todo Arrendatario cuyos avales y codeudores solidarios, uno o ambos de ellos, nieguen la autorización para obtener tales informes; conforme a lo dispuesto en los presentes Términos respecto del derecho reservado de negativa de Uso.</p>'
                +'</li>'
                +'<li><p><strong>Sistema de revisión de documentación de acreditación de ingresos</strong></p>'
                    +'<p>Conforme a lo señalado en lo precedente de estos Términos, la Empresa hace y hará uso de herramientas de terceros para la prestación de servicios. Conforme a ello, y en lo particularmente referente a la revisión de la documentación de acreditación de ingresos de los Usuarios, así como de sus avales y codeudores solidarios, dentro de los alcances de estos mismos Términos, tal revisión se realiza y realizará por medio de la plataforma Floid. </p>'
                    +'<p>De este modo, la Empresa permitirá al Usuario, y a sus avales y codeudores solidarios, respectivamente y según corresponda, entregar la documentación correspondiente, ello por medio del sistema Floid, y con los alcances al respecto contenidos en estos Términos. Sistema el cual será y deberá ser siempre operado de forma manual y directa por el Usuario y/o por sus avales y codeudores solidarios, a través de su clave única del Servicio de Registro Civil e Identificación, y en la propia aplicación de Floid.</p>'
                    +'<p>La Empresa no solicitará a ningún Usuario, ni a sus avales y codeudores solidarios, sus claves únicas del Servicio de Registro Civil e Identificación, u otras claves similares o relacionadas que sean empleadas por Floid, conforme a sus propios términos de uso y políticas de privacidad. Dicha información, de ser de todos modos entregada por el Usuario o por sus avales y codeudores solidarios a la Empresa, por cualquier motivo, no será guardada ni retenida de forma alguna. Toda referencia o información a ello será eliminada más allá de toda recuperación o lectura, tan pronto sea identificado que dicha referencia o información ha sido recibida por la Empresa.</p>'
                +'</li>'
        +'</ol>'
        +'</ol>' 
        +'</li>' 
    +'</ol>'
+'</section>'
 +'<section>'
        +'<ol style="counter-reset: item 5;">'
            +'<h2><li>SERVICIO DE COLOCACIÓN DE ARRIENDOS</h2>'
                +'<ol>'
                    +'<li><p><strong>Registro de Propiedades</strong></p>'
                        +'<p>El Usuario podrá hacer Uso de Tango para someter a revisión y —eventualmente— publicación en el mercado digital del sistema, de una o más Propiedades; ello, mediante el llenado de un formulario de datos respecto de las respectivas Propiedades, en el cual deberá indicar la siguiente información de cada una de ellas:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                                +'<p>La calidad del Usuario respecto de la Propiedad, ya sea como dueño único, co-dueño, o mandatario del(de los) dueño(s) de la misma</p>'
                                +'<p>El tipo de la Propiedad, ya sea casa o departamento, y en ambos casos, sujeto o no sujeto a un estatuto de copropiedad inmobiliaria o condominio</p>'
                                +'<p>La dirección de la Propiedad</p>'
                                +'<p>La superficie total construida de la Propiedad</p>'
                                +'<p>Tratándose de una casa, la superficie total del terreno de la Propiedad</p>'
                                +'<p>Tratándose de un departamento, la superficie de su terraza, si tuviera</p>'
                                +'<p>El número total de dormitorios</p>'
                                +'<p>El número de dormitorios en suite</p>'
                                +'<p>La indicación de su la Propiedad se encuentra actualmente ocupada, y la calidad en que sus ocupantes estén haciendo ocupación de ella, sea en calidad de dueños, arrendatarios, u otra calidad</p>'
                                +'<p>El precio al cual la propiedad deberá ser arrendada</p>'
                                +'<p>La indicación de si el precio del arriendo incluye o no los gastos comunes correspondientes a la Propiedad</p>'
                                +'<p>En caso de que los gastos comunes no se encuentren incluidos en el precio del arriendo, el valor promedio de los gastos comunes de los últimos 12 meses continuos inmediatamente anteriores a la solicitud de registro de la Propiedad</p>'
                                +'<p>La indicación de si el precio del arriendo incluye los gastos por concepto de cuentas domiciliarias de luz, agua y gas</p>'
                                +'<p>La indicación de si la Propiedad permite, o no permite, el ingreso de mascotas, y su tipo</p>'
                                +'<p>La indicación de si la Propiedad tiene disponibilidad inmediata para ser arrendada y entregada a posibles Arrendatarios</p>'
                                +'<p>En caso de que la Propiedad no tenga disponibilidad inmediata para ser arrendada y entregada a posibles Arrendatarios, la indicación de la fecha a partir de la cual la Propiedad estaría disponible</p>'
                                +'<p>La indicación de los días y horarios en que el Usuario estará disponible, para el caso de que se requiera su presencia para la visita a la Propiedad, sea por parte de personal de la Gestora de Arriendos, o de parte de posibles Arrendatarios</p>'
                     
                            +'</div>'
                        +' </div>'
                    +'</ol>'
                    +'<p>Será requisito para completar el registro de la Propiedad, adicionalmente, que el Usuario aporte o cargue a su cuenta de Usuario los siguientes documentos:</p>'
                   +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div  style="counter-reset: list-roman 17;" class="list">'
                                +'<p>Certificado de antecedentes de la Propiedad, obtenido a través de la página web del Servicio de Impuestos Internos</p>'
                                +'<p>Mandato por el cual el Usuario tiene derecho suficiente para registrar y arrendar la Propiedad, obtenido desde la Empresa a través del enlace a continuación indicado, y debidamente suscrito por todos los mandantes y el propio Usuario, en caso de ser necesario</p>'
                            +'</div>'
                        +' </div>'
                    +'</ol>'
                    +'<p>Para este último documento, la Empresa dispone y dispondrá de un modelo de documento de poder y mandato para uso por parte del Usuario, el cual podrá ser descargado por este, y luego cargado al sistema, como parte de este proceso de registro.</p>'
                    +'</li>'     
                    +'<li><p><strong>Requisitos mínimos de las Propiedades</strong></p>'
                        +'<p>Sólo se admitirán a publicación en Tango aquellas Propiedades que, junto con cumplir con todos los demás pasos y requerimientos del proceso de registro y verificación, adicionalmente cumplan con los siguientes requisitos:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                                +'<p>Tener una renta de arrendamiento igual o superior a $350.000 (trescientos cincuenta mil pesos)</p>'
                                +'<p>Encontrarse ubicada en las comunas de Estación Central, Independencia, La Reina, Las Condes, Lo Barnechea, Macul, Ñuñoa, Peñalolén, Providencia, Recoleta, San Joaquín, San Miguel, Santiago, o Vitacura</p>'
                            +'</div>'
                        +' </div>'
                    +'</ol>'
                    +'</li>' 
                    +'<li><p><strong>Verificación de la Propiedad</strong></p>'
                        +'<p>Una vez solicitada a Registro una Propiedad, la Corredora procederá a un proceso de verificación de esta. Proceso el cual se realiza mediante el agendamiento por parte del propio Usuario para una visita a la Propiedad por parte de un verificador de la Corredora.</p>'
                        +'<p>El verificador procederá a realizar la visita a la Propiedad, acto en el cual completará un formulario online que detallará las especificaciones de la misma (y del condominio en que se encuentre, si es que aplica), para su arrendamiento; y en el cual procederá a la toma de fotografías de la Propiedad, grabado de videos, y levantamiento de planos, todo ello para fines de su debida publicación en Tango una vez aprobada para ser publicada. El formulario, las fotografías, videos, y planos de la Propiedad, quedarán inmediatamente a disposición de la Empresa desde el momento de su confección.</p>'
                        +'<p>Las especificaciones verificadas de la Propiedad incluirán, entre otros, lo siguiente:</p>'
                    +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                                +'<p>Cantidad de dormitorios</p>'
                                +'<p>Cantidad de baños</p>'
                                +'<p>Cantidad de estacionamientos</p>'
                                +'<p>Disponibilidad de bodega</p>'
                                +'<p>Ubicación dentro de condominios, si es que correspondiere</p>'
                                +'<p>Superficie interior</p>'
                                +'<p>Superficie de terrazas, si es que correspondiere</p>'
                                +'<p>Si es que el arriendo incluye los gastos comunes</p>'
                                +'<p>Valor promedio de gastos comunes, si es que no están incluidos dentro de los gastos comunes</p>'
                                +'<p>Si es que el arriendo incluye los gastos de cuentas domiciliarias de luz, agua y gas</p>'
                                +'<p>Si es que la Propiedad acepta mascotas</p>'
                                +'<p>Fecha de disponibilidad</p>'
                                +'<p>Cantidad máxima de ocupantes permitidos</p>'
                                +'<p>Valor de la renta de arrendamiento</p>'
                                +'<p>Ubicación</p>'
                                +'<p>Equipamiento de la Propiedad</p>'
                                +'<p>Espacios comunes que beneficien a la Propiedad, si es que correspondiere</p>'
                                +'<p>Otros aspectos relevantes a discreción del verificador</p>'
                            +'</div>'
                        +' </div>'
                    +'</ol>'
                    +'<p>Como consecuencia del proceso de verificación, la Corredora podrá luego aprobar o rechazar la Propiedad para ser publicada en Tango. Toda Propiedad que no cumpla con estándares mínimos y razonables de calidad para ser habitada por potenciales Arrendatarios, a criterio del verificador y/o de la Corredora, será rechazada y no podrá ser publicada en Tango, sino hasta que rectifique su situación, y logre cumplir con tales estándares. La Corredora informará al Usuario el resultado de la verificación, con indicación de si la Propiedad ha sido no aprobada para ser incorporada al mercado de Tango, y debidamente publicada en este.</p>'
                    +'<p>Todo lo confeccionado por el verificador, e incluyendo fotografías, videos, y planos, serán de la exclusiva propiedad y titularidad de la  Empresa. Copia de estas no será entregada al Usuario, y no tendrá este derecho a hacer uso personal o directo de estas para ningún fin.</p>'
                    +'</li>'
                    +'<li><p><strong>Cajas de seguridad y custodia de llaves</strong></p>'
                        +'<p>Como parte del proceso de verificación, o en cualquier momento con posterioridad a este, el Usuario podrá autorizar a la Corredora para que proceda a la instalación de una caja de seguridad en la Propiedad, con la finalidad de almacenar en ella las llaves de acceso a la misma. Esto, con el fin de permitir así a personal de la Corredora acceder en todo momento a la Propiedad para visitas con Arrendatarios, sin necesidad de la asistencia presencial y personal del propio Usuario, o un representante de este.</p>'
                        +'<p>Las cajas de seguridad serán instaladas, mantenidas, y removidas, por la Corredora, a su cuenta y costo. Una vez instaladas estas no podrán ser removidas o hechas remover por el Usuario sino hasta que la Propiedad sea rechazada para ser publicada en Tango, o hasta que —habiendo sido debidamente publicada en Tango— sea eliminada del catálogo de propiedades del sistema.</p>'
                        +'<p>Ninguna caja de seguridad podrá ser instalada en una Propiedad que se encuentre con ocupantes y/o amoblada, siendo requisito necesario para instalarla y usarla, el que la Propiedad se encuentre completamente deshabitada y desamoblada.</p>'
                    +'</li>'
                    +'<li><p><strong>Publicación de la Propiedad</strong></p>'
                        +'<p>Una vez terminado por completo el proceso de registro y de verificación de Propiedades contenidos en estos Términos, y luego aprobada una Propiedad para ser publicada en Tango, la información aportada por el Usuario y la información recabada por el verificador, serán empleadas para generar una publicación de la Propiedad en el mercado del sistema, y desde el cual pueda ser revisada por otros Usuarios.</p>'
                        +'<p>Una vez publicada una Propiedad, el Usuario deberá cumplir con una obligación de exclusividad en su oferta para su venta, arrendamiento, comodato, o uso a cualquier título, por un periodo de tres (3) meses desde la fecha de publicación. Conforme a dicha obligación, el Usuario no podrá personalmente publicar u ofertar, o permitir por medio de terceros que sea publicada u ofertada, la Propiedad, sea directamente o por medio de cualquier otra persona, empresa, corredora, inmobiliaria, servicio digital, u otro; y, conjuntamente con ello, y para el caso de que la Propiedad ya se hubiere encontrado publicada u ofertada de forma previa a esta obligación de exclusividad, el Usuario deberá suspender y eliminar, o hacer suspender y eliminar, de forma inmediata, cualquier otra publicación u oferta de la Propiedad.</p>'
                        +'<p>Para efectos de la modificación o eliminación de la publicación de la Propiedad en Tango, el Usuario deberá solicitarlo de forma directa a la Empresa, por medio de correo electrónico a ayuda@tango.rent. Para solicitudes de modificación, el correo enviado deberá acompañar los antecedentes que respalden la necesidad de hacer los cambios o modificaciones a la publicación.</p>'
                        +'<p>Las publicaciones de las Propiedades permanecerán en Tango hasta que sean removidas a solicitud del Usuario respectivo, hasta que la Empresa lo determine conforme a las facultades generales en el manejo de Tango que le otorgan los presentes Términos, o hasta que la Propiedad sea arrendada por un Arrendatario. Para poder re-publicar la Propiedad, el Usuario deberá hacer una nueva solicitud, seguir un nuevo proceso de verificación, y recibir una nueva aprobación.</p>'
                    +'</li>'
                    +'<li><p><strong>Visitas a la Propiedad</strong></p>'
                    +'<p>La Corredora, como parte de los servicios, procederá a llevar a cabo las visitas a la Propiedad por parte de Arrendatarios interesados en arrendarla, según estos las soliciten a través del Uso de Tango. Ello mediante su propio personal al efecto, y ya sea en coordinación y con presencia del Usuario en los tiempos respectivamente asignados por este para visitas, o en forma directa y sin presencia del Usuario, y haciendo uso de las cajas de seguridad que existan, para aquellas Propiedades que dispongan de una.</p>'
                    +'</li>'
                    +'<li><p><strong>Análisis y avaluación de la capacidad financiera y de crédito de los Arrendatarios, y de los avales y codeudores solidarios</strong></p>'
                        +'<p>La Gestora de Arriendos, mediante los informes, reportes, y demás datos obtenidos por la Empresa, procederá a hacer una análisis y avaluación de la capacidad financiera del Arrendatario y de sus avales y codeudores solidarios, si correspondiere. Todo ello como parte del proceso de habilitación del perfil de Arrendatario del Usuario.</p>'
                        +'<p>A través del uso de los resultados obtenidos en este análisis y avaluación de la capacidad financiera del Arrendatario y de sus avales y codeudores solidarios, si correspondiere, y de la información aportada por el Arrendatario para su perfil en su Cuenta de Usuario, la Gestora de Arriendos procederá a calcular un puntaje o valoración del Arrendatario. Este, en una escala de 1 a 100, y denominado para estos efectos como puntaje Tango. Dicho valor será asignado e incorporado a su perfil de Arrendatario, en concordancia con los presentes Términos.</p>'
                        +'<p>Las Empresas tendrán derecho para rehusar la prestación de servicios a todo Arrendatario cuyo Perfil de Usuario posea un puntaje inferior a 50 puntos; conforme a lo dispuesto en los presentes Términos respecto del derecho reservado de negativa de Uso.</p>'
                    +'</li>'
                    +'<li><p><strong>Oferta y aceptación de arrendamiento, redacción y firma de contratos</strong></p>'
                        +'<p>Cualquier Arrendatario que desee realizar una oferta para el arrendamiento de una Propiedad en Tango, deberá primeramente contar con una Cuenta de Usuario, un perfil de Arrendatario debidamente habilitado, un puntaje Tango, y un valor máximo de arriendo. Conforme cumpla con estos requisitos, podrá entonces ofertar el arrendamiento de Propiedades través del propio sistema de Tango; ello respecto de propiedades que tengan un valor de arriendo igual o inferior al máximo permitido para su perfil, conforme a estos Términos.</p>'
                        +'<p>El Propietario, al recibir la oferta de arrendamiento sobre su Propiedad, recibirá la siguiente información:</p>'
                        +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                                +'<p>Nombre completo del Arrendatario</p>'
                                +'<p>Cédula de identidad del Arrendatario</p>'
                                +'<p>Nombre completo de cada uno de los avales y codeudores solidarios, si correspondiera</p>'
                                +'<p>Cédula de identidad de cada uno de los avales y codeudores solidarios, si correspondiera</p>'
                                +'<p>Ocupación, profesión u oficio del Arrendatario</p>'
                                +'<p>Indicación de si el Arrendatario, o cualquiera de sus avales y codeudores solidarios, so los hubiera, tienen antecedentes penales</p>'
                                +'<p>Indicación de si el Arrendatario, o cualquiera de sus avales y codeudores solidarios, si los hubiera, tienen juicios vigentes en su contra, de cualquier materia, verificables a través del portal del Poder Judicial</p>'
                                +'<p><i>Puntaje Tango</i>del Arrendatario</p>'
                                +'<p>Precio ofrecido para el arriendo</p>'
                                +'<p>Fecha de inicio propuesta para el arriendo</p>'
                                +'<p>Mensaje personalizado del Arrendatario al Usuario</p>'
                         +'</div>'
                        +'</div>'
                        +'</ol>'
                        +'<p>Hecha una propuesta de arrendamiento, el Propietario recibirá un aviso y notificación directamente por medio de su Cuenta de Usuario. El Propietario dispondrá, luego, de un plazo fatal de setenta y dos (72) horas para revisar, aceptar, o rechazar la propuesta, a través de Tango. Vencido este plazo sin respuesta de parte del Propietario, la propuesta se entenderá rechazada.</p>' 
                        +'<p>Aceptada la propuesta de arrendamiento, el Propietario dispondrá, luego, de un plazo fatal de cuarenta y ocho (48) horas para entregar, por intermedio de Tango, a la Empresa, copia debidamente firmada con firma digital, estampada a través de Tango, tanto del contrato de arrendamiento como del contrato de administración, éste último sólo si es que la Propiedad estará puesta en servicios de administración de los descritos en estos Términos, y sin aquel si es que la Propiedad no lo estará, y en cualquier caso conforme la Gestora de Arriendos le entregue al efecto. Vencido este plazo sin cumplir con lo antes dicho, la propuesta se entenderá rechazada.</p>' 
                        +'<p>De haber sido expresamente aceptada o rechazada la propuesta, y de haberse cumplido con entregar a la Empresa los contratos de arrendamiento y administración debidamente firmados por parte del Propietario, de ser procedentes y en su caso, un aviso y notificación de ello será dada al Arrendatario directamente por medio de su Cuenta de Usuario.</p>' 
                        +'<p>En caso de que la propuesta sea aceptada, se iniciará el procedimiento de arrendamiento de la Propiedad. Conforme a ello la Gestora de Arriendos procederá a enviar al Arrendatario el contrato de arrendamiento de la Propiedad, debidamente firmado por el Propietario, para que este sea firmado a su vez por el propio Arrendatario y por sus avales y codeudores solidarios, si los hubiere. El Arrendatario dispondrá de un plazo de cuarenta y ocho (48) para obtener las firmas en el contrato, con firma digital mediante Tango, tanto suya como de los avales y codeudores solidarios, y para proceder al pago del mes de garantía, y del valor de la renta correspondiente a los días del primer mes calendario del contrato de arrendamiento. Estos pagos se deberán realizar en la forma, condición y plazo, debidamente establecidos al efecto en el contrato de arrendamiento mismo, y conforme las facultades de la Gestora de Pagos, establecidas en el contrato de administración, cuando ello así proceda conforme al servicio que corresponda prestar a las Empresas. Vencido este plazo sin que el Arrendatario obtenga la totalidad de las firmas en el contrato, o sin que se realice el pago íntegro de la garantía y/o de los días del primer mes del contrato de arrendamiento, la propuesta se entenderá como retractada por el Arrendatario.</p>'
                        +'<p>En caso de que la propuesta sea expresa o tácitamente rechazada por cualquiera de las partes, en cualquier parte de este proceso, la Propiedad se mantendrá publicada en Tango, para recibir nuevas ofertas. </p>'
                        +'<p>La firma de los contratos se realizará de forma digital, mediante las herramientas propias de Tango, o de terceros e integradas a esta. Para ello será requisito necesario el que el Usuario correspondiente (y los avales y codeudores solidarios, en su caso), cuenten con cédulas de identidad vigentes, y con un dispositivo celular con cámara debidamente habilitada. Ello, como requisito técnico para el uso de las herramientas de toma de firma y verificación de identidad, empleadas para la firma de los contratos. </p>'
                        +'<p>Los contratos de arrendamiento y de administración, en su caso, y todos señalados en lo precedente, serán siempre redactados y gestionados por la Gestora de Arriendos. Estos contratos son y serán entendidos, conforme a la aceptación de los presentes Términos, tanto por el Propietario como por el Arrendatario, como contratos de adhesión, no existiendo derecho a los Usuarios para modificar de forma alguna tales contratos, y debiendo aceptarlos de forma pura y simple para que sean estos suscritos por las Empresas. Estos contratos tendrán vigencia a partir de cuándo se hayan verificado todas las siguientes condiciones copulativas:</p>'
                        +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                               +'<p>Que el contrato de administración, cuando corresponda su firma, se encuentre debidamente firmado por el Propietario</p>'
                               +'<p>Que el contrato de arrendamiento se encuentre debidamente firmado por el Propietario, el Arrendatario, y todos los avales y codeudores solidarios, si los hubiere</p>'
                               +'<p>Que el mes de garantía del arrendamiento de la Propiedad se encuentre pagado</p>'
                               +'<p>Que la renta de arrendamiento por los días del primer mes calendario del arrendamiento de la Propiedad se encuentre pagada</p>'                                         
                            +'</div>'
                        +'</div>'
                        +'</ol>'
                        +'<p>Sin perjuicio de la fecha de entrada en vigencia de los contratos, estos tendrán validez y efecto retroactivos, a la fecha o momento que cada contrato en particular indique, o en su defecto, a la fecha que se hubiere indicado al efecto en la oferta de arrendamiento por parte del Arrendatario al Propietario, para todos los efectos legales.</p>'
                        +'<p>La Propiedad será entregada al Arrendatario por la Gestora de Arriendos, por medio de su propio personal al efecto, y mediante levantamiento de un acta de entrega la cual constatará el estado de conservación de la Propiedad y el inventario de bienes que se encuentren dentro de esta. Dicha acta quedará en poder de todas las Empresas, y a disposición para mera lectura por parte tanto del Propietario como del Arrendatario, junto con los contratos de arrendamiento y de administración, respectivamente, en sus propias Cuentas de Usuario.</p>'
                        +'<p>Los servicios de la Corredora y de la Gestora de Arriendos, todos descritos en los puntos precedentes, y respecto de la Propiedad, se entenderán como prestados a cabalidad, y por ende terminados para todos los efectos legales, con el acto formal de entrega de la Propiedad al Arrendatario, respectivamente y en su caso para cada una de ellas.</p>'
                       +'</li>'
                    +'<li><p><strong>Reajuste a valores de arriendo en Propiedades sujetas a administración</strong></p>'
                        +'<p>Existiendo un contrato de arrendamiento vigente conforme a estos Términos, debidamente sujeto a un contrato de administración, entonces, y para efectos de su renovación por nuevos periodos y el eventual reajuste al valor de arrendamiento de estos, la Administradora de Arriendos podrá, conforme a las funcionalidades de Tango, llevar a cabo procesos de reajuste de valores de arriendo.</p>'
                        +'<p>Para ello:</p>'
                        +'<ol>'
                        +'<div class="list-wrap">'
                            +'<div class="list">'
                               +'<p>Cuando resten 90 (noventa) días de vigencia al periodo en curso del contrato, la Empresa informará al Propietario una sugerencia de reajuste para la renta de arrendamiento de la Propiedad; propuesta cuya base será:</p>'                                    
                                +'<p>Si la renta de arrendamiento se encuentra pactada en pesos, mediante la conversión de su valor a Unidades de Fomento al primer día del periodo de arrendamiento respectivo, y su reconversión a pesos al primero del nuevo periodo de arrendamiento por venir.</p>'
                                +'<p>Si la renta se encuentra pactada en unidades de fomento, mediante la mantención de la cantidad de unidades de fomento pactadas, aplicables ahora al nuevo periodo de arrendamiento por venir.</p>'                                       
                               +'<p>Se le otorgará al Propietario un plazo de 10 (diez) días corridos para aceptar lo sugerido por la Empresa, fijar un reajuste por un valor distinto, o no renovar el contrato de arriendo para un nuevo periodo. Su silencio vencido el plazo hará entender que acepta el reajuste sugerido por la Empresa.</p>'
                               +'<p>Tomada una decisión expresa o tácita por parte del Propietario, distinta de la no renovación del contrato, se dará aviso al Arrendatario por parte de la Empresa, y tendrá este un plazo de 10 (diez) días corridos para aceptar lo propuesto, contraproponer un reajuste por un valor distinto, o no renovar el contrato de arriendo para un nuevo periodo. Su silencio vencido el plazo hará entender que acepta el reajuste ofrecido.</p>'
                               +'<p>Tomada una decisión expresa o tácita por parte del Arrendatario, distinta de la no renovación del contrato, se dará aviso al Propietario por parte de la Empresa, y tendrá el Propietario un nuevo plazo de 10 (diez) días corridos para aceptar lo contrapropuesto por el Arrendatario, rechazar la contrapropuesta del Arrendatario pero prosperar en renovar el contrato, o no renovar el contrato de arriendo para un nuevo periodo. Su silencio vencido el plazo hará entender que acepta la contrapropuesta del Arrendatario.</p>'                                         
                            +'</div>'
                        +'</div>'
                        +'</ol>'
                        +'<p>Todo ello por intermedio de Tango, y siendo lo acordado por las partes en el respectivo arrendamiento sobre la Propiedad vinculante entre ambas. Así, lo acordado por las partes, sea expresa o tácitamente al efecto, se entenderá como acuerdo de sus voluntades sobre la renovación o no renovación del contrato, y/o del reajuste o no reajuste del valor de su renta de arrendamiento para un nuevo periodo de contrato, respectivamente; todo ello conforme a los alcances y condiciones del respectivo contrato de arrendamiento que existiera entre las partes.</p>'
                        +'<p>De ocurrir que, al final de este procedimiento, ninguna de las partes hubiera decidido no renovar el contrato, pero no hubieran aceptado y acordado el reajuste al valor de la renta de arrendamiento, la Empresa procederá a ejecutar una instancia de mediación entre el Propietario y el Arrendatario, por un término o plazo fatal de 15 (quince) días corridos, con el objeto de acordar la renovación o no renovación, y la renta de arrendamiento, en su caso, para el contrato de arrendamiento. Todo ello conforme a las facultades otorgadas en el respectivo contrato de de administración. Siendo de su deber acercar a las partes a una solución respecto de esta materia, y proponer a ambas partes una propuesta no vinculante de reajuste al valor de la renta de arrendamiento.</p>'
                    +'</li>'
                +'</ol>'
            +'</li>' 
        +'</ol>'
+'</section>'


+'<section>'
    +'<ol style="counter-reset: item 6;">'
        +'<h2><li>SERVICIO DE ADMINISTRACIÓN DE ARRIENDOS Y GESTIÓN DE PAGOS</h2>'
            +'<p>Los servicios de administración de arriendos por parte de la Administradora de Arriendos, y de gestión de pagos por parte de la Gestora de Pagos, se prestarán, en lo principal, conforme a las disposiciones, modalidades, condiciones y plazos, que se encuentren establecidos en el contrato de administración a que refieren los presentes Términos.</p>'
            +'<p>Los presentes Términos, en cuanto no fueren contrarios a la naturaleza del contrato de administración, servirán de anexo a dicho contrato, conforme los propios Términos y el mismo contrato lo establezcan. </p>'
            +'</li>'
    +'</ol>'
+'</section>'
+'<section>'
    +'<ol style="counter-reset: item 7;">'
        +'<h2><li>SERVICIO DE REFERERIDOS</h2>'
        +'<p>El servicio de referidos corresponde a un módulo o función puesto a disposición de los Usuarios por parte de la Empresa para que puedan estos referir Tango, como plataforma de servicios, a personas que aún no tengan una Cuenta de Usuario en la misma, y que tengan bienes inmuebles que puedan ser ofertados como Propiedades, por ellos en calidad de Propietarios, y de conformidad con estos Términos.</p>'
        +'<p>Para efectos de este servicio la Empresa:</p>'
        +'<ol>'
            +'<li>'
            +'<p>Requerirá del Usuario el que este incorpore a su Cuenta de Usuario una cuenta corriente, o una cuenta vista, válida y vigente, contratada con un banco chileno o entidad financiera chilena debidamente válida y vigente. Ello, en el acto de registro de la Cuenta de Usuario, o previo al uso del servicio al que refiere el presente punto.</p>'
            +'</li>'
            +'<li>'
                +'<p>Habilitará para el Usuario un módulo o función dentro de Tango, accesible a través de la Cuenta de Usuario respectiva, para generar un link o enlace al sitio web de Tango, para la creación de una nueva Cuenta de Usuario.</p>'
            +'</li>'
            +'<p class="clear-padding">El Usuario que hubiera usado el módulo o función de referidos (en adelante también indistintamente referido como el “Referenciador”) para ofrecer a una persona el incorporarse a Tango en calidad de Usuario (en adelante también indistintamente referido como el “Referido”), recibirá de parte de la Empresa un pago, por una única vez por cada Referido que, por seguir el enlace generado por el Referenciador: i) se incorpore a Tango, ii) registre una Cuenta de Usuario en este sistema, iii) publique su primera Propiedad, iv) la arriende por intermedio de Tango, v) mediante un contrato de arrendamiento sujeto a la administración de la Administradora de Arriendos, y vi) reciba por ella el pago de su primera renta de arrendamiento por parte de un Arrendatario.</p>'
            +'<p class="clear-padding">El pago al Referenciador corresponderá a un valor equivalente al 10% (diez por ciento) del valor de que corresponda al primer pago hecho por el primer Arrendador de la primera Propiedad del Referido que sea arrendada y administrada a través de los servicios de colocación y administración de arriendos propios de Tango.</p>'
            +'<p class="clear-padding">Este precio por Referido a que el Referenciador tenga derecho se pagará, por la Empresa:</p>'
            +'<li>'
                +'<p>En la medida que el mes de arriendo que sea base para su cálculo haya sido pagado, real, material, y efectivamente por el Arrendatario que hubiera tomado en arriendo la Propiedad.</p>'
            +'</li>'
            +'<li>'
                +'<p>Mediante transferencia electrónica bancaria o depósito bancario, a la cuenta corriente, vista, u otra, que el Referenciador tenga debidamente indicada y registrada en su Cuenta de Usuario al efecto.</p>'
            +'</li>'
            +'<li>'
                +'<p>Al día 15 (quince) de del mes, respecto de cualquier precio que por esta causa pudiera deberse al Referenciador, por arriendos del Referido que hayan entrado en vigencia y respecto de los cuales se haya pagado su primer mes de arriendo, ello dentro de los días 15 del mes inmediatamente anterior al de la fecha de pago, y 14 del mes de la fecha de pago. El día de pago, de caer en un día sábado, domingo, o feriado, se prorrogará hasta el día hábil bancario siguiente.</p>'
            +'</li>'
        +'</ol>'
        +'</li>'
    +'</ol>'             
+'</section>'
+'<section>'
    +'<ol style="counter-reset: item 8;">'
        +'<h2><li>PRIVACIDAD E INFORMACIÓN</h2>'
            +'<ol>'
                +'<li><p><strong>Recopilación de datos</strong></p>'
                    +'<p>La Empresa, conforme a los permisos otorgados por el Usuario y los actos de ingreso de datos realizados por este, podrá —directamente y mediante el uso de los sistemas y funciones de Tango y/o mediante herramientas de recopilación de datos integradas a Tango— hacer recolección, almacenamiento y análisis de datos generados por instancias de Uso. Incluyendo con ello datos tales como (pero no restringidos a) sus datos de contacto, la composición de su grupo familiar, su ocupación o profesión, su tenencia de productos bancarios y la entidad con que estos se encuentren contratados, cédulas de identidad, liquidaciones de sueldo, cartolas bancarias, entre otros.</p>'
                +'</li>'
                +'<li><p><strong>Almacenamiento y uso de información</strong></p>'
                    +'<p>Los datos recolectados del Usuario serán debidamente resguardados por la Empresa, la cual tendrá derecho a almacenarla, acceder a ella, entregarla en calidad de información confidencial a cualquiera de las Empresas, y compartirla con terceros ajenos a las Empresas; esto último siempre de forma encriptada, siendo los datos transferidos de forma agregada para respetar la privacidad y anonimato del Usuario, y siempre conforme sea estrictamente necesario para el cumplimiento de los compromisos comerciales y contractuales de las Empresas con dichos terceros, bajo un modelo de <i>“necesidad de saber”</i>.</p>'
                    +'<p>De cualquier modo, las Empresas deberán velar por resguardar la razonable confidencialidad y anonimato de los Usuarios, fuera de Tango, y fuera del marco de los servicios que presten al Usuario. Para tal efecto, la información que resulte necesario compartir con terceros, lo será siempre en la medida que existan condiciones contractuales suficientes con tales terceros para garantizar la debida confidencialidad y seguridad de la información. </p>'
                    +'<p>A solicitud del Usuario la Empresa le dispondrá de su información personal, con indicación del tipo de datos recolectados, fecha de su recolección, estado de almacenamiento, uso dado a la información, y copia de los mismos datos recolectados que la componen.</p>'
                    +'<p>Los datos podrán ser destruidos por la Empresa, más allá de toda recuperación, lectura y/o uso, inmediatamente con posterioridad al cierre y cese de Tango, o en cualquier momento una vez vencido un plazo de 6 meses, contado desde el último Uso registrado del Usuario, o contado desde el cierre de su Cuenta de Usuario.</p>'
                +'</li>'
                +'<li><p><strong>Seguridad de datos</strong></p>'
                    +'<p>Las Empresas resguardarán internamente la integridad y protección de la información de sus Usuarios, y en su recopilación, verificarán siempre que la implementación de aplicaciones de recopilación de datos en Tango sea con aquellos terceros que cuentan igualmente con debidos y razonables medios de seguridad y protección de datos. </p>'
                    +'<p>A pesar de ello, el Usuario declara entender que las Empresas no pueden garantizar la infalibilidad total de la seguridad empleada en el manejo de la información, y en especial frente a actos de terceros que, siendo imprevisibles o irresistibles, ya sea en su naturaleza, forma u oportunidad, terminen por vulnerar o superar toda medida razonable de seguridad dispuesta por las Empresas o por terceros, según sea del caso. </p>'
                    +'<p>De cualquier modo, toda filtración de información o vulneración de seguridad sobre la misma será notificada al Usuarios afectado, a la mayor brevedad posible desde la ocurrencia de tal hecho.</p>'
                +'</li>'
                 +'<li><p><strong>Privacidad e información infantil</strong></p>'
                    +'<p>Sin perjuicio de que la calidad de Usuario en Tango hace necesaria la mayoría de edad legal y la capacidad legal para contratar, todo ello según lo establece la ley que rige estos mismos Términos, y con la exclusiva finalidad de cumplir con los estándares y requerimientos adecuados para todo sistema o herramienta de uso en línea, y los demás que apliquen para las herramientas de terceros integradas a Tango, en su caso; todo ello conforme con la legislación actual, nacional e internacional, en la materia, e incluyendo las normativas de la COPPA y GDPR que sean aplicables para Usuarios residentes (temporal o permanentemente) en el extranjero (y, por ende, fuera del límite territorial de la República de Chile), es que la Empresa establece y declara expresamente que no procederá, en ningún momento, a requerir o solicitar información de ninguna persona menor de 16 años de edad.</p>'
                    +'<p>En el caso de que la Empresa identifique información recibida de parte del Usuario como aquella a su vez perteneciente a una persona menor de 16 años, la Empresa procederá de inmediato a destruir estos datos más allá de toda recuperación o restauración. En el caso de que sea así determinado que el propio Usuario es una persona menor de 16 años, la Empresa procederá adicionalmente al cierre de la respectiva Cuenta de Usuario.</p>'
                +'</li>'
                +'<li><p><strong>Información publicitaria dirigida</strong></p>'
                    +'<p>La Empresa podrá enviar información publicitaria dirigida al Usuario, por medio de su casilla de correo registrada en su Cuenta de Usuario y/o por medio de prompts y notificaciones en Tango.</p>'
                    +'<p>El Usuario podrá requerir a la Empresa, por escrito, el cese de todo envío de esta información publicitaria a su casilla de correo electrónico. Para tal efecto, la Empresa deberá asegurarse de que todo mensaje con información publicitaria que le sea enviada al Usuario a su casilla de correo electrónico contenga mención clara del derecho que corresponda para solicitar el cese de envío de información; sin perjuicio de que pueda ser reasumida en el futuro, a solicitud del Usuario.</p>'
                +'</li>'
            +'</ol>'
            +'</li>'
    +'</ol>'                   
+'</section>'


+'<section>'
    +'<ol style="counter-reset: item 9;">'
        +'<h2><li>OTRAS CONDICIONES GENERALES</h2>'
            +'<ol>'
                +'<li><p><strong>Descuentos especiales</strong></p>'
                    +'<p>La Empresa podrá, según determine para el caso concreto, ofrecer o aplicar descuentos sobre los precios aplicables a los distintos servicios descritos en los presentes Términos, de forma especial y para Propiedades específicas y particulares.</p>'
                    +'<p>Estos descuentos especiales a Propiedades específicas y particulares no serán aplicados en forma conjunta con cualquier otro descuento general establecido de forma amplia por la Empresa para sus servicios a distintas Propiedades, sino en forma alternativa y en reemplazo de dichos descuentos generales.</p>'
                    +'<p>Así, una misma Propiedad no podrá recibir dos descuentos distintos en forma simultánea, y de existir dos descuentos, el descuento especial al que refiere este punto de los Términos aplicará en sustitución y reemplazo de cualquier otro descuento, y con exclusión de cualquier otro descuento.</p>'
                +'</li>'
                +'<li><p><strong>Notificaciones y avisos</strong></p>'
                    +'<p>Para efectos de los presentes Términos, las notificaciones y avisos para y con el Usuario se realizarán por escrito, sea mediante carta certificada enviada a su domicilio registrado en su Cuenta de Usuario, mediante correo electrónico a su casilla de correo electrónico registrada en su Cuenta de Usuario, y/o mediante avisos emergentes en el dispositivo o equipo donde se haga Uso de Tango.</p>'
                +'</li>'
                +'<li><p><strong>Modificación de los Términos</strong></p>'
                    +'<p>La Empresa podrá, en cualquier momento, y por cualquier razón o causa, incluyendo su mera liberalidad o arbitrio, hacer modificaciones unilaterales a los presentes Términos; y el Uso de Tango por parte del Usuario una vez informado de un cambio o modificación de los Términos constituirá una nueva aceptación tácita de los mismos, para todos los efectos legales; sin perjuicio de la aceptación expresa que pueda ser requerida y/o voluntariamente dada por el Usuario en su oportunidad.</p>'
                +'</li>'
                +'<li><p><strong>Derecho reservado de negativa de Uso, revocación de accesos, y Cierre de Cuentas de Usuario</strong></p>'
                    +'<p>Las Empresas podrán, según las características propias de las Propiedades las condiciones comerciales establecidas por sus Propietarios para su oferta y arrendamiento, la evaluación hecha por la Empresa de la capacidad de crédito del Usuario, por el incumplimiento del Usuario de una o más de las condiciones de estos Términos, o por la negativa total o parcial del Usuario a los Términos, decidir rehusar uno o más de los servicios descritos en los presentes Términos, así como también poner término unilateral a la relación contractual que estos mismos constituyen entre ambas partes. Ello, mediante aviso por escrito al respectivo Usuario, de forma inmediata, y sin más trámite. </p>'
                +'</li>'
                 +'<li><p><strong>Cesión de derechos</strong></p>'
                    +'<p>La Empresa se reserva el derecho a ceder a terceros, y a cualquier título, Tango. Incluyendo con ello todos los datos y toda la información contenidos o asociados a Tango. Ello, con la obligación de dar aviso y notificación al Usuario.</p>'
                    +'<p>El Uso de Tango por parte del Usuario una vez informada tal cesión o transferencia de derechos a terceros constituirá nueva aceptación de los Términos, y respecto de quien detente la titularidad y propiedad sobre el Tango en calidad de contraparte al vinculo legal que los Términos constituyen para el Usuario; sin perjuicio de la aceptación expresa que pueda ser requerida y/o voluntariamente dada por el Usuario en su oportunidad.</p>'
                +'</li>'
                +'<li><p><strong>Naturaleza contractual de los Términos</strong></p>'
                    +'<p>Los presentes Términos constituyen, desde el acto de su aceptación, sea expresa o tácita, un contrato válido y vinculante con el Usuario, para la prestación de los servicios de la Gestora de Arriendos, agotándose en este mismo los términos y condiciones de tal servicio.</p>'
                    +'<p>Por su parte, los presentes Términos constituirán, en virtud de su aceptación, sea expresa o tácita, un contrato anexo o anexo de contrato, válido y vinculante con el Usuario, a los contratos de servicios de corretaje, de arrendamiento sobre Propiedades, y de servicios de administración de arriendos, según corresponda, y que sean suscritos por los Usuarios entre sí, y con las distintas Empresas, en su caso. Ello, por cuanto los servicios de las Empresas se prestarán, total o parcialmente, por medio de Tango, siendo así aplicable a tales servicios los presentes Términos en todo en cuanto regulan el Uso de Tango.</p>'
                    +'<p>Los presentes Términos, en cuanto constitutivos de anexo de contrato, o de contrato anexo, a los contratos de arrendamiento y de administración referidos precedentemente, aplicarán a estos de forma supletoria y en todo en cuanto no resultaren contrarios a su naturaleza o su esencia.</p>'
                    +'<p>El incumplimiento del Usuario a todo o parte de los presentes Términos, o la negativa a los mismos, sea en todo o en parte, constituirá así un incumplimiento de contrato respecto de los contratos de arrendamiento y de administración respectivos, para todos y cada uno de los efectos legales.</p>'
                +'</li>'
                +'<li><p><strong>Divisibilidad</strong></p>'
                     +'<p>La nulidad de que puedan eventualmente adolecer una o más de las condiciones de estos Términos no producirá la nulidad completa de los mismos, salvo en cuanto exista una sentencia judicial, firme y ejecutoriada, en tal sentido. En tal caso, de cualquier modo, ello sólo afectará aquellas cláusulas o condiciones expresamente declaradas nulas, sobreviviendo así los presentes Términos a la declaración de nulidad en todas las demás disposiciones que no adolecieran de tal nulidad.</p>' 
                +'</li>'
                +'<li><p><strong>No renuncia de derechos</strong></p>'
                     +'<p>El no ejercicio de un derecho por parte de las Empresas, de aquellos otorgados por medio de estos Términos, será siempre considerado un acto de mera liberalidad, y no hará entender ni presumir de forma alguna una renuncia a los mismos, sea en todo o en parte.</p>' 
                +'</li>'
                +'<li><p><strong>Propiedad intelectual, industrial, y uso de marcas comerciales</strong></p>'
                     +'<p>Todos los activos intangibles disponibles en Tango, y los demás elementos asociados a los mismos, tales como marcas comerciales, logotipos, nombres de fantasía, imágenes, piezas de diseño gráfico, código computacional, u otros similares, en cuanto sean objeto de derechos intelectuales o industriales, son y permanecerán en todo momento de propiedad y titularidad de la Empresa o de sus respectivos propietarios y titulares, en su caso. Éstos últimos, conforme a los términos en que hayan sido, en su caso, licenciados en uso a la Empresa, y según las condiciones particulares pactadas al respecto. Así, el Uso de Tango no otorga al Usuario licencia o derecho alguno sobre todo o parte de estos elementos sujetos a propiedad intelectual o industrial.</p>' 
                +'</li>'
                +'<li><p><strong>Ley aplicable y solución de controversias</strong></p>'
                     +'<p>Los presentes Términos se regirán bajo la ley de la República de Chile, y salvo lo dispuesto en contrario en el contrato de arriendo y/o de administración, en su caso, y según correspondiente, todo conflicto o controversia que pueda suscitarse entre el Usuarios y una o más de las Empresas, se someterá a la jurisdicción de los tribunales ordinarios de justicia de Santiago.</p>' 
                +'</li>'
            +'</ol>'
            +'</li>'
    +'</ol>'                   
+'</section>'
+'</div>'


export default termsAndConditions
