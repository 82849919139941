import React, { useState } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface CustomToolTipProps {
    bodyText?: any;
    headerText?: string;
    icon?: string;
    classImage?: string;
    popOver?: boolean;
    toolTipText?: string;
    children?: any;
    arrowPlacement?: any;
    iconClose?: any;
}
/**
 * This prop is similar to 'CustomToolTipProps' but 'CustomToolTipPropsTypes' is for the documentation
 * @typedef CustomToolTipPropsTypes
 * @type {(string|boolean)}
 * @property {string} bodyText - is a string.
 * @property {string} headerText - is a string.
 * @property {string} icon - is a string.
 * @property {string} classImage - is a string.
 * @property {boolean} popOver - is a boolean.
 * @property {string} toolTipText - is a string.
 * @property {string} arrowPlacement - is any since the library does not accept a prop type string.
 */
const width = window.innerWidth;
const TextOnlyTooltip = withStyles({
    tooltip: {
        color: 'black',
        backgroundColor: 'white',
        border: '2px solid #A8123E',
        fontSize: '14px',
        borderRadius: '7px',
        top: '0 !important',
        width: '90% !important',
        marginLeft: '5% !important',
        marginRight: '5% !important',
        padding: '1.5625rem',
    },
    arrow: {
        fontSize: 22,
        bottom: '0 !important',
        marginRight: 'calc(5% + 1.125rem) !important',
        '&::before': {
            backgroundColor: 'white',
            border: '2px solid #A8123E',
        },
    },
})(Tooltip);

const ChildrenTooltip = withStyles({
    tooltip: {
        color: '#333',
        backgroundColor: 'white',
        border: '2px solid #A8123E',
        fontSize: width >= 768 ? '14px' : '12px',
        borderRadius: '7px',
        top: '36px !important',
        minWidth: width >= 768 ? '50vw' : '98vw',
        maxWidth: width >= 768 ? '50vw' : '98vw',
        marginLeft: '-1px',
    },
    arrow: {
        '&::before': {
            backgroundColor: 'white',
            border: '2px solid #A8123E',
        },
    },
})(Tooltip);

/**
 * CustomToolTip is a functional component
 *@function
 *@param {CustomToolTipPropsTypes}  bodyText - this is the text that will be in the body of the tooltip
 *@param {CustomToolTipPropsTypes}  headerText - this is the text that will be in the head of the tooltip
 *@param {CustomToolTipPropsTypes}  icon - a simple icon which is imported into the image
 *@param {CustomToolTipPropsTypes}  classImage - class name for he image
 *@param {CustomToolTipPropsTypes}  popOver - if is true user can click on the tooltip
 *@param {CustomToolTipPropsTypes}  toolTipText - this is the text that will be displayed to open the tooltip
 *@param {CustomToolTipPropsTypes}  arrowPlacement - this prop sets the placement of the tooltip arrow
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const CustomToolTip = ({
    bodyText = '',
    headerText = '',
    icon,
    classImage = '',
    toolTipText = '',
    popOver = false,
    arrowPlacement = 'top',
    children,
    iconClose,
}: CustomToolTipProps) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const sizeWidth = useWindowSize().width;
    const closeTooltip = () => setOpenTooltip(false);
    const ref = useDetectClickOutside({ onTriggered: closeTooltip });
    const negativeMargins = width <= 768 ? 'negative-m-2' : 'negative-m-1';
    const BodyTooltip = () => {
        return (
            <div className={`d-flex flex-column ${children && negativeMargins}`}>
                {headerText && (
                    <div className='d-flex justify-content-between'>
                        <p className='font-weight-bold'>{headerText}</p>
                    </div>
                )}
                {iconClose && (
                    <div id='icon-close-tooltip-container' className='d-flex flex-row-reverse'>
                        <figure className='m-0' id='icon-close-tooltip' onClick={closeTooltip}>
                            <img src={iconClose} alt='iconClose' />
                        </figure>
                    </div>
                )}
                <span>{bodyText}</span>
                {children}
            </div>
        );
    };
    const iconTooltip = () => (
        <span>
            {icon && icon !== '*' ? (
                <img src={icon} alt='alertIcon' className={classImage} />
            ) : toolTipText ? (
                <span className='underline-link'>{toolTipText}</span>
            ) : (
                icon
            )}
        </span>
    );

    return popOver ? (
        <>
            {children ? (
                <ChildrenTooltip
                    ref={ref}
                    title={<BodyTooltip />}
                    placement={arrowPlacement}
                    arrow
                    open={openTooltip}
                    onClick={() => setOpenTooltip(!openTooltip)}
                    className='pointer'
                >
                    {iconTooltip()}
                </ChildrenTooltip>
            ) : (
                <TextOnlyTooltip
                    ref={ref}
                    title={<BodyTooltip />}
                    placement={arrowPlacement}
                    arrow
                    open={openTooltip}
                    onClick={() => setOpenTooltip(!openTooltip)}
                    className='pointer'
                >
                    {iconTooltip()}
                </TextOnlyTooltip>
            )}
        </>
    ) : (
        <TextOnlyTooltip ref={ref} title={<BodyTooltip />} placement={arrowPlacement} arrow>
            {iconTooltip()}
        </TextOnlyTooltip>
    );
};

export default CustomToolTip;
