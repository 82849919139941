export const updateFileStart = (fileIndex: string) => {
    return {
        type: 'UPDATE_FILE_STARTED',
        payload: { fileIndex },
    };
};
export const updateFileSuccess = (file: any) => {
    return {
        type: 'UPDATE_FILE_SUCCESS',
        payload: { file },
    };
};
export const updateFileError = (error: any, fileIndex: string) => {
    return {
        type: 'UPDATE_FILE_ERROR',
        payload: {
            error,
            fileIndex,
        },
    };
};
