import { getRentStateStart, getRentStateError, getRentStateSuccess } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { getDataGeneral } from '../../helpers/apiConnections';

const getRentState = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getRentStateStart());
        getDataGeneral(`users/${id}/attributes/config_attr`)
            .then((result) => {
                dispatch(getRentStateSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRentStateError(e));
            });
    };
};
export default getRentState;
