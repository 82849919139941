export default {
    CONTRACT_STATES_TITLE: 'Here you can know the meaning of each contract state',
    EMITTED_STATE: 'Emitted',
    SIGNED_STATE: 'Signed',
    ACTIVE_STATE: 'In force',
    NULLIFIED_STATE: 'Nullified', 
    CANCELED_STATE: 'Canceled', 
    NOTIFIED_STATE: 'Renewal process started',
    NEGOTIATING_STATE_2: 'Negotiating renewal', 
    RENEWAL_ACCEPTED_STATE: 'Renewal accepted', 
    RENEWAL_REJECTED_STATE: 'Renewal rejected',
    RENEWAL_ACTIVE_STATE: 'Renewal in force', 
    RENEWAL_MEDIATING_STATE: 'Mediation process started', 
    FINISHED_STATE: 'Finished',
    EMITTED_STATE_EXPLANATION: 'The contract has not been signed by all the parties',
    SIGNED_STATE_EXPLANATION: 'It is already signed by all parties but it is not yet in force',
    ACTIVE_STATE_EXPLANATION: 'It is in force and there is no renewal period in progress',
    NULLIFIED_STATE_EXPLANATION: 'It was not signed by any or all parties within 48 hours', 
    CANCELED_STATE_EXPLANATION: 'Owner decided to sign with another renter', 
    NOTIFIED_STATE_EXPLANATION: 'Owner and renter have been notified of the start of the lease renewal process (from 90 days before the end of the lease period)',
    NEGOTIATING_STATE_EXPLANATION: 'Owner or renter has provided an answer or is waiting for their counterpart, and the renewal has not been accepted by both or rejected', 
    RENEWAL_ACCEPTED_STATE_EXPLANATION: 'Owner and renter accepted the lease renewal', 
    RENEWAL_REJECTED_STATE_EXPLANATION: 'Owner or renter accepted the lease renewal',
    RENEWAL_ACTIVE_STATE_EXPLANATION: 'The lease was extended for another period and the previous one has already expired',
    RENEWAL_MEDIATING_STATE_EXPLANATION: 'Owner has rejected renter counterproposal or admin has proposed a new lease price',  
    FINISHED_STATE_EXPLANATION: 'Lease terminated due to non-renewal between owner and renter',
  }