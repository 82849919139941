import React from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import Input from '../../input'
import { DatePicker } from '../..'
import SwitchString from '../../switchString'
import intl from 'react-intl-universal'
import {
  EMPLOYEE_OPTIONS,
  BANK_ACCOUNT_OPTIONS,
  BOOLEAN_OPTIONS,
  RELATIONSHIP_OPTIONS,
  SOCIOECONOMIC_SEGMENT_OPTIONS,
  FAMILY_GROUP,
  MARITAL_STATUS_OPTIONS,
  NUMBER_OPTIONS,
  CURRENCY_OPTIONS,
} from '../../../../tango-react-base/helpers/selectOptions/options'
import Select from '../../select'
import PriceInput from '../../priceInput'
import { IncomeData } from './IncomeData.type'
import RadioButtonTango from '../../radioButtonTango'
import { FormValidation, FormValidationRenter } from './formValidation'
import MapAutoCompleteProfile from '../../mapAutoCompleteProfiles'
import UploadFileTruoraDicom from '../fileTruoraDicom/uploadFileTruoraDicom'
import IdentityDocument from '../identityDocument'
import { isRoleAdmin } from '../../../helpers/roleComparison/index'
import moment from 'moment'
import { formatDates } from '../../../helpers/date/index'
import { EditAttributes } from '@material-ui/icons'

/**
 * this prop is similar to 'IncomeDataFormProps' but 'IncomeDataFormPropsTypes' is for the documentation
 * @typedef IncomeDataFormPropsTypes
 * @type {(string|object|boolean|number|Array)}
 * @property {string} onSubmit - is a boolean
 * @property {string} idUser - is a string
 * @property {object} children - is a object
 * @property {boolean}  disabled - is a boolean
 * @property {number}  keyNumber - is a number
 * @property {object}  data - is a object
 * @property {boolean}  isExpired - is a boolean
 * @property {object}  renterState - is a Object
 * @property {Array}  filterTruora - is a Array
 * @property {Array}  filterDicom - is a Array
 * @property {boolean}  documentsConfirmation - is a boolean
 * @property {Function}  handleClick - is a Function
 * @property {Function}  openModalFinish - is a Function
 * @property {Function}  redirectToPost - is a Function
 * @property {Function}  backToPost - is a Function
 * @property {Function}  onClose - is a Function
 * @property {boolean}  disabledDocument - is a boolean
 * @property {boolean}  isExpiredDocument - is a boolean
 * @property {object}  value - is a object
 * @property {number}  userDataId - is a number
 * @property {string}  type - is a string
 * @property {number}  idForm - is a number
 * @property {number}  renterId - is a number
 * @property {Function}  setOpen - is a Function
 * @property {Function}  setDisableInputs - is a Function
 * @property {Array}  renterProfiles - is a Array
 * @property {boolean}  requiredInRenter - is a boolean
 * @property {object}  renterValidations - is a object
 * @property {boolean}  renterStatePending - is a boolean
 * @property {boolean}  validAddress - is a boolean
 * @property {object}  identity_document - is a object.
 * @property {object}  renterProfileType - is a object.
 */
interface IncomeDataFormProps {
  onSubmit: any
  idUser?: string
  children: any
  disabled: boolean
  keyNumber?: number
  data: any
  isExpired?: boolean
  renterState?: Object
  filterTruora?: Array<Object> | any
  filterDicom?: Array<Object> | any
  documentsConfirmation: any
  handleClick: any
  openModalFinish: any
  redirectToPost: any
  backToPost: any
  onClose: any
  disabledDocument: any
  isExpiredDocument: any
  value: any
  userDataId: any
  type: any
  idForm: any
  renterId: number
  setOpen: any
  setDisableInputs: any
  renterProfiles?: any
  requiredInRenter?: boolean
  renterValidations?: any
  renterStatePending?: any
  validAddress: boolean
  identity_document?: any
  renterProfileType?: any
  bankResource: any
  renter?: any
  dataContract: any
}

var oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18)
const isAdmin = isRoleAdmin

const InputClass = 'col-md-6 plm-0'

/**
 * InnerForm is a functional component
 *@function
 *@param {IncomeDataFormPropsTypes} values - return all form values
 *@param {IncomeDataFormPropsTypes} errors - returns an object with errors
 *@param {IncomeDataFormPropsTypes} handleChange - this is a function detects changed values
 *@param {IncomeDataFormPropsTypes} handleBlur - this is a function detects blur values
 *@param {IncomeDataFormPropsTypes} touched - object with values touched
 *@param {IncomeDataFormPropsTypes} data - object with all countries
 *@param {IncomeDataFormPropsTypes} children - return children with body
 *@param {IncomeDataFormPropsTypes} disabled - return a boolean with disabled prop
 *@param {IncomeDataFormPropsTypes} keyNumber - is key of the form
 *@param {IncomeDataFormPropsTypes} isExpired - return expired state
 *@param {IncomeDataFormPropsTypes} renterState - return renter state
 *@param {IncomeDataFormPropsTypes} filterTruora - array with troura document
 *@param {IncomeDataFormPropsTypes} filterDicom - array with dicom document
 *@param {IncomeDataFormPropsTypes} renterId - id of renter
 *@param {IncomeDataFormPropsTypes} requiredInRenter -
 *@param {IncomeDataFormPropsTypes} validAddress - return a boolean if address is valid
 *@param {IncomeDataFormPropsTypes} identity_document - object with identity document
 *@param {IncomeDataFormPropsTypes} renterProfileType - type of current renter profile
 * @returns {(ReactComponent)} Returns a react component with a functional component (form)
 */
const InnerForm: React.FunctionComponent<
  IncomeDataFormProps & FormikProps<IncomeData>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  data, // the countries from the api
  children,
  disabled,
  keyNumber = 0,
  isExpired,
  renterState,
  filterTruora,
  filterDicom,
  renterId,
  requiredInRenter,
  validAddress,
  identity_document,
  renterProfileType,
  bankResource,
  renter,
  dataContract,
}) => {
  const BANK_RESOURCE = bankResource
  return (
    <div>
      <Form>
        {values.profile_type !== 'cosigner' && (
          <>
            <h2 className='new-subtitle'>{intl.get('PERSONAL_INFORMATION')}</h2>
          </>
        )}
        {values.profile_type === 'cosigner' && (
          <>
            <Select
              options={RELATIONSHIP_OPTIONS}
              id='original_renter_relationship'
              onChange={handleChange}
              label={'RENTER_RELATIONSHIP'}
              value={values.original_renter_relationship}
              disabled={disabled}
            />
            {values.original_renter_relationship === 'OTHER' && (
              <Input
                id='renter_relationship_other'
                label={'RENTER_RELATIONSHIP'}
                type='text'
                value={values.renter_relationship_other}
                error={errors.renter_relationship_other}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.renter_relationship_other}
                disabled={disabled}
              />
            )}
          </>
        )}
        <Input
          id='names'
          label='NAMES'
          type='text'
          value={values.names}
          error={errors.names}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.names}
          disabled={disabled || isRoleAdmin}
          className='my-4'
          classNameInput={InputClass}
          justifyCenter='align-items-center'
        />
        <Input
          id='surnames'
          label='SURNAMES'
          type='text'
          value={values.surnames}
          error={errors.surnames}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.surnames}
          className='my-4'
          disabled={disabled || isRoleAdmin}
          classNameInput={InputClass}
          justifyCenter='align-items-center'
        />
        <Input
          id='rut'
          label='RUT'
          type='text'
          value={values.rut || dataContract.rut}
          error={errors.rut}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.rut}
          disabled={true}
          className='my-4'
          classNameInput={InputClass}
          justifyCenter='align-items-center'
        />
        <DatePicker
          name='birth_date'
          disableToolbar={disabled || isRoleAdmin}
          value={
            values.birth_date || moment(values.birth_date_by_id, 'DD/MM/YYYY')
          }
          label='BIRTHDATE'
          maxDate={oneYearFromNow}
          disabled={disabled || isRoleAdmin}
          className='my-4'
          classNameInput={InputClass}
          info={intl.get('MUST_BE_OVER_18')}
        />
        <Select
          options={MARITAL_STATUS_OPTIONS}
          id='marital_status'
          onChange={handleChange}
          label={'MARITAL_STATUS'.toUpperCase()}
          value={values.marital_status}
          disabled={isExpired ? false : disabled}
          classNameInput={InputClass}
          classNameSelect={
            disabled ? 'col-12 select-style disabled' : 'col-12 select-style'
          }
        />
        <Select
          options={data}
          id='country_of_residence'
          onChange={handleChange}
          label={'COUNTRY_OF_RESIDENCE'.toUpperCase()}
          value={values.country_of_residence}
          disabled={isExpired ? false : disabled}
          classNameInput={InputClass}
          classNameSelect={
            disabled ? 'col-12 select-style disabled' : 'col-12 select-style'
          }
          resource={true}
        />
        <Select
          options={data}
          id='nationality'
          onChange={handleChange}
          label={'NATIONALITY'.toUpperCase()}
          value={values.nationality}
          disabled={isExpired ? false : disabled}
          classNameInput={InputClass}
          classNameSelect={`col-12 select-style${disabled ? ' disabled' : ''}`}
          resource={true}
        />
        {disabled ? (
          <Input
            id='street_address'
            label='EXACT_ADDRESS'
            type='text'
            required={!requiredInRenter}
            value={values.street_address}
            error={errors.street_address}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.street_address}
            disabled={isExpired ? false : disabled}
            className='my-4'
            classNameInput='col-md-6 col-12 plm-0'
            justifyCenter='align-items-center'
            info={'SELECT_ADDRESS'}
          />
        ) : (
          <MapAutoCompleteProfile
            values={values.street_address}
            id={'street_address'}
            label={'EXACT_ADDRESS'}
            touched={touched.street_address}
            type='text'
            disabled={isExpired ? false : disabled}
            error={errors.street_address}
            allValues={values}
            className='my-4'
            classNameInput='col-md-6 plm-0'
            countrySelected={values.country_of_residence}
            validAddress={validAddress}
            address={values.street_address}
            info={'SELECT_ADDRESS'}
          />
        )}

        <Input
          id='street_address_extra'
          label='STREET_ADDRESS_EXTRA'
          type='text'
          value={values.street_address_extra}
          error={errors.street_address_extra}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.street_address_extra}
          disabled={isExpired ? false : disabled}
          className='my-4'
          classNameInput={InputClass}
          justifyCenter='align-items-center'
          info='EXTRA_ADDRESS_INFO'
        />
        <RadioButtonTango
          id='family_group'
          required={!requiredInRenter}
          options={FAMILY_GROUP}
          label='FAMILY_GROUP'
          valueChecked={values.family_group}
          onChange={handleChange}
          disabled={isExpired ? false : disabled}
          keyNumber={keyNumber}
          classNameInputContainer='col-md-8 plm-0'
        />
        <SwitchString
          value={values.animals}
          options={BOOLEAN_OPTIONS}
          name='animals'
          label='HAS_PET'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          disabled={isExpired ? false : disabled}
        />
        <Input
          id='ocupation'
          label='OCUPATION'
          type='text'
          required={!requiredInRenter}
          value={values.ocupation}
          error={errors.ocupation}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.ocupation}
          disabled={isExpired ? false : disabled}
          className='my-4'
          classNameInput={InputClass}
          justifyCenter='align-items-center'
        />
        <RadioButtonTango
          id='employee'
          options={EMPLOYEE_OPTIONS}
          label='EMPLOYEE'
          valueChecked={values.employee}
          onChange={handleChange}
          disabled={isExpired ? false : disabled}
          keyNumber={keyNumber}
          classNameInputContainer='col-md-8 plm-0'
        />
        {values.employee === 'dependent' ||
        values.employee === 'independent' ? (
          <>
            <Input
              id='employee_age'
              label='YEARS_EMPLOYEE'
              type='number'
              min='0'
              value={values.employee_age}
              messageAdditionalValidation={errors.employee_age}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.employee_age}
              disabled={isExpired ? false : disabled}
              className='my-4'
              classNameInput={InputClass}
              justifyCenter='align-items-center'
            >
              <span className='span-text'>{intl.get('YEAR')}</span>
            </Input>
          </>
        ) : null}
        <SwitchString
          value={values.bank_account}
          options={BOOLEAN_OPTIONS}
          name='bank_account'
          label='BANK_ACCOUNT'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          disabled={isExpired ? false : isAdmin ? true : disabled}
        />
        {values.bank_account === 'yes' && (
          <>
            <RadioButtonTango
              id='bank_account_type'
              options={BANK_ACCOUNT_OPTIONS}
              label='BANK_ACCOUNT_TYPE'
              valueChecked={values.bank_account_type}
              onChange={handleChange}
              disabled={isExpired ? false : isAdmin ? true : disabled}
              keyNumber={keyNumber}
              className='my-4'
              classNameInput={InputClass}
            />
            <Select
              options={BANK_RESOURCE}
              id='bank_name'
              onChange={handleChange}
              label={'BANK_NAME'.toUpperCase()}
              value={values.bank_name}
              disabled={isExpired ? false : isAdmin ? true : disabled}
              classNameInput={InputClass}
              resource={true}
              classNameSelect={
                disabled
                  ? 'col-12 select-style disabled'
                  : 'col-12 select-style'
              }
            />
          </>
        )}
        { keyNumber < 2 && (
          <SwitchString
            value={values.cosigners === 'other' ? 'yes' : values.cosigners}
            options={BOOLEAN_OPTIONS}
            name='cosigners'
            label='SIGN_WITH_SOMEONE'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={isExpired ? false : disabled}
            info='INFO_COSINGER'
          />
        )}
        {isAdmin && (
          <>
            <PriceInput
              id='average_income_last_3_months_amount'
              id_currency='average_income_last_3_months_currency'
              currency_options={CURRENCY_OPTIONS}
              currency_iso_value={values.average_income_last_3_months_currency}
              label='AVERAGE_INCOME_LAST_3'
              value={values.average_income_last_3_months_amount}
              onChange={handleChange}
              onBlur={handleBlur}
              min='0'
              disabled={disabled}
            />
            <div
              className='template-line'
              style={{ backgroundColor: `#A8123E` }}
            />
            <h2 className='text-left'>Dicom</h2>
            <section className='pr-0 mr-0 text-left plm-0'>
              <DatePicker
                name='report_date'
                value={values.report_date}
                label='REPORT_DATE'
                disabled={disabled}
              />
              <Select
                options={SOCIOECONOMIC_SEGMENT_OPTIONS}
                id='socioeconomic_level'
                onChange={handleChange}
                label={'SOCIOECONOMIC_SEGMENT'}
                value={values.socioeconomic_level}
                disabled={disabled}
              />
              <h3>{intl.get('ACCREDITATION')}</h3>
              <section className='pr-0 mr-0 plm-0'>
                <Input
                  id='vehicles_number'
                  label='VEHICLES_NUMBER'
                  type='number'
                  min='0'
                  value={values.vehicles_number}
                  messageAdditionalValidation={errors.vehicles_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.vehicles_number}
                  disabled={disabled}
                />
                <Input
                  id='properties_number'
                  label='PROPERTIES_NUMBER'
                  type='number'
                  min='0'
                  value={values.properties_number}
                  messageAdditionalValidation={errors.properties_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.properties_number}
                  disabled={disabled}
                />
                <PriceInput
                  id='tax_assessment_amount'
                  id_currency='tax_assessment_currency'
                  currency_options={CURRENCY_OPTIONS}
                  currency_iso_value={values.tax_assessment_currency}
                  label='TAX_ASSESSMENT'
                  value={values.tax_assessment_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min='0'
                  disabled={disabled}
                />
                <Input
                  id='partner_relationships'
                  label='PARTNER_RELATIONSHIPS'
                  type='number'
                  min='0'
                  value={values.partner_relationships}
                  messageAdditionalValidation={errors.partner_relationships}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.partner_relationships}
                  disabled={disabled}
                />
                <Input
                  id='non_payment_orders'
                  label='NON_PAYMENT_ORDERS'
                  type='number'
                  min='0'
                  value={values.non_payment_orders}
                  messageAdditionalValidation={errors.non_payment_orders}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.non_payment_orders}
                  disabled={disabled}
                />
                <Input
                  id='tax_annotations'
                  label='TAX_ANNOTATIONS'
                  type='text'
                  value={values.tax_annotations}
                  messageAdditionalValidation={errors.tax_annotations}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.tax_annotations}
                  disabled={disabled}
                />
              </section>
              <h3>{intl.get('SCORE')}</h3>
              <section className='pr-0 mr-0 plm-0'>
                <Input
                  id='current_score'
                  label='CURRENT_SCORE'
                  type='number'
                  min='0'
                  max='1000'
                  value={values.current_score}
                  messageAdditionalValidation={errors.current_score}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.current_score}
                  disabled={disabled}
                />
                <Input
                  id='average_score_last_12_months'
                  label='AVERAGE_SCORE_LAST_12_MONTHS'
                  type='number'
                  min='0'
                  max='1000'
                  value={values.average_score_last_12_months}
                  messageAdditionalValidation={
                    errors.average_score_last_12_months
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.average_score_last_12_months}
                  disabled={disabled}
                />
                <SwitchString
                  value={values.everclean_indicator}
                  options={BOOLEAN_OPTIONS}
                  name='everclean_indicator'
                  label='EVERCLEAN_INDICATOR'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={disabled}
                />
              </section>

              <h3>{intl.get('PROTESTS_DELINQUENCIES')}</h3>
              <section className='pr-0 pb-0 mr-0 plm-0'>
                <SwitchString
                  value={values.protestDelinquencies}
                  options={BOOLEAN_OPTIONS}
                  name='protestDelinquencies'
                  label='QUESTION_PROTESTS_DELINQUENCIES'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={disabled}
                />
              </section>
              {values.protestDelinquencies === 'yes' && (
                <section className='pr-0 mr-0 plm-0'>
                  <Input
                    id='unpaid_total_debts'
                    label='UNPAID_TOTAL_DEBTS'
                    type='number'
                    min='0'
                    max='1000'
                    value={values.unpaid_total_debts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    messageAdditionalValidation={errors.unpaid_total_debts}
                    touched={touched.unpaid_total_debts}
                    disabled={disabled}
                  />
                  <PriceInput
                    id='unpaid_debts_total_amount_amount'
                    id_currency='unpaid_debts_total_amount_currency'
                    currency_options={CURRENCY_OPTIONS}
                    currency_iso_value={
                      values.unpaid_debts_total_amount_currency
                    }
                    label='UNPAID_DEBTS_TOTAL_AMOUNT'
                    value={values.unpaid_debts_total_amount_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min='0'
                    disabled={disabled}
                  />
                  <DatePicker
                    name='last_payment_due'
                    value={values.last_payment_due}
                    label='LAST_PAYMENT_DUE'
                    disabled={disabled}
                  />
                  <Input
                    id='last_payment_debt_type'
                    label='LAST_PAYMENT_DEBT_TYPE'
                    type='text'
                    value={values.last_payment_debt_type}
                    error={errors.last_payment_debt_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.last_payment_debt_type}
                    disabled={disabled}
                  />
                  <PriceInput
                    id='last_payment_amount_amount'
                    id_currency='last_payment_amount_currency'
                    currency_options={CURRENCY_OPTIONS}
                    currency_iso_value={values.last_payment_amount_currency}
                    label='LAST_PAYMENT_AMOUNT'
                    value={values.last_payment_amount_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min='0'
                    disabled={disabled}
                  />
                  <Input
                    id='debt_unpaid_detail'
                    label='DEBT_UNPAID_DETAIL'
                    type='text'
                    value={values.debt_unpaid_detail}
                    error={errors.debt_unpaid_detail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.debt_unpaid_detail}
                    disabled={disabled}
                  />
                  <Input
                    id='job_newsletter'
                    label='JOB_NEWSLETTER'
                    type='text'
                    value={values.job_newsletter}
                    error={errors.job_newsletter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.job_newsletter}
                    disabled={disabled}
                  />
                </section>
              )}
            </section>
            <>
              <div
                className='template-line'
                style={{ backgroundColor: `#A8123E` }}
              />
              <h2>{intl.get('TRUORA_SCORE')}</h2>
              <section className='pr-0 mr-0 plm-0'>
                <DatePicker
                  name='truora_report_date'
                  value={values.truora_report_date}
                  label='REPORT_DATE'
                  disabled={disabled}
                  classNameInput='col-3'
                />
                <RadioButtonTango
                  id='truora_trust_level'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_TRUST_LEVEL'
                  valueChecked={values.truora_trust_level}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />
                <RadioButtonTango
                  id='truora_name_score'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_NAME_SCORE'
                  valueChecked={values.truora_name_score}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_id_score'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_ID_SCORE'
                  valueChecked={values.truora_id_score}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_identity'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_IDENTITY'
                  valueChecked={values.truora_identity}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_penal_and_criminal'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_PENAL_AND_CRIMINAL'
                  valueChecked={values.truora_penal_and_criminal}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_legal'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_LEGAL'
                  valueChecked={values.truora_legal}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_international'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_INTERNATIONAL'
                  valueChecked={values.truora_international}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_media'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_MEDIA'
                  valueChecked={values.truora_media}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />

                <RadioButtonTango
                  id='truora_taxes_and_finance'
                  options={NUMBER_OPTIONS}
                  label='TRUORA_TAXES_AND_FINANCE'
                  valueChecked={values.truora_taxes_and_finance}
                  onChange={handleChange}
                  disabled={isExpired ? false : disabled}
                  keyNumber={keyNumber}
                  classNameInputContainer='col-md-8 plm-0'
                />
                <SwitchString
                  value={values.truora_criminal_record}
                  options={BOOLEAN_OPTIONS}
                  name='truora_criminal_record'
                  label='CRIMINAL_RECORD'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={disabled}
                />
                <SwitchString
                  value={values.truora_lawsuits}
                  options={BOOLEAN_OPTIONS}
                  name='truora_lawsuits'
                  label='LAWSUITS'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={disabled}
                />
                <Input
                  id='truora_observations'
                  label='TRUORA_OBSERVATIONS'
                  type='text'
                  value={values.truora_observations}
                  error={errors.truora_observations}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.truora_observations}
                  disabled={disabled}
                  multiline={true}
                  classNameInput='col-12 col-md-7'
                />
              </section>
              {values.profile_type !== 'cosigner' && (
                <>
                  <div
                    className='template-line'
                    style={{ backgroundColor: `#A8123E` }}
                  />
                  <h2>Tango</h2>
                  <section className='pr-0 mr-0 plm-0'>
                    <Input
                      id='tango_points'
                      label='TANGO_POINTS'
                      type='number'
                      value={values.tango_points}
                      messageAdditionalValidation={errors.tango_points}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.tango_points}
                      disabled={disabled}
                    />
                  </section>
                </>
              )}
              <UploadFileTruoraDicom
                renterId={renterId}
                filterDicom={filterDicom}
                filterTruora={filterTruora}
                renterState={renterState}
              />
            </>
            <div className='mb-4'>
              <div className='template-line' />
              <h2 className='new-subtitle'>
                {intl.get('IDENTITY_DOCUMENT_TITLE')}
              </h2>
              <SwitchString
                value={values.valid_id_card}
                options={BOOLEAN_OPTIONS}
                name='valid_id_card'
                label='VALID_ID_CARD'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
                classSpan='span-text pl-4 plm-0 col-8 mr-2 text-break'
              />
              {renterProfileType === 'signer' && (
                <IdentityDocument identityDocument={identity_document} />
              )}
            </div>
          </>
        )}
        {children}
      </Form>
    </div>
  )
}

const IncomeDataForm = withFormik<IncomeDataFormProps, IncomeData>({
  mapPropsToValues: (props: any) => {
    const initialValuesExpired = {
      street_address: '',
      street_address_extra: '',
      family_group: '',
      ocupation: '',
      employee: '',
      employee_age: '',
      bank_account_type: '',
      acceptedAddress: props.acceptedAddress,
    }
    const initialValuesProtests = {
      unpaid_total_debts: props.unpaid_total_debts || 0,
      last_payment_debt_type: props.last_payment_debt_type || '0',
      debt_unpaid_detail: props.debt_unpaid_detail || '0',
      job_newsletter: props.job_newsletter || '0',
      protestDelinquencies:
        (props.unpaid_total_debts === 0 || !props.unpaid_total_debts) &&
        props.unpaid_debts_total_amount_amount === 0 &&
        (props.last_payment_debt_type === '0' ||
          !props.last_payment_debt_type) &&
        props.last_payment_amount_amount === 0 &&
        (props.debt_unpaid_detail === '0' || !props.debt_unpaid_detail) &&
        (props.job_newsletter === '0' || !props.job_newsletter)
          ? 'no'
          : 'yes',
    }
    if (props.isExpired) {
      return {
        ...props,
        ...initialValuesExpired,
        ...initialValuesProtests,
        phone_number: props.phone_number !== undefined && props.phone_number,
        idUser: props.idUser,
        onSubmit: props.onSubmit,
        last_payment_due: props.last_payment_due || null,
        report_date: props.report_date ? props.report_date : new Date(),
        acceptedAddress: props.acceptedAddress,
      }
    } else {
      return {
        ...props,
        ...initialValuesProtests,
        phone_number: props.phone_number !== undefined && props.phone_number,
        idUser: props.idUser,
        onSubmit: props.onSubmit,
        last_payment_due: props.last_payment_due || null,
        report_date: props.report_date ? props.report_date : new Date(),
        acceptedAddress: props.acceptedAddress,
      }
    }
  },

  validationSchema: process.env.ADMIN ? FormValidation : FormValidationRenter,

  handleSubmit(values: IncomeData, { props, setSubmitting, setErrors }) {
    if (values.protestDelinquencies === 'no') {
      if (values.unpaid_total_debts) {
        values.unpaid_total_debts = 0
      }
      if (values.unpaid_debts_total_amount_amount) {
        values.unpaid_debts_total_amount_amount = 0
      }
      if (values.last_payment_due) {
        values.last_payment_due = null
      }
      if (values.last_payment_debt_type) {
        values.last_payment_debt_type = '0'
      }
      if (values.last_payment_amount_amount) {
        values.last_payment_amount_amount = 0
      }
      if (values.debt_unpaid_detail) {
        values.debt_unpaid_detail = '0'
      }
      if (values.job_newsletter) {
        values.job_newsletter = '0'
      }
    }
    props.onSubmit(values)
  },
})(InnerForm)

export default IncomeDataForm
