import { updateFileStart, updateFileSuccess, updateFileError } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import putDataMultipart from '../../../tango-react-base/helpers/apiConnections/putDataMultipart';
import { handleError } from '../../helpers/handleErrors';
const updatePdfTruoraDicom = (file: File, id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(updateFileStart('WORKING'));
        let form_data = new FormData();
        form_data.append(`renter_document[file]`, file);
        putDataMultipart(`renter_documents/${id}`, form_data)
            .then((result) => {
                toast(intl.get('FILE_UPDATED'));
                dispatch(updateFileSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updateFileError(e, 'ERROR'));
            });
    };
};
export default updatePdfTruoraDicom;
