import * as Yup from 'yup';
import { setLocale } from 'yup';
import intl from 'react-intl-universal';

const FormValidationTourOrVisit = () => {
    setLocale({
        mixed: {
            required: intl.get('REQUIRED_FIELD'),
            notType: intl.get('INVALID_FORMAT'),
        },
        string: {
            email: intl.get('INSERT_VALID_MAIL'),
            min: intl.get('INSERT_MORE_CHARACTERS'),
        },
    });

    return Yup.object().shape({
        blocks: Yup.string().required(),
        startDate: Yup.date().required(),
        propertyUrl: Yup.string(),
        email: Yup.string().email(),
        fullAddress: Yup.string(),
    });
};
export default FormValidationTourOrVisit;
