export const getNotificationStart = () => {
    return {
        type: 'GET_NOTIFICATION_STARTED',
    };
};

export const getNotificationSuc = (data: any) => {
    return {
        type: 'GET_NOTIFICATION_SUCCESS',
        payload: data,
    };
};

export const getNotificationError = (error: any) => {
    return {
        type: 'NOTIFICATION_ERROR',
        payload: {
            error,
        },
    };
};
