export const validateEmailPresenceStart = () => {
  return {
    type: 'VALIDATE_EMAIL_PRESENCE_START',
  }
}

export const validateEmailPresenceSuccess = (data: any) => {
  return {
    type: 'VALIDATE_EMAIL_PRESENCE_SUCCESS',
    payload: { data },
  }
}

export const validateEmailPresenceError = (error: any) => {
  return {
    type: 'VALIDATE_EMAIL_PRESENCE_ERROR',
    payload: {
      error,
    },
  }
}
