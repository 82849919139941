import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import moment from 'moment';
import { toast } from 'react-toastify';
import { setTemplateChild } from '../../reduxActions';
import CreateTourOrVisitForm from './CreateTourOrVisit';
import newVisitOrTour from '../../reduxActions/newTourOrVisit/index';
import { DialogModal } from '..';
import { getParameterByName } from '../../helpers/getParamsUrl';

type createTourProps = {
    onSetTemplateChild: (child: Object) => void;
    onCreateNewVisitOrTour: any;
    statusNewTourOrVisit: any;
    availableBlocks: any;
};

/**
 * Here it is defined the type of the tour/visit, this prop is similar to 'createTourProps' but 'createTourPropsTypes' is for the documentation
 * @typedef createTourPropsTypes
 * @type {(function|object)}
 * @property {function} onCreateNewVisitOrTour - is a function.
 * @property {function} onSetTemplateChild - is a function.
 * @property {object} statusNewTourOrVisit - is a object.
 * @property {object} availableBlocks - is a object.
 */

/**
 * CreateNewTourOrVisit is a functional component
 *@function
 *@param {createTourPropsTypes}  onCreateNewVisitOrTour - Creates new tour or visit.
 *@param {createTourPropsTypes}  onSetTemplateChild - returns a template
 *@param {createTourPropsTypes}  statusNewTourOrVisit - returns status of the new tour
 *@param {createTourPropsTypes}  availableBlocks - returns an object with available blocks
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const CreateNewTourOrVisit = ({
    onSetTemplateChild,
    onCreateNewVisitOrTour,
    statusNewTourOrVisit,
    availableBlocks,
}: createTourProps) => {
    const history = useHistory();
    const [actualBlock, setActualBlock] = useState('');
    const [avlaState, setAvlaState] = useState('accepted');

    let detectNumber = /(\d+)/g;
    const renterEmail = getParameterByName('renter_email', history.location.search) || '';
    const postUrl = getParameterByName('post_url', history.location.search) || '';
    const visitKind = getParameterByName('visit_kind', history.location.search) || '';

    const isNotPhotographer = process.env.REACT_APP_API_ROLE_APP !== process.env.REACT_APP_VERIFIER;

    useEffect(() => {
        if (!sessionStorage.getItem('authorization')) return history.push('/login');
        else {
            onSetTemplateChild(<></>);
        }
    }, [onSetTemplateChild]);

    const onSubmit = (values: any) => {
        let data: Array<Object> = [];

        const newStartDate = `${moment(values.startDate).format('YYYY-MM-DD')} ${values.blocks.split(' -')[0]}:00`;
        const postId = values.propertyUrl.match(detectNumber) || '';
        if (postId && isNotPhotographer) {
            data.push({
                block: newStartDate,
                email: values.email,
                post_id: parseInt(postId[0]),
                kind: values.visitType,
                avla_state: avlaState,
            });
        } else if (postId && !isNotPhotographer) {
            data.push({
                block: newStartDate,
                post_id: parseInt(postId[0]),
                avla_state: avlaState,
            });
        } else {
            toast(intl.get('INVALID_LINK'));
        }

        if (data[0]) {
            onCreateNewVisitOrTour(data[0], history);
        }
    };

    let isBlockTaken = !!availableBlocks;
    const countOfBlocks = availableBlocks && availableBlocks.length;

    /**
     * AvailabeBlocks is a functional component
     * @function
     * @returns {(ReactComponent)} Returns a react component with a functional component
     */
    const AvailabeBlocks = () => {
        return (
            <>
                <div className='template-line' />
                <h2 className='new-subtitle '>{intl.get('HOURS_AVAILABLE')}</h2>
                <div className='d-flex flex-wrap justify-content-center'>
                    {availableBlocks.map((item: any, id: any) => {
                        const block = `${moment(item.attributes.start_time).format('HH:mm')} - ${moment(
                            item.attributes.end_time,
                        ).format('HH:mm')}`;
                        return (
                            <ToggleButton
                                value='check'
                                selected={actualBlock === block}
                                disabled={false}
                                key={id}
                                onChange={() => setActualBlock(block)}
                                className='button-blocks mr-3 mt-2'
                            >
                                <p className='columns font-sm-07'>
                                    <br />
                                    <span className='text-color-tertiary'>{block}</span>
                                </p>
                            </ToggleButton>
                        );
                    })}
                </div>
            </>
        );
    };
    const [openModal, setOpenModal] = useState(false);
    const ShowModal = () => {
        return (
            <DialogModal
                title={intl.get('NOT_AVAILABLE_BLOCKS')}
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >
                <p>{intl.get('THIS_BLOCK_IS_NOT_AVAILABLE')}</p>
            </DialogModal>
        );
    };

    useEffect(() => {
        if (statusNewTourOrVisit === 'ERROR' && countOfBlocks > 0) {
            return setOpenModal(true);
        }

        if (countOfBlocks === 0 && statusNewTourOrVisit === 'ERROR') {
            toast(intl.get('BLOCKS_NO_AVAILABLE'));
        }
    }, [statusNewTourOrVisit]);

    return (
        <div className='container-home pd-1'>
            <div className='row d-flex justify-content-left'>
                <div className='pb-4'>
                    <div className='row'>
                        <h2 className='title text-left'>{intl.get('NEW_VISIT_DATA')}</h2>
                    </div>
                    <div className='row'>
                        <div className='template-line'></div>
                    </div>
                </div>
            </div>
            {/* It will only be seen in test cases, in the environments: dev, dev-b, qa and loca development */}
            {process.env.REACT_APP_FAKE_AVLA_DATA === 'true' && (
                <div className='d-flex justify-content-center'>
                    <div className='w-50'>
                        <span className='pr-2'>Estado AVLA:</span>
                        <select value={avlaState} onChange={(e: any) => setAvlaState(e.target.value)}>
                            <option value='accepted' selected>
                                Accepted
                            </option>
                            <option value='rejected'>Rejected</option>
                            <option value='failed'>Failed</option>
                        </select>
                    </div>
                </div>
            )}

            <CreateTourOrVisitForm
                onSubmit={onSubmit}
                renterEmail={renterEmail}
                postUrl={postUrl}
                visitKind={visitKind}
                isBlockTaken={(isBlockTaken && countOfBlocks > 0) || statusNewTourOrVisit === 'STARTED'}
                actualBlock={actualBlock}
            >
                <p className='text-left text-color-secondary'>{intl.get('REMEBER_HOURS_AVAILABLE')}</p>
                {isBlockTaken && countOfBlocks > 0 && <AvailabeBlocks />}

                <div className='row d-flex justify-content-end'>
                    <button
                        className={'button-primary col-md-2 mt-3 ml-3 mr-sm-0'}
                        type='submit'
                        disabled={statusNewTourOrVisit === 'STARTED'}
                    >
                        {statusNewTourOrVisit !== 'STARTED' ? (
                            intl.get('ASSIGN_VISIT')
                        ) : (
                            <div className='spinner-border text-secondary' role='status'></div>
                        )}
                    </button>
                </div>
            </CreateTourOrVisitForm>
            <ShowModal />
        </div>
    );
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
    return {
        statusNewTourOrVisit: state.createNewTourOrVisit.status,
        availableBlocks: state.createNewTourOrVisit.blocks,
    };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onSetTemplateChild: (child: Object) => {
            return dispatch(setTemplateChild(child, '/'));
        },
        onCreateNewVisitOrTour: (values: Array<any>, history: any) => {
            return dispatch(newVisitOrTour(values, history));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTourOrVisit);
