/**
 * This function return the value of the url parameters
 * @function
 * @returns {(string)} value that is type string
 */
export const getParameterByName = (name: string, url: string) => {
    const finalUrl = url && url.includes('%26') ? url.replace('%26', '&') : url;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(finalUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
};
