export const acceptMediationStart = () => {
    return {
        type: 'MEDIATION_RESPONSE_STARTED',
    };
};

export const acceptMediationSuccess = () => {
    return {
        type: 'MEDIATION_RESPONSE_SUCCESS',
    };
};

export const acceptMediationError = (error: any) => {
    return {
        type: 'MEDIATION_RESPONSE_ERROR',
        payload: error,
    };
};
