import React from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { Input } from '../..'
import intl from 'react-intl-universal'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import getNewToken from '../../../reduxActions/getNewToken/index'
import DialogModal from '../../dialogModal/index'

interface ForgetPutTokenFormProps {
  onSubmit: any
  idUser?: string
  children?: any
  disabled: boolean
  params: any
  setLengthToken?: any
}

interface ForgetPutTokenForm {
  token: string
}
const InnerForm: React.FunctionComponent<
  ForgetPutTokenFormProps & FormikProps<ForgetPutTokenForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  setLengthToken,
  params,
}) => {
  const path = useLocation().pathname
  const email = params.email
  const validationPath = path && path.split('/')[1] !== 'forget_password'
  const dispatch = useDispatch()
  const { fetchUserToken, error } = useSelector((state: any) => state.user)
  const [popUpOpen, setpopUpOpen] = React.useState(false)

  const handleNewToken = () => {
    dispatch(getNewToken(email))
    setpopUpOpen(false)
  }

  React.useEffect(() => {
    if (fetchUserToken === 'FECHING_ERROR' && error) {
      setpopUpOpen(true)
    }
  }, [error, fetchUserToken])

  const RenderPopup = () => (
    <DialogModal
      title={intl.get('OH_SORRY')}
      open={popUpOpen}
      handleClose={() => setpopUpOpen(false)}
    >
      <div className='p-3'>
        <p className='mb-0'>{error}</p>
        <p className='text-dark'>
          {intl.getHTML('CLICK')}
          <span className='text-select underline-link' onClick={handleNewToken}>
            {intl.get('HERE_2')}
          </span>
          {intl.get('VALIDATION_CODE_EXPIRED_2')}
        </p>
        <p className='color-secondary p-2'>{intl.get('REMEMBER_TWO_HOURS')}</p>
      </div>
    </DialogModal>
  )
  return (
    <>
      <p>{`${params.email}`}</p>
      <div className='separator-token' />
      <Form>
        <section className='my-5 plm-0'>
          <Input
            id='token'
            label='SECURITY_CODE'
            type='text'
            value={values.token.toUpperCase()}
            error={errors.token}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.token}
            required={true}
            disabled={disabled}
            variant={false}
            className='col-12 mt-1'
            classNameInput='col-12 pl-0'
            classNameLabel='col-12 pl-0 text-color-secondary'
          />
          {validationPath && setLengthToken(values.token.length > 7)}
          {children}
          <RenderPopup />
          <p className='mt-4'>
            {intl.get('TRY_CODE_TOKEN_1')}
            <span className='text-select' onClick={handleNewToken}>
              {intl.get('HERE')}
            </span>
            {intl.get('TRY_CODE_TOKEN_2')}
          </p>
        </section>
      </Form>
    </>
  )
}

const PutTokenForms = withFormik<ForgetPutTokenFormProps, ForgetPutTokenForm>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      token: '',
      email: props.params.email,
    }
    return { ...initialValues, ...props }
  },

  validationSchema: Yup.object().shape({}),

  handleSubmit(
    values: ForgetPutTokenForm,
    { props, setSubmitting, setErrors },
  ) {
    props.onSubmit(values)
  },
})(InnerForm)

export default PutTokenForms
