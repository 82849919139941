import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { isRoleOwner } from '../../helpers/roleComparison';

interface RejectLeaseRenewalViewProps {
    currentStep?: number | string;
    dueDate?: string;
    daysLeft?: number;
}
const RejectLeaseRenewalView = ({ currentStep, dueDate, daysLeft }: RejectLeaseRenewalViewProps) => {
    const owner_steps = [
        'AN_ADMIN_WILL_CONTACT_YOU',
        'PROPERTY_INFORMATION_UPDATE',
        'REPUBLICATION_PROCESS',
        'PROCESS_RESTITUTION',
        'FIND_A_NEW_RENTER',
    ];
    const renter_steps = [
        'AN_ADMIN_WILL_CONTACT_YOU_REJECT',
        'PROCESS_RESTITUTION_REJECT',
        'DELIVERY_CERTIFICATE_REJECT',
        'KEYS_HANDOVER_REJECT',
        'PROFILE_UPDATE_REJECT',
        'FIND_NEW_LEASE_REJECT',
    ];

    return (
        <>
            <div className='template-line mt-5' />
            <h1 className='new-subtitle mb-5 font-weight-bold'>{intl.get('LEASE_RENEWAL')}</h1>
            <div className='text-left'>
                <h2 className='font-weight-bold mt-5 mb-4'>{intl.getHTML('CONTRACT_STATUS_REJECTED')}</h2>
                <p>
                    {intl.getHTML('DAYS_LEFT_FOR_RENEWAL', {
                        days_left: daysLeft,
                        due_at: dueDate,
                    })}
                </p>
                <ol className='ol-custom'>
                    {isRoleOwner
                        ? owner_steps.map((step, i) => (
                              <li className={`${currentStep === i + 1 ? 'current-step' : ''}`} key={step}>
                                  {intl.get(step)}
                              </li>
                          ))
                        : renter_steps.map((step, i) => (
                              <li className={`${currentStep === i + 1 ? 'current-step' : ''}`} key={step}>
                                  {intl.get(step)}
                              </li>
                          ))}
                </ol>
            </div>
        </>
    );
};
const mapStateToProps = (state: any) => {
    // return {
    //   stepRejectLeaseRenewal: state.rejectLeaseRenewal.step,
    // }
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetStepRejectLeaseRenewal: (data: {}) => {
            // dispatch(postRejectLeaseRenewal(data))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RejectLeaseRenewalView);
