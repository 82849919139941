import * as Yup from 'yup';
import { setLocale } from 'yup';
import intl from 'react-intl-universal';

const ValidationForm = () => {
    setLocale({
        mixed: {
            required: intl.get('REQUIRED_FIELD'),
        },
        string: {
            min: intl.get('MUST_BE_GREATER_THAN', { num: '${min}' }),
            email: intl.get('INSERT_VALID_MAIL'),
        },
    });

    return Yup.object().shape({
        email: Yup.string().when('form_sended', {
            is: true,
            then: Yup.string().email().required(),
        }),
        password: Yup.string().when('form_sended', {
            is: true,
            then: Yup.string().min(8).required(),
        }),
        repeat_password: Yup.string().when('form_sended', {
            is: true,
            then: Yup.string()
                .min(8)
                .required()
                .oneOf([Yup.ref('password'), null], intl.get('CHECK_PASS_ERROR')),
        }),
    });
};

export default ValidationForm;
