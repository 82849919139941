export const setTokeNewPasswordStart = () => {
  return {
    type: 'SET_FORGET_PASSWORD_STARTED',
  }
}

export const setTokeNewPasswordSuccess = () => {
  return {
    type: 'SET_FORGET_PASSWORD_SUCCESS',
    // payload: { session }
  }
}

export const setTokeNewPasswordError = (error: any) => {
  return {
    type: 'SET_FORGET_PASSWORD_ERROR',
    payload: {
      error,
    },
  }
}
