let moment = require('moment')

export const getWeeks = (start: any, end: any) => {
  let weekAux: any[] = []
  let day = start
  while (day.isBefore(end)) {
    weekAux.push(day)
    day = day.clone().add(1, 'days')
  }
  return weekAux
}

export const startWeek = moment().startOf('week').add(1, 'days')
export const endWeek = moment().endOf('week').add(1, 'days')