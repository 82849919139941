export const BANK_ACCOUNT_OPTIONS = [
    { value: 'checking_account', label: 'CHECKING_ACCOUNT' },
    { value: 'vista', label: 'VISTA' },
    { value: 'savings_account', label: 'SAVING_ACCOUNT' },
    { value: 'electronic_checkbook', label: 'ELECTRONIC_CHECKBOOK' },
];

export const RELATIONSHIP_OPTIONS = [
    { value: 'spouse', label: 'SPOUSE' },
    { value: 'sibling', label: 'SIBLING' },
    { value: 'parent', label: 'PARENT' },
    { value: 'sibling in law', label: 'SIBLING_IN_LAW' },
    { value: 'parent in law', label: 'PARENT_IN_LAW' },
    { value: "parent's sibling", label: 'UNCLE_AUNT' },
    { value: 'grandparents', label: 'GRANDPARENTS' },
    { value: 'friend', label: 'FRIEND' },
    { value: 'cousin', label: 'COUSIN' },
    { value: "sibling's children ", label: 'NIECE_NEPHEW' },
    { value: 'other', label: 'OTHER' },
];
export const FAMILY_GROUP = [
    { value: 'single', label: 'ALONE' },
    { value: 'family_with_children', label: 'FAMILY_WITH_CHILDREN' },
    { value: 'family_without_children', label: 'FAMILY_WITHOUT_CHILDREN' },
    { value: 'extended_family', label: 'EXTENDED_FAMILY' },
];
export const SOCIOECONOMIC_SEGMENT_OPTIONS = [
    { value: 'AB', label: 'AB' },
    { value: 'C1a', label: 'C1A' },
    { value: 'C1b', label: 'C1B' },
    { value: 'C2', label: 'C2' },
    { value: 'C3', label: 'C3' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
];
export const EMPLOYEE_OPTIONS = [
    { value: 'dependent', label: 'DEPENDENT' },
    { value: 'independent', label: 'INDEPENDENT' },
];
export const BOOLEAN_OPTIONS = ['yes', 'no'];

export const BOOLEAN_OPTIONS_VALUES = [
    { value: 'yes', label: 'yes' },
    { value: 'no', label: 'no' },
];

export const MARITAL_STATUS_OPTIONS = [
    { value: 'none', label: 'SELECT_MARITAL_STATUS' },
    { value: 'single', label: 'SINGLE' },
    { value: 'married', label: 'MARRIED' },
    { value: 'divorced', label: 'DIVORCED' },
    { value: 'widowed', label: 'WIDOWED' },
    { value: 'live_in_partner', label: 'LIVE_IN_PARTNER' },
];

export const NUMBER_OPTIONS = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];

export const EMPLOYEE_DEPENDENT = [
    { type: 'proof_of_job_seniority', label: 'INDEFINIED_CONTRACT' },
    { type: 'salary_settlement', label: 'LAST_SALARY' },
    { type: 'afp_quotation_dependent', label: 'AFP_CERTIFICATE' },
];

export const EMPLOYEE_INDEPENDENT = [
    { type: 'provitional_monthly_payment', label: 'LAST_PROVISIONAL_PAYMENTS' },
    { type: 'form22', label: 'FORM_22' },
    { type: 'afp_quotation_independent', label: 'AFP_CERTIFICATE' },
];

export const YES_NO_OPTIONS = [
    { label: 'YES', value: 'true' },
    { label: 'NO', value: 'false' },
];

export const UNIT_TYPE_OPTIONS = [
    { label: 'HOUSE', value: 'house' },
    { label: 'APARTMENT', value: 'apartment' },
];
export const UNIT_TYPE_OPTIONS_SEARCH = [
    { label: 'ALL_PROPERTY', value: 'all' },
    { label: 'HOUSE', value: 'house' },
    { label: 'APARTMENT', value: 'apartment' },
];

export const UNIT_TYPE_OPTIONS_REGISTER = [
    { label: 'HOUSE', value: 'house' },
    { label: 'APARTMENT', value: 'apartment' },
];

export const TYPE_FLOOR_APARTMENT = [
    { label: 'ONE_FLOOR', value: 1 },
    { label: 'DUPLEX', value: 2 },
    { label: 'THREE_FLOORS', value: 3 },
];

export const COMUNITY_TYPE_OPTIONS = [
    { label: 'CONDOMINIUM', value: 'condominium' },
    { label: 'INDEPENDENT', value: 'independent' },
];

export const HOME_APPLIANCES_OPTIONS = [
    { label: 'REFRIGERATOR', value: 'refrigerator' },
    { label: 'MICROWAVE', value: 'microwave_oven' },
    { label: 'OVEN', value: 'oven' },
    { label: 'STOVE', value: 'stove' },
    { label: 'WASHING_MACHINE', value: 'washing_machine' },
    { label: 'DRY_MACHINE', value: 'dryer' },
    { label: 'DISH_WASHER', value: 'dishwasher' },
];

export const COMMON_SPACES_OPTIONS = [
    { label: 'GYM', value: 'gym' },
    { label: 'OUTDOOR_POOL', value: 'outdoor_pool' },
    { label: 'INDOOR_POOL', value: 'indoor_pool' },
    { label: 'SAUNA', value: 'sauna' },
    { label: 'VISIT_PARKING', value: 'visitor_parking' },
    { label: 'MULTIUSE_ROOM', value: 'multipurpose_room' },
    { label: 'BARBECUE', value: 'barbecue_area' },
    { label: 'CHILDISH_GAMES', value: 'children_games' },
    { label: 'LAUNDRY_ROOM', value: 'laundry' },
    { label: 'BICYCLE_ROOM', value: 'bicycle_rack' },
    { label: 'GARDEN', value: 'garden' },
    { label: 'PET_AREA', value: 'pet_area' },
    { label: 'ROOFTOP', value: 'rooftop' },
    { label: 'COWORK', value: 'cowork' },
    { label: 'CINEMA', value: 'cinema' },
    { label: 'SPORTS_FIELD', value: 'sports_field' },
];

export const BASIC_SERVICES_INCLUDES_OPTIONS = [
    { label: 'WATER', value: 'water' },
    { label: 'ELECTRICITY', value: 'electricity' },
    { label: 'GAS', value: 'gas' },
];

export const BEDROOMS_COUNT_OPTIONS = [
    { label: 'STUDIO', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
];

export const BATHROOM_COUNT_OPTIONS = [
    { label: '1', value: 1 },
    { label: '1.5', value: 1.5 },
    { label: '2', value: 2 },
    { label: '2.5', value: 2.5 },
    { label: '3', value: 3 },
    { label: '3.5', value: 3.5 },
    { label: '4', value: 4 },
    { label: '4.5', value: 4.5 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
];

export const SUITE_BEDROOM_COUNT_OPTIONS = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
];

export const FLOOR_HOUSE_COUNT_OPTIONS = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
];

export const FLOOR_APARTMENT_COUNT_OPTIONS = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
];

export const KITCHEN_OPTIONS = [
    { label: 'CLOSED', value: 'closed' },
    { label: 'OPEN_AMERICAN', value: 'american' },
];

export const RESIDENT_TYPE_OPTIONS = [
    { label: 'I', value: 'myself' },
    { label: 'RENTER', value: 'renter' },
    { label: 'IS_EMPTY', value: 'none' },
];

export const BOOLEANS = [true, false];

export const REMEMBER_BOOLEAN_OPTIONS = [{ label: 'REMEMBER_ME', value: 'true' }];

export const CURRENCY_OPTIONS = [
    { label: 'CLP', value: 'CLP' },
    { label: 'CLF', value: 'CLF' },
];

export const COMMON_EXPENSES_OPTIONS = [
    { label: 'NOT_PAID', value: 'no' },
    { label: 'INCLUDED', value: 'yes' },
    { label: 'PAID_SEPARATELY', value: 'separated' },
];

export const LOADING_OPTIONS = [{ label: 'LOADING', value: '---' }];

export const JANITOR_OPTIONS = [
    { label: 'FULLDAY', value: '24/7' },
    { label: 'ONLY_DAY', value: 'only_day' },
    { label: 'ONLY_NIGHT', value: 'only_night' },
    { label: 'NO_CONCIERGE', value: 'none' },
];

export const OWNER_TYPE_OPTIONS = [
    { label: 'YES', value: 'yes' },
    { label: 'NO', value: 'no' },
    { label: 'SHARED_OTHERS', value: 'shared' },
];

export const REGISTER_TYPE_OPTIONS = [
    { label: 'NEW', value: 'new' },
    { label: 'REGISTERED', value: 'registered' },
    { label: 'BROKERAGE', value: 'brokerage' },
];

export const LIVING_ROOM_OPTIONS = [
    { label: 'TOGETHER', value: 'joined' },
    { label: 'SEPARATED', value: 'separated' },
];

export const PARKING_LOT_OPTIONS = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
];

export const ORIENTATION_OPTIONS = [
    { label: 'NORTH', value: 'north' },
    { label: 'SOUTH', value: 'south' },
    { label: 'WEST', value: 'west' },
    { label: 'EAST', value: 'east' },
];

export const HEATING_OPTIONS = [
    { label: 'ELECTRIC', value: 'electric' },
    { label: 'GAS', value: 'gas' },
    { label: 'NO_HEATING', value: 'none' },
];

export const WATER_HEATING_OPTIONS = [
    { label: 'CENTRAL', value: 'central' },
    { label: 'ELECTRIC_WATER_HEATER', value: 'electric' },
    { label: 'CAULDRON', value: 'cauldron' },
    { label: 'CALEFONT', value: 'calefont' },
    { label: 'OTHER_SYSTEM', value: 'other' },
];

export const THERMALPANELS_OPTIONS = [
    { label: 'YES', value: 'yes' },
    { label: 'NO', value: 'no' },
    { label: 'ONLY_BEDROOMS', value: 'only_bedrooms' },
];

export const BEDROOM_FLOOR_MATERIALS_OPTIONS = [
    { label: 'FLOATING_FLOOR', value: 'floating_floor' },
    { label: 'CARPET', value: 'carpet' },
    { label: 'CERAMIC', value: 'ceramic' },
    { label: 'PORCELAIN', value: 'porcelain' },
    { label: 'PARQUET', value: 'parquet' },
];

export const BATHROOM_FLOOR_MATERIALS_OPTIONS = [
    { label: 'CERAMIC', value: 'ceramic' },
    { label: 'PORCELAIN', value: 'porcelain' },
];

export const KITCHEN_FLOOR_MATERIALS_OPTIONS = [
    { label: 'CERAMIC', value: 'ceramic' },
    { label: 'PORCELAIN', value: 'porcelain' },
];

export const LIVING_FLOOR_MATERIALS_OPTIONS = [
    { label: 'FLOATING_FLOOR', value: 'floating_floor' },
    { label: 'CARPET', value: 'carpet' },
    { label: 'CERAMIC', value: 'ceramic' },
    { label: 'PORCELAIN', value: 'porcelain' },
    { label: 'PARQUET', value: 'parquet' },
];

export const LIVING_HERE_OPTIONS = [
    { label: 'I', value: 'i' },
    { label: 'RENTER', value: 'renter' },
    { label: 'IS_EMPTY', value: 'empty' },
];

export const ORDER_BY_OPTIONS = [
    {
        value: { attribute: '', order: '' },
        label: 'ORDER_BY',
        disabled: true,
    },
    {
        value: { attribute: 'price_amount', order: 'ASC' },
        label: 'ORDER_BY_LOWEST',
    },
    {
        value: { attribute: 'price_amount', order: 'DESC' },
        label: 'ORDER_BY_HIGHEST',
    },
    {
        value: { attribute: 'published_at', order: 'DESC' },
        label: 'ORDER_BY_MOST_RECENT',
    },
    {
        value: { attribute: 'published_at', order: 'ASC' },
        label: 'ORDER_BY_OLDEST',
    },
];

export const MARITAL_STATUS = [
    { value: 'single', label: 'SINGLE' },
    { value: 'married', label: 'MARRIED' },
    { value: 'widowed', label: 'WIDOWED' },
    { value: 'divorced', label: 'DIVORCED' },
    { value: 'live_in_partner', label: 'LIVE_IN_PARTNER' },
];

export const RENTER_STEPS = [
    {
        num: 'STEP_1',
        description: 'SEARCH_PROPERTIES_VERIFIED_LISTINGS',
    },
    {
        num: 'STEP_2',
        description: 'CREATE_PROFILE_SCHEDULE_TOURS',
    },
    {
        num: 'STEP_3',
        description: 'UPLOAD_DOCUMENTS_MAKE_OFFER',
    },
    {
        num: 'STEP_4',
        description: 'SIGN_ONLINE_MAKE_PAYMENT',
    },
    {
        num: 'STEP_5',
        description: 'DELIVERY_AND_REPLACEMENT_HELP',
    },
    {
        num: 'STEP_6',
        description: 'SAVE_MANAGE_SECURITY_DEPOSIT',
    },
];

export const PRICE_OPTIONS = [
    { country: 'chile', price: 200000 },
    { country: 'mexico', price: 300000 },
];

export const LEASE_DOCUMENTS_OPTIONS = [
    { value: 'none', label: 'SELECT_AN_OPTION' },
    { value: 'admin_contract', label: 'ADMIN_CONTRACT' },
    { value: 'admin_contract_annex', label: 'ADMIN_CONTRACT_ANNEX' },
    { value: 'lease_contract', label: 'LEASE_CONTRACT' },
    { value: 'lease_contract_annex', label: 'LEASE_CONTRACT_ANNEX' },
    { value: 'delivery_voucher', label: 'DELIVERY_VOUCHER' },
    { value: 'restoration_voucher', label: 'RESTORATION_VOUCHER' },
    { value: 'renewal_acceptance', label: 'RENEWAL_ACCEPTANCE' },
    { value: 'renewal_rejection', label: 'RENEWAL_REJECTION' },
];

export const VISIT_TYPES = [
    { value: 'tour', label: 'TOUR' },
    { value: 'delivery', label: 'DELIVERY' },
    { value: 'restoration', label: 'RESTORATION' },
];

export const CURRENCY_OPTIONS_FILTER = [
    {
        value: 'ALL',
        label: 'UF / $',
    },
    {
        value: 'CLF',
        label: 'UF',
    },
    {
        value: 'CLP',
        label: '$',
    },
];
