import React, { useState, useEffect, useRef } from 'react';
import { getCommunes } from '../../reduxActions';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { closingModalIcon } from '../../resources';
import { useIntersection } from '../../helpers/useIntersection';
interface communesCardProps {
    openCommunes?: boolean;
    handleCommunesListClose?: any;
    positionClass: string;
    onGetCommunes: () => void;
    communes: any;
    fetchingCommunes: any;
}
const CommunesCard = ({
    openCommunes,
    handleCommunesListClose,
    positionClass,
    onGetCommunes,
    communes,
    fetchingCommunes,
}: communesCardProps) => {
    const [validCommunes, setValidCommunes] = useState([]);
    const [handleShowMore, setHandleShowMore] = useState(false);

    const rowRef: any = useRef(null);
    const communesRef = useRef<HTMLInputElement>(null);
    const communesHalf = Math.round(validCommunes.length / 2);
    const communesCardVisibility = useIntersection(communesRef, '0px');

    useEffect(() => {
        if (!openCommunes) {
            rowRef.current.classList.add('max-height');
            setHandleShowMore(false);
        }
    }, [openCommunes]);

    const handleAction = () => {
        setHandleShowMore(!handleShowMore);
        handleShowMore ? rowRef.current.classList.add('max-height') : rowRef.current.classList.remove('max-height');
    };
    useEffect(() => {
        if (fetchingCommunes === 'FETCHED_COMMUNES') {
            setValidCommunes(communes);
        } else {
            communesCardVisibility && onGetCommunes();
        }
    }, [fetchingCommunes, communesCardVisibility]);

    return (
        <div ref={communesRef} className={`communes-list ${positionClass} ul-style ${openCommunes ? 'show' : ''}`}>
            <div className='circle-btn-cnt'>
                <button type='button' className='button-transparent' onClick={handleCommunesListClose}>
                    <img
                        loading='lazy'
                        src={closingModalIcon}
                        width='20'
                        height='20'
                        className='btn-close-dm p-2'
                        alt='closing-icon'
                    />
                </button>
            </div>
            <div className='row mx-auto max-height' ref={rowRef}>
                <div className='col-6'>
                    <ul className='text-left'>
                        {validCommunes.map(
                            (commune: any, index) =>
                                index < communesHalf && (
                                    <li key={index} className='my-2'>
                                        {commune.name}
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
                <div className='col-6'>
                    <ul className='text-left'>
                        {validCommunes.map(
                            (commune: any, index) =>
                                index >= communesHalf && (
                                    <li key={index} className='my-2'>
                                        {commune.name}
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
            </div>
            <div className='d-flex justify-content-center mt-2'>
                <span className='pointer' onClick={handleAction}>
                    {intl.get(handleShowMore ? 'SHOW_LESS' : 'SHOW_MORE')}
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = ({ communes }: any) => ({
    ...communes,
});
const mapDispatchToProps = (dispatch: any, getState: any) => ({
    onGetCommunes: () => {
        return dispatch(getCommunes());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunesCard);
