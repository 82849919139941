import intl from 'react-intl-universal';

/**
 * This function return the current month or months displayed
 * @function
 * @returns {(string)} Month that is type string
 */
export const getMonth = (startWeek: any, endWeek: any) => {
    if (startWeek.format('MMMM') === endWeek.format('MMMM')) {
        return intl.get(startWeek.format('MMMM').toUpperCase());
    } else {
        return `${intl.get(startWeek.format('MMMM').toUpperCase())} / ${intl.get(
            endWeek.format('MMMM').toUpperCase(),
        )}`;
    }
};
