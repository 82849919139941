export const putTermsAndConditionStart = () => {
    return {
        type: 'ACCEPT_TERMS_CONDITION_STATUS_STARTED',
    };
};

export const putTermsAndConditionSuccess = (data: any) => {
    return {
        type: 'ACCEPT_TERMS_CONDITION_STATUS_SUCCESS',
        payload: data,
    };
};

export const putTermsAndConditionError = (error: any) => {
    return {
        type: 'ACCEPT_TERMS_CONDITION_STATUS_ERROR',
        payload: {
            error,
        },
    };
};
