export const getCommunesStart = () => {
    return {
        type: 'GET_COMUNES_STARTED',
    };
};

export const getCommunesSuc = (communes: any) => {
    return {
        type: 'GET_COMMUNES_SUCCESS',
        payload: communes,
    };
};

export const getCommunesError = (error: any) => {
    return {
        type: 'GET_COMMUNES_ERROR',
        payload: {
            error,
        },
    };
};
