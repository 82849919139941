import {
  createBasicUserStart,
  createBasicUserSuccess,
  createBasicUserError,
} from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
const createBasicUser = (values: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(createBasicUserStart())

    let basicUserData = {
      user: {
        app: process.env.REACT_APP_API_ROLE_APP?.toLocaleLowerCase(),
        email: values.email,
        names: values.names,
        surnames: values.surnames,
        phone_number: values.phone_number,
        terms_read: values.terms_read
      },
    }
    postData('auth/users/short_register', basicUserData)
      .then((result: any) => {
        dispatch(createBasicUserSuccess(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(createBasicUserError(e))
      })
  }
}
export default createBasicUser
