export const getNewTokenStarted = () => {
    return {
        type: 'GET_NEW_TOKEN_STARTED',
    };
};

export const getNewTokenSuccess = (data: any) => {
    return {
        type: 'GET_NEW_TOKEN_SUCCESS',
        payload: { data },
    };
};

export const getNewTokenError = (error: any) => {
    return {
        type: 'GET_NEW_TOKEN_ERROR',
        payload: {
            error,
        },
    };
};
