import React from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import accessValueByString from '../../helpers/accessValueByString';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../helpers/hookWindowSize';
import { isRoleAdmin } from '../../helpers/roleComparison';

export class Option {
    value: string;
    label: string;
    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}

export interface OptionsInterface {
    value: string | number;
    label: string;
}

export interface CheckBoxProps {
    options: Array<OptionsInterface>;
    label?: string;
    name: string;
    disabled?: boolean;
    classNameContainer?: string;
    maxWidth?: boolean;
    info?: string;
    cbxType?: string;
    newUnitCreationView?: boolean;
    isVerifier?: boolean;
    error?: string;
    isRequiredVerifier?: boolean;
}
const CheckBoxTango = ({
    options,
    label,
    name,
    disabled,
    classNameContainer,
    maxWidth,
    info,
    cbxType,
    newUnitCreationView,
    error,
    isVerifier = false,
    isRequiredVerifier = false,
}: CheckBoxProps) => {
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const isNewUnitCreationView =
        currentLocation === 'new-unit-creation' || currentLocation === 'new-unit-creation-instapage';
    const size = useWindowSize();

    const handleChange = (actualValue: Array<any>, option: any, form: any) => {
        if (actualValue.includes(option.value)) {
            const nextValue = actualValue.filter((value: string) => value !== option.value);
            form.setFieldValue(name, nextValue);
        } else {
            const nextValue = actualValue.concat(option.value);
            form.setFieldValue(name, nextValue);
        }
    };
    const capitalize = (s: any) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
        <Field>
            {({ field, form }: any) => {
                let actualValue = accessValueByString(field.value, name);
                if (!actualValue) {
                    form.setFieldValue(name, []);
                }
                return (
                    <div className={`row radio-button-tango-container d-flex align-items-start ${classNameContainer}`}>
                        {label !== undefined && (
                            <span className={`${newUnitCreationView ? 'col-12' : 'col-lg-4'} plm-0 span-text`}>
                                {`${intl.get(`${label}`)}${isVerifier ? '*' : ''}`}
                            </span>
                        )}
                        <div
                            className={`row ${
                                maxWidth || newUnitCreationView ? 'col-12 pt-2' : 'col-lg-8 col-12 plm-0'
                            } radio-toolbar ${
                                isNewUnitCreationView && size.width < 1430 && 'd-flex justify-content-center'
                            }`}
                        >
                            {options.map((option, index) => {
                                const classByBoolean = Number(option.label) || parseInt(option.label) === 0;
                                return (
                                    <div
                                        key={`opt-${index}-${option.label}`}
                                        className={`${
                                            maxWidth
                                                ? cbxType === 'bedrooms' || cbxType === 'bathrooms'
                                                    ? !classByBoolean
                                                        ? 'col-12 p-0'
                                                        : 'col-3 px-2 py-1'
                                                    : 'col-12 p-0'
                                                : `${newUnitCreationView ? 'max-100' : 'cold-sm-6'} plm-0`
                                        }`}
                                    >
                                        <input
                                            type='checkbox'
                                            checked={
                                                actualValue !== undefined
                                                    ? actualValue && actualValue.includes(option.value)
                                                    : false
                                            }
                                            id={`inlineCheck-${name}-${index}`}
                                            onChange={() => handleChange(actualValue, option, form)}
                                            disabled={disabled}
                                        />
                                        <label
                                            htmlFor={`inlineCheck-${name}-${index}`}
                                            style={{ width: maxWidth ? '100%' : '' }}
                                            className={
                                                cbxType === 'bedrooms' || cbxType === 'bathrooms'
                                                    ? classByBoolean
                                                        ? 'button-secondary-disabled-number-cbx inputCbx'
                                                        : 'button-secondary-disabled-string-cb-cbx'
                                                    : 'button-secondary-disabled-string-cb'
                                            }
                                        >
                                            {' '}
                                            {classByBoolean
                                                ? option.label
                                                    ? capitalize(option.label)
                                                    : option.label
                                                : intl.get(option.label)
                                                ? capitalize(intl.get(option.label))
                                                : option.label}
                                        </label>
                                    </div>
                                );
                            })}
                            {error && (
                                <div className='text col-12 pl-0'>
                                    <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                                </div>
                            )}
                            {(isVerifier || isRoleAdmin) && isRequiredVerifier && (
                                <div className='text col-12 pl-0'>
                                    <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                                </div>
                            )}
                            {info && <p className='p-info-label'>{info}</p>}
                        </div>
                    </div>
                );
            }}
        </Field>
    );
};

export default CheckBoxTango;
