import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { CircularLoading } from '..';
import { calendar } from '../../resources/index';
import es from 'date-fns/esm/locale/es';
import moment from 'moment';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#A8123E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);

type CalendarMobileProps = {
    submitBlocksTaken: any;
    idVisit: any;
    history: any;
    hourTaken: any;
    fetchingBlocks: any;
    blocksAvailable: any;
    isSelected: any;
    dayDate: any;
    onChangeDate: any;
    ToggleButton: any;
    backArrow: any;
    blocksDate: any;
    blockType?: any;
};
const today = moment();

export const RenterCalendarMobile = ({
    submitBlocksTaken,
    idVisit,
    history,
    hourTaken,
    fetchingBlocks,
    blocksAvailable,
    isSelected,
    dayDate,
    onChangeDate,
    ToggleButton,
    backArrow,
    blocksDate,
    blockType,
}: CalendarMobileProps) => {
    const [selectedDate, setSelectedDate] = useState(today);
    const [showBlocks, setShowBlocks] = useState(false);

    onChangeDate(dayDate(selectedDate));

    const handleDateChange = (date: any) => {
        setSelectedDate(moment(date));
        isSelected(dayDate(date));
        setShowBlocks(true);
    };

    useEffect(() => {
        if (!showBlocks && selectedDate) setShowBlocks(true);
    }, [selectedDate]);

    const DaysBlock = () =>
        showBlocks ? (
            <div className='mb-2 mt-2'>
                {blockType.map(({ name, block, action, show }: any) => {
                    const blocksAvailable = blocksDate(selectedDate, name.toLowerCase(), true)?.includes(false);
                    return (
                        <>
                            <ToggleButton
                                value='check'
                                selected={isSelected(dayDate(selectedDate))}
                                onClick={blocksAvailable && action}
                                disabled={!blocksAvailable}
                                onChange={() => {
                                    onChangeDate(dayDate(selectedDate));
                                }}
                                className={`col-12 toggle-button-hour ${!show && 'bg-transparent'}`}
                            >
                                <p className='columns btn-day-owner'>
                                    <span>{intl.get(name.toUpperCase())}</span>
                                    <br />
                                    <span>{block}</span>
                                </p>
                                {blocksAvailable && (
                                    <img src={backArrow} alt='arrow' className='arrow-section ml-2' width='10' />
                                )}
                            </ToggleButton>
                            <section className={!show ? 'd-none' : ''}>
                                {isSelected(dayDate(selectedDate)) ? (
                                    <div className='list-hours-new'>
                                        {show && blocksDate(selectedDate, name.toLowerCase())}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </section>
                        </>
                    );
                })}
            </div>
        ) : (
            <></>
        );

    let tenDays = moment().add(14, 'day');
    const disableWeekends = (date: any) => {
        return date.getDay() === 0 || tenDays < date;
    };

    const now = moment();
    const maxDate = moment(now).add(1, 'week').add(2, 'days');

    return (
        <div className='row p-0'>
            <div className='col-12 mt-3'>
                {blocksAvailable.length > 0 && fetchingBlocks === 'FETCHED_BLOCKS' && (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                            <Grid container justify='space-around'>
                                <BootstrapInput
                                    margin='normal'
                                    disableToolbar={true}
                                    id='date-picker-dialog'
                                    variant='static'
                                    format='dd/MM/yyyy'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    minDate={now}
                                    maxDate={maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    shouldDisableDate={disableWeekends}
                                    keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='Calendar' />}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <DaysBlock />
                        <section>
                            <div>
                                <button
                                    className='button-primary col-12 mb-3'
                                    onClick={() => submitBlocksTaken(idVisit, history)}
                                    disabled={hourTaken.length < 1}
                                >
                                    {intl.get('UPDATE_BLOCKS')}
                                </button>
                            </div>
                        </section>
                    </>
                )}
                {blocksAvailable.length === 0 && fetchingBlocks === 'FETCHED_BLOCKS' && (
                    <p>{intl.get('SCHEDULE_NOT_AVAILABLE')}</p>
                )}
                {fetchingBlocks !== 'FETCHED_BLOCKS' && <CircularLoading />}
            </div>
        </div>
    );
};
