export const validateUnitError = (error: any) => {
  return {
    type: 'VALIDATE_UNIT_ERROR',
    payload: {
      error,
    },
  }
}

export const validateUnitStart = () => {
  return {
    type: 'VALIDATE_UNIT_STARTED',
  }
}

export const validateUnitSuccess = (unit: any) => {
  return {
    type: 'VALIDATE_UNIT_SUCCESS',
    payload: unit,
  }
}
