import React, { useEffect, useLayoutEffect, useState } from 'react'
import './App.scss'
import './customBootstrap.scss'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import Navbar from './components/navbar'
import { CircularLoading, Footer } from './tango-react-base/components'
import routes from './routes'
import store from './redux/store'
import { Provider } from 'react-redux'
import intl from 'react-intl-universal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import routesMount from './routesMount'
import { ThemeProvider } from '@material-ui/core'
import { theme } from './tango-react-base/resources/theme'

require('intl/locale-data/jsonp/en.js')

declare global {
  interface Window {
    Cypress: any
    intl: any
  }
}

// only for tests
if (window.Cypress) {
  window.intl = intl
}

const locales: any = {
  'en-US': require('./tango-react-base/locales/en-US'),
  'es-ES': require('./tango-react-base/locales/es-ES'),
  'es-CL': require('./tango-react-base/locales/es-ES'),
  'es-MX': require('./tango-react-base/locales/es-ES'),
}

function App() {
  const [loadingLanguajes, setLoadingLanguajes] = useState(true)

  useEffect(() => {
    loadLocales()
  }, [])

  const loadLocales = () => {
    let currentLocale = 'es-ES'
    let locales_parsed: any = {}
    Object.keys(locales).forEach(
      (key) => (locales_parsed[`${key}`] = locales[key].default),
    )
    intl
      .init({
        currentLocale,
        locales: locales_parsed,
      })
      .then(() => {
        setLoadingLanguajes(false)
      })
  }

  const RenderWithSubRoutes = (route: any) => {
    const location = useLocation()
    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [location.pathname])

    return (
      <Route
        exact={route.exact}
        path={route.path}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    )
  }

  return (
    <Router>
      <LastLocationProvider>
        <ToastContainer hideProgressBar={true} autoClose={10000} />
        <div className='App'>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              {loadingLanguajes ? (
                <div>{<CircularLoading />}</div>
              ) : (
                <>
                  <div className='container-mount-view'>
                    <div className='container-uniq-mount'>
                      {routesMount.map((route, index) => (
                        <RenderWithSubRoutes
                          {...route}
                          key={`route-cmp-${index}`}
                        />
                      ))}
                    </div>
                  </div>
                  <div className='principal-container'>
                    <Navbar />
                    <Switch>
                      {routes.map((route, index) => (
                        <RenderWithSubRoutes
                          {...route}
                          key={`route-cmp-${index}`}
                        />
                      ))}
                    </Switch>
                    <Footer />
                  </div>
                </>
              )}
            </ThemeProvider>
          </Provider>
        </div>
      </LastLocationProvider>
    </Router>
  )
}

export default App
