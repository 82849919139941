import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { getUnitVersionStart, getUnitVersionError, getUnitVersionSuccess, restartDocument } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getUnitVersion = (id: number) => {
    return (dispatch: any) => {
        dispatch(getUnitVersionStart());
        getData(`unit_versions/${id}`)
            .then((result) => {
                dispatch(getUnitVersionSuccess(result.data));
                dispatch(restartDocument());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getUnitVersionError(e));
            });
    };
};
export default getUnitVersion;
