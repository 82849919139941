import { getData } from '../../helpers/apiConnections';
import { getRentedPostStart, getRentedPostError, getRentedPostSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRentedPost = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getRentedPostStart());
        getData(`posts/rented_property`)
            .then((result) => {
                dispatch(getRentedPostSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRentedPostError(e));
            });
    };
};
export default getRentedPost;
