import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { Field } from 'formik';
import intl from 'react-intl-universal';
import { Input } from '../';

interface inputProps {
    'aria-label': string;
}

interface SwitchStringInputProps {
    inputProps: inputProps;
    options: Array<String>;
    name: string;
    value: string;
    label: string;
    disabled?: boolean;
    required: boolean;
    idInput: string;
    labelInput: string;
    onBlur: any;
    onChange: any;
    touched: any;
    type: string;
    valueInput: any;
}

interface SwitchOptionProps {
    field: any;
    form: any;
    checked: boolean;
    setChecked: any;
}

const SwitchOption = ({
    inputProps,
    options,
    name,
    value,
    label,
    disabled,
    field,
    form,
    checked,
    setChecked,
    required,
    idInput,
    labelInput,
    onBlur,
    onChange,
    touched,
    type,
    valueInput,
}: SwitchOptionProps & SwitchStringInputProps) => {
    useEffect(() => {
        if (!value) {
            form.setFieldValue(name, options[options.length - 1]);
        }
    }, []);

    return (
        <div className='row switch-container d-flex align-items-start'>
            {label !== undefined && (
                <div className='col-md-4 pl-0'>
                    <span className='col-12 switch-span align-self-center span-text'>{intl.getHTML(`${label}`)}</span>
                </div>
            )}

            <div className='col-md-4 pl-0'>
                <div className='d-flex align-items-center'>
                    <span className='switch-span-option'>{intl.get(options[1].toUpperCase())}</span>
                    <Switch
                        checked={checked}
                        onChange={(e) => {
                            field.value[name] = e.target.checked ? options[0] : options[1];
                            form.setFieldValue(name, field.value[name]);
                            setChecked(e.target.checked);
                        }}
                        value={checked}
                        inputProps={inputProps}
                        disabled={disabled}
                    />
                    <span className='switch-span-option'>{intl.get(options[0].toUpperCase())}</span>
                </div>
            </div>
            <div className='col-md-4 pl-0'>
                {checked && (
                    <Input
                        required={required}
                        disabled={disabled}
                        id={idInput}
                        label={labelInput}
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched}
                        type={type}
                        value={valueInput}
                        className='my-0'
                        classNameInput='col-10 pl-0'
                    />
                )}
            </div>
        </div>
    );
};

const SwitchStringInput = ({ ...props }: SwitchStringInputProps) => {
    const [checked, setChecked] = useState(props.value === props.options[0]);
    return (
        <Field>
            {({ field, form }: any) => {
                return <SwitchOption {...props} field={field} form={form} checked={checked} setChecked={setChecked} />;
            }}
        </Field>
    );
};

export default SwitchStringInput;
