export const sendUserTokeStart = () => {
  return {
    type: 'SET_SEND_USER_TOKEN_STARTED',
  }
}

export const sendUserTokeSuccess = () => {
  return {
    type: 'SET_SEND_USER_TOKEN_SUCCESS',
  }
}

export const sendUserTokeError = (error: any) => {
  return {
    type: 'SET_SEND_USER_TOKEN_ERROR',
    payload: {
      error,
    },
  }
}
