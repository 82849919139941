import React, { useEffect } from 'react'
import { RenterCalendar } from '../../tango-react-base/components'
import { connect } from 'react-redux'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { VerifierReduxStates } from '../../tango'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'

type CalendarViewProps = {
  onSetTemplateChild: (child: Object) => void
}

const CalendarView = ({ onSetTemplateChild }: CalendarViewProps) => {
  const history = useHistory()
  useEffect(() => {
    if (!sessionStorage.getItem('authorization'))
      return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('SCHEDULE_NEW_VISIT')}</h1>
          <p className="col-sm-12 col-md-6 px-0 font-sm-1">
            {intl.get('SCHEDULE_NEW_VISIT_INFO')}
          </p>
        </>,
      )
    }
  }, [onSetTemplateChild])

  return (
    <div className="container-home">
      <RenterCalendar />
    </div>
  )
}

const mapStateToProps = (state: VerifierReduxStates) => ({})

const mapDispatchToProps = (dispatch: any, getState: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView)
