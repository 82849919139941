import { PostValidationState } from '../../../tango'

const initialState: PostValidationState = {
  post: {},
  fetch: 'LOADING',
  savingPost: 'NO_SAVING',
  fetchPhoto: 'NO_ERROR',
  fetchVideo: 'NO_ERROR',
  photos: [],
  photosUploading: [],
  photosUploaded: [],
  videos: [],
  videosUploading: [],
  deletingVideo: [],
  deletedVideo: [],
  verified: 'no',
  fetchPhotoUploading: ''
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_POST_STARTED':
      return {
        ...state,
        fetch: 'LOADING',
      }
    case 'GET_POST_SUCCESS':
      return {
        ...state,
        post: action.payload.data,
        photos: action.payload.data.attributes.photos,
        videos: action.payload.data.attributes.videos,
        fetch: 'LOADED',
      }
    case 'GET_POST_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
    /* UPLOAD PHOTO */
    case 'UPLOAD_PHOTO_STARTED':
      return {
        ...state,
        photosUploading: [...state.photosUploading, action.payload.photoIndex],
        fetchPhotoUploading: 'STARTED'
      }
    case 'UPLOAD_PHOTO_SUCCESS':
      const { indexPhoto } = action.payload
      let img: Array<any> = []
      img.push({
        index: indexPhoto,
        id: action.payload.photo.data.id,
        photo_url: action.payload.photo.data.attributes.file_url
      })
      const photosUploading = state.photosUploading.filter(
        (value: any) => {
          return value !== indexPhoto
        },
      )
      return {
        ...state,
        photosUploading: photosUploading,
        photosUploaded: state.photosUploaded.concat(img),
        fetchPhotoUploading: 'SUCCESS'
      }
    case 'UPLOAD_PHOTO_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPhoto: 'ERROR',
        fetchPhotoUploading: 'ERROR'
      }
    
    /* UPLOAD VIDEO */
    case 'UPLOAD_VIDEO_STARTED':
      return {
        ...state,
        videosUploading: [...state.videosUploading, action.payload.title],
      }

    case 'UPLOAD_VIDEO_SUCCESS':
      const videosUploading = state.videosUploading.filter(
        (videoUploading: any) => {
          return videoUploading !== action.payload.title
        }
      )

      const {
        id,
        attributes: { title, file_url: video_url }
      } = action.payload.video.data

      const video = {
        id,
        title,
        video_url,
        priority: null,
        video_type: null
      }

      return {
        ...state,
        videosUploading: videosUploading,
        videos: [...state.videos, video]
      }

    /* DELETED VIDEO */
    case 'DELETE_VIDEO_STARTED':
      return {
        ...state,
        deletingVideo: action.payload.id,
        fetchVideo: 'LOADING'
      }

    case 'DELETE_VIDEO_SUCCESS':
      let idVideo = action.payload.id

      let videos = state.videos.filter((video: any) => {
        return video.id !== idVideo
      })
      return {
        ...state,
        videos: videos,
        deletingVideo: [],
        fetchVideo: 'LOADED'
      }
    case 'UPLOAD_VIDEO_ERROR':
    case 'DELETE_VIDEO_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchVideo: 'ERROR',
      }

    /* VALIDATE UNIT */
    case 'VALIDATE_UNIT_STARTED':
      return {
        ...state,
      }
    case 'VALIDATE_UNIT_SUCCESS':
      return {
        ...state,
        verified: action.payload.data,
      }
    case 'VALIDATE_UNIT_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    /* UPDATE UNIT */
    case 'UPDATE_POST_STARTED':
      return {
        ...state,
        savingPost: 'SAVING',
      }
    case 'UPDATE_POST_SUCCESS':
      return {
        ...state,
        savingPost: 'SAVED',
      }
    case 'UPDATE_POST_ERROR':
      return {
        ...state,
        savingPost: 'SAVE_ERROR',
        error: action.payload.error,
      }

    default:
      return state
  }
}
