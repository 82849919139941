import { Tooltip } from '@material-ui/core';
import React from 'react';
import intl from 'react-intl-universal';
import './timeline.scss';

type TimeInfo = {
    value: string;
    title: string;
    description: string;
};

type TimeLineTangoProps = {
    className?: string;
    state: string;
    arrayLine: Array<TimeInfo>;
};

const TimeLineTango = ({ state, arrayLine, className }: TimeLineTangoProps) => {
    let positionState = 0;
    arrayLine.forEach((value: TimeInfo, key: number) => {
        if (value.value === state) positionState = key;
    });
    return (
        <div className={`timeline-flex-parent ${className}`}>
            <div className='timeline-input-flex-container'>
                {arrayLine.map((value: TimeInfo, key: number) => {
                    let active = positionState === key ? 'active' : key > positionState ? 'inactive' : '';

                    let more5 = arrayLine.length > 4 && 'more-5';
                    return (
                        <div key={`${value}-${key}`} className={`timeline-input-wrapper ${active}`}>
                            <div>
                                <Tooltip title={intl.get(value.description)} placement='top' arrow>
                                    <div className={`timeline-input ${active} ${more5}`}>
                                        <span
                                            className={`${
                                                active !== 'active' ? 'timeline-hidden-text' : 'timeline-show-text'
                                            }`}
                                            data-type={intl.get(value.title)}
                                        ></span>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default TimeLineTango;
