import React from 'react'
import { ForgetPasswordEmailForm } from '../../../tango-react-base/components'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const history = useHistory()
  return (
    <div className="container-home">
      <ForgetPasswordEmailForm history={history} disabled={false} />
    </div>
  )
}
export default Login
