import React from 'react';
import intl from 'react-intl-universal';
import { IconButton, DialogModal } from '../index';

/** Here the types of the props for the interface 'PopupWithIconProps' are defined
 * @typedef UnpublishPostModalTypes
 * @type {(function|boolean|string|React.ReactNode)}
 * @property {boolean} open - is a boolean.
 * @property {function} handleClose - is a function.
 * @property {string} title - is a string.
 * @property {any} icon - is an icon img.
 * @property {string} modalTitle - is a string.
 * @property {string} className - is a string.
 * @property {React.ReactNode} children - is a TSX element.
 */
interface PopupWithIconProps {
    open: boolean;
    handleClose?: () => void;
    children?: React.ReactNode;
    title: string;
    icon?: any;
    modalTitle?: string;
    className?: string;
    lineUp?: boolean;
    contentDialogClass?: string;
    centeredContent?: boolean;
    classNameTitle?: string;
    classNameDiv?: String;
    firstPosition?: boolean;
}
/**
 * PopupWithIcon is a functional component that renders a confirmation modal
 *@function
 *@param {PopupWithIconTypes} open - state that validates the rendering of the modal
 *@param {PopupWithIconTypes} handleClose - function that closes the modal
 *@param {PopupWithIconTypes} children - TSX elements
 *@param {PopupWithIconTypes} title - string that is to be translated and showed as prop for the DialogModal component
 *@param {PopupWithIconTypes} modalTitle - string that is to be translated and showed as an h2 element
 *@param {PopupWithIconTypes} icon - icon passed to be used for the src of an img element
 *@param {PopupWithIconTypes} className - class to be passed as prop to the DialogModal component
 *@param {PopupWithIconTypes} lineUp - boolean that validates the rendering of the lineUp prop of the DialogModal component
 *@param {PopupWithIconTypes} contentDialogClass - class to be passed as prop to the DialogModal component
 *@param {PopupWithIconTypes} centeredContent - boolean that validates the rendering of the centeredContent prop of the DialogModal component
 *@param {PopupWithIconTypes} classNameTitle - class of title to be passed as prop to the DialogModal component
 *@param {PopupWithIconTypes} firstPosition - boolean to be passed as prop to the DialogModal component, validates position of zindex
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const PopupWithIcon = ({
    open,
    handleClose,
    title,
    children,
    icon,
    modalTitle = '',
    className = '',
    lineUp = false,
    contentDialogClass = '',
    centeredContent = true,
    classNameTitle = '',
    classNameDiv = '',
    firstPosition,
}: PopupWithIconProps) => {
    return (
        <DialogModal
            title={title ? intl.get(title) : ''}
            open={open}
            handleClose={handleClose}
            className={className}
            lineUp={lineUp}
            contentDialogClass={contentDialogClass}
            classNameTitle={classNameTitle}
            firstPosition={firstPosition}
        >
            {handleClose && <IconButton type='close' icon='fa-times' classColor='btn-close-dm' onClick={handleClose} />}
            {modalTitle && <h2 className='text-left mb-4'>{intl.get(modalTitle)}</h2>}
            {icon && <img src={icon} alt='icon' />}
            <div
                className={`promotion-text-container ${
                    centeredContent ? ' center m-auto' : 'no-center-font'
                } ${classNameDiv}`}
            >
                {children}
            </div>
        </DialogModal>
    );
};
export default PopupWithIcon;
