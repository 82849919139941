import { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { formatNumber } from '../../helpers/numberFormat'

interface Props {
  currency: string
  proposedPrice: string
}

export const useCurrencyFormater = ({ currency, proposedPrice }: Props) => {
  const [formatCurrency, setFormatCurrency] = useState('')

  const { currentLocale } = intl.getInitOptions()

  useEffect(() => {
    if(proposedPrice && currentLocale){
      const clfValue = parseFloat(proposedPrice).toFixed(2)
      const clfValidation = clfValue.split('.').join(currentLocale === 'en-US' ? '.' : ',')
      currency === 'CLP' && setFormatCurrency(`$ ${formatNumber(proposedPrice)}`)
      currency === 'CLF' && setFormatCurrency(`UF ${clfValidation}`)
    }
  }, [currency, proposedPrice, currentLocale])

  return [formatCurrency]
}
