const initialState: any = {
  communes: [],
  fetchingCommunes: 'NO_COMMUNES'
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_COMMUNES_STARTED':
      return {
        ...state,
        fetchingCommunes: 'FETCHING_COMMUNES'
      }

    case 'GET_COMMUNES_SUCCESS':
      return {
        ...state,
        communes: action.payload.data,
        fetchingCommunes: 'FETCHED_COMMUNES'
      }
    case 'GET_COMMUNES_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingCommunes: 'ERROR_FETCHING_COMMUNES'
      }
    default:
      return state
  }
}
