import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { leaseRenewalIcon, noLeaseRenewalIcon, sendIcon, PhoneContact } from '../../resources';
import { withFormik, FormikProps, Form } from 'formik';
import { ButtonLoading, PopupWithIcon, CircularLoading, CustomToolTip } from '../index';
import { connect } from 'react-redux';
import { getCurrentRenewalPeriod, acceptMediation, rejectMediation } from '../../reduxActions';
import { formatNumber } from '../../helpers/numberFormat';
import { useCurrencyConvertor, useCurrencyFormater } from '../../hooks';
import { isRoleAdmin } from '../../helpers/roleComparison';
import { useWindowSize } from '../../helpers/hookWindowSize';

/** Here the types of the props for the type 'MediationResponseProps' are defined
 * @typedef MediationResponseTypes
 * @type {(object|string|function)}
 * @property {any} renewalPeriod - is an object.
 * @property {function} onGetCurrentRenewalPeriod - is a function.
 * @property {string} fetchingRenewalPeriod - is a string.
 * @property {string} mediationResponseStatus - is a string.
 * @property {function} onAcceptMediation - is a function.
 * @property {function} onRejectMediation - is a function.
 */

type MediationResponseProps = {
    renewalPeriod: { id: number; attributes: any };
    onGetCurrentRenewalPeriod: (renewalPeriodId: any) => void;
    fetchingRenewalPeriod: string;
    mediationResponseStatus: string;
    price_amount?: string;
    price_currancy?: string;
    onAcceptMediation: (renewalPeriodId: number, setConfirmationPopup: (state: boolean) => void) => void;
    onRejectMediation: (renewalPeriodId: number, setConfirmationPopup: (state: boolean) => void) => void;
};

interface FormValidation {
    option: any;
}

/**
 * MediationResponse is a functional component that renders a confirmation view for owner and renter
 *@function
 *@param {MediationResponseTypes} renewalPeriod - object that contains a renewal period attributes
 *@param {MediationResponseTypes} onGetCurrentRenewalPeriod - function that fetches the current renewal period
 *@param {MediationResponseTypes} fetchingRenewalPeriod - string that represents a renewal period fetching status
 *@param {MediationResponseTypes} mediationResponseStatus - string that represents a medioation response fetching status
 *@param {MediationResponseTypes} onAcceptMediation - function that send an acceptance request by owner or renter to the API
 *@param {MediationResponseTypes} onRejectMediation - function that send a rejection request by owner or renter to the API

 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<MediationResponseProps & FormikProps<FormValidation>> = ({
    values,
    handleChange,
    fetchingRenewalPeriod,
    onGetCurrentRenewalPeriod,
    renewalPeriod,
    mediationResponseStatus,
    onAcceptMediation,
    onRejectMediation,
}) => {
    const [disableButton, setDisableButton] = useState(true);
    const [rejectionPopup, setRejectionPopup] = useState(false);
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const { id: renewalPeriodId } = renewalPeriod || '';
    const {
        aasm_mediation,
        days_left,
        admin_proposal: adminProposal,
        proposed_price_admin_amount: proposedPriceAdmin,
        current_lease_period_price,
        legal_mediation_days_left: mediationDaysLeft,
        due_at_current_active_contract,
        proposed_currency: proposedCurrency,
        lease_price_change: leasePriceChange,
        registered_currency: registeredCurrency,
    } = renewalPeriod.attributes || '';

    const price_amount =
        aasm_mediation === 'admin_price_proposed' ? adminProposal.price : renewalPeriod.attributes.price_amount;
    const price_currency =
        aasm_mediation === 'admin_price_proposed' ? adminProposal.currency : renewalPeriod.attributes.price_currency;

    const registeredCurrencyUpperCase = registeredCurrency?.toUpperCase();
    const proposedCurrencyUpperCase = price_currency?.toUpperCase();

    const changeConditionCurrency = registeredCurrencyUpperCase != proposedCurrencyUpperCase;
    const changeConditionCurrencyText =
        changeConditionCurrency &&
        intl.getHTML('CHANGE_CONDITION_CURRENCY', {
            currency: proposedCurrencyUpperCase === 'CLP' ? 'Pesos.' : 'UF.',
        });

    const { inflation: adminInflation, inflation_with_format: inflationWithFormat } = adminProposal || 0;
    const size = useWindowSize();
    const percentage_text =
        adminInflation >= 0 ? intl.get('AN_INCREASE') : adminInflation < 0 ? intl.get('A_DECREASE') : '';

    const [formatCurrency] = useCurrencyFormater({
        currency: price_currency,
        proposedPrice: price_amount,
    });

    const [, priceConversion, , currentUF] = useCurrencyConvertor({
        currencyIsoValue: price_currency,
        value: price_amount,
    });

    useEffect(() => {
        setDisableButton(!values.option.length || mediationResponseStatus === 'FETCHING');
    }, [values, mediationResponseStatus]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!disableButton) {
            if (values.option === 'rejectAdminProposal') {
                setRejectionPopup(true);
            } else {
                onAcceptMediation(renewalPeriodId, setConfirmationPopup);
            }
        }
    };
    const handleMediationRejection = () => {
        setRejectionPopup(false);
        onRejectMediation(renewalPeriodId, setConfirmationPopup);
    };

    /** ConfirmRejectionPopup: renders a confirmation popup before submitting a rejection
     * @function
     * @returns {(ReactComponent)} Returns a tsx element
     */
    const ConfirmRejectionPopup = () => {
        return (
            <PopupWithIcon
                title='NO_CONTRACT_RENEWAL'
                open={rejectionPopup}
                handleClose={() => setRejectionPopup(false)}
                icon={noLeaseRenewalIcon}
                lineUp
            >
                <>
                    <p className='text-left font-weight-bold mt-4'>{intl.get('ARE_YOU_SURE_TO_REJECT_MEDIATION')}</p>
                    <div className='mt-4'>
                        <button className='button-tertiary col-md-5 mr-3 mb-2' onClick={() => setRejectionPopup(false)}>
                            {intl.get('CANCEL')}
                        </button>
                        <button className='button-primary col-md-5' onClick={() => handleMediationRejection()}>
                            {intl.get('RENEWAL_REFUSAL')}
                        </button>
                    </div>
                </>
            </PopupWithIcon>
        );
    };
    /** ResponseConfirmationPopup: renders a confirmation popup for both acceptance and rejection
     * @function
     * @returns {(ReactComponent)} Returns a tsx element
     */
    const ResponseConfirmationPopup = () => {
        return (
            <PopupWithIcon
                title='MEDIATION_RESPONSE_SENT'
                open={confirmationPopup}
                handleClose={() => {
                    setConfirmationPopup(false);
                    onGetCurrentRenewalPeriod(renewalPeriodId);
                }}
                icon={sendIcon}
                lineUp
            >
                <>
                    <p className='mt-4 text-left'>{intl.get('RESPONSE_SENT')}</p>
                    <p className='mt-4 text-left'>{intl.get('ADMIN_COMPANY_FOR_DOUBTS')}</p>
                </>
            </PopupWithIcon>
        );
    };
    /** MediationHeader: renders the header for the meadiation view for owner and renter
     * @function
     * @returns {(ReactComponent)} Returns a tsx element
     */

    const MediationHeader = () => {
        return (
            <>
                <div className='template-line' />
                <h1 className='new-subtitle font-weight-bold mb-5'>{intl.get('RENEWAL_MEDIATION_PROCESS')}</h1>
                <div className='d-flex align-items-start flex-column'>
                    <p>
                        {intl.getHTML('DAYS_LEFT_FOR_RENEWAL', {
                            days_left,
                            due_at: due_at_current_active_contract,
                        })}
                    </p>
                    <p>
                        {mediationDaysLeft > 0
                            ? intl.getHTML('DAYS_LEFT_FOR_MEDIATION', {
                                  days_left: mediationDaysLeft,
                              })
                            : intl.getHTML('MEDIATION_DAYS_OVERDUE', {
                                  days_left: mediationDaysLeft,
                              })}
                    </p>
                    <p>{intl.getHTML('CONTRACT_WILL_NOT_BE_RENEWED')}</p>
                    <p>
                        {intl.getHTML('ADMIN_MEDIATION', {
                            price: formatCurrency,
                            percentage_number: inflationWithFormat,
                            reference: priceConversion,
                            percentage_text,
                        })}
                        {changeConditionCurrencyText}
                    </p>
                    <p>
                        {changeConditionCurrency &&
                            intl.getHTML('CURRENCY_INFO', {
                                currency: proposedCurrencyUpperCase === 'CLP' ? 'Pesos.' : 'UF.',
                            })}
                    </p>

                    <p className='font-weight-bold text-color-secondary'>{intl.get('SOME_OPTIONS')}</p>
                </div>
                <div className={`row my-5 d-flex justify-content-${!isRoleAdmin ? 'around' : 'between'}`}>
                    <div className={`my-1 col-md-${size.width > 1024 ? '5' : '6'}`}>
                        <label
                            className={`lease-renewal-radio-${!isRoleAdmin ? 'up' : ''} ${
                                values.option === 'acceptAdminProposal' ? 'card-checked' : ''
                            }`}
                        >
                            <div className='lease-renewal-input-container'>
                                <input
                                    type='radio'
                                    name='option'
                                    className='mr-1'
                                    id='acceptAdminProposal'
                                    value='acceptAdminProposal'
                                    onChange={handleChange}
                                    checked={values.option === 'acceptAdminProposal'}
                                />
                                <span className='check-lease-renewal'></span>
                                <img src={leaseRenewalIcon} alt='leaseRenewalIcon' height='50' />
                            </div>
                            <h2 className={`${!isRoleAdmin ? 'font-weight-bold' : 'pb-5'} lease-renewal-text`}>
                                {intl.get('RENEW_LEASE_AGREEMENT')}
                            </h2>
                        </label>
                    </div>
                    <div className={`my-1 col-md-${size.width > 1024 ? '5' : '6'}`}>
                        <label
                            className={`lease-renewal-radio-${!isRoleAdmin ? 'up' : ''} ${
                                values.option === 'rejectAdminProposal' ? 'card-checked' : ''
                            }`}
                        >
                            <div className='lease-renewal-input-container'>
                                <input
                                    type='radio'
                                    name='option'
                                    id='rejectAdminProposal'
                                    value='rejectAdminProposal'
                                    onChange={handleChange}
                                    checked={values.option === 'rejectAdminProposal'}
                                />
                                <span className='check-lease-renewal'></span>
                                <img src={noLeaseRenewalIcon} className='' alt='noLeaseRenewalIcon' height='50' />
                            </div>
                            <h2 className={`${!isRoleAdmin ? 'font-weight-bold' : ''} lease-renewal-text`}>
                                {intl.get('NO_RENEW_LEASE_AGREEMENT')}
                            </h2>
                            <div className='lease-renewal-contact-info'>
                                <img src={PhoneContact} alt='PhoneContact' />
                                <span className='p-0'>{intl.get('ADMIN_CONTACT')}</span>
                            </div>
                        </label>
                    </div>
                </div>
            </>
        );
    };

    /** ResponseButton: renders a dynamic submit button for acceptance and rejection of the proposed price by admin
     * @function
     * @returns {(ReactComponent)} Returns a tsx element
     */
    const ResponseButton = () => {
        return (
            <>
                <Form className='lease-renewal-container' onSubmit={onSubmit}>
                    <div className='d-flex justify-content-center mt-5 mb-3'>
                        <button className='button-primary col-md-4' disabled={disableButton}>
                            {mediationResponseStatus === 'FETCHING' ? (
                                <ButtonLoading />
                            ) : (
                                intl.get('SEND_MEDIATION_RESPONSE')
                            )}
                        </button>
                    </div>
                </Form>
                <ConfirmRejectionPopup />
                <ResponseConfirmationPopup />
            </>
        );
    };
    return (
        <div>
            {fetchingRenewalPeriod === 'FETCHED' ? (
                <div className='lease-renewal-container'>
                    <MediationHeader />
                    {values.option.length ? <ResponseButton /> : null}
                </div>
            ) : (
                <CircularLoading />
            )}
        </div>
    );
};

const MediationResponse = withFormik<MediationResponseProps, FormValidation>({
    mapPropsToValues: (props: any) => {
        const initialValues = {
            option: '',
        };
        return {
            ...initialValues,
        };
    },

    handleSubmit: () => {},
})(InnerForm);

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
    const { fetchingRenewalPeriod } = state.renewalPeriod;
    const { mediationResponseStatus } = state.mediationResponse;
    return {
        fetchingRenewalPeriod: fetchingRenewalPeriod,
        mediationResponseStatus,
    };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatches an action to change the state.
 *@param {MapToProps}  getState - Returns the current state object tree.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCurrentRenewalPeriod: (id: number) => {
            dispatch(getCurrentRenewalPeriod(id));
        },
        onAcceptMediation: (id: number, setConfirmationPopup: (state: boolean) => void) => {
            dispatch(acceptMediation(id, setConfirmationPopup));
        },
        onRejectMediation: (id: number, setConfirmationPopup: (state: boolean) => void) => {
            dispatch(rejectMediation(id, setConfirmationPopup));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediationResponse);
