import { approveAnnexStart, approveAnnexSuccess, approveAnnexError } from './types';
import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
import { getContract, getRentedPost } from '../../reduxActions';
import { isRoleOwner, isRoleAdmin, isRoleRenter } from '../../helpers/roleComparison/index';

const canDispatchGetContract = isRoleAdmin || isRoleOwner;

const approveAnnex = (id: string) => {
    return (dispatch: any) => {
        dispatch(approveAnnexStart());
        putData(`lease_contract_delivery_vouchers/${id}/approve`)
            .then((result) => {
                toast(intl.get('INVENTORY_APPROVED'));
                dispatch(approveAnnexSuccess());
                canDispatchGetContract && dispatch(getContract(id));
                isRoleRenter && dispatch(getRentedPost());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(approveAnnexError(e));
            });
    };
};
export default approveAnnex;
