import React, { useState, useEffect } from 'react'
import { FormikProps, withFormik, Form, Field, validateYupSchema } from 'formik'
import Input from '../../input'
import intl from 'react-intl-universal'
import { toast } from 'react-toastify'
import Select from '../../select'
import { useLocation, useHistory } from 'react-router-dom'
import { getParameterByName } from '../../../helpers/getParamsUrl'
import NewRegisterForm from './newRegisterForm'
import {
  isRoleOwner,
  isRoleSuperAdmin,
  isRoleRenter,
} from '../../../helpers/roleComparison/index'
import { FormRegister, NewFormRegister } from './formValidates'
import { includeInstapagePath } from '../../../helpers/routesForInstapage'

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18)
/**
 * Here it is defined the type of the property, this prop is similar to 'NewPasswordFormProps' but 'inputPropsTypes' is for the documentation
 * @typedef NewPasswordFormProps
 * @type {(boolean|string|object)}
 * @property {string} idUser - is an string.
 * @property {string} fetchingCodes - is an string.
 * @property {boolean} setReadTermAndPolicies - is a boolean.
 * @property {string} countriesCodes - is an string.
 * @property {boolean} readTermAndPolicies - is an boolean.
 * @property {boolean} disabled - is an boolean.
 * @property {boolean} setIncompleteForm - is an boolean.
 * */
interface NewPasswordFormProps {
  onSubmit: any
  idUser?: string
  children?: any
  disabled: boolean
  leyend?: any
  child_input_admin?: any
  selectTypeRole?: any
  setReadTermAndPolicies?: any
  readTermAndPolicies?: boolean
  countriesCodes: any
  fetchingCodes: string
  setRoleSelected?: any
  setIncompleteForm?: any
}
/**
 * this is the form used to create the user register
 *@function
 *@param {createUserFormTypes}  email - input to the email
 *@param {createUserFormTypes}  password - input to the password
 *@param {createUserFormTypes}  repeat_password -input to repeat the password
 *@param {createUserFormTypes}  names - input names
 *@param {createUserFormTypes}  surnames - input surnames
 *@param {createUserFormTypes}  phone_number - input is phone number
 *@param {createUserFormTypes}  street_address - input address
 *@param {createUserFormTypes}  street_address_extra - input extra address
 *@param {createUserFormTypes}  verifier_ref -
 *@param {createUserFormTypes}  agent_ref -
 *@param {createUserFormTypes}  terms_read - to be sure if the user read the terms and conditions
 *@param {createUserFormTypes}  country_code - list of the countries codes
 *@param {createUserFormTypes}  referred_code - input with the referred code to the owner
 *@param {createUserFormTypes}  selected_role - value which stores the checked roles by the user
 *@param {createUserFormTypes}  other_selected_role - value that contains role information
 * @returns {(ReactComponent)} returns a component with the inputs for the register
 */
interface createUserForm {
  email: string
  password: string
  repeat_password: string
  names: string
  surnames: string
  phone_number: string
  street_address: string
  street_address_extra: string
  verifier_ref: string
  agent_ref: string
  terms_read: boolean
  country_code: string
  referred_code: string
  selected_role: string
  other_selected_role: string
}
/**
 *@function
 *@param {createUserForm} - this is the form used to create the user register
 */
const InnerForm: React.FunctionComponent<
  NewPasswordFormProps & FormikProps<createUserForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  child_input_admin,
  selectTypeRole,
  setReadTermAndPolicies,
  readTermAndPolicies,
  countriesCodes,
  fetchingCodes,
  setRoleSelected,
  onSubmit,
  setIncompleteForm,
}) => {
  const history = useHistory()
  let location = useLocation()
  const saveUserEmail = (event: any) => {
    setuserEmail(event)
    if (!event) setIncompleteForm(true)
  }

  const paramsNewRegister = getParameterByName('new_register', location?.search)
  const paramUserEmail = getParameterByName('email', location?.search)
  const [userEmail, setuserEmail] = useState(paramUserEmail || '')
  const [haveReferenceCode, setHaveReferenceCode] = useState(false)
  const appTangoBackOffice = !isRoleRenter && !isRoleOwner
  const referredCode = localStorage.getItem('referredCode')

  useEffect(() => {
    if (!haveReferenceCode) values.referred_code = ''
  }, [haveReferenceCode])

  useEffect(() => {
    if (values.referred_code.length === 12) {
      localStorage.setItem('referredCode', values.referred_code)
    }
  }, [values.referred_code])

  useEffect(() => {
    if (referredCode && isRoleOwner) return setHaveReferenceCode(true)
    setHaveReferenceCode(false)
  }, [referredCode])

  useEffect(() => {
    setIncompleteForm && setIncompleteForm(Object.keys(errors).length >= 1)
    if (!values.email) setIncompleteForm(true)
  }, [errors, values.email])
  const appValidation = isRoleOwner || isRoleRenter ? 'border-login' : ''
  return (
    <>
      {!paramsNewRegister ? (
        <Form className='row mt-2'>
          <div className='row input-container d-flex align-items-start input-container-class name-and-surnames'>
          <div
            className={`${
              appTangoBackOffice
                ? 'div-container-input-class-12'
                : 'col-md-6 names-register'
            }`}
          >
            <Input
              required={true}
              variant={false}
              id='names'
              label={'names'.toUpperCase()}
              type='text'
              value={values.names}
              error={errors.names}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.names}
              disabled={disabled}
              className='input-container-class p-0'
              classNameInput={`input-class ${appValidation}`}
              classNameLabel='label-class mb-2'
            />
          </div>
          <div
            className={`${
              appTangoBackOffice
                ? 'div-container-input-class-12'
                : 'col-md-6 surnames-register'
            }`}
          >
            <Input
              required={true}
              variant={false}
              id='surnames'
              label={'surnames'.toUpperCase()}
              type='text'
              value={values.surnames}
              error={errors.surnames}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.surnames}
              disabled={disabled}
              className='input-container-class p-0'
              classNameInput={`input-class ${appValidation}`}
              classNameLabel='label-class mb-2'
            />
          </div>
          </div>

          {child_input_admin !== undefined && selectTypeRole.verifier && (
            <div className='div-container-input-class-12'>
              <Input
                required={true}
                variant={false}
                id={'verifier_ref'}
                label={'VERIFIER_ID'}
                type={'text'}
                value={values.verifier_ref}
                error={errors.verifier_ref}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.verifier_ref}
                disabled={disabled}
                className='input-container-class'
                classNameInput='input-class'
                classNameLabel='label-class mb-2'
              />
            </div>
          )}
          {child_input_admin !== undefined && selectTypeRole.agent && (
            <div className='div-container-input-class-12'>
              <Input
                required={true}
                variant={false}
                id={'agent_ref'}
                label={'AGENT_ID'}
                type={'text'}
                value={values.agent_ref}
                error={errors.agent_ref}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.agent_ref}
                disabled={disabled}
                className='input-container-class'
                classNameInput='input-class'
                classNameLabel='label-class mb-2'
              />
            </div>
          )}
          <div className='div-container-input-class-12'>
            <div className='row input-container d-flex align-items-start input-container-class'>
              <div className='col-12 p-0'>
                <label
                  className={
                    includeInstapagePath()
                      ? 'text-white-bold-iframe'
                      : appTangoBackOffice
                      ? 'text-color-secondary'
                      : 'text-color-gray'
                  }
                >
                  {includeInstapagePath() || appTangoBackOffice
                    ? intl.get('PHONE_NUMBER').toUpperCase()
                    : intl.get('PHONE_NUMBER')}
                </label>
              </div>
              <div className='col-4 p-0 padding-select'>
                {fetchingCodes === 'FETCHED_CODES' ? (
                  <Select
                    options={countriesCodes}
                    id='country_code'
                    onChange={handleChange}
                    value={values.country_code}
                    disabled={false}
                    classNameInput='select-input p-0 font-sm-08'
                    classNameSelect={`${
                      includeInstapagePath() ? 'text-white-bold-iframe' : ''
                    } col-12 select-border  h-32-8`}
                    resource={true}
                    className='m-0 pr-0 mt-1'
                  />
                ) : (
                  <select
                    className={`${
                      includeInstapagePath() ? 'text-white-bold-iframe' : ''
                    } col-12 select-border h-32-8`}
                  >
                    <option label='---' value=''></option>
                  </select>
                )}
              </div>
              <div className='col-8 p-0'>
                <Input
                  id='phone_number'
                  type='phone_number'
                  value={values.phone_number}
                  error={errors.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.phone_number}
                  disabled={disabled}
                  className='input-container-class pdr-0'
                  classNameInput={`input-class ${appValidation}`}
                  classNameLabel='label-class mb-2'
                  variant={false}
                />
              </div>
            </div>
          </div>
          <div className='div-container-input-class-12'>
            <Input
              required={true}
              variant={false}
              id='email'
              label={'email'.toUpperCase()}
              type='email'
              value={(values.email = userEmail).toLowerCase()}
              error={!includeInstapagePath() ? errors.email : ''}
              onChange={(e: any) => saveUserEmail(e.target.value)}
              onBlur={handleBlur}
              touched={touched.email}
              disabled={disabled}
              placeholder={intl.get(`EXAMPLE_EMAIL`)}
              className='input-container-class'
              classNameInput={`input-class ${appValidation}`}
              classNameLabel='label-class mb-2'
              requiredInstapage
            />
          </div>
          <div className='div-container-input-class-12'>
            <Input
              required={true}
              variant={false}
              id='password'
              label={'password'.toUpperCase()}
              type='password'
              value={values.password}
              error={!includeInstapagePath() ? errors.password : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.password}
              disabled={disabled}
              className='input-container-class'
              classNameInput={`input-class ${appValidation}`}
              classNameLabel='label-class mb-2'
              placeholder={intl.get('MIN_CHARS_PASSWORD')}
              requiredInstapage
            />
          </div>
          <div className='div-container-input-class-12'>
            <Input
              required={true}
              variant={false}
              id='repeat_password'
              label={'CONFIRM_PASSWORD'}
              type='password'
              value={values.repeat_password}
              error={!includeInstapagePath() ? errors.repeat_password : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.repeat_password}
              disabled={disabled}
              className='input-container-class'
              classNameInput={`input-class ${appValidation}`}
              classNameLabel='label-class mb-2'
              requiredInstapage
            />
          </div>
          {!isRoleSuperAdmin && (
            <div className='div-container-input-class-12 align-checkbox my-3'>
              <input
                type='checkbox'
                className='form-check-input'
                id='checkReferenceCode'
                checked={haveReferenceCode}
                onChange={() => setHaveReferenceCode(!haveReferenceCode)}
              />
              <label
                className={`${
                  includeInstapagePath()
                    ? 'text-white-bold-iframe text-no-transform'
                    : appTangoBackOffice
                    ? 'text-color-secondary text-no-transform'
                    : 'text-color-black h6 text-no-transform'
                }`}
                htmlFor='checkReferenceCode'
              >
                {appTangoBackOffice
                  ? intl.get('HAVE_A_REFERENCE_CODE').toUpperCase()
                  : intl.get('HAVE_A_REFERENCE_CODE')}
              </label>
            </div>
          )}
          {haveReferenceCode && (
            <div className='div-container-input-class-12'>
              <Input
                variant={false}
                id='referred_code'
                type='text'
                required={haveReferenceCode}
                value={values.referred_code = referredCode || values.referred_code.toUpperCase()}
                error={errors.referred_code}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.referred_code}
                disabled={disabled}
                className='input-container-class'
                classNameInput='input-class'
                classNameLabel='label-class'
                maxLength={12}
                placeholder={intl.get('ENTER_IT_HERE')}
              />
            </div>
          )}
          {child_input_admin === undefined && (
            <div className='div-container-input-class-12 align-checkbox'>
              <Field
                type='checkbox'
                className='form-check-input'
                id='terms_read'
                name='terms_read'
                value={values.terms_read}
                checked={readTermAndPolicies}
                onChange={() => setReadTermAndPolicies(!readTermAndPolicies)}
              />
              <label
                className={
                  includeInstapagePath() ? 'text-white-bold-iframe' : 'form-check-label'
                }
                htmlFor='terms_read'
              >
                {intl.getHTML(
                  includeInstapagePath()
                    ? 'TERM_AND_POLICY_INSTAPAGE'
                    : appTangoBackOffice
                    ? 'TERM_AND_POLICY_APP_TANGO'
                    : 'TERM_AND_POLICY'
                )}
              </label>
            </div>
          )}
          {child_input_admin !== undefined && (
            <>
              <div className='div-container-input-class-12'>
                <Input
                  required={true}
                  variant={false}
                  id={'street_address'}
                  label={'STREET_ADDRESS'}
                  type={'text'}
                  value={values.street_address}
                  error={errors.street_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.street_address}
                  disabled={disabled}
                  className='input-container-class'
                  classNameInput='input-class'
                  classNameLabel='label-class'
                />
              </div>
              <div className='div-container-input-class-12'>
                <Input
                  required={true}
                  variant={false}
                  id={'street_address_extra'}
                  label={'STREET_ADDRESS_EXTRA'}
                  type={'text'}
                  value={values.street_address_extra}
                  error={errors.street_address_extra}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.street_address_extra}
                  disabled={disabled}
                  className='input-container-class'
                  classNameInput='input-class'
                  classNameLabel='label-class'
                />
              </div>
            </>
          )}
          <div className='div-container-input-class-12 input-container-class'>
            {children}
          </div>
        </Form>
      ) : isRoleOwner ? (
        <NewRegisterForm
          userEmail={userEmail}
          setuserEmail={setuserEmail}
          children={children}
          onSubmit={onSubmit}
        />
      ) : (
        history.push('/login')
      )}
    </>
  )
}

const validateEmail = (event: any) => {
  let emailRegex = /^([A-Za-z0-9-_.A+])+@([A-Za-z0-9-_.])+\.([A-Za-z]){2,4}$/
  return emailRegex.test(event)
}

/**
 * This is used to select the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */

const createUserForms = withFormik<NewPasswordFormProps, createUserForm>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      country_code: '',
      email: '',
      password: '',
      repeat_password: '',
      names: '',
      surnames: '',
      phone_number: '',
      terms_read: true,
      ...props.child_input_admin,
      referred_code: '',
      selected_role: isRoleSuperAdmin ? '' : process.env.REACT_APP_ROLE || '',
    }

    return { ...initialValues, ...props }
  },

  validationSchema: isRoleOwner ? NewFormRegister : FormRegister,

  handleSubmit(values: createUserForm, { props }) {
    if (!values.phone_number) {
      toast(intl.get('PHONE_NUMBER_REQUIRED'))
    }
    if (
      values.phone_number.toString().indexOf(values.country_code) === -1 &&
      values.phone_number.toString().length === 9
    ) {
      values.phone_number = values.country_code + values.phone_number
    }
    if (!validateEmail(values.email)) {
      toast(intl.get('EMAIL_PATTERN_ERROR'))
    }
    if (values.referred_code !== '') {
      if (
        values.referred_code.length < 12 ||
        values.referred_code.length > 13
      ) {
        toast(intl.get('VERIFY_REFERRED_CODE'))
        return
      }
    }

    //validate the password and the repeat password input are the same
    if (values.password === values.repeat_password) {
      props.onSubmit(values)
    } else {
      toast(intl.get('CHECK_PASS_ERROR'))
    }
  },
})(InnerForm)
export default createUserForms
