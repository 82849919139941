import { getDataResource } from '../../../tango-react-base/helpers/resources';
import { getCitiesStart, getCitiesError, getCitiesSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCities = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getCitiesStart());
        getDataResource('provinces')
            .then((result) => {
                dispatch(getCitiesSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                dispatch(getCitiesError(e));
            });
    };
};
export default getCities;
