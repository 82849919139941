export const getContractStart = () => {
    return {
        type: 'GET_CONTRACT_STARTED',
    };
};

export const getContractSuccess = (result: any) => {
    return {
        type: 'GET_CONTRACT_SUCCESS',
        payload: { result },
    };
};
export const getContractError = (error: any) => {
    return {
        type: 'GET_CONTRACT_ERROR',
        payload: {
            error,
        },
    };
};
