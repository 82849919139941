import React, { useState } from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import { Input } from '../../'
import intl from 'react-intl-universal'
import { useWindowSize } from '../../../helpers/hookWindowSize'

/** Here it is defined the type of the props for the interface "ManualReferredCodeFormProps"
 * @property {function} handleRejectionReferredCode - is a function.
 * @property {function} setReferredCodeLocalStorage - is a function.
 * @property {string} fetchingNewReference - is a string.
 * @type {(function|string)}
 * @typedef ManualReferredCodeFormProps
 */
interface ManualReferredCodeFormProps {
  handleRejectionReferredCode: Function
  setReferredCodeLocalStorage: Function
  fetchingNewReference: string
}

interface ManualReferredCodeFormValues {
  referredCode: string
}

/**
 * ManualReferredCodeForm is a functional component
 * The main function of this component is to render a form when the reference code is wrong using Formik.
 * @function
 * @param {ManualReferredCodeFormProps} handleRejectionReferredCode - Control what happens when the form is closed or skipped to enter the correct reference code
 * @param {ManualReferredCodeFormProps} fetchingNewReference - Informs about the current status of the request to back
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<
ManualReferredCodeFormProps & FormikProps<ManualReferredCodeFormValues>
> = ({
  values,
  handleChange,
  handleBlur,
  touched,
  handleRejectionReferredCode,
  fetchingNewReference
}) => {
  const [sendCode, setSendCode] = useState(false)
  const size = useWindowSize()
  return (
    <Form className='confirmation-referred-form'>
      <div className='confirmation-referred-text-container mt-0'>
        <div className="col-12 p-0">
          <p className='text-left'>{intl.get('REFERRED_CODE_NOT_FOUND')}</p>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12 p-0">
          <p className='mb-0 text-left'>{intl.get('ENTER_NEW_REFERRED_CODE')}</p>
        </div>
      </div>
      <Input
        id='referredCode'
        type='text'
        value={values.referredCode.toUpperCase()}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.referredCode}
        required
        maxLength={12}
        className='input-container-class p-0'
        classNameInput= {`col-12 p-0 ${fetchingNewReference === 'FETCHING_ERROR' && sendCode ? 'input-alert' : ''}`}
        name='referredCode'
        labelInput='Ej. REF02636451U'
        disabled={fetchingNewReference === 'FETCHING'}
        messageAdditionalValidation={
          fetchingNewReference === 'FETCHING_ERROR' && sendCode ?
          intl.get('INVALID_REFERRED_CODE') : ''
        }
      />
      <div className='confirmation-referred-button-container'>
        {size.width >= 768 ? (
          <div className='row'>
            <div className='col-6 p-0 pr-2'>
              <button
                className='button-five p-0 col-12'
                onClick={() => handleRejectionReferredCode()}
                disabled={fetchingNewReference === 'FETCHING'}
              >
                {intl.get('SKIP')}
              </button>
            </div>
            <div className='col-6 p-0 pl-2'>
              <button
                className='button-primary p-0 col-12'
                type='submit'
                disabled={fetchingNewReference === 'FETCHING'}
                onClick={() => setSendCode(true)}
              >
                {intl.get('NEXT')}
              </button>
            </div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-12 p-0 mb-3'>
              <button
                className='button-primary col-12 p-0'
                type='submit'
                disabled={fetchingNewReference === 'FETCHING'}
                onClick={() => setSendCode(true)}
              >
                {intl.get('NEXT')}
              </button>
            </div>
            <div className='col-12 p-0'>
              <button
                className='button-five col-12 p-0 mr-md-2'
                onClick={() => handleRejectionReferredCode()}
                disabled={fetchingNewReference === 'FETCHING'}
              >
                {intl.get('SKIP')}
              </button>
            </div>
          </div>
        )}
      </div>
    </Form>
  )
}

const ManualReferredCodeForm = withFormik<ManualReferredCodeFormProps, ManualReferredCodeFormValues>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      referredCode: ''
    }
    return { ...initialValues, ...props }
  },

  handleSubmit(values: ManualReferredCodeFormValues, { props, resetForm }) {
    props.setReferredCodeLocalStorage(values.referredCode.toUpperCase())
  }
})(InnerForm)

export default ManualReferredCodeForm