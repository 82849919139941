const initialState: any = {
  status: null,
  error: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_NEW_TOKEN_STARTED':
      return {
        ...state,
        status: 'STARTED',
      }

    case 'GET_NEW_TOKEN_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
      }
    case 'GET_NEW_TOKEN_ERROR':
      return {
        ...state,
        status: 'ERROR',
        error: action.payload.error,
      }
    default:
      return state
  }
}
