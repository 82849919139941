const initialState = {
  status: 'NO_FETCH',
  clf: null,
  date: null
}

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case 'GET_CLF_STARTED':
      return {
        ...state,
        status: 'FETCHING'
      }
    case 'GET_CLF_SUCCESS':
      let object = action.payload.data
      let key = Object.keys(object)
      let value = Object.values(object)
      return {
        status: 'FETCHED',
        clf: value[0],
        date: key[0]
      }
    case 'GET_CLF_ERROR':
      return {
        ...state,
        clf: null,
        status: 'ERROR_FETCH'
      }
    default:
      return state
  }
}
