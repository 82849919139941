import React, { useState, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ButtonGroup, CustomDot } from '../carousel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

type AllPhotosProps = {
    photos: Array<any>;
    deleteFunction?: any;
};
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

const AllPhotos = ({ photos }: AllPhotosProps) => {
    const classes = useStyles();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [srcImages, setSrcImages] = useState<Array<any>>([]);
    const [indexShow, setIndexShow] = useState<number>(0);

    const lengthDependWitdh = window.screen.width > 1024 ? 3 : 2;
    const columnNumber = photos.length > 1 ? 'col-4' : 'col-12';
    useEffect(() => {
        let allImageSouce: Array<any> = [];
        photos.forEach((image: any) => (allImageSouce = allImageSouce.concat({ source: image.photo_url })));
        setSrcImages(allImageSouce);
    }, [photos]);

    const toggleModal = (src: any) => {
        setModalIsOpen(!modalIsOpen);
        typeof src === 'string' && setIndexShow(srcImages.findIndex((image) => image.source === src));
    };

    const allImagesElementsCarrousel = (className: string, classNameDiv?: string) =>
        photos.map((image: any) => (
            <div key={image.photo_url} className={classNameDiv}>
                <img
                    src={image.photo_url}
                    alt={image.title}
                    onClick={(e) => toggleModal(image.photo_url)}
                    className={className}
                />
            </div>
        ));

    return (
        <>
            {photos.length > lengthDependWitdh ? (
                <div className={`image-carousel-container item-carousel-container ${classes.root}`}>
                    <Carousel
                        arrows={false}
                        customButtonGroup={<ButtonGroup />}
                        containerClass='container-with-dots'
                        deviceType='desktop'
                        infinite={false}
                        itemClass='image-item'
                        keyBoardControl={true}
                        responsive={responsive}
                        ssr
                        showDots
                        sliderClass=''
                        swipeable
                        customDot={<CustomDot />}
                    >
                        {allImagesElementsCarrousel('image-carousel-line mt-5')}
                    </Carousel>
                </div>
            ) : (
                <div className='row pt-4' style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {allImagesElementsCarrousel('img-show-renter-post', columnNumber)}
                </div>
            )}
        </>
    );
};

export default AllPhotos;
