import { getDataResource } from '../../../tango-react-base/helpers/resources';
import { getCommunesStart, getCommunesError, getCommunesSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCommunes = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getCommunesStart());
        getDataResource('communes')
            .then((result) => {
                dispatch(getCommunesSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                dispatch(getCommunesError(e));
            });
    };
};
export default getCommunes;
