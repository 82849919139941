export const updatePhoneNumberStart = () => {
    return {
        type: 'UPDATE_USER_STARTED',
    };
};

export const updatePhoneNumberSuccess = (result: any) => {
    return {
        type: 'UPDATE_USER_SUCCESS',
        payload: {
            result,
        },
    };
};
export const updatePhoneNumberError = (error: any) => {
    return {
        type: 'UPDATE_USER_ERROR',
        payload: {
            error,
        },
    };
};
