import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { CircularLoading } from '../';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import es from 'date-fns/esm/locale/es';
import { colors, withStyles } from '@material-ui/core';
import { FormikProps, withFormik, Form } from 'formik';
import { IncomeData } from '../forms/incomeDataForm/IncomeData.type';
import { calendar } from '../../resources/index';
import { useWindowSize } from '../../helpers/hookWindowSize';
import { PopupWithIconButtons } from '..';

/**
 *  here are the props used for CloseLeaseEarly component
 *  @param {date} minDate - min date to select on calendar
 *  @param {date} maxDate - max date to select on calendar
 *  @param {date} dueDate - date of lease due date
 *  @param {string} minimumDays - minimum dates to select a date into the future
 *  @param {any} fineAmount - amount to the fine
 *  @param {string} yourPropertyText - title of lease data list
 *  @param {object} leaseData - data of the lease
 *  @param {any} whenWillDesocupate - text of when will be desocupate
 *  @param {string} registeredCurrency - currency of the lease clp or clf
 *  @param {string} motiveText - label of motive text area
 *  @param {boolean} sendingRequisition - boolean for knowing if the requisition is sending
 *  @param {string} address - address of the property
 *  @param {string} subtitle - subtitle for the view
 *  @param {string} customWarning - custom warning text
 *  @param {any} redirections - redirections of the view, for example renter and owner
 *  @param {boolean} noPopup - boolean for knowing if the second confirmation popup is shown or not, true doesn't show it
 *  @param {string} customCardTitle - custom card title if present shows it if not default one
 */
interface closeLeaseEarlyProps {
    minDate: any;
    maxDate: any;
    dueDate: any;
    minimumDays?: any;
    fineAmount: any;
    yourPropertyText: string;
    leaseData: any;
    whenWillVacate: string;
    motiveText: string;
    registeredCurrency: string;
    sendingRequisition: boolean;
    address: string;
    subtitle?: string;
    customWarning?: string;
    redirections?: any;
    noPopup?: boolean;
    customCardTitle?: string;
    onConfirmation: (date: any, motive: any) => void;
}

/**
 * Here it is defined the detailList
 * @param {string} listClass - is a string.
 * @param {string} titleClass - is a string.
 * @param {string} detailClass?: string;
 */
interface detailsListProps {
    listClass?: string;
    titleClass?: string;
    detailClass?: string;
    dict: Array<{ label: string; value: string }>;
}

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#00000023',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#00000023',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#00000023',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#00000023',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#00000023',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#00000023',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#00000023',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00000023',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);

/**
 * @function
 * @param {detailsListProps} titleClass - class for the title of the component.
 * @param {detailsListProps} detailClass - class used for the detail of the component.
 * @param {detailsListProps} dict - array used for storage the elements for the list.
 * @returns {(ReactComponent)} Returns a react component.
 */
const DetailedListComponent = ({
    listClass = '',
    titleClass = 'component-list',
    detailClass = '',
    dict = [],
}: detailsListProps) => {
    const elements = dict.map((item) => {
        const { label, value } = item;
        return (
            <div key={label}>
                <dt className={titleClass}>{`${label}:`}</dt>
                <dd>&nbsp;{value}</dd>
            </div>
        );
    });

    return <dl>{elements}</dl>;
};

/**
 * Here it is defined the card
 * @param {any} children - is a string.
 */
interface CardProps {
    children?: any;
}
/**
 * @param {CardProps} children - children used to return the card conteiner.
 * @returns {(ReactComponent)} Returns a react component.
 */
const Card = ({ children = null }: CardProps) => {
    return (
        <div className={`card card-container-motive-details`}>
            <div className='card-body'>{children}</div>
        </div>
    );
};

/**
 * Here it is defined the card content
 * @param {string} whenWillVacate - is a string.
 * @param {any} buttonClass - is a string.
 * @param {date} dueDate - is date
 * @param {string} motive - is a string
 * @param {date} selectedDate -is a date
 * @param {function} handleDateChange -is a function
 * @param {date} minDate - is a date
 * @param {date} maxDate - is a date
 * @param {date} minimumDays -is a date
 * @param {string} motiveText - is a string
 * @param {boolean} loading - boolean to show loading button or not
 * @param {string} customCardTitle - custom card title if present shows it if not default one
 */
interface CardContentProps {
    whenWillVacate: string;
    buttonClass?: string;
    dueDate: string;
    motive: string;
    selectedDate?: any;
    handleDateChange: any;
    minDate: any;
    maxDate: any;
    minimumDays?: any;
    motiveText: string;
    loading?: boolean;
    customCardTitle?: string;
    handleChange: (value: string) => void;
    handleAction: () => void;
}
/**
 * component for the card content
 * @param {CardContentProps} buttonClass - class for the buttons in the component
 * @returns {(ReactComponent)} Returns a react component.
 */
const CardContent = ({
    buttonClass,
    whenWillVacate,
    dueDate,
    motive,
    selectedDate,
    handleDateChange,
    minDate,
    maxDate,
    minimumDays = 0,
    motiveText,
    loading,
    customCardTitle,
    handleChange,
    handleAction,
}: CardContentProps) => {
    const [open, setOpen] = React.useState(false);
    const [firstChange, setFirstChange] = React.useState(true);
    const validSend = (date: any, motive: string) => {
        if (date !== '' && motive !== '' && minDate < new Date(maxDate)) {
            return true;
        }
        return false;
    };
    const handleChangeDate = (e: any) => {
        handleDateChange(e);
        setOpen(false);
    };
    const onHandleChange = (e: any) => {
        if (firstChange) setFirstChange(false);
        handleChange(e.target.value);
    };

    let motiveComponent = (
        <TextField
            className='w-100'
            id='outlined-basic'
            error={!firstChange && motive.length === 0}
            helperText={!firstChange && motive.length === 0 ? intl.get('REQUIRED') : ''}
            value={motive}
            placeholder={intl.get('MAX_CHARACTERS')}
            variant='outlined'
            multiline
            disabled={minDate > new Date(maxDate)}
            rows={8}
            inputProps={{ maxLength: 200 }}
            onChange={onHandleChange}
        />
    );

    return (
        <>
            <p className='periods-contract-title'>
                {!!customCardTitle ? customCardTitle : intl.getHTML('EARLY_LEASE_TERMINATION_DUE_DATE', { dueDate })}
            </p>
            <p className='card-title'>{whenWillVacate}</p>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <div className=''>
                    <BootstrapInput
                        className='w-100'
                        openTo={'date'}
                        open={open}
                        views={['year', 'month', 'date']}
                        variant='inline'
                        placeholder='dd/mm/aaaa'
                        inputVariant={'outlined'}
                        size='small'
                        format='dd/MM/yyyy'
                        margin='normal'
                        onClick={() => setOpen(true)}
                        id='date-picker-inline'
                        value={minDate > new Date(maxDate) ? '' : selectedDate}
                        error={minDate > new Date(maxDate)}
                        onChange={handleChangeDate}
                        invalidDateMessage={intl.get('INVALID_FORMAT_DATE')}
                        maxDate={maxDate}
                        minDate={minDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            onFocus: (e) => {
                                setOpen(true);
                            },
                        }}
                        readOnly={false}
                        disabled={false}
                        helperText={
                            minimumDays > 0 &&
                            (!(minDate > new Date(maxDate))
                                ? intl.get('EARLY_LEASE_TERMINATION_TIME_CONSIDER', {
                                      minimumDays,
                                  })
                                : intl.getHTML('REQUISITION_WARING_DAY', {
                                      minimumDays,
                                  }))
                        }
                        keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='C' />}
                        PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                                setOpen(false);
                            },
                        }}
                        InputProps={{
                            onFocus: () => {
                                setOpen(true);
                            },
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <h3 className='text-left pt-3'>{intl.getHTML('EARLY_LEASE_TERMINATION_MOTIVE', { motiveText })}</h3>
            {motiveComponent}
            <div className='send-solicitud-button-container'>
                <button
                    onClick={handleAction}
                    disabled={!validSend(selectedDate, motive) || loading}
                    className={buttonClass}
                >
                    {loading ? <CircularLoading /> : intl.get('SEND_SOLICITUDE')}
                </button>
            </div>
        </>
    );
};

/**
 * EarlyLeaseTerminationComponent is a functional component
 * @param closeLeaseEarlyProps
 * @returns jsx.function
 * @describe Base component to closure lease termination
 */
const EarlyLeaseTerminationComponent = ({
    minDate,
    maxDate,
    minimumDays = 0,
    motiveText,
    yourPropertyText,
    leaseData,
    registeredCurrency,
    fineAmount,
    dueDate,
    address,
    whenWillVacate,
    subtitle,
    customWarning,
    redirections,
    noPopup,
    customCardTitle,
    onConfirmation,
    sendingRequisition,
}: closeLeaseEarlyProps): JSX.Element => {
    const [motive, setMotive] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(minDate);
    const history = useHistory();
    const onHandleDateChange = (date: any) => {
        setSelectedDate(date);
    };
    const onChangeMotiveText = (value: string) => {
        setMotive(value);
    };
    const formatterDate = (date: any) => {
        // formate Date to dd/mm/yyyy
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const onHandleConfirmation = () => {
        onConfirmation(selectedDate, motive);
    };
    useEffect(() => {
        setSelectedDate(minDate);
    }, [minDate]);

    return (
        <div className='early-lease-termination'>
            <div className='early-lease-termination-detail'>
                <h2 className='title font-weight-bold'>{intl.get('EARLY_LEASE_TERMINATION_TITLE')}</h2>
                {!!subtitle && <p>{subtitle}</p>}
                <p>
                    {!customWarning
                        ? intl.getHTML(
                              `EARLY_LEASE_TERMINATION_WARNING_${registeredCurrency.toUpperCase()}`,
                              fineAmount,
                          )
                        : customWarning}
                </p>
                {!!redirections && (
                    <div className='mt-4'>
                        {redirections.map((item: { label: string; redirection: string; value: string }) => {
                            const { label, redirection, value } = item;
                            return (
                                <div className='link-general-app'>
                                    <b>{label}</b> :{' '}
                                    <a
                                        onClick={() => {
                                            history.push(redirection);
                                        }}
                                    >
                                        {value}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                )}
                <h3 className='font-weight-bold mt-5'>{yourPropertyText}</h3>
                <DetailedListComponent dict={leaseData} />
            </div>
            <div className='early-lease-solicitud-card'>
                <Card>
                    <CardContent
                        buttonClass='send-solicitude-button mt-3'
                        selectedDate={selectedDate}
                        whenWillVacate={whenWillVacate}
                        dueDate={dueDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        minimumDays={minimumDays}
                        motiveText={motiveText}
                        motive={motive}
                        handleDateChange={onHandleDateChange}
                        handleChange={onChangeMotiveText}
                        handleAction={() => (noPopup ? onHandleConfirmation() : setOpenPopup(true))}
                        loading={noPopup && sendingRequisition}
                        customCardTitle={customCardTitle}
                    />
                </Card>
                <PopupWithIconButtons
                    open={openPopup}
                    className='general-popup-with-buttons'
                    handleClose={() => setOpenPopup(false)}
                    handleConfirm={onHandleConfirmation}
                    title='REQUEST_ANTICIPATED_CONTRACT_TERM'
                    classNameTitle='font-weight-bold'
                    lineUp
                    loading={sendingRequisition}
                    textButtonRed={intl.get('SEND_REQUEST')}
                    textButtonWhite={intl.get('CANCEL')}
                >
                    <span>
                        {intl.getHTML('CONFIRM_REQUEST_REQUISITION', {
                            address: address,
                            request_day: formatterDate(selectedDate),
                            fine_amount: fineAmount[registeredCurrency],
                            fine_multiplicator: '1,5',
                        })}
                    </span>
                </PopupWithIconButtons>
            </div>
        </div>
    );
};

export default EarlyLeaseTerminationComponent;
