import * as Yup from 'yup';
import intl from 'react-intl-universal';
import { setLocale } from 'yup';
const FormValidates = () => {
    setLocale({
        mixed: {
            required: intl.get('REQUIRED_FIELD'),
            notType: intl.get('INVALID_FORMAT'),
        },
        string: {
            email: intl.get('INSERT_VALID_MAIL'),
        },
    });
    return Yup.object().shape({
        bank_account_type: Yup.string().required(),
        bank_name: Yup.string().required(),
        bank_account_number: Yup.string().required(),
        email: Yup.string().when('third_party', {
            is: 'yes',
            then: Yup.string().email().required(),
        }),
        full_name: Yup.string().when('third_party', {
            is: 'yes',
            then: Yup.string().required(),
        }),
        rut: Yup.string().when('third_party', {
            is: 'yes',
            then: Yup.string().required(),
        }),
    });
};
export default FormValidates;
