import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { getUnitStart, getUnitSuccess, getUnitError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUnits = (id: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(getUnitStart())
    getData(`units/${id}`)
      .then((result) => {
        dispatch(getUnitSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getUnitError(e))
      })
  }
}
export default getUnits
