import React from 'react';
import { uploadAnnex } from '../../../tango-react-base/reduxActions';
import { connect } from 'react-redux';
import DropZoneAgent from '../../../tango-react-base/components/dropZoneAgent';
import { eyeIcon } from '../../resources';
import { Tooltip } from '@material-ui/core';
import intl from 'react-intl-universal';

interface UploadPDFProps {
    onUploadPDF: (visitId: any, data: any, url: string, typeTable: string) => void;
    canUpload: boolean;
    urlDeliveryVoucher: string;
    urlRestorationVoucher: string;
    visitId: number;
    contractId: number;
    typeTable: string;
}

class File {
    file: any;
    documentType: string;
    constructor(file: any, documentType: string) {
        this.file = file;
        this.documentType = documentType;
    }
}

const UploadPDF = ({
    onUploadPDF,
    canUpload,
    urlDeliveryVoucher,
    urlRestorationVoucher,
    visitId,
    contractId,
    typeTable,
}: UploadPDFProps) => {
    const handleUpload = (visitId: any, contractId: number) => (e: any) => {
        if (e.target.files[0]) {
            let fileToUpload: File = e.target.files[0];
            let url =
                typeTable === 'pending_delivered'
                    ? `lease_contract_delivery_vouchers/${contractId}/add_delivery_voucher`
                    : `lease_contract_restoration_vouchers/${contractId}/add_restoration_voucher`;
            onUploadPDF(visitId, fileToUpload, url, typeTable);
        }
    };

    const uploadPDF = (visitId: number, contractId: number) => {
        return <DropZoneAgent onUploadPDF={handleUpload(visitId, contractId)} />;
    };

    const annexUploader = () => {
        // If there is a url, it shows eye to be able to see the uploaded file
        // and you can upload record again.
        if ((canUpload && urlDeliveryVoucher) || (canUpload && urlRestorationVoucher)) {
            return (
                <div className='d-flex justify-content-between'>
                    <Tooltip title={intl.get('SEE_CURRENT_INVENTORY')} arrow>
                        <a
                            target='_blank'
                            className='d-flex align-items-center mr-3'
                            rel='noopener noreferrer'
                            href={urlDeliveryVoucher ? urlDeliveryVoucher : urlRestorationVoucher}
                        >
                            <img src={eyeIcon} alt='eye-icon' width='45' />
                        </a>
                    </Tooltip>
                    {uploadPDF(visitId, contractId)}
                </div>
            );
        }
        // If there is no url, upload file.
        else if ((canUpload && !urlDeliveryVoucher) || (canUpload && !urlRestorationVoucher)) {
            return <>{uploadPDF(visitId, contractId)}</>;
        } else {
            // If you can no longer upload minute, just show a eye.
            return (
                <Tooltip title={intl.get('SEE_CURRENT_INVENTORY')} arrow>
                    <a
                        target='_blank'
                        className='d-flex align-items-center mr-3'
                        rel='noopener noreferrer'
                        href={urlDeliveryVoucher ? urlDeliveryVoucher : urlRestorationVoucher}
                    >
                        <img src={eyeIcon} alt='eye-icon' width='45' />
                    </a>
                </Tooltip>
            );
        }
    };
    return <div>{annexUploader()}</div>;
};

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => {
    return {
        onUploadPDF: (visitId: any, data: any, url: string, typeTable: string) => {
            return dispatch(uploadAnnex(visitId, data, url, typeTable));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPDF);
