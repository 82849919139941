import { getData } from '../../helpers/apiConnections';
import { getPhotosStart, getPhotosSuccess, getPhotosError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getPhotos = (id: number) => {
    return (dispatch: any) => {
        dispatch(getPhotosStart());
        getData(`posts/${id}/photos`)
            .then((result) => {
                dispatch(getPhotosSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPhotosError(e));
            });
    };
};
export default getPhotos;
