import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { changeDay, changeHour, changeVisit } from '../../reduxActions/calendarActions';
import { nextWeek, lastWeek } from '../../reduxActions/requesterCalendarActions';
import { useParams, useHistory } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { getBlockGroup, getBlocks } from '../../reduxActions';
import intl from 'react-intl-universal';
import { backArrow, forwardArrow } from '../../resources/index';
import { CircularLoading } from '..';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { RenterCalendarMobile } from './renterCalendarMobile';
import { getMonth } from '../../helpers/getMonth';
import { getBlocksReset } from '../../reduxActions/getBlocks/types';
import { isRoleTanguero, isRolePhotographer } from '../../helpers/roleComparison/index';

var moment = require('moment');

interface CalendarProps {
    submitBlocksTaken: any;
    startWeek: any;
    endWeek: any;
    onLastWeek: any;
    onNextWeek: any;
    week: Array<any>;
    blocksTaken: Array<any>;
    hourTaken: Array<any>;
    onChangeDate: any;
    onChangeHour: any;
    blocksAvailable: Array<any>;
    blockGroupAvailable: Array<any>;
    fetchingBlocks: any;
    error: any;
    onGetBlocks: any;
    onGetBlocksGroup: (id?: string, reschedule?: boolean) => void;
    onResetBlocks: () => void;
    fetchingBlocksGroupAvailable: string;
}

const Calendar = ({
    submitBlocksTaken,
    startWeek,
    endWeek,
    onLastWeek,
    onNextWeek,
    week,
    blocksTaken,
    hourTaken,
    blocksAvailable,
    blockGroupAvailable,
    onChangeDate,
    onChangeHour,
    onGetBlocks,
    fetchingBlocks,
    error,
    onGetBlocksGroup,
    onResetBlocks,
    fetchingBlocksGroupAvailable,
}: CalendarProps) => {
    const [showBlocks, setShowBlocks] = useState(false);
    const [showBlocksMorning, setShowBlocksMorning] = useState(false);
    const [showBlocksAfternoon, setShowBlocksAfternoon] = useState(false);
    const [showBlocksNight, setShowBlocksNight] = useState(false);

    let tenDays = moment().add(14, 'day');
    const groupAvailable = fetchingBlocks === 'FETCHED_BLOCKS' ? blockGroupAvailable : [];

    let { id, idVisit }: any = useParams();
    let history = useHistory();

    useEffect(() => {
        // TODO: Remove when block group are deleted
        let reschedule = false;
        if (isRoleTanguero || isRolePhotographer) {
            reschedule = history.location.pathname.includes('calendar');
        }

        onGetBlocksGroup(id, reschedule);
        onGetBlocks(id);
    }, [id, onGetBlocks, onGetBlocksGroup]);

    const [stateBlocks, setStateBlocks]: any = useState([]);

    useEffect(() => {
        blocksAvailable.length > 0 && setStateBlocks(blocksAvailable);
    }, [blocksAvailable]);

    const dayDate = (date: any) => {
        const initDay = moment(date).hours(9).minutes(0).seconds(0);
        return initDay.format('DD');
    };

    const blocksDay = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        const endDate = moment(date).hours(21).minutes(0).seconds(0);
        let iterDate = initDay;
        let dateBlocks: Array<any> = [];
        while (iterDate < endDate) {
            dateBlocks.push({
                block_group: {
                    hour: `${iterDate.format('HH:mm')}`,
                    start_time: `${iterDate.format('YYYY-MM-DD HH:mm:ss')}`,
                    end_time: `${iterDate.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}`,
                },
            });
        }
        return dateBlocks;
    };

    /* blocksDate => Its function is to filter the data of blockdays obtaining the blocks of hours and make a
     mapping of all the blocks generating buttons with the hours available for days.
     Only the corresponding blocks will be enabled per hour range.
  */
    const blocksDate = (date: any, blocks: string, blockValidation?: boolean) => {
        const data = blocksDay(date);

        const start_time = moment(date).hours(8).minutes(0).seconds(0);

        const filterData = data.filter((hr: any) => {
            const hour = moment(hr.block_group.start_time);
            let duration = moment.duration(hour.diff(start_time));
            let hours = duration.asHours();
            if (blocks === 'morning') {
                return hours <= 3.5;
            } else if (blocks === 'afternoon') {
                return hours >= 4 && hours <= 8.5;
            } else if (blocks === 'night') {
                return hours >= 9 && hours <= 17;
            }
        });

        if (filterData.length > 0) {
            let count = 0;

            const filterBlockAvailable = filterData.map((block: any) => !isDisabled(block.block_group.start_time));
            let blockComplete: any = filterData.map((block: any, index: number) => {
                return (
                    <>
                        {!isDisabled(block.block_group.start_time) ? (
                            <></>
                        ) : (
                            <ToggleButton
                                key={index}
                                value='check'
                                onChange={() => onChangeHour(block.block_group.start_time, stateBlocks)}
                                selected={isSelectedHour(block.block_group.start_time)}
                                className='col-12 toggle-button-hour-renter'
                            >
                                <div className='columns'>{block.block_group.hour}</div>
                            </ToggleButton>
                        )}
                    </>
                );
            });
            blockComplete =
                count === 24 ? <p className='text-left mt-4'>{intl.get('NOT_AVAILABLE_DAY')}</p> : blockComplete;

            if (blockValidation) {
                return filterBlockAvailable;
            } else {
                return blockComplete;
            }
        }
    };

    const handleMorning = () => {
        setShowBlocks(true);
        setShowBlocksAfternoon(false);
        setShowBlocksMorning(!showBlocksMorning);
        setShowBlocksNight(false);
    };
    const handleAfternoon = () => {
        setShowBlocks(true);
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(!showBlocksAfternoon);
        setShowBlocksNight(false);
    };

    const handleNight = () => {
        setShowBlocks(true);
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(false);
        setShowBlocksNight(!showBlocksNight);
    };

    /* disabledDay => disable the Sunday blocks, those that are greater than
  ten days from today and that are less than the current day */
    let actualDay = moment().subtract(1, 'days');

    const disabledDay = (day: any) => {
        if (day.day() === 0 || day > tenDays || day < actualDay) {
            return true;
        } else {
            return isDisabled(dayDate(day));
        }
    };

    const isDisabled = (date: any) => {
        const filteredblocksDisabled = stateBlocks.filter((dateIter: any) => {
            return moment(dateIter).isSame(date);
        });
        return filteredblocksDisabled.length > 0;
    };
    const isSelectedHour = (hour: any) => {
        if (hourTaken.length > 0) {
            return hourTaken[0].isSame(moment(hour));
        }
    };

    const isSelected = (date: any) => {
        return blocksTaken === date;
    };

    const blockType = [
        {
            name: 'Morning',
            block: '8:00-11:30',
            action: handleMorning,
            show: showBlocksMorning,
        },
        {
            name: 'Afternoon',
            block: '12:00-16:30',
            action: handleAfternoon,
            show: showBlocksAfternoon,
        },
        {
            name: 'Night',
            block: '17:00-20:30',
            action: handleNight,
            show: showBlocksNight,
        },
    ];

    const size = useWindowSize();

    const firstDayWeek = week[0];
    const [weekCount, setWeekCount] = useState(1);

    const handleNextWeek = () => {
        if (weekCount === 1 && weekCount <= 2) {
            onNextWeek();
            setWeekCount(weekCount + 1);
        }
    };
    const handleLastWeek = () => {
        if (weekCount > 1) {
            moment(firstDayWeek) >= moment() && onLastWeek();
            setWeekCount(weekCount - 1);
        }
    };

    const isAvailableBlock = (date: any) => {
        const dateWithMinutes = moment(date).minutes(30).seconds(0).format();
        const filteredblocksAvailable = groupAvailable.filter((dateIter: any) => {
            return moment(dateIter.start_time).isSame(date) || moment(dateIter.start_time).isSame(dateWithMinutes);
        });
        return filteredblocksAvailable.length > 0;
    };

    const disableBlock = (block: string, day: any) => {
        const timeNow = moment().format('HH:mm');
        return block.split('-')[1] < timeNow && moment(day) <= moment();
    };
    const blocksValdation = stateBlocks.length !== 0 && fetchingBlocks === 'FETCHED_BLOCKS';
    return size.width >= 768 ? (
        <>
            <div className='row'>
                {blocksValdation && (
                    <div className='right-calendar-arrow'>
                        <a onClick={() => handleLastWeek()}>
                            <img src={backArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}

                <div className={size.width >= 768 ? 'col-10' : 'col-12'}>
                    {stateBlocks.length > 0 &&
                        fetchingBlocks === 'FETCHED_BLOCKS' &&
                        fetchingBlocksGroupAvailable === 'FETCHED' && (
                            <>
                                <h2 className='mb-2 text-left pl-2 mt-3'>{getMonth(startWeek, endWeek)}</h2>
                                <div className='header-calendar calendar-container '>
                                    <section className='row no-wrap week-section align-items-start'>
                                        {week.map((day: any, index: number) => {
                                            return (
                                                <div className='col columns day-column' key={index}>
                                                    <div className='d-flex justify-content-center align-items-center div-sec-owner'>
                                                        <span className='word-sp-5 font-sm-1r'>
                                                            {intl.get(day.format('dddd').toUpperCase())}{' '}
                                                            {day.format('DD')}
                                                        </span>
                                                    </div>
                                                    {blockType.map(({ name, block, action, show }) => {
                                                        const hour = block.split(':')[0];
                                                        const blockGroupAvailable = isAvailableBlock(
                                                            moment(day)
                                                                .hours(parseInt(hour))
                                                                .minutes(0)
                                                                .seconds(0)
                                                                .format(),
                                                        );
                                                        return (
                                                            <>
                                                                <ToggleButton
                                                                    value='check'
                                                                    selected={isSelected(dayDate(day))}
                                                                    onClick={action}
                                                                    disabled={
                                                                        disabledDay(day) ||
                                                                        !blockGroupAvailable ||
                                                                        disableBlock(block, day)
                                                                    }
                                                                    onChange={() => {
                                                                        onChangeDate(dayDate(day));
                                                                    }}
                                                                    className={`col-12 toggle-button-hour ${
                                                                        disabledDay(day) || disableBlock(block, day)
                                                                            ? 'btn-disabled'
                                                                            : !show && 'bg-transparent'
                                                                    }`}
                                                                >
                                                                    <p className='columns btn-day-owner'>
                                                                        <span>{intl.get(name.toUpperCase())}</span>
                                                                        <br />
                                                                        <span>{block}</span>
                                                                    </p>
                                                                    {!disabledDay(day) &&
                                                                        blockGroupAvailable &&
                                                                        !disableBlock(block, day) && (
                                                                            <img
                                                                                src={backArrow}
                                                                                alt='arrow'
                                                                                className='arrow-section ml-2'
                                                                                width='10'
                                                                            />
                                                                        )}
                                                                </ToggleButton>
                                                                <section className={!show ? 'd-none' : ''}>
                                                                    {isSelected(dayDate(day)) ? (
                                                                        <div className='list-hours-new'>
                                                                            {show &&
                                                                                blocksDate(day, name.toLowerCase())}
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </section>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </section>
                                </div>
                                <section>
                                    <div>
                                        <button
                                            className='button-primary mt-xl-1 w-50'
                                            onClick={() => submitBlocksTaken(idVisit, history)}
                                            disabled={hourTaken.length < 1}
                                        >
                                            {intl.get('UPDATE_BLOCKS')}
                                        </button>
                                    </div>
                                </section>
                            </>
                        )}
                    {!blocksValdation && fetchingBlocks === 'FETCHED_BLOCKS' && (
                        <p>{intl.get('SCHEDULE_NOT_AVAILABLE')}</p>
                    )}
                    {fetchingBlocks !== 'FETCHED_BLOCKS' && <CircularLoading />}
                </div>

                {blocksValdation && (
                    <div className='left-calendar-arrow'>
                        <a onClick={() => handleNextWeek()}>
                            <img src={forwardArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}
            </div>
        </>
    ) : (
        <RenterCalendarMobile
            submitBlocksTaken={submitBlocksTaken}
            idVisit={idVisit}
            history={history}
            hourTaken={hourTaken}
            fetchingBlocks={fetchingBlocks}
            blocksAvailable={stateBlocks}
            isSelected={isSelected}
            dayDate={dayDate}
            ToggleButton={ToggleButton}
            onChangeDate={onChangeDate}
            backArrow={backArrow}
            blocksDate={blocksDate}
            blockType={blockType}
        />
    );
};

const mapStateToProps = (state: any) => ({ ...state.changeVisit });

const mapDispatchToProps = (dispatch: any, getState: any) => {
    let url = '';
    return {
        onNextWeek: () => {
            return dispatch(nextWeek());
        },
        onLastWeek: () => {
            return dispatch(lastWeek());
        },
        onChangeDate: (date: any) => {
            return dispatch(changeDay(date));
        },
        onChangeHour: (hour: any, stateBlocks?: any) => {
            return dispatch(changeHour(hour, null, undefined, 0, stateBlocks));
        },
        submitBlocksTaken: (idVisit: any, history?: any) => {
            return dispatch(changeVisit(idVisit, history));
        },
        onGetBlocks: (id?: any) => {
            return dispatch(getBlocks(url, id));
        },
        onGetBlocksGroup: (id?: string, reschedule?: boolean) => {
            const toReschedule = reschedule ? reschedule : false;
            const url: string = toReschedule ? '?group=yes&unit_version_id=' : '?unit_version_id=';
            return dispatch(getBlockGroup(url, id, toReschedule));
        },
        onResetBlocks: () => {
            return dispatch(getBlocksReset());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
