export const getPhotosStart = () => {
    return {
        type: 'GET_PHOTOS_STARTED',
    };
};
export const getPhotosSuccess = (photos: any) => {
    return {
        type: 'GET_PHOTOS_SUCCESS',
        payload: photos,
    };
};
export const getPhotosError = (error: any) => {
    return {
        type: 'GET_PHOTOS_ERROR',
        payload: {
            error,
        },
    };
};
