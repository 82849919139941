import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import accessValueByString from '../../helpers/accessValueByString';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../helpers/hookWindowSize';
import { isRoleAdmin } from '../../helpers/roleComparison';

interface Option {
    value: number | string;
    label: string;
}

interface RadioButtonProps {
    options: Array<Option>;
    onChange: any;
    id: string;
    label?: string;
    valueChecked: any;
    className?: string;
    maxWidth?: boolean;
    disabled?: boolean;
    range?: boolean;
    info?: string;
    classNameLabel?: string;
    classNameLabelInput?: string;
    classNameInput?: string;
    classNameInputContainer?: string;
    error?: string;
    required?: boolean;
    valueSelected?: any;
    setValueSelected?: any;
    keyNumber?: number;
    bedroomCount?: number;
    message?: string;
    classInfo?: string;
    classNameBank?: string;
    classNameMobile?: string;
    toVerify?: boolean;
    isVerifier?: boolean;
    isRequiredVerifier?: boolean;
}

interface FieldProps {
    form: any;
    field: any;
}

const RadioButtonOptions = ({
    options,
    label,
    valueChecked,
    form,
    field,
    className,
    disabled,
    maxWidth,
    range,
    info,
    classNameLabel = 'col-lg-4 pl-0 col-12',
    classNameLabelInput,
    classNameInput,
    classNameInputContainer = 'col-lg-8 col-12 plm-0',
    error,
    bedroomCount,
    required = false,
    valueSelected,
    setValueSelected,
    keyNumber = 0,
    message,
    classInfo,
    classNameMobile = 'cold-sm-6 plm-0',
    classNameBank,
    toVerify,
    isVerifier = false,
    isRequiredVerifier = false,
    ...inputProps
}: RadioButtonProps & FieldProps) => {
    useEffect(() => {
        const actualValue = accessValueByString(field.value, inputProps.id);
        if (!actualValue && actualValue !== 0) {
            form.setFieldValue(inputProps.id);
        }
    }, []);

    const changeValue = (e: any, name: string, value: any) => {
        form.setFieldValue(name, valueSelected !== value ? value : null);
        setValueSelected(valueSelected !== value ? value : null);
        valueSelected === value && e.preventDefault(false);
    };
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const isNewUnitCreationView =
        currentLocation === 'new-unit-creation' || currentLocation === 'new-unit-creation-instapage';
    const size = useWindowSize();

    const isUpperCase = (word: string) => {
        if (word === '0') return false;
        return word.toUpperCase() === word && !Number(word);
    };

    return (
        <div className={`radio-button-tango-container d-flex align-items-start row ${className}`}>
            {label !== undefined && (
                <div className={classNameLabel}>
                    <span className={`col-12 span-text color-secondary ${classNameLabelInput}`}>
                        {`${intl.get(`${label}`)}${isVerifier || isRoleAdmin ? '*' : ''}`}
                    </span>
                    <p className={`${classInfo} p-info-label`}>{intl.getHTML(`${info}`)}</p>
                </div>
            )}
            <div
                className={`row ${maxWidth ? 'col-12 ' : classNameInputContainer} radio-toolbar ${
                    isNewUnitCreationView && size.width < 1430 && 'd-flex justify-content-center'
                }`}
            >
                {options.map((option, index) => {
                    const classByBoolean = Number(option.label) || parseInt(option.label) === 0;
                    const new_value = range ? `${option.value}, ${option.value}` : option.value;
                    return (
                        <div
                            key={`opt-${index}-${label}`}
                            className={`${
                                maxWidth && !classByBoolean
                                    ? 'col-12 p-0 '
                                    : currentLocation === 'advanced-search'
                                    ? 'col-3 px-2 py-1'
                                    : classNameMobile
                            }`}
                        >
                            <input
                                type='radio'
                                id={`radio${classByBoolean ? '-number' : ''}-${option.label}-${inputProps.id}${
                                    keyNumber ? `-${keyNumber}` : ''
                                }`}
                                className={`${classByBoolean ? 'radio-number' : 'radio-string'} ${
                                    range && `${option.value}, ${option.value}` === valueSelected
                                        ? 'checked'
                                        : valueSelected === option.value && 'checked'
                                }`}
                                name={`${inputProps.id}`}
                                value={new_value}
                                onClick={(e) => changeValue(e, inputProps.id, new_value)}
                                defaultChecked={
                                    (range && `${option.value}, ${option.value}` === valueSelected) ||
                                    valueSelected === option.value
                                }
                                disabled={disabled}
                                required={required}
                            />
                            <label
                                className={`${
                                    classByBoolean
                                        ? 'button-secondary-disabled-number'
                                        : `${classNameBank} button-secondary-disabled-string`
                                }`}
                                style={{ width: maxWidth && !classByBoolean ? '100%' : '' }}
                                htmlFor={`radio${classByBoolean ? '-number' : ''}-${option.label}-${inputProps.id}${
                                    keyNumber > 0 ? `-${keyNumber}` : ''
                                }`}
                            >
                                {isUpperCase(option.label) ? intl.get(`${option.label}`) : option.label}
                            </label>
                        </div>
                    );
                })}
                {(error && required) ||
                    (error && (
                        <div className='text col-12 pl-0'>
                            <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                        </div>
                    ))}

                {(isVerifier || isRoleAdmin) && isRequiredVerifier && !error && (
                    <div className='text col-12 pl-0'>
                        <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                    </div>
                )}
                {message && (
                    <div className='text col-12 pl-0'>
                        <p className='text-color-error small m-0'>{message}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
const RadioButtonTango = ({ ...props }: RadioButtonProps) => {
    const [valueSelected, setValueSelected] = useState<string | undefined | number>(props.valueChecked);
    return (
        <Field>
            {({ field, form }: any) => {
                return (
                    <RadioButtonOptions
                        {...props}
                        form={form}
                        field={field}
                        valueSelected={valueSelected}
                        setValueSelected={setValueSelected}
                    />
                );
            }}
        </Field>
    );
};
export default RadioButtonTango;
