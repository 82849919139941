export const getApplicationRole = (appRole: any) => {
    const conversion: any = {
        operations_admin: 'admin',
        agent: 'agent',
        consignee: 'cosignee',
        owner: 'owner',
        renter: 'renter',
        master_admin: 'master',
        verifier: 'validator',
    };
    return conversion[appRole];
};
