import intl from 'react-intl-universal';
export const thousandSeparator = (number: Number) => {
    let formatNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return formatNumber;
};

/** @function formatCurrencyNumber Currency formatter
 * This is a function.
 * @param {string} currency - Currency to format
 * @return {string} returns the formatted currency
 *
 * @example
 *   formatCurrencyNumber(50000)
 *    return '$50.000'
 */
export const formatCurrencyNumber = (currency: any) => {
    const options = {
        style: 'currency',
        currency: 'CLP',
        symbol: 'narrowSymbol',
    };

    const intlNumberFormat = new Intl.NumberFormat('es-CL', options);
    const newFormat = intlNumberFormat.format(currency);
    return newFormat;
};
export const formatNumber = (currency: any) => {
    const { currentLocale } = intl.getInitOptions();
    const intlNumberFormat = new Intl.NumberFormat(currentLocale === 'en-US' ? 'en-US' : 'de-DE');
    const newFormat = intlNumberFormat.format(currency);
    return newFormat;
};
