import { UserState } from '../../../tango'

const initialState: UserState = {
  users: [],
  userDetail: {},
  fetchUser: 'NO_FETCH',
  fetchUserToken: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CREATE_USER_STARTED':
      return {
        ...state,
        fetchUser: 'FETCHING',
      }
    case 'CREATE_USER_SUCCESS':
      return {
        ...state,
        userDetail: action.payload.data.data,
        fetchUser: 'FETCHED',
      }
    case 'CREATE_USER_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchUser: 'FECHING_ERROR',
      }
    case 'SET_SEND_USER_TOKEN_STARTED':
      return {
        ...state,
        fetchUserToken: 'FETCHING',
      }
    case 'SET_SEND_USER_TOKEN_SUCCESS':
      return {
        ...state,
        fetchUserToken: 'FETCHED',
      }
    case 'SET_SEND_USER_TOKEN_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchUserToken: 'FECHING_ERROR',
      }
    default:
      return state
  }
}
