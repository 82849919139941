import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { sendTokenNewPassword, setTemplateChild } from '../../../reduxActions'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import intl from 'react-intl-universal'
import { getParameterByName } from '../../../helpers/getParamsUrl'
import { useDispatch } from 'react-redux'
import setTokenNewPassword from '../../../reduxActions/session/setTokenNewPassword'
import { tangoBlackRedLogo } from '../../../../tango-react-base/resources/index'
interface ForgetPasswordTokenProps {
  fetchForgetStatus: string
  onForgetLoginSession: any
  onSetTemplateChild: (child: Object) => void
}

const ForgetPasswordToken = ({
  fetchForgetStatus,
  onForgetLoginSession,
  onSetTemplateChild,
}: ForgetPasswordTokenProps) => {
  const params: { email: string } = useParams()
  const history = useHistory()
  const location: any = useLocation()
  const tokenParam = getParameterByName('token', history.location.search)
  const dispatch = useDispatch()

  useEffect(() => {
    const values = {
      email: params.email,
      token: tokenParam,
    }
    tokenParam && onForgetLoginSession(values, history)
  }, [tokenParam])

  useEffect(() => {
    let title =
      location.state === undefined
        ? 'Forget password'
        : location.state.type === 'verify'
        ? 'Validation code'
        : ''
    onSetTemplateChild(
      <>
        <h2>{title}</h2>
      </>,
    )
  }, [location.state, onSetTemplateChild])

  const handleNewToken = () => {
    dispatch(setTokenNewPassword(params))
  }
  const redirectToHome = () => {
    const roleWithRedirectionToMain = [
      process.env.REACT_APP_OWNEREQUAL,
      process.env.REACT_APP_RENTEREQUAL,
    ]
    if (
      roleWithRedirectionToMain.includes(process.env.REACT_APP_API_ROLE_APP)
    ) {
      window.location.href = `${process.env.REACT_APP_HOME_URL}`
    } else {
      history.push('/')
    }
  }

  return (
    <div className='card-view card-without-shadow col-md-6 h-50'>
      <div className='row'>
        <h2 className='title text-left'>
          {intl.get('FORGOT_PASSWORD_INSTRUCTIONS_TOKEN')}
        </h2>
      </div>
      <div className='template-line'></div>
      <div className='row text-left mt-2'>
        <span className='title-info mb-5'>
          {intl.get('FORGOT_PASSWORD_INSTRUCTIONS_TOKEN_INFO')}
        </span>
        <span className='title-info'>{intl.get('TRY_CODE_TOKEN_1')}</span>
        <span className='font-weight-bold px-2' onClick={handleNewToken}>
          {intl.getHTML('HERE_LINK')}
        </span>
        <span className='title-info mb-3'>
          {intl.getHTML('TRY_CODE_TOKEN_2')}
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetchForgetStatus: state.session.fetchForgetStatus,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onForgetLoginSession: (values: any, history: any) => {
      return dispatch(sendTokenNewPassword(values, history))
    },
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordToken)
