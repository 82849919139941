import { getNewReferenceStart, getNewReferenceSuccess, getNewReferenceError } from './types';
import { getData } from '../../helpers/apiConnections';

const getNewReference = (referredCode: string) => {
    localStorage.setItem('referredCode', referredCode);
    const data = { ref_code: referredCode };
    return (dispatch: any, getState: any) => {
        dispatch(getNewReferenceStart());
        getData(`user/new_reference`, data)
            .then((result) => {
                dispatch(getNewReferenceSuccess(result.data));
            })
            .catch((e) => {
                return dispatch(getNewReferenceError(e));
            });
    };
};
export default getNewReference;
