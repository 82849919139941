import React, { useRef } from 'react'
import intl from 'react-intl-universal'
import { checkLogo, uploadLogo } from '../../../resources/'
import { useDispatch } from 'react-redux'
import uploadPdfTruoraDicom from '../../../reduxActions/uploadPdfTruoraDicom'
import { CircularLoading } from '../../../components'
import { toast } from 'react-toastify'
import updatePdfTruoraDicom from '../../../reduxActions/updatePdfTruoraDicom'

interface FilesProps {
  renterId: number
  renterState?: Object
  filterTruora?: Array<Object> | any
  filterDicom?: Array<Object> | any
}

/**
 * Here it is defined the type of the property, this prop is similar to 'FilesProps' but 'FilesPropsTypes' is for the documentation
 * @typedef FilesPropsTypes
 * @type {(function|string|object)}
 * @property {number} renterId - is a Number.
 * @property {object} renterState - is a Object.
 * @property {array} filterTruora - is a Array.
 * @property {array} filterDicom - is a Array.
 */

/**
 * UploadFileTruoraDicom is a functional component
 *@function
 *@param {ContractRentedTypes}  renterId - Id of the renter profile.
 *@param {ContractRentedTypes}  renterState - State of the renter
 *@param {ContractRentedTypes}  filterTruora - return a filter with only truora
 *@param {ContractRentedTypes}  filterDicom -return a filter with only dicom
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

const UploadFileTruoraDicom = ({
  renterId,
  renterState,
  filterTruora,
  filterDicom,
}: FilesProps) => {
  const dispatch = useDispatch()
  const [dicomUpload, setDicomUpload] = React.useState(false)
  const [truoraUpload, setTruoraUpload] = React.useState(false)
  const InputRefDicom = useRef<HTMLInputElement>(null)
  const InputRefTruora = useRef<HTMLInputElement>(null)
  const idDocumentDicom = filterDicom[0] && filterDicom[0].id
  const idDocumentTruora = filterTruora[0] && filterTruora[0].id

  /**
   * @typedef  uploadPdfType
   * @type {(file|string|number)}
   * @property {string} documentType - is a string.
   * @property {file} file - is a File.
   * @property {string} typeAction - is a string.
   * @property {number} id - is a number.
   * */

  /**
   *@function actionsFileDicomTruora
   *@param {uploadPdfType}  file - is a simple PDF
   *@param {uploadPdfType}  typeAction - action type (upload|update).
   *@param {uploadPdfType}  id - id of the document .
   *@param {uploadPdfType}  documentType - type of document(truora|dicom)  .
   */
  const actionsFileDicomTruora = (
    documentType: string,
    file: File,
    typeAction: string,
    id?: any,
  ) => {
    if (file && file.type === 'application/pdf') {
      if (typeAction === 'uploadNew') {
        documentType === "dicom" && setDicomUpload(true)
        documentType === "truora" && setTruoraUpload(true)
        // here is the dispatch to upload a new document
        dispatch(uploadPdfTruoraDicom(renterId, documentType, file.name, file))
      } else {
        dispatch(updatePdfTruoraDicom(file, id))
      }
    } else {
      toast(intl.get('ONLY_PDF'))
    }
  }

  /**
   * this  function executes the modal to upload documents (Truora)
   *@function openFileDialogTruora
   * @returns {(event)} Return a click event
   */
  const openFileDialogTruora = () => {
    if (InputRefTruora.current) InputRefTruora.current.click()
  }
  /**
   * this function executes the modal to upload documents (Dicom)
   *@function openFileDialogDicom
   * @returns {(event)} Return a click event
   */
  const openFileDialogDicom = () => {
    if (InputRefDicom.current) InputRefDicom.current.click()
  }

  const RenderUploadInput = () => {
    return renterId ? (
      <>
        <div className='row mb-4'>
          <p className='span-text pl-4 plm-0 col-8 mr-2 text-break'>
            {intl.get('DICOM')}
            {(filterDicom.length > 0 || dicomUpload) &&
              renterState !== 'verified' && (
                <img
                  src={checkLogo}
                  alt='checkLogo'
                  className='check-logo ml-3'
                />
              )}
          </p>
          {renterState !== 'verified' ? (
            <div className='dropzone-cnt'>
              <img
                src={uploadLogo}
                alt='uploadLogo'
                className='upload-logo'
                onClick={openFileDialogDicom}
              />
              <input
                name='dicom'
                id='pdf_dicom'
                ref={InputRefDicom}
                className='dropzone-file-input'
                type='file'
                accept='application/pdf'
                onChange={(e: any) =>
                  actionsFileDicomTruora(
                    'dicom',
                    e.target.files[0],
                    filterDicom.length === 0 && renterState !== 'verified'
                      ? 'uploadNew'
                      : 'update',
                    idDocumentDicom,
                  )
                }
              />
            </div>
          ) : (
            <img src={checkLogo} alt='checkLogo' className='check-logo ml-3' />
          )}
        </div>
        <div className='row mb-4'>
          <p className='span-text pl-4 plm-0 col-8 mr-2 text-break'>
            {intl.get('TRUORA')}
            {(filterTruora.length > 0 || truoraUpload) &&
              renterState !== 'verified' && (
                <img
                  src={checkLogo}
                  alt='checkLogo'
                  className='check-logo ml-3'
                />
              )}
          </p>
          {renterState !== 'verified' ? (
            <div className='dropzone-cnt'>
              <img
                src={uploadLogo}
                alt='uploadLogo'
                className='upload-logo'
                onClick={openFileDialogTruora}
              />

              <input
                name='truora'
                id='pdf_truora'
                ref={InputRefTruora}
                className='dropzone-file-input'
                type='file'
                accept='application/pdf'
                onChange={(e: any) =>
                  actionsFileDicomTruora(
                    'truora',
                    e.target.files[0],
                    filterTruora.length === 0 && renterState !== 'verified'
                      ? 'uploadNew'
                      : 'update',
                    idDocumentTruora,
                  )
                }
              />
            </div>
          ) : (
            <img src={checkLogo} alt='checkLogo' className='check-logo ml-3' />
          )}
        </div>
      </>
    ) : (
      <CircularLoading />
    )
  }

  return (
    <>
      <div className='template-line' />
      <h2 className='new-subtitle'>{intl.get('UPLOAD_FILES_TITLE')}</h2>
      <div className='info-upload-files'>
        <p className='pl-4 plm-0 text-left mb-4 text-muted'>
          {intl.get('ADMIN_UPLOAD_TRUORA_DICOM')}
        </p>
        <RenderUploadInput />
      </div>
    </>
  )
}

export default UploadFileTruoraDicom
