import React, { useEffect } from 'react'
import { CircularLoading, PutTokenForm } from '../..'
import { connect } from 'react-redux'
import { sendTokenCreateUser, setTemplateChild } from '../../../reduxActions'
import { useParams, useHistory } from 'react-router-dom'
import CardMountContainer from '../../cardMountContainer'
import intl from 'react-intl-universal'

interface CreateUserTokenProps {
  fetchUserToken: string
  onSendTokenUser: any
  onSetTemplateChild: (child: Object) => void
}

const CreateUserToken = ({
  fetchUserToken,
  onSendTokenUser,
  onSetTemplateChild,
}: CreateUserTokenProps) => {
  useEffect(() => {
    onSetTemplateChild(
      <>
        <h2>{intl.get('VALIDATION_CODE')}</h2>
      </>,
    )
  }, [onSetTemplateChild])
  const params = useParams()
  const history = useHistory()
  const [lengthToken, setLengthToken] = React.useState<Number>(0)
  return (
    <CardMountContainer
      infoTitle={'CODE_VALIDATE'}
      textBody='INSERT_CODE'
      heightCard={'h-75'}
    >
      <PutTokenForm
        disabled={false}
        onSubmit={onSendTokenUser(history)}
        params={params}
        setLengthToken={setLengthToken}
      >
        {fetchUserToken !== 'FETCHING_TOKEN' ? (
          <>
            {fetchUserToken === 'ERROR_FETCHING_TOKEN' && (
              <p className='text-warning'>{intl.get('TOKEN_ERROR')}</p>
            )}
            <div className='separator-token' />
            <button
              className='button-primary col-12 mt-5 mx-sm-0'
              type='submit'
              disabled={!lengthToken}
            >
              {intl.get('CONTINUE')}
            </button>
          </>
        ) : (
          <CircularLoading />
        )}
      </PutTokenForm>
    </CardMountContainer>
  )
}
const mapStateToProps = (state: any) => {
  return {
    fetchUserToken: state.user.fetchUserToken,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSendTokenUser: (history: any) => (values: any) => {
      return dispatch(sendTokenCreateUser(values, history))
    },
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserToken)
