import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#a8123e',
        },
        secondary: {
            main: '#fff',
        },
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    props: {
        MuiSelect: {
            disableUnderline: true,
        },
    },
});
