import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  getPhotos,
  getPost,
  updatePost,
  setTemplateChild,
} from '../../tango-react-base/reduxActions'
import {
  CircularLoading,
  MultipleDropzone,
  PostForm,
} from '../../tango-react-base/components'
import MultipleDrozoneVideo from '../../components/multipleDropzoneVideo'
import { verifyPost, prioritizePhotos } from '../../redux/actions/index'
import { VerifierReduxStates } from '../../tango'
import intl from 'react-intl-universal'
import { toast } from 'react-toastify'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'

type PostValidationProps = {
  onGetPost: any
  post: any
  savingPost: string
  fetchingPost: string
  countVideoUpload: number
  onValidatePost: (data: any, history: any) => void
  onUpdatePost: (newData: any) => void
  onSetTemplateChild: (child: Object) => void
  onPrioritizePhotos: (data: any, id: number) => void
  onGetPhotos: (id: number) => void
  photos: Array<any>
  fetchingPhotos: string
  videosUploading: Array<any>
  error: any
  incompleteForm?: any
  photosPost?: any
  videosPosts?: any
}

const PostValidation = ({
  onGetPost,
  post,
  fetchingPost,
  onValidatePost,
  onUpdatePost,
  savingPost,
  onSetTemplateChild,
  onPrioritizePhotos,
  onGetPhotos,
  photos,
  fetchingPhotos,
  videosUploading,
  photosPost,
  videosPosts,
}: PostValidationProps) => {
  const { id }: any = useParams()
  const history = useHistory()
  const [videoCounter, setVideoCounter] = useState(0)
  const [sortedPhotos, setSortedPhotos] = useState<any[]>([])
  const allPhotosSelected = sortedPhotos.length === photos?.length
  const isPostCreated = post?.attributes?.aasm_state === 'created'
  const [toVerify, setToVerify] = useState(true)
  const [fullFieldsBasic, setFullFieldsBasic] = useState(false)
  const [fullFieldsEquipment, setFullFieldsEquipment] = useState(false)
  const [fullFieldsUnitDetail, setFullFieldsUnitDetail] = useState(false)
  const [fullFieldsCommonSpaces, setFullFieldsCommonSpaces] = useState(false)
  const [fullFieldsTerms, setFullFieldsTerms] = useState(false)
  const user = JSON.parse(localStorage.getItem('session') || '{ "attributes": "{}" }').attributes
  const size = useWindowSize()

  const fullFields =
    fullFieldsBasic &&
    fullFieldsEquipment &&
    fullFieldsUnitDetail &&
    fullFieldsCommonSpaces &&
    fullFieldsTerms

  // this is to know where is the scroll to hide the save button floating
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  let containerHeight = document?.getElementById('container')?.offsetHeight
  let childrenHeight = document?.getElementById('children-div')?.offsetHeight
  let TotalHeight =
    (containerHeight && childrenHeight) ? (containerHeight - childrenHeight) : 0

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('UNIT_DETAIL')}</h1>
          <p className='col-sm-12 col-md-6 px-0'>
            {intl.get('UNIT_DETAIL_VISIT_INFO')}
          </p>
        </>,
      )
      onGetPost(id)
      onGetPhotos(id)
    }
  }, [onSetTemplateChild, onGetPost, id, onGetPhotos, history])

  useEffect(() => {
    if (fetchingPhotos === 'LOADED') {
      const sortedPhotosByName = photos.sort((a: any, b: any) => {
        const firstPhoto = a?.attributes?.title?.split('-', 1).join()
        const secondPhoto = b?.attributes?.title?.split('-', 1).join()
        const firstMinorThanSecondName = firstPhoto < secondPhoto ? -1 : 1
        return firstPhoto === secondPhoto
          ? 0
          : firstMinorThanSecondName
      })
      const sortedPhotosByPriority = photos.sort((a: any, b: any) => {
        const firstPhoto = a?.attributes?.priority
        const secondPhoto = b?.attributes?.priority
        const firstMinorThanSecondPriority = firstPhoto < secondPhoto ? -1 : 1
        return firstPhoto === secondPhoto
          ? 0
          : firstMinorThanSecondPriority
      })
      const allSortedPhotos = isPostCreated
        ? sortedPhotosByName
        : sortedPhotosByPriority
      setSortedPhotos(allSortedPhotos.map((photo: any) => photo.id))
    }
  }, [fetchingPhotos, fetchingPost, photos])

  useEffect(() => {
    if (fetchingPost === 'LOADED') {
      setVideoCounter(post?.attributes?.videos.length)
    }
  }, [fetchingPost, post?.attributes?.videos.length])

  const handleUpdate = (values: any) => {
    onUpdatePost(values)
    setToVerify(true)
  }

  const handleValidatePost = (data: any) => {
    if (videoCounter < 1) toast(intl.get('UPLOAD_ONE_VIDEO'))
    if (photos.length < 1) toast(intl.get('UPLOAD_ONE_PHOTO'))
    else {
      onValidatePost(data, history)
      onPrioritizePhotos(sortedPhotos, id)
    }
  }
  const handlePhotoSorting = (photoId: any) => {
    let tempSortedPhotos = sortedPhotos
    if (tempSortedPhotos.some((tempPhotoId) => tempPhotoId === photoId)) {
      tempSortedPhotos = tempSortedPhotos.filter(
        (tempPhotoId) => tempPhotoId !== photoId,
      )
      setSortedPhotos(tempSortedPhotos)
    } else {
      setSortedPhotos([...tempSortedPhotos, photoId])
    }
  }

  const validationBtnVerify =
    !fullFields || !photosPost.length || !videosPosts.length

  return (
    <div id='container' className='container-home'>
      {fetchingPost === 'LOADED' ? (
        <PostForm
          post={post}
          onSubmit={toVerify ? handleValidatePost : handleUpdate}
          savingPost={savingPost}
          sortedPhotos={sortedPhotos}
          toVerify={toVerify}
          setFullFieldsBasic={setFullFieldsBasic}
          setFullFieldsEquipment={setFullFieldsEquipment}
          setFullFieldsUnitDetail={setFullFieldsUnitDetail}
          setFullFieldsCommonSpaces={setFullFieldsCommonSpaces}
          setFullFieldsTerms={setFullFieldsTerms}
        >
          <div id='children-div'>
            <MultipleDropzone
              handlePhotoSorting={handlePhotoSorting}
              sortedPhotos={sortedPhotos}
              setSortedPhotos={setSortedPhotos}
            />
            {!allPhotosSelected && (
              <p className='text-color-error text-center'>
                {intl.get('YOU_MUST_SELECT_ALL_PHOTOS')}
              </p>
            )}
            <MultipleDrozoneVideo
              setVideoCounter={setVideoCounter}
              videoCounter={videoCounter}
            />

            <div>
              {post.attributes.aasm_state === 'verified' && (
                <p style={{ fontWeight: 600, color: '#077324' }}>
                  {intl.get('UNIT_ALREADY_VERIFIED')}
                </p>
              )}
              <div className='row justify-content-between'>
                <div className='col-12 text-center'>
                  {savingPost === 'SAVING' && (
                    <span style={{ fontWeight: 600 }}>
                      {intl.get('SAVING')}
                    </span>
                  )}
                  {savingPost === 'SAVED' && (
                    <span style={{ fontWeight: 600, color: 'green' }}>
                      {intl.get('SAVED')}
                    </span>
                  )}
                </div>
                <div className='col-12 text-center'>
                  {(post.attributes.aasm_state === 'created' ||
                    user.email.includes('@avispa.tech') ) && (
                    <>
                      <div className='d-flex justify-content-between flex-wrap ml-2 mr-2 mb-5'>
                        {TotalHeight < scrollPosition ? (
                          <button
                            disabled={false}
                            onClick={() => setToVerify(false)}
                            className='button-primary col-md-5 mt-4'
                          >
                            {`${intl.get('SAVE')}`}
                          </button>
                        ) : (
                          <div className='btn-floating-container'>
                            {size.width > 577 ? (
                              <button
                                disabled={false}
                                onClick={() => setToVerify(false)}
                                className='button-primary px-4'
                              >
                                {intl.get('SAVE')}
                              </button>
                            ) : (
                              <button
                                disabled={false}
                                onClick={() => setToVerify(false)}
                                className='btn-img px-4'
                              ></button>
                            )}
                          </div>
                        )}
                        <button
                          onClick={() => setToVerify(true)}
                          className='button-primary col-md-5 mt-4'
                          disabled={validationBtnVerify}
                        >
                          {`${intl.get('VERIFY')}`}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PostForm>
      ) : (
        <div>
          {fetchingPost === 'LOADING' && <div>{<CircularLoading />}</div>}
          {fetchingPost === 'ERROR' && <div>{intl.get('ERROR')}</div>}
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPost: (postId: number) => {
      dispatch(getPost(postId))
    },
    onValidatePost: (data: any, history: any) => {
      dispatch(verifyPost(data, history))
    },
    onUpdatePost: (newData: any) => {
      return dispatch(updatePost(newData))
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onPrioritizePhotos: (data: any, postId: number) => {
      dispatch(prioritizePhotos(data, postId))
    },
    onGetPhotos: (postId: number) => {
      dispatch(getPhotos(postId))
    },
  }
}

const mapStateToProps = (state: VerifierReduxStates) => {
  const { post, fetch, savingPost, error } = state.postValidation
  return {
    post: post,
    fetchingPost: fetch,
    savingPost,
    error,
    photos: state.photos.photos,
    fetchingPhotos: state.photos.fetch,
    videosUploading: state.postValidation.videosUploading,
    videosPosts: state.postValidation.videos,
    photosPost: state.photos.photos,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostValidation)
