import { isRoleRenter } from '../../../helpers/roleComparison/index'
export const SubmitCalendarStart = (date: any) => {
  return {
    type: 'SUBMIT_CALENDAR_STARTED',
    payload: { date },
  }
}

export const SubmitCalendarSuc = (result: any) => {
  if (isRoleRenter) {
    return {
      type: 'SUBMIT_CALENDAR_SUCCESS_RENTER',
      payload: { result },
    }
  } else {
    return {
      type: 'SUBMIT_CALENDAR_SUCCESS',
      payload: { result },
    }
  }
}

export const SubmitCalendarError = (error: any) => {
  return {
    type: 'SUBMIT_CALENDAR_ERROR',
    payload: {
      error,
    },
  }
}
