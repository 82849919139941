import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cancelVisit, getBlocks } from '../../reduxActions';
import { visitFinishedAction } from '../../../tango-react-base/reduxActions';
import { Popover } from '@material-ui/core';
import AddHoursForm from './AddHoursForm';
import intl from 'react-intl-universal';
import { isRoleTanguero, isRoleAdmin } from '../../helpers/roleComparison/index';
import { ButtonTooltip } from '../index';

/**
 * Here it is defined the interface of the VisitsTableProps Component
 * @typedef VisitsTableProps
 * @type {(object|function|string)}
 * @param {function} visits - is a object.
 * @param {function} onGetBlocks - is a function.
 * @param {function} onCancelVisit - is a function.
 * @param {function} onVisitFinished - is a function.
 * @param {string} visitFinishedFetch - is a string.
 * @param {function} render - is a function.
 * @param {string} fetch - is a string.
 */

interface VisitsTableProps {
    visits: Array<any>;
    onGetBlocks: any;
    onCancelVisit: any;
    onVisitFinished: any;
    visitFinishedFetch: string;
    render?: any;
    fetch?: String;
}

/**
 * VisitsTable is a functional component
 * @function
 * @param {VisitsTableProps} visits - is a object with pending, finished or canceled visits.
 * @param {VisitsTableProps} onGetBlocks - this function
 * @param {VisitsTableProps} onCancelVisit - this function cancels the scheduled visit between the photographer and the owner or the tanguero and the renter.
 * @param {VisitsTableProps} onVisitFinished - this function ends the scheduled visit between the photographer and the owner or the tanguero and the renter.
 * @param {VisitsTableProps} visitFinishedFetch - fetching status of whether the visit ended.
 * @param {VisitsTableProps} render - this function makes a call to history for move the pointer in the history stack by 0 entries.
 * @returns {(ReactComponent)} Return a react component with a functional component.
 */

const VisitsTable = ({
    visits,
    render,
    onGetBlocks,
    onCancelVisit,
    onVisitFinished,
    visitFinishedFetch,
    fetch,
}: VisitsTableProps) => {
    const history = useHistory();
    const moment = require('moment');
    const [anchorEl, setAnchorEl] = useState(null);
    const [offerId, setOfferId] = useState('');
    const [postId, setPostId] = useState('');

    const handleClick = (event: any, offer_id: string, post_id: string) => {
        setOfferId(offer_id);
        setPostId(post_id);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseArray = [process.env.REACT_APP_VERIFIER, process.env.REACT_APP_BROKER];
    const handleClose = () => {
        if (!handleCloseArray.includes(process.env.REACT_APP_API_ROLE_APP)) {
            render(true);
        } else {
            setAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;

    const extraButton = (state: string, firstChild: any, secondChild: any, thirdChild: any) => {
        let role = process.env.REACT_APP_API_ROLE_APP;
        return (
            <>
                {state === 'pending' && (
                    <TableRow>
                        <TableCell className='table-content'></TableCell>
                        <TableCell className='table-content'></TableCell>
                        <TableCell className='table-content'></TableCell>
                        {role === 'BROKER' && (
                            <>
                                <TableCell className='table-content'></TableCell>
                                <TableCell className='table-content'></TableCell>
                            </>
                        )}
                        <TableCell className='table-content'>{firstChild}</TableCell>
                        <TableCell className='table-content'>{secondChild}</TableCell>
                        <TableCell className='table-content'>{thirdChild}</TableCell>
                    </TableRow>
                )}
            </>
        );
    };

    const renderTableHeader = () => {
        return (
            <>
                <TableContainer className='table'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header table-border-left'>{intl.get('OWNER')}</TableCell>
                                <TableCell className='table-header'>{intl.get('ADDRESS')}</TableCell>
                                <TableCell className='table-header'>{intl.get('PHONE')}</TableCell>
                                {isRoleTanguero && (
                                    <>
                                        <TableCell className='table-header'>{intl.get('REQUESTER')}</TableCell>
                                        <TableCell className='table-header'>{intl.get('PHONE')}</TableCell>
                                    </>
                                )}
                                <TableCell className='table-header'>{intl.get('DATE')}</TableCell>
                                <TableCell className='table-header'>{intl.get('START')}</TableCell>
                                <TableCell className='table-header table-border-right'>{intl.get('END')}</TableCell>
                            </TableRow>
                        </TableHead>
                        {renderTableBody()}
                    </Table>
                </TableContainer>
            </>
        );
    };

    const renderTableBody = () => {
        return (
            <>
                <TableBody>
                    {fetch !== 'LOADED' ? (
                        <TableCell colSpan={6} className='text-center border-0'>
                            <p>{intl.get('LOADING')}</p>
                        </TableCell>
                    ) : visits.length > 0 ? (
                        visits.map((visit: any) => {
                            const {
                                start_time,
                                end_time,
                                owner,
                                renter,
                                street_address,
                                aasm_state,
                                unit_id,
                                post_id,
                                post_aasm_state,
                            } = visit.attributes;

                            const startDate = moment(start_time, 'YYYY-MM-DD HH:mm');
                            const endDate = moment(end_time, 'YYYY-MM-DD HH:mm');

                            const ownerName = `${owner.names.split(' ')[0]} ${owner.surnames}`;

                            const verifiedStateCases = ['verified', 'published'];
                            const isVerified = verifiedStateCases.includes(post_aasm_state);
                            const isCreated = post_aasm_state === 'created';
                            let renterName;
                            if (isRoleTanguero) {
                                renterName = renter.surnames
                                    ? `${renter.names.split(' ')[0]} ${renter.surnames}`
                                    : renter.names;
                            }
                            return (
                                <>
                                    <TableRow key={visit.id}>
                                        <TableCell className='table-content'>{ownerName}</TableCell>
                                        <TableCell className='table-content'>{street_address}</TableCell>
                                        <TableCell className='table-content'>
                                            <a href={`tel:${owner.phone_number}`}>{owner.phone_number}</a>
                                        </TableCell>
                                        {isRoleTanguero && (
                                            <>
                                                <TableCell className='table-content'>{renterName}</TableCell>
                                                <TableCell className='table-content'>
                                                    <a href={`tel:${renter.phone_number}`}>{renter.phone_number}</a>
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell className='table-content'>
                                            {moment(startDate).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell className='table-content'>
                                            {moment(startDate).format('HH:mm')}
                                        </TableCell>
                                        <TableCell className='table-content'>
                                            {moment(endDate).format('HH:mm')}
                                        </TableCell>
                                    </TableRow>
                                    {!isRoleAdmin &&
                                        extraButton(
                                            aasm_state,
                                            !moment().isAfter(startDate) ? (
                                                <button
                                                    className='col-12 button-tertiary'
                                                    onClick={() => {
                                                        onCancelVisit(visit.id);
                                                    }}
                                                >
                                                    {intl.get('CANCEL')}
                                                </button>
                                            ) : null,
                                            moment().isAfter(startDate) ? (
                                                isCreated ? (
                                                    <ButtonTooltip
                                                        title={intl.get('YOUR_VERIFICATION_IS_PENDING')}
                                                        className='d-flex justify-content-center align-items-center col-12 button-primary-disabled'
                                                        textButton={intl.get('FINISH_VISIT')}
                                                    />
                                                ) : (
                                                    <button
                                                        className='col-12 button-primary'
                                                        onClick={(e: any) => handleClick(e, visit.id, post_id)}
                                                    >
                                                        {intl.get('FINISH_VISIT')}
                                                    </button>
                                                )
                                            ) : (
                                                <button
                                                    className='col-12 button-primary'
                                                    onClick={() => {
                                                        history.push(`/post/${unit_id}/calendar/${visit.id}/`);
                                                    }}
                                                >
                                                    {intl.get('RESCHEDULE')}
                                                </button>
                                            ),
                                            <button
                                                className='col-12 button-fourth'
                                                onClick={() => {
                                                    !isRoleTanguero && onGetBlocks([unit_id, visit.id]);
                                                    history.push(`postsValidation/${post_id}`);
                                                }}
                                            >
                                                {intl.get(
                                                    !isRoleTanguero ? (!isVerified ? 'VERIFY' : 'DETAILS') : 'DETAILS',
                                                )}
                                            </button>,
                                        )}
                                </>
                            );
                        })
                    ) : (
                        <TableCell colSpan={7} className='text-center border-0'>
                            <p>{intl.get('DONT_HAVE_VISIT')}</p>
                        </TableCell>
                    )}
                </TableBody>
            </>
        );
    };
    return (
        <>
            {renderTableHeader()}
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='col-12 text-right'>
                    <i className='fa fa-circle-x color-primary cursor-pointer' onClick={handleClose} />
                </div>

                <AddHoursForm
                    fetch={visitFinishedFetch}
                    onSubmit={onVisitFinished(offerId, postId, handleClose, isRoleTanguero)}
                />
            </Popover>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        ...state.home,
    };
};

const mapDispatchToProps = (dispatch: any, getState: any) => {
    return {
        onCancelVisit: (id: any) => {
            return dispatch(cancelVisit(id));
        },
        onGetBlocks: (id: any) => {
            if (isRoleTanguero) {
                return dispatch(getBlocks(id));
            }
        },
        onVisitFinished: (id: string, postId: any, handleClose: any, isRoleTanguero: boolean) => (value: any) => {
            handleClose();
            return dispatch(visitFinishedAction(id, postId, value, isRoleTanguero));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitsTable);
