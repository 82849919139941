import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '../iconButton';
import Typography from '@material-ui/core/Typography';
import { CloseBtnX } from '../../resources';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
    classNameTitle: string;
    noTitle?: boolean;
    singUp?: boolean;
    newRentedUnit?: boolean;
    newCloseButton?: boolean;
    classes: any;
    lineUp?: boolean;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {
        children,
        classes,
        onClose,
        classNameTitle,
        noTitle,
        singUp = false,
        newRentedUnit = false,
        newCloseButton = false,
        lineUp = false,
        ...other
    } = props;

    const { pathname } = window.location;
    const locationValidation = pathname === '/new-unit-creation-instapage';
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose && !newCloseButton && !locationValidation ? (
                <IconButton type='close' icon='fa-times' classColor='btn-close-dm' onClick={onClose} />
            ) : null}
            {onClose && newCloseButton && (
                <div className='new-close-btn'>
                    <img src={CloseBtnX} onClick={onClose} />
                </div>
            )}
            {singUp || newRentedUnit || lineUp ? (
                <Typography variant='h2'>
                    <div className='template-line my-3' />
                    <p className={`${classNameTitle}`}>{children}</p>
                </Typography>
            ) : (
                <Typography variant='h2'>
                    <p className={`${classNameTitle} text-left modal-title`}>{children}</p>
                    {!noTitle && <div className='template-line mb-2' />}
                </Typography>
            )}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(0),
        paddingLeft: '2rem',
        paddingRight: '2rem',
        marginBottom: '0',
    },
}))(MuiDialogContent);

type DialogModalProps = {
    title: string;
    open: boolean;
    children?: any;
    handleClose?: () => void;
    className?: string;
    classNameTitle?: string;
    noTitle?: boolean;
    singUp?: boolean;
    newRentedUnit?: boolean;
    firstPosition?: boolean;
    newCloseButton?: boolean;
    lineUp?: boolean;
    contentDialogClass?: string;
};
const DialogModal = ({
    children,
    title,
    open,
    handleClose,
    className = '',
    classNameTitle = '',
    noTitle,
    singUp,
    newRentedUnit,
    newCloseButton = false,
    firstPosition = false,
    lineUp = false,
    contentDialogClass,
}: DialogModalProps) => {
    const idTitleWithNewButton = newCloseButton ? 'customized-dialog-title-with-icon' : 'customized-dialog-title';
    return (
        <div>
            <Dialog
                className={`dialog-modal-container-tango ${className}`}
                aria-labelledby='customized-dialog-title'
                open={open}
                scroll='paper'
                style={{ zIndex: firstPosition ? 1300 : 999 }}
            >
                <DialogTitle
                    id={idTitleWithNewButton}
                    onClose={handleClose}
                    classNameTitle={classNameTitle}
                    noTitle={noTitle}
                    singUp={singUp}
                    newRentedUnit={newRentedUnit}
                    newCloseButton={newCloseButton}
                    lineUp={lineUp}
                >
                    {title}
                </DialogTitle>
                <DialogContent id='customized-dialog-content' dividers className={contentDialogClass}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default DialogModal;
