import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import FormValidationTourOrVisit from './formValidationTourOrVisit';
import { Input, DatePicker, Select } from '..';
import { VISIT_TYPES } from '../../../tango-react-base/helpers/selectOptions/options';
import moment from 'moment';

/**
 * Here it is defined the type of the props for the interface "CreateNewTourOrVisitForm"
 * @typedef CreateNewTourOrVisitFormTypes
 * @type {(function)}
 * @property {object} propertyUrl - is a object.
 * @property {function} email - is a function.
 * @property {function} startDate - is a function.
 * @property {object} blocks - is a object.
 * @property {boolean} fullAddress - is a boolean.
 */
interface CreateNewTourOrVisitForm {
    propertyUrl?: string;
    email?: string;
    startDate?: Date;
    blocks: string;
    fullAddress?: string;
    visitType: string;
}

/**
 * Here it is defined the type of the props for the interface "ComponentProps"
 * @typedef ComponentPropsTypes
 * @type {(function|boolean|object|string)}
 * @property {function} onSubmit - is a Function.
 * @property {object} children - is a object.
 * @property {boolean} disabled - is a boolean.
 * @property {boolean} isBlockTaken - is a boolean.
 * @property {boolean} actualBlock - is a boolean.
 * @property {string} renterEmail - is a string.
 * @property {boolean} postUrl - is a string.
 */
interface ComponentProps {
    onSubmit: any;
    children?: any;
    disabled?: boolean;
    isBlockTaken?: any;
    actualBlock?: any;
    renterEmail?: string;
    visitKind?: string;
    postUrl?: string;
}
/**
 * Here it is defined the type of the props, this prop is similar to 'ComponentProps' but 'ComponentPropsTypes' is for the documentation
 * @typedef ComponentPropsTypes
 * @type {(function)}
 * @property {object} values - is a object.
 * @property {function} handleChange - is a function.
 * @property {function} handleBlur - is a function.
 * @property {boolean} touched - is a boolean.
 * @property {boolean} isBlockTaken - is a boolean.
 * @property {object} errors - is a object.
 */

/**
 * InnerForm(CreateNewTourOrVisitForm) is a functional component
 *@function
 *@param {ComponentPropsTypes}  values - all values of the form (propertyUrl,email,startDate,blocks,fullAddress)
 *@param {ComponentPropsTypes}  handleChange - detects changes in the input
 *@param {ComponentPropsTypes}  handleBlur - returns an object
 *@param {ComponentPropsTypes}  touched - returns a boolean indicating if it is "touched"
 *@param {ComponentPropsTypes}  isBlockTaken - return a boolean for validate if tour are available
 *@param {ComponentPropsTypes}  errors - returns an object with the errors
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<ComponentProps & FormikProps<CreateNewTourOrVisitForm>> = ({
    values,
    handleChange,
    handleBlur,
    touched,
    isBlockTaken,
    errors,
    children,
}) => {
    const isNotPhotographer = process.env.REACT_APP_API_ROLE_APP !== process.env.REACT_APP_VERIFIER;

    const start = moment().startOf('day').add(8, 'hour').add(30, 'minute');
    const start_new = moment().startOf('day').add(9, 'hour');

    /**
     * times = number of blocks to be generated
     */
    const times = 24;
    let BLOCKS = [];

    for (let i = 0; i < times; i++) {
        const toPrint = moment(start)
            .add(30 * i, 'minutes')
            .format('HH:mm');
        const toPrintMorOneHore = moment(start_new)
            .add(30 * i, 'minutes')
            .format('HH:mm');
        BLOCKS.push({ label: 'block', value: `${toPrint} - ${toPrintMorOneHore}` });
    }

    return (
        <Form>
            <Input
                id='propertyUrl'
                label='PROPERTY_TO_VISIT'
                info='ATTACH_PROPERTY_LINK'
                type='url'
                value={values.propertyUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.propertyUrl}
                disabled={isBlockTaken}
                className='my-4'
                required={true}
                error={errors.propertyUrl}
                classNameInput='col-md-8 plm-0 pr-0'
                justifyCenter='align-items-center'
            />
            {isNotPhotographer && (
                <Input
                    id='email'
                    label='RENTER_EMAIL'
                    type='text'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.email}
                    disabled={isBlockTaken}
                    error={errors.email}
                    required={true}
                    className='my-4'
                    classNameInput='col-md-8 plm-0 pr-0'
                    justifyCenter='align-items-center'
                />
            )}
            {isNotPhotographer && (
                <Select
                    options={VISIT_TYPES}
                    id='visitType'
                    onChange={handleChange}
                    label='VISIT_TO_DO'
                    value={values.visitType}
                    className='my-4'
                    classNameInput='col-md-8 plm-0 pr-0'
                    justifyCenter='align-items-center'
                    classNameSelect='customized-select pl-3'
                />
            )}

            <div className='row'>
                <DatePicker
                    variant
                    openTo
                    name='startDate'
                    disableToolbar={false}
                    value={values.startDate}
                    label={'SELECT_VISIT_DAY'}
                    disabled={isBlockTaken}
                    minDate={new Date()}
                    className='col-md-7 p-0 '
                    classNameInput='col-md-4 pl-1'
                    classNameLabel='col-md-7 pl-0'
                    error={errors.startDate}
                    isDisableWeekends
                />

                <Select
                    options={BLOCKS}
                    id='blocks'
                    onChange={handleChange}
                    label={'BLOCK_VISIT'}
                    value={values.blocks}
                    disabled={isBlockTaken}
                    classNameLabel='col-md-6'
                    className='col-md-5 p-0'
                    classNameInput='col-md-6 p-sm-0'
                    classNameSelect='customized-select pl-3'
                    isCustome={true}
                />
            </div>
            {children}
        </Form>
    );
};
const CreateTourOrVisitForm = withFormik<ComponentProps, CreateNewTourOrVisitForm>({
    mapPropsToValues: (props: any) => {
        const initialValues = {
            propertyUrl: props.postUrl || '',
            email: props.renterEmail || '',
            blocks: '',
            fullAddress: '',
            visitType: props.visitKind || '',
        };
        return {
            ...initialValues,
        };
    },

    validationSchema: FormValidationTourOrVisit,

    handleSubmit(values: CreateNewTourOrVisitForm, { props }) {
        if (props.isBlockTaken) {
            values.blocks = props.actualBlock;
        }
        const dateBlock = moment(values.startDate).format('YYYY-MM-DD');
        const dateNow = moment().format('YYYY-MM-DD');
        const diffDate = moment(dateBlock).diff(moment(dateNow), 'days');

        if (values.blocks.length <= 1) {
            toast(intl.get('SELECT_A_BLOCK'));
        } else if (diffDate > 10 || diffDate < 0) {
            toast(intl.get('YOU_CANNOT_SELECT_THAT_DATE'));
        } else {
            props.onSubmit(values);
        }
    },
})(InnerForm);
export default CreateTourOrVisitForm;
