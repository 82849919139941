import { getDataResource } from '../../helpers/resources';
import { getCountriesCodesStart, getCountriesCodesError, getCountriesCodesSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCountriesCodes = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getCountriesCodesStart());
        getDataResource(`country_codes`)
            .then((result) => {
                dispatch(getCountriesCodesSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getCountriesCodesError(e));
            });
    };
};
export default getCountriesCodes;
