import React, { useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import IconAndDescription from './IconAndDescription';
import ModalSignUp from './ModalSignUp';
import { useBlankView } from '../../helpers/hookBlankView/index';
import { detectMob } from '../../helpers/detectMobile';
import {
    tangoWhiteGreyLogo,
    tangoBlackRedLogo,
    tangoRedGreyLogo,
    SignupDeskRegistroRenter,
    SingupDeskRegistro2Renter,
    SingupDeskLayerRenter,
    SingupDeskRenter,
    SignupDeskCorretajeOwner,
    SignupDeskOfertaOwner,
    SignupDeskPagosOwner,
    SignupDeskOwner,
    SignupMobCorretajeOwner,
    SignupMobOfertaOwner,
    SignupMobPagosOwner,
    SignupMobOwner,
} from '../../resources';
import { CREATION_STEPS } from './steps';
import { useLastLocation } from 'react-router-last-location';
import {
    isRoleAdmin,
    isRolePhotographer,
    isRoleRenter,
    isRoleSuperAdmin,
    isRoleTanguero,
} from '../../helpers/roleComparison';
import { getParameterByName } from '../../helpers/getParamsUrl/';
import { useWindowSize } from '../../helpers/hookWindowSize';

type ButtonChangeView = {
    label: string;
    to: string;
};

interface CardMountContainerProps {
    children?: any;
    grid_card?: number;
    buttonsActionView?: Array<ButtonChangeView>;
    activeViewLabel?: string; // Only use when you neeed change view
    infoTitle?: string;
    descriptionTitle?: string;
    textBody?: string;
    textBodySize?: string;
    heightCard?: string;
    IsOwner?: boolean;
    newUnitCreation?: boolean;
    creationStep?: string;
    emailField?: boolean;
    chooseRegister?: boolean;
}

const CardMountContainer = ({
    children,
    IsOwner,
    grid_card = 11,
    buttonsActionView,
    activeViewLabel,
    infoTitle,
    descriptionTitle,
    textBody,
    textBodySize,
    heightCard,
    newUnitCreation = false,
    creationStep,
    emailField = false,
    chooseRegister = false,
}: CardMountContainerProps) => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const CURRENT_LOCATION = pathname.split('/')[1];
    const ref: any = useRef(null);
    useBlankView(ref);
    const routesWithExpection = ['/new-unit-creation-instapage', '/sign-up-instapage', '/search-instapage'];

    const paramsNewRegister = getParameterByName('new_register', history.location.search);

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation?.pathname || '';
    const isEmployee = isRolePhotographer || isRoleSuperAdmin || isRoleTanguero || isRoleAdmin;
    const currentAC = routesWithExpection.includes(pathname) || routesWithExpection.includes(lastPathname);

    const formValidation = ['/new-unit-creation-instapage', '/sign_up', '/new-unit-creation', '/sign-up-instapage'];
    const showStep = !routesWithExpection.includes(pathname);
    const size = useWindowSize();
    const idValidation = formValidation.includes(pathname) ? 'form' : '';
    const isNewUnitCreationInstapage = history.location.pathname === '/new-unit-creation-instapage';
    const classRegisterMobile =
        (history.location.pathname === '/new-unit-creation' || history.location.pathname === '/sign_up') &&
        size.width > 768
            ? 'card-container-mount card-without-shadow '
            : 'container-mount w-100';

    const { width } = useWindowSize();

    const CREATION_STEPS_NEW = [
        { number: '1', content: 'CONTACT_DATA', state: 'owner-data' },
        { number: '2', content: 'UNIT_DATA', state: 'unit-data' },
        { number: '3', content: 'SELECT_VISIT_TIME', state: 'schedule-visit' },
        { number: '4', content: 'SIGN_UP', state: 'sign_up' },
    ];

    return (
        <div className='mount-container-tango-view'>
            <div className={`col-md-${grid_card} p-0 col-12`}>
                <div
                    className={`${
                        currentAC && !isNewUnitCreationInstapage
                            ? 'card-little-margin-top'
                            : isNewUnitCreationInstapage
                            ? 'm0-without-shadow'
                            : ''
                    } ${classRegisterMobile}`}
                    ref={ref}
                >
                    <section
                        className={`${!chooseRegister && 'info-card'} ${
                            newUnitCreation && !chooseRegister && 'new-unit-creation'
                        } ${chooseRegister && 'choose-register'}`}
                    >
                        {size.width > 768 && (
                            <div className='text'>
                                <div className={'text-color-black'}>
                                    {infoTitle && <h1>{intl.getHTML(infoTitle)}</h1>}
                                    <div className={`${!chooseRegister && 'template-line'}`} />
                                    {descriptionTitle && <p className='m-0'>{intl.get(descriptionTitle)}</p>}
                                </div>
                                {newUnitCreation && !chooseRegister && (
                                    <div className='mt-5'>
                                        {CREATION_STEPS.map(
                                            (step: { number: string; content: string; state: string }) => (
                                                <div className='d-flex flex-column'>
                                                    <h3
                                                        className={`${
                                                            creationStep === step.state && 'active-step'
                                                        } creation-step`}
                                                        key={step.number}
                                                    >
                                                        <span
                                                            className={`${
                                                                creationStep === step.state && 'active-step'
                                                            } circle-step`}
                                                        >
                                                            {step.number}
                                                        </span>
                                                        <span className='step-text step-margin'>
                                                            {intl.get(step.content)}
                                                        </span>
                                                    </h3>
                                                    {<span className='vertical-line' />}
                                                </div>
                                            ),
                                        )}
                                        <div className='d-flex flex-column'>
                                            <h3 className='creation-step mb-2'>
                                                <span className={'circle-step'}>{4}</span>
                                                <span className='step-text step-margin'>{intl.get('SIGN_UP')}</span>
                                            </h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {chooseRegister && size.width > 768 ? (
                            <div className={`${chooseRegister && 'choose-register-logo-tango'}`}>
                                <img src={tangoWhiteGreyLogo} alt='Logo' className='big-img-logo' />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {pathname === '/sign_up' && (
                            <>
                                {detectMob() && !IsOwner && IsOwner && <ModalSignUp isOwner={IsOwner} mobile={detectMob()} />}
                                {paramsNewRegister != 'yes' ? (
                                    <>
                                        <div className='info-body upper-content'>
                                            <IconAndDescription
                                                img={IsOwner ? SignupDeskOfertaOwner : SingupDeskRenter}
                                                text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_1`)}
                                            />
                                            <IconAndDescription
                                                img={IsOwner ? SignupDeskCorretajeOwner : SignupDeskRegistroRenter}
                                                text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_2`)}
                                            />
                                            <IconAndDescription
                                                img={IsOwner ? SignupDeskOwner : SingupDeskRegistro2Renter}
                                                text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_3`)}
                                            />
                                            <IconAndDescription
                                                img={IsOwner ? SignupDeskPagosOwner : SingupDeskLayerRenter}
                                                text={intl.get(`ICON_TEXT_${IsOwner ? 'OWNER' : 'RENTER'}_4`)}
                                            />
                                        </div>
                                        {IsOwner && (
                                            <p className='info-owner-singup font-weight-bold upper-body'>
                                                {intl.get('SIGNUP_INFO_OWNER')}
                                            </p>
                                        )}
                                        {isRoleRenter && <p></p>}
                                    </>
                                ) : (
                                    <>
                                        {size.width > 768 && (
                                            <div className='mt-5'>
                                                {CREATION_STEPS.map(
                                                    (step: { number: string; content: string; state: string }) => (
                                                        <div className='d-flex flex-column'>
                                                            <h3
                                                                className={`${
                                                                    creationStep === step.state && 'active-step'
                                                                } creation-step`}
                                                                key={step.number}
                                                            >
                                                                <span
                                                                    className={`${
                                                                        creationStep === step.state && 'active-step'
                                                                    } circle-step`}
                                                                >
                                                                    {step.number}
                                                                </span>
                                                                <span className='step-text step-margin'>
                                                                    {intl.get(step.content)}
                                                                </span>
                                                            </h3>
                                                            {<span className='vertical-line' />}
                                                        </div>
                                                    ),
                                                )}
                                                <div className='d-flex flex-column'>
                                                    <h3 className='creation-step mb-2'>
                                                        <span className={'circle-step active-step'}>{4}</span>
                                                        <span className='step-text step-margin font-weight-bold'>
                                                            {intl.get('SIGN_UP')}
                                                        </span>
                                                    </h3>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </section>

                    {!chooseRegister && width < 993 && showStep && paramsNewRegister && (
                        <div className='body-card-step'>
                            <div className='mobile-steps'>
                                <>
                                    <span className='text-header'>{intl.get('SIMPLE_STEPS_VISIT')}</span>
                                    <div className='w-100'>
                                        <div className='template-line' />
                                    </div>
                                </>
                                {CREATION_STEPS_NEW.map((step) => {
                                    const { content, number, state } = step;
                                    return (
                                        <div key={number} className='w-100'>
                                            {creationStep === state ||
                                            (paramsNewRegister === 'yes' && state === 'sign_up') ? (
                                                <div className='row w-100 mt-4 mb-2'>
                                                    <span className='circle-step-mobile'>{number}</span>
                                                    <span className='col-10 content-text'>{intl.get(content)}</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    );
                                })}
                                {CREATION_STEPS_NEW.map((step) => {
                                    const { state } = step;
                                    return (
                                        <div className='mb-4 col-3 p-0 d-block'>
                                            <div
                                                className={`step-line ${
                                                    creationStep === state ||
                                                    (paramsNewRegister === 'yes' && state === 'sign_up')
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <section
                        ref={(el) =>
                            width <= 768
                                ? el?.style.setProperty(
                                      'width',
                                      creationStep === 'schedule-visit' ? ' 100vw' : '',
                                      'important',
                                  )
                                : el?.style.removeProperty('width')
                        }
                        id={idValidation}
                        className={`${currentAC && 'bg-color-secondary'} ${
                            newUnitCreation && !emailField && 'new-unit-creation'
                        } ${size.width > 768 ? 'body-card' : 'body-card-mobile'}`}
                    >
                        <div className={`${heightCard ? heightCard : ''}`}>
                            {textBody && (
                                <div className='title-body '>
                                    <div className='template-line' style={{ backgroundColor: `#A8123E` }} />
                                    {CURRENT_LOCATION === 'sign_up' ||
                                        (CURRENT_LOCATION === '/sign-up-instapage' && (
                                            <h1 className='d-md-none d-sm-block mb-3'>{intl.get('CODE_VALIDATE')}</h1>
                                        ))}
                                    {CURRENT_LOCATION === 'forget_password' && (
                                        <h1 className='d-md-none d-sm-block mb-3'>{intl.get('RECOVER_PASSWORD')}</h1>
                                    )}
                                    <p className={`${textBodySize} m-0 mt-2`}>{intl.getHTML(textBody)}</p>
                                </div>
                            )}

                            {children}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default CardMountContainer;
