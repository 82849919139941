import axios from 'axios';
export default async (url: string, data?: any) => {
    const api_url = `${process.env.REACT_APP_API_URL}`;
    const role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const urlsWithoutApp = ['auth', 'toc', 'user'];
    const baseUrl = url.split('/')[0];
    let uri = urlsWithoutApp.includes(baseUrl)
        ? api_url.replace(role.toLowerCase(), `${url}`)
        : `${process.env.REACT_APP_API_URL}/${url}`;
    let headers: any = {
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };
    return axios.post(uri, data, { headers });
};
