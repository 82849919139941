import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ButtonLoading, CardMountContainerTango, CheckBox, CircularLoading, Input } from '../..';
import { REMEMBER_BOOLEAN_OPTIONS } from '../../../../tango-react-base/helpers/selectOptions/options';
import { DialogModal, RedirectTo } from '../../../components';
import { getParameterByName } from '../../../helpers/getParamsUrl/';
import { useWindowSize } from '../../../helpers/hookWindowSize/index';
import { isRoleOwner, isRoleRenter } from '../../../helpers/roleComparison/index';
import { sendTokenCreateUser, setGoogleSession, setSession, validateEmailPresence } from '../../../reduxActions';
import getNewToken from '../../../reduxActions/getNewToken/index';

import { GoogleLogin } from '@react-oauth/google';
import { useIsMobile } from '../../../helpers/hookIsMobile';
import { buttonActionValidation } from '../createUser';

/**
 *Here it is defined the type of the props for the interface "LoginFormProps"
 * @type {(function|string|object)}
 * @property {function} onValidateEmailPresence - is an function.
 * @property {function} onLoginSession - is an function.
 * @property {string} idUser - is an string.
 * @property {object} children - is an object.
 * @property {string} fetchSessionStatus - is a string.
 * @property {string} fetchUserToken - is an string.
 * @property {string} email - is an string.
 * @property {string} lastLocation - is an string.
 * @property {string} userEmailFormView - is an string.
 * @property {string} errorType - is an string.
 * @property {string} statusToken - is an string.
 * @property {function} onSendTokenUser - is an boolean.
 * @property {function} onSetGoogleSession - is a function.
 **/
interface LoginFormProps {
    onValidateEmailPresence: any;
    onLoginSession: any;
    idUser?: string;
    children?: any;
    fetchSessionStatus: string;
    fetchUserToken?: string;
    email?: string;
    lastLocation: any;
    userEmailFormView?: string;
    errorType?: Object;
    statusToken?: string;
    onSendTokenUser: any;
    currentLocation?: string;
    history?: any;
    onSetGoogleSession: (values: any) => void;
}
/**
 * this is the form used to login the user
 *@function
 *@param {createUserFormTypes}  email - input to the email
 *@param {createUserFormTypes}  password - input to the password
 *@param {createUserFormTypes}  remember_me -check to remember the user
 * @returns {(ReactComponent)} returns a component with the inputs for the login
 */
interface LoginForm {
    email: string;
    password: string;
    remember_me: Array<string>;
}
/**
 *@function
 *@param {LoginForm} - this is the form used to login the user
 */
const InnerForm: React.FunctionComponent<LoginFormProps & FormikProps<LoginForm>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    children,
    fetchSessionStatus,
    fetchUserToken,
    errorType,
    statusToken,
    userEmailFormView,
    onSendTokenUser,
    onSetGoogleSession,
    lastLocation,
}) => {
    const [usesGoogle, setUsesGoogle] = useState(false);
    const size = useWindowSize();
    const history = useHistory();
    const authorization = sessionStorage.getItem('authorization');
    const lastLocationSaved = sessionStorage.getItem('lastLocation');
    const locationSearch = history.location.search;
    const lastPathname = lastLocationSaved?.includes('?') ? lastLocationSaved?.split('?')[0] : lastLocationSaved;
    const lastLocationSearch = lastLocationSaved?.includes('?') ? lastLocationSaved?.split('?')[1] : null;
    const userEmail = getParameterByName('email', lastLocationSearch || locationSearch);
    const contractIdParam: any = getParameterByName('contract_id', lastLocationSearch || locationSearch);
    const userRedirectParam: any = getParameterByName('url', lastLocationSearch || locationSearch);
    const uuidParam: any = getParameterByName('uuid', lastLocationSearch || locationSearch);
    const [initialUserEmail, setInitialUserEmail] = useState(userEmail || userEmailFormView || '');
    const profileFormPath: any = getParameterByName('profileFormPath', lastLocationSearch || locationSearch);
    const validationKey = getParameterByName('key', locationSearch);
    const firstPath = lastPathname?.split('/')[1];
    const paramPostId = getParameterByName('post_id', locationSearch);
    const post = getParameterByName('post', locationSearch);
    const [postLooked, setPostLooked] = useState(post);
    const viewException = [
        'terms-and-conditions',
        'about-us',
        'login',
        'sign_up',
        'forget_password',
        'new',
        'thank_you',
        'intention',
        'short-register',
    ];
    const completeLastLocation = lastLocationSearch ? `${lastPathname}?${lastLocationSearch}` : lastPathname;
    const validationToAll =
        !viewException.includes(firstPath ? firstPath : '') && lastPathname !== '/' && lastPathname !== undefined;
    const appTangoBackOffice = !isRoleRenter && !isRoleOwner;
    const isMobile = useIsMobile();
    /* If lastPathname is different from null and if it is different from the main page, or the pages that
    they should be universal, the redirection of the last accessed route is generated. If it doesn't exist...
    In Owner it has its own redirection component, for the rest to the main page */
    const redirectToLastLocation = () => {
        const lastLocationNewUnit = completeLastLocation === '/new-unit-creation';
        sessionStorage.removeItem('lastLocation');
        if (lastPathname && validationToAll && !lastLocationNewUnit) {
            return (
                <>
                    <CircularLoading />
                    {(window.location.href = completeLastLocation ? completeLastLocation : '/')}
                </>
            );
        } else {
            if (isRoleOwner || lastLocationNewUnit) {
                return <RedirectTo />;
            } else if (isRoleRenter && postLooked) {
                window.location.href = `${postLooked}`;
            } else {
                {
                    window.location.href = '/';
                }
                return (
                    <>
                        <CircularLoading />
                    </>
                );
            }
        }
    };

    useEffect(() => {
        const valuesUser = {
            email: initialUserEmail,
            token: validationKey,
        };

        if (sessionStorage.getItem('authorization')) {
            redirectToLastLocation();
        }
        if (userRedirectParam !== 'null') {
            sessionStorage.setItem('QrRedirect', userRedirectParam);
        }
        if (uuidParam !== 'null') {
            sessionStorage.setItem('uuid', uuidParam);
        }
        if (profileFormPath !== 'null') {
            sessionStorage.setItem('profileFormPath', profileFormPath);
        }
        if (contractIdParam !== 'null') {
            sessionStorage.setItem('contractIdParam', contractIdParam);
        }
        validationKey && onSendTokenUser(valuesUser, history);
    }, []);

    const includeEmailLastLocation = () => {
        if (lastLocationSaved) {
            const url = new URL(window.location.origin + lastLocationSaved);
            const searchParams = url.searchParams;
            const email = searchParams.get('email') ? searchParams.get('email')!.replace(/ /g, '+') : null;
            return email;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (
            !sessionStorage.getItem('authorization') &&
            includeEmailLastLocation() !== null &&
            !window.location.href.includes('login?email')
        ) {
            window.location.href = `${window.location.origin}/login?email=${includeEmailLastLocation()}`;
        } else {
            if (!window.location.href.includes('login')) {
                setTimeout(() => {
                    const firstPathLastLocation = lastPathname?.split('/')[1].split('?')[0];
                    if (
                        lastLocationSaved &&
                        lastLocationSaved != '/' &&
                        !viewException.includes(firstPathLastLocation ? firstPathLastLocation : '')
                    ) {
                        window.location.href = lastLocationSaved;
                        sessionStorage.removeItem('lastLocation');
                    }
                    if (!isRoleOwner && !isRoleRenter && lastLocation && lastLocation.pathname != '/') {
                        history.push(lastLocation.pathname);
                    }
                }, 1);
            }
        }
    });

    useEffect(() => {
        if (appTangoBackOffice) return;
        if (isMobile) {
            // Set height to default on mobile
            document.querySelector('.container-blank-view')?.setAttribute('style', `height: 50rem`);
            return;
        }

        // Get height to card and set blank view as the height of the card plus an extra
        const cardHeight = document.querySelector('.card-view-login')?.clientHeight;
        const view =
            document.querySelector('.container-blank-view') || document.querySelector('.container-blank-view-s');
        view?.setAttribute('style', `height: ${(cardHeight || 0) + 130}px`);
    }, [fetchSessionStatus, isMobile]);

    const dispatch = useDispatch();
    const handleNewToken = () => {
        dispatch(getNewToken(values.email));
        if (tokenSendSuccess) {
            setpopUpOpen(false);
        }
    };
    const [popUpOpen, setpopUpOpen] = React.useState(false);
    const tokenSendSuccess = statusToken === 'SUCCESS';
    const isFetching = fetchSessionStatus === 'FETCHING' || fetchUserToken === 'FETCHING';

    useEffect(() => {
        if (fetchSessionStatus === 'ERROR_FETCHING' && errorType === 'VERIFY_ACCOUNT_TOKEN') {
            setpopUpOpen(!popUpOpen);
        }
    }, [fetchSessionStatus, errorType, tokenSendSuccess]);

    /** responseGoogle: This function sends tokenId allowing registration and/or login with Google account.
     * @function
     */
    const responseGoogle = (response: any) => {
        setUsesGoogle(true);
        onSetGoogleSession(response);
    };

    return (
        <>
            {isRoleOwner || isRoleRenter ? (
                <div className='card-view-login col-md-8 col-xl-6'>
                    <div className='row'>
                        <div className='position-absolute'>
                            <h2 className='title'>{intl.get('LOG_IN')}</h2>
                            <div className='template-line'></div>
                        </div>
                    </div>

                    <Form>
                        <section className='pt-5 p-0'>
                            <Input
                                id='email'
                                label='EMAIL'
                                type='email'
                                value={(values.email = initialUserEmail)}
                                error={errors.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                    setInitialUserEmail(e.target.value)
                                }
                                onBlur={handleBlur}
                                touched={touched.email}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left'
                                justifyCenter='align-items-center'
                                placeholder={intl.get('EMAIL_EXAMPLE')}
                            />
                            <Input
                                id='password'
                                label='PASSWORD'
                                type='password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.password}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left mt-4'
                                placeholder={intl.get('ENTER_PASSWORD')}
                            />

                            <section className='info-post-login p-0'>
                                <div className='row d-flex flex-inline'>
                                    <div className='col-5 col-md-6 p-0'>
                                        <CheckBox
                                            options={REMEMBER_BOOLEAN_OPTIONS}
                                            label=''
                                            name='remember_me'
                                            disabled={false}
                                            classNameContainer='col-12 p-0'
                                        />
                                    </div>

                                    <div className='col-7 col-md-6 p-0 d-flex justify-content-end'>
                                        <div className='forget-password-container mb-2'>
                                            <button
                                                className='button-transparent text-color-tertiary-inline text-underline btn-pass plm-0'
                                                type='button'
                                                onClick={() => history.push('/forget_password')}
                                            >
                                                {intl.get('FORGOT_PASS')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {children}

                            <DialogModal
                                title={intl.get('ONE_STEP_MISSING')}
                                open={popUpOpen}
                                handleClose={() => setpopUpOpen(false)}
                            >
                                <div className='p-3'>
                                    <p className='text-dark'>
                                        {intl.get('ACCOUNT_NOT_ACTIVE')}
                                        <span className='text-select' onClick={handleNewToken}>
                                            {intl.get('HERE')}
                                        </span>
                                    </p>
                                    <p className='color-secondary p-2'>{intl.get('REMEMBER_TWO_HOURS_LOGIN')}</p>
                                </div>
                            </DialogModal>
                            {fetchSessionStatus === 'ERROR_FETCHING' && errorType !== 'VERIFY_ACCOUNT_TOKEN' && (
                                <p className='text-color-secondary'>{intl.get('LOGIN_ERROR')}</p>
                            )}
                            <button className='button-secondary col-12 mt-2' type='submit' disabled={isFetching}>
                                {isFetching && !usesGoogle ? <ButtonLoading /> : intl.get('LOGIN_1')}
                            </button>

                            {fetchSessionStatus === 'FETCHED' && redirectToLastLocation()}
                        </section>

                        <section className='p-0'>
                            {(isRoleOwner || isRoleRenter) && (
                                <div className='row'>
                                    <div className='template-line-or-container'>
                                        <div className='template-line-or-left' />
                                        <span className='text-color-tertiary'>{intl.get('OR')}</span>
                                        <div className='template-line-or-right' />
                                    </div>
                                    <div className='google-button-container'>
                                        <GoogleLogin onSuccess={responseGoogle} onError={() => {}} />
                                    </div>
                                </div>
                            )}
                        </section>
                    </Form>
                </div>
            ) : (
                <CardMountContainerTango
                    buttonsActionView={buttonActionValidation}
                    activeViewLabel={`LOGIN`}
                    infoTitle='FORGET_ABOUT_COMPLICATED'
                    descriptionTitle='THE_EASIEST_WAY'
                    textBody='WELCOME_ENTER_USER'
                >
                    {(isRoleOwner || isRoleRenter) && (
                        <div className='row'>
                            <div className='google-button-container'>
                                <GoogleLogin onSuccess={responseGoogle} onError={() => {}} />
                            </div>
                            <div className='template-line-or-container-app-tango'>
                                <div className='template-line-or-left-app-tango' />
                                <span id='template-line-span'>{intl.get('OR')}</span>
                                <div className='template-line-or-right-app-tango' />
                            </div>
                        </div>
                    )}
                    <Form className='text-color-secondary'>
                        <section>
                            <Input
                                id='email'
                                label='EMAIL'
                                type='email'
                                value={(values.email = initialUserEmail)}
                                error={errors.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                    setInitialUserEmail(e.target.value)
                                }
                                onBlur={handleBlur}
                                touched={touched.email}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left'
                                variant={false}
                            />
                            <Input
                                id='password'
                                label='PASSWORD'
                                type='password'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.password}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left mt-4'
                                variant={false}
                            />
                            {children}

                            <DialogModal
                                title={intl.get('ONE_STEP_MISSING')}
                                open={popUpOpen}
                                handleClose={() => setpopUpOpen(false)}
                            >
                                <div className='p-3'>
                                    <p className='text-dark'>
                                        {intl.get('ACCOUNT_NOT_ACTIVE')}
                                        <span className='text-select' onClick={handleNewToken}>
                                            {intl.get('HERE')}
                                        </span>
                                    </p>
                                    <p className='color-secondary p-2'>{intl.get('REMEMBER_TWO_HOURS_LOGIN')}</p>
                                </div>
                            </DialogModal>
                            {fetchSessionStatus === 'ERROR_FETCHING' && errorType !== 'VERIFY_ACCOUNT_TOKEN' && (
                                <p className='text-color-error'>{intl.get('LOGIN_ERROR')}</p>
                            )}
                            <button className='button-primary col-12 mt-5' type='submit' disabled={isFetching}>
                                {isFetching && !usesGoogle ? <ButtonLoading /> : intl.get('LOGIN')}
                            </button>

                            {fetchSessionStatus === 'FETCHED' && redirectToLastLocation()}
                        </section>
                        <section className='info-post-login'>
                            <div className='row'>
                                <div className='col-12 col-md-6 plm-0'>
                                    <CheckBox
                                        options={REMEMBER_BOOLEAN_OPTIONS}
                                        label=''
                                        name='remember_me'
                                        disabled={false}
                                        classNameContainer='col-12 plm-0'
                                    />
                                </div>

                                <div className='col-8 col-md-6 plm-0'>
                                    <div className='forget-password-container'>
                                        <button
                                            className='button-transparent color-secondary btn-pass plm-0'
                                            onClick={() => history.push('/forget_password')}
                                        >
                                            {intl.get('FORGOT_PASS')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Form>
                    <div className='footer-container button-color row'>
                        <div className={`col-12 col-md text-left ${size.width <= 768 && 'font-sm-08 plm-0 mb-2'}`}>
                            <span className='text-color-secondary'>{intl.get('DONT_HAVE_ACCOUNT')}</span>

                            <span
                                className={`ml-2 ${appTangoBackOffice ? 'link-login-black' : 'link-login'}`}
                                onClick={() => history.push(`/sign_up?post_id=${paramPostId}`)}
                            >
                                {intl.get('SIGN_UP')}
                                {intl.get('HERE')}
                            </span>
                        </div>
                    </div>
                </CardMountContainerTango>
            )}
        </>
    );
};

/**
 * This is used to select the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const LoginForm = withFormik<LoginFormProps, LoginForm>({
    mapPropsToValues: (props: any) => {
        return {
            remember_me: ['true'],
            ...props,
        };
    },

    validationSchema: Yup.object().shape({}),

    handleSubmit(values: LoginForm, { props, setSubmitting, setErrors }) {
        // For owners, email validation is needed first because they could have created their users in 'new-unit-creation'
        // This validation is used to inform them if they have not activated their account or not finished one of the steps from the 'new-unit-creation' form
        isRoleOwner
            ? props.onValidateEmailPresence(values, null, props.currentLocation, props.history)
            : props.onLoginSession(values);
    },
})(InnerForm);

const mapStateToProps = (state: any) => {
    const { status: statusToken } = state.generateNewToken;
    const { fetchSessionStatus, errorType } = state.session;
    const { fetchUserToken } = state.user;
    return {
        fetchSessionStatus,
        errorType,
        statusToken,
        fetchUserToken,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onValidateEmailPresence: (values: any, emailField: boolean, path: string, history: any) => {
            return dispatch(validateEmailPresence(values, emailField, path, history));
        },
        onLoginSession: (values: any) => {
            return dispatch(setSession(values));
        },
        onSendTokenUser: (values: any, history: any) => {
            return dispatch(sendTokenCreateUser(values, history));
        },
        onSetGoogleSession: (values: any) => {
            return dispatch(setGoogleSession(values));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
