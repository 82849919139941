import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import uploadPhoto from '../../reduxActions/uploadImages';
import deletePhoto from '../../reduxActions/deleteImages';
import { deleteLogo } from '../../resources';
import { Tooltip } from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import { CircularLoading } from '../index';

/**
 * MultipleDropzoneTypes defines the property types for documentation
 * @interface MultipleDropzoneTypes
 * @type {(number | function | Array<any> | Array<number> | string)}
 * @prop {number} postId - Is a number
 * @prop {function} onUploadPhoto - Is a function
 * @prop {function} OnDeletePhoto - Is function
 * @prop {Array<any>} photos - Is an Array
 * @prop {function} handlePhotoSorting - Is function
 * @prop {function} setSortedPhotos - Is function
 * @prop {Array<number>} sortedPhotos - Is an Array of numbers
 * @prop {string} fetchPhotoUploading - Is a string
 */
interface MultipleDropzoneProps {
    postId: number;
    onUploadPhoto: (form_data: any, alt_text: string, postId: number) => void;
    OnDeletePhoto: (id: any, postId: number, type: string) => void;
    photos: Array<any>;
    handlePhotoSorting: (photoId: any) => void;
    sortedPhotos: Array<number>;
    setSortedPhotos: (sortedPhotos: Array<number>) => void;
    fetchPhotoUploading?: string;
}
/**
 * MultipleDropzone is a functional component which has many props. The main function of this component is to render a photo and video uploader
 *@function
 *@param {MultipleDropzoneTypes}  postId - Data of the post.
 *@param {MultipleDropzoneTypes}  onUploadPhoto - Handles the uploading of photos for the post
 *@param {MultipleDropzoneTypes}  OnDeletePhoto - Handles the deletion of the post photos
 *@param {MultipleDropzoneTypes}  photos - Returns the post photos
 *@param {MultipleDropzoneTypes}  handlePhotoSorting - Returns a function that either filters or adds ids to the sortedPhotos array state
 *@param {MultipleDropzoneTypes}  setSortedPhotos - Stores the selected photos into the sortedPhotos state
 *@param {MultipleDropzoneTypes}  sortedPhotos - Returns an array of sorted photos ids
 *@param {MultipleDropzoneTypes}  fetchPhotoUploading - Returns fetching state, for example: 'FETCHED'

 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const MultipleDropzone = ({
    postId,
    onUploadPhoto,
    OnDeletePhoto,
    photos,
    handlePhotoSorting,
    sortedPhotos,
    setSortedPhotos,
    fetchPhotoUploading,
}: MultipleDropzoneProps) => {
    let [files, setFiles] = useState([]);
    const [isPhotoBeingUploaded, setIsPhotoBeingUploaded] = useState(false);

    function makeid(length: number) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * 62));
        }
        return result;
    }
    const uploadPhoto = (title: string, alt_text: string, file: File) => {
        let form_data = new FormData();
        form_data.append('photo[post_id]', `${postId}`);
        form_data.append('photo[title]', title);
        form_data.append('photo[alt_text]', alt_text);
        form_data.append('photo[file]', file);
        onUploadPhoto(form_data, alt_text, postId);
    };

    useEffect(() => {
        setIsPhotoBeingUploaded(fetchPhotoUploading === 'STARTED');
    }, [fetchPhotoUploading]);

    const handleCompressor = (file: any, index: any) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            'JPEG',
            40,
            0,
            (img: any) => {
                uploadPhoto(`${file.name}-photo-${index}`, `alt-photo-${index}`, img);
            },
            'file',
            200,
            200,
        );
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles: any) => {
            const newFiles = acceptedFiles.map((file: any, index: any) => {
                index = makeid(15);
                handleCompressor(file, index);
                return Object.assign(file, {
                    index: `alt-photo-${index}`,
                    preview: URL.createObjectURL(file),
                });
            });
            setFiles(files.concat(newFiles));
        },
    });

    const deletePhoto = (id: any, postId: number, type: string) => {
        if (type === 'last') {
            OnDeletePhoto(id, postId, type);
            let tempSortedPhotos = sortedPhotos;
            if (tempSortedPhotos.some((tempPhotoId) => tempPhotoId === id)) {
                tempSortedPhotos = tempSortedPhotos.filter((tempPhotoId) => tempPhotoId !== id);
                setSortedPhotos(tempSortedPhotos);
            }
        }
    };

    const allPhotos = photos.map((photo: any, index) => {
        const { id, attributes } = photo || '';
        const { title, file_url } = attributes || '';
        return (
            <div className='thumb-inner img-container my-3' key={`file-${index}`}>
                <div className='tooltip-delete-logo text-right'>
                    <Tooltip title={intl.get('DELETE_PHOTO')} placement='top' arrow>
                        <img
                            src={deleteLogo}
                            alt='deleteLogo'
                            className='delete-logo-img-video'
                            onClick={() => deletePhoto(id, postId, 'last')}
                        />
                    </Tooltip>
                </div>
                <div onClick={() => handlePhotoSorting(id)} className='img-container selectable-photo'>
                    <small>{title.split('-', 1)}</small>
                    {sortedPhotos.map((sortedPhoto, index) =>
                        sortedPhoto == id ? (
                            <div className='selected-photo-number-container'>
                                <small className='selected-photo-number'>{index + 1}</small>
                            </div>
                        ) : (
                            ''
                        ),
                    )}
                    <img
                        className={`img col px-0 grid-image ${sortedPhotos.includes(id) && 'selected-border'}`}
                        src={file_url}
                        alt='img'
                    />
                </div>
            </div>
        );
    });

    return (
        <div className='multiple-dropzone-container'>
            <div className='template-line'></div>
            <h2 className='new-subtitle'>{intl.get('UPLOAD_PHOTOS')}</h2>
            <p className='col-12 p-info-label mb-4'>{intl.get('UPLOAD_PHOTOS_INFO')}</p>
            <section {...getRootProps({ className: 'dropzone' })} className='multiple-dropzone-box'>
                <input {...getInputProps()} />
                {isDragActive ? intl.get('DROP_FILE') : intl.get('DROP_IMAGES_HERE')}
            </section>
            {photos?.length > 0 && (
                <p className='text-left mt-5 text-color-tertiary'>{intl.get('PHOTO_ORDER_INSTRUCTIONS')}</p>
            )}
            <div className='thumbs-container'>{allPhotos}</div>
            {isPhotoBeingUploaded && (
                <div className='uploading-photos-message-container'>
                    <p className='mr-3'>{intl.get('UPLOADING_PHOTOS')}</p>
                    <CircularLoading />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    postId: state.postValidation.post.id,
    photos: state.photos.photos,
    fetchPhotoUploading: state.postValidation.fetchPhotoUploading,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onUploadPhoto: (data: FormData, index: string, postId: number) => {
            dispatch(uploadPhoto(data, index, postId));
        },
        OnDeletePhoto: (id: any, postId: number, type: any) => {
            dispatch(deletePhoto(id, postId, type));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleDropzone);
