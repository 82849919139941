import React, { FC } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';

/** Here it is defined the type of the props for the interface "NotAvailableVisitInfoTypes"
 * @typedef NotAvailableVisitInfoTypes
 * @type {(boolean|Object|Function|String)}
 * @property {object} personalData - is an object.
 * @property {oObject} post - is an object.
 * @property {function} customAction - is a function
 * @property {string} post - is an string.
 */
interface Props {
    personalData?: {
        names?: string;
        email?: string;
        phone_number?: string;
        rut?: string;
    };
    post?: {
        attributes: {
            unit: {
                full_address: string;
            };
        };
    };
    customAction?: Function;
    className?: string;
}

/** Here it is defined the type of the props for the interface "PersonalInformation"
 * @typedef PersonalInformationTypes
 * @type {(string)}
 * @property {object} name - is an string.
 * @property {object} phone_number - is an string.
 * @property {object} rut - is an string.
 * @property {object} email - is an string.
 * @property {object} address - is an string.
 * @property {object} rute - is an string.
 */
interface PersonalInformation {
    name: string;
    rut: string;
    phone_number: string;
    email: string;
    address: string;
    rute: string;
}

/**
 * Render a functional component with data to intercon
 *@function
 *@param {PostPropsTypes}
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const NotAvailableVisitInfo: FC<Props> = ({ post, personalData = {}, customAction, className = '' }) => {
    const address = post?.attributes?.unit?.full_address || '';

    return (
        <div className='not-available-visit'>
            <p>{intl.get('SCHEDULE_NOT_AVAILABLE_VISIT')}</p>
            <button type='button' className={className}>
                {intl.get('SCHEDULE_WITH_AN_TANGUERO')}
            </button>
        </div>
    );
};

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: { postDetail: { post: Object } }) => {
    const { post } = state?.postDetail;
    return {
        post,
    };
};

export default connect(mapStateToProps, null)(NotAvailableVisitInfo);
