import React from 'react'
import intl from 'react-intl-universal'
import { eyeIcon } from '../../../resources'

/**
 * this prop is similar to 'IdentityDocument' but 'IdentityDocumentPropsTypes' is for the documentation
 * @typedef IdentityDocumentPropsTypes
 * @type {(object)}
 * @property {identityDocument} identityDocument - is a object.
 */
interface IdentityDocumentProps {
  identityDocument: {
    backside_base64: string
    frontside_base64: string
  }
}

/**
 * IdentityDocument is a functional component
 *@function
 *@param {IdentityDocumentPropsTypes}  identityDocument - object with identity document
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const IdentityDocument = ({
  identityDocument: { backside_base64, frontside_base64 },
}: IdentityDocumentProps) => {
  let documents = [
    { name: 'IDENTITY_CARD_FRONT', value: frontside_base64 },
    { name: 'IDENTITY_CARD_BACK', value: backside_base64 },
  ]

  const handleNewTable = (data: string) => {
    const image = `${
      data.substring(0, 4).includes('data') ? data : `data:Image/png;base64,${data}`
    }`
    const newTab: any = window.open()
    const img = document.createElement('img')
    const div = document.createElement('div')
    img.src = `${image}`
    div.style.textAlign = 'center'
    div.appendChild(img)
    newTab.document.body.appendChild(div)
    return false
  }
  return frontside_base64 && backside_base64 ? (
    <>
      {documents.map((document) => (
        <div className='row mb-4'>
          <p className='span-text pl-4 plm-0 col-8 mr-2 text-break'>
            {intl.get(document.name)}
          </p>
          <div className='dropzone-cnt'>
            <img
              src={eyeIcon}
              alt='downloadLogo'
              className='upload-logo'
              onClick={() => handleNewTable(document.value)}
            />
          </div>
        </div>
      ))}
    </>
  ) : (
    <></>
  )
}

export default IdentityDocument
