import React from 'react';
import { Input } from '..';
import { SectionProps } from '../postForm/sections/SectionInterfaces';
import { connect } from 'react-redux';
import { changeUnit } from '../../reduxActions';
import intl from 'react-intl-universal';

const PropertyData = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    countriesCodes,
    fetchingCodes,
    disabled,
    isRegistered = false,
    toRepublish = false,
    submitFunction,
}: SectionProps) => {
    return (
        <div>
            {!toRepublish && (
                <>
                    <div className='template-line mt-4' />
                    <h2 className='new-subtitle'>{intl.get('PROPERTY_DATA')}</h2>
                </>
            )}
            <div className='row'>
                <Input
                    id='property_comments'
                    label='PROPERTY_COMMENTS'
                    type='text'
                    value={values.property_comments}
                    onChange={handleChange}
                    requiredMessage='Property comments is required'
                    disabled={disabled}
                    multiline={true}
                    rows={5}
                    classNameInput='col-md-8 plm-0'
                    classNameLabel='col-md-4 p-0'
                    className='w-100 p-0'
                />
            </div>
            {!toRepublish && (
                <div className='row d-flex justify-content-center'>
                    <button
                        type='submit'
                        disabled={disabled || Object.keys(errors).length > 0}
                        className='button-primary col-md-4 my-4'
                        onClick={submitFunction}
                    >
                        {intl.get('SAVE')}
                    </button>
                </div>
            )}

            {!toRepublish && Object.keys(errors).length > 0 && (
                <div className='row d-flex justify-content-center'>
                    <div className='col-md-4 text-danger'>
                        <h3>{intl.get('MISSING_FIELDS')}</h3>
                        <ul>
                            {Object.keys(errors).map((error, index) => (
                                <li key={index}>
                                    <small>{intl.get(error.toUpperCase())}</small>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onChangeValue: (property_comments: string) => {
            dispatch(changeUnit(property_comments));
        },
    };
};

const mapStateToProps = (state: any) => {
    const { unit, fetchingUnit } = state.publish;
    return {
        fetchingUnit,
        unit,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyData);
