import React, { useState, useEffect, Suspense } from 'react'
import { ButtonLoading, CardMountContainerTango, CircularLoading, PopupWithIcon } from '../..'
import { connect } from 'react-redux'
import { detectMob } from '../../../helpers/detectMobile'
import {
  createUser,
  getCountriesCodes,
  setGoogleSession,
  getNewReference
} from '../../../reduxActions'
import { useHistory } from 'react-router-dom'
import CardMountContainer from '../../cardMountContainer'
import intl from 'react-intl-universal'
import { getParameterByName } from '../../../helpers/getParamsUrl/'
import {
  isRoleOwner,
  isRoleRenter,
} from '../../../helpers/roleComparison/index'
import { useWindowSize } from '../../../helpers/hookWindowSize'
import { googleLoginClient } from '../../../helpers/googleLoginClient'
import { GoogleLogin } from '@react-oauth/google'
import ReferredCodePopups from './referredCodePopups'
import ModalSignUp from '../../cardMountContainer/ModalSignUp'

const UserForm = React.lazy(() => import('../userForm'))

const viewsToChange = [
  {
    label: 'SIGN_UP',
    to: '/sign_up',
  },
  {
    label: 'LOGIN',
    to: '/login',
  },
]

export const buttonActionValidation = viewsToChange
/** Here it is defined the type of the props for the interface "CreateUserFormProps"
 * @property {object} countriesCodes - is a object.
 * @property {string} fetchingCodes - is a string.
 * @property {string} fetchUser - is a string.
 * @property {string} userError - is a string.
 * @property {function} onGetCountriesCodes - is a function.
 * @property {function} onCreateUser - is a function.
 * @property {function} onSetGoogleSession - is a function.
 * @type {(function|object|string)}
 * @typedef CreateUserFormProps
 */

interface CreateUserFormProps {
  countriesCodes: any
  fetchingCodes: string
  fetchUser: string
  userError: any
  fullNameNewReference: string
  fetchingNewReference: string
  onGetCountriesCodes: () => void
  onCreateUser: (history: any, paramPostId: any) => void
  onSetGoogleSession: (
    values: any,
    history: any,
    redirectionURL: string,
    isInstapage: boolean
  ) => void
  onGetNewReference: (referredCode: string) => void
}

const CreateUserForm = ({
  countriesCodes,
  fetchingCodes,
  fetchUser,
  userError,
  onGetCountriesCodes,
  onCreateUser,
  onSetGoogleSession,
  fullNameNewReference,
  fetchingNewReference,
  onGetNewReference
}: CreateUserFormProps) => {
  const history = useHistory()
  const [readTermAndPolicies, setReadTermAndPolicies] = useState(false)
  const [incompleteForm, setIncompleteForm] = useState(false)
  const [referredCodeConfirmation, setReferredCodeConfirmation] = useState(false)
  const [referredCodeManual, setReferredCodeManual] = useState(false)
  const [referredCodeParam, setReferredCodeParam] = useState(getParameterByName('reference_code', history.location.search) || '')
  const [referredInformation, setReferredInformation] = useState(false)
  const [postId, setPostId]: any = useState(
    sessionStorage.getItem('propertyId') || 0
  )
  const [referredCodeLocalStorage, setReferredCodeLocalStorage] = useState(localStorage.getItem('referredCode') || '')
  const size = useWindowSize()

  /* This useEffect store in localStorage the code that comes from
  the parameters and update URL in order to work only with one variable.*/
  useEffect(() => {
    if (referredCodeParam && isRoleOwner) {
      localStorage.setItem('referredCode', referredCodeParam)
      setReferredCodeParam('')
      history.push('sign_up')
    }
  }, [referredCodeParam])

  /* This useEffect makes a request for the name of whom the code belongs to
  when the variable referredCodeLocalStorage changes.*/
  useEffect(() => {
    if (referredCodeLocalStorage && isRoleOwner) {
      onGetNewReference(referredCodeLocalStorage)
    }
  }, [referredCodeLocalStorage])

  /* This useEffect if the back request is successful, it open popup to confirm
  that the reference code comes from the correct person, otherwise, you must
  enter the reference code manual. */
  useEffect(() => {
    if (fetchingNewReference === 'FETCHED') {
      setReferredCodeManual(false)
      setReferredCodeConfirmation(true)
    }
    if (fetchingNewReference === 'FETCHING_ERROR') {
      if (!referredCodeManual) setReferredCodeManual(true)
    }
  }, [fetchingNewReference])

  const isFetching = fetchUser === 'FETCHING'
  const paramsNewRegister = getParameterByName(
    'new_register',
    history.location.search
  )

  const owner_title = size.width > 768 ? 'OWNER' : 'OWNER_MOBILE'
  const infoTitleValidation = `USER_REGISTRATION_${
    isRoleOwner ? (paramsNewRegister ? 'OWNER_STEPS' : owner_title) : 'RENTER'
  }`

  const appTangoBackOffice = !isRoleRenter && !isRoleOwner

  useEffect(() => {
    if (fetchingCodes !== 'FETCHED_CODES') onGetCountriesCodes()
  }, [fetchingCodes])
  const signUpInstapage = history.location.pathname.includes(
    '/sign-up-instapage'
  )
  const redirectionURL = signUpInstapage
    ? isRoleOwner
      ? `${process.env.REACT_APP_OWNER}tango-plans`
      : `${process.env.REACT_APP_RENTER}`
    : isRoleOwner
    ? '/tango-plans'
    : '/'

  /** responseGoogle: This function sends tokenId allowing registration and/or login with Google account.
   * @function
   */
  const responseGoogle = (response: any) => {
    onSetGoogleSession(response, history, redirectionURL, signUpInstapage)
  }

  return appTangoBackOffice ? (
    <CardMountContainerTango
      buttonsActionView={buttonActionValidation}
      activeViewLabel='SIGN_UP'
      infoTitle={infoTitleValidation}
      IsOwner={isRoleOwner}
    >
      <Suspense fallback={<CircularLoading />}>
        <UserForm
          onSubmit={onCreateUser(history, parseInt(postId))}
          setReadTermAndPolicies={setReadTermAndPolicies}
          readTermAndPolicies={readTermAndPolicies}
          disabled={false}
          fetchingCodes={fetchingCodes}
          countriesCodes={countriesCodes}
          setIncompleteForm={setIncompleteForm}
        >
          <div className='text-center col-12'>
            <button
              className={`col-12 mt-3 ${
                appTangoBackOffice ? 'button-primary' : 'button-secondary'
              }`}
              type='submit'
              disabled={
                (!readTermAndPolicies && !paramsNewRegister) ||
                isFetching ||
                (incompleteForm && !paramsNewRegister)
              }
            >
              {isFetching ? <ButtonLoading /> : intl.get('SIGN_UP')}
            </button>
          </div>
          <div className='error-container-form col-12'>
            {typeof userError === 'string' && <small>{userError}</small>}
          </div>
        </UserForm>
      </Suspense>
      {!signUpInstapage && (
        <div
          className={`col-12 pb-5 text-left ${
            size.width <= 768 && 'font-sm-08 plm-0 mb-2'
          }`}
        >
          <span
            className={`${
              appTangoBackOffice
                ? 'text-color-secondary'
                : 'text-color-black font-weight-bold ml-2'
            }`}
          >
            {intl.get('HAVE_ACCOUNT')}
          </span>

          <span
            className={`ml-2 ${
              appTangoBackOffice
                ? 'link-login'
                : 'link-login-black font-weight-bold'
            }`}
            onClick={() => history.push('/login')}
          >
            {intl.get('SIGN_IN')}
            {intl.get('HERE')}
          </span>
        </div>
      )}
    </CardMountContainerTango>
  ) : (
    <CardMountContainer
      buttonsActionView={buttonActionValidation}
      activeViewLabel='SIGN_UP'
      infoTitle={infoTitleValidation}
      IsOwner={isRoleOwner}
    >
      {(isRoleOwner || isRoleRenter) && (
        <div className='row padding-blocks'>
          {size.width <= 994 && paramsNewRegister !== 'yes' && (
            <div className='mb-4'>
              <div className={'text-color-black'} style={{ paddingTop: '50px'}}>
                {signUpInstapage ? <></> :  <h1>{intl.getHTML(infoTitleValidation)}</h1>}
              </div>
              <div className='template-line' />
            </div>
          )}
          <div className='google-button-container'>
            <GoogleLogin
              onSuccess={responseGoogle}
              onError={() => {}}
            />
          </div>
          <div className='template-line-or-container'>
            <div className='template-line-or-left' />
            <span id='template-line-span' className='text-color-tertiary'>
              {intl.get('OR')}
            </span>
            <div className='template-line-or-right' />
          </div>
        </div>
      )}
      <ReferredCodePopups
        setReferredCodeConfirmation={setReferredCodeConfirmation}
        setReferredCodeManual={setReferredCodeManual}
        setReferredCodeLocalStorage={setReferredCodeLocalStorage}
        setReferredInformation={setReferredInformation}
        referredCodeConfirmation={referredCodeConfirmation}
        referredCodeManual={referredCodeManual}
        referredInformation={referredInformation}
        fullNameNewReference={fullNameNewReference}
        fetchingNewReference={fetchingNewReference}
      />
      {detectMob() && isRoleOwner && (
        <ModalSignUp
          isOwner={isRoleOwner}
          mobile={detectMob()}
          referredCodeConfirmation={referredCodeConfirmation}
          referredCodeManual={referredCodeManual}
          referredInformation={referredInformation}
        />
      )}
      <Suspense fallback={<CircularLoading />}>
        <UserForm
          onSubmit={onCreateUser(history, parseInt(postId))}
          setReadTermAndPolicies={setReadTermAndPolicies}
          readTermAndPolicies={readTermAndPolicies}
          disabled={false}
          fetchingCodes={fetchingCodes}
          countriesCodes={countriesCodes}
          setIncompleteForm={setIncompleteForm}
        >
          <div className='text-center col-12'>
            <button
              className='col-12 mt-3 font-weight-bold button-secondary'
              type='submit'
              disabled={
                (!readTermAndPolicies && !paramsNewRegister) ||
                isFetching ||
                (incompleteForm && !paramsNewRegister)
              }
            >
              {isFetching ? <ButtonLoading /> : intl.get('SIGN_UP')}
            </button>
          </div>
          <div className='error-container-form col-12'>
            {typeof userError === 'string' && <small>{userError}</small>}
          </div>
        </UserForm>
      </Suspense>
      {!signUpInstapage && (
        <div
          className={`col-12 pb-5 text-left ${
            size.width <= 768 && 'font-sm-08 plm-0 mb-2'
          }`}
        >
          <span
            className='text-color-black font-weight-bold ml-2'
          >
            {intl.get('HAVE_ACCOUNT')}
          </span>

          <span
            className='ml-2 link-login-black font-weight-bold'
            onClick={() => history.push('/login')}
          >
            {intl.get('SIGN_IN')}
            {intl.get('HERE')}
          </span>
        </div>
      )}
    </CardMountContainer>
  )
}

const mapStateToProps = (state: any) => {
  const { fullNameNewReference, fetchingNewReference} = state.newReference || {}
  return {
    fetchUser: state.user.fetchUser,
    userError: state.user.error,
    countriesCodes: state.countriesCodes.codes.data,
    fetchingCodes: state.countriesCodes.fetchingCodes,
    fullNameNewReference,
    fetchingNewReference
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreateUser: (history: any, post: number) => (values: any) => {
      return dispatch(createUser(values, history, post))
    },
    onGetCountriesCodes: () => {
      return dispatch(getCountriesCodes())
    },
    onSetGoogleSession: (
      values: any,
      history: any,
      redirectionURL: string,
      isInstapage: boolean
    ) => {
      return dispatch(
        setGoogleSession(values, history, redirectionURL, isInstapage)
      )
    },
    onGetNewReference: (referredCode: string) => {
      return dispatch(getNewReference(referredCode))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm)
