import {
    prioritizePhotosStart,
    prioritizePhotosSuccess,
    prioritizePhotosError,
  } from './types'
  import { putData } from '../../../tango-react-base/helpers/apiConnections'
  import { toast } from 'react-toastify'
  import intl from 'react-intl-universal'
  import { handleError } from '../../../tango-react-base/helpers/handleErrors'

  const prioritizePhotos = (data: any, id: number) => {
      const queryObject = { post: {
          photos_id: data
      } }
    return (dispatch: any) => {
      dispatch(prioritizePhotosStart())
      putData(`posts/${id}/prioritize_photos`, queryObject)
        .then((result) => {
          toast(intl.get('PHOTO_ORDER_SENT'))
          dispatch(prioritizePhotosSuccess())
        })
        .catch((e) => {
          toast(handleError(e))
          return dispatch(prioritizePhotosError(e))
        })
    }
  }
  export default prioritizePhotos
  