export const getRentStateStart = () => {
    return {
        type: 'GET_RENT_STATE_STARTED',
    };
};

export const getRentStateSuccess = (data: any) => {
    return {
        type: 'GET_RENT_STATE_SUCCESS',
        payload: data,
    };
};

export const getRentStateError = (error: any) => {
    return {
        type: 'GET_RENT_STATE_ERROR',
        payload: {
            error,
        },
    };
};
