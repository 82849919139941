import DocumentTermsAndConditions from './documents/es-TermsAndConditions'
import es_DateFormat from './es_DateFormat'
import es_contractStates from './es_contractStates'
import es_fakePaymentMethod from './fake_payment_method/es-ES'

export default {
  ...es_DateFormat,
  ...es_contractStates,
  ...es_fakePaymentMethod,
  undefined: '',
  A_STEP:
    '¡Felicidades! Estás a un paso de completar tu perfil, solo <strong>debes subir tus documentos</strong>. Aunque no es necesario que lo hagas ahora, recuerda que no podrás hacer una propuesta de arriendo hasta que hayas completado este paso.',
  AB: 'AB',
  ABOUT_TO_VETO:
    'Estás a punto de <strong>vetar</strong> a este usuario de poder ser arrendatario en Tango. Esto automáticamente le enviará un correo informándole que no podrá usar los servicios de la plataforma. ¿Confirmas esta acción?',
  ABOUT_US: 'Acerca de nosotros',
  ABOUT_YOURSELF: 'El propietario quiere conocerte, cuéntale más sobre ti',
  ACCEPT_ANIMALS_A: 'Acepta mascoltas',
  ACCEPT_ANIMALS: '¿Acepta mascotas?',
  ACCEPT: 'Aceptar',
  ACCEPTED_OFFERS: '¡Aceptaste una propuesta de arriendo!',
  ACCEPTED: 'Aceptada',
  ACCOUNT_NUMBER: 'Número de cuenta',
  ACCOUNTING_ADMIN: 'Administrador/a contable',
  ACCREDITATION: 'Acreditación',
  ACEPT_FORM:
    'Al hacer click en Enviar, usted acepta que usted es la persona cuya información se ingresó arriba o que usted tiene el consentimiento expreso de esa persona para proporcionarnos su información. Además, usted acepta y reconoce que la información que ingresó arriba será utilizada para responder a su consulta para obtener más información de las ofertas laborales de Tango Rent.  El uso de este sitio web implica la aceptación de los términos y condiciones y las políticas de privacidad de Tango Rent',
  ACTIONS: 'Acciones',
  ACTIVE: 'Activas',
  LISTING_WITH_PROFESSIONAL_PHOTOS_AND_VIDEOS:
    'Tomamos fotos, videos y planos de tu propiedad y creamos tu aviso profesional',
  ADDITIONAL_INFORMATION: 'Información Adicional',
  ADDRESS_INSTRUCTIONS:
    'Seleccione una dirección del listado.</br>Si la numeración de la propiedad comienza con 0, no lo ingrese.',
  ADDRESS: 'Dirección',
  ADMIN: 'Administrador',
  ADMINISTRATION_AND_INSURANCE: 'Administración y Fianza',
  ADMINISTRATION_CONTRACT: 'Contrato de administración',
  ADMIN_VALIDATING_DATA:
    'Un administrador está validando su información actualmente',
  KIND: 'Tipo',
  ADMINISTRATION_FEE: 'Fee de administración',
  ADMINISTRATION_FEE_DISCOUNT: 'Descuento fee de administración',
  ADMINISTRATION_OF_DEPOSIT: 'Administración del depósito de garantía',
  ADMINISTRATION_FEE_NEW_DISCOUNT: 'Descuento fee de administración',
  ADMINISTRATION_FEE_REGISTERED_DISCOUNT: 'Descuento fee de administración',
  OWNER_BROKERAGE_FEE_NEW_DISCOUNT: 'Descuento fee de corretaje owner',
  RENTER_BROKERAGE_FEE_NEW_DISCOUNT: 'Descuento fee de corretaje renter',
  OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT: 'Descuento fee de corretaje owner',
  RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT: 'Descuento fee de corretaje renter',
  ADMINISTRATION: 'Administración',
  ADVANCED_FILTER: 'Filtro avanzado',
  ADVANCED_SEARCH: 'Búsqueda avanzada',
  AFTERNOON: 'Tarde',
  AGENT_DESCRIPTION:
    'Estos son los días y horas que disponibilizas para que un Tanguero de Tango junto a un Arrendatario visite tu propiedad.',
  AGENT_ID: 'Tanguero id',
  AGENT: 'Tanguero',
  ALARM: 'Alarma',
  ALMOST_THERE: '¡Ya falta poco!',
  ALL_RENTERS: 'Arrendatarios Tango',
  ALL_SERVICES_INCLUDED:
    'Todos nuestros servicios están incluidos en tu pago mensual',
  ALONE: 'Solo',
  AMERICAN: 'Americana',
  AMMOUNT_REQUIRED:
    'Lamentablemente, el monto de arriendo es mas alto, puede actualizar su registro para ser reevaluado por nuestro equipo Tango.',
  AMOUNT: 'Monto',
  APPROVED_AMOUNT: 'Monto aprobado para arrendar:',
  AND: 'y',
  ANY_QUESTION_AGENT: 'Si tienes alguna duda, envíame un correo a {agent} ',
  ANY_QUESTION_VERIFIER:
    'Si tienes alguna duda, envíame un correo a {verifier}',
  APARTMENT: 'Departamento',
  APPROVE_PROPOSAL: 'Aprobar propuesta',
  APPROVED: 'Aprobado',
  APPROVED_UNIT: 'Aprobadas',
  APRIL: 'Abril',
  APPROVE: 'Aprobar',
  ARE_YOU_OWNER: '¿Eres el/la dueño/a de la propiedad?',
  AT_LEAST_7: 'Recuerda que tu contraseña debe tener al menos 8 caracteres.',
  AT: 'a las',
  ATTENTION: 'Atención!',
  AUGUST: 'Agosto',
  AUTHORIZED_AMOUNT: 'Monto autorizado',
  AVAILABLE_NOW: '¿Disponibilidad inmediata?',
  AVAILABLE_PROPERTIES: 'Propiedades disponibles',
  AVAILABLE_SCHEDULE:
    'La propiedad tiene una agenda disponible para ser visitada',
  AVAILABLE_VISIT: 'Disponible para tours',
  AVALIABLE_FROM: 'Disponible desde',
  AVERAGE_INCOME_LAST_3: 'Ingreso promedio monto de los últimos 3 meses',
  AVERAGE_SCORE_LAST_12_MONTHS: 'Promedio últimos 12 meses',
  BACK_BTN: '< Volver atrás',
  BACK_HOME: 'Volver al inicio',
  BALCONY: 'Balcón',
  BANK_ACCOUNT_TYPE: 'Tipo de cuenta',
  BANK_ACCOUNT: '¿Tienes cuenta bancaria?',
  BANK_INFORMATION: 'Información bancaria',
  BANK_NAME: 'Banco',
  BARBECUE: 'Quincho/Asadores',
  BASIC_DATA_A: 'Datos básicos',
  BASIC_DATA: 'Datos básicos',
  BASIC_DETAILS: 'Detalles básicos',
  BATHROOM_FLOOR_MATERIALS: 'Baños',
  BATHROOMS_NUMBER: '{num, plural, =0 {Sin baños} =1 {1 baño} other {# baños}}',
  BATHROOMS: 'Baños',
  NUMBER_BATHROOMS: 'Cantidad de baños',
  BE_A_DANCE: 'sea un baile',
  BE_REFERRER: 'Quiero referir a alguien',
  BE_TANGUERO: 'Sé un Tanguero',
  BE_VERIFIER: 'Sé un fotógrafo',
  BEDROOM_FLOOR_MATERIALS: 'Dormitorios',
  BEDROOMS_NUMBER_A:
    '{num, plural, =0 {Estudio} =1 {1 dormitorio} other {# dormitorios}}',
  BEDROOMS_NUMBER: 'Cantidad de dormitorios',
  BEDROOMS: 'Dormitorios',
  BEFORE_CREATING:
    'Antes de crear tu propiedad debes entender bien los servicios de',
  BEFORE_MAKING_OFFER:
    'Antes de hacer una propuesta, debes ir a tu perfil, completar tus datos y subir los documentos requeridos.',
  BETTERUSER_EXPERIENCE:
    "Puedes leer el contrato detalladamente desde tu computador, pero para poder firmarlo debes hacerlo desde un <span class='font-weight-bold'>dispositivo móvil </span> a través del enlace que te enviamos por correo o <span class='font-weight-bold'> escanee este código QR. </span>",
  BICYCLE_ROOM: 'Bicicletero',
  BIRTHDATE: 'Fecha de nacimiento',
  ASSIGNED: 'Asignada',
  BLOCKS_ASSIGNED: 'Los bloques han sido asignados',
  BLOCKS_NO_AVAILABLE: 'No tienes bloques disponibles para ese día',
  BLOCKS_TAKEN: 'Bloque(s) ha(n) sido tomados',
  BLOCKS_TAKEN_UPDATE: 'Bloque(s) ha(n) sido actualizado(s)',
  BOTTOM_LABEL_USABLE_AREA:
    '*Un fotógrafo de Tango comprobará la superficie exacta',
  BUILDING_SITE: 'Este recurso no está disponible',
  BROKERAGE_FEE: 'Fee de éxito',
  BROKERAGE: 'Corretaje',
  BROKERAGE_FEE_OWNER: 'Fee de corretaje',
  BROKERAGE_FEE_DISCOUNT_OWNER: 'Descuento fee de corretaje',
  BROKERAGE_INFO_1:
    'Recuerda, el plan de corretaje solo te permitirá ver los comprobantes asociados al primer pago del arriendo y garantía.',
  BROKERAGE_INFO_2:
    'Recibirás el pago del valor proporcional del arriendo y la garantía, en 10 días hábiles desde el pago del arrendatario.',
  BROKERAGE_INFO_3:
    'No olvides que se descontará el monto a pagar el fee de corretaje según corresponda.',
  BURNERS: 'Quemadores',
  BROKER: 'Tanguero',
  BY: 'por',
  CLP: '$',
  C1A: 'C1a',
  C1B: 'C1b',
  C2: 'C2',
  C3: 'C3',
  CALENDAR_VISIT_INFO:
    'Marca en el calendario tus fechas y horas disponibles para que los propietarios puedan coordinar una visita',
  CALENDAR_VISIT: 'Calendario de visitas',
  CANCEL: 'Cancelar',
  CANCELED: 'Cancelada',
  CANT_OFFER:
    'Debes agendar y hacer un tour antes de enviar una propuesta de arriendo',
  CAPTURE_BACK: '¡Vamos!',
  CARPET: 'Alfombra',
  CREATE_NEW_VISIT: 'Crear nueva visita',
  CARRY_OUT_TOURS:
    'Mostramos tu propiedad vía tours virtuales o presenciales, sin que estés tú',
  CERAMIC: 'Cerámica',
  CHEAPEST_FEE: 'Tenemos el fee más barato del mercado',
  CHECK_LOGO: 'Visto bueno',
  CHECK_YOUR_INBOX_THANK_YOU:
    'Revisa tu <span class="font-weight-bold"> bandeja de entrada </span> y <span class="font-weight-bold"> verifica tu dirección de correo electrónico </span> en el mail que te acabamos de enviar.',

  CHECK_PASS_ERROR: 'Las contraseñas no coinciden',
  CHECK_PASS: 'Error de contraseña, compruebe la contraseña',
  CHECKING_ACCOUNT: 'Corriente',
  CHEKING_ACCOUNT: 'Cuenta corriente',
  CHILDISH_GAMES: 'Juegos infantiles',
  CHOOSE_DATE: 'Selecciona tu fecha de inicio de arriendo',
  CI_BOTH_SIDES: 'Cédula de identidad por ambos lados',
  CINEMA: 'Cine',
  CLEAR_FILTERS: 'Limpiar filtros',
  CLOSE: 'Cerrar',
  CLOSED_CIRCUIT: 'Circuito cerrado',
  CLOSED: 'Cerrada',
  CODE_VALIDATE: 'Código de verificación',
  COLLABORATORS: 'Colaboradores',
  COMMENT_DATE_EMPTY: 'Debe ingresar algún comentario y fecha.',
  COMMISSION_ADMINISTRATION_AND_INSURANCE: '7% de arriendo mensual',
  COMMISSION_BROKERAGE: '50% del arriendo por única vez',
  COMMISSION_EXTRA_INFO:
    'Ambos cobros son descontados del arriendo que recolectamos mensualmente.',
  COMMISSION_WHEN_TO_PAY: '¡Paga sólo cuando tu propiedad haya sido arrendada!',
  COMMISSIONS: 'Comisiones',
  COMMON_EXPENSES_FILTER: 'Gastos comunes',
  COMMON_EXPENSES: 'Gastos comunes',
  COMMON_SPACES: 'Espacios comunes',
  COMMUNES_FOR_THE_MOMENT:
    'Por el momento Tango está disponible sólo en las siguientes ',
  COMMUNES_TITLE: 'Se encuentren en las siguientes comunas:',
  COMMUNES: 'comunas',
  COMPANY_SUPPORTS_US: 'La empresa con mayor experiencia global nos respalda',
  COMPLETE_DATA_OFFER:
    'Debes completar tus datos antes de realizar una propuesta a esta propiedad',
  COMPLETE_DATA_SUCCESS:
    'Has completado tus datos, ahora puedes aceptar o rechazar la propuesta de arriendo',
  COMPLETE_DATA:
    'Debes completar tus datos antes de aceptar o rechazar una propuesta de arriendo',
  COMPLETE_YOUR_PROFILE_WE_NEED:
    'Para completar tu perfil necesitamos que ingreses algunos datos.',
  COMUNITY_TYPE: 'Tipo de comunidad',
  CONDITIONS_DESCRIPTION:
    'El contrato de Corretaje y Administración de Tango es 1 solo (lo llamamos Contrato de Administración) y tiene una condición que es importante que sepas:',
  CONDOMINIUM: 'Condominio',
  CONFIRM_NEW_PASSWORD: 'Confirmar nueva contraseña',
  CONFIRM_PASSWORD: 'Confirma tu contraseña',
  CONFIRMATION: 'Confirmación',
  CONGRATS_YOU_RENTED:
    'Acabas de firmar el contrato de arriendo. Ahora sólo queda hacer el pago de la garantía, comisión y primer mes de arriendo.',
  CONGRATS: '¡Felicidades!',
  CONSENT_CREDIT_INFO: 'Consentimiento uso de información crediticia',
  CONSIGNEE_APPROVAL:
    '{renter}, te ha asignado como Aval y Codeudor Solidario para el arriendo de una propiedad a través de Tango.',
  CONSIGNEE_APPROVAL_REGISTERED:
    '{renter}, nos indicó que figuras como Aval y Codeudor Solidario en su contrato de arriendo de la propiedad en {street_address}.',
  CONSIGNEE_AUTH:
    'AUTORIZO A TANGO A ANALIZAR MI INFORMACIÓN DE INGRESOS ENTREGADAS POR {renter} Y CONSULTAR MIS REPORTES DICOM PLATINUM 360 Y DE ANTECEDENTES TRUORA',
  CONSIGNEE_FORM: 'Información de aval y codeudor solidario',
  CONSIGNEE_STEP_ONE:
    'Para aceptar esta solicitud debes permitirnos analizar tu información de ingresos entregada por {renter} y permitirnos consultar tu reporte Dicom Platinum 360 y de antecedentes de Truora. Además debes enviarnos una imagen por ambos lados de tu cédula de identidad.',
  CONSIGNEE_STEP_TWO:
    'Nota: La siguiente autorización no genera una obligación ni te designa como Aval y Codeudor Solidario/a. Una vez aprobados los documentos te enviaremos el contrato de arriendo completo que para que revises y firmes.',
  CONSIGNEE_STEP_ONE_REGISTERED:
    'El/la propietario/a de este inmueble ingresó el contrato de arriendo en Tango para que lo administremos. Tú no debes preocuparte de nada, simplemente darnos autorización para que analicemos tu información financiera entregada por {renter} y permitirnos consultar tu reporte Dicom Platinum 360 y Truora. Además debes enviarnos una imagen por ambos lados de tu documente de identidad.',
  CONSIGNEE_STEP_TWO_REGISTERED:
    'Nota: La siguiente autorización no genera una obligación adicional a la que ya tienes como Aval y Codeudor Solidario de un contrato de arriendo.',
  CONSIGNEE: 'Aval y codeudor',
  CONSTRUCTION_YEAR: 'Año de construcción',
  CONTINUE: 'Continuar',
  CONTRACT: 'Contrato',
  CONTRACTS: 'Contratos',
  COPY: 'Copiar',
  COSIGNEES_NUMBER: 'Cantidad de avales y codeudores solidarios',
  COSIGNER_INFORMATION: 'Información de aval y codeudor solidario',
  COSIGNER_NAME: 'Nombre de aval y codeudor solidario',
  COSIGNER_NOTIFICATION:
    'Si agregaste un aval y codeudor solidario, cuéntale que le enviaremos un mail, solicitando autorización para revisar su información crediticia.',
  COSIGNER_RUT: 'Rut de aval y codeudor solidario',
  COUNTRIES: 'Países',
  CAN_COMPLETE_YOUR_INFORMATION:
    'A continuación puedes completar tu información y cargar tu documento de identidad',
  COUSIN: 'Primo/a',
  COWORK: 'Cowork/Negocios',
  CREATE_ERROR: 'Error al crear:',
  CREATE_MY_PROPERTY: 'Crear mi propiedad',
  CREATE_NEW_USER: 'Crear perfiles',
  TRANSACTIONS: 'Transacciones',
  CREATE_PROFILE_SCHEDULE_TOURS:
    "Crea tu perfil y agenda tours virtuales o presenciales con nuestros <span class='text-color-secondary'>Tangueros</span>",
  CREATED: 'Creada',
  CRIMINAL_RECORD: 'Antecedentes penales',
  CURRENT_AMOUNT: 'Cantidad actual',
  CURRENT_PASSWORD: 'Contraseña actual',
  CURRENT_SCORE: 'Puntaje actual',
  CURRENTLY_WHO_LIVES:
    'Preguntamos esto para poder facilitar el proceso de tours a la propiedad. Podemos instalar un Tangobox en las propiedades que están vacías para que no tengas que usar tu tiempo para estar en cada tour a la propiedad.',
  CURTAINS: 'Cortinas',
  CV: 'Currículum',
  D: 'D',
  DATA_CONSIGNEE_TITLE: 'Información de tus avales y codeudores solidarios',
  DATA_CONSIGNEE:
    'Recuerda que si vas a firmar con un aval y codeudor solidario, debes completar sus datos para avanzar.',
  DATA_HAS_BEEN_SAVED: 'La información ha sido guardada',
  DATA_SAVED: '¡Datos guardados exitosamente!',
  DATE_FUTURE: 'Por favor, elije una fecha a futuro',
  DATE: 'Fecha',
  DEATHLINE_CONSIGNEE:
    'Ahora sólo necesitamos que nos mandes una foto de tu CI por ambos lados',
  DEBT_UNPAID_DETAIL: 'Detalles de impagos',
  DECEMBER: 'Diciembre',
  DEFAULT_COVERAGE: 'Cobertura de impago y gestión del proceso de desalojo',
  DEFINE_AVAILABILITY: 'Definir disponibilidad para tours',
  DEFINITION_CONSIGNE:
    'Si vas a firmar con un aval y codeudor solidario ve a la pestaña de aval y codeudor solidario en la parte superior.',
  DEFINITION_PET:
    'La definición de mascotas según Tango corresponde a perros, gatos o animales enjaulados que no emiten ruidos ni olores molestos, y que no representen un peligro para la comunidad.',
  DELETE_DOCUMENT: 'Borrar documento',
  DELETE_IMAGE: 'Eliminar imagen',
  DELETE_UPDATE: 'No estoy conforme. Actualizar',
  DELIVERED: 'Entregadas',
  DELIVERY: 'Entrega',
  DELIVERY_AND_REPLACEMENT_HELP:
    "Te ayudamos con la <span class='text-color-secondary'>entrega y reposición</span> de la propiedad",
  DENIED: 'Rechazadas',
  DEPENDENT: 'Dependiente',
  DESCRIPTION_ADMIN_AND_INSURANCE:
    "<p>Recolectamos el pago de arriendo a través de nuestra plataforma.</p> <p>Además te entregamos una fianza en la que <span class='text-color-secondary'>cubrimos el pago del arriendo por hasta 6 meses </span> de arriendo en caso de que el arrendatario no pague, y nos encargamos de todos los procesos legales en caso de desalojo.</p>",
  DESCRIPTION_ADMINISTRATION:
    'Listo...Tras este proceso deja la propiedad en manos de Tango!',
  DESCRIPTION_BROKERAGE:
    "Publicamos, mostramos, arrendamos y entregamos tu propiedad! Revisamos la capacidad de pago y perfil de los posibles arrendatarios. Además el proceso de <span class='text-color-secondary'>firma de contrato es 100% online</span>",
  DESCRIPTION_CREATE_PROPERTY:
    "Crea la propiedad en Tango en <span class='text-color-secondary'>5 minutos</span> entregándonos detalles básicos de tu propiedad y sin la necesidad de subir fotos.",
  DESCRIPTION_PROPOSALS:
    "<p>Recibirás las propuestas de arriendo a través de nuestra plataforma y podrás ver la <span class='text-color-secondary'>información general del arrendatario/a</span>. Además te daremos un puntaje Tango para que puedas tomar una mejor decisión.</p><p>Recuerda que todos los arrendatarios aprobados en Tango pasan por un profundo análisis de crédito.</p>",
  CONTRACT_TERM: 'La duración del contrato de arriendo es de',
  NUMBER_OF_MONTHS_CONTRACT: '12 meses',
  DESCRIPTION_PUBLISH_PROPERTY:
    "Nuestro fotógrafo va a subir toda la información de la propiedad en <span class='text-color-secondary'>menos de 48 horas </span>y tu propiedad será publicada.",
  DESCRIPTION_SIGNATURE:
    "Revisa y <span class='text-color-secondary'>firma el contrato</span> de Arriendo y el contrato de Administración con <span class='text-color-secondary'>Tango online</span>.",
  DESCRIPTION_VERIFY_INFO:
    "<p>Agenda a través de nuestra plataforma una <span class='text-color-secondary'>visita de un fotógrafo</span> para que vaya a tu propiedad y junte toda la información necesaria, saque fotos/videos y cree una publicación completa y atractiva de tu propiedad.</p><p>El fotógrafo también va a instalar un <span class='font-weight-bold tangobox' data-tooltip='El Tangobox es una caja de seguridad que contiene las llaves y se pone afuera de tu propiedad para poder coordinar los tours sin la necesidad de que tu vayas'>Tangobox</span> en tu propiedad siempre y cuando esta esté vacía (sin muebles ni arrendatarios).</p>",
  DESCRIPTION_VISITS:
    "<p>Nuestros tangueros van a dirigir todos los tours a la propiedad, independiente de si la propiedad tiene Tangobox o no.</p><p>En caso de que la propiedad no tenga Tangobox deberás indicar tu disponibilidad de tiempo a través de nuestra plataforma para que los arrendatarios puedan <span class='text-color-secondary'>agendar tours online</span>. Siempre que haya un tour serás notificado/a.</p>",
  DESCRIPTION_PROPERTY: '¿Qué hace a esta propiedad única?',
  DETAILS: 'Detalles',
  DINING_ROOM: 'Comedor de diario',
  DISCOUNT:
    'Recibe 10% del primer mes de arriendo una vez que se arriende la propiedad referida.',
  DISCOVER_EVERY_DETAIL: 'Descubre cada detalle de tu próximo hogar',
  DISCOVER_MORE: 'Descubre más',
  DISH_WASHER: 'Lavavajillas',
  DIVORCED: 'Divorciado/a',
  DO_YOU_KNOW_A_RENTER: '¿Conoces a alguien que quiera arrendar su propiedad?',
  DO_YOU_SOMEONE_WHO_HAS_A_PROPERTY:
    '¿Conoces a alguien que quiera arrendar su propiedad?',
  DOC_APPROVED: 'Documentos aprobados',
  DOC_PENDING: 'Documentos por aprobar',
  DOCUMENT_1_INFO:
    'Puedes obtenerlo siguiendo estos pasos: www.sii.cl / Mi SII / Mis Bienes Raíces / [Anotar el Rol de la vivienda] / Ir al menú para pagar contribuciones o hacer otras consultas / Consulta avalúos y certificados / Consultar antecedentes de un bien raíz / [Acá hay que ingresar Rol] / Buscar / [Imprimir en PDF]',
  DOCUMENT_1: 'Comprobante de propiedad',
  DOCUMENT_2_INFO: 'Puedes subir una foto de la colilla',
  DOCUMENT_2: 'Colilla de cobro de contribuciones del SII',
  DOCUMENT_3_INFO:
    'Debes descargar e imprimir el archivo, completar todos los campos y subir una foto.',
  DOCUMENT_3: 'Poder simple',
  DOCUMENT_APPROVED_ADMIN: 'Documento aprobado, ya no puede ser eliminado',
  DOCUMENT_APPROVED: 'Documento aprobado',
  DOCUMENT_CHILE: '¿Tienes documentación vigente para trabajar en Chile?',
  DOCUMENT_DELETED: 'Documento(s) eliminado(s) exitosamente',
  DOCUMENT_REJECTED: 'Documento rechazado',
  DOCUMENT_SENT: 'Tus documentos fueron enviados',
  DOCUMENT_SUCCESSFULLY_UPLOADED: 'Documento subido exitosamente',
  DOCUMENT_TERMS_AND_CONDITIONS: DocumentTermsAndConditions,
  DOCUMENTS: 'Documentos',
  PROPERTY_DOCUMENTATION: 'Documentación de la propiedad',
  DONT_CONSIGNEE: 'No tienes avales y codeudores solidarios',
  DONT_HAVE_ACCOUNT: '¿No tienes una cuenta?',
  DONT_HAVE_AVAILABLE: 'Aún no tienes propiedades disponibles',
  DONT_HAVE_PENDING: 'Aún no tienes propiedades por publicar',
  DONT_HAVE_RENTED: 'Aún no tienes propiedades arrendadas',
  DONT_HAVE_SECOND_SURNAME: 'No tengo un segundo apellido',
  DONT_HAVE_VISIT: 'No tienes tours',
  DONT_NEED_GUARANTOR: 'No necesitas aval',
  DONT_PAY_UNTIL_2: 'No pagas hasta que tu propiedad se arriende',
  DONT_PAY_UNTIL: '¡No pagas hasta que tu propiedad se arriende!',
  DONT_SLEEP: '¡No te duermas!',
  DOOR_BELL: 'Citófono',
  DOWNLOAD_CONTRACT: 'Descargar contrato',
  DOWNLOAD_DOCUMENT: 'Descargar documento',
  DOWNLOAD_RECEIPT: 'Descargar comprobante',
  DOWNLOAD_FILE: 'Descargar archivo',
  DOWNLOADED_FILE: 'Archivo descargado',
  DROP_FILE: 'Arrastra tu archivo',
  DROP_IMAGES_HERE:
    'Arrastra tus imágenes o haz click aquí y selecciona los archivos',
  DROP_VIDEOS_HERE:
    'Arrastra tus videos o haz click aquí y selecciona los archivos',
  DRY_MACHINE: 'Secadora',
  DUE_DATE: 'Fecha de vencimiento',
  DUPLEX: 'Dos pisos',
  E: 'E',
  EAST: 'Este',
  EASY: ' ¡Así de fácil!',
  EDIT_PROFILE: 'Editar datos',
  EDIT: 'Editar',
  ELECTRIC: 'Eléctrica',
  ELECTRICITY: 'luz',
  ELEVATOR: 'Ascensor',
  EMAIL_ERROR_FORGETPASS:
    'Error de correo electrónico, compruebe su correo electrónico',
  EMAIL_PATTERN_ERROR: 'Ingresa un correo electrónico válido',
  EMAIL_SENT: 'El correo ha sido enviado',
  EMAIL_TANGO: 'ayuda@tango.rent',
  EMAIL: 'Correo electrónico',
  EXACT_CURRENT_ADDRESS: 'Dirección actual exacta',
  ENTER_INFORMATION_EXTRA_ADDRESS: 'Opcional',
  EMPLOYEE: 'Trabajador/a',
  ENABLE_RENTER: 'Habilitar para arrendar',
  END: 'Fin',
  ENTER_COMMUNE_CITY: 'Ingresa comuna o ciudad',
  ENTER_IT_HERE: 'Ingrésalo aquí',
  ENTER_NEW_PASSWORD:
    'Ingresa tu nueva contraseña. <br/> Asegúrate de que los campos coincidan.',
  EQUIPMENT: 'Equipamiento',
  ERROR_404: 'Error 404',
  ERROR_ACTION: 'Error, no puedes realizar esta acción',
  ERROR_SENDING_EMAIL: 'Error al enviar correo',
  ERROR: 'Error',
  EVALUATE_OFFERS: 'Evalúa tus propuestas',
  EVERCLEAN_INDICATOR: 'Indicador Everclear',
  EXAMPLE_EMAIL: 'Correo@example.com',
  EXCLUSIVITY_1:
    'En Tango ponemos mucho esfuerzo en crear una publicación de alta calidad para que tu propiedad se arriende lo mas rápido posible.',
  EXCLUSIVITY_2:
    "Es por eso que requerimos tener exclusividad para arrendar tu propiedad durante <span class='text-color-secondary'>3 meses</span>.",
  EXCLUSIVITY_3:
    'Si no logramos arrendar tu propiedad en este periodo perdemos el derecho a esa exclusividad.',
  EXCLUSIVITY: 'Exclusividad',
  EXPERIENCE_DIFFERENCE: 'Experience the Tango difference',
  EXPERIENCED_TEAM:
    'Nuestro equipo con la mayor experiencia del mercado estará respaldándote en todo momento',
  EXTENDED_FAMILY: 'Familia extendida',
  FAMILY_GROUP: 'Grupo familiar',
  FAMILY_WITH_CHILDREN: 'Familia con hijos',
  FAMILY_WITHOUT_CHILDREN: 'Familia sin hijos',
  FEATURED_PROPERTIES: 'Propiedades destacadas en Tango',
  FEATURED: 'Featured Properties',
  FEBRUARY: 'Febrero',
  FILE_DELETED: 'Archivo eliminado',
  FILE_UPLOADED_TO_CONTRACT:
    '¡Archivo subido! Serás automaticamente redireccionado al contrato',
  FILE_UPLOADED_TO_POST:
    '¡Archivo subido! Serás redireccionado a la propiedad.',
  FILE_UPLOADED_TO_CALENDAR:
    '¡Archivo subido! Serás redireccionado a la agenda.',
  FILE_UPLOADED_TO_PENDING:
    '¡Archivo subido! Serás redireccionado a las propiedades por publicar.',
  FILE_UPLOADED: '¡Archivo subido!',
  FILE_UPDATED: '¡Archivo actualizado!',
  FILL_OUT_FORM_REFERRED: 'Llena el formulario con los datos de tu referido',
  FILTER_BY: 'Filtrar por',
  FILTER: 'Filtrar',
  FIND_BY: 'Buscar por',
  FIND_YOUR_NEW_HOME: 'Encuentra tu nuevo hogar',
  FINISH_HOUR: 'Hora de término',
  FINISH_VISIT: 'Finalizar visita',
  FIRST_SURNAME: 'Apellido paterno',
  SURNAMES: 'Apellidos',
  FLOATING_FLOOR: 'Piso flotante',
  FLOOR_A: 'Piso',
  FLOOR_COUNT_APARTMENT: '¿En qué piso está?',
  FLOOR_LOCATED: 'Ubicado en el piso',
  FLOOR_MATERIALS: 'Materiales de piso',
  FLOORS_A: 'Pisos',
  FLOORS_COUNT: '¿Cuántos pisos tiene?',
  FLOORS: 'Pisos',
  FOLLOW_SIGNING_STEPS: 'Sigue estos pasos para firmar el contrato',
  FORGET_ABOUT_COMPLICATED: 'Olvídate del complicado proceso de renta.',
  FORGET_PASSWORD: 'Contraseña olvidada',
  FORGOT_PASS: '¿Olvidaste tu contraseña?',
  FORGOT_PASSWORD_INSTRUCTIONS_TOKEN: 'Revisa tu bandeja de entrada',
  FORGOT_PASSWORD_INSTRUCTIONS_TOKEN_INFO:
    'Hemos envíado un correo electrónico para que reestablezcas tu contraseña.',
  FORGOT_PASSWORD_INSTRUCTIONS:
    'Ingresa tu correo electrónico y te enviaremos un código para reestablecer tu contraseña.',
  FORM_INFO:
    'Completa el siguiente formulario para dejar registro de tus datos',
  FORM_READY: 'Formulario completado',
  FORM: 'Formulario',
  FRIDAY: 'Viernes',
  FRIDGE: 'Refrigerador',
  FRIEND: 'Amigo/a',
  FROM: 'Desde',
  FULL_ADDRESS: 'Dirección completa',
  FULLDAY: '24/7',
  FURNISHED: 'Amoblado',
  GARDEN: 'Jardín',
  GAS: 'Gas',
  GLOBAL_MARKETS: 'Mercados globales',
  GO_TO_DOCUMENTS: '¡Vamos!',
  GO_TO_RENTER_PROFILE: 'Ir a mi perfil',
  NEW_RENTER_PROFILE_1:
    'El siguiente paso para que tu arriendo se pague a través de Tango es que llenes tu <span class="font-weight-bold"> Perfil de Arrendatario</span>',
  NEW_RENTER_PROFILE_2:
    'Para esto vamos a necesitar que nos des información tuya como <span class="color-secondary">arrendatario/a</span> y de los <span class="color-secondary"> avales y codeudores solidarios</span> que aparecen en el contrato de arriendo.',
  GO_RENTER_PROFILE: 'Ir al Perfil de Arrendatario',
  GO_TO_PROPERTY: 'Volver a la propiedad',
  GRANDPARENTS: 'Abuelo/a',
  GUARANTEE_AND_CODEBTOR_PLURAL: 'Avales',
  GUARANTEE_AND_CODEBTOR_SINGULAR: 'Aval',
  GYM: 'Gimnasio',
  HAS_PET: '¿Tienes mascota?',
  HAVE_READ_CONTRACT_AND_INFO:
    'Declaro que he leído el contrato y revisado que la información personal, datos del inmueble y condiciones están correctos',
  HEATING: 'Calefacción',
  HEIGHT_FLOOR_CEILING: 'Altura del piso a techo',
  HELLO: 'Hola',
  HELP_CENTER: 'Centro de ayuda',
  HELP_YOU_RENT_HOME_1: 'Arrendamos y administramos tu propiedad',
  HELP_YOU_RENT_HOME_2: 'en simples pasos',
  HELP: 'Ayuda',
  HOME_APPLIANCES: 'Electrodomésticos',
  INVALID_FIELDS: 'Te faltan llenar datos y/o tienes datos no validos',
  INVALID_FORMAT: 'Formato no válido',
  HOUR_NOTIFICATION:
    'En las próximas 48 horas te notificaremos una vez que hayas sido aprobado como arrendatario en Tango.',
  HOURS_CONTRACT_SIGNING_COSIGNERS:
    'Recuerda que <strong>tienes únicamente 48 horas</strong> para que tú y todas las partes firmen y realices el primer pago, ¿Qué esperas?',
  HOURS_CONTRACT_SIGNING:
    'Recuerda que <strong>tienes únicamente 48 horas</strong> para firmar el contrato de administración, </br>¿Qué esperas?',
  HOURS_CONTRACT:
    'Recuerda que <strong>tienes únicamente 48 horas</strong> para firmar el contrato de arrendamiento, </br>¿Qué esperas?',
  HOURS_SCHEDULED: 'Se ha guardado',
  HOUSE: 'Casa',
  HOW_DOES_TANGO_WORK: '¿Cómo funciona Tango?',
  HOW_TANGO_WORKS:
    "<span class='text-color-secondary'>¿</span>Cómo funciona Tango<span class='text-color-secondary'>?</span>",
  I_AGREE: 'Entendido',
  I: 'Yo',
  INFORM_WHEN_COSIGNERS_SIGN: 'Te informaremos cuando todos firmen para que puedas realizar el pago.',
  OWNER_BROKERAGE_FEE: 'Fee de corretaje owner',
  RENTER_BROKERAGE_FEE: 'Fee de corretaje renter',
  BROKERAGE_INFO: 'Este es el % de cobro de comisión en el corretaje de la propiedad',
  ID_DOCUMENTS_COMPLETED: 'Documentos de identidad completados',
  ID_REQUIRED_INFO_MOBILE:
    'Para poder hacer un tour a una propiedad, primero debes subir tu documento de identidad.',
  ID_REQUIRED_INFO:
    "Para poder arrendar esta propiedad, primero debes subir tu carnet de identidad. En el menú de navegación accede a <strong>'Mi cuenta'</strong>, sección <strong>'Documento de identidad'</strong>.",
  ID_DATA_REQUIRED_INFO:
    "Para continuar con el proceso de evaluación, primero debes subir tu carnet de identidad. En la sección <strong>'Documento de identidad'</strong>.",
  ID_REQUIRED:
    "<p class='text-color-secondary mt-2'>Recuerda hacerlo desde un dispositivo móvil</p>",
  IDECLARE:
    'Declaro que no tengo una relación cercana, ya sea familiar o de otra índole, con el arrendatario ni con el o los avales y codeudores solidarios.',
  IDENTITY_CARD_BACK: 'Parte posterior',
  IDENTITY_CARD_FRONT: 'Parte frontal',
  IDENTITY_DOCUMENT: 'Tu documento de identidad',
  IDENTITY_DOCUMENT_TITLE: 'Documento de identidad',
  IDENTITY_VERIFIED: 'Tu identidad fue verificada',
  IDK_MEASUREMENTS:
    'Si no conoces medidas exactas, no te preocupes, el fotógrafo las verificará en la visita',
  IF_OWNER: 'Si eres propietario',
  IF_RENTER: 'Si eres arrendatario',
  INCLUDED: 'Incluidos',
  INCLUDES_BASIC_SERVICES: '¿El precio incluye luz, agua y gas?',
  INCLUDES_COMMON_EXPENSES: '¿Valor incluye gastos comunes?',
  INCORRECT_INFO: 'En caso de que algún dato no sea correcto, escríbenos a ',
  INDEPENDENT: 'Independiente',
  INDEPENTENT: 'Independiente',
  INDOOR_POOL: 'Piscina interior',
  INFO_BACK_SIDE: 'Ahora toca capturar la parte posterior de tu documento.',
  INFO_BASIC_SERVICES: 'Si no incluye ninguno, dejar en blanco',
  INFO_BATHROOM: 'No incluye baño de servicio',
  INFO_BEDROOM: 'No incluye dormitorio de servicio',
  INFO_CONTACT: 'Información de contacto',
  INFO_COSINGER:
    'El aval y codeudor solidario avala la obligación de pago de arriendo, de modo que si el deudor principal no paga el acreedor puede cobrar la totalidad de la deuda y no sólo una parte de esta al Aval y Codeudor Solidario',
  INFO_PROFILE:
    'Trabajadores dependientes: últimas 3 liquidaciones salariales o certificado de cotizaciones de AFP últimos 3 meses Trabajadores independientes: Certificado de cotizaciones de AFP de los últimos 6 meses o facturas de servicios del último mes + extractos bancarios',
  INFO_SERVICES: 'Dormitorio y baño de servicio',
  INFO_UPLOAD_FILES_OWNER:
    'Adjunta el siguiente documento para validar la propiedad del inmueble. El archivo puede ser en formato PDF, JPG o PNG.',
  INFO_UPLOAD_FILES:
    'Adjunta los siguientes documentos para validar la propiedad del inmueble. Los formatos admitidos son PDF, JPG o PNG. En caso de tener tus documentos en un solo archivo te recomendamos subirlo en PDF',
  INFORMAL: 'Informal',
  INFORMATION_UPDATED: 'Información actualizada',
  INSERT_CODE: 'Ingresa el código de verificación que hemos enviado al correo.',
  INSERT_MORE_CHARACTERS: 'Debe insertar más de 10 caracteres',
  INSERT_VALID_MAIL: 'Inserta un email válido',
  INVALID_ADDRESS:
    'Dirección inválida. Debes ingresar una dirección con número.',
  INVALID_ADDRESS_GENERIC: 'Dirección inválida.',
  INVALID_MARITAL_STATUS: 'Estado civil inválido.',
  INVALID_COMMUNE: 'Por el momento Tango no está disponible en esta comuna',
  INVALID_RUT: 'Rut inválido',
  INVENTORY_APPROVED: 'Acta aprobada',
  INVENTORY_NOT_UPLOADED: 'Acta no subida por Tanguero',
  INVENTORY_UPLOADED: 'Acta subida exitosamente',
  IS_EMPTY: 'Está vacío',
  IS_OWNER:
    'Si no eres dueño de la propiedad, se te solicitará un poder simple a nombre del dueño',
  IS_REQUIRED: 'Es requerido',
  IVA: 'IVA', 
  JANITOR_PHONE_NUMBER: 'Número de teléfono de conserjería',
  JANITOR: 'Conserje',
  JANUARY: 'Enero',
  JOB_NEWSLETTER: 'Boletín laboral',
  JOB_OPPORTUNITY: '¿Estás en búsqueda de una oportunidad laboral?',
  JULY: 'Julio',
  JUNE: 'Junio',
  KITCHEN_FLOOR_MATERIALS: 'Cocina',
  KITCHEN_OPTION: 'Cocina',
  LABEL_INNER_AREA: 'Superficie interior',
  LABEL_TERRACE_AREA: 'Superficie de terraza',
  LABEL_TERRAIN_AREA: 'Superficie Terreno',
  LABEL_TOTAL_AREA: 'Superficie total',
  LABEL_AREA: 'Superficie',
  LABEL_VALUE: 'Valor arriendo',
  LAST_AMOUNT: 'Last amount',
  LAST_OFFER:
    '¡Estás a punto de enviar tu <span class="text-danger">última</span> propuesta de arriendo!',
  LAST_PAYMENT_AMOUNT: 'Monto último impago',
  LAST_PAYMENT_DEBT_TYPE: 'Tipo de deuda último impago',
  LAST_PAYMENT_DUE: 'Vencimiento último impago',
  LAUNDRY_ROOM: 'Logia',
  LAUNDRY: 'Logia',
  LAWSUITS: '¿Demandas en poder judicial?',
  LEASE_CONTRACT: 'Contrato de arriendo',
  LEAVE_PROPERTY_TO_US:
    'Deja tu propiedad en nuestras manos y paga una vez que se arriende',
  LETS_START:
    "<span class='text-color-secondary'>¡</span>Comencemos<span class='text-color-secondary'>!</span>",
  LETS_START_1:
    "<span class='text-color-secondary'>¡</span>Empecemos<span class='text-color-secondary'>!</span>",
  LIST: 'Lista',
  LISTING: 'Precios',
  LIVE_IN_PARTNER: 'Conviviente civil',
  LIVE_IN_THE_PROPERTY:
    '¿El aval y codeudor solidario va a vivir en el inmueble?',
  LIVING_FLOOR_MATERIALS: 'Living/Comedor',
  LIVING_ROOM: 'Living/Comedor',
  LOADING: 'Cargando...',
  LOGIN_ERROR:
    'Error de inicio de sesión, verifique su correo electrónico y contraseña',
  TOKEN_EXPIRED: 'Tu código de verificación caducó, solicítalo nuevamente ',
  LOGIN_1: 'Inicia sesión',
  LOGIN: 'Ingresar',
  LOGOUT: 'Cerrar sesión',
  MAIN_AREA: 'Área principal',
  MAIN_FEATURES: 'Características Principales',
  MAP: 'Mapa',
  MARCH: 'Marzo',
  MARITAL_STATUS: 'Estado Civil',
  SELECT_MARITAL_STATUS: 'Selecciona tu estado civil',
  MARRIED: 'Casado/a',
  MAX_CAPACITY: 'Máxima capacidad',
  MAX_SCORE_NUMBER: 'El número debe ser menor al ingresado',
  MAX_TWO_HOURS:
    'Solo puedes disponibilizar tus horarios en un maximo de <span class="text-color-secondary"> 2 semanas</span>',
  MAY: 'Mayo',
  MESSAGE_SENT_TO_OWNER: 'Mensaje enviado al propietario',
  MESSAGES: 'Mensajes',
  MICROWAVE: 'Microondas',
  MIN_CHARS_PASSWORD: 'Usa al menos 8 caracteres',
  MIN_SCORE_NUMBER: 'El número debe ser igual o mayor a 0',
  MINIMUM_AUTHORIZED_AMOUNT: 'El monto mínimo para autorizar es $200.000',
  MINIMUM_PRICE:
    'El valor del arriendo mínimo en Tango es de <strong> $200.000 {priceInCLF}</strong>',
  MINIMUM_RENT_TITLE: 'El arriendo mensual tenga un valor mínimo de:',
  MISSING_DOCUMENTS: '¡Tienes documentos pendientes por enviar!',
  MONDAY: 'Lunes',
  MONTHLY_RENT_PAYMENT: 'Pago de renta mensual los días 10 de cada mes',
  MONTHLY_RENT_VALUE: 'Del valor del arriendo mensual',
  MORE_BEDROOMS_IN_SUITE:
    'No puedes tener más habitaciones en suite que el total',
  MORNING: 'Mañana',
  MULTIUSE_ROOM: 'Sala multiuso',
  MUST_BE_OVER_18: 'Debes tener más de 18 años',
  MY_ACCOUNT: 'Mi cuenta',
  MY_CURRENT_LEASE: 'Mi arriendo actual',
  MUST_SEE_DOCUMENT_BEFORE_VALIDATE:
    'Debes visualizar el documento antes de validar',
  DELIVERS: 'Entregas',
  RESTITUTIONS: 'Restituciones',
  MY_DOCUMENTS: 'Mis documentos',
  MY_BANK: 'Datos bancarios',
  MY_PROFILE: 'Perfil',
  MY_PROPERTIES: 'Mis propiedades',
  MY_VISITS: 'Mis visitas',
  NEW_VISIT_DATA: 'Datos nueva visita',
  NAME: 'Nombre',
  USERS: 'Usuarios',
  USERNAME: 'Nombre del usuario',
  NAMES: 'Nombres',
  NATIONALITY: 'Nacionalidad',
  NEW: 'Full',
  NEW_PASSWORD: 'Nueva contraseña',
  NEW_UNIT_INFO:
    'Ingresa los datos de tu propiedad para ser <br> verificados por el equipo de Tango.',
  NEW_UNIT_TITLE: 'Nueva propiedad',
  NEW_UPLOAD_PHOTOS: 'Nuevas fotos cargadas',
  NEW_UPLOAD_VIDEOS: 'Nuevos videos cargados',
  NEW_USER: 'Crea un nuevo usuario',
  NIECE_NEPHEW: 'Sobrino/a',
  NIGHT: 'Noche',
  NO_CHARGES: 'No hay pagos por realizar',
  NO_CONCIERGE: 'Sin conserje',
  NO_CONTRACTS: 'Sin contratos activos',
  NO_DOCUMENT: 'No hay documentos',
  NO_HEATING: 'Sin calefacción',
  NO_PAYMENT: 'No tienes historial de pagos',
  NO_PAYMENTS: 'No existen pagos',
  NO_PHOTOS: 'Sin fotos',
  NO_POSTS: 'No tienes publicaciones',
  NO_PROPERTY_SECTION: 'No hay propiedades en esta sección',
  NO_PROPERTY: 'No tienes propuestas de arriendo',
  NO_RENTERS: 'No existen arrendatarios',
  NO_RESULTS_MATCH:
    'Por el momento no tenemos propiedades disponibles en esta comuna. Puedes obtener mayores resultados consultando otras comunas',
  NO: 'No',
  NON_PAYMENT_ORDERS: 'Órdenes de no pago',
  NORTH: 'Norte',
  NOT_AVAILABLE_BLOCKS: 'Bloques no disponibles',
  NOT_AVAILABLE_DAY: 'No hay horarios disponibles para este día',
  NOT_AVAILABLE: 'No disponible',
  NOT_COMMON_SPACES: 'La propiedad no tiene espacios comunes',
  NOT_EMPLOYED: 'No empleado',
  NOT_HOME_APPLIANCES: 'La propiedad no tiene electrodomésticos',
  NOT_PAID: 'No se pagan',
  NOT_RENT: 'Aún no has arrendado una propiedad en Tango.',
  NOT_VALIDATED: 'No validado',
  NOVEMBER: 'Noviembre',
  NOW_CREATE_PROPERTY: 'Ahora ya puedes crear tu propiedad en',
  NOW_SCHEDULE_WITH_VERIFIER:
    'Ahora <strong>debes agendar una hora</strong> con un fotógrafo para completar el proceso de publicación.',
  NOW_SHARE_CODE: 'El código ha sido copiado. ¡Ya puedes compartirlo!',
  NUMBER_ACCOUNT_INFO: 'Aquí es donde se pagará tu arriendo',
  NUMBER_ACCOUNT_INFO_V2:
    'Esta cuenta se utilizará para pagarte (arriendo/referencia)',
  OCTOBER: 'Octubre',
  OCUPATION: 'Profesión',
  OFFER_ACCEPTED: 'Propuesta aceptada',
  OFFER_ACCEPTED_MODAL: 'Has aprobado la propuesta con éxito. Ahora puedes ir a firmar el contrato de administración y arriendo.',
  OFFER_ACEPTED:
    'La propuesta ha sido aceptada. El siguiente paso es <strong>firmar digitalmente el contrato de arriendo y administración</strong>.</br> Tienes 48 horas para hacerlo, solo necesitas tu documento de identidad y ¡listo!',
  OFFER_CANCELED: 'Propuesta cancelada',
  OFFER_DETAILS: 'Detalles de propuesta',
  OFFER_MADE: '¡Propuesta realizada con éxito!',
  OFFER_PLACEHOLDER:
    'Cuéntale sobre quién eres, con quien vas a vivir en la propiedad, en qué trabajas, porqué te cambias de casa, o ¡Lo que quieras!',
  OFFER_RECEIVED: 'Propuesta recibida',
  OFFER_REJECTED:
    'La propuesta ha sido rechazada. Tu propiedad seguirá disponible en Tango.',
  OFFER_TITLE_INFO:
    'La propiedad seguirá disponible hasta que ambas partes hayan firmado.  Todos los contratos de arriendo firmados a través de Tango tienen un plazo de 12 meses renovables.',
  OFFER_TITLE:
    '¡Estás a punto de enviar una propuesta de arriendo! <br> <small class="text-danger">Ten en cuenta que sólo puedes hacer 3 propuestas. <span class="font-weight-bold text-dark">Te quedan: {valueCount}</span></small>',
  ON_DAY: 'hrs el día',
  ONE_FLOOR: 'Un piso',
  ONE_MONTH_RENT: '50% del arriendo por única vez',
  ONE_STEP_MISSING: '¡Falta un paso!',
  ONLINE_CONTRACT: 'Firma online del contrato',
  ONLINE_CONTROL:
    'Panel de control online para revisión de solicitudes y pagos',
  ONLNE_PAYMENT: 'Tus pagos son en línea, con tarjeta de crédito o débito',
  ONLY_BEDROOMS: 'Sólo dormitorios',
  ONLY_DAY: 'Sólo día',
  ONLY_NIGHT: 'Sólo noche',
  ONLY_TWO_IMAGES_ALLOWED: 'Deberías enviar sólo 2 imágenes',
  OPEN_AMERICAN: 'Abierta / Americana',
  OPEN_MOBILE_ID: 'Este paso debes hacerlo desde tu dispositivo móvil.',
  OPEN_MOBILE_ID_QR:
    'Escanea este código QR para continuar con el proceso desde su dispositivo móvil.',
  OPEN_MOBILE: 'Abre esta página en un dispositivo móvil.',
  OPEN_MOBILE_QR:
    "Abre esta página en un dispositivo móvil, <span class='font-weight-bold'>escaneando este código QR.</span>",
  OPERATIONS_ADMIN: 'Administrador/a de operaciones',
  OR: 'o',
  ORDER_BY_HIGHEST: 'Precio más alto',
  ORDER_BY_LOWEST: 'Precio más bajo',
  ORDER_BY_MOST_RECENT: 'Más recientes',
  ORDER_BY_OLDEST: 'Menos recientes',
  ORDER_BY: 'Ordenar por',
  ORIENTATION: 'Orientación',
  OTHER_CONDITIONS: 'Otras condiciones',
  OTHER_PROOF_OF_INCOME_1: 'Otra prueba de ingresos 1',
  OTHER_PROOF_OF_INCOME_2: 'Otra prueba de ingresos 2',
  OTHER: 'Otra',
  OTHERS: 'Espacios comunes',
  OUR_PLATFORM_IS_DIGITAL:
    'Nuestra plataforma es 100% digital para que puedas arrendar sin moverte de donde estés',
  OUR_SERVICES_GUARANTEE:
    'Nuestros servicios garantizan la seguridad de propietarios y arrendatarios durante todo el proceso de arriendo',
  OUTDOOR_POOL: 'Piscina exterior',
  OVEN: 'Horno',
  OWNER_DOCUMENT: 'Poder simple',
  OWNER: 'Propietario',
  PAGE_NOT_FOUND: 'Página no encontrada',
  PAID_SEPARATELY: 'Se pagan aparte',
  PARENT_IN_LAW: 'Padrastro/Madrastra',
  PARENT: 'Padre/Madre',
  PARKING_LOT_NUMBER: 'Número/Letra',
  PARKING_LOT: 'Estacionamientos',
  PARKING_SPACES: 'Estacionamientos',
  PARQUET: 'Parquet',
  PARTNER_RELATIONSHIPS: 'Relaciones como socio',
  PASSWORD: 'Contraseña',
  PAY_RENT: 'Paga tu arriendo',
  PAY: 'Pagar',
  PAYMENT_BUTTON: 'Ver factura',
  GROSS_PAY: 'Pago bruto',
  SUCCESS_FEE: 'Fee de éxito',
  SUCCESS_FEE_DISCOUNT: 'Descuento fee de éxito',
  COLLECTION_FEE: 'Fee de corretaje',
  COLLECTION_FEE_DISCOUNT: 'Descuento fee de corretaje',
  PAYMENT_DATE: 'Fecha de pago',
  PAYMENT_DETAIL: 'Detalle de pago',
  PAYMENT_HISTORY: 'Historial de pagos',
  PAYMENT_TITLE_2: 'Pagos realizados',
  PAYMENT_TITLE: 'Pagos a realizar',
  PAYMENTS: 'Pagos',
  PENDING_DELIVER: 'Pendientes de entrega',
  PENDING_DOCUMENT_INFO:
    'En caso de no tener documentos pendientes, espera la aprobación de un administrador de Tango.',
  PENDING_DOCUMENT:
    'Revisa el detalle de tu propiedad, ¡tienes documentos pendientes por enviar!',
  PENDING_IDENTITY_DOCUMENTS:
    'Ahora toca agendar visita con el fotógrafo, hemos enviado un correo con las instrucciones.',
  PENDING_IDENTITY_DOCUMENTS_MOBILE: 'Debes subir tu documento de identidad.',
  PENDING_IDENTITY_DOCUMENTS_DESKTOP:
    "Debes subir tu documento de identidad. Este paso <span class='text-color-secondary'>debes hacerlo desde tu dispositivo móvil</span>.",
  PENDING_PUBLISH: 'Pendientes a publicar',
  PENDING_RENTERS_MESSAGE: 'No tienes arrendatarios pendientes de aprobación',
  PENDING_RENTERS: 'Pendientes de aprobación',
  PENDING: 'Pendiente',
  PENDING_UNIT: 'Pendientes',
  PENTHOUSE: 'Penthouse',
  PERIOD_1:
    "El contrato de Administración de Tango tiene un plazo mínimo de <span class='text-color-secondary'>24 meses</span>.",
  PERIOD_2:
    'Este se renovará de forma automática solo si existe un contrato de arriendo vigente a través de Tango.',
  PERIOD_3:
    "Si el plazo de <span class='text-color-secondary'>24 meses</span> pasó y vence el contrato de arriendo de la propiedad, el contrato de Administración sigue solo si el/la propietario/a lo solicita.",
  PERIOD: 'Plazo',
  PERSONAL_INFORMATION: 'Datos personales',
  PERSONAL_PHOTO: 'Foto personal',
  PERSONS: 'Personas',
  PET_AREA: 'Área de mascotas',
  PHONE_NUMBER: 'Número de teléfono',
  PHONE: 'Teléfono',
  INVALID_PHONE_NUMBER: 'Número de teléfono inválido',
  PHONE_NUMBER_REQUIRED: 'Ingresa un número de teléfono válido',
  PHOTOS: 'Fotos',
  POLICY_PRIVACY_TITLE: 'Políticas de privacidad',
  POLICY_PRIVACY:
    'El uso de este sitio web implica la aceptación de los términos y condiciones y las políticas de privacidad de Tango Rent',
  PORCELAIN: 'Porcelanato',
  POST_DETAILS: 'Detalles de la publicación',
  POSTS: 'Publicaciones',
  POTENTIAL_RENTER_MESSAGE: 'Mensaje del posible arrendatario',
  PRESS_NEXT_TO_REPEAT:
    'Una vez capturada, presiona "¡Vamos!" para continuar con la parte posterior del documento.',
  PRICE: 'Precio',
  PRICES: 'Precios',
  PROPERTY_TO_VISIT: 'Propiedad a visitar',
  PRIVACY_POLICIES: 'Política de privacidad',
  PRIVATE_COURTYARD: 'Patio privado',
  PRIVATE_JACUZZI: 'Jacuzzi privado',
  PRIVATE_POOL: 'Piscina privada',
  PROFESSION: 'Profesión',
  PROFESSIONAL_VIDEOS: 'Sesión de fotos y video profesional',
  PROFILE_CREATED: 'Perfil creado exitosamente',
  PROFILE_IS_EXPIRED:
    'Lamentablemente tu perfil está caducado, por favor actualiza tus documentos',
  PROFILE_REJECTED: 'Lamentablemente tu perfil está rechazado',
  PROFILE_SAVED: 'Perfil guardado',
  PROOF_OF_INCOME_CONSIGNEE_1: 'Prueba de ingresos aval y codeudor solidario 1',
  PROOF_OF_INCOME_CONSIGNEE_2: 'Prueba de ingresos aval y codeudor solidario 2',
  PROOF_OF_INCOME_INDEPENDENT: 'Prueba de ingresos independiente',
  PROOF_OF_INCOME: 'Prueba de ingresos',
  PROPERTIES_NUMBER: 'Nª de propiedades',
  PROPERTIES_TO_BE_RESTORED: 'Propiedades a restituir',
  PROPERTIES_THAT_BEST_SUIT:
    'A continuación se muestran las propiedades que mejor <br> se adaptan a tu búsqueda según los filtros aplicados',
  PROPERTY_AND_HOUSE_DESCRIPTION:
    'Condominio se refiere a casas o departamentos dentro de un conjunto',
  PROPERTY_COMMENTS: 'Comentarios de la propiedad',
  PROPERTY_DATA: 'Otros datos de propiedad',
  PROPERTY_DETAIL: 'Detalle de propiedad',
  PROPERTY_INVENTORY: 'Acta de entrega',
  PROPERTY_PROPOSALS: 'Propuestas de arriendo',
  PROPERTY_ROLE: 'Rol de propiedad',
  PROPERTY_TYPE: 'Tipo de propiedad',
  PROPERTY_UPDATED: 'Propiedad actualizada exitosamente',
  PROPOSAL_DATE: 'Fecha propuesta',
  PROPOSALS_MADE: 'Propuestas realizadas',
  PROPOSALS: 'Propuestas',
  PROPOSED: 'Pendiente',
  PROTESTS_DELINQUENCIES: 'Protestos, morosidades',
  QUESTION_PROTESTS_DELINQUENCIES: '¿Protestos y/o morosidades?',
  PUBLICATION_PENDING: 'Por publicar',
  PUBLISH_ERROR: "<p style='font-weight:600 color:red´'>Error al publicar</p>",
  PUBLISH_PROPERTY_ADMIN: 'Validar',
  PUBLISH_PROPERTY: 'Poner en arriendo mi propiedad',
  PUBLISH_YOUR_PROPERTY: 'Publica tu propiedad',
  PUBLISH: 'Publicar',
  PUBLISHED_AVAILABLE: 'Disponibles',
  PROPERTY_PUBLISHED: 'Propiedad publicada',
  PUBLISHED_DESCRIPTION:
    'La propiedad se publica una vez que el equipo de Tango revisa y aprueba la información, fotos y videos en un plazo máximo de 72 horas después de la verificación.',
  PUBLISHED: 'Publicada',
  PUBLISHING_PROPERTY: 'Publicando la propiedad',
  PUBLISHED_ADVERT_GALLERY: 'Publicación de galería exitosa',
  CREATED_ADVERT: 'Creación de campaña exitosa',
  EDITED_ADVERT: 'Modificación de campaña exitosa',
  DELETED_ADVERT: 'Eliminación de campaña exitosa',
  CREATED_ADVERT_IMAGE: 'Creación de imagen exitosa',
  PUBLISHING: '<p style={{fontWeight:600}}>Publicando</p>',
  PUT_MY_PROPERTY: 'Poner mi propiedad en arriendo',
  PUBLISH_YOUR_PROPERTY_CHOOSE_REGISTER:
    'Publica tu propiedad en simples pasos',
  QUALIFIED_RENTERS_MESSAGE:
    'No tienes arrendatarios calificados para arrendar',
  QUALIFIED_RENTERS: 'Habilitados para arrendar',
  RE_UPLOAD_ID_DOCUMENT:
    'Pincha aquí para re-subir tus documentos en caso de no poder verificar tu identidad con escaner biométrico',
  READ_CONTRACT:
    'Lee detenidamente el contrato, tienes 48 horas para firmarlo.',
  READY: 'Listo',
  REAL_ESTATE_DOCUMENT: 'Comprobante de propiedad',
  RECEIVE_INFORMATION_TITLE: '¡Recibimos tu información!',
  RECEIVE_PAYMENT: 'Recibe tu pago cuando la propiedad se arriende',
  RECEIVED_OFFERS: '¡Haz recibido una o más propuestas de arriendo!',
  RECENT_REMODEL_A: 'Recent remodel' /* ojito con esta */,
  RECENT_REMODEL: '¿Remodelación reciente?',
  RECENTY_UPLOADED_DOCUMENT:
    'Documento subido recientemente, ya no puede ser eliminado.',
  RECOVER_PASSWORD: 'Recupera tu contraseña',
  REDIRECT_TO_PROPERTY_OFFERS:
    'Estás siendo redirigido a la propuesta de arriendo',
  REDIRECT_TO: 'Estás siendo redirigido al formulario para completar sus datos',
  REDIRECT_TO_QR: 'Estás siendo redirigido',
  REFER_AND_EARN_FOR_EACH:
    'Recibe 10% del primer mes de arriendo una vez que se arriende la propiedad referida. ¡Así de fácil!',
  REFER_PROPERTY: 'Refiere propiedades y gana dinero en simples pasos',
  REFERENCE_CODE: 'Código de referencia',
  REFERENTIAL_ADDRESS: '*La ubicación de la propiedad es referencial',
  REFERRED_CODE: 'Con ese código él/ella podrá registrarse',
  REFRIGERATOR: 'Refrigerador',
  REFUSE: 'Rechazar propuesta',
  REGISTER_TYPE: 'Tipo de registro',
  REGISTERED: 'Administrada',
  REJECT_ERROR: "<p style='font-weight:600 color:red'>Error al rechazar</p>",
  REJECT_MINIMUM_AMOUNT: 'Rechazar por monto mínimo',
  REJECT_ON_MINIMUM_AMOUNT:
    'Estás a punto de rechazar a este usuario por monto insuficiente. Esto automáticamente le enviará un correo sugiriendo que actualice sus datos. ¿Confirmas esta acción? ',
  REJECT_PROPERTY_ADMIN: 'Rechazar',
  REJECT: 'Rechazar',
  REJECTED: 'Rechazada',
  REJECT_RENTER: 'Rechazo de arrendatario',
  REJECT_RENTER_REASON:
    'A continuación debes indicar el motivo de rechazo para este usuario',
  REJECTING_PROPERTY: 'Rechazando la propiedad',
  REJECTING: "<p style='font-weight:600 color:#d39e00'>Rechazando</p>",
  REMEMBER_CAM: 'Recuerda permitir el uso de la cámara si es requerido.',
  REMEBER_HOURS_AVAILABLE:
    '*Recuerda que debes tener bloques disponibles para agendar.',
  REMEMBER_ME: 'Recordarme',
  REMEMBER_PERSONAL_MAIL: 'Recuerda utilizar tu correo personal',
  REMINDER: '¡Tenemos una recomendación!',
  RENT_A_PROPERTY: 'Arrendar una propiedad',
  RENT_PRICE: 'Precio de arriendo',
  RENT_PROCCESS_DESCRIPTION:
    "Recibirás tu arriendo neto de comisiones el día <span class='text-color-secondary'>10 de cada mes</span> en la cuenta corriente que indiques en tu perfil de usuario en Tango.",
  RENT_PROPERY: 'Arrendar una propiedad',
  RENT_START_DATE: 'Fecha de incio de tu arriendo',
  RENT: 'Arriendo',
  RENTAL_PROPOSAL_INFO:
    'En esta propuesta podrás ver algunos datos <br>del arrendatario y conocer más sobre él',
  RENTAL_PROPOSAL: 'Propuesta de arriendo',
  RENTED: 'Arrendadas',
  REQUIRED_INFORMATION: 'Información requerida',
  RENTER_AUTHORIZED: 'Arrendatario autorizado',
  RENTER_DETAILS: 'Detalles del arrendatario',
  RENTER_EXPIRED_MESSAGE:
    'Hemos analizado toda tu documentación y desafortunadamente han caducado, !Pero no todo está perdido!, puedes actualizar tu perfil en Tango. Para esto solo debes ir a la sección de formulario en tu perfil y actualizar tus documentos.',
  RENTER_INFORMATION: 'Información de arrendatario',
  RENTER_REJECTED_MESSAGE:
    'Hemos analizados toda tu documentación y desafortunadamente no podemos prestarte los servicios de Tango ya que no has pasado algunos de los requerimientos mínimos que exigimos para poder arrendar una propiedad. Lamentamos mucho la situación y agradecemos tu interés en arrendar a través de Tango.',
  RENTER_REJECTED: 'Arrendatario rechazado',
  RENTER_RELATIONSHIP: 'Relación',
  RENTER_SIGNING_TIME:
    'Ahora es turno del arrendatario, el plazo para firmar es de 48 horas. Te notificaremos cuando esto ocurra.',
  RENTER_UPLOAD_FILES_TITLE:
    'Para continuar con el proceso de validación adjunta los siguientes documentos, el archivo puede estar en formato PDF, JPG o PNG',
  RENTER_UPLOAD_FILES_INDEPENDENT:
    'Nos indicaste que eres trabajador/a <span class="color-secondary">independiente</span> por lo que necesitamos que nos entregues los siguientes documentos para poder comprobar tu nivel de ingresos:',
  RENTER_UPLOAD_FILES_DEPENDENT:
    'Nos indicaste que eres trabajador/a <span class="color-secondary">dependiente</span> por lo que necesitamos que nos entregues los siguientes documentos para poder comprobar tu nivel de ingresos',
  RESTORATION: 'Restitución',
  INDEFINIED_CONTRACT:
    'CONTRATO DE TRABAJO INDEFINIDO (ANTIGÜEDAD MAYOR A 3 MESES) O CERTIFICADO DE EMPLEADOR QUE INDIQUE CARGO, TIPO DE CONTRATO Y ANTIGÜEDAD',
  LAST_SALARY:
    'ÚLTIMAS 3 LIQUIDACIONES DE SUELDO <span class="p-info-label">Para subir más de 1 documento a la vez, debes seleccionarlos todos<span>',
  FORM_22: 'FORMULARIO 22 (MÁS RECIENTE)',
  CANNOT_UPLOAD_MANY_FILES: 'No puedes subir esa cantidad de archivos',
  LAST_PROVISIONAL_PAYMENTS:
    'ÚLTIMOS 6 PAGOS PROVISIONALES MENSUALES O BOLETAS DE HONORARIOS EMITIDAS DURANTE ÚLTIMOS 3 MESES',
  AFP_CERTIFICATE:
    'CERTIFICADO DE COTIZACIONES DE AFP (QUE ACREDITE AFILIACIÓN Y ANTIGÜEDAD LABORAL)',
  REMEMBER_FULL_FORM:
    "Recuerda que luego de completar todos los campos y subir los archivos, debes <span class='font-weight-bold'>ENVIAR</span> tu perfil para que podamos revisarlo y continúes tu proceso sin problemas. Aplica también en caso de firmar con avales y codeudores",
  ADMIN_UPLOAD_TRUORA_DICOM:
    'Para continuar con el proceso de aprobación del arrendatario, adjunta los reportes de truora y dicom en formato PDF',
  DICOM: 'Reporte de Dicom',
  TRUORA: 'Reporte de Truora',
  ONLY_PDF: 'Solo puedes subir archivos PDF',
  RENTER: 'Arrendatario',
  RENTER_AND_COSIGNER: 'Arrendatario y Aval',
  SELECT_RENTER: 'Selecciona un arrendatario',
  NO_REQUISITIONS: 'No existen solicitudes',
  RENTERS: 'Arrendatarios',
  RENTING_EXPERIENCE: 'Que tu experiencia <br>al arrendar sea un baile',
  REPEAT_NEW_PASSWORD: 'Repite nueva contraseña',
  REPORT_DATE: 'Fecha informe',
  REQUESTER: 'Nombre',
  REQUIRED_FIELD: 'Requerido',
  RESCHEDULE_VISIT: 'Reagendar visita',
  RESCHEDULE: 'Reagendar',
  RESIDENCE: 'Residencia',
  RESTORED: 'Restituidas',
  RETIRED: 'Retirado',
  RIGUROUS_ANALYSES: 'Riguroso análisis del arrendatario',
  ROOFTOP: 'Azotea',
  ROLE_IN_TANGO: 'Especifica tu rol en tango',
  RUT: 'Cédula de identidad',
  SAFEGUARD_GUARANTEE_INFO: '*Hasta un monto equivalente a 6 meses de arriendo',
  SALES_NOTES: 'Notas de venta',
  SALES_NOTE: 'Nota de venta',
  SATURDAY: 'Sábado',
  SAUNA: 'Sauna',
  SAVE_MANAGE_SECURITY_DEPOSIT:
    "Guardamos y administramos tu <span class='text-color-secondary'>depósito en garantía</span>",
  SAVE: 'Guardar',
  SAVE_BANK_DATA: 'Guardar datos',
  SAVED: 'Guardado',
  SAVING: 'Guardando...',
  SCAN_DOCUMENT:
    'Posiciona la parte frontal del documento con la imagen que aparecerá en tu pantalla.',
  SCHEDULE_NEW_VISIT_INFO:
    'Reagenda tu nueva visita, sólo puedes elegir un bloque. Recuerda que la visita debe tomar aproximadamente 45 minutos.',
  SCHEDULE_NEW_VISIT: 'Reagenda tu nueva visita',
  SCHEDULE_NOT_AVAILABLE: 'No hay horarios disponibles',
  SCHEDULE_DELIVERS: 'Agendar',
  SCHEDULE_TOUR: 'Agendar tour',
  SCHEDULE_NEW_VISIT_V2: 'Agendamiento de visita',
  SCHEDULE_VISIT_AGENT: 'Agenda tour con Tanguero',
  SCHEDULE_VISIT_SUBTITLE:
    '¿Estás listo para visitar tu nuevo hogar? Selecciona un día y una hora a continuación.<br> El tour debe tomar aproximadamente 45 minutos y será guiada por uno de nuestros Tangueros.',
  SCHEDULE_VISIT_VERIFIER_CARD: 'Agendar visita con fotógrafo',
  SCHEDULE_VISIT_VERIFIER: 'Agendar visita',
  SCHEDULE_VISIT: 'Agendar visita',
  SCHEDULE: 'Horario',
  SCHEDULED: 'Agendada',
  SCHEDULED_VISIT: 'Visita agendada',
  SCHEDULED_TOUR: 'Tour agendado',
  SCORE: 'Puntajes',
  SEARCH_FOR_HOME: 'Busca una propiedad',
  SEARCH_INVITE: 'Te invitamos a seguir buscando tu nuevo hogar.',
  SEARCH_PROPERTIES_VERIFIED_LISTINGS:
    "Busca propiedades con <span class='text-color-secondary'>avisos 100% verificados</span>",
  SEARCH: 'Buscar',
  SECOND_SURNAME: 'Apellido materno',
  SECURITY_CODE: 'Código de seguridad',
  SECURITY_DEPOSIT_INFO:
    '*Tras evaluación de las condiciones de la propiedad a momento de la devolución',
  SEE_AVAILABLE_COMMUNES: 'Ver comunas disponibles',
  SEE_LEASE_CONTRACT: 'Ver contrato de arriendo',
  SEE_PROPERTY_INVENTORY: 'Ver acta de entrega',
  SEE_VIDEO: 'Ve nuestro video',
  SELECT_DOCUMENT_TYPE: 'Selecciona tipo de documento',
  SELECT_FRONT_SIDE: 'Selecciona tu tipo de documento de identidad.',
  SELECT_TWO_IMAGES: 'Selecciona dos fotos al mismo tiempo.',
  SEND_CODE_REFERRED: 'Envía el código generado a tu referido',
  SEND_MAIL: 'Enviar correo',
  SEND_PROPOSAL: 'Enviar propuesta',
  SEND_YOUR_INFO:
    'Envíanos tu información y hazte notar, en cuanto haya alguna posición en Tango que vaya de acuerdo con tus aptitudes y habilidades nos pondremos en contacto contigo.',
  SEND: 'Enviar',
  SENT: 'Enviado',
  SEPARATE_PARKING: 'Por favor separa por espacios los estacionamientos.',
  SEPARATED: 'Separado',
  SEPTEMBER: 'Septiembre',
  SERIOUS_PROPOSAL:
    'Esta es una propuesta seria, me comprometo a firmar el contrato en caso de que el propietario lo aprueba.',
  SERVICES_COVERAGE_DESCRIPTION:
    'Por ahora los servicios de Tango se ofrecen a propiedades que cumplan con las siguientes condiciones:',
  SERVICES_COVERAGE: 'Cobertura Servicios',
  SERVICES_WE_OFFER: '¿Qué servicios ofrecemos?',
  SERVICES: 'Servicios',
  SHARE_CODE:
    'Puedes compartir tu código a través de tus redes sociales preferidas',
  SHARED_OTHERS: 'Si, con alguien más',
  SHOW_MORE: 'Ver más',
  SIBLING_IN_LAW: 'Hermanastro/a',
  SIBLING: 'Hermano/a',
  SIGN_ADMINISTRATION_CONTRACT: 'Firmar contrato de administración',
  SIGN_CONTRACT: 'Firmar',
  SIGN_IN: 'Ingresa',
  SIGN_CONTRACT_NOW: 'Firmar contrato',
  SIGN_LEASE_CONTRACT: 'Firmar contrato de arriendo',
  SIGN_ONLINE_MAKE_PAYMENT:
    "<span class='text-color-secondary'>Firma online el contrato</span> de arriendo y haz tus pagos con tarjeta de crédito o débito",
  SIGN_UP: 'Regístrate',
  SIGN_UP_WITH_GOOGLE: 'Regístrate con Google',
  SIGN_UP_WITH_EMAIL: 'Regístrate con Email',
  SIGN_UP_ON_TANGO: 'Regístrate en Tango',
  LOG_IN_WITH_GOOGLE: 'Inicia sesión con Google',
  SIGN_WITH_SOMEONE: '¿Vas a firmar con alguien más?',
  SIGNATURE: 'Firma',
  SIGNED_CONTRACT_DESCRIPTION:
    '¡El contrato de arriendo ha sido firmado exitosamente por ambas partes!',
  SIGNED_CONTRACT: 'Contrato firmado',
  SIMPLE_THAT: '¡Así de sencillo!',
  SINGLE: 'Soltero/a',
  SOCIOECONOMIC_SEGMENT: 'Informe socioeconómico',
  SOUTH: 'Sur',
  SPECIFY_REMODELING: 'Especifica lo que fue remodelado ...',
  SPORT_FIELD: 'Cancha deportiva',
  SPORTS_FIELD: 'Cancha deportiva',
  SPOUSE: 'Esposo/a',
  START: 'Comienzo',
  START_HOUR: 'Hora de inicio',
  STATUS: 'Estado',
  STEP_1: 'Paso 1',
  STEP_2: 'Paso 2',
  STEP_3: 'Paso 3',
  STEP_4: 'Paso 4',
  STEP_5: 'Paso 5',
  STEP_6: 'Paso 6',
  STILL_DOUBTS: '<strong>¿Aún tienes dudas?</strong>',
  STILL_HAVE_DOUBTS: '¿Aún tienes dudas? Ve a nuestro ',
  STORAGE_NUMBER: 'Número',
  STORAGE: 'Bodega',
  STOVE: 'Quemadores',
  STREET_ADDRESS_EXTRA: 'n° Unidad / Dpto / Casa',
  STREET_ADDRESS: 'Dirección',
  STUDIO: 'Estudio',
  SUCCESSFUL_LOGIN: 'Inicio de sesión exitoso',
  SUCCESSFUL_SIGNING: '¡Contrato firmado exitosamente!',
  SUCCESSFULLY_SIGNED: 'Firma de contrato exitosa',
  SUITE_BEDROOMS_NUMBER: 'Dormitorios en suite',
  SUNDAY: 'Domingo',
  SWITCH_AGENT: 'Cambiar a Tanguero',
  SWITCH_MASTERADMIN: 'Cambiar a Administrador principal',
  SWITCH_OPERATION: 'Cambiar a Administrador de operaciones',
  SWITCH_OWNER: 'Soy propietario',
  SWITCH_RENTER: 'Soy arrendatario',
  SWITCH_VERIFIER: 'Cambiar a fotógrafo',
  SUCCESS_SIGN_WITHOUT_COSIGNERS: 'Has firmado el contrato de arriendo exitosamente. Recuerda que <b>tienes plazo para pagar hasta el día antes del arriendo a las 20:30 horas.</b>',
  SUCCESS_SIGN_WITH_COSIGNERS: 'Has firmado el contrato de arriendo exitosamente. Ahora es el turno de tus avales. Recuerda que <b>tienen plazo para firmar hasta el día antes del arriendo a las 20:30 horas.</b>',
  SIGN_DOCUMENTS_SUCCESS: 'Has firmado el contrato de administración y el contrato de arriendo exitosamente. Ahora es el turno del arrendatario. <b>Tiene plazo hasta el día antes del arriendo a las 20:30 horas. </b> Te notificaremos una vez el arrendatario haya firmado.', 
  SIGN_LEASING_CONTRACT_SUCCESS: 'Has firmado el contrato de arriendo exitosamente.', 
  TANGO_EXPLANATION:
    'Tango es un servicio integral que cubre todo el proceso de arriendo a través de una plataforma automatizada y un equipo profesional preparado para solucionar todos tus problemas',
  TANGO_IS_GREYSTAR:
    "Tango es parte de <a href='https://www.greystar.com/' target='_blank'><span class='text-color-secondary underline-link'>Greystar</span></a>, <strong>la empresa líder mundial</strong> en administración de arriendo residencial",
  TANGO_LEASE_HOURS:
    'Los contratos de arrendamiento de Tango tienen una duración inicial de 12 meses, con renovaciones automáticas por periodos consecutivos iguales.',
  TANGO_PLAN: 'Plan Tango Full',
  TANGO_POINT_MESSAGE:
    'El puntaje Tango es un valor de referencia que estima la capacidad de pago del arrendatario y sus cofirmantes. Se calcula teniendo en cuenta sus ingresos, comportamiento crediticio, entre otras variables. La puntuación de Tango varía de 0 a 100, siendo 100 la mejor puntuación.',
  TANGO_POINTS: 'Puntaje Tango',
  TANGO_SERVICES: 'Servicios de Tango',
  TAX_ANNOTATIONS: 'Anotaciones tributarias',
  TAX_ASSESSMENT: 'Avalúo fiscal de propiedades',
  TEAM_TANGO: 'Equipo Tango',
  TERM_AND_POLICY_APP_TANGO:
    "Declaro haber leído los <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-secondary'>términos, condiciones y políticas de privacidad</span></a> de Tango",
  TERM_AND_POLICY:
    "Declaro haber leído los <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-black font-weight-bold'>términos, condiciones y políticas de privacidad</span></a> de Tango",
  TERM_AND_POLICY_INSTAPAGE:
    "Declaro haber leído los <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-black'>términos, condiciones y políticas de privacidad</span></a> de Tango",
  TERM_AND_POLICY_V2:
    "He leído y acepto los <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-secondary'>términos y condiciones</span></a> de Tango y estoy de acuerdo con su <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-secondary'>política de privacidad</span></a>.",
  TERM_CONDITIONS: 'Términos y condiciones',
  TERM_LAPSE_72HRS:
    'Recibiste una propuesta de arriendo. Recuerda que <strong>tienes únicamente 72 horas</strong> a partir de este momento para aceptarla o rechazarla.',
  TERM_LEASE:
    'Ten en cuenta que el plazo del contrato de arriendo es de 12 meses.',
  TERMS: 'Condiciones',
  TERRACE_AREA: 'Área de terraza',
  TERRACE: 'Terraza',
  THANK_YOU_MODAL: '¡Gracias!',
  THANK_YOU_TANGO:
    '<p>Muchas gracias <span class="font-weight-bold">{name}</span> por enviarnos tu información.</>',
  THE_BENEFITS_ARE_MANY_OWNER_1:
    'Con Tango, ¡arrendar tus inmuebles se vuelve una experiencia agradable!',
  THE_BENEFITS_ARE_MANY: '¡Los beneficios de arrendar con Tango son muchos!',
  THE_EASIEST_WAY:
    'Tango, la forma más sencilla, sin precupaciones y con un equipo de profesionales que te ayudará en todo.',
  THERE_ARE_NO_PROPOSALS: 'No hay propuestas ',
  THERE_ARE_NO_PROPERTIES: 'No hay propiedades',
  THERMALPANEL: 'Termopaneles',
  THIRTY_SECONDS_EXPLANATION: 'Te lo explicamos en 90 segundos',
  THREE_FLOORS: 'Tres pisos',
  THREE_MONTH_EXCLUSIVITY: '*Exclusividad de 3 meses',
  THURSDAY: 'Jueves',
  TIME_COSIGNEE_PLURAL:
    'Los avales y codeudores solidarios deben firmar en un plazo de 48hrs.',
  THIS_BLOCK_IS_NOT_AVAILABLE:
    'Este bloque no se encuentra disponible, pero te mostramos otras opciones a continuación',
  THIS_PROPERTY_IS_BROKERAGE: 'Esta propiedad es de tipo corretaje',
  TIME_COSIGNEE_SINGULAR:
    'El aval y codeudor solidario debe firmar en un plazo de 48hrs.',
  TIME_VISIT: '¿Cuántas horas duró esta visita aproximadamente?',
  TIME: 'Hora',
  TITLE_ACCEPTED: 'Aceptada',
  TITLE_ACTIVE: 'Activa',
  TITLE_CANCELED: 'Canceladas',
  TITLE_CREATE_PROPERTY: 'Crear propiedad en Tango',
  TITLE_FINISHED: 'Finalizadas',
  TITLE_PENDING: 'Pendientes',
  TITLE_PROPERTIES: 'Propiedades',
  TITLE_PUBLISH_PROPERTY: 'Publicar la propiedad',
  TITLE_PUBLISHED: 'Publicadas',
  TITLE_VERIFY_INFO: 'Verificar y completar la información',
  TITLE_VISITS: 'Visitas',
  TO: 'Hasta',
  TOAST_OFFER_REJECTED: 'Propuesta rechazada',
  TOAST_UNIT_CREATED: 'La propiedad ha sido creada',
  TOAST_UNIT_NOT_CREATED: 'La propiedad no pudo ser creada',
  TOGETHER: 'Junto',
  TOKEN_ERROR: 'Error de token, verifique su token',
  TOTAL_AREA_DS: 'Metros cuadrados de área total',
  TOTAL_PAY: 'Total a pagar',
  VISIT: 'Visita',
  TOUR: 'Tour',
  TOUR_CONFIRMED: 'Tour confirmado',
  TOUR_FINISHED: 'Tour finalizado',
  TOUR_TO_PROPERTY: 'Tour a la propiedad',
  TRUORA_ID_SCORE: 'Score por ID',
  TRUORA_IDENTITY: 'Identidad',
  TRUORA_INTERNATIONAL: 'Internacional',
  TRUORA_LEGAL: 'Legal',
  TRUORA_MEDIA: 'Medios',
  TRUORA_NAME_SCORE: 'Score por nombre',
  TRUORA_OBSERVATIONS: 'Observaciones',
  TRUORA_PENAL_AND_CRIMINAL: 'Penal y criminal',
  TRUORA_SCORE: 'Puntaje Truora',
  TRUORA_TAXES_AND_FINANCE: 'Impuestos y finanzas',
  TRUORA_TRUST_LEVEL: 'Nivel de confianza',
  TRY_AGAIN: 'Inténtalo de nuevo. Código de error: {error_code}',
  TRY_CODE_TOKEN_1: '¿No recibiste el correo? Haz click ',
  HERE: ' aquí ',
  HERE_LINK: "<span  class='cursor-pointer'> acá </span>",
  ACCOUNT_NOT_ACTIVE:
    'Tu cuenta aún no está activa, solicita un nuevo código de verificación',
  REMEMBER_TWO_HOURS:
    '*Recuerda que tienes 2 horas para usar tu código, si no tendrás que pedir otro nuevamente',
    TOTAL: 'Total',
    REMEMBER_TWO_HOURS_LOGIN:
    '*Recuerda que tienes 2 horas para activar tu cuenta, sino tendrás que solicitar un código nuevamente',
  VALIDATION_CODE_EXPIRED_2: 'y te reenviaremos otro.',
  OH_SORRY: 'Oh, lo sentimos!',
  TRY_CODE_TOKEN_2: 'y te lo enviaremos',
  TUESDAY: 'Martes',
  TYPE_FLOOR_APARTMENT: '¿Cuántos pisos tiene?',
  UNCLE_AUNT: 'Tío/Tía',
  UNEMPLOYED: 'Desempleado',
  UNIT_ALREADY_VERIFIED: 'La propiedad ya fue verificada',
  UNIT_CREATED: '¡Ya creaste la propiedad!',
  UNIT_DEPT_HOUSE: 'Número o letra de depto/casa',
  UNIT_DETAIL_VISIT_INFO:
    'En tu visita a la propiedad completa todos los campos disponibles',
  UNIT_DETAIL: 'Detalle propiedad',
  UNIT_TYPE: 'Tipo de propiedad',
  UNIT_USED_NOW: '¿Alguien vive en el inmueble actualmente?',
  UNIT_VERIFIED: 'La propiedad fue exitosamente verificada',
  UNIT: 'm<sup>2</sup>',
  UNITS_UNDER_MANAGEMENT: 'Unidades bajo administración',
  UNIVERSAL_ACCESS: 'Acceso universal',
  UNPAID_DEBTS_TOTAL_AMOUNT: 'Monto total impagos',
  UNPAID_TOTAL_DEBTS: 'Total impagos',
  UNREVERSE_ACTION: 'Esta acción no se puede deshacer',
  UPDATE_AVAILABILITY: 'Actualiza tu disponibilidad',
  UPDATE_BLOCKS: 'Actualizar bloques',
  UPDATE_DATA: 'Actualizar datos',
  UPDATED_PASSWORD: 'Tu contraseña ha sido actualizada',
  UPLOAD_BLOCKS: 'Guardar Agenda',
  UPLOAD_DATA_CONSIGNEE: 'Completar datos',
  UPLOAD_DOCUMENT: 'Cargar documento',
  UPLOAD_DOCUMENTS_MAKE_OFFER:
    "Carga tus documentos y haz una <span class='text-color-secondary'>propuesta online</span> sin necesidad de aval",
  UPLOAD_DOCUMENTS:
    'Necesita completar el formulario y subir los documentos para hacer una propuesta',
  UPLOAD_FILES_TITLE: 'Adjuntar archivos',
  UPLOAD_FILES_DOCUMENTS: 'Adjuntar documentos',
  UPLOAD_ID_DOCUMENT: 'Sube tu cédula',
  UPLOAD_ID_DOCUMENT_LINK:
    "Sube tu documento de identidad <span class='text-color-secondary cursor-pointer'>Aquí</span>",
  UPLOAD_PDF: 'Subir PDF',
  UPLOAD_PHOTOS: 'Subir fotos',
  UPLOAD_PHOTOS_INFO:
    'Adjunta en alta resolución todas las fotos de la propiedad',
  DELETE_PHOTO: 'Borrar foto',
  UPLOAD_VIDEOS: 'Subir videos',
  UPLOAD_VIDEOS_INFO:
    'Adjunta todos los vídeos de la propiedad. Te recomendamos vídeos con un peso de no más de 200 MB, en calidad 720p HD a 30 fps.',
  DELETE_VIDEO: 'Borrar video',
  UPLOADING: 'Subiendo ...',
  USABLE_AREA: 'Área útil',
  USER_REGISTERED: 'Usuario registrado',
  USER_VERIFIED: 'Usuario verificado correctamente',
  USER_REGISTRATION: 'Registro de usuario Tango.',
  USER_REGISTRATION_OWNER:
    'Estás a muy pocos pasos de arrendar con Tango… y <strong>¡despreocuparte!</strong>',
  USER_REGISTRATION_OWNER_MOBILE:
    'Estás a muy pocos pasos de arrendar con Tango… y ¡despreocuparte!',
  USER_REGISTRATION_RENTER: 'Encuentra tu nuevo hogar con Tango 🏠',
  USER_UPDATED: 'Usuario actualizado exitosamente',
  USER_VALIDATED: 'Usuario validado',
  CLF: 'UF',
  SELECT_CLF_INFO:
    'Al seleccionar esta opción, aceptas que será la unidad monetaria, en la que se pactará tu contrato al momento de arrendar',
  MONETARY_UNIT_INFO: 'Unidad Monetaria de arriendo',
  VALID_ID_CARD: '¿Carnet vigente?',
  VALIDATION_CODE: 'Código de verificación',
  VEHICLES_NUMBER: 'Nº de vehículos',
  VERIFIED_CONTRACT_DESCRIPTION:
    'El contrato ha sido validado al recibir el depósito por parte del arrendatario/a',
  VERIFIED_CONTRACT: 'Contrato validado',
  VERIFIED_DESCRIPTION:
    'La propiedad se verifica cuando uno de nuestros fotógrafoes visita la propiedad y sube toda la información y fotos necesarias para crear la publicación.',
  VERIFIED: 'Verificada',
  VERIFIER_DESCRIPTION:
    'Selecciona un día y hora para que un fotógrafo visite tu propiedad y recopile <br>toda la información necesaria. La visita se podría extender de los 30 minutos',
  VERIFIER_ID: 'Verifier id',
  VERIFIER_INTRODUCTION:
    'El fotógrafo recopilará la información faltante, tomará fotos y video sin costo adicional.',
  VERIFIER: 'Fotógrafo',
  VERIFY_IDENTITY_WITH_SCAN: 'Verifica tu identidad con escaner biométrico',
  VERIFY_IDENTITY: 'Verifica tu identidad',
  VERIFY: 'Verificar',
  VETO: 'Vetar',
  VIEW_CONTRACT: 'Ver',
  VIRTUAL_TOURS:
    "Tours virtuales o presenciales realizados por nuestros <span class='text-color-secondary'>Tangueros</span>",
  VISIT_AGENT_SUCC:
    'Tu tour ha sido programado! Te enviamos un correo electrónico con todo lo que necesitas saber sobre el tour.',
  VISIT_AGENT:
    '¡Hola! Soy {agent}, y te estaré acompañando en el tour. Nos vemos el <strong>{date} a las {hours} hrs</strong>. Te hemos enviado un correo con toda la información para que llegues preparado a la cita.',
  VISIT_CANCELED: 'Visita cancelada',
  VISIT_CHANGED: 'Visita cambiada exitosamente',
  VISIT_CONFIRMED: 'Visita confirmada',
  VISIT_CREATED: 'Visita creada',
  VISIT_DATE: 'Fecha de visita',
  VISIT_FINISHED: 'Visita terminada',
  VISIT_HISTORY: 'Historial de visitas',
  VISIT_PARKING: 'Estac. de visitas',
  VISIT_SCHEDULED: 'Visita ha sido agendada',
  VISIT_STATE: 'Estado de la visita',
  VISIT_TO_DO: 'Visita a realizar',
  VISIT_VERIFIER_SUCC:
    'Tu visita ha sido programada! Te enviamos un correo electrónico con todo lo que necesitas saber sobre la visita del fotógrafo.',
  VISIT_VERIFIER:
    '¡Hola! Soy {verifier}, seré tu fotógrafo. Nos vemos el <strong>{date} a las {hours} hrs </strong>. Te hemos enviado un correo con toda la información para que estés preparado para tu visita',
  VISIT_WITH: 'Visita con',
  VISTA: 'Vista',
  ELECTRONIC_CHECKBOOK: 'Chequera electrónica',
  SAVING_ACCOUNT: 'Ahorro',
  WALK_IN_CLOSET: 'Walk-in closet',
  WANT_LEASE: 'Quiero arrendar',
  WANT_TO_KNOW_MORE: 'Quiero saber más',
  WANT_POST: 'Quiero poner una propiedad en arriendo',
  WANT_TO_REFER: '¿Quieres referir a alguien?',
  WASHING_MACHINE: 'Lavadora',
  WATER: 'Agua',
  WE_COVER:
    'Cubrimos tu arriendo en caso de no pago y gestionamos el proceso de desalojo*',
  WE_DELIVER_AND_REPLACE_PROPERTY:
    'Nos encargamos de entregar, cuidar y devolver tu propiedad',
  WE_DEPOSIT_AND_COVER:
    'Recibes tu arriendo mensual y te cubrimos en caso de no pago',
  WE_EVALUATE_RENTER:
    'Tu próximo arrendatario pasa por nuestro análisis detallado',
  WE_MANAGE_PROPOSALS_AND_CONTRACT:
    'Firmas un contrato digital; gestionas todo 100% online',
  WE_SAFEGUARD_DEPOSIT:
    'Resguardamos tu depósito y lo entregamos al finalizar el arriendo**',
  WE_VERIFY_EVERY_PROPERTY:
    'Verificamos cada aviso para que la información sea real',
  WE_WILL_CONTACT: 'Nos pondremos en contacto con él/ella',
  WEDNESDAY: 'Miércoles',
  WELCOME_ENTER_USER:
    'Bienvenido! Por favor, ingresa tu usuario y contraseña para iniciar tu sesión',
  WELCOME_TO_TANGO: 'Bienvenido a Tango',
  WEST: 'Oeste',
  WHAT_WOULD_YOU_LIKE: '¿Estás buscando publicar o arrendar una propiedad?',
  WHEN__AND_HOW_RENT_RECEIVED:
    "<span class='text-color-secondary'>¿</span>Cómo y cuándo recibes tu arriendo<span class='text-color-secondary'>?</span>",
  WHEN_PROPERTY_IS_RENTED: 'Cuando se arrienda la propiedad',
  WIDOWED: 'Viudo/a',
  WITH_TANGOBOX: 'Con nuestro Tangobox nosotros mostramos tu propiedad',
  WORK_WITHUS: 'Trabaja con nosotros',
  YEAR: 'Años',
  YEARS_EMPLOYEE: 'Antigüedad en el trabajo actual',
  YES: 'Sí',
  YOU_ARE_RENTING:
    'Ya te encuentras en un proceso de arriendo de una propiedad',
  YOU_CREATE_PROPERTY_TANGO: '<strong>¡Creaste tu propiedad en Tango!</strong>',
  ADDRESS_DUPLICATED:
    '<strong>La propiedad que intentas crear ya se encuentra registrada!</strong>',
  YOU_DONT_HAVE_VISITS: 'No tienes tours',
  YOU_HAVE_THREE_PROPOSAL: 'Ya no puedes realizar más propuestas',
  YOU_HAVE_TRHEE_PROPOSAL: 'Tienes 3 propuestas, no puedes realizar más',
  YOU_MADE_A_PROPOSAL: 'Hiciste una propuesta',
  YOU_WANT_LOCKBOX: '¿Tangobox?',
  YOU_CANNOT_SELECT_THAT_DATE: 'No puedes seleccionar esa fecha',
  YOU_WILL_BE_INFORMED:
    'Te informaremos cuando todos firmen para que realices el primer pago',
  YOUR_PROPOSAL_DATE: 'Fecha de tu propuesta',
  YOUR_VISIT_IS_PENDING: 'Se encuentra pendiente el tour',
  YOUR_VERIFICATION_IS_PENDING: 'Se encuentra pendiente la verificación',
  PENDING_VERIFY: 'Pendiente de verificación',
  UPLOAD_ONE_VIDEO: 'Debes subir un video como mínimo',
  VIDEOS: 'Videos',
  DROP_VIDEOS_LIMIT: 'Solo puedes subir maximo 10 videos',
  PROPERTY: 'Propiedad',
  REQUESTED_BY: 'Solicitada por',
  END_DATE_REQUESTED: 'Día de término solicitado',
  DELETION_ORDER: 'Debes eliminar el poder simple primero',
  PROPERTY_NOT_VALID: 'La propiedad no es válida.',
  NO_VIDEOS: 'Sin videos',
  RENTER_DUE: 'Morosos',
  RENTER_PAID: 'Al día',
  RENTER_PAID_TITLE: 'Arrendatarios al día',
  RENTER_DUE_TITLE: 'Arrendatarios morosos',
  PAYMENET_DUE: 'Vencimiento Pago',
  DAYS_LATE: 'Días de atraso',
  NO_DUE_RENTER: 'No hay arrendatarios morosos',
  NO_PAID_RENTER: 'No hay arrendatarios al día',
  NO_NOTES: 'No hay notas de ventas',
  RENTS_PROPERTY_SINCE: 'Arrienda la propiedad desde',
  THANK_YOU:
    "Felicidades, estás a un paso más cerca de arrendar tu propiedad en <span class='text-color-secondary'>Tango</span>!",
  THE_LEASING_YOU_DESERVE: 'Arrienda como te lo mereces',
  YOU_RELAX: 'Tú te relajas,',
  TANGO_TAKES_CARE: 'Tango se encarga.',
  ALREADY_LEASING: 'Ya tengo una propiedad arrendada',
  FAST_GREATER_VALUE: 'Arrienda rápido y a mayor valor',
  FAST_GREATER_VALUE_INFO:
    'Nuestros fotógrafos profesionales logran arriendos más rápidos y a mejor precio',
  WE_TAKE_CARE_OF_YOUR_TIME: 'Optimiza tu tiempo',
  WE_TAKE_CARE_OF_YOUR_TIME_INFO:
    'Nos ocupamos de los tours, los contratos y la mantención. Firma y avisos de pago online',
  LEASE_WITHOUT_RISK: 'Recibe tu arriendo sin riesgo',
  LEASE_WITHOUT_RISK_INFO:
    'Tango cubre el arriendo de tu propiedad hasta por 6 meses en caso de no pago',
  ALREADY_LEASING_Q: '¿Ya tienes una propiedad en arriendo?',
  YOU_CAN_ALSO_BENEFIT:
    'También te puedes beneficiar de Tango, podemos administrar tu arriendo por ti',
  MANAGE_LEASED_PROPERTY: 'Administra una propiedad ya arrendada',
  TANGO_ADMIN_PLAN: 'Plan Tango administración',
  PROPERTY_REGISTERED: 'Registradas',
  MANAGEMENT_PROPERTY_FIXES: 'Gestión de arreglos del inmueble',
  GET_PLAN: 'Contrata plan',
  FULL_PLAN: 'Tango Full',
  ADMIN_PLAN: 'Tango Administración',
  SHOW_LESS: 'Ver menos',
  GLOBAL_LIDER_SUPPORT: 'El líder global nos respalda',
  ONLINE_CHAT: 'Chat en línea',
  RENTED_UNIT: '¿Actualmente el inmueble se encuentra arrendado?',
  CONTRACT_CONDITIONS: 'Condiciones del contrato de arriendo',
  CONTRACT_DATE: 'Fecha inicio contrato de arriendo de inmueble',
  CURRENT_PRICE: 'Valor actual del arriendo',
  RENTER_FULLNAME: 'Nombre completo de arrendatario/a',
  RENTER_RUT: 'Rut de arrendatario/a',
  RENTER_EMAIL: 'Correo electrónico de arrendatario/a',
  RENTER_EMAIL_INFO:
    'Solicitamos el correo electrónico del arrendatario/a para contactarlo/a solicitando la información financiera personal que nos permitirá analizar su capacidad y comportamiento de pago.',
  RENEGOTIATION: 'Renegociaciones',
  PROOF_OF_PAYMENT: 'Comprobante de pagos',
  LEASE_CONTRACT_INFO:
    'Adjunta el contrato de arriendo original firmado ante notario',
  RENEGOTIATION_INFO:
    'Adjunta correo electrónico o documento firmado en que hayas renegociado el precio o condiciones del contrato de arriendo con tu arrendatario/a',
  PROOF_OF_PAYMENT_INFO:
    'Documentos que demuestren el pago del arriendo por partes del propietario los últimos 3 meses. Estos pueden ser comprobantes de depósito bancarios con en que se muestre el nombre del depositante.',
  MANAGE_YOUR_PROPERTY: 'Administra tu propiedad',
  IF_CREATED_RENTED_PROPERTY:
    'Si completaste el formulario de una propiedad que ya tienes en arriendo, espera la aprobación de un administrador de Tango.',
  NEW_RENTED_PROPERTY_TITLE: 'Nueva propiedad arrendada',
  FAILED_DOCUMENT_DELETION:
    'Ocurrió un error borrando el archivo, refresca la página por favor',
  LEASE_CONTRACT_FILE: 'Contrato de arriendo *',
  RENEGOTIATION_FILE: 'Renegociaciones (Opcional)',
  PROOF_OF_PAYMENT_FILE: 'Comprobante de pagos *',
  DOCUMENT_SUCCESSFULLY_ATTACHED: 'Documento(s) adjuntado(s) exitosamente',
  UP_TO_FIVE_FILES: 'Puedes subir un máximo de 5 archivos',
  UP_TO_TEN_FILES: 'Puedes subir un máximo de 10 archivos',
  ALERT_TO_RENTER: 'Ya le avisé a mi arrendatario/a sobre este proceso',
  ALERT_TO_RENTER_INFO:
    'El/la arrendario/a debe estar al tanto de este proceso ya que deberá crearse un usuario en Tango, nos deberá mandar información financiera para análisis y deberá comenzar a pagar el arriendo a través de Tango',
  PROPERTY_NOT_LEASED_1:
    'Si tu inmueble no está arrendado nosotros podemos arrendarlo por ti! En este ',
  PROPERTY_NOT_LEASED_2:
    ' puedes ingresar tu propiedad y nosotros nos encargamos',

  BEFORE_CREATING_ADMINISTRATION:
    '¡Estás a punto de comenzar la nueva administración de tu propiedad!',
  BEFORE_CREATING_ADMINISTRATION_2:
    'Déjanos antes darte más detalles sobre nuestro servicio',
  TITLE_CREATE_PROPERTY_ADMIN: 'Ingresa tu propiedad en Tango',
  DESCRIPTION_CREATE_PROPERTY_ADMIN:
    'Ingresa datos básicos de tu propiedad, contratos de arriendo y arrendatario en 5 minutos!',
  TITLE_REVIEW_INFO: 'Revisamos el contrato y contactamos al arrendatario',
  DESCRIPTION_REVIEW_INFO:
    'Revisaremos las condiciones de tu contrato de arriendo para asegurarnos que cumplan con nuestros estándares. Tras aprobarlo, solicitaremos información financiera a tu arrendatario/a',
  TITLE_RENTER_AND_COSIGNER_APPROVAL:
    'Aprobación del arrendatario y avales y codeudores solidarios',
  DESCRIPTION_RENTER_AND_COSIGNER_APPOVAL:
    'Revisaremos el perfil de crédito y capacidad de pago del arrendatario para poder asignarle un puntaje Tango y aprobarlo como un buen pagador dentro de nuestro sistema.',
  TITLE_SIGN_ADMIN_CONTRACT: 'Firma el contrato de administración',
  DESCRIPTION_SIGN_ADMIN_CONTRACT:
    "Revisa y <span class='text-color-secondary'>firma el contrato</span> de Administración con <span class='text-color-secondary'>Tango online</span>.",
  ADMINISTRATION_AND_INSURANCE_ADMIN:
    "Recolectamos el pago de arriendo a través de nuestra plataforma.<br><br/> Además te entregamos una fianza en la que  <span class='text-color-secondary'>cubrimos el pago del arriendo por hasta 6 meses</span> de arriendo en caso de que el arrendatario no pague, y nos encargamos de todos los procesos legales en caso de desalojo.",
  CHARGE_ADMIN:
    'El cobro es descontado del arriendo que recolectamos mensualmente',
  CHARGED_AMOUNT: 'Monto cobrado',
  CONDITIONS_DESCRIPTION_ADMIN:
    'El contrato de Administración de Tango tiene una condición que es importante que sepas:',
  PERIOD_ADMIN:
    "El contrato de Administración de Tango tiene un plazo mínimo de <span class='text-color-secondary'>24 meses</span>.<br><br/>Este se renovará de forma automática solo si existe un contrato de arriendo vigente a través de Tango.<br><br/>Si el plazo de <span class='text-color-secondary'>24 meses</span> pasó y vence el contrato de arriendo de la propiedad, el contrato de Administración sigue solo si tú lo solicita",
  NOW_CREATE_PROPERTY_ADMIN: 'Ahora ya puedes administrar tu propiedad con',
  CREATE_MY_PROPERTY_ADMIN: 'Comienza a administrar tu propiedad',
  CONTACT_US: '<strong>Contáctanos</strong>',
  THANKS_FOR_SENDING_UNIT_INFO:
    '¡Gracias por enviarnos la información de tu propiedad!',
  FILL_OUT_FORM:
    'Ahora necesitamos que por favor completes tu perfil con tus datos mientras tu propiedad es aprobada por un administrador de Tango',
  YOU_WILL_BE_REDIRECTED: 'Serás redireccionado en unos segundos.',
  PROOF_OF_JOB_SENIORITY: 'Prueba de antigüedad laboral',
  SALARY_SETTLEMENT: 'Liquidaciones de sueldo',
  AFP_QUOTATION_DEPENDENT: 'Certificado de cotizaciones',
  AFP_QUOTATION_INDEPENDENT: 'Certificado de cotizaciones',
  SUCCESFULLY_SAVED_DATA: 'Tus datos han sido guardados exitósamente.',
  CLICK: 'Haz clic',
  GO_TO_UPPER_TAB:
    'Para llenar los datos de tu aval y codeudor solidario debes ir a la pestaña superior con el mismo nombre. Pero antes, debes guardar tus datos.',
  UNDERSTAND: 'Entiendo',
  INFO_UPLOAD_FILES_RENTER:
    'Los formatos admitidos son PDF, JPG o PNG. En caso de tener tus documentos en un solo archivo te recomendamos subirlo en PDF',
  MAX_DATE: 'La fecha no puede ser superior a la permitida',
  MIN_DATE: 'La fecha no puede ser inferior a la permitida',
  BIRTH_MIN_DATE: 'Debes tener al menos 18 años para registrarte en Tango',
  ICON_TEXT_RENTER_1: 'Regístrate y explora las propiedades',
  ICON_TEXT_RENTER_2: 'Agenda un tour virtual o presencial',
  ICON_TEXT_RENTER_3: 'Sube tus documentos y firma el contrato online',
  ICON_TEXT_RENTER_4: 'Paga tu arriendo online con tarjeta de débito o crédito',
  SIGN_UP_RENTER: '¡Arrienda con Tango sin aval!',
  SIGN_UP_OWNER:
    'En muy pocos pasos puedes arrendar con Tango… y ¡despreocuparte!',
  SIGN_UP_OWNER_2:
    'Necesitamos algunos datos de la propiedad, así nuestro fotógrafo te puede visitar!',
  USER_REGISTRATION_OWNER_STEPS:
    'En cuatro pasos tu propiedad estará publicada',
  ICON_TEXT_OWNER_1:
    'Regístrate, crea tu propiedad y agenda tu visita con un fotógrafo',
  ICON_TEXT_OWNER_2: 'Nosotros realizamos los tours',
  ICON_TEXT_OWNER_3:
    'Elige un arrendatario aprobado por Tango y firma el contrato de manera online',
  ICON_TEXT_OWNER_4: 'Garantizamos el pago de tu arriendo mes a mes',
  SIGNUP_INFO_OWNER: '¡No pagas nada hasta que tu propiedad se arriende!',
  UPLOADED_DOCUMENTS: 'Archivos subidos',
  NEXT: 'Siguiente',
  NAME_OWNER: 'Nombre propietario',
  NAME_RENTER: 'Nombre arrendatario',
  PAYMENT_AMOUNT: 'Monto a pagar',
  RENTER_PAYMENT_STATUS: 'Estado pago arrendatario ',
  PROFESSIONAL_PHOTOS_VIDEOS: 'Fotos, videos y planos profesionales',
  OPTIONAL_TOURS: 'Tours (no es necesario que estés)',
  RENTER_ANALYSIS: 'Análisis de arrendatarios',
  ONLINE_CONTRACT_SIGNING: 'Firma de contratos online',
  GUARANTEED_PAYMENT: 'Pago de arriendo a tiempo - garantizado',
  VERIFIED_LISTINGS: 'Encuentra avisos 100% verificados',
  NO_GUARANTORS: 'Arrienda sin aval',
  REQUESTED_NOT_FOUND: 'No se encontró lo solicitado',
  VIRTUAL_TOURS_BENEFIT: 'Tours virtuales y presenciales',
  PAY_ONLINE: 'Paga online con tu tarjeta de crédito o débito',
  LEASE_WITH_TANGO:
    "¡Arrienda tu propiedad a largo plazo con <strong class='text-color-secondary'>Tango</strong>!",
  TANGO_DESCRIPTION:
    '<strong>Una plataforma de arriendo y administración para propietarios y arrendatarios</strong> con tecnología y equipo profesional que harán tu <strong>proceso ágil y seguro.</strong>',
  CHOOSE_PROFILE: 'Escoge el perfil que te identifique',
  CHOOSE_PROFILE_HOME:
    "<h1 class='font-weight-bold'>En Tango administramos y arrendamos tu propiedad</h1> <h2>Conoce lo que podemos hacer juntos</h2>",
  I_AM_OWNER: 'Soy propietario',
  I_AM_RENTER: 'Soy arrendatario',
  RELAX_WE_TAKE_CARE: '¡Relájate nosotros nos encargamos!',
  DIFFERENT_LEASING_EXPERIENCE: '¡Una experiencia de arriendo diferente!',
  LEASE_MY_PROPERTY: 'Arrendar mi propiedad',
  SEARCH_PROPERTIES: 'Buscar propiedades',
  PROPERTIES: 'Propiedades',
  NO_TRANSACTIONS: 'No hay transacciones',
  COUNTRY_MANAGER: 'Country Manager',
  TRANSACTIONS_TEXT:
    'Revisa los estados de pago y datos relacionados a las propiedades',
  PROMOTIONS: 'Promociones',
  PROMOTIONS_TEXT: 'Crea, revisa y edita en el listado de promociones',
  CREATE_USER: 'Crear perfiles',
  CREATE_USER_TEXT: 'Crea nuevos usuarios',
  MY_ACCOUNT_TEXT: 'Edita tus datos, cambia tu contraseña',
  MENU: 'Menú',
  DESCRIPTION: 'Descripción',
  DISCOUNT_DURATION: 'Duración descuento',
  DISCOUNT_PORCENTAGE: '% de descuento',
  DISCOUNT_PORCENTAGE_OWNER: '% de descuento propietario',
  DISCOUNT_PORCENTAGE_RENTER: '% de descuento arrendatario',
  CREATE: 'Crear',
  TOTAL_RECEIVED: 'Total recibido',
  PROOF_OF_PAYMENT_PDF: 'Comprobante de pago',
  CREATE_PROMOTION: 'Crear promoción',
  PROMOTION_LIST: 'Lista de promociones',
  EDIT_PROMOTION: 'Editar promoción',
  PROMOTION_NO_UPDATE: 'La promoción no puede ser editada',
  DATA_EMPTY: 'Debe ingresar valores',
  CHECK_VALUE: 'Revisa el valor',
  CHECK_VALUE_PERCENTAGE: 'El valor debe estar entre 0% a 100%',
  CHECK_VALUE_MONTH: 'El valor debe estar entre 1 a 12',
  CHECK_VALUE_NUMBER: 'El valor debe ser un número entero',
  ADMINISTERED: 'Administrada',
  LISTED: 'Listada',
  DURATION: 'Duración',
  START_DATE: 'Fecha inicio',
  ENDED_DATE: 'Fecha término',
  DIFFERENT_EXPERIENCE: 'Una experiencia de arriendo diferente',
  WANT_TO_LEASE_MY_PROPERTY: 'Quiero publicar mi propiedad',
  MONTH: '{num, plural,=1 {1 mes} other {# meses}}',
  FORM22: 'Formulario 22',
  PROVITIONAL_MONTHLY_PAYMENT: 'Pagos provisionales mensuales',
  CREATE_PROPOSAL: 'Crear propuesta',
  CHECK_YOUR_PROFILE: 'Revisa tus datos',
  CHECK_YOUR_COSIGNER_PROFILE: 'Revisa los datos de tu aval y codeudor',
  CHECK_YOUR_COSIGNER_PROFILES: 'Revisa los datos de tus avales y codeudores',
  EXACT_ADDRESS: 'Dirección personal',
  INDICATE_FULL_ADDRESS: 'Por favor indica número/nombre de calle/avenida',
  EXTRA_ADDRESS_INFO: 'Opcional',
  COUNTRY_OF_RESIDENCE: 'País de residencia',
  SELECT_ADDRESS: 'Selecciona una dirección del listado',
  INVENTORY_REJECTED: 'Acta rechazada',
  INTERNAL_SERVER_ERROR:
    'Error interno del servidor, recarga la página o comunícate con tango',
  CONNECTION_ERROR: 'Error de conexión',
  ATTACH_PROPERTY_LINK: 'Adjuntar link de la propiedad',
  SELECT_VISIT_DAY: 'Seleccionar día de visita',
  BLOCK_VISIT: 'Bloque horario visita',
  HOURS_AVAILABLE: 'Horarios disponibles',
  TOUR_CREATED: 'Tour creado correctamente',
  INVALID_LINK: 'Link de la propiedad no válido',
  SELECT_A_BLOCK: 'Debes seleccionar un bloque',
  UNIT_ADDRESS_PLACEHOLDER_TEXT: 'Busca por dirección',
  UNIT_ADDRESS_RENTER_NAMES_PLACEHOLDER_TEXT:
    'Busca por dirección o arrendatario',
  USER_SEARCH: 'Busca por correo o nombre',
  USER_SEARCH_LABEL: 'Búsqueda usuarios',
  DELIVERS_SEARCH_LABEL: 'Búsqueda entregas',
  RESTITUTIONS_SEARCH_LABEL: 'Búsqueda restituciones',
  ASSIGN_VISIT: 'Asignar visita',
  ENTER_ADDRESS: 'Ingrese dirección',
  EXACT_ADDRESS_PROPERTY: 'Ingrese dirección exacta de la propiedad',
  REVIEW_YOUR_DATA_CONTRACT:
    'Para crear esta propuesta necesitamos que revises tus datos a continuación.',
  NOT_FOUND_ERROR: 'Error 404, solicitud no encontrada',
  RENTER_PROFILE: 'Perfil renter',
  INSERTED_PROPERTIES: 'Propiedades ingresadas',
  SHOW_DETAILS: 'Ver detalle',
  GENERATE: 'Generar',
  SEARCH_NOT_FOUND: 'Búsqueda no encontrada',
  SEARCH_RESULT: 'Resultado de la búsqueda',
  NOT_HAVE: 'No tiene',
  REJECT_ANNEX_TITLE: 'Rechazo de acta',
  FULLNAME: 'Nombre completo',
  DATA_CONTRACT_ERROR: 'Si existe algún dato erróneo, por favor indícanos',
  WILL_CONTACT_YOU: 'Nos pondremos en contacto prontamente.',
  HAVE_DOUBT: 'Si tienes dudas, por favor contáctanos',
  REJECT_ANNEX_SUBTITLE:
    'Lamentamos que el acta no cumpla con tus requerimientos.',
  REJECT_ANNEX_PLACEHOLDER:
    'Cuéntanos el motivo, para que nuestro Tanguero pueda actualizarlo.',
  USERS_DETAILS: 'Detalles del usuario',
  INVENTORY: 'Acta',
  UPLOAD_INVENTORY: 'Subir acta',
  SEE_CURRENT_INVENTORY: 'Ver acta actual',
  ROWS_PER_PAGE: 'Filas por página',
  PREVIOUS_PAGE: 'Página anterior',
  NEXT_PAGE: 'Página siguiente',
  TYPE_OF_INCOME: 'Tipo de ingreso',
  HASNT_REGISTERED_PROPERTIES: 'Usuario sin propiedades ingresadas',
  UPLOAD_DOCUMENT_ID:
    'Ahora necesitamos que por favor subas tu documento de identidad mientras tu propiedad es aprobada por un administrador de Tango',
  NO_DATA_TO_CONTINUE: 'Ups! Nos hacen falta algunos de tus datos',
  INVITE_TO_COMPLETE_DATA: 'Te invitamos a completarlos a continuación',
  BEFORE_CONTINUE: '¡Ups! Antes de continuar...',
  CHECK_REQUIREMENTS:
    'Revisemos que tengas todo en orden, puedes seleccionar una de las opciones a continuación',
  ID_DOCUMENTS: 'Documentos de identidad',
  PROPERTY_DOCUMENTS: 'Documentos de propiedad (opcional)',
  PROFILE_DATA: 'Datos de perfil',
  CHECK_YOUR_PROPERTY_DATA: 'Información de propiedad',
  STORAGE_ROOM_NUMBER: 'N° Bodega',
  PARKING_SPACE_NUMBER: 'N° Estacionamiento',
  COMMUNE_AND_REGION: 'Comuna / Región',
  BASIC_SERVICES: 'Servicios básicos',
  ANIMALS: 'Mascotas',
  CONFIRM_INFORMATION: 'Confirmar datos',
  ACCEPT_PROPOSAL_NEW: 'Confirmar información',
  REQUIREMENTS_COMPLETED_TITLE: '¡Felicidades!',
  REQUIREMENTS_COMPLETED_SUBTITLE: 'Tu documentación esta completa. Ahora podrás confirmar tus datos para aprobar la propuesta.',
  SIGN_TITLE: 'Firma eléctronica avanzada',
  SIGN_SUBTITLE: 'A continuación pasarás al proceso de firma electrónica avanzada a través de una página externa de Tango. Luego de firmar volverás a Tango para revisar el proceso.', 
  BEFORE_SIGN: 'Antes de firmar debes contar con:',
  UNIQUE_PASS: 'Clave única',
  ID_DOCUMENT_NEARBY: 'Tu cédula de identidad cerca',
  EMAIL_DINAMIC_PASS: 'Correo eléctronico para recibir clave dinámica',
  GO_TO_SIGN: 'Ir a firmar', 
  REVIEW_DATA_OWNER:
    'Para aceptar esta propuesta necesitamos que revises tus datos a continuación.',
  REFUSE_PROPOSAL: 'Rechazo de propuesta',
  REFUSE_PROPOSAL_SURE: '¿Estás seguro de rechazar esta propuesta?',
  OWNER_DATA: 'Información del propietario',
  DATA_FROM_ID: 'DATOS OBTENIDOS DE ID',
  SCHEDULE_VISIT_WITH_PHOTOGRAPHER: 'Agendar visita con fotógrafo',
  FILL_FORM_DATA: 'Completar mi perfil',
  STATE_DOCUMENT:
    'Documentación pendiente de aprobación por el administrador (opcional)',
  THANKS_FOR_FILLING_OUT_PROFILE:
    '¡Gracias por completar los datos de tu perfil!',
  WAIT_ADMIN_APPROVAL:
    'Ahora solo queda esperar que un administrador de Tango apruebe tu propiedad para poder seguir con el proceso.',
  IF_ALREADY_APPROVED:
    'Si tu propiedad ya fue aprobada, simplemente ignora este mensaje.',
  NOW_SCHEDULE_WITH_PHOTOGRAPHER:
    'Ahora tienes que agendar visita con un fotógrafo',
  USER: 'Usuario',
  REFERENCE: 'Referencia',
  RRSS_INVITATION_TITLE:
    '¡Te elegí para que accedas a los beneficios que yo ya disfruto en Tango! Pon en arriendo tu propiedad, ahorra tiempo, es online y 100% seguro. Ingresa el código de referencia {referralCode} y regístrate aquí ',
  REFERENCE_BODY:
    'Ingresa el código de referencia {referralCode} y regístrate aquí ',
  SUBJECT_INVITATION:
    '¡Te elegí para que accedas a los beneficios que yo ya disfruto en Tango! Pon en arriendo propiedad, ahorra tiempo, es online y 100% seguro.',
  LINKEDIN_SHARE:
    'Al compartir en Linkedin presione las teclas ctrl + v para pegar el código de referencia',
  TANGO: 'Tango',
  NO_DATA: 'No hay información para mostrar',
  WHAT_DOES_TANGO_DO: '¿Qué hace Tango?',
  REFERENCIA: 'Referencia',
  USER_UPDATED_DATA: 'Datos actualizados',
  SEND_YOU_EMAIL:
    'Te enviaremos un email para que puedas confirmar tus datos bancarios',
  EMAIL_IS_SEND:
    'Te enviamos un email para que puedas confirmar y actualizar tus datos bancarios',
  I_AM_OTHER: 'Otro',
  SELECT_ROLE: '¿Qué quieres hacer en Tango?',
  ROLE: 'Rol/es',
  NO_DOCUMENTS: 'Documentos faltantes',
  EMAIL_BODY_REFERENCE:
    '{referring_user}, te ha invitado a formar parte de la experiencia Tango, copia y pega el siguiente link en tu navegador',
  SUBJECT_REFERENCE: '{referring_user}, te invita a ser parte de Tango',
  NO_PAID: 'No pagado',
  PAID: 'Pagado',
  SELECTED: 'Has {num, plural, =1 {seleccionado 1 } other {seleccionado # }}',
  CHECK_YOUR_DATA: 'Revisa tus datos, es importante que no tengan errores',
  CONFIRM_UPDATE_BANK:
    '¿Confirmas que éstos son tus datos bancarios y que están correctos?',
  BANK_DATA_SAVED:
    'Datos bancarios completados. Serás redireccionado a la propuesta de arriendo.',
  CONFIRM_BTN: 'Confirmar',
  CONFIRMED_DATA: 'Datos confirmados',
  YOUR_DATA_BEEN_UPDATED: 'Tus datos se han actualizado correctamente',
  ALL: 'Todo',
  LEASES: 'Arriendos',
  REFERENCES: 'Referencias',
  REFERRING_USER: 'Referenciador',
  REFERRED_USER: 'Referido',
  THIS_EMAIL_USED: 'Este correo se utilizará para tus datos bancarios',
  OVERDUE_DATE: 'Fecha de pago',
  REFERENCEABLE_TYPE: 'Tipo de referencia',
  REFER: 'Referenciar',
  REFERENCE_STATUS: 'Estado de referencia',
  REFERENCE_LIST: 'Lista de referenciados',
  CHANGE_STATUS_TO_PENDING:
    '¿Estás seguro que quieres cambiar el estado de Pagado a Pendiente?',
  CHANGE_STATUS: 'Cambiar',
  PARKING_SPACES_NUM:
    '{num, plural, =0 {Sin estacionamiento} =1 {1 estacionamiento} other {# estacionamientos}}',
  REGISTERED_REFERENCE: 'Registrada',
  ASSIGNED_REFERENCE: 'Asignada',
  PAID_REFERENCE: 'Pagada',
  REGISTERED_REFERENCE_DESCRIPTION: 'referenciado se registró con tu código',
  ASSIGNED_REFERENCE_DESCRIPTION: 'referenciado logró arrendar una propiedad',
  PAID_REFERENCE_DESCRIPTION: 'recibiste el pago de la referencia',
  PROFILE_DECLINED:
    'Lamentablemente tu perfil fue declinado. Lo bueno es que puedes actualizar tu información en tu perfil para ser nuevamente evaluado',
  RENTER_DECLINED_MESSAGE_1:
    'Nuestro equipo ha analizado tu documentación y lamentamos informar que tu perfil no cumple todos los requisitos solicitados por Tango.Rent y ha sido declinado.',
  RENTER_DECLINED_MESSAGE_2:
    'Si crees que puedes agregar los antecedentes necesarios para subsanar la situación, vuelve a tu perfil pinchando el botón a continuación.',
  PAID_AT: 'Pagado el',
  PAID_BY: 'Pagado por',
  NEXT_STEP_LEASE_CONTRACT:
    'Ya firmaste el contrato de administración, ahora tienes que ir a firmar el contrato de arriendo',
  SIGNED_PROPERTY_REGISTER:
    'Tu contrato de Arriendo desde ahora lo administra Tango!',
  BODY_SIGNED_PROPERTY_REGISTER:
    'Desde ahora podrás ver el resumen de tus pagos, comprobantes, contrato de arriendo y contrato de administración online. Además, tu arriendo ya esta cubierto por nuestro seguro de no pago!',
  PHOTOGRAPHER_VISIT: 'Listo! Ya puedes agendar visita con un fotógrafo',
  FILL_FORM_DATA_OPTIONAL: 'O ir a completar mi perfil (opcional)',
  FILL_FORM_DATA_OPTIONAL_2: 'Ir a completar mi perfil (opcional)',
  MAKE_ALL_BLOCKS_AVAILABLE: 'Habilitar todos los bloques disponibles',
  ENABLING_BLOCKS_DESCRIPTION_1:
    'Al activar esta opción quedarán disponibilizados los bloques hasta 2 semanas en adelante',
  ENABLING_BLOCKS_DESCRIPTION_2:
    'Puedes desactivarlo en cualquier momento. Debes recordar que al hacerlo, los bloques ya disponibles deberán ser desactivados manualmente',
  BLOCK_AVAILABILITY: 'Disponibilidad de bloques',
  ACTIVATE_BLOCK_TWO_WEEKS:
    '¿Deseas activar configuración para tomar bloques automáticamente?',
  DEACTIVATE_BLOCK_TWO_WEEKS:
    '¿Deseas desactivar la configuración para tomar bloques automáticamente?',
  PHOTO_ORDER_INSTRUCTIONS:
    'A continuación puedes escoger el orden de las fotografías que acabas de subir:',
  YOU_MUST_SELECT_ALL_PHOTOS: '¡Debes definir el orden para todas las fotos!',
  WHAT_TO_DO_IN_TANGO: '¿Qué puedo hacer en Tango?',
  WANT_LEASE_PROPERTY: 'Quiero arrendar una propiedad',
  US: 'Nosotros',
  LEGAL_INFORMATION: 'Información legal',
  OUR_NETWORKS: 'Nuestras redes',
  POLICY_PRIVACY_TITLE_AND_CONDITIONS_TERMS: 'Políticas de privacidad y TC',
  OWNER_IDENTITY_DOCUMENT_NO_PRESENT:
    'El propietario no ha subido el documento de identidad',
  OWNER_FULL_ADDRESS_NO_PRESENT: 'El propietario no ha guardado su dirección',
  TYPE: 'Tipo',
  GENERAL: 'General',
  UNPUBLISH: 'Dar de baja',
  ABOUT_TO_UNPUBLISH:
    "¿Confirmas que darás de baja la propiedad <br/><span class='font-weight-bold'>{address}</span>?",
  SUCCESSFULLY_UNPUBLISHED: 'La propiedad fue dada de baja exitosamente',
  CONFIRM: 'Confirmar',
  YOU_CANT_SCHEDULE: 'No puedes agendar este horario:',
  REQUIRED: 'Requerido',
  UPLOADING_PHOTOS: 'Subiendo foto(s)...',
  MEANING_OF_COLORS:
    'Acá puedes conocer el significado de cada color en el calendario',
  MEANING_OF_VISITS:
    'Aquí puedes conocer el significado de los estados de las visitas en la tabla',
  MEANING_PENDING: 'La propiedad aún no tiene una visita agendada.',
  MEANING_SCHEDULE:
    'La propiedad tiene una visita agendada, pero aún no ha sido finalizada.',
  MEANING_FINISHED_DELIVERS:
    'La propiedad tiene una visita agendada, pero aún no ha sido aprobada el acta de entrega por el admin, owner y renter.',
  MEANING_FINISHED_RESTITUTIONS:
    'La propiedad tiene una visita agendada, pero aún no ha sido aprobada el acta de restitución por el admin.',
  MEANING_1: 'Este bloque se puede disponibilizar',
  MEANING_2: 'Este bloque se encuentra bloqueado',
  MEANING_3: 'Este bloque se encuentra disponible para agendar visitas',
  PER_MONTH: 'Mes',
  PER_MONTHS: 'Meses',
  PHOTO_ORDER_SENT: 'Orden de fotos enviada',
  PUBLISH_MY_PROPERTY: 'Publicar mi propiedad',
  TOTAL_AREA: 'Sup. total',
  UNIT_NUMBER: 'nº casa/depto',
  CHOOSE_VISIT_TIME: 'Elegir horario visita',
  UPDATE_PASSWORD: 'Cambiar contraseña',
  PASSWORD_DO_NOT_MATCH: 'Contraseñas no coinciden',
  PROMOTION_TITLE: 'Publica tu propiedad ahora y aprovecha la promoción',
  PROMOTION_CONDITIONS:
    '*Condiciones promocionales estipuladas en  contrato de administración, aplica para propiedades una vez sean arrendadas',
  NEED_TO_VERIFY_EMAIL:
    '¡Ya falta poco! Sólo necesitamos verificar que guardamos bien tu dirección de correo electrónico.',
  CHECK_YOUR_INBOX:
    'Para ello te pedimos que revises tu bandeja de entrada y lo confirmes en el mail que te acabamos de enviar.',
  USER_EXISTS:
    'Usuario ya existe, debes ingresar otro email o loguearte haciendo click en la opción de Ingresar.',
  FLOOR_TO_CEILING_HEIGHT: 'Altura del piso al techo',
  OWNER_TYPE: '¿Eres el/la dueño/a de la propiedad?',
  SUITE_BEDROOM_COUNT: 'Dormitorios en suite',
  BEDROOM_COUNT: 'Dormitorios',
  BATHROOM_COUNT: 'Baños',
  RESIDENT_TYPE: '¿Alguien vive en el inmueble actualmente?',
  INNER_AREA: 'Superficie interior',
  ESTIMATE_PRICE: 'Valor arriendo',
  MISSING_FIELDS: 'Campos faltantes:',
  TERRAIN_AREA: 'Superficie terreno',
  FULL_NAME: 'Nombre completo de arrendatario',
  PROMOTION_DESCRIPTION: 'Esta promoción consiste en:',
  CHANGE_OF_PASSWORD: 'Cambio de contraseña',
  CHANGE_OF_PASSWORD_SUCCESSFUL: 'Cambio de contraseña exitoso',
  PASSWORD_UPDATE: 'Tu contraseña se ha actualizado correctamente',
  VERIFY_REFERRED_CODE: 'Ingresa un código de referencia válido',
  IDENTITY_DOCUMENT_UPLOAD:
    'Para una mejor experiencia debes subir tu documento de identidad.',
  USERLIST_REQUEST_SENT:
    'La solicitud está siendo procesada. En un momento el archivo será enviado a tu correo',
  SEND_USERLIST: 'Enviar listado de usuarios',
  SIMPLE_DATA_SAVED: 'Datos guardados',
  PROFILE_REMINDER:
    "Recuerda que debes <span class='font-weight-bold'>ENVIAR</span> tu perfil para que lo podamos revisar y puedas continuar tu proceso",
  PROFILE_HAS_BEEN_SAVED: '¡Tus datos han sido guardados!',
  OK_LETS_GO: '¡Ok, sigamos!',
  PROPERTY_DATA_TITLE: 'Datos de tu propiedad',
  COMMON_EXPRENSES_PLACEHOLDER: 'Ingresa valor estimado',
  OTHER_SELECTED_ROLE_PLACEHOLDER: 'Corredor, Proveedor de servicios, etc.',
  VISIT_SCHEDULED_SHORT: '¡Tu visita fue agendada!',
  WE_RECOMMEND_CREATING_ACCOUNT:
    'Te recomendamos crear tu cuenta para avanzar más rápido en tu proceso en Tango',
  CREATE_MY_ACCOUNT: 'Crear mi cuenta',
  HAVE_A_REFERENCE_CODE: 'Tengo un código de referencia',
  COMMUNICATE_WITH_TANGO_MESSAGEBIRD:
    'Comunícate con tango a través del chat haciendo click ',
  SUCCESS_FEE_DISCOUNT_DETAIL: 'de descuento en el fee de éxito',
  ADMIN_FEE_DISCOUNT_DETAIL: 'de descuento en el fee de administración por ',
  WE_INVITE_COMPLETE_USER_PROFILE:
    'Te invitamos a completar los datos de tu perfil (opcional)',
  DO_IT_LATER: 'No por ahora',
  HOUSE_DPTO: 'Nº casa / depto',
  TOTAL_M2: 'Total m²',
  EXAMPLE_UNIT_NUMBER: 'Ej: Nº 10',
  WANT_RENT:
    'Si quieres arrendar esta propiedad, debes adjuntar todos tus documentos',
  HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
  SIMPLE_STEPS_VISIT: 'En cuatro pasos tu propiedad estará publicada',
  CONTACT_DATA: 'Indica tus datos de contacto',
  UNIT_DATA: 'Ingresa los datos básicos de tu propiedad',
  SELECT_VISIT_TIME: 'Selecciona un horario de la agenda de nuestro fotógrafo',
  AND_THATS_IT: '¡y...listo!',
  CHECK_INBOX_TO_CONTINUE: 'Revisa tu correo para que continúes con el proceso',
  OK: '¡Ok!',
  VERIFICATION_MISSING:
    'Solo falta que verifiques tu cuenta. Puedes solicitar el correo de verificación haciendo clic ',
  ALREADY_VERIFIED_USER:
    'Este email ya está registrado con nosotros. Ingresa a tu cuenta con tu correo y contraseña.',
  CANNOT_USE_GOOGLE_EMAIL:
    'Tu correo ya está registrado con Google, inicia sesión utilizando Google',
  DISABLED_ACCOUNT: 'Tu cuenta está desabilitada. Contáctate con Tango a ',
  REGISTRATION_MISSING:
    'Solo falta que termines tu registro. Puedes solicitar el correo para continuar haciendo clic ',
  STEP_ALREADY_DONE: '¿Ya completaste este paso? Puedes continuar ',
  USER_DOES_NOT_EXIST: 'Usuario no existe',
  CONTINUE_FROM_THIS_STEP: 'Ahora puedes continuar desde este paso',
  ID_REDIRECTION_INSTRUCTION:
    'Para poder enviar tu perfil hace falta que subas tu documento de identidad',
  PERFECT: 'Perfecto!',
  COMPLETE_YOUR_PROFILE: 'Completar tu Perfil',
  CREATE_PROPERTY: 'Crear propiedad',
  REMIND_YOU: 'Además te recordamos que:',
  ID_UPLOADED: 'Ya subiste tu documento de identidad.',
  REMEMBER_TO_SEND_PROFILE:
    "No olvides <span class='font-weight-bold'>ENVIAR</span> tu perfil para que sea aprobado y así puedas arrendar en Tango",
  FULL_ADDRESS_FORM_SUGGESTION:
    'Para poder continuar necesitamos que completes la siguiente información',
  FULL_ADDRESS_FORM_CONFIRMATION:
    '¿Confirmas que ésta es tu dirección personal y es distinta de la dirección de propiedad que quieres administrar?',
  WATER_HEATING: 'Suministro de agua caliente',
  CAULDRON: 'Caldera',
  CALEFONT: 'Calefont',
  CENTRAL: 'Central',
  ELECTRIC_WATER_HEATER: 'Termo eléctrico',
  OTHER_SYSTEM: 'Otro',
  I_WANT_TO_SIGN_UP: 'Quiero registrarme',
  WATER_HEATING_SYSTEM: 'Calefacción agua',
  PROFILE_STATE: 'Estado perfil',
  UPLOAD_YOUR_IDENTITY_DOCUMENT:
    '*Recuerda que para que tus datos sean completados, debes subir tu documento de identidad',
  PROPERTY_WITH_TANGOBOX: '*Propiedad con Tangobox',
  CANNOT_PUBLISH:
    'Debes llenar todos campos de la propiedad antes de poder publicarla',
  FINISHED: 'Finalizada',
  HOW_TO_LEASE_MY_PROPERTY: 'Cómo arrendar mi propiedad',
  REGISTER_OWNER: 'Registrarme como propietario',
  REGISTER_RENTER: 'Registrarme como arrendatario',
  RENTER_HELP_TO_SCHEDULE_VISIT_MESSAGE:
    'Hola, necesito ayuda para agendar un tour en esta propiedad',
  RENTER_HELP_TO_NEW_UNIT_CREATION:
    'Hola, necesito ayuda para registrar mi propiedad',
  OWNER_HELP_TO_SCHEDULE_VISIT_MESSAGE:
    'Hola, necesito ayuda para agendar una visita en esta propiedad',
  REGISTRATION_PENDING:
    'Solo falta que termines tu registro. Te hemos enviado un correo para que puedas continuar',
  REGISTRATION_STEP_PENDING:
    'Ups! Falta que termines un paso en el agendamiento con fotógrafo',
  RENTER_HELP_TO_SCHEDULE_VISIT:
    "Si no existen horarios disponibles para agendar tour, <u><span class='text-color-secondary cursor-pointer'>contáctanos aquí</span></u>",
  OWNER_HELP_TO_SCHEDULE_VISIT:
    "Si no existen horarios disponibles para tu visita, <u><span class='text-color-secondary cursor-pointer'>contáctanos aquí</span></u>",
  DATA_EMPTY_REQUIRED:
    'Existen campos sin completar, por favor revisa el formulario para poder enviarlo.',
  UPLOAD_ONE_PHOTO: 'Debes subir una foto como mínimo',
  PROPERTY_NO_VERIFIED: 'La propiedad no pudo ser verificada',
  LOW_AMOUNT:
    'Lamentablemente el monto de arriendo supera el monto aprobado para tu perfil, puedes solicitar una reevaluación por parte de nuestro equipo Tango ',
  VIDEO_REQUEST_ERROR:
    'Video no pudo ser cargado. Intenta con un video más liviano o revisa tu conexión a internet',
  SEND_TEST_ID: 'Enviar CI de prueba',
  I_WANT_RENT_AMOUNT_EXCEEDS:
    'Hola, quiero arrendar esta propiedad pero el monto de arriendo supera el aprobado para mi perfil',
  ONCE_ACCOUNT_VALIDATED: 'Una vez validada tu cuenta podrás utilizar Tango',
  DETAIL: 'Detalle',
  STATE: 'Estado',
  CONTRACT_TYPE: 'Tipo contrato',
  PROPERTY_ADDRESS: 'Dirección propiedad',
  LEASE_RENEWAL: 'Renovación de contrato',
  CLOSURE_LEASE: 'Solicitar término de arriendo',
  LEASE_TERMINATION: 'Término de contrato',
  DAYS_LEFT_FOR_RENEWAL: "Quedan <span class='font-weight-bold'>{days_left} días</span> ({due_at}) para el vencimiento de tu contrato de arriendo.",
  SOME_OPTIONS: 'Selecciona una de estas opciones:',
  WE_ARE_SORRY: 'Lamentamos que no quieras renovar este contrato de arriendo',
  TELL_YOUR_REASONS:
    'Para continuar con este proceso necesitamos que nos comentes tus razones:',
  WE_REMIND_YOU:
    'Esta información es confidencial y sólo podrá ser vista por el equipo Tango',
  RENEW_LEASE_AGREEMENT: 'Acepto nuevo precio de arriendo',
  NO_RENEW_LEASE_AGREEMENT: 'No acepto nuevo precio de arriendo',
  INDICATE_YOUR_REASONS: 'Aquí puedes indicar tus motivos',
  SUBMIT_RENEWAL_PROPOSAL: 'Enviar propuesta de renovación',
  SUBMIT_RENEWAL_REJECTION: 'Enviar no renovación de contrato',
  RENEWAL_REJECTION_SENT: 'No se renovará el contrato de arriendo',
  RENEWAL_REJECTION_SENT_MEDIATION: 'Contrato de arriendo quedó en mediación',
  ARE_YOU_SURE_TO_REJECT:
    '¿Estás seguro de no renovar este contrato por las razones indicadas?',
  ARE_YOU_SURE_TO_DELETE_IMAGE:
    "Estás seguro que quieres eliminar la<span class='font-weight-bold'> imagen Nº{image_number} del carrusel</span>?",
  WE_HAVE_SENT_YOUR_INFORMATION:
    'Una persona de nuestro equipo te contactará para acompañarte en este proceso.',
  CONTRACT_STATUS_REJECTED:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">NO RENOVADO</span>',
  CONTRACT_STATUS_MEDIATION:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">DE MEDIACIÓN</span>',
  TAKE_INTO_CONSIDERATION:
    'Ten en consideración los pasos a seguir para continuar con Tango',
  AN_ADMIN_WILL_CONTACT_YOU:
    'Un Administrador te contactará para entregarte información y responder a cualquier consulta que tengas',
  AN_ADMIN_WILL_CONTACT_YOU_REJECT:
    'Un Administrador te contactará para agendar la Restitución de la propiedad',
  AN_ADMIN_WILL_CONTACT_YOU_2: 'Un Administrador te contactará',
  PROCESS_RESTITUTION: 'Realizaremos la Restitución de la propiedad',
  PROCESS_RESTITUTION_REJECT:
    'Se comenzará con el proceso de Restitución de la propiedad',
  REPUBLICATION_PROCESS: 'Republicaremos la propiedad',
  PROPERTY_INFORMATION_UPDATE: 'Actualizaremos la información de tu propiedad',
  FIND_A_NEW_RENTER: 'Encontraremos un nuevo arrendatario',
  WAIT_FOR_RENTER_ACCEPTANCE:
    'Ahora debes esperar la respuesta del arrendatario, un admin de Tango estará acompañándote en este proceso.',
  RENEWAL_PROPOSAL_SENT: 'Propuesta enviada',
  NEW_LEASING_PRICE: 'Nuevo valor de arriendo',
  CONGRATS_FOR_RENEWING:
    '¡Estamos felices de que sigas arrendando con nosotros!',
  PROPOSE_READJUSTMENT:
    'Proponer un reajuste en el valor del arriendo de acuerdo a la inflación: ${priceSuggestion} (incremento de un {percentage}%)',
  KEEP_CURRENT_PRICE: 'Mantener el valor del arriendo actual ${currentPrice}',
  PROPOSE_NEW_PRICE: 'Proponer un precio distinto',
  NEW_PRICE_VALIDATION: 'El nuevo precio de arriendo no puede ser menor a cero',
  SELECT_RENEWAL_OPTIONS:
    'Para continuar con este proceso debes seleccionar una de las siguientes opciones:',
  RENTER_ACCEPTS_CURRENT_PRICE:
    'Acepto renovar el contrato de arriendo manteniendo el valor del arriendo a ${currentPrice}',
  RENTER_ACCEPTS_PROPOSED_PRICE:
    'Acepto renovar el contrato de arriendo con un reajuste en el valor de arriendo quedando en ${proposedPrice} ({variation} de un {percentage}%)',
  THANKS_FOR_CONTINUING_WITH_US: 'Agradecemos que continues con nosotros',
  RENEWAL_SENT: 'Renovación enviada exitosamente',
  RENTER_ACCEPTS_READJUSTMENT:
    'Aceptas renovar con la propuesta de reajuste informada',
  RENTER_INTERESTED_IN_REJECTING:
    'Te interesa renovar pero deseas proponer un monto distinto del reajuste informado, o puedes decidir no renovar.',
  OWNER_PRICE_READJUSTMENT:
    '<strong>El propietario/a ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{proposedPrice}</span>, equivalente a {variation} de {percentage} (correspondiente a la inflación del periodo)</strong>',
  CURRENCY_CHANGE: '<strong>, con un cambio de unidad monetaria a {currency}.</strong>',
  CURRENCY_INFO: '<strong>Al aceptar el nuevo precio de arriendo, confirmas que tu contrato se pactará en {currency}</strong>',
  PROPERTY_CONTRACTS_AND_ACTIONS: 'Contratos y acciones de la propiedad',
  PENDING_UPLOAD: 'pendiente de subida',
  MEDIATION: 'Mediación',
  RENEWAL_MEDIATION_PROCESS: 'Renovación de contrato',
  PROPERTY_HISTORY: 'Historial de la propiedad',
  PROPERTY_REGISTER: 'Registro de la propiedad en Tango',
  VISIT_SCHEDULE_BY_PHOTOGRAPHER: 'Agendamiento visita por fotógrafo',
  PROPERTY_INFORMATION_REVIEW: 'Revisión de información de la propiedad',
  CONTRACT_STATUS_ACCEPTED:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">{state}</span>',
  LEASE_ANNEX_AVAILABILITY: 'Gracias por seguir con nosotros',
  CONTRACT_STATUS_PENDING:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">EN PROCESO DE RENOVACIÓN</span>',
  ANNEX_CREATION: 'Esperar la respuesta del {other_part}.',
  RENTER_CONFIRMS_RENEWAL:
    'Para continuar con este proceso debes confirmar tu decisión:',
  RENTER_PRICE_PROPOSAL:
    'No acepto el incremento indicado, deseo proponer un nuevo valor de arriendo',
  LEASE_RENEWAL_REJECTION: 'No quiero continuar con el arriendo',
  RENEWAL_REFUSAL: 'No renovar',
  THE_LESSEE_RESPONDED_NEW_VALUE:
    '<strong>El arrendatario/a ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{proposedPrice}</span></strong> (Valor referencial: {reference}), <strong>equivalente a {variation} de {percentage}</strong>',
  THE_LESSEE_RESPONDED_NEW_VALUE_EQUIVALENT:
    '<strong>El arrendatario/a ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{proposedPrice}</span></strong> (Valor referencial: {reference}), <strong>equivalente al del arriendo actual</strong>',  
  INCREASE_TEXT: 'aumento',
  DECREASE_TEXT: 'disminución',
  LEASE_CONTRACT_WILL_BE_RENEWD:
    'El contrato de arriendo será renovado de forma automática cuando se cumpla el plazo de 60 días previos al vencimiento del contrato.',
  IMPORTANT_TEXT: 'Importante',
  WE_UNDERSTAND_WITH_OPTIONS:
    'Lamentamos que no quieras renovar este contrato de arriendo... Te damos algunas alternativas para confirmar tu decisión',
  PERCENTAGE_INCREASE: 'Este aumento corresponde a un ',
  COUNTERPROPOSAL_TITLE: 'Contra propuesta de valor de arriendo',
  COUNTERPROPOSAL_CONFIRMATION: '¿Estás seguro/a de enviar esta propuesta?',
  COUNTERPROPOSAL_WARNING:
    'Estás a punto de enviar una contra propuesta por un monto menor al valor actual',
  CONTRACT_STATUS_NEGOTIATING:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">EN NEGOCIACIÓN</span>',
  MUTUAL_AGREEMENT: 'Acuerdo entre las partes',
  POSSIBLE_RENEWAL: 'Renovación de contrato / No renovación de contrato',
  NO_RENEWAL_CASE:
    'Si no existe renovación de contrato, actualización de información de perfil; en caso de querer continuar arrendando en Tango',
  FIND_NEW_LEASE:
    'Encontrar un nuevo arriendo (en caso de querer continuar arrendando en Tango)',
  FIND_NEW_LEASE_REJECT: 'Buscaremos tu nuevo hogar',
  PERCENTAGE_DECREASE: 'Esta disminución corresponde a un ',
  OWNER_KEEPS_PRICE:
    'El propietario/a ha propuesto <span class="font-weight-bold text-color-secondary">mantener el valor de arriendo de ${proposedPrice}</span>',
  WE_ARE_HAPPY_YOU_RENEW:
    '¡Estamos felices de que quieras renovar tu contrato!',
  WAIT_FOR_OTHER_PART: 'Ahora debes esperar la respuesta del {other_part}.',
  PART_OWNER: 'propietario/a',
  PART_RENTER: 'arrendatario/a',
  ADMIN_COMPANY:
    'Un administrador de Tango estará acompañándote en este proceso.',
  COUNTERPROPOSAL_CONFIRMATION_QUESTION:
    '¿Estás seguro/a de enviar esta contra propuesta al propietario/a?',
  KEYS_HANDOVER: 'Entrega de llaves',
  KEYS_HANDOVER_REJECT: 'Coordinaremos la entrega de llaves',
  PROFILE_UPDATE: 'Actualización de información de perfil',
  PROFILE_UPDATE_REJECT:
    'Te solicitaremos la información para la actualización de información de tu perfil',
  RENEWAL_NEWS: 'Noticias sobre tu renovación',
  OWNER_SENT_PROPOSAL:
    'El propietario de tu contrato de arriendo envió una propuesta para este proceso.',
  CHECK_PROPOSAL: 'Puedes revisarla aquí.',
  CHECK: 'Revisar',
  ACCEPT_RENEWAL: 'Aceptar renovación',
  AN_INCREASE: 'un aumento',
  A_DECREASE: 'una disminución',
  RENEWED: 'Renovado',
  RENEWAL_REJECTED: 'La no renovación de contrato fue enviada exitosamente',
  RENEWAL_UPDATE: 'La propuesta de renovación ha sido enviada exitosamente',
  ADMIN_MEDIATION:
    '<strong>El administrador ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{price}</span></strong> (Valor referencial {reference}), <strong>equivalente a {percentage_text} de {percentage_number}</strong>',
  CONTRACT_STATUS_RENEWED:
    'El contrato para esta propiedad se encuentra con estado <span class="text-color-secondary">{state}</span>',
  ACCEPT_MEDIATION: 'Aceptar propuesta de administrador',
  REJECT_MEDIATION: 'No aceptar propuesta de administrador',
  NOT_ACCEPT: 'No aceptar',
  MEDIATION_RESPONSE_SENT: 'Respuesta a mediación',
  PROPOSE_NEW_PRICE_ADMIN: 'Proponer nuevo precio',
  NEW_PROPOSED_PRICE: 'Proponer nuevo precio',
  NEW_PROPOSED_PRICE_REASON: 'Razones de nueva propuesta (Opcional)',
  NO_RENEWAL_REASON: 'Razones de no renovación',
  MEDIATION_PROCCESS: 'Proceso de mediación',
  NEGOTIATING_STATE: 'EN PROCESO DE NEGOCIACIÓN',
  MEDIATION_ACCEPTED_STATE: 'EN ESPERA DE RESPUESTA',
  IN_PROGRESS_STATE: 'EN PROCESO',
  MEDIATION_STATE: 'EN MEDIACIÓN',
  LEASE_ANNEX_SIGNING: 'EN PROCESO DE FIRMA',
  NOT_ALLOWED_AMOUNT: 'Monto no permitido, ingrese un monto mayor',
  IF_YOU_HAVE_QUESTION:
    'Si tienes alguna duda, puedes comunicarte con nosotros ',
  SIGN_CONTRACT_ANNEX: 'Firmar anexo de contrato',
  WAIT_SIGNING_OWNER: 'Esperar firma de contrato Propietario',
  SEE_CONTRACT_ANNEX: 'Ver anexo de contrato',
  DELIVERY_CERTIFICATE: 'Acta de entrega',
  DELIVERY_CERTIFICATE_REJECT: 'Se generará el acta de entrega',
  OUR_TEAM_WILL_SEND_YOU_A_PROPOSAL:
    'Nuestro equipo te enviará una propuesta con lo acordado por las partes',
  ANSWER_RECEIVED_PROPOSAL: 'Aceptar/No aceptar propuesta recibida',
  WAIT_OWNER_RESPONSE: 'Esperar la respuesta del Propietario/a',
  WAIT_RENTER_RESPONSE: 'Esperar la respuesta del Arrendatario/a',
  WAIT_SIGNING_RENTER: 'Esperar firma de contrato Arrendatario/a',
  CONTACT_INFORMATION: '<strong>DATOS DE CONTACTO</strong>',
  RENTER_CONTACT: 'Contacto arrendatario',
  OWNER_CONTACT: 'Contacto propietario',
  RENTER_NAME: 'Nombre arrendatario',
  OWNER_NAME: 'Nombre propietario',
  RENTER_EMAIL_2: 'Correo electrónico arrendatario',
  OWNER_EMAIL: 'Correo electrónico propietario',
  INFORMATION_CONSIDER: 'Información a considerar',
  DAYS_LEFT_FOR_MEDIATION:
    '{days_left, plural, =1 {Queda} other {Quedan}} <strong>{days_left, plural, =1 {1 día} other {# días}}</strong> para el término del periodo de mediación finalice.',
  SUGGESTED_PRICE:
    "Se sugiere un precio de arriendo de  <strong class='text-color-secondary'>{suggested_price}</strong>  que considera un ajuste en la inflación de un {inflation}",
  CURRENT_INFLATION_VALUE:
    'El valor actual de la inflación es de un {inflation} (calculado en base a la variación porcentual de la UF en el periodo de duración del contrato de arriendo)',
  CURRENT_LEASE_VALUE:
    'El valor actual del arriendo es de {currentLeasePeriodPrice}',
  ENTER_REASON: 'Ingrese el motivo de la opción seleccionada',
  ENTER_VALUE: 'Ingrese monto propuesto',
  SELECT_OPTION: 'Seleccione una opción',
  ARE_YOU_SURE_TO_REJECT_ADMIN:
    '¿Estás seguro/a de no renovar el contrato de arriendo?',
  ARE_YOU_SURE_TO_PROPOSE_ADMIN:
    '¿Estás seguro/a de enviar la propuesta por el precio indicado?',
  NEW_PRICE_PROPOSE: 'Propuesta nuevo precio',
  NO_RENEWAL: 'No renovación',
  NEW_LEASE_PROPOSAL: 'Nueva propuesta de precio de arriendo',
  RENEWAL_REJECTED_SENT: 'No renovación enviada',
  DATA_SENT_TO_RENTER_OWNER:
    'La información ha sido enviada al propietario/a y al Arrendatario/a.',
  CONTINUE_PROCCESS:
    'Para continuar con este proceso debes esperar la respuesta de ambas partes.',
  SEND_MEDIATION_RESPONSE: 'Enviar respuesta a mediación',
  NO_CONTRACT_RENEWAL: 'No renovación de contrato',
  ARE_YOU_SURE_TO_REJECT_MEDIATION:
    '¿Estás seguro/a de que no quieres renovar este contrato de arriendo?',
  RESPONSE_SENT: 'Tu respuesta ha sido enviada.',
  ADMIN_COMPANY_FOR_DOUBTS:
    'Recuerda que un Administrador estará acompañándote y resolviendo tus dudas.',
  DATA_NO_RENEWAL_SENT: 'Información de No renovación enviada.',
  CONTACT_PHOTOGRAPHER:
    'Debes comunicarte con el tanguero/fotógrafo para continuar con el proceso',
  SEND_NEW_PROPOSAL: 'Enviar nueva propuesta',
  OWNER_PROPOSAL_ACCEPTED: 'Propiestario aceptó la propuesta',
  RENTER_PROPOSAL_ACCEPTED: 'Arrendatario aceptó la propuesta',
  OWNER_PROPOSE:
    'Propietario hizo una propuesta por un monto de  <strong>{price}</strong> correspondiente a una inflación de <strong>{inflation}</strong>',
  OWNER_PROPOSE_UF:
    '<strong>{owner}</strong> envió una propuesta de <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{price}</span> <span class="text-color-tertiary">(Valor referencial: {reference})</span> {date} {hour}',
  RENTER_PROPOSE_UF:
    '<strong>{renter}</strong> envió una propuesta de <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{price}</span> <span class="text-color-tertiary">(Valor referencial: {reference})</span> {date} {hour}',
  RENTER_PROPOSE:
    'Arrendatario hizo una propuesta por un monto de  <strong>{price}</strong> correspondiente a una inflación de <strong>{inflation}</strong>,',
  SEND_COUNTERPROPOSAL: 'Enviar contrapropuesta',
  RENEWAL_ACCEPTED: 'Renovación aceptada',
  MEDIATION_END: 'El proceso de mediación ha terminado',
  REJECT_COUNTERPROPOSAL: 'No estoy de acuerdo con la propuesta',
  WE_ARE_SORRY_COUNTERPROPOSAL_REJECTION:
    'Lamentamos que no estés de acuerdo con la propuesta del arrendatario',
  MEDIATION_CONFIRMATION:
    'Al continuar con esta decisión comenzará un proceso de mediación.',
  MEDIATION_PROCESS: 'Proceso de mediación',
  INFLATION_EXPLANATION:
    "{variation} de un <span class='font-weight-bold'>{percentage}</span> correspondiente a la inflación del periodo (cálculo estimado, éste puede variar el día en el que se realice la renovación)",
  WAIT_RESPONSE_TEXT:
    'Al enviar tu propuesta debes esperar la respuesta del {other_part}',
  RECOMMENDED: 'Recomendado',
  NEW_PRICE: 'Nuevo valor',
  RENEW_WITH_PROPOSED_PRICE: 'Renovar con precio propuesto',
  REJECT_PROPOSED_PRICE: 'No acepto nuevo precio de arriendo',
  RESTITUTION_WARNING:
    'Al confirmar tu decisión iniciará el proceso de restitución de la propiedad',
  AN_ADMIN_WILL_CONTACT_YOU_LATER:
    'Un Administrador de Tango se pondrá en contacto contigo',
  REPUBLISH_PROPERTY: 'Republicar la propiedad',
  PROPOSE_A_PRICE: 'Proponer nuevo valor',
  BACK: 'Volver',
  REJECTION_REASONS: 'Razones no renovación',
  ACCEPT_NEW_PRICE: 'Acepto nuevo precio de arriendo',
  POPOVER_INFLATION_INFO:
    'Calculado del valor porcentual de la UF en el periodo de duración del contrato. (IPC)',
  PRICE_CAN_VARY:
    'Este precio puede variar según el cálculo a realizar en la fecha en la que se realizará la renovación.',
  PRICE_DIFFERENCE:
    "{variation} de un <span class='font-weight-bold'>{percentage}</span> del precio de arriendo actual",
  OWNER_PRICE_WITHOUT_INFLATION:
    '<strong>El propietario/a ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{proposedPrice}</span></strong> (Valor referencial {reference}), <strong>equivalente a {variation} de {percentage}</strong>',
  OWNER_PRICE_WITHOUT_INFLATION_EQUIVALENT:
    '<strong>El propietario/a ha propuesto un <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{proposedPrice}</span></strong> (Valor referencial {reference}), <strong>equivalente al del arriendo actual</strong>',  
  MEDIATION_DAYS_OVERDUE_FOR_ADMIN:
    'El periodo de mediación ha finalizado para las partes.',
  MEDIATION_DAYS_OVERDUE:
    '{days_left, plural, =1 {Queda} other {Quedan}} <strong>{days_left, plural, =1 {1 día} other {# días}}</strong>. El periodo de mediación ha finalizado.',
  DAYS_LEFT_FOR_MEDIATION_ADMIN:
    '{days_left, plural, =1 {Queda} other {Quedan}} <strong>{days_left, plural, =1 {1 día} other {# días}}</strong> para que el periodo de mediación de las partes',
  MINUTES: 'minutos',
  SECONDS: 'segundos',
  HOURS: 'horas',
  AGO: 'atrás',
  TRANSITION_TIME_ERROR: 'Debes ingresar entre 3 y 5 segundos',
  FROM_NOTIFIER: 'desde',
  NEGOTIATION_DAYS_LEFT: "Tienes <span class='font-weight-bold'>{days} días</span> para tomar acción. Luego de este período, el contrato de arriendo se renovará automáticamente.",
  NEGOTIATION_DAYS_INFO: 'Luego de este periodo, el contrato de arriendo se renovará automáticamente con un ajuste de precio correspondiente a la inflación del periodo.',
  END_OF_LEASE: 'Termino de contrato de arriendo',
  PENDING_REPUBLISH: 'Pendientes a republicar',
  REQUISITION: 'Término anticipado',
  REPUBLISH: 'Republicar',
  CURRENT_LEASE_PRICE: 'Valor actual del arriendo',
  INITIAL_LEASE_PRICE: 'Valor inicial del arriendo',
  LAST_RENEWAL_DATE: 'Fecha de última renovación',
  LAST_LEASE_PRICE: 'Valor arriendo anterior',
  BANK_DETAILS_SOMEONE_ELSE:
    'Los datos bancarios que ingresaste ¿Pertenecen a otra persona?',
  LEASE_TERMINATION_ANNEX: 'Término de contrato de arriendo',
  LEASE_RENEWAL_PERIOD: 'Renovación de contrato de arriendo',
  IS_IMPORTANT_YOU_INFORM_US:
    'Es importante que nos informes si los datos que ingresaste son tuyos o pertenecen a otra persona. De esta forma podremos evitar errores al momento de los pagos.',
  THIRD_PARTY_BANK: 'Datos bancarios de terceros',
  SCHEDULE_TOUR_WITH_TANGO_MESSAGEBIRD:
    'Para agendar un tour comunícate con tango a través del chat de messagebird',
  NEXT_PERIOD_DATE: 'Inicio del próximo periodo',
  SIGNED: 'Firmada',
  LEASE_RENEWAL_PERIOD_DATE: 'Fecha de renovación',
  SELECT_PAYMENT_METHOD: 'Selecciona medio de pago',
  SERVIPAG_METHOD_TEXT:
    'Pagos electrónicos con cargo a cuenta corriente, vista o cuenta Rut. Pagos electrónicos con tarjetas de casas comerciales (CMR, Ripley, Cencosud).',
  PADPOW_METHOD_TEXT:
    'Pagos electrónicos con tarjetas de crédito y débito de bancos nacionales.',
  NEAT_METHOD_TEXT: 'Todas las tarjetas de débito y crédito nacionales.',
  NEAT_METHOD_TEXT_DISABLED: "<span class='text-color-dark-gray'>Todas las tarjetas de débido y crédito nacionales.</span>",
  NEAT_METHOD_INFO: "El pago con <span class='font-weight-bold'>Neat</span>, está disponible <span class='font-weight-bold'>los primeros 5 días</span> de cada mes.",
  NEAT_METHOD_TITLE: 'Pago con Neat',
  PAYMENT_METHODS_FINTOC: 'Paga con transferencia en menos de un 1 minuto',
  PAYMENT_METHODS_FINTOC_SUCCESS: 'Pago efectuado, los cobros se verán reflejados en los próximos minutos',
  PAYMENT_METHODS_FINTOC_FAILED: 'Lo sentimos, Fintoc tuvo un inconveniente.',
  GO_TO_NEAT: 'Ir a pagar con Neat',
  NEAT_METHOD_INFO_POPUP: 'Si no tienes cuenta de Neat, deberás registrarte.',
  NEAT_METHOD_INFO_POPUP_2: 'Ten en cuenta que los pagos realizados en Neat se verán reflejados luego de 5 días hábiles en la web de Tango.',
  ADMIN_CONTRACT: 'Contrato de administración',
  ADMIN_CONTRACT_ANNEX: 'Anexos de contrato de administración',
  LEASE_CONTRACT_ANNEX: 'Anexos de contrato de arriendo',
  DELIVERY_VOUCHER: 'Acta de entrega',
  RESTORATION_VOUCHER: 'Acta de restitución',
  RENEWAL_ACCEPTANCE: 'Renovación',
  RENEWAL_REJECTION: 'Término de contrato',
  DOCUMENT_TYPE: 'Tipo de documento',
  DOCUMENT: 'Documento',
  UPLOAD: 'Cargar',
  INFO_UPLOAD_DOCUMENTS: 'El formato admitido es PDF',
  FILE: 'Archivo',
  NAME_DOCUMENT_INFO: 'Nombre específico del documento',
  SELECT_AN_OPTION: 'Selecciona una opción',
  SELECT_FILE_TO_UPLOAD: 'Selecciona el documento a subir',
  ENABLE: 'Habilitar',
  DISABLE: 'Deshabilitar',
  ENABLE_DOCUMENT: 'Habilitar documento',
  DISABLE_DOCUMENT: 'Deshabilitar documento',
  PENDING_DOCUMENT_STATE: 'El documento no ha sido ni aprobado ni rechazado.',
  ENABLE_DOCUMENT_STATE: 'El documento ha sido aprobado por un admin.',
  DISABLE_DOCUMENT_STATE:
    'El documento ha sido rechazado por un admin o fue reemplazado por otro que se habilitó.',
  ENABLED: 'Habilitado',
  DISABLED: 'Deshabilitado',
  DOCUMENT_STATE: 'Estado de los documentos',
  DOCUMENTS_LIST: 'Listado de documentos',
  CREATED_DATE: 'Fecha de creación',
  UPDATED_DATE: 'Fecha de actualización',
  CREATED_BY: 'Creado por',
  UPDATED_BY: 'Actualizado por',
  LEASE_DOCUMENTS_TITLE: 'Documentos de arriendo',
  PROPERTY_DOCUMENTS_TITLE: 'Documentos de la propiedad',
  REJECT_DOCUMENT: 'Rechazar documento',
  APPROVE_DOCUMENT: 'Aprobar documento',
  ENABLING_DOC_CONFIRMATION: '¿Estás seguro/a de habilitar este documento?',
  SEE_DOCUMENT: 'Ver documento',
  PLAN_FULL: 'Full Tango',
  PLAN_BROKERAGE: 'Solo Corretaje',
  PLAN_ADMINISTRATION: 'Solo administración',
  PLAN_FULL_INFO:
    'LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM',
  PLAN_BROKERAGE_INFO:
    'LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM',
  PLAN_ADMINISTRATION_INFO:
    'LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM LOREM IPSUM',
  BEFORE_CREATING_BROKERAGE:
    'Antes de comenzar tu arriendo conoce más detalles de nuestro servicio',
  BEFORE_CREATING_BROKERAGE_2:
    'Te invitamos a conocer una nueva experiencia junto a nosotros',
  TITLE_CREATE_PROPERTY_BROKERAGE: 'Registra tu propiedad en Tango',
  DESCRIPTION_CREATE_PROPERTY_BROKERAGE:
    'Este proceso no te tomará más de <span class="text-color-secondary">5 minutos<span>.',
  TITLE_VERIFY_AND_COMPLETE_INFO: 'Verifica y completa la información',
  DESCRIPTION_VERIFY_AND_COMPLETE_INFO:
    'Asegurate de ingresar los datos correctos para que tu publicación quede completa.',
  TITLE_VISIT_SCHEDULE: 'Agenda visita con uno de nuestros Fotógrafos',
  DESCRIPTION_VISIT_SCHEDULE:
    'Él se encargará de tomar fotos y videos profesionales de tu propiedad, hará la carga de toda la información en menos de 48 horas y tu propiedad será publicada.',
  TITLE_OPTIONAL_TANGO_BOX: 'Tangobox (Opcional)',
  DESCRIPTION_OPTIONAL_TANGO_BOX:
    'Si tu propiedad está vacía, sin muebles ni arrendatarios, instalaremos un Tangobox, dispositivo de almacenamiento de llaves, que permite recibir visitas en tu inmueble sin necesidad de que tú estés presente.',
  DESCRIPTION_PROPERTY_PROPOSALS_BROKERAGE:
    'Cada posible arrendatario pasará por un exhaustivo análisis de crédito, donde le asignaremos un puntaje Tango, para asegurarnos de seleccionar a los mejores candidatos. <br> Te enviaremos todas las propuestas a través de nuestra plataforma, donde podrás ver el detalle de la información de cada candidato, para que puedas tomar la mejor decisión.',
  DESCRIPTION_SIGNATURE_BROKERAGE:
    "Revisa y <span class='text-color-secondary'>firma el contrato</span> de Arriendo de manera <span class='text-color-secondary'>online con Tango</span>.",
  DONE: 'Listo',
  DESCRIPTION_DONE: 'Nosotros nos encargamos del arriendo de tu propiedad',
  MIN_PROPERTY_VALUE: '$200.000',
  DESCRIPTION_BROKERAGE_SERVICE:
    "Publicamos, mostramos, arrendamos y entregamos tu propiedad.<br><br>Revisamos la capacidad de pago y perfil de los posibles candidatos.<br><br>El proceso de <span class='text-color-secondary'>firma de contrato es 100% online</span>.",
  COMMISSION: 'Comisión',
  REGISTER_MY_PROPERTY: 'Registrar mi propiedad',
  NOW_REGISTER_PROPERTY: 'Ahora ya puedes regitrar tu propiedad en',
  SELECT_PLAN: 'Elegir plan',
  SELECT_A_PLAN: 'Elige un plan',
  REGISTER_YOUR_PROPERTY: 'Registra tu propiedad',
  PHOTOS_VIDEOS_SESION: 'Sesión de fotos y video profesional.',
  PUBLICATION_MAIN_PORTALS: 'Publicación en principales portales.',
  SHOW_PROPERTY: 'Mostrar propiedad.',
  SIGN_CONTRACT_ONLINE: 'Firma online del contrato.',
  RENTER_ANALISYS: 'Riguroso análisis del arrendatario.',
  MANAGMENT_PROPERTY: 'Gestión de arreglos del inmueble.',
  NON_PAYMENT_COVERAGE:
    'Cobertura de impago y gestión del proceso de desalojo.',
  MONTHLY_PAYMENT: 'Pago de renta mensual los días 10 de cada mes.',
  REVIEW_APP: 'Panel de control online para revisión de solicitudes y pagos.',
  PLAN_FULL_TAB: 'Full Tango',
  TRANSACTIONS_PAYED: 'Las transacciones han sido pagadas',
  TRANSACTIONS_UNPAYED:
    'Las transacciones han sido devueltas a estado pendiente',
  TANGO_TERMS_AND_CONDITIONS: 'Tango actualizará sus términos y condiciones.',
  ACCEPT_TERMS_AND_CONDITIONS: 'Acepto Términos y Condiciones',
  PLAN_TYPE: 'Tipo de plan',
  DATE_PAY_SERVICE: 'Fecha de pago del servicio',
  OFFER_ACEPTED_BROKERAGE:
    'La propuesta ha sido aceptada. El siguiente paso es <strong>firmar digitalmente el contrato</strong>.</br> Tienes 48 horas para hacerlo, solo necesitas tu documento de identidad y ¡listo!',
  PENDING_VOUCHERS: 'Actas pendientes',
  VOUCHER_ENABLED: 'Acta habilitada',
  RENTER_CONTACT_DATA: 'Datos del arrendatario',
  LEASE_CONTACT_DATA: 'Datos de contacto',
  SIMPLE_EMAIL: 'Correo',
  OWNER_CONTACT_DATA: 'Datos del propietario',
  SIMPLE_RUT: 'Rut',
  TRANSFER_DATA: 'Datos de transferencia',
  PLAN: 'Plan',
  INVITE_AND_EARN: 'Invita y gana',
  WHO_WE_ARE: 'Quienes somos',
  BLOG: 'Blog',
  LOG_IN: 'Inicia sesión',
  YOU_CAN_ALSO: 'O También puedes',
  ANY_QUESTIONS: '¿Tienes dudas?',
  TALK_SOMEONE_FROM_TANGO: 'Hablar con alguien de Tango',
  TALK_WITH_US: 'Conversemos',
  INTENTION_MESSAGE:
    'Hola, necesito mas información de sus servicios para saber como registrarme',
  TANGO_REAL_ESTATE_PORTAL: 'Tango, tu nuevo Portal Inmobiliario',
  LOGO_ALT:
    'Tango portal inmobiliario de administración y arriendo de propiedades en Santiago de Chile',
  PENDING_RESTORATION_VOUCHERS: 'Actas de restitución',
  WRONG_VALUE: 'El valor ingresado no es correcto',
  PENDING_DELIVERY_VOUCHERS: 'Actas de entrega',
  SEE_RESTORATION_VOUCHER: 'Ver acta de restitución',
  MUST_SEE_DOCUMENT_BEFORE_VALIDATION:
    'Debes visualizar el documento antes de validar.',
  EMAIL_EXAMPLE: 'correo@ejemplo.com',
  ENTER_PASSWORD: 'Ingresa tu contraseña',
  EVALUATION: 'Evaluación',
  MY_RENT: 'Mi arriendo',
  SEARCH_PROPERTY: 'Busca propiedades',
  MY_DATA: 'Mis datos',
  NOTIFICATIONS: 'Notificaciones',
  RECOVER: 'Recuperar',
  ALL_PROPERTY: 'Todas',
  SHOW_ALL_NOTIFICATIONS: 'Ver notificaciones anteriores',
  RENT_A_PROPERTY_IN_TANGO:
    'Arrienda tu propiedad en Tango y evita preocupaciones',
  A_RENTAL_AND_ADMIN_PLATAFORM:
    'Una plataforma de arriendo y administración para propietarios y arrendatarios con tecnología y equipo profesional que harán tu proceso ágil y seguro.',
  ALTERNATIVE_TEXT_ZONA_PROPIA: 'Vende tu propiedad en tiempo récord con la alianza de Tango y Zona propia',
  FIND_YOU_NEW_HOME:
    'Encuentra tu nuevo hogar en Tango de forma segura y confiable',
  PUBLISH_PROPERTY_HOME: 'Publicar propiedad',
  I_HAVE_A_PROPERTY: 'Tengo una propiedad',
  RENT_A_PROPERTY_HOME: 'Arrienda una propiedad',
  CLIENTS_TESTIMONIALS: 'Testimonios de nuestros clientes',
  TANGO_IS_GREYSTAR_HOME:
    '<strong>Tango es parte de Greystar la empresa líder mundial</strong> en administración de  arriendo residencial.',
  KNOW_OUR_ALLIANCES: 'Conoce nuestras alianzas',
  OUR_STRATEGIC_ALLIANCES:
    'Nuestras alianzas estratégicas nos permiten potenciar y ayudar a nuestros clientes a desarrollar de mejor manera sus inversiones:',
  YOU_HAVE_DOUBTS: '¿Aún tienes dudas?<br/> <strong>Contáctanos:</strong>',
  ONLINE_ADVICE: 'Asesoría online',
  EMAILHOME: 'Correo',
  ADDRESS_OF_PROPERTY: 'Dirección de la propiedad',
  PROPERTY_BASIC_DATA: 'Datos básicos de tu propiedad',
  BROKERAGE_PAYMENTS: 'Pagos de servicio de corretaje',
  DOUBTS: '¿Tienes dudas?',
  LETS_TALK: 'Conversemos',
  REF_VALUE: 'Valor referencial',
  INDICATOR: 'Indicador',
  UF_INDICATOR_LABEL: `El precio del arriendo corresponde al valor de la UF calculado al primer día de cada mes. Para más información revisar el siguiente`,
  CLF_CONVERSION_INFO: 'Valor referencial <strong>{price}</strong>',
  PRE_EVALUATION: 'Realizaremos una pre-evaluación con tu información, para confirmar la visita a la propiedad seleccionada.',
  SCHEDULE_VISIT_INFORMATION: 'Para agendar una visita necesitamos los siguientes datos',
  MUST_BE_GREATER_THAN: 'Debe ser mayor que {num} caracteres',
  SELECT_DAY_AND_TIME_RANGE: 'Elige el día y el rango horario en el que quieres agendar tu visita',
  CHANGE_VISIT_DATE: 'Cambiar fecha de visita',
  YOU_WILL_RECEIVE_AN_EMAIL: 'Recibirás un correo con la confirmación de tu visita.',
  THESE_ARE_THE_DETAILS: 'Estos son los datos de tu visita',
  THANK_YOU_FOR_TRUSTING_TANGO: '¡Gracias por confiar en Tango!',
  ENTER_PHONE: 'Ingresa tu teléfono',
  RENTER_LOOKING_RENT: 'Busco arrendar una propiedad',
  OWNER_LOOKING_POST: 'Quiero publicar una propiedad',
  ORIGINAL_LEASE_VALUE: "El valor del arriendo actual es de  <span class='text-color-secondary font-weight-bold'> {currentLeasePeriodPrice}*</span>",
  CURRENT_UF_VALUE: 'Valor UF al día de hoy',
  ERROR_MAX_VALUE: 'El valor máximo no puede ser inferior al valor mínimo',
  CONTRACT_WILL_NOT_BE_RENEWED: 'En caso de no tomar ninguna decisión, este contrato no será renovado.',
  REF_VALUE_INFO: '(Valor referencial: {price})',
  PROPOSE_NEW_VALUE: 'Proponer nuevo valor',
  REASON_PROPOSAL:
    'Puedes agregar comentarios para la propuesta de un nuevo precio',
  ADMIN_CONTACT: 'Un administrador de Tango se pondrá en contacto contigo',
  MEDIATION_HISTORY: 'Historial de mediación',
  ADMIN_PROPOSE:
    '<strong>{admin}</strong> envió una propuesta por un monto de {price} {date} {hour}',
  ADMIN_PROPOSE_UF:
    '<strong>{admin}</strong> envió una propuesta de <span class="text-color-secondary">nuevo precio de arriendo</span> de <span class="text-color-secondary">{price}</span> <span class="text-color-tertiary">(Valor referencial: {reference})</span> {date} {hour}',
  CHANGE_CONDITION_CURRENCY: '<strong>, con un cambio de unidad monetaria a {currency}</strong>',
  ACCEPT_NEW_CURRANCY_CONDITION: 'Al aceptar el nuevo precio de arriendo, confirmas que tu contrato de pactará en ',
  NO_RENEW_LEASE_AGREEMENT_ADMIN: 'No renovar contrato de arriendo',
  REF_VALUE_INFO_NO_PARENTHESES: 'Valor referencial {price}',
  MONTHS: 'meses',
  ENTER_YOUR_NAMES: 'Ingresa tu nombre y apellido',
  EMAIL_SAMPLE: 'correo@ejemplo.com',
  WE_NEED_THIS_DATA: 'Para registrarte en Tango, necesitamos los siguientes datos',
  MORE_INFORMATION: 'Hola, me gustaría tener más información sobre mi estado crediticio. Mi correo electrónico es {email}',
  REQUESTS: 'Solicitudes',
  PENDING_REQUESTS: 'Solicitudes pendientes',
  RENTER_WAIT_PRE_APPROVED: 'Pendiente de pre aprobación',
  RENTER_WAIT_PRE_EVALUATION: 'Pendiente de pre evaluación',
  RENTER_CONFIRM_PRE_APPROVED: 'Confirmación pre-aprobación',
  RENTER_CONFIRM_PRE_APPROVED_TEXT: `¿Estás seguro que quieres cambiar el estado del usuario a <b>{state}</b>? esta acción no se puede deshacer`,
  OPTIONAL_COMMENTS: 'Comentarios (opcional)',
  WRITE_COMMENTS_REJECT_APPROVED: 'Escribe los motivos de rechazo de la pre-evaluación',
  REFERRED: 'Referidos',
  KNOW_PERSON: '¿Conoces a esta persona?',
  REFERRED_CONFIRMATION_BUTTON: 'Si, me invitó',
  REFERRED_REJECTION_BUTTON: 'No la conozco',
  REFERRED_FULL_NAME: 'Te refirió <strong>{full_name}</strong>',
  GO_TO_REGISTRATION: 'Ir a registro',
  REFERRED_INFORMATION_1: 'Para hacer efectivo tu código de referencia',
  REFERRED_INFORMATION_2: 'debes <strong>registrarte</strong> en <strong>Tango</strong>',
  REFERRED_INFORMATION_3: 'y <strong>publicar tu propiedad!</strong>',
  GO_PAY: 'Ir a pagar',
  NEAT_PAYMENT_ATTEMPT_INFO: 'Registramos un intento de pago con Neat, antes de continuar revisa tu cuenta  bancaria para no ejecutar otro pago. Si no pagaste por Neat ignora este mensaje.',
  NEAT_PAYMENT_ATTEMPT: 'Intento de pago con Neat',
  REFERRED_CODE_NOT_FOUND: 'Ups!, no encontramos tu código de referencia',
  SKIP: 'Omitir',
  ENTER_NEW_REFERRED_CODE: 'Ingresa tu código acá',
  INVALID_REFERRED_CODE: 'Código incorrecto, reintenta.',
  COMMUNE:'Comuna',
  END_TIME_TITLE: 'Término',
  START_TIME_TITLE: 'Inicio',
  GENERAL_CAROUSEL_SETTINGS: 'Configuración general de carrusel',
  UPLOAD_IMAGES_ON_DEVICES: 'Subida de imágenes en dispositivos',
  TRANSITION_TIME: 'Tiempo transición',
  TRANSITION_TIME_INFO:'(duración de cada slider. mín 3 seg. - máx 5 seg.)',
  IMAGE_DESKTOP_INFO: "Sube una imagen de 1440 x 600 px. <br> Peso máximo. 400kb <br>en formato JEPG PNG",
  IMAGE_MOBILE_INFO: "Sube una imagen de 360 x 300 px. <br> Peso máximo. 400kb <br>en formato JEPG PNG",
  IMAGE_TABLET_INFO: "Sube una imagen de 994 x 460 px. <br> Peso máximo. 400kb <br>en formato JEPG PNG",
  CAMPAIGNS: 'Campañas',
  CAROUSEL_IMAGES: 'Imágenes del carrusel',
  DELETE: 'Eliminar',
  ADD_IMAGES: 'Agregar imágenes',
  PRIORITY: 'Prioridad',
  SCHEDULE_NOT_AVAILABLE_VISIT: 'No existen horarios disponibles para tu visita',
  SCHEDULE_WITH_AN_TANGUERO: 'Agenda con un ejecutivo',
  MESSAGEBIRD_PERSONAL_DATA: 'Hola, soy {name}, rut: {rut}, teléfono {phone_number}, correo {email}, y necesito agendar un tour en la propiedad de {address}. {rute}',
  LEASE_AVAILABLE_FROM: 'Arriendo disponible desde: ',
  NO_TOC_MODULE: 'La información no se ha podido cargar',
  UPLOAD_SUCCESS: 'Subida con éxito',
  NO_FORGET_UPDATE_PROFILE: 'No olvides completar tu perfil',
  GO_TO_PROFILE: 'Ir a perfil',
  I_NEED_HELP: 'Hola, necesito ayuda',
  TITLE: 'Título',
  ALTERNATIVE_TEXT: 'Texto alternativo',
  WRONG_IMAGE_DIMENSIONS: 'La imagen que adjuntaste no corresponde a la dimensión soportada',
  WRONG_IMAGE_TYPE_OR_DIMENSIONS: 'La imagen que adjuntaste no corresponde al formato soportado o a la dimensión sugerida',
  WRONG_IMAGE_WEIGHT: 'La imagen que estás adjuntando supera el peso máximo permitido (50MB)',
  POSITION: 'Posición',
  ERROR_UNSAVED_CHANGES: 'Tienes cambios sin guardar',
  LAST_POST_PUBLISHED: 'Última publicación: ',
  LINK: 'Link',
  ANTICIPATED_CONTRACT_TERM: 'Término anticipado de contrato',
  REQUEST_ANTICIPATED_CONTRACT_TERM: 'Solicitud de término anticipado de contrato',
  STATE_OF_REQUISITION: 'Estado de la solicitud: <span class="text-color-secondary "><b>{state}</b></span>',
  REQUEST_ANTICIPATED_CONTRACT_TERM_TEXT: "Se ha emitido una solicitud de parte del <b>{requester}</b> a la propiedad de <b>{full_address}</b>, con fecha de término de contrato <b>el día {contract_end_date}</b>",
  CURRENT_LEASE_DATA: 'Datos del arriendo en curso',
  SIGNED_DATE: 'Fecha de firma',
  STARTED_DATE: 'Fecha de inicio',
  END_DATE: 'Fecha de término',
  FINE: 'Multa',
  FINE_TEXT: 'Es el valor cobrado por término anticipado,<br/> equivale a 1.5 meses de arriendo',
  FINE_SUBTITLE: '¿A quién se le aplicará la multa?',
  BOTH: 'Ambos',
  NONE: 'Ninguno',
  EARLY_LEASE_TERMINATION_TITLE: 'Solicitud de término de contrato anticipado',
  EARLY_LEASE_TERMINATION_WARNING_CLP: "Si terminas tu contrato de arriendo antes de la fecha de renovación, deberás pagar una <strong>multa aprox. de {clp}</strong> equivalente a <strong>1,5 meses de arriendo</strong>",
  EARLY_LEASE_TERMINATION_WARNING_CLF: "Si terminas tu contrato de arriendo antes de la fecha de renovación, deberás pagar una <strong>multa de {clf}</strong> ({clp}) al día de hoy, equivalente a <strong>1,5 meses de arriendo. Este monto será recalculado al último día de arriendo</strong>",
  ADMIN_EARLY_LEASE_TERMINATION_WARNING_CLP: "Si terminas el contrato de arriendo antes de la fecha de renovación, se efectuará el cobro de una <strong>multa aprox. de {clp}</strong> equivalente a <strong>1,5 meses de arriendo</strong> que será pagada por owner, renter o ambos",
  ADMIN_EARLY_LEASE_TERMINATION_WARNING_CLF: "Si terminas el contrato de arriendo antes de la fecha de renovación, se efectuará el cobro de una <strong>multa de {clf}</strong> ({clp}) al día de hoy, equivalente a <strong>1,5 meses de arriendo. Este monto será recalculado al último día de arriendo</strong> que será pagada por owner, renter o ambos",
  EARLY_LEASE_TERMINATION_MOTIVE: '<strong>{motiveText}</strong> <span class="text-color-tertiary"> (obligatorio)</span>',
  EARLY_LEASE_TERMINATION_DUE_DATE: 'Tu contrato actual tiene <strong>vigencia</strong> hasta el <strong>{dueDate}<strong>',
  EARLY_LEASE_TERMINATION_TIME_CONSIDER: 'Debes considerar al menos {minimumDays} días desde la fecha de la solicitud',
  ADMIN_EARLY_LEASE_TERMINATION_DUE_DATE: 'El contrato actual tiene <strong>vigencia</strong> hasta el <strong>{dueDate}<strong>',
  SEND_SOLICITUDE: 'Enviar solicitud',
  MAX_CHARACTERS: 'Max. 200 caracteres',
  CURRENT_PROMOTIONS: 'Promoción actual',
  ACTIVE_PROMOTIONS: 'Promociones activas',
  NO_ACTUAL_PROMOTION: 'No tienes promoción actual',
  NO_AVAILABLE_PROMOTIONS: 'No hay promociones disponibles',
  PROMOTION_ACTIVE: 'Activa',
  PROMOTION_FINISHED: 'Finalizada',
  PROMOTION_UNINITIATED: 'No iniciada',
  CHANGE_PROMOTION: 'Cambio de promoción',
  CONFIRM_CHANGE_PROMOTION: '¿Estás seguro que quieres asignar <span class="font-weight-bold">{promotion_name}</span>  a la propiedad?',
  PROMOTION_START: 'Fecha inicio',
  PROMOTION_END: 'Fecha fin',
  REGISTER_TYPE_NEW: 'Full tango',
  REGISTER_TYPE_REGISTERED: 'Administrada',
  REGISTER_TYPE_BROKERAGE: 'Corretaje',
  BUTTON_CHANGE_PROMOTION: 'Cambiar promoción',
  BUTTON_CANCEL_PROMOTION: 'Cancelar',
  BUTTON_ASSIGN_PROMOTION: 'Asignar',
  LEASING_PRICE_VALUE_MONTH: 'Valor arriendo mensual',
  MARK_AS_PAID: 'Marcar como pagado',
  NO_CHARGE_LINES_FOUND: 'No existen pagos disponibles',
  EDIT_CHARGES: 'Edición de cobros',
  CONFIRM_UPDATE_CHARGES: '¿Estás seguro que quieres guardar estos cambios?',
  SAVE_CHARGES: 'Guardar cobros',
  GUARANTEE: 'Garantía',
  FIRST_PAYMENT: 'Primer mes de arriendo',
  UF_VALUE_ON_THE_DAY_OF_SIGNING: 'Valor UF al dia de la firma',
  MIN_VALUE_HELPER_TEXT: 'El valor debe ser mayor a ${value}',
  FOLIO: 'Folio',
  SIGNED_CONTRACT_AT: 'Fecha de firma',
  LEASE_START_AT: 'Fecha de inicio',
  DUE_AT: 'Fecha de término',
  ERROR_NOT_FULL_TANGO: 'Sólo puedes editar los planes Full Tango',
  RENT_ACTIVE_OR_FINISHED: 'Renta activa o finalizada',
  ADD_PAYMENT: 'Agregar pago',
  PROVIDER: 'Proveedor',
  PAYMENT_KIND: 'Tipo de pago',
  THIS_ACTION_CANNOT_BE_UNDONE: 'Esta acción no se puede deshacer',
  SUCCESS_MARK_AS_PAID: 'El pago ha sido marcado como pagado!',
  NO_PUBLICATIONS: 'No hay compañas publicadas',
  RENTER_LEASE_DATA_TITLE: 'Datos de tu arriendo',
  RENTER_WHEN_WILL_DESOCUPATE: '¿Cuándo dejarías la propiedad?',
  RENTER_MOTIVE_TEXT: 'Estamos muy tristes de que te vayas, cuéntanos tus motivos',
  OWNER_LEASE_DATA_TITLE: 'Datos de tu propiedad arrendada',
  OWNER_WHEN_WILL_DESOCUPATE: '¿Cuándo quieres que se desocupe tu propiedad?',
  OWNER_MOTIVE_TEXT: 'Cuéntanos los motivos de tu solicitud',
  ADMIN_LEASE_DATA_TITLE: 'Datos del arriendo en curso',
  ADMIN_WHEN_WILL_DESOCUPATE: '¿Cuál será el último día de arriendo?',
  ADMIN_MOTIVE_TEXT: 'Motivos de solicitud de término',
  ADMIN_CLOSURE_LEASE_SUBTITLE: 'Vas a terminar el contrato de arriendo de la propiedad de <strong>{address}</strong>',
  FINE_VALUE: 'Valor multa',
  FINISH_CONTRACT: 'Terminar contrato',
  REJECT_REQUEST: 'Rechazar solicitud',
  CONFIRM_REJECT_REQUEST: 'Confirmación de rechazo de término de contrato',
  CONFIRM_REQUEST_REQUISITION:
  "<div class='mb-4'>¿Estás seguro de términar anticipadamente el arriendo en la propiedad de <b>{address}</b>, el día <b>{request_day}</b>? </div> <div>Es posible que el valor aprox. de la multa sean <b>{fine_amount}</b>, equivalentes a <b>{fine_multiplicator} meses</b>  de arriendo</div>",
  SEND_REQUEST: 'Enviar solicitud',
  CLF_LEASE_VALUE: '{clf} / <span class="secondary-price">{clp}</span>',
  REQUISITION_WARING_DAY: '<p class="text-danger MuiFormHelperText-contained">Quedan menos de {minimumDays} días para terminar tu contrato, por lo que no puedes enviar una solicitud de término anticipado</p>',
  WE_RECIBE_REQUEST: 'Recibimos tu solicitud',
  REJECTED_REQUEST: 'Tu solicitud fue rechazada',
  PENDING_REQUISITION_INFO: 'Tu solicitud está pendiente de revisión, te notificaremos muy pronto el resultado',
  DELETE_REQUEST: 'Eliminar solicitud',
  NEW_REQUEST: 'Enviar nueva solicitud',
  CONFIRM_DELETE_REQUEST: '¿Estás seguro de eliminar tu solicitud de término de contrato?',
  CONFIRM_DELETE_REQUEST_INFO: 'Al eliminar la solicitud: tu contrato de arriendo mantendrá su vigencia, fecha de renovación y podrás generar un nuevo término de contrato',
  ARE_YOU_SURE_ABOUT_REJECT: '<strong>¿Estás seguro de rechazar la solicitud de término de contrato?</strong>',
  YOUR_DECISION_WILL_BE_NOTIFIED: 'Tu decisión será notificada al solicitante',
  THIS_ACTION_CANT_BE_UNDONE: '<strong>Esta acción no se puede deshacer</strong>',
  REJECTION_EARLY_REQUISITION: 'Propuesta Rechazada',
  REASONS_OF_YOUR_DECISION: "Razones de tu decisión <span class='text-color-gray font-weight-normal'>(Obligatorio)</span>",
  REASONS_OF_CONTRACT_TERMINATION: 'Motivos de solicitud de término anticipado',
  CLOSING_COMMENT: 'Razones de decisión del administrador',
  REQUEST_HISTORY: 'Historial de solicitudes',
  GLOSSARY_OF_STATES: 'Glosario de estados',
  GLOSSARY_OF_REQUISITION_APPROVED: '<b>Aprobada:</b> admin confirmó el término de contrato',
  GLOSSARY_OF_REQUISITION_REJECTED: '<b>Rechazada:</b> admin rechazó la solicitud',
  GLOSSARY_OF_REQUISITION_FINISHED: '<b>Finalizada:</b> admin marcó como pagados todos los  cobros asociados a la solicitud',
  ARE_YOU_SURE_ABOUT_FINISH_CONTRACT: "¿Estás seguro que deseas terminar este contrato el día <span class='color-secondary'>{day}</span>?",
  CONFIRM_FINISH_CONTRACT: 'Confirmación de término de contrato',
  THE_FOLLOWING_FINE_WILL_APPLY: 'Se aplicarán los siguientes cobros asociado a la multa:',
  SUCCESS_FINISH_CONTRACT: 'El contrato ha sido terminado!',
  PENDING_CHARGES: 'Cobros pendientes',
  ASSOCIATED_DISCOUNTS: 'Descuentos asociados',
  PENDING_FINE: 'Multa pendiente',
  ENTER_RENTER_PAYMENTS: 'Ingresar los pagos de arrendatario',
  ENTER_OWNER_PAYMENTS: 'Ingresar los pagos de propietario',
  SELECT_PAYMENT_PROVIDER: 'Selecciona el proveedor',
  SELECT_PAYMENT_KIND: 'Selecciona el tipo de pago',
  ACCEPTED_REQUEST_REQUISITION: `Aceptamos el término de contrato en la propiedad ubicada en <span class="color-secondary">{address} </span>`,
  REJECTED_REQUEST_REQUISITION: `Rechazamos el término de contrato en la propiedad ubicada en <span class="color-secondary">{address} </span>`,
  AUTOMATIC_DISCOUNT_FOR_OWNER: 'La multa se descontará automáticamente en el próximo pago de tu arriendo',
  WE_CONTACT_WITH_YOU_FOR_REPUPLISH_PROCESS: 'Nos contactaremos contigo para comenzar el proceso de republicación',
  WE_CONTACT_WITH_YOU_FOR_RESTITUTION_PROCESS: 'Nos contactaremos contigo para comenzar el proceso de restitución',
  YOUR_REQUEST_WAS_APPROVED: 'Tu solicitud fue aprobada',
  CHECK_YOUR_PAYMENTS_HISTORY: 'Revisa tu historial de pagos',
  CHECK_YOUR_PENDING_PAYMENTS: 'Revisa tus pagos pendientes',
  EXIST_A_REQUEST_APPROVED: 'Existe una solicitud aprobada',
  ALERT_MESSAGE_SUCCESS_TITLE: 'Datos Guardados', 
  ALERT_MESSAGE_SUCCESS_TEXT: 'Tus datos se han guardado existosamente.',
  ALERT_MESSAGE_WARNING_TITLE: 'Información incorrecta',
  ALERT_MESSAGE_WARNING_TEXT: 'No encontramos tu Rut o Número de documento ¿Probamos de nuevo?.',
  ALERT_MESSAGE_VALIDATION_TITLE: 'Cédula de identidad no vigente',
  ALERT_MESSAGE_VALIDATION_TEXT: 'Por favor vuelve a intenterlo cuando tengas tu documento actualizado.',
  ALERT_MESSAGE_ERROR_TITLE: 'Oops! Ha ocurrido un error',
  ALERT_MESSAGE_ERROR_TEXT: "Nuestro sitio web presenta intermitencias. Comunícate con nosotros en ",
  MESSAGEBIRD_MESSAGE_ALERT_ERROR: 'Hola! No pude ingresar mi información personal (Rut, C.I, Número de documento) ¿Me podrían ayudar?',
  PAGE_NOT_EXIST_OR_UNAVAILABLE: '¡Ups! La página que buscas no existe o no se encuentra disponible.',
  SIGNTIME_OVER_TITLE: 'Tiempo para firmar finalizado',
  SIGNTIME_OVER_BODY: 'Se ha agotado el tiempo para poder firmar este contrato como aval.',
  SIGNTIME_OVER_BODY_NOTIFY_RENTER: 'Avisale a {renter} que deberá iniciar un nuevo proceso de arriendo.',
  TANGO_CAN_DOS: 'En Tango puedes:',
  TANGO_CAN_ONE: 'Arrendar una propiedad rápido, seguro y 100 digital', 
  TANGO_CAN_TWO: 'Publicar tu propiedad gratis para distintos portales de arriendo', 
  TANGO_CAN_THREE: 'Tener asesoría personalizada de nuestros ejecutivos y mucho más...',
  I_WANT_TO_KNOW_MORE: 'Me interesa saber más',
  THE_RENTER: 'el arrendatario',
  COSIGNER_SIGN_SUCCESSFUL_TITLE: 'Proceso de firma exitoso',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_ONE: 'Has completado el proceso de firma. Recuérdale a {renter} que tiene ',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_TWO: 'hasta el día antes del arriendo a las 20:30 horas',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_THREE: 'para realizar el primer pago.',
  SIGNATURE_PROCESS_FOR_COSIGNER_TITLE: 'Proceso de firma para avales',
  SIGNATURE_PROCESS_FOR_COSIGNER_DESCRIPTION: 'Te damos la bienvenida al proceso de firma de arriendo para avales. Recuerda que fuiste asignado como aval por <strong>{renter}</strong>.',
  INFORMATION_ABOUT_THE_PROPERTY: 'Información sobre la propiedad',
  SIGNATURE_PROCESS_FOR_COSIGNER_REMEMBER: 'Recuerda que tienes <strong>hasta el día antes del arriendo a las 20:30 horas</strong> para que tú y todas las partes firmen y realizar el primer pago. ¿Qué esperas?',
  CONTRACT_SIGNING_COSIGNER: 'Firma de contrato avales',
  RETRY: 'Reintentar',
  STATUS_MODAL_ERROR_TITLE: '¡Ups! Ha ocurrido un error',
  STATUS_MODAL_ERROR_BODY_400: 'Algo ha ido mal con el proceso de firma. Por favor vuelve a intentarlo.',
  STATUS_MODAL_ERROR_BODY_500: 'Estamos presentando problemas con el servidor. Por favor vuelve a intentar en unos minutos.',
  ECERT_ERROR: 'Ecert error',
  FINISH_PROMOTION: 'Finalizar promoción',
  FINISH_PROMOTION_CONFIRMATION: '¿Deseas finalizar la promoción de manera anticipada?',
  GUARANTEE_INFO: 'Estos son los meses de garantía solicitados al comienzo del arriendo',
}
