import React from 'react';
import { tangoImg } from '../../resources/index';
const QRCode = require('qrcode.react');

interface QrCodeProps {
    role: string;
    url: string;
}

const QrCode = ({ role, url }: QrCodeProps) => {
    return (
        <QRCode
            value={`${role}${url}`}
            size={128}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'H'}
            includeMargin={false}
            renderAs={'svg'}
            imageSettings={{
                src: tangoImg,
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
            }}
        />
    );
};

export default QrCode;
