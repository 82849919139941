import { getDataResource } from '../../../tango-react-base/helpers/resources';
import { getCLFStart, getCLFError, getCLFSuccess } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCLF = () => {
    return (dispatch: any) => {
        dispatch(getCLFStart());
        getDataResource('currencies/clf')
            .then((result) => {
                dispatch(getCLFSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getCLFError(e));
            });
    };
};
export default getCLF;
