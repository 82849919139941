import React from 'react';
import intl from 'react-intl-universal';

const TermsAndConditionFile = () => {
    return (
        <div className='container-home' style={{ paddingTop: '2rem' }}>
            {intl.getHTML('DOCUMENT_TERMS_AND_CONDITIONS')}
        </div>
    );
};

export default TermsAndConditionFile;
