import { updatePhoneNumberStart, updatePhoneNumberSuccess, updatePhoneNumberError } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const updatePhoneNumber = (values: any, id: number) => {
    return (dispatch: any) => {
        dispatch(updatePhoneNumberStart());
        putData(`users/${id}/attributes/phone_number`, values)
            .then((result: any) => {
                dispatch(updatePhoneNumberSuccess(result.data));
                toast(intl.get('DATA_HAS_BEEN_SAVED'));
            })
            .catch((e: any) => {
                toast(handleError(e));
                return dispatch(updatePhoneNumberError(e));
            });
    };
};
export default updatePhoneNumber;
