export const uploadAnnexStart = (visitId: any) => {
    return {
        type: 'UPLOAD_FILE_STARTED',
        payload: { visitId },
    };
};
export const uploadAnnexSuccess = (result: any, visitId: any) => {
    return {
        type: 'UPLOAD_FILE_SUCCESS',
        payload: result,
        visitId: visitId,
    };
};
export const uploadAnnexError = (error: any, visitId: any) => {
    return {
        type: 'UPLOAD_FILE_ERROR',
        payload: {
            error,
            visitId,
        },
    };
};
