import React, { useEffect } from 'react'
import { withFormik, FormikProps, Form, Field } from 'formik'
import intl from 'react-intl-universal'
import Input from '../../input'
import { NewFormRegister } from './formValidates'
import { isRoleSuperAdmin } from '../../../helpers/roleComparison'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { includeInstapagePath } from '../../../helpers/routesForInstapage';

/**
 * Here it is defined the type of the property, this prop is similar to 'FormProps' but 'FormPropsTypes' is for the documentation
 * @typedef FormPropsTypes
 * @type {(boolean|string|Function)}
 * @property {string} onSubmit - is an string.
 * @property {string} idUser - is an string.
 * @property {string} referredCode - is an string.
 * @property {string} fetchingCodes - is an string.
 * @property {string} countriesCodes - is an string.
 * @property {boolean} disabled - is an boolean.
 * @property {Function} setuserEmail - is an Function.
 * @property {string} userReference - is an string.
 * @property {string} userEmail - is an string.
 */
interface FormProps {
  onSubmit: any
  idUser?: string
  children?: any
  disabled?: boolean
  grid_form?: number
  child_input_admin?: any
  setReadTermAndPolicies?: any
  readTermAndPolicies?: boolean
  userEmail?: any
  setuserEmail?: any
}
/**
 * this is the form used to create the user register
 *@function
 *@param {createUserFormTypes}  email - input to the email
 *@param {createUserFormTypes}  password - input to the password
 *@param {createUserFormTypes}  repeat_password -input to repeat the password
 *@param {createUserFormTypes}  verifier_ref -
 *@param {createUserFormTypes}  agent_ref -
 *@param {createUserFormTypes}  referred_code - input with the referred code to the owner
 * @returns {(ReactComponent)} returns a component with the inputs for the register
 */
interface createUserForm {
  email: string
  password: string
  repeat_password: string
  verifier_ref: string
  agent_ref: string
  referred_code: string
  new_register: boolean
}
/**
 *@function
 *@param {createUserForm} - this is the form used to create the user register
 */
const InnerForm: React.FunctionComponent<
  FormProps & FormikProps<createUserForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  grid_form = 12,
  child_input_admin,
  userEmail,
  setuserEmail,
}) => {
  const [haveReferenceCode, setHaveReferenceCode] = useState(false)

  useEffect(() => {
    if (!haveReferenceCode) values.referred_code = ''
  }, [haveReferenceCode])

  return (
    <Form className='row'>
      <div className={`col-md-${grid_form} plm-0 mb-3`}>
        <Input
          variant={false}
          id='email'
          label={'email'.toUpperCase()}
          type='email'
          value={(values.email = userEmail).toLowerCase()}
          error={errors.email}
          onChange={(e: any) => setuserEmail(e.target.value)}
          onBlur={handleBlur}
          touched={touched.email}
          disabled
          placeholder={intl.get(`REMEMBER_PERSONAL_MAIL`)}
          className='input-container-class'
          classNameInput='input-class border-login'
          classNameLabel='label-class mb-2'
        />
      </div>
      <div className={`col-md-${grid_form} plm-0 mb-3`}>
        <Input
          variant={false}
          id='password'
          label={'password'.toUpperCase()}
          type='password'
          value={values.password}
          error={!includeInstapagePath() ? errors.password : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.password}
          disabled={disabled}
          className='input-container-class'
          classNameInput='input-class border-login'
          classNameLabel='label-class mb-2'
          placeholder={intl.get('MIN_CHARS_PASSWORD')}
          requiredInstapage
        />
      </div>
      <div className={`col-md-${grid_form} plm-0 mb-3`}>
        <Input
          variant={false}
          id='repeat_password'
          label={'CONFIRM_PASSWORD'}
          type='password'
          value={values.repeat_password}
          error={!includeInstapagePath() ? errors.repeat_password : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.repeat_password}
          disabled={disabled}
          className='input-container-class'
          classNameInput='input-class border-login'
          classNameLabel='label-class mb-2'
          placeholder={intl.get('MIN_CHARS_PASSWORD')}
          requiredInstapage
        />
      </div>
      <div className={`col-md-${grid_form} plm-0 align-checkbox mb-3`}>
        <input
          type='checkbox'
          className='form-check-input'
          id='checkReferenceCode'
          checked={haveReferenceCode}
          onChange={() => setHaveReferenceCode(!haveReferenceCode)}
        />
        <label
        className={`${includeInstapagePath() ? 'text-white-bold-iframe text-no-transform' : 'text-color-black h6 text-no-transform'}`}
        htmlFor='checkReferenceCode'
        >
          {intl.get('HAVE_A_REFERENCE_CODE')}
        </label>
      </div>

      {haveReferenceCode && (
        <div className={`col-md-${grid_form} plm-0`}>
          <Input
            variant={false}
            id='referred_code'
            type='text'
            required={haveReferenceCode}
            value={values.referred_code.toUpperCase()}
            error={errors.referred_code}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.referred_code}
            disabled={disabled}
            className='col-12 mt-1'
            classNameInput='col-12 pl-0'
            classNameLabel='col-12 pl-0 text-color-secondary'
            maxLength={12}
            placeholder={intl.get('ENTER_IT_HERE')}
          />
        </div>
      )}
      <div className={`col-md-${grid_form} plm-0 col-12 mt-1'`}>{children}</div>
    </Form>
  )
}
/**
 * This is used to select the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const NewRegisterForm = withFormik<FormProps, createUserForm>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      email: '',
      password: '',
      repeat_password: '',
      ...props.child_input_admin,
      referred_code: '',
      selected_role: isRoleSuperAdmin? '' : process.env.REACT_APP_ROLE || '',
      new_register: true,
    }
    return { ...initialValues }
  },

  validationSchema: NewFormRegister,

  handleSubmit(values: createUserForm, { props, setSubmitting, setErrors }) {
    if (values.referred_code !== '') {
      if (
        values.referred_code.length < 12 ||
        values.referred_code.length > 13
      ) {
        toast(intl.get('VERIFY_REFERRED_CODE'))
        return
      }
    }
    props.onSubmit(values)
  },
})(InnerForm)
export default NewRegisterForm
