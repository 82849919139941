export default {
    CONTRACT_STATES_TITLE: 'Acá puedes conocer el significado de cada estado de contrato',
    EMITTED_STATE: 'Emitido',
    SIGNED_STATE: 'Firmado',
    ACTIVE_STATE: 'Vigente',
    NULLIFIED_STATE: 'Anulado', 
    CANCELED_STATE: 'Cancelado', 
    NOTIFIED_STATE: 'Proceso de renovación iniciado',
    NEGOTIATING_STATE_2: 'Negociando renovación', 
    RENEWAL_ACCEPTED_STATE: 'Renovación aceptada', 
    RENEWAL_REJECTED_STATE: 'Renovación rechazada',
    RENEWAL_ACTIVE_STATE: 'Renovación vigente',
    RENEWAL_MEDIATING_STATE: 'Proceso de mediación iniciado',  
    FINISHED_STATE: 'Finalizado',
    EMITTED_STATE_EXPLANATION: 'Aún no ha sido firmado por todas las partes',
    SIGNED_STATE_EXPLANATION: 'Ya se encuentra firmado por todas las partes pero aún no ha sido activado',
    ACTIVE_STATE_EXPLANATION: 'Se encuentra vigente y no existe un periodo de renovación en curso',
    NULLIFIED_STATE_EXPLANATION: 'No fue firmado por alguna o todas las partes dentro de las 48 horas', 
    CANCELED_STATE_EXPLANATION: 'Propietario decide firmar contrato con otro arrendatario', 
    NOTIFIED_STATE_EXPLANATION: 'Propietario y arrendatario han sido notificados del inicio del proceso de renovación del arriendo (a partir de los 90 días antes de que finalice el periodo del contrato)',
    NEGOTIATING_STATE_EXPLANATION: 'Propietario o arrendatario ha entregado alguna respuesta o está a la espera de su contraparte, y la renovación no ha sido aceptada por ambos o rechazada', 
    RENEWAL_ACCEPTED_STATE_EXPLANATION: 'Propietario y arrendatario aceptaron la renovación de contrato', 
    RENEWAL_REJECTED_STATE_EXPLANATION: 'Propietario o arrendatario rechazó la renovación de contrato',
    RENEWAL_ACTIVE_STATE_EXPLANATION: 'El arriendo fue extendido por otro periodo y ya ha vencido el anterior',
    RENEWAL_MEDIATING_STATE_EXPLANATION: 'Propietario ha rechazado contrapropuesta de arrendatario o admin ha propuesto precio de arriendo', 
    FINISHED_STATE_EXPLANATION: 'Arriendo finalizado por no renovación entre propietario y arrendatario',
  }