import { VisitFinishedState } from '../../../tango'

const initialState: VisitFinishedState = {
  visitsFinished: [],
  visitFinishedDetail: {},
  visitFinishedIds: [],
  fetch: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'VISIT_FINISHED_STARTED':
      return {
        ...state,
        visitFinishedIds: state.visitFinishedIds.concat(action.payload.id),
        fetch: 'FETCHING',
      }
    case 'VISIT_FINISHED_SUCCESS':
      return {
        ...state,
        visitsFinished: state.visitsFinished.concat(action.payload.data.data),
        visitFinishedDetail: action.payload.data.data,
        fetch: 'FETCHED',
      }
    case 'VISIT_FINISHED_ERROR':
      return {
        ...state,
        visitFinishedIds: state.visitFinishedIds.filter(
          (val: string) => val !== action.payload.id,
        ),
        error: action.payload.error,
        fetch: 'FETCHED_ERROR',
      }
    default:
      return state
  }
}
