export const nextWeek = () => {
    return {
        type: 'NEXT_WEEK',
    };
};

export const lastWeek = () => {
    return {
        type: 'LAST_WEEK',
    };
};
