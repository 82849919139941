import { useEffect } from 'react'
import intl from 'react-intl-universal'
import { useSelector, useDispatch } from 'react-redux'
import { formatCurrencyNumber } from '../../helpers/numberFormat'
import { getCLF } from '../../reduxActions'
interface CLFInterface {
  clf: number
  status: string
  date: { date: string }
}

/**
 * PropsType defines the property types for documentation
 * @interface PropsType
 * @type {(number | string)}
 * @prop {string} messageAdditionalValidation - Is a string
 * @prop {string} currencyIsoValue - Is a string
 * @prop {number} value - Is a any
 * @prop {number} minimunPrice - Is a any
 */
interface Props {
  messageAdditionalValidation?: string
  currencyIsoValue?: string
  value: number
  minimunPrice?: number
}

/**
 * useCurrencyConvertor is a hook that returns the converted value
 *@function
 *@param {Props} messageAdditionalValidation - Information in the forms located under the input
 *@param {Props} currencyIsoValue - Returns a currency
 *@param {Props} value - Returns a currency amount
 *@param {Props} minimunPrice - Returns min price
 * @returns {(CustomHook)} Returns a currency amount 
 * @example 
 * 
 *   import { useCurrencyConvertor } from '../../hooks'
 *   import useEffect from 'react';
 *   import getCLF from '../../reduxActions/getCLF/index';
 *   import { options } from '../../components/paymentMethodComponent/options';
 *  .........
 *  ....
 * 
 * const [priceValidation, priceConversion ElementPrice] = useCurrencyConvertor({
 *  messageAdditionalValidation,
 *  currencyIsoValue,
 *  value,
 *  minimunPrice: 200000,
 * })
 * ...
 * ......
 * <div dangerouslySetInnerHTML={{__html:ElementPrice}}/>
*/
export const useCurrencyConvertor = ({
  messageAdditionalValidation,
  currencyIsoValue = 'CLP',
  value,
  minimunPrice = 0,
}: Props) => {
  const dispatch = useDispatch()
  /** CLFFormat: This function receives a float number as a value and returns that value formatted to CLF
   * @function
   */
  const { currentLocale } = intl.getInitOptions()
  const CLFFormat = (value: number) => {
    return Intl.NumberFormat(currentLocale).format(Number(value.toFixed(2)))
  }
  const { clf, status }: CLFInterface = useSelector(
    (state: { CLFResource: CLFInterface }) => state.CLFResource
  )

  useEffect(() => {
    if (status === 'NO_FETCH') {
      dispatch(getCLF())
    }
  }, [status])

  const validationDecimal = (valueCurrency: number) => {
    const splitValue = CLFFormat(Math.abs(valueCurrency / clf)).split(',')
    const decimalValidation =
      splitValue[1]?.length === 1 ? `${splitValue[1]}0` : splitValue[1]
    return `${splitValue[0]}${
      currentLocale === 'en-US' ? '.' : ','
    }${decimalValidation || '00'}`
  }

  const minimumPriceCLF = Number(Math.abs(minimunPrice / clf).toFixed(2))
  const priceValidation = currencyIsoValue === 'CLP' ? 
    (value < minimunPrice ? intl.getHTML(`${messageAdditionalValidation}`, { priceInCLF: null }) : '')
      : (value < minimumPriceCLF ? intl.getHTML(`${messageAdditionalValidation}`, {priceInCLF: `(${validationDecimal(minimunPrice)} UF)` })
      : '')

  const priceConversion = currencyIsoValue === 'CLP' ? 
    (value !== undefined ? `UF ${validationDecimal(value)}` : '')
      : (value !== undefined ? `${formatCurrencyNumber(value * clf)}` : '')

  const body = `<span class='text-color-tertiary d-block'>${intl.get('CLF_CONVERSION_INFO', {price: priceConversion})}</span>`
  const ElementPrice =  `${status === 'FETCHED' && priceConversion ? body : ''}`
  const currentUF = String(clf)
  return [priceValidation, priceConversion, ElementPrice, currentUF]
}
