import {
  setSessionStart,
  setSessionSuccess,
  setSessionSuccessStorage,
  setSessionError,
} from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
import { getNewToken } from '../../index'
import { sendsMessageToInstapage } from '../../../helpers/instapageMessage'


const setGoogleSession = (
  sessionData: any,
  history?: any,
  redirectionURL?: any,
  isInstapage?: boolean
) => {
  const userSession = {
    user: {
      token_id: sessionData.credential,
      kind: 'google',
      referred_code: localStorage.getItem('referredCode')
    },
    role: {
      name: process.env.REACT_APP_ROLE,
    },
  }

  return (dispatch: any) => {
    dispatch(setSessionStart())
    postData('auth/login', userSession)
      .then((result: any) => {
        dispatch(setSessionSuccess(result.data))
        localStorage.setItem('authorization', result.headers.authorization)
        localStorage.setItem('session', JSON.stringify(result.data.data))
        sessionStorage.setItem('authorization', result.headers.authorization)
        sessionStorage.setItem('session', JSON.stringify(result.data.data))
        localStorage.removeItem('referredCode')
        dispatch(setSessionSuccessStorage())
        if (isInstapage) {
          sendsMessageToInstapage(redirectionURL)
        } else {
          history && redirectionURL && history.push(redirectionURL)
        }
      })

      .catch((e) => {
        const errors = { ...e }
        const { response } = errors || ''
        const { data } = response || ''
        const { code } = data?.errors[0] || ''
        if (code === 'VERIFY_ACCOUNT_TOKEN') {
          dispatch(getNewToken(sessionData.email))
        }
        toast(handleError(e))
        return dispatch(setSessionError(e))
      })
  }
}
export default setGoogleSession
