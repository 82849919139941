import React from 'react';
import PopupWithIcon from '.';
import { CircularLoading } from '..';

interface PopupWithIconProps {
    open: boolean;
    handleClose: () => void;
    children?: React.ReactNode;
    title: string;
    icon?: any;
    modalTitle?: string;
    className?: string;
    lineUp?: boolean;
    contentDialogClass?: string;
    centeredContent?: boolean;
    classNameTitle?: string;
    classNameDiv?: String;
    firstPosition?: boolean;
    handleConfirm: () => void;
    textButtonWhite?: string;
    textButtonRed?: string;
    loading?: boolean;
    disabled?: boolean;
}
/** Here the types of the props for the interface 'PopupWithIconProps' are defined
 * @typedef UnpublishPostModalTypes
 * @type {(function|boolean|string|React.ReactNode)}
 * @property {boolean} open - is a boolean that indicates if the modal is open or not.
 * @property {function} handleClose - is a function that closes the modal.
 * @property {React.ReactNode} children - is a ReactNode that contains the content of the modal.
 * @property {string} title - is a string that contains the title of the modal.
 * @property {any} icon - is a any that contains the icon of the modal.
 * @property {string} modalTitle - is a string that contains the title of the modal.
 * @property {string} className - is a string that contains the class of the modal.
 * @property {boolean} lineUp - is a boolean that indicates if the modal is aligned to the top or not.
 * @property {string} contentDialogClass - is a string that contains the class of the content of the modal.
 * @property {boolean} centeredContent - is a boolean that indicates if the content of the modal is centered or not.
 * @property {string} classNameTitle - is a string that contains the class of the title of the modal.
 * @property {string} classNameDiv - is a string that contains the class of the div of the modal.
 * @property {boolean} firstPosition - is a boolean that indicates if the modal is aligned to the top or not.
 * @property {function} handleConfirm - is a function that handles the confirm button.
 * @property {string} textButtonWhite - is a string that contains the text of the white button.
 * @property {string} textButtonRed - is a string that contains the text of the red button.
 * @property {boolean} loading - is a boolean that indicates if the modal is loading or not.
 * @property {boolean} disabled - is a boolean that indicates if the buttons is disabled or not.
 *
 */

const PopupWithIconButtons = ({
    open,
    handleClose,
    children,
    title,
    icon,
    disabled = false,
    modalTitle = '',
    className = '',
    lineUp = false,
    contentDialogClass = '',
    centeredContent = true,
    classNameTitle = '',
    classNameDiv = '',
    firstPosition,
    handleConfirm,
    loading = false,
    textButtonWhite = '',
    textButtonRed = '',
}: PopupWithIconProps) => {
    return (
        <PopupWithIcon
            open={open}
            handleClose={handleClose}
            title={title}
            icon={icon}
            modalTitle={modalTitle}
            className={'general-popup-with-buttons ' + className}
            lineUp={lineUp}
            contentDialogClass={contentDialogClass}
            centeredContent={centeredContent}
            classNameTitle={classNameTitle}
            classNameDiv={classNameDiv}
            firstPosition={firstPosition}
        >
            <>
                {children}
                <div className={'buttons-container-options center'}>
                    <button onClick={handleClose}>{textButtonWhite}</button>
                    <button onClick={handleConfirm} disabled={disabled || loading}>
                        {loading ? <CircularLoading /> : textButtonRed}
                    </button>
                </div>
            </>
        </PopupWithIcon>
    );
};
export default PopupWithIconButtons;
