import { servipagLogo, padpowLogo, neatLogo, neatLogoDisabled, fintocLogo } from '../../resources/index';

export const options = [
    {
        value: 'neat',
        icon: neatLogo,
        iconDisabled: neatLogoDisabled,
        methodText: 'NEAT_METHOD_TEXT',
        methodDisabledText: 'NEAT_methodDisabledText',
    },
    {
        value: 'fintoc',
        icon: fintocLogo,
        iconDisabled: null,
        methodText: 'PAYMENT_METHODS_FINTOC',
        methodDisabledText: null,
    },
    {
        value: 'padpow',
        icon: padpowLogo,
        iconDisabled: null,
        methodText: 'PADPOW_METHOD_TEXT',
        methodDisabledText: null,
    },
    {
        value: 'servipag',
        icon: servipagLogo,
        iconDisabled: null,
        methodText: 'SERVIPAG_METHOD_TEXT',
        methodDisabledText: null,
    },
];
