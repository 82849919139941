import React from 'react';
import VisitsTable from './VisitsTable';

/**
 * Here it is defined the type of the VisitsList Component
 * @typedef VisitsListProps
 * @type {(object|function)}
 * @property {object} visits - is a object.
 * @property {function} render - is a function optionality.
 * @property {function} fetch - is a function optionality.
 */

interface VisitsListProps {
    visits: Array<any>;
    render?: any;
    fetch?: String;
}

/**
 * VisitsTable is a contained component
 * @function
 * @param {VisitsListProps} visits - is a object with pending, finished or canceled visits.
 * @param {VisitsListProps} render - this function makes a call to history for move the pointer in the history stack by 0 entries.
 * @returns {(ReactComponent)} Return a react component with one type of visit table displayed by the photographer.
 */

const VisitsList = ({ visits, render, fetch }: VisitsListProps) => {
    return <VisitsTable visits={visits} render={render} fetch={fetch} />;
};

export default VisitsList;
