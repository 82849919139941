import axios from 'axios';
export default async (url: string, data: any) => {
    const uri = `${process.env.REACT_APP_API_CONTACT}/${url}`;

    const headers: any = {
        'content-type': 'multipart/form-data',
        authorization: sessionStorage.getItem('authorization'),
    };
    return axios.post(uri, data, { headers });
};
