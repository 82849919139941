import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

type AccordionProps = {
    title?: string;
    children?: Object;
    link?: string;
    href?: any;
    className?: string;
    border?: boolean;
    classNameText?: string;
    expanded?: boolean;
    showArrow?: boolean;
    isDisabled?: boolean;
    accordionClasses?: any;
    estimatePrice?: any;
    registeredCurrency?: string;
};

const AccordionItem = ({
    children,
    title,
    link,
    href,
    className,
    expanded = false,
    border = true,
    showArrow = true,
    isDisabled = false,
    classNameText = 'advanced-filter-h4',
    accordionClasses = {},
    estimatePrice = {},
    registeredCurrency = '',
}: AccordionProps) => {
    const [showExpanded, setShowExpanded] = React.useState(expanded);
    const handleChange = (panel: string) => (e: any, isExpanded: boolean) => {
        setShowExpanded(!showExpanded);
    };
    return (
        <div className={`accordion-item`}>
            <div className={!border ? '' : 'border-top-secondary-color'}>
                <Accordion expanded={showExpanded} onChange={handleChange('panel1a-header')} disabled={isDisabled}>
                    <AccordionSummary
                        expandIcon={showArrow && <i className={`fa fa-keyboard-arrow-down ${classNameText}`} />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                        classes={accordionClasses}
                    >
                        {title && (
                            <div
                                className={`${
                                    !classNameText
                                        ? 'p-0 p-md-4 container-item-list row'
                                        : `${classNameText} ${className}`
                                }`}
                            >
                                <h3 className={`${classNameText} ${className}`}>{title}</h3>
                            </div>
                        )}
                        {link && (
                            <>
                                <h2 className='text link-general-app'>
                                    <Link to={href} className='flex-wrap'>
                                        {link}
                                    </Link>
                                </h2>
                                <div className='price-container'>
                                    <span className='primary-price-title primary-price-title-payments'>
                                        <b>{`${intl.get('RENT')}`}</b>
                                        <div>
                                            <b className='ml-1'>
                                                {`${
                                                    estimatePrice
                                                        ? estimatePrice[registeredCurrency?.toLowerCase()]
                                                        : ''
                                                }`}{' '}
                                            </b>
                                            {`/ ${intl.get('PER_MONTH').toLocaleLowerCase()}`}
                                        </div>
                                    </span>
                                    <span className='secondary-price-title secondary-price-title-payments'>
                                        {intl.get('REF_VALUE')}
                                        <div>
                                            <b className='ml-1'>
                                                {`${
                                                    estimatePrice
                                                        ? estimatePrice[
                                                              registeredCurrency?.toLowerCase() === 'clp'
                                                                  ? 'clf'
                                                                  : 'clp'
                                                          ]
                                                        : ''
                                                }`}
                                            </b>
                                        </div>
                                    </span>
                                </div>
                            </>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>{children}</AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default AccordionItem;
