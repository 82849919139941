import * as Yup from 'yup';
import { validateEstimatePriceAmountYup } from '../../helpers/validateEstimatePriceAmount';

export const form_new_unit = (clf: any) =>
    Yup.object().shape({
        unit_type: Yup.string().required('REQUIRED_FIELD'),
        full_address: Yup.string().required('REQUIRED_FIELD'),
        estimate_price_amount: validateEstimatePriceAmountYup(clf),
    });
export const form_new_registered_unit = (clf: any) =>
    Yup.object().shape({
        owner_type: Yup.string().required('REQUIRED_FIELD'),
        unit_type: Yup.string().required('REQUIRED_FIELD'),
        community_type: Yup.string().when('unit_type', {
            is: 'house',
            then: Yup.string().required('REQUIRED_FIELD'),
        }),
        full_address: Yup.string().required('REQUIRED_FIELD'),
        inner_area: Yup.number().required(),
        bedroom_count: Yup.string().required('REQUIRED_FIELD'),
        suite_bedroom_count: Yup.string().required('REQUIRED_FIELD'),
        bathroom_count: Yup.string().required('REQUIRED_FIELD'),
        estimate_price_amount: validateEstimatePriceAmountYup(clf),
        includes_common_expenses: Yup.string().required('REQUIRED_FIELD'),
        common_expenses_amount: Yup.number().when('includes_common_expenses', {
            is: 'separated',
            then: Yup.number().nullable().min(0).required().max(20000000),
        }),
        full_name: Yup.string().required(),
        rut: Yup.string().required('REQUIRED_FIELD'),
        email: Yup.string().required('REQUIRED_FIELD'),
        alert_to_renter: Yup.string().required('REQUIRED_FIELD'),
    });

export const form_verifier = Yup.object().shape({
    terrace_area: Yup.number().when('unit_type', {
        is: 'apartment',
        then: Yup.number().test('Is positive?', 'WRONG_VALUE', (value) => value > 0),
    }),
    inner_area: Yup.number().test('Is positive?', 'WRONG_VALUE', (value) => value > 0),
    terrain_area: Yup.number().when('unit_type', {
        is: 'house',
        then: Yup.number().test('Is positive?', 'WRONG_VALUE', (value) => value > 0),
    }),
});

export const no_validation = Yup.object();
