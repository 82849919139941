import { putData } from '../../helpers/apiConnections';
import { updateScheduleConfigStart, updateScheduleConfigSuc, updateScheduleConfigError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const automaticTakeSchedule = (option: boolean) => {
    const yesOrNot = option ? 'yes' : 'no';
    const updateSchedule = { user: { automatic_schedule: yesOrNot } };

    return (dispatch: any, getState: any) => {
        const userId = getState().session.user.id;
        dispatch(updateScheduleConfigStart());
        putData(`users/${userId}/attributes/automatic_schedule`, updateSchedule)
            .then((result) => {
                dispatch(updateScheduleConfigSuc(result.data.data.attributes.automatic_schedule));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updateScheduleConfigError(e));
            });
    };
};
export default automaticTakeSchedule;
