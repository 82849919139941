import { getBlocksGroupStart, getBlocksGroupSucc, getBlocksGroupError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { isRoleOwner, isRoleRenter } from '../../helpers/roleComparison/index';
const moment = require('moment');

// TODO: Delete this action when all calendars are refactored
// since blockGroup calls are no longer needed
const getBlockGroup = (url?: any, id?: any, history?: any, shortRegisterUserId?: boolean, newWantToLease?: boolean) => {
    const currentWeek = {
        startDate: moment(),
        endDate: moment().endOf('week'),
    };
    const nextWeek = {
        startDate: moment().add(1, 'weeks').startOf('isoWeek'),
        endDate: moment().add(1, 'weeks').endOf('week'),
    };
    const twoWeeks = [currentWeek, nextWeek];

    return (dispatch: any) => {
        dispatch(getBlocksGroupStart());
        let urlFinal = '';
        if (isRoleOwner) {
            urlFinal = 'user/time_blocks';
        }

        if (isRoleRenter) {
            urlFinal = `user/time_blocks/renter_index`;
        }

        if (!isRoleOwner && !isRoleRenter) {
            urlFinal = 'time_blocks';
        }

        twoWeeks.forEach((week, index) => {
            const filterByDate = `&filters[start_date]=${week.startDate}&filters[end_date]=${week.endDate}`;
            const routeUse = `${urlFinal}${url}${id}${filterByDate}`;
            getData(routeUse)
                .then((result) => {
                    return dispatch(getBlocksGroupSucc(result.data, index));
                })
                .catch((e) => {
                    toast(handleError(e));
                    return dispatch(getBlocksGroupError(e));
                });
        });
    };
};
export default getBlockGroup;
