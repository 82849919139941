import { getContractStart, getContractSuccess, getContractError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getContract = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getContractStart());
        getData(`contracts/${id}`)
            .then((result) => {
                return dispatch(getContractSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getContractError(e));
            });
    };
};
export default getContract;
