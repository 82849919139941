import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { status, greenCheckIcon, warningOrangeIcon, errorXIcon } from '../../resources';
import { Done, Close, WarningRounded } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: '4rem',
            paddingRight: '2rem',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '150%',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    subTitle: any;
    onClose?: () => void;
    classNameTitle: string;
    noTitle?: boolean;
    modalType?: string;
    classes: any;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, subTitle, classes, onClose, classNameTitle, noTitle, modalType, ...other } = props;

    const { pathname } = window.location;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {modalType === 'SUCCESS' ? <img src={greenCheckIcon} alt='green check icon'></img> : null}
            {modalType === 'WARNING' ? (
                <>
                    {/* <div className='dot'></div> */}
                    <img src={warningOrangeIcon} alt='warning orange icon'></img>
                </>
            ) : null}
            {modalType === 'SIGN' ? <img src={status} alt='blue exclamation mark' /> : null}
            {modalType === 'ERROR' ? <img src={errorXIcon} alt='error cross mark' /> : null}
            <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                <Close className='close-icon' />
            </IconButton>
            <Typography variant='h2'>
                <p className={`${classNameTitle} new-modal-title`}>{children}</p>
                <p className={`${classNameTitle} new-modal-subtitle`}>{subTitle}</p>
            </Typography>
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(0),
        paddingLeft: '2rem',
        paddingRight: '2rem',
        marginBottom: '0',
    },
}))(MuiDialogContent);

type DialogModalProps = {
    title: string;
    subTitle: any;
    open: boolean;
    children?: any;
    handleClose?: () => void;
    className?: string;
    classNameTitle?: string;
    noTitle?: boolean;
    firstPosition?: boolean;
    contentDialogClass?: string;
    modalType?: string;
};
const NewDialogModal = ({
    children,
    title,
    subTitle,
    open,
    handleClose,
    className = '',
    classNameTitle = '',
    noTitle,
    firstPosition = false,
    contentDialogClass,
    modalType,
}: DialogModalProps) => {
    const idTitleWithNewButton = 'customized-dialog-title';
    return (
        <div>
            <Dialog
                className={`dialog-modal-container-tango ${className}`}
                aria-labelledby='customized-dialog-title'
                open={open}
                scroll='paper'
                style={{ zIndex: firstPosition ? 1300 : 999 }}
            >
                <DialogTitle
                    id={idTitleWithNewButton}
                    subTitle={subTitle}
                    onClose={handleClose}
                    classNameTitle={classNameTitle}
                    noTitle={noTitle}
                    modalType={modalType}
                >
                    {title}
                </DialogTitle>
                <DialogContent id='customized-status-dialog-content' dividers className={contentDialogClass}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default NewDialogModal;
