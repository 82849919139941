import * as Yup from 'yup'
import { setLocale } from 'yup'
import intl from 'react-intl-universal'

const FormCosignee = () => {
  setLocale({
    mixed: {
      required: intl.get('REQUIRED_FIELD'),
    },
    string: {
      email: intl.get('INSERT_VALID_MAIL'),
      min: intl.get('INSERT_MORE_CHARACTERS'),
    },
    number: {},
  })

  return Yup.object().shape({
    names: Yup.string(),
    surnames: Yup.string(),
    rut: Yup.string().min(9),
    birth_date: Yup.date(),
    street_address: Yup.string(),
    email: Yup.string().email(),
    phone_number: Yup.string().min(7),
    family_group: Yup.string(),
    ocupation: Yup.string(),
    employee: Yup.string(),
  })
}
export default FormCosignee
