import React from 'react'
import { ForgetPasswordToken } from '../../../tango-react-base/components'

const Login = () => {
  return (
    <div className="home-cnt container-home">
      <ForgetPasswordToken />
    </div>
  )
}
export default Login
