import { Form, FormikProps, withFormik } from 'formik';
import React from 'react';
import { DialogModal, Input } from '../../../tango-react-base/components';
import * as Yup from 'yup';
import intl from 'react-intl-universal';

type RejectInventoryModalProps = {
    openRejectModel: boolean;
    handleClose: () => void;
    onSubmit: (value: any) => void;
};

type RejectInventoryModal = {
    comments: string;
};

const RejectInventoryModalView: React.FunctionComponent<
    RejectInventoryModalProps & FormikProps<RejectInventoryModal>
> = ({ values, errors, handleChange, handleBlur, touched, openRejectModel, handleClose, isValid, ...form }) => {
    return (
        <DialogModal title={intl.get('REJECT_ANNEX_TITLE')} open={openRejectModel} handleClose={handleClose}>
            <>
                <Form>
                    <div className='col-12 text-left mb-4'>
                        <strong>{intl.get('REJECT_ANNEX_SUBTITLE')}</strong>
                    </div>
                    <Input
                        value={values.comments}
                        error={errors.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.comments}
                        disabled={false}
                        id='comments'
                        multiline={true}
                        className='col-12'
                        classNameInput='col-12 p-0'
                        rows={4}
                        placeholder={intl.get('REJECT_ANNEX_PLACEHOLDER')}
                    />
                    <button disabled={!values.comments} type='submit' className='button-primary col-md-6 my-4'>
                        {intl.get('SEND')}
                    </button>
                </Form>
            </>
        </DialogModal>
    );
};

const RejectInventoryModal = withFormik<RejectInventoryModalProps, RejectInventoryModal>({
    mapPropsToValues: (props: any) => {
        return {
            comments: '',
        };
    },

    validationSchema: Yup.object().shape({
        comments: Yup.string().nullable(false),
    }),

    handleSubmit(values: RejectInventoryModal, { props }) {
        props.onSubmit(values);
    },
})(RejectInventoryModalView);

export default RejectInventoryModal;
