import React, { useState, useEffect } from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import Input from '../input';
import intl from 'react-intl-universal';
import RadioButtonTango from '../radioButtonTango';
import { BANK_ACCOUNT_OPTIONS } from '../../../tango-react-base/helpers/selectOptions/options';
import { Select } from '..';
import FormValidates from './bankValidation';
import DialogModal from '../dialogModal/index';
import { useHistory } from 'react-router-dom';
import { getParameterByName } from '../../helpers/getParamsUrl';
import { isRoleAdmin, isRoleRenter, isRoleOwner } from '../../helpers/roleComparison/index';
import SwitchString from '../switchString/index';
import { danger } from '../../resources';
import { BOOLEAN_OPTIONS } from '../../helpers/selectOptions/options';
import { formatRut } from '../../helpers/formatRut';

/**
 * Here it is defined the type of the property, 'BankAccountDataPropsDoc' is for the documentation
 * @typedef BankAccountDataPropsDoc
 * @type {(function|object|boolean|string)}
 * @property {function} onSubmit - is an function.
 * @property {object} children - is an object.
 * @property {boolean} disabled - is an boolean.
 * @property {object} bankResource - is a object.
 * @property {string} bankDetailUpdateShow - is an string.
 * @property {string} fetchStatus - is an string.
 * @property {function} onConfirmBankDetail - is an function.
 * @property {string} confirmBankStatus - is an string.
 * @property {string} classContainer - is an string.
 * @property {function} setCompleteForm - is an function.
 */
interface BankAccountDataProps {
    onSubmit?: any;
    children?: any;
    disabled: boolean;
    bankResource: any;
    bankDetailUpdateShow?: any;
    fetchStatus?: string;
    onConfirmBankDetail?: any;
    confirmBankStatus?: string;
    classContainer?: string;
    bankDetailUser?: any;
    fetchProfileStatus?: string;
    setCompleteForm?: any;
}

/**
 * Here it is defined the type of the property, 'BankAccountDataProps' is for the documentation
 * @typedef BankAccountDataProps
 * @type {(string)}
 * @property {string} bank_account_type - is an string.
 * @property {string} bank_name - is an string.
 * @property {string} bank_account_number - is an string.
 * @property {string} email - is an string.
 */
interface BankAccountData {
    bank_account_type: string;
    bank_name: string;
    bank_account_number: string;
    email: string;
    full_name: string;
    rut: string;
    third_party: string;
}

/**
 * InnerForm is a functional component
 *@function
 *@param {FormikProps}  values - all values of the form (propertyUrl,email,startDate,blocks,fullAddress)
 *@param {FormikProps}  handleChange - detects changes in the input
 *@param {FormikProps}  handleBlur - returns an object
 *@param {FormikProps}  touched - returns a boolean indicating if it is "touched"
 *@param {FormikProps}  errors - returns an object with the errors
 *@param {BankAccountDataPropsDoc}  disabled - this boolean disable input
 *@param {BankAccountDataPropsDoc}  bankResource - returns a object with bankResource
 *@param {BankAccountDataPropsDoc}  children - returns children
 *@param {BankAccountDataPropsDoc}  bankDetailUpdateShow - return a object with bankDetailUpdateShow
 *@param {BankAccountDataPropsDoc}  fetchStatus - status of the fetchStatus
 *@param {BankAccountDataPropsDoc}  onConfirmBankDetail - execute confirmBankDetail
 *@param {BankAccountDataPropsDoc}  confirmBankStatus - status of the confirmBank
 *@param {BankAccountDataPropsDoc}  classContainer - div class
 *@param {BankAccountDataPropsDoc}  setCompleteForm - useState => complete form
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<BankAccountDataProps & FormikProps<BankAccountData>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    disabled,
    bankResource,
    children,
    bankDetailUpdateShow,
    fetchStatus,
    fetchProfileStatus,
    onConfirmBankDetail,
    confirmBankStatus,
    setCompleteForm,
}) => {
    const BANK_RESOURCE = bankResource.banks;
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const search = useHistory().location.search;
    const history = useHistory();
    const uuidValid = sessionStorage.getItem('uuid') || getParameterByName('uuid', search);

    useEffect(() => {
        const validation =
            bankDetailUpdateShow && uuidValid && uuidValid?.length === 36 && confirmBankStatus !== 'STARTED';
        if (validation && isRoleOwner && (fetchStatus === 'FETCHED' || fetchProfileStatus === 'FETCHED')) {
            setOpenConfirmModal(true);
        } else if (validation && isRoleRenter) {
            setOpenConfirmModal(true);
        } else {
            setOpenConfirmModal(false);
            sessionStorage.removeItem('uuid');
        }
        if (confirmBankStatus === 'ERROR' || confirmBankStatus === 'SUCCESS') {
            setOpenConfirmModal(false);
        }
    }, [uuidValid, bankDetailUpdateShow, fetchStatus, confirmBankStatus, fetchProfileStatus]);

    useEffect(() => {
        !openConfirmModal && sessionStorage.removeItem('uuid');
    }, [openConfirmModal]);

    const confirmBankDetail = () => {
        const data = {
            bank_data: {
                uuid: uuidValid,
            },
        };
        onConfirmBankDetail(data);
    };

    const handleCancelOrClose = () => {
        setOpenConfirmModal(false);

        history.push(isRoleRenter ? 'reference' : 'bank_detail');
    };
    useEffect(() => {
        setCompleteForm && setCompleteForm(Object.keys(errors).length >= 1);
    }, [errors]);

    useEffect(() => {
        if (values.third_party === 'no') {
            values.full_name = '';
            values.rut = '';
        }
    }, [values.third_party]);

    const ModalInfo = () => {
        return (
            <DialogModal
                title={intl.get('THIRD_PARTY_BANK')}
                open={openInfo}
                className='with-max-modal-visit'
                handleClose={() => setOpenInfo(false)}
            >
                <div className='d-flex flex-column'>
                    <span className='mb-4 mt-4 text-left'>{intl.get('IS_IMPORTANT_YOU_INFORM_US')}</span>
                </div>
                <button className='button-primary col-4' onClick={() => setOpenInfo(false)}>
                    {intl.get('ACCEPT')}
                </button>
            </DialogModal>
        );
    };

    const ModalConfirm = () => {
        return (
            <DialogModal title='' open={openConfirmModal} noTitle handleClose={() => handleCancelOrClose()}>
                <div>
                    <div className='template-line' />
                    <h4 className='text-left'>{intl.get('MY_BANK')}</h4>
                    <h3 className='text-left mt-3 mb-3'>{intl.get('CHECK_YOUR_DATA')}</h3>
                    <div>
                        {bankDetailUpdateShow.third_party === 'yes' && (
                            <>
                                <Input
                                    id='full_name_show'
                                    label='FULLNAME'
                                    type='text'
                                    value={bankDetailUpdateShow.full_name}
                                    disabled
                                    className='my-4'
                                    classNameInput='col-md-6 plm-0'
                                    justifyCenter='align-items-center'
                                />
                                <Input
                                    id='rut_show'
                                    label='RUT'
                                    type='text'
                                    value={bankDetailUpdateShow.rut}
                                    disabled
                                    className='my-4'
                                    classNameInput='col-md-6 plm-0'
                                    justifyCenter='align-items-center'
                                />
                                <Input
                                    id='email_show'
                                    label='EMAIL'
                                    type='text'
                                    value={bankDetailUpdateShow.email}
                                    className='my-4'
                                    classNameInput='col-md-6 plm-0'
                                    justifyCenter='align-items-center'
                                    disabled
                                />
                            </>
                        )}
                        <Input
                            id='bank_account_type_show'
                            label='BANK_ACCOUNT_TYPE'
                            type='text'
                            value={bankDetailUpdateShow.bank_account_type}
                            disabled
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                        />
                        <Input
                            id='bank_name_show'
                            label='BANK_NAME'
                            type='text'
                            value={bankDetailUpdateShow.bank_name}
                            disabled
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                        />
                        <Input
                            id='bank_account_number_show'
                            label='ACCOUNT_NUMBER'
                            type='text'
                            value={bankDetailUpdateShow.bank_account_number}
                            disabled
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                        />
                        {bankDetailUpdateShow.third_party !== 'yes' && (
                            <Input
                                id='email_show'
                                label='EMAIL'
                                type='text'
                                value={bankDetailUpdateShow.email}
                                className='my-4'
                                classNameInput='col-md-6 plm-0'
                                justifyCenter='align-items-center'
                                disabled
                            />
                        )}
                    </div>
                    <h3 className='text-left font-weight-bold'>{intl.get('CONFIRM_UPDATE_BANK')}</h3>
                    <div className='d-flex justify-content-between mt-4 row mb-4'>
                        <button className='button-primary col-md-5' onClick={() => confirmBankDetail()}>
                            {intl.get('CONFIRM_BTN')}
                        </button>
                        <button className='button-disabled-bank' onClick={() => handleCancelOrClose()}>
                            {intl.get('CANCEL')}
                        </button>
                    </div>
                </div>
            </DialogModal>
        );
    };

    const RenderSwitch = () => {
        return (
            <div className='col mb-2 p-0'>
                <ModalInfo />
                <div className='row'>
                    <div className='col col-md-4 pl-0' />
                    <div className='col-md-6 d-flex p-mb-0 text-left'>
                        <span>{intl.get('BANK_DETAILS_SOMEONE_ELSE')}</span>
                        <img
                            src={danger}
                            alt='danger-icon'
                            width='30'
                            onClick={() => setOpenInfo(true)}
                            className='pointer'
                        />
                    </div>
                </div>

                <SwitchString
                    value={values.third_party}
                    options={BOOLEAN_OPTIONS}
                    name='third_party'
                    label=' '
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    disabled={disabled}
                />
            </div>
        );
    };

    return (
        <div>
            {openConfirmModal && bankDetailUpdateShow && <ModalConfirm />}
            <Form className='mb-3'>
                <h2 className='new-subtitle'> {intl.get('BANK_INFORMATION')}</h2>
                <RadioButtonTango
                    id={'bank_account_type'}
                    error={errors.bank_account_type}
                    options={BANK_ACCOUNT_OPTIONS}
                    label='BANK_ACCOUNT_TYPE'
                    valueChecked={values.bank_account_type}
                    onChange={handleChange}
                    disabled={disabled}
                    className='my-4'
                    classNameInput='col-md-6 plm-0'
                    classNameBank='mw-8r'
                    classNameMobile='cold-sm-7 plm-0'
                />
                <Select
                    options={BANK_RESOURCE}
                    id='bank_name'
                    onChange={handleChange}
                    label={'BANK_NAME'.toUpperCase()}
                    value={values.bank_name}
                    disabled={disabled}
                    classNameInput='col-md-6 plm-0'
                    resource={true}
                    classNameSelect={disabled ? 'col-12 select-style disabled' : 'col-12 select-style'}
                />
                <Input
                    id='bank_account_number'
                    label='ACCOUNT_NUMBER'
                    type='text'
                    value={values.bank_account_number}
                    messageAdditionalValidation={errors.bank_account_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.bank_account_number}
                    disabled={disabled}
                    className='my-4'
                    classNameInput='col-md-6 plm-0'
                    justifyCenter='align-items-center'
                    info='NUMBER_ACCOUNT_INFO_V2'
                />
                {!isRoleAdmin && <RenderSwitch />}
                {values.third_party === 'yes' && (
                    <>
                        <Input
                            id='full_name'
                            label='FULLNAME'
                            type='text'
                            value={values.full_name}
                            messageAdditionalValidation={errors.full_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disabled}
                            touched={touched.full_name}
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                        />
                        <Input
                            id='rut'
                            label='RUT'
                            type='text'
                            value={values.rut ? formatRut(values.rut) : values.rut}
                            messageAdditionalValidation={errors.rut}
                            onChange={handleChange}
                            disabled={disabled}
                            onBlur={handleBlur}
                            touched={touched.rut}
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                        />
                        <Input
                            id='email'
                            label='EMAIL'
                            type='text'
                            value={values.email}
                            messageAdditionalValidation={errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.email}
                            className='my-4'
                            classNameInput='col-md-6 plm-0'
                            justifyCenter='align-items-center'
                            disabled={disabled}
                            info='THIS_EMAIL_USED'
                        />
                    </>
                )}
                {children}
            </Form>
        </div>
    );
};

const BankAccountDataForm = withFormik<BankAccountDataProps, BankAccountData>({
    mapPropsToValues: (props: any) => {
        const initialValues = {
            bank_account_type: props.bankDetailUser?.attributes.bank_account_type || 'checking_account',
            bank_name: props.bankDetailUser?.attributes.bank_name || '',
            bank_account_number: props.bankDetailUser?.attributes.bank_account_number || '',
            email: props.bankDetailUser?.attributes?.email || '',
            rut: props.bankDetailUser?.attributes.rut || '',
            full_name: props.bankDetailUser?.attributes.full_name || '',
            third_party: props.bankDetailUser?.attributes.third_party || '',
        };
        return { ...initialValues, ...props };
    },
    validationSchema: !isRoleAdmin && FormValidates,
    handleSubmit(values: BankAccountData, { props }) {
        props.onSubmit(values);
    },
})(InnerForm);

export default BankAccountDataForm;
