import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PopupWithIcon } from '..';
import intl from 'react-intl-universal';
import { isRoleRenter, isRoleOwner } from '../../helpers/roleComparison/index';
import { updatePhoneNumber, getUserPhone } from '../../reduxActions';
import { ChileFlag } from '../../resources';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';

/**
 * Here it is defined the type of the PopUpUserPhoneInput, this prop is similar to 'PopUpUserPhoneInput' but 'PopUpUserPhoneTypes' is for the documentation
 * @typedef PopUpUserPhoneTypes
 * @type {(string|number|Function|boolean)}
 * @property {Function} onGetUserPhoneStatus - is a Function.
 * @property {Function} onSaveUserPhone - is a Function.
 * @property {string} phoneNumber - is a string.
 * @property {boolean} phonePresent - is a boolean.
 * @property {number} userId - is a number.
 * @property {string} status - is a string.
 * @property {string} error - is a string.
 */
interface PopUpUserPhone {
    onGetUserPhoneStatus: Function;
    onSaveUserPhone: Function;
    userId: number;
    phonePresent: boolean;
    error?: string;
    status: string;
    contryCode?: string;
    provider?: string;
    userSavedPhoneNumber: string;
}

/**
 * PopUpUserPhoneInput is a functional component
 *@function
 *@param {PopUpUserPhoneTypes}  onGetUserPhoneStatus - handle the action to get the phone status of user (present or not)
 *@param {PopUpUserPhoneTypes}  onSaveUserPhone - handle the action to save phone number
 *@param {PopUpUserPhoneTypes}  userId - returns the user id from the session
 *@param {PopUpUserPhoneTypes}  phonePresent - returns the status of phone present or not
 *@param {PopUpUserPhoneTypes}  error - returns the error message
 *@param {PopUpUserPhoneTypes}  status - returns the status of the phone number
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const PopUpUserPhoneInput = ({
    onGetUserPhoneStatus,
    onSaveUserPhone,
    phonePresent,
    userId,
    status,
    userSavedPhoneNumber,
    contryCode = '+56',
    provider = '',
}: PopUpUserPhone) => {
    const history = useHistory();
    const [shownPopup, setShownPopup] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [check, setCheck] = useState(false);

    const showCondition = shownPopup && userSavedPhoneNumber === 'NOT_NUMBER';
    const handleSubmit = () => {
        onSaveUserPhone(userId, contryCode + phoneNumber);
    };
    const changeValue = (value: any) => {
        setPhoneNumber(value.floatValue);
        if (value.floatValue != undefined && value.floatValue.toString().length >= 8) {
            setCheck(true);
        } else {
            setCheck(false);
        }
    };

    useEffect(() => {
        // TODO: Modificar para que el location o pathname sea dependencia del useEffect
        history.listen((location) => {
            if (
                status === 'FETCHED' &&
                !phonePresent &&
                provider &&
                provider === 'google' &&
                (isRoleOwner || isRoleRenter)
            ) {
                setShownPopup(true);
            }
        });
    }, [history, status, phonePresent]);

    useEffect(() => {
        if (
            status === 'FETCHED' &&
            !phonePresent &&
            provider &&
            provider === 'google' &&
            (isRoleOwner || isRoleRenter)
        ) {
            setShownPopup(true);
        }
    }, [status, phonePresent]);

    useEffect(() => {
        setTimeout(() => {
            const userIdSession = JSON.parse(sessionStorage.getItem('session') || '0').id;

            if (userIdSession || userId) {
                onGetUserPhoneStatus(userId || userIdSession);
            }
        }, 1000);
    }, [userId]);

    const handleRedirect = () => {
        setShownPopup(false);
        window.location.href = isRoleOwner ? `${process.env.REACT_APP_RENTER}` : `${process.env.REACT_APP_OWNER}`;
    };
    const modal = () => (
        <PopupWithIcon title='ENTER_PHONE' open={showCondition} className='popup-phone-input-modal' firstPosition>
            <span className='d-flex pb-1 font-weight-bold'>{intl.get('PHONE')}</span>
            <div className='user-phone-input'>
                <div className='user-phone-input-flag'>
                    <img src={ChileFlag} alt='Chile flag' width={30} />
                    <span>{contryCode}</span>
                </div>
                <NumberFormat
                    className='phone-input'
                    id={'user-phone_input-google'}
                    placeholder={intl.get('PHONE')}
                    defaultValue={phoneNumber ? phoneNumber : ''}
                    value={phoneNumber}
                    required
                    customInput={TextField}
                    format='# ## ### ###'
                    onValueChange={(value) => changeValue(value)}
                    InputProps={{ disableUnderline: true }}
                />
            </div>

            <button
                className='button-primary mt-3 d-flex w-100 justify-content-center'
                onClick={handleSubmit}
                disabled={!check}
            >
                {intl.get('SAVE')}
            </button>
            <div className='pt-4'>
                <button className='btn-user-close' onClick={handleRedirect}>
                    {intl.get(!isRoleRenter ? 'RENTER_LOOKING_RENT' : 'OWNER_LOOKING_POST')}
                </button>
            </div>
        </PopupWithIcon>
    );

    return modal();
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
    return {
        userId: isRoleRenter ? state.session.user?.id : state.session.user?.id || state.session.userProfile?.id,
        phonePresent: state.termsAndCondition?.phonePresent || state.userPhone?.phonePresent,
        status:
            state.termsAndCondition?.status === 'FETCHED' || state.userPhone?.status === 'FETCHED'
                ? 'FETCHED'
                : 'NO_FETCH',
        sendStatus: state.user.fetchUserNumber,
        userSavedPhoneNumber: state.user.phoneNumber,
        provider: state.termsAndCondition?.provider || state.userPhone?.provider,
    };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetUserPhoneStatus: (id: number) => {
            return dispatch(getUserPhone(id));
        },
        onSaveUserPhone: (id: number, phoneNumber: any) => {
            return dispatch(updatePhoneNumber({ user: { phone_number: phoneNumber } }, id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpUserPhoneInput);
