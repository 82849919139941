import { getCurrentRenewalPeriodStart, getCurrentRenewalPeriodSuccess, getCurrentRenewalPeriodError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getCurrentRenewalPeriod = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getCurrentRenewalPeriodStart());
        getData(`renewal_periods/${id}`)
            .then((result) => {
                return dispatch(getCurrentRenewalPeriodSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getCurrentRenewalPeriodError(e));
            });
    };
};
export default getCurrentRenewalPeriod;
