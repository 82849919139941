import { newVisitOrTourStart, newVisitOrTourSuccess, newVisitOrTourError } from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
const newVisitOrTour = (data: any, history: any) => {
    const isNotPhotographer = process.env.REACT_APP_API_ROLE_APP !== process.env.REACT_APP_VERIFIER;

    const newVisit = {
        visit: {
            start_time: data.block,
            post_id: data.post_id,
            renter_email: data.email,
            kind: isNotPhotographer ? data.kind : 'verify',
            avla_state: data.avla_state,
        },
    };

    return (dispatch: any, getState: any) => {
        dispatch(newVisitOrTourStart());
        postData('unit_visits', newVisit)
            .then((result) => {
                toast(intl.get('VISIT_CREATED'));
                setTimeout(() => {
                    history.go(0);
                }, 2500);
                return dispatch(newVisitOrTourSuccess(result.data));
            })
            .catch((e) => {
                const blocks = e.response.data.errors ? e.response.data.errors[0].data : '';
                toast(handleError(e));
                return dispatch(newVisitOrTourError(e.response.data.errors[0], blocks));
            });
    };
};
export default newVisitOrTour;
