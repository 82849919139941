import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { getNotificationStart, getNotificationSuc, getNotificationError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getNotificationUser = () => {
    return (dispatch: any) => {
        let role = process.env.REACT_APP_ROLE;
        if (sessionStorage.getItem('authorization')) {
            dispatch(getNotificationStart());
            getData(`auth/events?user_role=${role}`)
                .then((result) => {
                    dispatch(getNotificationSuc(result.data));
                })
                .catch((e) => {
                    toast(handleError(e));
                    return dispatch(getNotificationError(e));
                });
        }
    };
};
export default getNotificationUser;
