import React, { useState } from 'react';
import { DialogModal } from '../../components';
import intl from 'react-intl-universal';
import IconAndDescription from './IconAndDescription';
import {
    SignupMobCorretajeOwner,
    SignupMobOfertaOwner,
    SignupMobPagosOwner,
    SignupMobOwner,
    SignupMobRegistroRenter,
    SingupMobRegistro2Renter,
    SingupMobLayerRenter,
    SingupMobRenter,
} from '../../resources';

interface ModalSignUpProps {
    isOwner?: boolean;
    mobile?: boolean;
}

const ModalSignUp = ({ isOwner, mobile }: ModalSignUpProps) => {
    const [openModal, setOpenModal] = useState(true);
    return (
        <DialogModal
            title={intl.getHTML('SIGN_UP_OWNER')}
            open={openModal}
            handleClose={() => setOpenModal(false)}
            singUp={true}
        >
            <div className='popup-register-phone m-4'>
                <IconAndDescription
                    img={isOwner ? SignupMobOfertaOwner : SingupMobRenter}
                    text={intl.get(`ICON_TEXT_${isOwner ? 'OWNER' : 'RENTER'}_1`)}
                    mobile={mobile}
                />
                <IconAndDescription
                    img={isOwner ? SignupMobCorretajeOwner : SignupMobRegistroRenter}
                    text={intl.get(`ICON_TEXT_${isOwner ? 'OWNER' : 'RENTER'}_2`)}
                    mobile={mobile}
                />
                <IconAndDescription
                    img={isOwner ? SignupMobOwner : SingupMobRegistro2Renter}
                    text={intl.get(`ICON_TEXT_${isOwner ? 'OWNER' : 'RENTER'}_3`)}
                    mobile={mobile}
                />
                <IconAndDescription
                    img={isOwner ? SignupMobPagosOwner : SingupMobLayerRenter}
                    text={intl.get(`ICON_TEXT_${isOwner ? 'OWNER' : 'RENTER'}_4`)}
                    mobile={mobile}
                />
                {isOwner && <p className='info-owner-singup'>{intl.get('SIGNUP_INFO_OWNER')}</p>}
            </div>
            <button className='sign-up-button' onClick={() => setOpenModal(false)}>
                {intl.get('I_WANT_TO_SIGN_UP')}
            </button>
        </DialogModal>
    );
};

export default ModalSignUp;
