import submitCalendar from './submitCalendar';
import submitBlocks from './submitBlocks';
import changeVisit from './changeVisit';
const addDate = (date: any, aasm_state?: string, id?: number) => {
    return {
        type: 'ADD_DATE',
        payload: {
            date,
            aasm_state,
            id,
        },
    };
};
const changeDay = (date: any, type?: any) => {
    if (type === 'agent') {
        return {
            type: 'CHANGE_DAY_AGENT_VISIT',
            payload: {
                date,
            },
        };
    } else {
        return {
            type: 'CHANGE_DAY',
            payload: {
                date,
            },
        };
    }
};
const changeDate = (date: any, type?: any) => {
    if (type === 'agent') {
        return {
            type: 'CHANGE_DATE_AGENT_VISIT',
            payload: {
                date,
            },
        };
    } else {
        return {
            type: 'CHANGE_DATE',
            payload: {
                date,
            },
        };
    }
};
const changeHour = (hour: any, type?: any, aasm_state?: string, id?: number, stateBlocks?: any) => {
    if (type === 'agent') {
        return {
            type: 'CHANGE_HOUR_AGENT_VISIT',
            payload: {
                hour,
                aasm_state,
                id,
            },
        };
    } else {
        return {
            type: 'CHANGE_HOUR',
            payload: {
                hour,
                stateBlocks,
            },
        };
    }
};
const nextWeek = () => {
    return {
        type: 'NEXT_WEEK',
    };
};

const lastWeek = () => {
    return {
        type: 'LAST_WEEK',
    };
};

export { submitCalendar, changeDay, addDate, changeDate, nextWeek, lastWeek, changeHour, changeVisit, submitBlocks };
