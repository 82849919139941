import {
    monitor,
    group,
    home,
    money,
    fee,
    tangobox,
    process,
    verification,
    codebtor,
    online,
    moneyhand,
} from '../../resources/index';

export const CARD_ITEMS = [
    { message: 'OUR_PLATFORM_IS_DIGITAL', icon: monitor },
    { message: 'EXPERIENCED_TEAM', icon: group },
    { message: 'OUR_SERVICES_GUARANTEE', icon: home },
];

export const OWNER_BENEFITS = [
    { description: 'DONT_PAY_UNTIL_2', icon: money },
    { description: 'CHEAPEST_FEE', icon: fee },
    { description: 'WITH_TANGOBOX', icon: tangobox },
    { description: 'WE_COVER', icon: process },
];

export const RENTER_BENEFITS = [
    { description: 'WE_VERIFY_EVERY_PROPERTY', icon: verification },
    { description: 'DONT_NEED_GUARANTOR', icon: codebtor },
    { description: 'ONLNE_PAYMENT', icon: online },
    { description: 'WE_SAFEGUARD_DEPOSIT', icon: moneyhand },
];

export const TANGO_FIGURES = [
    { message: 'COLLABORATORS', number: '+19.000' },
    { message: 'GLOBAL_MARKETS', number: '+193' },
    { message: 'UNITS_UNDER_MANAGEMENT', number: '+660.000' },
    { message: 'COUNTRIES', number: '13' },
];
