export const rejectMediationStart = () => {
    return {
        type: 'MEDIATION_RESPONSE_STARTED',
    };
};

export const rejectMediationSuccess = () => {
    return {
        type: 'MEDIATION_RESPONSE_SUCCESS',
    };
};

export const rejectMediationError = (error: any) => {
    return {
        type: 'MEDIATION_RESPONSE_ERROR',
        payload: error,
    };
};
