import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { getPostsitStart, getPostsitSuc, getPostsError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUnits = () => {
  return (dispatch: any, getState: any) => {
    dispatch(getPostsitStart())
    getData('posts')
      .then((result) => {
        dispatch(getPostsitSuc(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPostsError(e))
      })
  }
}
export default getUnits
