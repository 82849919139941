import { templateChildStart, templateChildSuc } from './types';
import { getNotificationUser } from '..';
import { isHomePage } from '../../helpers/roleComparison';

const updatePost = (values: any, backTo?: string, templateInBody?: boolean) => {
    const principalContainer = document.getElementsByClassName('principal-container')[0];
    if (principalContainer)
        templateInBody && !isHomePage
            ? principalContainer.classList.add('back-ground-line-diagonal')
            : principalContainer.classList.remove('back-ground-line-diagonal');
    return (dispatch: any, getState: any) => {
        dispatch(getNotificationUser());
        dispatch(templateChildStart());
        dispatch(templateChildSuc(values, backTo));
    };
};

export default updatePost;
