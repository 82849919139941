const initialState: any = {
  photos: [],
  deletingPhoto: [],
  deletedPhoto: [],
  fetch: 'LOADING',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PHOTOS_STARTED':
      return {
        ...state,
        fetch: 'LOADING',
      }
    case 'GET_PHOTOS_SUCCESS':
      return {
        ...state,
        photos: action.payload.data,
        fetch: 'LOADED',
      }
    case 'GET_PHOTOS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
      /* DELETE PHOTO */
    case 'DELETE_PHOTO_STARTED':
        return {
          ...state,
          deletingPhoto: action.payload,
        }
      case 'DELETE_PHOTO_SUCCESS':
        let id = action.payload.photo.data.data.id
        let photosAux = state.photos.filter((photo: any) => {
          return photo.id !== id
        })
        return {
          ...state,
          photos: photosAux,
          deletingPhoto: [],
        }
      case 'DELETE_PHOTO_ERROR':
        return {
          ...state,
          error: action.payload.error,
          fetchPhoto: 'ERROR',
        }
    default:
      return state
  }
}
