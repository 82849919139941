import React, { useEffect, useState } from 'react';
import { CircularLoading } from '..';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { submitBlocks, changeDay, changeHour } from '../../reduxActions/calendarActions';
import { nextWeek, lastWeek } from '../../reduxActions/requesterCalendarActions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { getBlocksAvailable } from '../../reduxActions';
import intl from 'react-intl-universal';
import { backArrow, forwardArrow } from '../../resources/index';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { RequesterCalendarMobile } from './requesterCalendarMobile';
import { getMonth } from '../../helpers/getMonth';
import CalendarColorMeaning from '../calendarColorMeaning/index';

var moment = require('moment');

interface CalendarProps {
    onSubmit: any;
    startWeek: any;
    endWeek: any;
    onLastWeek: any;
    onNextWeek: any;
    week: Array<any>;
    blocksTaken: Array<any>;
    hoursTaken: Array<any>;
    blocksDisabledWithId: Array<any>;
    onChangeDate: any;
    submitblocksTaken: any;
    onChangeHour: any;
    blocksDisabled: any;
    blocksAvailable: Array<any>;
    fetchingBlocksAvailable: string;
    onGetBlocks: any;
    error: any;
}

const RequesterCalendar = ({
    onSubmit,
    startWeek,
    endWeek,
    onLastWeek,
    onNextWeek,
    week,
    blocksTaken,
    blocksDisabledWithId,
    hoursTaken,
    submitblocksTaken,
    blocksDisabled,
    onChangeDate,
    onChangeHour,
    error,
    onGetBlocks,
    fetchingBlocksAvailable,
}: CalendarProps) => {
    let history = useHistory();

    let { id }: any = useParams();

    const [showBlocks, setShowBlocks] = useState(false);
    const [showBlocksMorning, setShowBlocksMorning] = useState(false);
    const [showBlocksAfternoon, setShowBlocksAfternoon] = useState(false);
    const [showBlocksNight, setShowBlocksNight] = useState(false);

    let tenDays = moment().add(13, 'day');

    if (error === 'disabled blocks') {
    }
    useEffect(() => {
        onGetBlocks(id);
    }, [id, onGetBlocks]);
    const dayDate = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        return initDay.format('DD');
    };
    const blocksDay = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        const endDate = moment(date).hours(21).minutes(0).seconds(0);
        let iterDate = initDay;
        let dateBlocks: Array<any> = [];
        while (iterDate < endDate) {
            dateBlocks.push({
                block_group: {
                    hour: `${iterDate.format('HH:mm')}`,
                    start_time: `${iterDate.format('YYYY-MM-DD HH:mm:ss')}`,
                    end_time: `${iterDate.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}`,
                },
            });
        }
        return dateBlocks;
    };

    /* blocksDate => Its function is to filter the data of blockdays obtaining the blocks of hours and make a
     mapping of all the blocks generating buttons with the hours available for days.
     Only the corresponding blocks will be enabled per hour range.
  */
    const blocksDate = (date: any, blocks: string, blockValidation?: boolean) => {
        const data = blocksDay(date);

        const start_time = moment(date).hours(8).minutes(0).seconds(0);
        let current_date = moment().format('YYYY-MM-DD');
        const filterData = data.filter((hr: any) => {
            const hour = moment(hr.block_group.start_time);
            let duration = moment.duration(hour.diff(start_time));
            let hours = duration.asHours();
            if (blocks === 'morning') {
                return hours <= 3.5;
            } else if (blocks === 'afternoon') {
                return hours >= 4 && hours <= 8.5;
            } else if (blocks === 'night') {
                return hours >= 9 && hours <= 17;
            }
        });

        if (filterData[0].block_group.end_time >= current_date && data.length > 0) {
            let blockComplete: any = filterData.map((block: any, index: number) => {
                return (
                    <div key={index}>
                        {isDisabled(block.block_group.start_time) ? (
                            <div className='toggle-btn-schedule'>
                                <ToggleButton
                                    key={index}
                                    value='check'
                                    selected={isSelectedHour(block.block_group.start_time)}
                                    onChange={() => {
                                        onChangeHour(
                                            block.block_group.start_time,
                                            'scheduled',
                                            isScheduledId(block.block_group.start_time),
                                        );
                                    }}
                                    className='col toggle-button-hour-renter'
                                >
                                    <div className='columns'>{block.block_group.hour}</div>
                                </ToggleButton>
                            </div>
                        ) : (
                            <div className='available'>
                                <ToggleButton
                                    key={index}
                                    value='check'
                                    selected={isSelectedHour(block.block_group.start_time)}
                                    onChange={() => {
                                        onChangeHour(block.block_group.start_time, '');
                                    }}
                                    className='col-12 toggle-button-hour-renter'
                                >
                                    <div className='columns'>{block.block_group.hour}</div>
                                </ToggleButton>
                            </div>
                        )}
                    </div>
                );
            });

            return blockComplete;
        } else return <p className='text-left mt-4'>{intl.get('DATE_FUTURE')}</p>;
    };

    const isScheduledId = (date: any) => {
        const filteredblocks = blocksDisabledWithId.filter((dateIter: any) => {
            return moment(dateIter.start_time).isSame(date);
        });

        return filteredblocks[0].id;
    };

    const isDisabled = (date: any) => {
        const filteredblocksDisabled = blocksDisabled.filter((dateIter: any) => {
            return moment(dateIter).isSame(date);
        });
        return filteredblocksDisabled.length > 0;
    };

    const isSelectedHour = (hour: any) => {
        const filteredHoursTaken = hoursTaken.filter((hourTaken: any) => {
            return hourTaken.hour === hour;
        });
        return filteredHoursTaken.length > 0;
    };

    const isSelected = (date: any) => {
        return blocksTaken === date;
    };

    const handleMorning = () => {
        setShowBlocks(true);
        setShowBlocksAfternoon(false);
        setShowBlocksMorning(!showBlocksMorning);
        setShowBlocksNight(false);
    };
    const handleAfternoon = () => {
        setShowBlocks(true);
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(!showBlocksAfternoon);
        setShowBlocksNight(false);
    };

    const handleNight = () => {
        setShowBlocks(true);
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(false);
        setShowBlocksNight(!showBlocksNight);
    };

    /* disabledDay => disable the Sunday blocks, those that are greater than
  ten days from today and that are less than the current day */
    let actualDay = moment().subtract(1, 'days');
    const disabledDay = (day: any) => {
        if (day.day() === 0 || day > tenDays || day < actualDay) {
            return true;
        } else {
            return isDisabled(dayDate(day));
        }
    };

    const blockType = [
        {
            name: 'Morning',
            block: '8:00-11:30',
            action: handleMorning,
            show: showBlocksMorning,
        },
        {
            name: 'Afternoon',
            block: '12:00-16:30',
            action: handleAfternoon,
            show: showBlocksAfternoon,
        },
        {
            name: 'Night',
            block: '17:00-20:30',
            action: handleNight,
            show: showBlocksNight,
        },
    ];

    const showButtonActivated = showBlocksMorning || showBlocksNight || showBlocksAfternoon;

    const firstDayWeek = week[0];
    const [weekCount, setWeekCount] = useState(1);

    const handleNextWeek = () => {
        if (weekCount === 1 && weekCount <= 2) {
            onNextWeek();
            setWeekCount(weekCount + 1);
        }
    };
    const handleLastWeek = () => {
        if (weekCount > 1) {
            moment(firstDayWeek) >= moment() && onLastWeek();
            setWeekCount(weekCount - 1);
        }
    };
    // size.width >= 768 => renders desktop if it is greater than or equal to 768px
    const size = useWindowSize();
    return size.width >= 768 ? (
        <>
            <div className='row'>
                {fetchingBlocksAvailable === 'FETCHED' && (
                    <div className='right-calendar-arrow'>
                        <a onClick={() => handleLastWeek()}>
                            <img src={backArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}

                <div className='col-10'>
                    {fetchingBlocksAvailable === 'FETCHED' && (
                        <>
                            <div className='d-flex justify-content-between '>
                                <h2 className='mb-2 text-left pl-2'>{getMonth(startWeek, endWeek)}</h2>
                                <small>{intl.getHTML('MAX_TWO_HOURS')}</small>
                            </div>
                            <div className='header-calendar calendar-container'>
                                <section className='row no-wrap week-section align-items-start'>
                                    {week.map((day: any, index: number) => {
                                        return (
                                            <div className='col columns day-column' key={index}>
                                                <div className='d-flex justify-content-center align-items-center div-sec-owner'>
                                                    <span className='word-sp-5 font-sm-1r'>
                                                        {intl.get(day.format('dddd').toUpperCase())} {day.format('DD')}
                                                    </span>
                                                </div>
                                                {blockType.map(({ name, block, action, show }) => {
                                                    return (
                                                        <React.Fragment key={name}>
                                                            <ToggleButton
                                                                value='check'
                                                                selected={isSelected(dayDate(day))}
                                                                onClick={action}
                                                                disabled={disabledDay(day)}
                                                                onChange={() => {
                                                                    onChangeDate(dayDate(day));
                                                                }}
                                                                className={`col-12 toggle-button-hour ${
                                                                    disabledDay(day)
                                                                        ? 'btn-disabled'
                                                                        : !show && 'bg-transparent'
                                                                }`}
                                                            >
                                                                <div className='columns btn-day-owner'>
                                                                    <p>{intl.get(name.toUpperCase())}</p>
                                                                    <p>{block}</p>
                                                                </div>
                                                                {!disabledDay(day) && (
                                                                    <img
                                                                        src={backArrow}
                                                                        alt='arrow'
                                                                        className='arrow-section ml-2'
                                                                        width='10'
                                                                    />
                                                                )}
                                                            </ToggleButton>
                                                            <section className={!show ? 'd-none' : ''}>
                                                                {isSelected(dayDate(day)) ? (
                                                                    <div className='list-hours-new'>
                                                                        {show && blocksDate(day, name.toLowerCase())}
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </section>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </section>
                                {!showButtonActivated && <CalendarColorMeaning />}
                            </div>
                            <section>
                                <div className='mb-3'>
                                    <button
                                        className='button-primary mt-xl-1 w-50'
                                        type='submit'
                                        disabled={hoursTaken.length < 1}
                                        onClick={() => submitblocksTaken(id, history)}
                                    >
                                        <p className='m-0'>{intl.get('UPDATE_AVAILABILITY')}</p>
                                    </button>
                                </div>
                            </section>
                            {showButtonActivated && <CalendarColorMeaning />}
                        </>
                    )}
                </div>

                {fetchingBlocksAvailable === 'FETCHED' && (
                    <div className='left-calendar-arrow'>
                        <a onClick={() => handleNextWeek()}>
                            <img src={forwardArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}
            </div>
            {fetchingBlocksAvailable === 'FETCHING' && <CircularLoading />}
        </>
    ) : (
        <RequesterCalendarMobile
            hourTaken={hoursTaken}
            id={id}
            fetchingBlocks={fetchingBlocksAvailable}
            onChangeDate={onChangeDate}
            isSelected={isSelected}
            dayDate={dayDate}
            moment={moment}
            history={history}
            submitblocksTaken={submitblocksTaken}
            ToggleButton={ToggleButton}
            backArrow={backArrow}
            blocksDate={blocksDate}
            blockType={blockType}
        />
    );
};

const mapStateToProps = (state: any) => ({
    ...state.visitsCalendar,
    ...state.postDetail,
});

const mapDispatchToProps = (dispatch: any, getState: any) => {
    return {
        onNextWeek: () => {
            return dispatch(nextWeek());
        },
        onLastWeek: () => {
            return dispatch(lastWeek());
        },
        onChangeDate: (date: any) => {
            return dispatch(changeDay(date, 'agent'));
        },
        onChangeHour: (hour: any, aasm_state?: string, id?: number) => {
            return dispatch(changeHour(hour, 'agent', aasm_state, id));
        },
        submitblocksTaken: (id: any, history: any) => {
            dispatch(submitBlocks(id, history));
        },
        onGetBlocks: (id: string) => {
            return dispatch(getBlocksAvailable(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequesterCalendar);
