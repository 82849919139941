/* istanbul ignore file */
import getPost from './getPost'
import uploadImages from './uploadImages'
import getPosts from './getPosts'
import updatePost from './updatePost'
import uploadFile from './uploadFile'
import updateUserInformation from './updateUserInformation'
import getUserData from './session/getUserData'
import getUser from './session/getUser'
import setSession from './session/setSession'
import getBlocks from './getBlocks'
import setBlocks from './setBlock'
import getVisits from './getVisits'
import addConsignee from './addConsignee'
import removeConsignee from './removeConsignee'
import createProfile from './createProfile'
import putSign from './putSign'
import getConsignee from './getConsignee'
import getVisitsPending from './getVisitsPending'
import getVisitsFinished from './getVisitsFinished'
import getVisitsCanceled from './getVisitsCanceled'
import cancelVisit from './cancelVisit'
import changeUnit from './changeUnit'
import getRentersPending from './getRentersPending'
import getRentersValidate from './getRentersValidate'
import getRentersNotReady from './getRentersNotReady'
import setTokenNewPassword from './session/setTokenNewPassword'
import sendTokenNewPassword from './session/sendTokenNewPassword'
import updatePassword from './session/updatePassword'
import logOutSession from './session/logOutSession'
import deletePhoto from './deleteImages'
import createUser from './user/createUserData'
import sendTokenCreateUser from './user/sendTokenCreateUser'
import getLocationUser from './getLocationUser'
import updateUser from './updateUser'
import navBarMenuAction from './navBarMenuAction'
import getNotificationUser from './getNotificationUser'
import pressNotification from './pressNotification'
import getVisitHistory from './getVisitHistory'
import visitFinishedAction from './visitFinishedAction'
import setTemplateChild from './setTemplateChild'
import postTocSessionId from './postTocSessionId'
import uploadDocuments from './uploadDocuments'
import updateDocuments from './updateDocuments'
import postTocLivenessId from './postTocLivenessId'
import uploadLivenessData from './uploadLivenessData'
import getContract from './getContract'
import getBlocksAvailable from './getBlocksAvailable'
import addUnit from './addUnit'
import requestEmailContract from './requestEmailContract'
import getAdminContract from './getAdminContract'
import putSignAdminContract from './putSignAdminContract'
import getCountries from './getCountries'
import getOwnerData from './getOwnerData'
import addOwnerData from './addOwnerData'
import updateOwnerData from './updateOwnerData'
import sendFormFooter from './sendFormWorkWithUs'
import getCommunes from './getCommunes'
import getCities from './getCities'
import updateProfile from './updateProfile'
import getCountriesCodes from './getCountriesCodes'
import getUserDataByEmail from './session/getUserDataByEmail'
import getIdentityDocument from './session/getIdentityDocument'
import getRenterDataContract from './getRenterDataContract'
import approveAnnex from './approveAnnex'
import rejectAnnex from './rejectAnnex'
import getOwnerDataContract from './getOwnerDataContract'
import getCheckRequirements from './getCheckProposalRequirements'
import updateUnit from './updateUnit'
import newVisitOrTour from './newTourOrVisit'
import getRentedPost from './getRentedPost'
import getReference from './getReference'
import getBankDetail from './getBankDetail'
import getBankResource from './getBankResource'
import createBankDetail from './createBankDetail'
import updateBankDetail from './updateBankDetail'
import getBankDetailUpdate from './getBankDetailUpdate'
import confirmBankDetail from './confirmBankDetail'
import getPhotos from './getPhotos'
import automaticTakeSchedule from './automaticSchedule'
import getBlockGroup from './getBlocks/groups'
import validateEmailPresence from './user/validateEmailPresence'
import createBasicUser from './user/createBasicUserData'
import getPromotion from './getPromotion'
import getNewTokenByEmail from './getNewTokenByEmail'
import getNewToken from './getNewToken'
import resendRegistrationEmail from './resendRegistrationEmail'
import getIdentityDocumentStatus from './getIdentityDocumentStatus'
import getAdminContractStatus from './getAdminContractStatus'
import getLeaseContractStatus from './getLeaseContractStatus'
import postRejectLeaseRenewal from './postRejectLeaseRenewal'
import getRenewalPeriodAvailability from './getRenewalPeriodAvailability'
import getCurrentRenewalPeriod from './getCurrentRenewalPeriod'
import acceptLeaseRenewal from './acceptLeaseRenewal'
import proposePriceByOwner from './proposePriceByOwner'
import proposePriceByRenter from './proposePriceByRenter'
import getContracts from './getContracts'
import acceptMediation from './acceptMediation'
import rejectMediation from './rejectMediation'
import proposePriceByAdmin from './proposePriceByAdmin'
import reproposePriceByAdmin from './reproposePriceByAdmin'
import rejectLeaseRenewalByAdmin from './rejectLeaseRenewalByAdmin'
import getUnitVersion from './getUnitVersion'
import updateUnitVersion from './updateUnitVersion'
import publishUnitVersion from './publishUnitVersion'
import getPostsToRepublish from './getPostsToRepublish'
import updateUserPassword from './updateUserPassword'
import updatePhoneNumber from './updatePhoneNumber'
import uploadLeaseDocument from './uploadLeaseDocuments'
import getLeaseDocuments from './getLeaseDocuments'
import getDatasContracts from './getDatasContracts'
import getPropertyDocuments from './getPropertyDocuments'
import uploadAnnex from './uploadAnnex'
import getOffersRented from './getOffersRented'
import getTermsAndConditionStatus from './getTermsAndConditionStatus'
import acceptTermsAndCondition from './acceptTermsAndContition'
import getRestitutions from './getRestitutions'
import getPendingVouchers from './getPendingVouchers'
import getContactData from './getContactData'
import getRegisterType from './getRegisterType'
import getTransferData from './getTransferData'
import setGoogleSession from './session/setGoogleSession'
import getRentState from './getRentState'
import getCLF from './getCLF'
import getUserPhone from './getUserPhone'
import getRenterPreApproved from './getRenterPreApproved'
import getNewReference from './newReference'
import sendRenterEvaluation from './sendRenterEvaluation'
import getAdvertGalleryPublished from './getAdvertisementGalleryPublished'
import getRequisitions from './getRequisitions'
import assignPromotion from './assignPromotion'
import getChargeLines from './getChargeLines'
import getOwnerPayments from './getOwnerPayments'
import updateChargeLines from './updateChargeLines'
import getPaymentsProviders from './getPaymentsProviders'
import markAsPaidCharges from './markAsPaidCharges'
import getRequisition from './getRequisition'
import getClosureLeaseData from './getClosureLeaseData'
import postClosureLeaseConfirmation from './postClosureLeaseConfirmation'
import postClosureLeaseCancel from './postClosureLeaseCancel'
import rejectEarlyRequisition from './rejectEarlyRequisition'
import confirmEarlyRequisition from './confirmEarlyRequisition'
import markAsPaidFineOwner from './markAsPaidFineOwner'
import closeEarlyRequisition from './closeEarlyRequisition'
import putSignDocument from './putSignDocument'
import getDocumentFile from './getDocumentFile'

export {
  getIdentityDocument,
  getIdentityDocumentStatus,
  getAdminContractStatus,
  getLeaseContractStatus,
  getPost,
  uploadImages,
  getPosts,
  updatePost,
  uploadFile,
  updateUserInformation,
  getUserData,
  getUser,
  getBlocks,
  setBlocks,
  getVisits,
  addConsignee,
  removeConsignee,
  createProfile,
  putSign,
  getConsignee,
  getVisitsPending,
  getVisitsFinished,
  getVisitsCanceled,
  cancelVisit,
  changeUnit,
  getRentersPending,
  getRentersValidate,
  getRentersNotReady,
  setSession,
  setTokenNewPassword,
  sendTokenNewPassword,
  updatePassword,
  logOutSession,
  deletePhoto,
  createUser,
  sendTokenCreateUser,
  getLocationUser,
  updateUser,
  navBarMenuAction,
  getNotificationUser,
  pressNotification,
  getVisitHistory,
  visitFinishedAction,
  setTemplateChild,
  postTocSessionId,
  uploadDocuments,
  updateDocuments,
  postTocLivenessId,
  uploadLivenessData,
  getContract,
  getBlocksAvailable,
  addUnit,
  requestEmailContract,
  getAdminContract,
  putSignAdminContract,
  getCountries,
  getOwnerData,
  addOwnerData,
  updateOwnerData,
  sendFormFooter,
  getCommunes,
  getCities,
  updateProfile,
  getCountriesCodes,
  getUserDataByEmail,
  getRenterDataContract,
  approveAnnex,
  rejectAnnex,
  getOwnerDataContract,
  getCheckRequirements,
  updateUnit,
  newVisitOrTour,
  getRentedPost,
  getReference,
  getBankDetail,
  getBankResource,
  createBankDetail,
  updateBankDetail,
  getBankDetailUpdate,
  confirmBankDetail,
  getPhotos,
  automaticTakeSchedule,
  getBlockGroup,
  validateEmailPresence,
  createBasicUser,
  getPromotion,
  getNewTokenByEmail,
  resendRegistrationEmail,
  getNewToken,
  postRejectLeaseRenewal,
  getRenewalPeriodAvailability,
  getCurrentRenewalPeriod,
  acceptLeaseRenewal,
  proposePriceByOwner,
  proposePriceByRenter,
  getContracts,
  acceptMediation,
  rejectMediation,
  proposePriceByAdmin,
  reproposePriceByAdmin,
  rejectLeaseRenewalByAdmin,
  getUnitVersion,
  updateUnitVersion,
  publishUnitVersion,
  getPostsToRepublish,
  updateUserPassword,
  updatePhoneNumber,
  uploadLeaseDocument,
  getLeaseDocuments,
  getPropertyDocuments,
  uploadAnnex,
  getOffersRented,
  getTermsAndConditionStatus,
  acceptTermsAndCondition,
  getPendingVouchers,
  getRestitutions,
  getContactData,
  getRegisterType,
  getTransferData,
  setGoogleSession,
  getRentState,
  getCLF,
  getUserPhone,
  getRenterPreApproved,
  getNewReference,
  sendRenterEvaluation,
  assignPromotion,
  getAdvertGalleryPublished,
  getRequisitions,
  getChargeLines,
  getOwnerPayments,
  updateChargeLines,
  getDatasContracts,
  getPaymentsProviders,
  markAsPaidCharges,
  getRequisition,
  getClosureLeaseData,
  postClosureLeaseConfirmation,
  postClosureLeaseCancel,
  rejectEarlyRequisition,
  confirmEarlyRequisition,
  markAsPaidFineOwner,
  closeEarlyRequisition,
  putSignDocument
}
