import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Field } from 'formik';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core';
import { calendar } from '../../resources/index';
import es from 'date-fns/esm/locale/es';
import { useWindowSize } from '../../helpers/hookWindowSize';
import moment from 'moment';

interface DatePickerProps {
    alignItems?: string;
    className?: string;
    classNameInput?: string;
    classNameLabel?: string;
    label?: string;
    disabled?: boolean;
    disableToolbar?: boolean;
    info?: string;
    labelDatePicker?: string;
    maxDate?: Date;
    minDate?: Date;
    name: string;
    value: Date | null | any;
    variant?: boolean;
    maxDateMessage?: string;
    minDateMessage?: string;
    openTo?: boolean;
    error?: string;
    isDisableWeekends?: boolean;
    isVerifier?: boolean;
}
const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#A8123E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);

const DatePicker = ({
    alignItems = 'align-items-start',
    maxDateMessage = 'MAX_DATE',
    minDateMessage = 'MIN_DATE',
    openTo = false,
    className,
    classNameInput = 'col-md-4',
    classNameLabel = 'col-md-4 pl-0',
    disabled,
    disableToolbar = true,
    info,
    label,
    labelDatePicker,
    maxDate,
    minDate,
    name,
    value,
    variant,
    error,
    isDisableWeekends,
    isVerifier = false,
}: DatePickerProps) => {
    const handleDateChange = (field: any, form: any) => (date: Date | null) => {
        field.value[name] = date;
        form.setFieldValue(date);
        setDate(date);
    };

    const size = useWindowSize();
    const inputVariant = variant ? undefined : 'outlined';
    const [selectedDate, setDate] = React.useState(value);

    const disableWeekends = (date: any) =>
        isDisableWeekends ? date.getDay() === 0 || moment().add(10, 'day') < date : date.getDay() === 8;

    return (
        <Field>
            {({ field, form }: any) => {
                return (
                    <div className={`row date-picker-container d-flex ${alignItems} ${className}`}>
                        {label !== undefined && (
                            <div className={`${classNameLabel} my-auto`}>
                                <span className='span-text'>{`${intl.get(`${label}`)}${isVerifier ? '*' : ''}`}</span>
                                {info && <p className='p-info-label'>{info}</p>}
                            </div>
                        )}
                        <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                            <div className={`${classNameInput} ${size.width < 991 && 'pl-0'}`}>
                                <BootstrapInput
                                    openTo={openTo ? 'year' : 'date'}
                                    views={['year', 'month', 'date']}
                                    variant='inline'
                                    placeholder='dd/mm/aaaa'
                                    inputVariant={inputVariant}
                                    disableToolbar={disableToolbar}
                                    shouldDisableDate={disableWeekends}
                                    size='small'
                                    format='dd/MM/yyyy'
                                    margin='normal'
                                    id='date-picker-inline'
                                    label={labelDatePicker}
                                    value={selectedDate ? moment(selectedDate) : null}
                                    onChange={handleDateChange(field, form)}
                                    invalidDateMessage={intl.get('INVALID_FORMAT_DATE')}
                                    maxDate={maxDate}
                                    maxDateMessage={intl.get(maxDateMessage)}
                                    minDate={minDate}
                                    minDateMessage={intl.get(minDateMessage)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    readOnly={disabled}
                                    disabled={disabled}
                                    helperText={error}
                                    keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='C' />}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                );
            }}
        </Field>
    );
};

export default DatePicker;
