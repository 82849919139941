import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { CircularLoading, ErrorView, PopupWithIcon } from '../..';
import { setTemplateChild, updatePassword } from '../../../reduxActions';
import { checkLogo } from '../../../resources';
import Input from '../../input';

interface NewPasswordFormProps {
    onLoginSession: any;
    idUser?: string;
    children?: any;
    disabled: boolean;
    fetchUpdatePasswordStatus: string;
    params: any;
    fetchForgetStatus: string;
    onSetTemplateChild: (child: Object) => void;
}

interface NewPasswordForm {
    password: string;
    repeat_password: string;
}
const InnerForm: React.FunctionComponent<NewPasswordFormProps & FormikProps<NewPasswordForm>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    children,
    disabled,
    fetchUpdatePasswordStatus,
    fetchForgetStatus,
    onSetTemplateChild,
}) => {
    let history = useHistory();
    const [updatedPassword, setUpdatedPassword] = useState(false);
    useEffect(() => {
        onSetTemplateChild(
            <>
                <h2>{intl.get('FORGET_PASSWORD')}</h2>
            </>,
        );
    }, [onSetTemplateChild]);

    useEffect(() => {
        setUpdatedPassword(fetchUpdatePasswordStatus === 'UPLOADED');
    }, [fetchUpdatePasswordStatus]);

    const redirect = () => {
        setUpdatedPassword(false);
        history.push('/login');
    };

    if (fetchForgetStatus === 'FETCHED_TOKEN' || fetchUpdatePasswordStatus === 'UPLOADED') {
        return (
            <>
                <div className='card-view card-without-shadow col-md-6'>
                    <div className='row'>
                        <h2 className='title text-left'>{intl.get('NEW_PASSWORD')}</h2>
                    </div>
                    <div className='template-line'></div>
                    <div className='row text-left mt-2'>
                        <span className='title-info mb-3'>{intl.getHTML('ENTER_NEW_PASSWORD')}</span>
                    </div>

                    {fetchUpdatePasswordStatus !== 'UPLOADED' ? (
                        <Form>
                            <Input
                                required={true}
                                id='password'
                                label={'password'.toUpperCase()}
                                type='password'
                                value={values.password}
                                error={errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.password}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left'
                                justifyCenter='align-items-center'
                                placeholder={intl.get('MIN_CHARS_PASSWORD')}
                            />

                            <Input
                                required={true}
                                id='repeat_password'
                                label={'CONFIRM_PASSWORD'}
                                type='password'
                                value={values.repeat_password}
                                error={errors.repeat_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.repeat_password}
                                disabled={false}
                                classNameLabel='col-12 p-0'
                                classNameInput='col-12 p-0 mt-1'
                                className='col-12 p-0 text-left'
                                justifyCenter='align-items-center'
                                placeholder={intl.get('MIN_CHARS_PASSWORD')}
                            />

                            {fetchUpdatePasswordStatus === 'ERROR_UPLOADING' && (
                                <p className='text-warning'>{intl.get('CHECK_PASS')}</p>
                            )}
                            {values.password !== values.repeat_password && (
                                <p className='text-danger'>{intl.get('CHECK_PASS_ERROR')}</p>
                            )}
                            {fetchUpdatePasswordStatus === 'UPLOADING' ? (
                                <p>
                                    <CircularLoading />
                                </p>
                            ) : (
                                <button
                                    className='button-primary col-12 mt-5 mx-sm-0'
                                    type='submit'
                                    disabled={values.password !== values.repeat_password}
                                >
                                    {intl.get('CONTINUE')}
                                </button>
                            )}
                        </Form>
                    ) : (
                        <PopupWithIcon
                            icon={checkLogo}
                            title='CHANGE_OF_PASSWORD_SUCCESSFUL'
                            open={updatedPassword}
                            handleClose={redirect}
                            lineUp
                        >
                            <p>{intl.get('PASSWORD_UPDATE')}</p>
                        </PopupWithIcon>
                    )}
                </div>
            </>
        );
    } else return <ErrorView />;
};

const NewPasswordForms = withFormik<NewPasswordFormProps, NewPasswordForm>({
    mapPropsToValues: (props: any) => {
        const initialValues = {
            password: '',
            repeat_password: '',
            ...props.params,
        };
        return { ...initialValues };
    },

    validationSchema: Yup.object().shape({}),

    handleSubmit(values: NewPasswordForm, { props, setSubmitting, setErrors }) {
        props.onLoginSession(values);
    },
})(InnerForm);

const mapStateToProps = (state: any) => {
    return {
        fetchForgetStatus: state.session.fetchForgetStatus,
        fetchUpdatePasswordStatus: state.session.fetchUpdatePasswordStatus,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onLoginSession: (values: any) => {
            return dispatch(updatePassword(values));
        },
        onSetTemplateChild: (child: Object) => {
            return dispatch(setTemplateChild(child));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordForms);
