import { rejectAnnexStart, rejectAnnexSuccess, rejectAnnexError } from './types';
import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
import { getContract, getRentedPost } from '../../reduxActions';
import { isRoleOwner, isRoleAdmin, isRoleRenter } from '../../helpers/roleComparison/index';

const canDispatchGetContract = isRoleAdmin || isRoleOwner;

const rejectAnnex = (id: string, value: any) => {
    const formData = new FormData();
    formData.append('delivery_voucher[delivery_voucher_rejection_comments]', value.comments);
    return (dispatch: any) => {
        dispatch(rejectAnnexStart());
        putData(`lease_contract_delivery_vouchers/${id}/reject`, formData)
            .then((result) => {
                toast(intl.get('INVENTORY_REJECTED'));
                dispatch(rejectAnnexSuccess());
                canDispatchGetContract && dispatch(getContract(id));
                isRoleRenter && dispatch(getRentedPost());
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(rejectAnnexError(e));
            });
    };
};
export default rejectAnnex;
