export const uploadPhotoError = (error: any) => {
    return {
        type: 'UPLOAD_PHOTO_ERROR',
        payload: {
            error,
        },
    };
};

export const uploadPhotoStart = (photoIndex: string) => {
    return {
        type: 'UPLOAD_PHOTO_STARTED',
        payload: { photoIndex: photoIndex },
    };
};

export const uploadPhotoSuccess = (photo: any, index: string) => {
    return {
        type: 'UPLOAD_PHOTO_SUCCESS',
        payload: { photo, indexPhoto: index },
    };
};
