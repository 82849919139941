import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import accessValueByString from '../../helpers/accessValueByString';
import { Input } from '../';
import { isRoleAdmin } from '../../helpers/roleComparison';

interface Option {
    value: number | string;
    label: string;
}

interface RadioButtonInputProps {
    disabled?: boolean;
    error: string;
    id: string;
    label: string;
    onChange: any;
    options: Array<Option>;
    required: boolean;
    valueChecked: any;
    valueSelected?: any;
    setValueSelected?: any;
    requiredInput: boolean;
    idInput: string;
    labelInput: string;
    onBlur: any;
    onChangeInput: any;
    touched: any;
    type: string;
    valueInput: any;
    isVerifier?: boolean;
    isRequiredVerifier?: boolean;
}

interface FieldProps {
    form: any;
    field: any;
}

const RadioButtonOptions = ({
    disabled,
    error,
    id,
    label,
    onChange,
    options,
    required = false,
    valueChecked,
    valueSelected,
    setValueSelected,
    form,
    field,
    requiredInput,
    idInput,
    labelInput,
    onBlur,
    onChangeInput,
    touched,
    type,
    valueInput,
    isVerifier,
    isRequiredVerifier = false,
}: RadioButtonInputProps & FieldProps) => {
    useEffect(() => {
        const actualValue = accessValueByString(field.value, id);
        if (!actualValue && actualValue !== 0) {
            form.setFieldValue(id, undefined);
        }
    }, []);

    const changeValue = (e: any, name: string, value: any) => {
        form.setFieldValue(name, valueSelected !== value ? value : null);
        setValueSelected(valueSelected !== value ? value : null);
        valueSelected === value && e.preventDefault(false);
    };

    const isUpperCase = (word: string) => {
        if (word === '0') return false;
        return word.toUpperCase() === word && !Number(word);
    };

    return (
        <div className='row radio-button-tango-container d-flex align-items-start '>
            {label !== undefined && (
                <div className='col-md-4 col-12 pl-0'>
                    <span className='col-md-12  span-text color-secondary'>
                        {`${intl.get(`${label}`)}${isVerifier ? '*' : ''}`}
                    </span>
                </div>
            )}

            <div className='row col-md-4 col-12 plm-0 plm-0 radio-toolbar'>
                {options.map((option, index) => {
                    const classByBoolean = Number(option.label) || parseInt(option.label) === 0;
                    return (
                        <div key={`opt-${index}-${label}`}>
                            <input
                                type='radio'
                                id={`radio${classByBoolean ? '-number' : ''}-${option.label}-${id}`}
                                className={`${classByBoolean ? 'radio-number' : 'radio-string'}
                ${
                    `${option.value}, ${option.value}` === valueSelected
                        ? 'checked'
                        : valueSelected === option.value && 'checked'
                }`}
                                name={`${id}`}
                                value={option.value}
                                onClick={(e) => changeValue(e, id, option.value)}
                                defaultChecked={
                                    `${option.value}, ${option.value}` === valueSelected ||
                                    valueSelected === option.value
                                }
                                disabled={disabled}
                                required={required}
                            />
                            <label
                                className={`${
                                    classByBoolean
                                        ? 'button-secondary-disabled-number'
                                        : 'button-secondary-disabled-string'
                                }`}
                                htmlFor={`radio${classByBoolean ? '-number' : ''}-${option.label}-${id}`}
                            >
                                {isUpperCase(option.label) ? intl.get(`${option.label}`) : option.label}
                            </label>
                        </div>
                    );
                })}
                {(error && required) ||
                    (error && (
                        <div className='text col-12 pl-0'>
                            <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                        </div>
                    ))}
                {(isVerifier || isRoleAdmin) && isRequiredVerifier && (
                    <div className='text col-12 pl-0'>
                        <p className='text-color-error small m-0'>{intl.get('REQUIRED_FIELD')}</p>
                    </div>
                )}
            </div>
            <div className='col-xl-4 col-md-6 p-sm-0'>
                {valueSelected > 0 && (
                    <div className='row'>
                        <Input
                            required={requiredInput}
                            disabled={disabled}
                            id={idInput}
                            label={labelInput}
                            onBlur={onBlur}
                            onChange={onChangeInput}
                            touched={touched}
                            type={type}
                            value={valueInput}
                            className='my-0'
                            classNameInput='col-md-10 p-0 m-0'
                        />
                        {valueSelected >= 2 && (
                            <p className='text-color-secondary small m-0'>
                                {valueInput && valueInput.length <= 2 && intl.get('SEPARATE_PARKING')}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
const RadioButtonInputTango = ({ ...props }: RadioButtonInputProps) => {
    const [valueSelected, setValueSelected] = useState<string | undefined | number>(props.valueChecked);
    return (
        <Field>
            {({ field, form }: any) => {
                return (
                    <RadioButtonOptions
                        {...props}
                        form={form}
                        field={field}
                        valueSelected={valueSelected}
                        setValueSelected={setValueSelected}
                    />
                );
            }}
        </Field>
    );
};
export default RadioButtonInputTango;
