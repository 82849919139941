import { multipartPost } from '../../helpers/apiConnections';
import { uploadFileStart, uploadFileSuccess, uploadFileError } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const uploadPdfTruoraDicom = (renter_profile_id: number, document_type: string, title: string, file: File) => {
    return (dispatch: any, getState: any) => {
        dispatch(uploadFileStart(title));
        let form_data = new FormData();
        form_data.append(`renter_document[renter_profile_id]`, renter_profile_id.toString());
        form_data.append(`renter_document[title]`, title);
        form_data.append(`renter_document[document_type]`, document_type);
        form_data.append(`renter_document[file]`, file);
        multipartPost('renter_documents', form_data)
            .then((result) => {
                toast(intl.get('FILE_UPLOADED'));
                dispatch(uploadFileSuccess(result.data, title));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(uploadFileError(e, title));
            });
    };
};
export default uploadPdfTruoraDicom;
