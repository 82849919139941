import React, { useState, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { InputMap } from '..';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import './style.scss';
import intl from 'react-intl-universal';
const countries = require('i18n-iso-countries');

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

interface selectProps {
    description: string;
    structured_formatting: any;
}

interface FieldProps {
    form?: any;
    field?: any;
}

interface inputProps {
    values: any;
    id: string;
    label?: string;
    requiredMessage?: string;
    touched: any;
    type: string;
    required?: boolean;
    disabled: boolean;
    error: any;
    setFieldValue?: any;
    hiddenMap?: boolean;
    className?: string;
    classNameInput?: string;
    variant?: boolean;
    labelInput?: string;
    classNameContainer?: string;
    classNameAutocompleteList?: string;
    classNameAutocompleteListIn?: string;
    classNameSuggestions?: string;
    showOnlySecondText?: boolean;
    allValues?: any;
    validAddress?: boolean;
    stateValidAddress?: boolean;
    stateUnit?: string | undefined;
    detectSelectGMap?: boolean;
    countrySelected: string;
    address: string;
    setInvalidAddress?: any;
    placeholder?: string;
    info?: string;
}

/**
 * Here it is defined the type of the property, this prop is similar to 'inputProps' but 'inputPropsTypes' is for the documentation
 * @typedef inputPropsTypes
 * @type {(boolean|string|object|Function)}
 * @property {string} values - is an string.
 * @property {string} id - is an string.
 * @property {string} label - is an string.
 * @property {boolean} requiredMessage - is an boolean.
 * @property {string} type - is a string.
 * @property {boolean} required - is an boolean.
 * @property {boolean} disabled - is an boolean.
 * @property {object} error - is an object.
 * @property {object} form - is an object.
 * @property {string} className - is an string.
 * @property {string} classNameInput - is an string.
 * @property {string} variant - is an string.
 * @property {string} labelInput - is an string.
 * @property {string} classNameContainer - is an string.
 * @property {string} classNameAutocompleteList - is an string.
 * @property {string} classNameAutocompleteListIn - is an string.
 * @property {object} allValues - is an object.
 * @property {boolean} stateValidAddress - is an boolean.
 * @property {boolean} detectSelectGMap - is an boolean.
 * @property {string} countrySelected - is an string.
 * @property {string} address - is an string.
 * @property {Function} setInvalidAddress - is a function.
 * @property {string} info - is an string.
 */

/**
 * Autocomplete is an input where the address is entered with google maps
 *@function
 *@param {inputPropsTypes}  values - this is the address
 *@param {inputPropsTypes}  id - this is the id of the input
 *@param {inputPropsTypes}  label - this is the label for translation
 *@param {inputPropsTypes}  requiredMessage - message showing when a field is required
 *@param {inputPropsTypes}  type - type of the input
 *@param {inputPropsTypes}  required - input is required or not(true o false)
 *@param {inputPropsTypes}  disabled - input is disabled or not(true o false)
 *@param {inputPropsTypes}  error - returns a error
 *@param {inputPropsTypes}  form - get the form
 *@param {inputPropsTypes}  className - string with class
 *@param {inputPropsTypes}  classNameInput - string with classNameInput
 *@param {inputPropsTypes}  variant - string with the variant
 *@param {inputPropsTypes}  labelInput - label of the input
 *@param {inputPropsTypes}  classNameContainer - string with classNameContainer
 *@param {inputPropsTypes}  classNameAutocompleteList - string with classNameAutocompleteList
 *@param {inputPropsTypes}  classNameAutocompleteListIn - string with classNameAutocompleteListIn
 *@param {inputPropsTypes}  allValues - get user data
 *@param {inputPropsTypes}  stateValidAddress - returns if the address is correct
 *@param {inputPropsTypes}  detectSelectGMap - detect select with google maps
 *@param {inputPropsTypes}  countrySelected - default is chile
 *@param {inputPropsTypes}  address - is onlye the address
 *@param {inputPropsTypes}  setInvalidAddress - detect if address is invalid
 *@param {inputPropsTypes} info - string With a text about the input
 * @returns {(ReactComponent)} returns a component with a list of streets from google maps
 */
const Autocomplete = ({
    values,
    id,
    label,
    requiredMessage,
    type,
    required,
    disabled,
    error,
    form,
    className,
    classNameInput,
    classNameSuggestions,
    variant,
    labelInput,
    classNameContainer,
    allValues,
    stateValidAddress = false,
    detectSelectGMap,
    countrySelected,
    address,
    setInvalidAddress,
    placeholder = '',
    info = '',
}: inputProps & FieldProps) => {
    const {
        ready,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {
                country: [countries.getAlpha2Code(countrySelected, 'en')],
            },
        },
        debounce: 300,
    });

    const [validAddress, setValidAddress] = useState(stateValidAddress);
    const [validHouse, setValidHouse] = useState(true);
    const [addressType, setAddressType] = useState([]);
    const ref = useOnclickOutside(() => clearSuggestions());

    useEffect(() => {
        if (address) {
            handleAddress(address);
        }
    }, []);

    useEffect(() => {
        setInvalidAddress && setInvalidAddress(!validAddress);
    }, [validAddress]);

    useEffect(() => {
        if (!values || values.split(',').length !== 3) {
            setValidAddress(false);
            setAddressType([]);
        }
    }, [values]);

    const handleErrorMessage = () => {
        const containAddress = ['street_address', 'premise'];
        if (addressType[0] === 'route') return intl.get('INDICATE_FULL_ADDRESS');
        if (
            (addressType[0] && !containAddress.includes(addressType[0])) ||
            (values?.length === 0 && (!validHouse || !validAddress))
        ) {
            return intl.get('INVALID_ADDRESS_GENERIC');
        }
        if (!validAddress) return intl.get('SELECT_ADDRESS');
    };

    const handleInput = (e: any) => {
        detectSelectGMap && form.setFieldValue('selectOneGMap', false);
        const value = e.target.value;
        setValue(value);
        form.setFieldValue(id, value);
    };

    const handleAddress = (address: string) => {
        form.setFieldValue('inputValueAddress', address);

        getGeocode({ address: address })
            .then((results) => {
                const addressComponents = results[0].address_components;
                const findValidHouse = addressComponents.find((house: any) => house.types.includes('locality'));
                const containAddress = ['street_address', 'premise'];
                containAddress.includes(results[0].types.join()) ? validActions(results) : invalidActions(results);
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    };

    const getAddressComponents = (fullData: any) => {
        const address_components = fullData[0].address_components;
        address_components.forEach((comp: any) => {
            const types = comp.types;
            types.includes('street_number') && getAddressNumber(comp);
            types.includes('route') && getAddressStreet(comp);
            types.includes('locality') && getCommune(comp);
            types.includes('administrative_area_level_3') && getCommune(comp);
            types.includes('administrative_area_level_2') && getProvince(comp);
            types.includes('administrative_area_level_1') && getCity(comp);
            types.includes('country') && getCountry(comp);
        });
    };

    const getAddressNumber = (data: any) => form.setFieldValue('address_number', data.long_name);

    const getAddressStreet = (data: any) => form.setFieldValue('address_street', data.long_name);

    const getCommune = (data: any) => form.setFieldValue('commune', data.long_name);

    const getProvince = (data: any) => form.setFieldValue('province', data.long_name);

    const getCity = (data: any) => form.setFieldValue('city', data.long_name);

    const getCountry = (data: any) => form.setFieldValue('country_name', data.long_name);

    const handleSelect =
        ({ description, structured_formatting: { main_text, secondary_text } }: selectProps) =>
        () => {
            detectSelectGMap && form.setFieldValue('selectOneGMap', true);
            const textValue = description;
            form.setFieldValue(id, textValue);
            clearSuggestions();
            handleAddress(description);
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <MenuItem key={place_id} onClick={handleSelect(suggestion)}>
                    <span>
                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                    </span>
                </MenuItem>
            );
        });

    const validActions = (results: any) => {
        getAddressComponents(results);
        setValidAddress(true);
        setAddressType(results[0].types);
        form.setFieldValue('acceptedAddress', true);
    };

    const invalidActions = (results: any) => {
        const fields = ['address_number', 'address_street', 'commune', 'province', 'city', 'country_name'];
        fields.forEach((field) => {
            form.setFieldValue(field, '');
        });
        setValidAddress(false);
        setAddressType(results[0].types);
        form.setFieldValue('acceptedAddress', false);
        form.setFieldValue('inputValueAddress', '');
    };

    return (
        <div className={classNameContainer} ref={ref}>
            <InputMap
                placeholder={placeholder}
                required={required}
                disabled={!ready || disabled}
                id={id}
                label={label}
                onChange={(e: any) => handleInput(e)}
                requiredMessage={requiredMessage}
                type={type}
                value={values}
                className={className}
                classNameInput={classNameInput}
                variant={variant}
                labelInput={labelInput}
                disabledAutocomplete={true}
                error={error}
                messageAdditionalValidation={handleErrorMessage()}
                info={info}
            >
                {status === 'OK' && (
                    <div className='d-flex justify-content-start'>
                        <div className={`list-container ${classNameSuggestions}`}>
                            <ul className='p-0'>{renderSuggestions()}</ul>
                        </div>
                    </div>
                )}
            </InputMap>
        </div>
    );
};

const PlacesAutocomplete = ({ ...props }: inputProps) => {
    return <Field>{({ field, form }: any) => <Autocomplete {...props} form={form} field={field} />}</Field>;
};

export default PlacesAutocomplete;
