import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { submitCalendar, addDate } from '../../reduxActions/calendarActions';
import { nextWeek, lastWeek } from '../../reduxActions/requesterCalendarActions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { automaticTakeSchedule, getBlocks, getUser } from '../../reduxActions';
import intl from 'react-intl-universal';
import { backArrow, CalendarDate, CalendarOff, forwardArrow } from '../../resources/index';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { CalendarMobileAgent } from './calendarMobileVisitor';
import { CircularLoading } from '..';
import { getMonth } from '../../helpers/getMonth';
import DialogModal from '../dialogModal';
import { Typography } from '@material-ui/core';
import CalendarColorMeaning from '../calendarColorMeaning';

var moment = require('moment');

interface CalendarProps {
    onSubmit: any;
    automaticSchedule: string;
    startWeek: any;
    endWeek: any;
    onLastWeek: any;
    onNextWeek: any;
    week: Array<any>;
    blocksTaken: Array<any>;
    blocksDisabled: Array<any>;
    blocksDisabledWithId: Array<any>;
    onChangeDate: (timeOfDay: any, aasm_state?: string, id?: number) => void;
    onGetBlocks: () => void;
    onGetUser: () => void;
    updateSchedule: (arg: any) => void;
    isLoadingBlocks?: string;
    fetchingBlocksSubmitting?: string;
}

const Calendar = ({
    onSubmit,
    automaticSchedule,
    startWeek,
    endWeek,
    onLastWeek,
    onNextWeek,
    week,
    blocksTaken,
    blocksDisabled,
    blocksDisabledWithId,
    onChangeDate,
    onGetBlocks,
    onGetUser,
    updateSchedule,
    isLoadingBlocks,
    fetchingBlocksSubmitting,
}: CalendarProps) => {
    const inputSwitch = useRef<any>();
    const [checkbox, setCheckbox] = useState({
        openModal: false,
        isChecked: automaticSchedule === 'yes',
    });

    const morningDate = (date: any) => {
        return moment(date).hours(8).minutes(0).seconds(0);
    };
    const afternoonDate = (date: any) => {
        return moment(date).hours(12).minutes(0).seconds(0);
    };
    const nightDate = (date: any) => {
        return moment(date).hours(17).minutes(0).seconds(0);
    };

    const dayDate = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        return initDay.format('DD');
    };

    const showModalConfirm = (e: any) => {
        setCheckbox({
            ...checkbox,
            openModal: true,
            isChecked: e.target.checked,
        });
    };
    const confirmSwitch = (isEnable: boolean) => {
        updateSchedule(isEnable);
        setCheckbox({ ...checkbox, openModal: false });
    };
    const handleCancelOrClose = (enable: boolean) => {
        if (inputSwitch.current) {
            inputSwitch.current.checked = !enable;
        }
        setCheckbox({ ...checkbox, openModal: false });
    };
    useEffect(() => {
        if (automaticSchedule === 'yes' || fetchingBlocks !== 'FETCHED_BLOCKS') {
            onGetBlocks();
            onGetUser();
        }
    }, [automaticSchedule]);

    useEffect(() => {
        if (isLoadingBlocks === 'SUCCESS' && fetchingBlocks === 'FETCHED_BLOCKS') {
            onGetBlocks();
        }
    }, [isLoadingBlocks]);

    const isSelected = (date: any) => {
        const filteredblocksTaken = blocksTaken.filter((dateIter: any) => {
            return moment(dateIter.date).isSame(date);
        });
        return filteredblocksTaken.length > 0;
    };

    const isDisabled = (date: any) => {
        if (blocksDisabled.length === 0) return false;
        const filteredblocksTaken = blocksDisabled.filter((dateIter: any) => {
            return moment(dateIter).isSame(date);
        });
        return filteredblocksTaken.length > 0;
    };

    const isScheduledId = (date: any) => {
        const filteredblocks = blocksDisabledWithId.filter((dateIter: any) => {
            return moment(dateIter.start_time).isSame(date);
        });

        return filteredblocks[0]?.id || '';
    };

    const size = useWindowSize();
    const { fetchingBlocks } = useSelector((state: any) => state.visitCalendar);

    const blockType = [
        {
            name: 'Morning',
            block: '8:00 - 11:59',
            action: morningDate,
        },
        {
            name: 'Afternoon',
            block: '12:30 - 16:59',
            action: afternoonDate,
        },
        {
            name: 'Night',
            block: '17:00 - 20:30',
            action: nightDate,
        },
    ];

    const currentYear = new Date().getFullYear();
    return fetchingBlocks === 'FETCHED_BLOCKS' ? (
        <div className='columns wrap calendar-container pd-1 mt-5'>
            <DialogModal title='' handleClose={() => handleCancelOrClose(checkbox.isChecked)} open={checkbox.openModal}>
                <h2 className='subtitle-modal'>{intl.get('BLOCK_AVAILABILITY')}</h2>
                <img src={checkbox.isChecked ? CalendarDate : CalendarOff} alt='Logo' className='my-3' />
                <Typography gutterBottom>
                    {checkbox.isChecked ? intl.get('ACTIVATE_BLOCK_TWO_WEEKS') : intl.get('DEACTIVATE_BLOCK_TWO_WEEKS')}
                </Typography>
                <div className='d-flex justify-content-between mt-4 row'>
                    <button
                        className='button-disabled-bank col-md-5 mb-3'
                        onClick={() => handleCancelOrClose(checkbox.isChecked)}
                    >
                        {intl.get('CANCEL')}
                    </button>
                    <button className='button-primary col-md-5' onClick={() => confirmSwitch(checkbox.isChecked)}>
                        {intl.get('CONFIRM_BTN')}
                    </button>
                </div>
            </DialogModal>
            {size.width >= 768 ? (
                <>
                    <div className='row calendar-month'>
                        <button
                            onClick={() => onLastWeek()}
                            disabled={blocksTaken.length > 0}
                            className='col-2 arrow-logo-container'
                        >
                            <img src={backArrow} alt='arrow' className={size.width >= 768 ? 'arrow-logo' : 'h-sm-50'} />
                        </button>
                        <h2 className='col-8 align-self-center '>
                            {getMonth(startWeek, endWeek)} - {currentYear}
                        </h2>
                        <button
                            onClick={() => onNextWeek()}
                            disabled={blocksTaken.length > 0}
                            className='col-2 arrow-logo-container'
                        >
                            <img
                                src={forwardArrow}
                                alt='arrow'
                                className={size.width >= 768 ? 'arrow-logo' : 'h-sm-50'}
                            />
                        </button>
                    </div>
                    <section className='row no-wrap week-section align-items-start'>
                        {week.map((day: any, index: number) => {
                            const startOrEndBorder =
                                (index === 0 && 'border-radius-left') || (index === 6 && 'border-radius-rigth') || '';
                            const now = new moment().subtract(1, 'days');

                            const threeMonths = new moment().add(3, 'month');
                            const classBtnSchedule = (action: any) => {
                                return isDisabled(action) && disabledBtn ? 'toggle-btn-schedule' : 'available';
                            };

                            const disabledBtn = day.day() !== 0 && threeMonths > day && day > now;

                            return (
                                <div className='day-column col-mb-4 mt-sm-2' key={index}>
                                    <div
                                        className={`${startOrEndBorder} d-flex justify-content-center align-items-center divSection mb-2`}
                                    >
                                        <span className='word-nowrap font-sm-1r'>
                                            {`${intl.get(day.format('dddd').toUpperCase())} ${day.format('DD')}`}
                                        </span>
                                    </div>
                                    {blockType.map(({ name, block, action }) => {
                                        const aasm_state = isScheduledId(action(day)).length > 0 ? 'scheduled' : '';
                                        return (
                                            <div className={`${classBtnSchedule(action(day))}`}>
                                                <ToggleButton
                                                    value='check'
                                                    selected={isSelected(action(day))}
                                                    disabled={!disabledBtn}
                                                    onChange={() => {
                                                        onChangeDate(
                                                            action(day),
                                                            aasm_state,
                                                            isScheduledId(action(day)),
                                                        );
                                                    }}
                                                    className={`toggle-button-calendar ${
                                                        !disabledBtn && 'btn-disabled'
                                                    } ${classBtnSchedule(action(day))}`}
                                                >
                                                    <p className='columns font-sm-07'>
                                                        <span className='text-capitalize'>
                                                            {intl.get(name.toUpperCase())}
                                                        </span>
                                                        <br />
                                                        <span>{block}</span>
                                                    </p>
                                                </ToggleButton>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </section>
                </>
            ) : (
                <CalendarMobileAgent
                    onChangeDate={onChangeDate}
                    isDisabled={isDisabled}
                    isSelected={isSelected}
                    blockType={blockType}
                    dayDate={dayDate}
                    fetchingBlocks={fetchingBlocks}
                    blocksTaken={blocksTaken}
                    onSubmit={onSubmit}
                    isScheduledId={isScheduledId}
                />
            )}
            <section>
                <div className='switch-calendar-container'>
                    <label className='switch-take-calendar'>
                        <input
                            ref={inputSwitch}
                            type='checkbox'
                            defaultChecked={automaticSchedule === 'yes'}
                            onClick={(e: any) => showModalConfirm(e)}
                        />
                        <span className='slider' />
                    </label>
                    <span className='text-take-calendar text-subtitle'>{intl.get('MAKE_ALL_BLOCKS_AVAILABLE')}</span>
                </div>
                <div className='row text-left'>
                    <p className='text-take-calendar text-basic'>{intl.get('ENABLING_BLOCKS_DESCRIPTION_1')}</p>
                    <p className='text-take-calendar text-basic'>{intl.get('ENABLING_BLOCKS_DESCRIPTION_2')}</p>
                </div>
                <CalendarColorMeaning />
                <div className='mb-3'>
                    <button
                        className='button-primary col-md-4'
                        type='submit'
                        onClick={() => onSubmit()}
                        disabled={blocksTaken.length < 1}
                    >
                        <p className='m-0'>{intl.get('UPLOAD_BLOCKS')}</p>
                    </button>
                </div>
            </section>
        </div>
    ) : (
        <div className='my-3'>
            <CircularLoading />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.visitCalendar,
    automaticSchedule: state.session.user?.attributes?.automatic_schedule,
});

const mapDispatchToProps = (dispatch: any, getState: any) => {
    return {
        onNextWeek: () => {
            return dispatch(nextWeek());
        },
        onLastWeek: () => {
            return dispatch(lastWeek());
        },
        onChangeDate: (date: any, aasm_state?: string, id?: number) => {
            return dispatch(addDate(date, aasm_state, id));
        },
        submitblocksTaken: () => {
            return dispatch(submitCalendar());
        },
        onGetBlocks: () => {
            return dispatch(getBlocks());
        },
        updateSchedule: (arg: any) => {
            return dispatch(automaticTakeSchedule(arg));
        },
        onGetUser: () => {
            return dispatch(getUser());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
