import React, { Fragment } from 'react';
import { Backdrop } from '../index';
import intl from 'react-intl-universal';
import {
    isRoleRenter,
    isRoleOwner,
    isRolePhotographer,
    isRoleSuperAdmin,
    isRoleTanguero,
    isRoleAdmin,
    isHomePage,
} from '../../helpers/roleComparison/index';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { blackFb, blackIg, blackLinkedin } from '../../resources/index';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import {
    RENTER_MENU_OPTIONS_NOT_RENTING_MOBILE,
    RENTER_MENU_OPTIONS_SIGNED_MOBILE,
    RENTER_MENU_OPTIONS_ACTIVE_MOBILE,
    RENTER_MENU_OPTIONS_NOT_LOGIN_MOBILE,
    OWNER_MENU_OPTIONS_MOBILE,
    HOME_MENU_OPTIONS,
    PHOTOGRAPHER_MENU_OPTIONS_MOBILE,
    TANGUERO_MENU_OPTIONS_MOBILE,
    ADMIN_MENU_OPTIONS_MOBILE,
    COUNTRY_MANAGER_MENU_OPTIONS_MOBILE,
} from './headerOptions';
/**
 * 'SidebarTypes' defines the types of properties of this component for documentation
 * @typedef SidebarTypes
 * @type {(function|any|boolean|string)}
 * @property {boolean} show - is a state that conditions whether the sidebar should be displayed.
 * @property {any} new_session - is a user object with attributes.
 * @property {function} clicked - is a function that updates the show state.
 * @property {function} onLogoutSession - is a function that logs out the user.
 * @property {string} renterState - is an string that get the renter rent state.
 */

type SidebarProps = {
    show: boolean;
    clicked: () => void;
    new_session: { attributes: any };
    onLogoutSession: (history: any) => void;
    navBarMenu?: any;
    redirectToProperties?: any;
    redirectTo?: string;
};

/**
 * Sidebar is a functional component that renders a user menu
 *@function
 *@param {SidebarTypes} show - state that validates the rendering of sidebar
 *@param {SidebarTypes} new_session - object that contains the  details of the user
 *@param {SidebarTypes} clicked - function thst updates the show state of the sidebar
 *@param {SidebarTypes} onLogoutSession - function that logs the user out
 *@returns {(React.FunctionComponent)} Returns a functional component
 */
const Sidebar = ({
    show,
    clicked,
    new_session,
    onLogoutSession,
    navBarMenu,
    redirectToProperties,
    redirectTo,
}: SidebarProps) => {
    const loggedInUser = sessionStorage.getItem('authorization');
    let rentingLocal = localStorage.getItem('renting');
    let renting = new_session?.attributes?.renting;
    const history = useHistory();
    const routes = ['/login', '/sign_up'];
    const { pathname } = useLocation();
    const profileRedirection = isRoleRenter ? '/renter_profile/form' : '/profile/form';
    const unauthorizedRenter = isRoleRenter && !loggedInUser && !routes.includes(pathname);
    const dataUser = new_session?.attributes !== undefined ? new_session?.attributes : {};
    const referralCode = dataUser?.referral_code;
    const isEmployee = isRolePhotographer || isRoleSuperAdmin || isRoleTanguero || isRoleAdmin;
    const appTangoBackOffice = !isRoleRenter && !isRoleOwner && !isHomePage;
    const sizeWidth = useWindowSize().width;

    const usersSwitch = [
        {
            role: isRoleOwner,
            label: 'SWITCH_RENTER',
            url: `${process.env.REACT_APP_RENTER}login?email=${dataUser?.email}`,
        },
        {
            role: isRoleRenter,
            label: 'SWITCH_OWNER',
            url: `${process.env.REACT_APP_OWNER}login?email=${dataUser?.email}`,
        },
    ];
    const filteredUsersSwitch = usersSwitch.filter((user) => user.role);
    const signUpView = isHomePage ? '/intention?sign_up' : '/sign_up';
    const logInView = isHomePage ? '/intention?login' : '/login';
    const location = useLocation();
    const renterState = dataUser?.renter_rent_state;
    const loggedUser = Object.keys(dataUser).length > 0;

    const renterMenuOptions =
        renterState === 'pre-sign' && Object.keys(new_session).length > 0
            ? RENTER_MENU_OPTIONS_NOT_RENTING_MOBILE
            : renterState === 'signed' && Object.keys(new_session).length > 0
            ? RENTER_MENU_OPTIONS_SIGNED_MOBILE
            : renterState === 'active' && Object.keys(new_session).length > 0
            ? RENTER_MENU_OPTIONS_ACTIVE_MOBILE
            : RENTER_MENU_OPTIONS_NOT_LOGIN_MOBILE;
    const homeNav = sessionStorage.getItem('session') == null;

    const ownerMenuOptions = homeNav ? RENTER_MENU_OPTIONS_NOT_LOGIN_MOBILE : OWNER_MENU_OPTIONS_MOBILE;

    const postDetailView = history.location.pathname.split('/')[1];

    const newBarNavigation =
        isHomePage ||
        location.pathname === '/' ||
        location.pathname === '/sign_up' ||
        location.pathname === '/login' ||
        location.pathname === '/new' ||
        (postDetailView === 'posts' && Object.keys(new_session).length === 0);

    return (
        <Fragment>
            <Backdrop show={show} onClick={clicked} />
            <nav
                className={`${show ? 'sidebar open' : 'sidebar'} ${
                    loggedInUser || unauthorizedRenter || isEmployee ? 'left' : 'right'
                }`}
            >
                <div className='sidebar-items-container'>
                    <ul className='nav-items'>
                        {newBarNavigation && !loggedInUser && (
                            <>
                                <li>
                                    <Link
                                        className='text-color-secondary font-weight-bold'
                                        onClick={clicked}
                                        to={logInView}
                                    >
                                        {intl.get('LOG_IN')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className='text-color-secondary font-weight-bold'
                                        onClick={clicked}
                                        to={signUpView}
                                    >
                                        {intl.get('SIGN_UP')}
                                    </Link>
                                </li>
                            </>
                        )}

                        {appTangoBackOffice && loggedUser && (
                            <>
                                <div className='row'>
                                    <li>
                                        <i className='fa fa-user-circle fa-lg' aria-hidden='true'></i>
                                        <span className='ml-2 m-0 name-user-login'>
                                            {new_session?.attributes?.names}
                                        </span>
                                    </li>
                                </div>
                            </>
                        )}

                        {isRoleRenter &&
                            renterMenuOptions.map((value, index) => {
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a
                                            key={index}
                                            href={`${value.label === 'EVALUATION' ? redirectTo : value.to}`}
                                            className={value.style}
                                        >
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}
                        {isRoleOwner &&
                            ownerMenuOptions.map((value, index) => {
                                let myProperties = value.label === 'MY_PROPERTIES' && redirectToProperties;
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a
                                            key={index}
                                            href={`${myProperties || value.to}`}
                                            className={value.style}
                                            onClick={clicked}
                                        >
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}

                        {isRolePhotographer &&
                            PHOTOGRAPHER_MENU_OPTIONS_MOBILE.map((value, index) => {
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a key={index} href={value.to} className={value.style}>
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}
                        {isRoleTanguero &&
                            TANGUERO_MENU_OPTIONS_MOBILE.map((value, index) => {
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a key={index} href={value.to} className={value.style}>
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}

                        {isRoleAdmin &&
                            ADMIN_MENU_OPTIONS_MOBILE.map((value, index) => {
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a key={index} href={value.to} className={value.style}>
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}

                        {isRoleSuperAdmin &&
                            COUNTRY_MANAGER_MENU_OPTIONS_MOBILE.map((value, index) => {
                                return (
                                    <li key={`${value}-${index}`}>
                                        <a key={index} href={value.to} className={value.style}>
                                            {intl.get(value.label)}
                                        </a>
                                    </li>
                                );
                            })}
                    </ul>
                    {
                        <>
                            <hr key='12' className='m-0' />
                            {!loggedInUser && !isHomePage && (
                                <ul>
                                    <li>
                                        <a href={process.env.REACT_APP_BLOG_URL} onClick={clicked}>
                                            {intl.get('BLOG')}
                                        </a>
                                    </li>
                                </ul>
                            )}
                            {loggedInUser && appTangoBackOffice && (
                                <ul>
                                    <li>
                                        <div
                                            className='text-color-primary sidebar-right'
                                            onClick={() => (window.location.href = 'https://centroayuda.tango.rent/')}
                                        >
                                            {intl.get('HELP')}
                                        </div>
                                    </li>
                                    <li>
                                        <Link
                                            className='text-color-primary sidebar-right'
                                            to={'/'}
                                            onClick={() => navBarMenu(null, onLogoutSession(history))}
                                        >
                                            {intl.get('LOGOUT')}
                                        </Link>
                                    </li>
                                </ul>
                            )}
                            {isHomePage && (
                                <ul className='nav-items'>
                                    {HOME_MENU_OPTIONS.map((value, index) => {
                                        return (
                                            <li key={`${value}-${index}`}>
                                                <a
                                                    key={index}
                                                    href={value.to}
                                                    className={`${value.style} ${sizeWidth > 768 ? 'mx-4' : ''}`}
                                                >
                                                    {intl.get(value.label)}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            {!isRoleAdmin && (
                                <div className='sidebar-media'>
                                    <ul className='media'>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                href='https://www.facebook.com/tangorent'
                                                target='_blank'
                                            >
                                                <img src={blackFb} alt='fb-icon' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                href='https://www.linkedin.com/company/tango-rent/'
                                                target='_blank'
                                            >
                                                <img src={blackLinkedin} alt='linkedin-icon' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                rel='noopener noreferrer'
                                                href='https://www.instagram.com/tango.rent/'
                                                target='_blank'
                                            >
                                                <img src={blackIg} alt='ig-icon' />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </>
                    }
                </div>
            </nav>
        </Fragment>
    );
};
export default Sidebar;
