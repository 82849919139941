import * as Yup from 'yup'
import { setLocale } from 'yup'
import intl from 'react-intl-universal'

export const FormValidation = () => {
  setLocale({
    number: {
      min: intl.get('MIN_SCORE_NUMBER'),
      max: intl.get('MAX_SCORE_NUMBER'),
    },
    mixed: {
      default: intl.get('REQUIRED_FIELD'),
    },
  })

  return Yup.object().shape({
    tango_points: Yup.number().nullable().min(0).max(100),
    average_score_last_12_months: Yup.number().nullable().min(0).max(1000),
    current_score: Yup.number().nullable().min(0).max(1000),
    pdf_dicom: Yup.object().required(),
    pdf_truora: Yup.object().required(),
  })
}

export const FormValidationRenter = () => {
  setLocale({
    number: {
      min: intl.get('MIN_SCORE_NUMBER'),
      max: intl.get('MAX_SCORE_NUMBER'),
    },
    mixed: {
      default: intl.get('REQUIRED_FIELD'),
    },
  })

  return Yup.object().shape({
    tango_points: Yup.number().nullable().min(0).max(100),
    average_score_last_12_months: Yup.number().nullable().min(0).max(1000),
    current_score: Yup.number().nullable().min(0).max(1000),
    marital_status: Yup.string().test(
      'MARITAL_STATUS_TEST',
      'INVALID_MARITAL_STATUS',
      (value) => value !== 'none',
    ),
  })
}
