import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { getOffersRentedStart, getOffersRentedSuccess, getOffersRentedError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
const getOffersRented = (state_delivery_voucher: string, filter?: string) => {
    return (dispatch: any) => {
        dispatch(getOffersRentedStart(state_delivery_voucher));
        getData(
            filter
                ? `unit_visits/with_rent?state_voucher=${state_delivery_voucher}${filter}`
                : `unit_visits/with_rent?state_voucher=${state_delivery_voucher}`,
        )
            .then((result) => {
                return dispatch(getOffersRentedSuccess(state_delivery_voucher, result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getOffersRentedError(state_delivery_voucher, e));
            });
    };
};
export default getOffersRented;
