export const RENTER_MENU_OPTIONS_NOT_RENTING = [
    {
        label: 'EVALUATION',
        to: '/renter_profile/form',
        style: 'text-color-secondary font-weight-bold',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary margin-horizontal',
    },
];
export const RENTER_MENU_OPTIONS_NOT_RENTING_MOBILE = [
    {
        label: 'SEARCH_PROPERTY',
        to: '/advanced-search',
        style: 'text-color-primary font-weight-bold',
    },
    {
        label: 'EVALUATION',
        to: '/renter_profile/form',
        style: 'text-color-secondary font-weight-bold',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary',
    },
];

export const RENTER_MENU_OPTIONS_SIGNED = [
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary margin-horizontal',
    },
];

export const RENTER_MENU_OPTIONS_SIGNED_MOBILE = [
    {
        label: 'SEARCH_PROPERTY',
        to: '/advanced-search',
        style: 'text-color-primary font-weight-bold',
    },
    {
        label: 'PAY_RENT',
        to: '/payments',
        style: 'text-color-secondary font-weight-bold',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary',
    },
];
export const RENTER_MENU_OPTIONS_ACTIVE = [
    {
        label: 'MY_RENT',
        to: '/rented_post',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary margin-horizontal',
    },
];
export const RENTER_MENU_OPTIONS_ACTIVE_MOBILE = [
    {
        label: 'PAY_RENT',
        to: '/payments',
        style: 'text-color-secondary font-weight-bold',
    },
    {
        label: 'MY_RENT',
        to: '/rented_post',
        style: 'text-color-primary',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visit_history',
        style: 'text-color-primary',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary',
    },
];
export const RENTER_MENU_OPTIONS_NOT_LOGIN = [
    {
        label: 'I_AM_OWNER',
        to: process.env.REACT_APP_OWNER_INSTAPAGE,
        style: 'text-color-primary margin-horizontal text-nowrap',
    },
    {
        label: 'I_AM_RENTER',
        to: process.env.REACT_APP_RENTER_INSTAPAGE,
        style: 'text-color-primary margin-horizontal text-nowrap',
    },
    {
        label: 'INVITE_AND_EARN',
        to: process.env.REACT_APP_REFERRED_URL,
        style: 'text-color-primary margin-horizontal text-nowrap',
    },
    {
        label: 'WHO_WE_ARE',
        to: '/about-us',
        style: 'text-color-primary margin-horizontal text-nowrap',
    },
    {
        label: 'BLOG',
        to: process.env.REACT_APP_BLOG_URL,
        style: 'text-color-primary margin-horizontal text-nowrap',
    },
];
export const HOME_MENU_OPTIONS = [
    {
        label: 'I_AM_OWNER',
        to: process.env.REACT_APP_OWNER_INSTAPAGE,
        style: 'text-color-primary',
    },
    {
        label: 'I_AM_RENTER',
        to: process.env.REACT_APP_RENTER_INSTAPAGE,
        style: 'text-color-primary',
    },
    {
        label: 'INVITE_AND_EARN',
        to: process.env.REACT_APP_REFERRED_URL,
        style: 'text-color-primary',
    },
    {
        label: 'WHO_WE_ARE',
        to: '/about-us',
        style: 'text-color-primary',
    },
    {
        label: 'BLOG',
        to: process.env.REACT_APP_BLOG_URL,
        style: 'text-color-primary',
    },
];
export const RENTER_MENU_OPTIONS_NOT_LOGIN_MOBILE = [
    {
        label: 'I_AM_OWNER',
        to: process.env.REACT_APP_OWNER_INSTAPAGE,
        style: 'text-color-primary',
    },
    {
        label: 'I_AM_RENTER',
        to: process.env.REACT_APP_RENTER_INSTAPAGE,
    },
    {
        label: 'INVITE_AND_EARN',
        to: process.env.REACT_APP_REFERRED_URL,
        style: 'text-color-primary',
    },
    {
        label: 'WHO_WE_ARE',
        to: '/about-us',
        style: 'text-color-primary',
    },
];
export const OWNER_MENU_OPTIONS = [
    {
        label: 'MY_PROPERTIES',
        to: '/home/pending',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'TITLE_VISITS',
        to: '/scheduleVisits',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'PAYMENTS',
        to: '/payment_history',
        style: 'text-color-primary margin-horizontal',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary margin-horizontal',
    },
];

export const OWNER_MENU_OPTIONS_MOBILE = [
    {
        label: 'REGISTER_YOUR_PROPERTY',
        to: '/tango-plans',
        style: 'text-color-secondary font-weight-bold',
    },
    {
        label: 'MY_PROPERTIES',
        to: '/home/pending',
        style: 'text-color-primary',
    },
    {
        label: 'PROPOSALS',
        to: '/offers',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_VISITS',
        to: '/scheduleVisits',
        style: 'text-color-primary',
    },
    {
        label: 'PAYMENTS',
        to: '/payment_history',
        style: 'text-color-primary',
    },
    {
        label: 'INVITE_AND_EARN',
        to: '/reference',
        style: 'text-color-primary',
    },
];

export const PHOTOGRAPHER_MENU_OPTIONS_MOBILE = [
    {
        label: 'CREATE_NEW_VISIT',
        to: '/create-visit',
        style: 'text-color-primary',
    },
];

export const TANGUERO_MENU_OPTIONS_MOBILE = [
    {
        label: 'CREATE_NEW_VISIT',
        to: '/create-visit',
        style: 'text-color-primary',
    },
    {
        label: 'DELIVERS',
        to: '/delivers',
        style: 'text-color-primary',
    },
    {
        label: 'RESTITUTIONS',
        to: '/restitutions',
        style: 'text-color-primary',
    },
];
export const ADMIN_MENU_OPTIONS_MOBILE = [
    {
        label: 'USERS',
        to: '/users',
        style: 'text-color-primary',
    },
    {
        label: 'RENTERS',
        to: '/renters',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_PROPERTIES',
        to: '/posts',
        style: 'text-color-primary',
    },
    {
        label: 'TITLE_VISITS',
        to: '/visits',
        style: 'text-color-primary',
    },
    {
        label: 'PAYMENTS',
        to: '/payments',
        style: 'text-color-primary',
    },

    {
        label: 'CONTRACTS',
        to: '/contracts',
        style: 'text-color-primary',
    },
    {
        label: 'PENDING_VOUCHERS',
        to: '/pending-vouchers',
        style: 'text-color-primary',
    },
    { label: 'CAMPAIGNS', to: '/campaigns', style: 'text-color-primary' },
];

export const COUNTRY_MANAGER_MENU_OPTIONS_MOBILE = [
    {
        label: 'TRANSACTIONS',
        to: '/transactions',
        style: 'text-color-primary',
    },
    {
        label: 'PROMOTIONS',
        to: '/promotions',
        style: 'text-color-primary',
    },
    {
        label: 'CREATE_NEW_USER',
        to: '/create_user',
        style: 'text-color-primary',
    },
];
