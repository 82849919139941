import { deleteData } from '../../../tango-react-base/helpers/apiConnections'
import { deleteVideoStart, deleteVideoSuccess, deleteVideoError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const deleteVideo = (id: any, postId: number) => {
  return (dispatch: any) => {
    dispatch(deleteVideoStart(id))
    deleteData(`videos/${id}?post_id=${postId}`)
      .then((result) => {
        dispatch(deleteVideoSuccess(result, id))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(deleteVideoError(e))
      })
  }
}
export default deleteVideo
