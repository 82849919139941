import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { CircularLoading } from '..';
import { calendar } from '../../resources/index';
import es from 'date-fns/esm/locale/es';
import moment from 'moment';
import CalendarColorMeaning from '../calendarColorMeaning/index';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiTextField-root': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& label.Mui-focused': {
            color: '#A8123E',
            Width: '100%',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A8123E',
            Width: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
            '&:hover fieldset': {
                Width: '100%',
                borderColor: '#A8123E',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A8123E',
                Width: '100%',
            },
        },
    },
})(KeyboardDatePicker);

type CalendarMobileProps = {
    hourTaken: Array<any>;
    id: any;
    fetchingBlocks: any;
    onChangeDate: any;
    isSelected: any;
    dayDate: any;
    moment: any;
    history?: any;
    submitblocksTaken: any;
    ToggleButton: any;
    backArrow: any;
    blocksDate: any;
    blockType?: any;
};
const today = moment();

export const RequesterCalendarMobile = ({
    hourTaken,
    id,
    fetchingBlocks,
    onChangeDate,
    isSelected,
    dayDate,
    moment,
    history,
    submitblocksTaken,
    ToggleButton,
    backArrow,
    blocksDate,
    blockType,
}: CalendarMobileProps) => {
    const [selectedDate, setSelectedDate] = useState(today);
    const [showBlocks, setShowBlocks] = useState(false);

    useEffect(() => {
        if (!showBlocks && selectedDate) setShowBlocks(true);
    }, [selectedDate]);

    const handleDateChange = (date: any) => {
        setSelectedDate(moment(date));
        isSelected(dayDate(date));
        setShowBlocks(true);
    };

    const DaysBlock = () =>
        showBlocks ? (
            <div className='mb-2 mt-2'>
                {blockType.map(({ name, block, action, show }: any) => {
                    return (
                        <React.Fragment key={name}>
                            <ToggleButton
                                value='check'
                                selected={isSelected(dayDate(selectedDate))}
                                onClick={action}
                                onChange={() => {
                                    onChangeDate(dayDate(selectedDate));
                                }}
                                className={`col-12 toggle-button-hour ${!show && 'bg-transparent'}`}
                            >
                                <p className='columns btn-day-owner'>
                                    <span>{intl.get(name.toUpperCase())}</span>
                                    <br />
                                    <span>{block}</span>
                                </p>
                                <img src={backArrow} alt='arrow' className='arrow-section ml-2' width='10' />
                            </ToggleButton>
                            <section className={!show ? 'd-none' : ''}>
                                {isSelected(dayDate(selectedDate)) ? (
                                    <div className='list-hours'>
                                        {show && blocksDate(selectedDate, name.toLowerCase())}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </section>
                        </React.Fragment>
                    );
                })}
            </div>
        ) : (
            <></>
        );

    useEffect(() => {
        onChangeDate && onChangeDate(dayDate(selectedDate));
    }, [onChangeDate]);

    const now = moment();
    const maxDate = moment(now).add(1, 'week').add(2, 'days');

    let tenDays = moment().add(13, 'day');
    const disableWeekends = (date: any) => {
        return date.getDay() === 0 || tenDays < date;
    };

    return (
        <div className='row p-0'>
            <div className='col-12 mt-3'>
                {fetchingBlocks === 'FETCHED' ? (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                            <Grid container justify='space-around'>
                                <BootstrapInput
                                    margin='normal'
                                    disableToolbar={true}
                                    id='date-picker-dialog'
                                    variant='static'
                                    format='dd/MM/yyyy'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    minDate={now}
                                    maxDate={maxDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    shouldDisableDate={disableWeekends}
                                    keyboardIcon={<img className='ico-image-calendar' src={calendar} alt='Calendar' />}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <small>{intl.getHTML('MAX_TWO_HOURS')}</small>
                        <DaysBlock />
                        <section>
                            <div className='mt-5'>
                                <div className='mt-3'>
                                    <CalendarColorMeaning />
                                </div>
                                <button
                                    className='button-primary btn-block mb-3'
                                    type='submit'
                                    disabled={hourTaken.length < 1}
                                    onClick={() => submitblocksTaken(id, history)}
                                >
                                    <p className='m-0'>{intl.get('UPDATE_AVAILABILITY')}</p>
                                </button>
                            </div>
                        </section>
                    </>
                ) : (
                    <CircularLoading />
                )}
            </div>
        </div>
    );
};
