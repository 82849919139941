export const getCurrentRenewalPeriodStart = () => {
    return {
        type: 'GET_CURRENT_RENEWAL_PERIOD_STARTED',
    };
};

export const getCurrentRenewalPeriodSuccess = (result: any) => {
    return {
        type: 'GET_CURRENT_RENEWAL_PERIOD_SUCCESS',
        payload: { result },
    };
};
export const getCurrentRenewalPeriodError = (error: any) => {
    return {
        type: 'GET_CURRENT_RENEWAL_PERIOD_ERROR',
        payload: {
            error,
        },
    };
};
