import { setBlockStart, setBlockSuccess, setBlockError } from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
var moment = require('moment');
const setBlocks = (data: any, id: string) => {
    return (dispatch: any, getState: any) => {
        const filterBlocks = () => {
            const { start_time } = data.attributes;
            //filters to get the blocks with the same date.
            const blocksFiltered = getState().visits.blocks.filter((block: any) => {
                return (
                    moment(block.attributes.start_time).isSame(moment(start_time), 'year') &&
                    moment(block.attributes.start_time).isSame(moment(start_time), 'month') &&
                    moment(block.attributes.start_time).isSame(moment(start_time), 'day')
                );
            });

            const blocksToRequest = blocksFiltered.map((block: any) => {
                if (
                    moment(block.attributes.end_time).diff(moment(start_time), 'minutes') < 180 &&
                    moment(block.attributes.end_time).diff(moment(start_time), 'minutes') > 0
                )
                    return block.id;
            });

            return blocksToRequest;
        };
        const payload = {
            visit: {
                unit_version_id: id,
                visit_type: 'Verify',
                blocks_ids: filterBlocks(),
            },
        };
        dispatch(setBlockStart([data.id]));
        postData('visits', payload)
            .then((result) => {
                toast(intl.get('BLOCKS_ASSIGNED'));
                return dispatch(setBlockSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(setBlockError(e));
            });
    };
};
export default setBlocks;
