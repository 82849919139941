export const menuActionShow = (event: any) => {
    return {
        type: 'CHANGE_MENU',
        event,
    };
};

export const menuActionPages = (event: any) => {
    return {
        type: 'CHANGE_MENU_NAVEGATE',
        event,
    };
};
