export const newVisitOrTourStart = () => {
    return {
        type: 'CREATE_TOUR_VISITS_STARTED',
    };
};

export const newVisitOrTourSuccess = (result: any) => {
    return {
        type: 'CREATE_TOUR_VISITS_SUCCESS',
        payload: { result },
    };
};
export const newVisitOrTourError = (error: any, blocks: any) => {
    return {
        type: 'CREATE_TOUR_VISITS_ERROR',
        payload: {
            error,
            blocks,
        },
    };
};

export const clearNewVisitOrTour = () => {
    return {
        type: 'CLEAR_VISITS_TOUR',
    };
};
