import React from 'react';

/**
 * 'BackdropTypes' defines the types of properties of this component for documentation
 * @typedef SidebarTypes
 * @type {(function|boolean)}
 * @property {boolean} show - is a state that conditions whether the component should be displayed.
 * @property {function} onClick - is a function that updates the show state.
 */
type BackdropProps = {
    show: boolean;
    onClick: () => void;
};

/**
 * Backdrop is a functional component that renders a background layer for the user menu
 *@function
 *@param {BackdropTypes} show - state that validates the rendering of component
 *@param {BackdropTypes} onClick - function thst updates the show state
 *@returns {(React.FunctionComponent)} Returns a functional component
 */
const Backdrop = ({ onClick, show }: BackdropProps) => {
    const loggedInUser = sessionStorage.getItem('authorization');
    return show ? <div className={`backdrop ${loggedInUser ? 'left' : 'right'}`} onClick={onClick}></div> : null;
};
export default Backdrop;
