export const getPostsError = (error: any) => {
  return {
    type: 'GET_POSTS_ERROR',
    payload: {
      error,
    },
  }
}

export const getPostsitStart = () => {
  return {
    type: 'GET_POSTS_STARTED',
  }
}

export const getPostsitSuc = (posts: any) => {
  return {
    type: 'GET_POSTS_SUCCESS',
    payload: posts,
  }
}
