import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { submitCalendar } from '../../tango-react-base/reduxActions/calendarActions'
import {
  getVisitsPending,
  getVisitsFinished,
  getVisitsCanceled,
  setTemplateChild,
} from '../../tango-react-base/reduxActions'
import { useHistory } from 'react-router-dom'
import { VerifierReduxStates } from '../../tango'
import VisitsAllTable from './VisitsAllTable'
import intl from 'react-intl-universal'
import { clearNewVisitOrTour } from '../../tango-react-base/reduxActions/newTourOrVisit/types'
import { VisitorCalendar } from '../../tango-react-base/components'

/**
 * Here it is defined the type of the Home Component , this prop is similar to 'HomeProps' but 'HomePropsTypes' is for the documentation
 * @typedef HomePropsTypes
 * @type {(function|object|string)}
 * @property {function} submitBlocksTaken - is a function.
 * @property {object} visitsPending - is a object.
 * @property {object} visitsFinished - is a object.
 * @property {object} visitsCanceled - is a object.
 * @property {string} fetchingVisitsPending - is a string.
 * @property {string} fetchingVisitsFinished - is a string.
 * @property {string} fetchingVisitsCanceled - is a string.
 * @property {function} onGetVisitsPending - is a function.
 * @property {function} onGetVisitsFinished - is a function.
 * @property {function} onGetVisitsCanceled - is a function.
 * @property {function} onSetTemplateChild - is a function.
 * @property {string} fetchSessionStatus - is a string.
 * @property {object} user - is a object.
 * @property {function} onClearNewVisitOrTour - is a function.
 */

interface HomeProps {
  submitBlocksTaken: any
  visitsPending: Array<any>
  visitsFinished: Array<any>
  visitsCanceled: Array<any>
  fetchingVisitsPending: string
  fetchingVisitsFinished: string
  fetchingVisitsCanceled: string
  onGetVisitsPending: (id: Object) => void
  onGetVisitsFinished: (id: Object) => void
  onGetVisitsCanceled: (id: Object) => void
  onSetTemplateChild: (child: Object) => void
  fetchSessionStatus: string
  user: any
  onClearNewVisitOrTour?: any
}

/**
 * Home is a functional component
 *@function
 * @param {HomePropsTypes} submitBlocksTaken - this function send blocks taken
 * @param {HomePropsTypes} visitsPending - returns an object with pending visits.
 * @param {HomePropsTypes} visitsFinished - returns an object with finished visits.
 * @param {HomePropsTypes} visitsCanceled - returns an object with  canceled visits.
 * @param {HomePropsTypes} fetchingVisitsPending - fetching status
 * @param {HomePropsTypes} fetchingVisitsFinished - fetching status
 * @param {HomePropsTypes} fetchingVisitsCanceled - fetching status
 * @param {HomePropsTypes} onGetVisitsPending - this function get all  pemding visits
 * @param {HomePropsTypes} onGetVisitsFinished - this function get all finished visits
 * @param {HomePropsTypes} onGetVisitsCanceled - this function get all canceled visits
 * @param {HomePropsTypes} onSetTemplateChild - this function is a simple template
 * @param {HomePropsTypes} fetchSessionStatus - fetching status
 * @param {HomePropsTypes} user - returns an object with the user data.
 * @param {HomePropsTypes} onClearNewVisitOrTour - this function clears the reducer
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

const Home = ({
  submitBlocksTaken,
  visitsPending,
  visitsFinished,
  visitsCanceled,
  fetchingVisitsPending,
  fetchingVisitsFinished,
  fetchingVisitsCanceled,
  onGetVisitsPending,
  onGetVisitsFinished,
  onGetVisitsCanceled,
  fetchSessionStatus,
  user,
  onSetTemplateChild,
  onClearNewVisitOrTour,
}: HomeProps) => {
  const history = useHistory()
  const homeView =
    history.location.pathname === '/' &&
    !sessionStorage.getItem('authorization')

  useEffect(() => {
    if (homeView) {
      return history.push('/login')
    } else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('CALENDAR_VISIT')}</h1>
          <p className='col-sm-12 col-md-6 px-0'>
            {intl.get('CALENDAR_VISIT_INFO')}
          </p>
        </>,
      )
      onGetVisitsPending(user.id)
      onGetVisitsFinished(user.id)
      onGetVisitsCanceled(user.id)
      onClearNewVisitOrTour()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchSessionStatus,
    onGetVisitsPending,
    onGetVisitsFinished,
    onGetVisitsCanceled,
    onSetTemplateChild,
    user.id,
    homeView,
  ])

  return (
    <div className='container-home'>
      <VisitorCalendar onSubmit={submitBlocksTaken} />
       <VisitsAllTable
          visitsPending={{data: visitsPending, fetch: fetchingVisitsPending}}
          visitsFinished={{data: visitsFinished, fetch: fetchingVisitsFinished}}
          visitsCanceled={{data: visitsCanceled, fetch: fetchingVisitsCanceled}}
        />
    </div>
  )
}

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: VerifierReduxStates) => {
  const {
    visitsPending,
    visitsFinished,
    visitsCanceled,
    fetchingVisitsPending,
    fetchingVisitsFinished,
    fetchingVisitsCanceled,
  } = state.visit
  const { fetchSessionStatus, user } = state.session
  return {
    visitsPending,
    visitsFinished,
    visitsCanceled,
    fetchingVisitsPending,
    fetchingVisitsFinished,
    fetchingVisitsCanceled,
    fetchSessionStatus,
    user,
  }
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    submitBlocksTaken: () => {
      return dispatch(submitCalendar())
    },
    onGetVisitsPending: (id: any) => {
      dispatch(getVisitsPending(id))
    },
    onGetVisitsFinished: (id: any) => {
      dispatch(getVisitsFinished(id))
    },
    onGetVisitsCanceled: (id: any) => {
      dispatch(getVisitsCanceled(id))
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onClearNewVisitOrTour: () => {
      return dispatch(clearNewVisitOrTour())
    },
    getUserData: () => {
      return dispatch()
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
