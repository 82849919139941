import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { resendRegistrationEmailStarted, resendRegistrationEmailSuccess, resendRegistrationEmailError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { getApplicationRole } from '../../helpers/getApplication';
import intl from 'react-intl-universal';

const resendRegistrationEmail = (email: string) => {
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE);
    const data = {
        user: {
            email: email,
            app: appRole,
        },
    };
    return (dispatch: any) => {
        dispatch(resendRegistrationEmailStarted());
        putData(`auth/users/resend_registration_mail`, data)
            .then((result) => {
                dispatch(resendRegistrationEmailSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(resendRegistrationEmailError(e));
            });
    };
};
export default resendRegistrationEmail;
