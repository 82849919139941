export const getUnitVersionStart = () => {
    return {
        type: 'GET_UNIT_VERSION_STARTED',
    };
};

export const getUnitVersionSuccess = (post: any) => {
    return {
        type: 'GET_UNIT_VERSION_SUCCESS',
        payload: post,
    };
};

export const getUnitVersionError = (error: any) => {
    return {
        type: 'GET_UNIT_VERSION_ERROR',
        payload: {
            error,
        },
    };
};
export const restartDocument = () => {
    return {
        type: 'RESET_DOCUMENT',
    };
};
