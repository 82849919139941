import React from 'react';
import { Popover } from '@material-ui/core';
import { connect } from 'react-redux';
import { pressNotification } from '../../reduxActions';
import { NotificationsDataProps } from './Interface';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { CircularLoading } from '..';
import { formatDates } from '../../helpers/date';
var moment = require('moment');

interface NofifierProps {
    id: string;
    open: boolean;
    anchorElNotifier: any;
    setAnchorEl: any;
    onPressNotification: any;
    notifications: Array<NotificationsDataProps>;
    fetchNotifications: string;
}

const Nofifier = ({
    id,
    open,
    anchorElNotifier,
    setAnchorEl,
    onPressNotification,
    notifications,
    fetchNotifications,
}: NofifierProps) => {
    const history = useHistory();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onRedirectNotification = (notification: any) => {
        if (notification.attributes.redirect_url) {
            let currentUrl = history.location.pathname.split('/');
            let nextUrl = notification.attributes.redirect_url.split('/');
            (currentUrl[1] !== nextUrl[0] || currentUrl[2] !== nextUrl[1]) &&
                history.push(`/${notification.attributes.redirect_url}`);
            handleClose();
        }
        notification.attributes.aasm_state === 'unread' && onPressNotification(notification.id);
    };
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorElNotifier}
            onClose={handleClose}
            className='notification-popover'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className='list-group list-notification'>
                {notifications.map((value: NotificationsDataProps, key: number) => {
                    let now = moment(new Date());
                    let end = moment(new Date(value.attributes.created_at));
                    let duration = moment.duration(now.diff(end));
                    let minutes = Math.round(duration.asMinutes());
                    let hours = Math.round(duration.asHours());
                    let minutesText = `${minutes > 60 ? hours : minutes} ${
                        minutes > 60 ? intl.get('HOURS') : intl.get('MINUTES')
                    } ${intl.get('AGO')}`;
                    let day = end.format('YYYY-MM-DD HH:mm');
                    let dayText = ` ${intl.get('FROM_NOTIFIER')} ${formatDates({
                        date: day,
                        custom: 'DD/MM/YYYY HH:mm:ss',
                    })
                        .split(':')
                        .slice(0, -1)
                        .join(':')}`;
                    return (
                        <div
                            className={`list-group-item list-group-item-action cursor-pointer ${
                                value.attributes.aasm_state === 'unread' && 'active'
                            } ${fetchNotifications === 'FETCHING_DETAIL' && 'disabled'}`}
                            onClick={() => onRedirectNotification(value)}
                            key={`notification-${key}`}
                        >
                            <p className='mb-0 pr-sm-5'>{value.attributes.description}</p>
                            <small>{hours > 24 ? dayText : minutesText}</small>
                        </div>
                    );
                })}
            </div>
        </Popover>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onPressNotification: (notificationId: string) => {
            return dispatch(pressNotification(notificationId));
        },
    };
};

export default connect(null, mapDispatchToProps)(Nofifier);
