import { multipartPost } from '../../../tango-react-base/helpers/apiConnections'
import { uploadPhotoStart, uploadPhotoSuccess, uploadPhotoError } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const uploadPhoto = (data: FormData, index: string) => {
  return (dispatch: any) => {
    dispatch(uploadPhotoStart(index))
    multipartPost('photos', data)
      .then((result) => {
        dispatch(uploadPhotoSuccess(result.data, index))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(uploadPhotoError(e))
      })
  }
}
export default uploadPhoto
