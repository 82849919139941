import React, { useEffect } from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { CircularLoading, Input } from '../..'
import { connect } from 'react-redux'
import { setTokenNewPassword } from '../../../reduxActions'
import intl from 'react-intl-universal'

interface ForgetPasswordFormProps {
  onForgetLoginSession: any
  idUser?: string
  children?: any
  disabled: boolean
  fetchForgetStatus: string
  history: any
}

interface ForgetPasswordForm {
  email: string
}
const InnerForm: React.FunctionComponent<
  ForgetPasswordFormProps & FormikProps<ForgetPasswordForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  fetchForgetStatus,
}) => {
  return (
    <>
      <div className='card-view card-without-shadow col-md-6 h-50'>
        <div className='row'>
          <h2 className='title font-weight-bold'>{intl.get('RECOVER_PASSWORD')}</h2>
        </div>
        <div className='template-line'></div>
        <div className='row text-left'>
          <span className='title-info pt-3 pb-3'>
            {intl.get('FORGOT_PASSWORD_INSTRUCTIONS')}
          </span>
        </div>
        <Form>
          <Input
            id='email'
            label='EMAIL'
            type='email'
            value={values.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.email}
            disabled={disabled}
            required={true}
            classNameLabel='col-12 p-0 font-weight-bold'
            classNameInput='col-12 p-0 mt-1'
            className='col-12 p-0 text-left'
            justifyCenter='align-items-center'
            placeholder={intl.get('EMAIL_EXAMPLE')}
          />
          {children}
          {fetchForgetStatus !== 'FETCHING' ? (
            <>
              {fetchForgetStatus === 'ERROR_FETCHING' && (
                <p className='text-danger text-left'>
                  {intl.get('EMAIL_ERROR_FORGETPASS')}
                </p>
              )}
              <div></div>

              <button
                className='button-primary col-12 mx-sm-0 font-weight-bold'
                type='submit'
                disabled={disabled}
              >
                {intl.get('RECOVER')}
              </button>
            </>
          ) : (
            <CircularLoading />
          )}
        </Form>
      </div>
    </>
  )
}

const ForgetPasswordForms = withFormik<
  ForgetPasswordFormProps,
  ForgetPasswordForm
>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      email: '',
    }
    return { ...initialValues, ...props }
  },

  validationSchema: Yup.object().shape({}),

  handleSubmit(
    values: ForgetPasswordForm,
    { props, setSubmitting, setErrors },
  ) {
    props.onForgetLoginSession(values)
  },
})(InnerForm)

const mapStateToProps = (state: any) => {
  return {
    fetchForgetStatus: state.session.fetchForgetStatus,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onForgetLoginSession: (values: any) => {
      return dispatch(setTokenNewPassword(values))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForms)
