import React from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { CircularLoading, TimePicker } from '..';
import intl from 'react-intl-universal';

interface AddHoursFormProps {
    onSubmit: any;
    idOffer?: string;
    children?: any;
    disabled?: boolean;
    fetch?: string;
}

interface AddHoursForm {
    duration: string;
}

const InnerForm: React.FunctionComponent<AddHoursFormProps & FormikProps<AddHoursForm>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    children,
    disabled = false,
    fetch = 'NO_FETCH',
}) => {
    let disabledHoursArray = [];
    for (let index = 5; index < 24; index++) {
        disabledHoursArray.push(index);
    }
    return (
        <div className='popover-container-form'>
            <Form>
                <TimePicker
                    id='duration'
                    label='TIME_VISIT'
                    value={values.duration}
                    error={errors.duration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.duration}
                    requiredMessage='The time is required'
                    disabled={disabled}
                    minuteStep={15}
                    disabledHoursArray={disabledHoursArray}
                />
                {(fetch === 'NO_FETCH' || fetch === 'FETCHED_ERROR') && (
                    <div className='col-12 text-center'>
                        <button className='button-primary col-12' type='submit' disabled={disabled}>
                            {intl.get('SAVE')}
                        </button>
                    </div>
                )}
                {fetch === 'FETCHING' && <CircularLoading />}
            </Form>
        </div>
    );
};

const AddHoursForm = withFormik<AddHoursFormProps, AddHoursForm>({
    mapPropsToValues: (props: any) => {
        return {
            duration: '00:00',
        };
    },

    validationSchema: Yup.object().shape({}),

    handleSubmit(values: AddHoursForm, { props, setSubmitting, setErrors }) {
        props.onSubmit(values);
    },
})(InnerForm);

export default AddHoursForm;
