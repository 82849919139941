import * as Yup from 'yup';
import { setLocale } from 'yup';
import intl from 'react-intl-universal';

const FormCosignee = () => {
    setLocale({
        mixed: {
            required: intl.get('REQUIRED_FIELD'),
        },
        string: {
            email: intl.get('INSERT_VALID_MAIL'),
        },
        number: {},
    });

    return Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
    });
};
export default FormCosignee;
