export const resendRegistrationEmailStarted = () => {
    return {
        type: 'RESEND_REGISTRATION_EMAIL_STARTED',
    };
};

export const resendRegistrationEmailSuccess = (data: any) => {
    return {
        type: 'RESEND_REGISTRATION_EMAIL_SUCCESS',
        payload: { data },
    };
};

export const resendRegistrationEmailError = (error: any) => {
    return {
        type: 'RESEND_REGISTRATION_EMAIL_ERROR',
        payload: {
            error,
        },
    };
};
