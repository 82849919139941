import intl from 'react-intl-universal';

/**
 * Defined the type of the props for the interface "formatDatesProps"
 * @typedef formatDatesPropsTypes
 * @type {(object)}
 * @property {string} date - is an Date, void or string.
 * @property {string} formatDate - is an string.
 * @property {string} formatTime - is an string.
 * @property {string} year - is an string.
 * @property {string} month - is an string.
 * @property {string} day - is an string.
 */
interface formatDatesProps {
    date: any;
    formatDate?: 'full' | 'long' | 'medium' | 'short';
    formatTime?: 'full' | 'long' | 'medium' | 'short';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    day?: 'numeric' | '2-digit';
    custom?: string;
}

/** @function formatDates Date formatter
 * This is a function.
 * @param {string} date - Date to format
 * @param {string} formatDate - Specify the format to use for date
 * @param {string} formatTime - Specify the format to use for time
 * @param {string} year - Custom year
 * @param {string} month - Custom month
 * @param {string} day - Custom day
 * @return {string} returns the formatted date
 *
 * @example
 *     formatDates({
 *            date: new Date()
 *            formatDate: 'medium'
 *            formatDate: 'short'
 *           })
 *  return "17-06-2021 12:12"
 *
 *  formatTime: 'short' => "12:20"
 *  formatTime: 'full' => "11:43:00 hora de verano de Chile"
 *  formatTime: 'long' => "11:43:00 GMT-30"
 *  formatTime: 'medium' => "11:43:00"
 */
export const formatDates = ({
    date,
    formatDate,
    formatTime,
    year = 'numeric',
    month = '2-digit',
    day = '2-digit',
    custom,
}: formatDatesProps) => {
    const isInvalidDate = !isNaN(Date.parse(date));
    if (!isInvalidDate) {
        return intl.get('INVALID_FORMAT_DATE');
    }
    const convertDate = new Date(date);
    let options: any;
    if (formatDate || formatTime) {
        options = {
            dateStyle: formatDate ? formatDate : undefined,
            timeStyle: formatTime ? formatTime : undefined,
        };
    } else {
        options = {
            year: year ? year : undefined,
            month: month ? month : undefined,
            day: day ? day : undefined,
        };
    }
    if (custom === 'DD/MM/YYYY HH:mm:ss') {
        options = {
            year: 'numeric',
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
    }

    const intlFormat = new Intl.DateTimeFormat('en-GB', options);
    const newDate = intlFormat.format(convertDate);

    return newDate;
};
