import { isRolePhotographer } from '../../helpers/roleComparison/index';
import { isRoleOwner, isRoleTanguero, isRoleRenter } from '../../helpers/roleComparison/index';
export const getBlocksStart = () => {
    if (isRoleRenter || isRoleOwner) {
        return {
            type: 'GET_BLOCKS_UNIT_STARTED',
        };
    } else {
        return {
            type: 'GET_BLOCKS_STARTED',
        };
    }
};
export const getBlocksReset = () => {
    return {
        type: 'GET_BLOCKS_GROUP_RESET',
    };
};

export const getBlocksSuccess = (result: any, index?: number) => {
    if (isRoleRenter || isRoleOwner) {
        if (index === 0) {
            return {
                type: 'GET_BLOCKS_UNIT_SUCCESS',
                payload: { result },
            };
        } else {
            return {
                type: 'GET_BLOCKS_UNIT_SECOND_WEEK_SUCCESS',
                payload: { result },
            };
        }
    } else {
        return {
            type: 'GET_BLOCKS_SUCCESS',
            payload: { result },
        };
    }
};
export const getBlocksError = (error: any) => {
    if (isRoleRenter || isRoleTanguero) {
        return {
            type: 'GET_BLOCKS_UNIT_ERROR',
            payload: { error },
        };
    } else {
        return {
            type: 'GET_BLOCKS_ERROR',
            payload: {
                error,
            },
        };
    }
};

export const getBlocksUnitStart = () => {
    if (isRoleRenter) {
        return {
            type: 'GET_BLOCKS_UNIT_STARTED',
        };
    } else {
        return {
            type: 'GET_BLOCKS_STARTED',
        };
    }
};

export const getBlocksUnitSuccess = (result: any, id: any) => {
    if (isRoleRenter || isRoleTanguero) {
        return {
            type: 'GET_BLOCKS_UNIT_BROKER_SUCCESS',
            payload: { result, id },
        };
    } else if (isRolePhotographer) {
        return {
            type: 'GET_BLOCKS_UNIT_VERIFIER_SUCCESS',
            payload: { result, id },
        };
    } else {
        return {
            type: 'GET_BLOCKS_SUCCESS',
            payload: { result },
        };
    }
};
export const getBlocksUnitError = (error: any) => {
    if (isRoleRenter || isRoleTanguero) {
        return {
            type: 'GET_BLOCKS_UNIT_BROKER_ERROR',
            payload: { error },
        };
    } else if (isRolePhotographer) {
        return {
            type: 'GET_BLOCKS_UNIT_VERIFIER_ERROR',
            payload: { error },
        };
    } else {
        return {
            type: 'GET_BLOCKS_ERROR',
            payload: {
                error,
            },
        };
    }
};

// TODO: Delete this action when all calendars are refactored
// since blockGroup calls are no longer needed
export const getBlocksGroupStart = () => {
    return {
        type: 'GET_BLOCKS_GROUP_STARTED',
    };
};
export const getBlocksGroupSucc = (result: any, index: number) => {
    if (index === 0) {
        return {
            type: 'GET_BLOCKS_GROUP_SUCC',
            payload: { result, index },
        };
    } else {
        return {
            type: 'GET_BLOCKS_GROUP_SECOND_WEEK_SUCC',
            payload: { result, index },
        };
    }
};
export const getBlocksGroupError = (error: any) => {
    return {
        type: 'GET_BLOCKS_GROUP_ERROR',
        payload: {
            error,
        },
    };
};
