import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import intl from 'react-intl-universal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
    }),
);

interface InlineImageProps {
    images: Array<any>;
}

const InlineImageViewer = ({ images }: InlineImageProps) => {
    const classes = useStyles();

    if (images.length === 0) {
        return <div>{intl.get('NO_PHOTOS')}</div>;
    }

    return (
        <div className={`image-viewer-container ${classes.root}`}>
            <GridList className={classes.gridList} cols={3}>
                {images.map((image: any) => {
                    return (
                        <GridListTile key={image.photo_url}>
                            <img src={image.photo_url} alt={image.title} />
                        </GridListTile>
                    );
                })}
            </GridList>
        </div>
    );
};

export default InlineImageViewer;
