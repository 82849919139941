import React, { useRef } from 'react';
import { uploadLogo } from '../../resources/index';
import { Tooltip } from '@material-ui/core';
import intl from 'react-intl-universal';

interface DropZoneAgentProps {
    onUploadPDF: any;
}

/**
 * Here the types of properties are defined. This prop is similar to 'DropZoneAgentProps' but 'DropZoneAgentTypes' is for the documentation.
 * @typedef DropZoneAgentTypes
 * @type {(function)}
 * @property {function} onUploadPDF - is Function.
 */

/**
 * UDropZoneAgent is a functional component which has one prop. The main function of this component is to handle the inventory uploading.
 *@function
 *@param {DropZoneAgentTypes}  onUploadPDF- prop function that dispatches a redux action.
 * @returns {(ReactComponent)} Returns a react component with a functional component.
 */

const DropZoneAgent = ({ onUploadPDF }: DropZoneAgentProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    /**
     * openFileDialog: This function checks the reference to the input type file so as to open the file uploader.
     * This function does not return anything since it only checks the reference for a click action
     * @function
     */
    const openFileDialog = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    return (
        <Tooltip title={intl.get('UPLOAD_INVENTORY')} arrow>
            <div className={`dropzone-cnt mr-2`}>
                <img src={uploadLogo} alt='uploadLogo' className='upload-logo' onClick={openFileDialog} />
                <input
                    ref={fileInputRef}
                    className='dropzone-file-input'
                    type='file'
                    accept='.pdf'
                    onChange={onUploadPDF}
                />
            </div>
        </Tooltip>
    );
};

export default DropZoneAgent;
