import { finishProccessStart, finishProccessSuc, finishProccessError } from './types';
import { toast } from 'react-toastify';
import { putData } from '../../helpers/apiConnections';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
import { isRolePhotographer } from '../../helpers/roleComparison/index';

const visitFinishedAction = (id: string, postId: any, value: any, isRoleTanguero?: boolean) => {
    return (dispatch: any) => {
        dispatch(finishProccessStart(id));
        let visit: any = { visit: value };
        if (isRolePhotographer) visit.visit['post_id'] = postId;
        putData(`unit_visits/${id}/finish`, visit)
            .then((result) => {
                toast(intl.get('VISIT_FINISHED'));
                dispatch(finishProccessSuc(result.data));
                if (isRoleTanguero) {
                    window.location.reload();
                }
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(finishProccessError(id, e));
            });
    };
};
export default visitFinishedAction;
