import 'date-fns';
import React, { useEffect, useState, Suspense } from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import { MyFormProps } from './PostValidationClasses';
import { useLocation } from 'react-router-dom';
import ValidationFormInterface from '../../model_interfaces/ValidationFormInterface';
import { verifierFormPermissions } from './sections/SectionHelpers';
import { form_new_unit, form_verifier, form_new_registered_unit, no_validation } from './formValidate';
import { connect } from 'react-redux';
import { getCountriesCodes, getCLF } from '../../reduxActions';
import { CircularLoading, PostProperty } from '..';
import { isRoleOwner, isRoleAdmin } from '../../helpers/roleComparison/index';
const BasicData = React.lazy(() => import('./sections/BasicData'));
const UnitDetails = React.lazy(() => import('./sections/UnitDetails'));
const Equipment = React.lazy(() => import('./sections/Equipment'));
const CommonSpaces = React.lazy(() => import('./sections/CommonSpaces'));
const Terms = React.lazy(() => import('./sections/Terms'));

const InnerForm: React.FunctionComponent<MyFormProps & FormikProps<ValidationFormInterface>> = (props) => {
    const {
        children,
        clf,
        countriesCodes,
        disabled,
        fetchingCodes,
        isRegistered,
        newRegisteredUnit,
        onGetCLF,
        onGetCountriesCodes,
        onSaveData,
        post,
        setFullFieldsBasic,
        setFullFieldsCommonSpaces,
        setFullFieldsEquipment,
        setFullFieldsTerms,
        setFullFieldsUnitDetail,
        setIamOwner,
        setValidAddresRented,
        sortedPhotos,
        status,
        toVerify = false,
        onSubmit,
        submitFunction,
        ...sectionProps
    } = props;

    useEffect(() => {
        onGetCountriesCodes();
    }, []);

    useEffect(() => {
        if (status === 'NO_FETCH') {
            onGetCLF();
        }
    }, [status]);

    const location = useLocation();
    const currentLocation = location.pathname.split('/')[1];
    const isUnitApartment = sectionProps.values.unit_type === 'apartment';
    const isNewUnit = currentLocation === 'new-unit';
    // const isNewRentedUnit = currentLocation === 'new-rented-unit'
    const [isVerifier, setIsVerifier] = useState(false);
    const [incompleteForm, setIncompleteForm] = useState(true);
    const disableRegisterType = post?.attributes.aasm_state == 'published';
    useEffect(() => {
        if (verifierFormPermissions()) setIsVerifier(true);
        setIncompleteForm(sectionProps.isValid);
    }, []);

    return (
        <div>
            <Form>
                <Suspense fallback={<CircularLoading />}>
                    <BasicData
                        {...sectionProps}
                        disabled={!!disabled}
                        setIamOwner={setIamOwner}
                        isNewUnit={isNewUnit}
                        toVerify={toVerify}
                        setFullFieldsBasic={setFullFieldsBasic}
                        isVerifier={isVerifier}
                        setValidAddresRented={setValidAddresRented}
                        disableRegisterType={disableRegisterType}
                    />
                    {(newRegisteredUnit || isRegistered) && isUnitApartment && (
                        <Equipment
                            isRegistered={isRegistered}
                            {...sectionProps}
                            disabled={!!disabled}
                            toVerify={toVerify}
                            isVerifier={isVerifier}
                        />
                    )}
                    {!newRegisteredUnit && !isRegistered && (
                        <>
                            <UnitDetails
                                toVerify={toVerify}
                                {...sectionProps}
                                disabled={!!disabled}
                                isVerifier={isVerifier}
                                setFullFieldsUnitDetail={setFullFieldsUnitDetail}
                            />
                            <Equipment
                                toVerify={toVerify}
                                {...sectionProps}
                                disabled={!!disabled}
                                isVerifier={isVerifier}
                                setFullFieldsEquipment={setFullFieldsEquipment}
                            />
                            <CommonSpaces
                                toVerify={toVerify}
                                {...sectionProps}
                                disabled={!!disabled}
                                isVerifier={isVerifier}
                                countriesCodes={countriesCodes}
                                fetchingCodes={fetchingCodes}
                                setFullFieldsCommonSpaces={setFullFieldsCommonSpaces}
                            />
                        </>
                    )}
                    <Terms
                        {...sectionProps}
                        canUpdateRenter={post?.attributes.can_update_renter}
                        clf={clf}
                        disabled={!!disabled}
                        isNewUnit={isNewUnit}
                        isRegistered={isRegistered}
                        isVerifier={isVerifier}
                        setFullFieldsTerms={setFullFieldsTerms}
                        toVerify={toVerify}
                    />
                </Suspense>
                <div className={`${!sectionProps.isValid && 'is-not-valid-submit'}`}>{children}</div>
                {isRoleAdmin && <PostProperty {...sectionProps} disabled={disabled} submitFunction={submitFunction} />}
                {incompleteForm}
            </Form>
        </div>
    );
};

const PostForm = withFormik<MyFormProps, ValidationFormInterface>({
    mapPropsToValues: (props: any) => {
        const isVerifier = verifierFormPermissions();
        const recentRemodel = props.post !== undefined && props.post.attributes.unit.recent_remodel;
        const recentRemodelComment =
            recentRemodel && props.post.attributes.unit.recent_remodel_comment !== null
                ? props.post.attributes.unit.recent_remodel_comment
                : '';

        const getYesterday = () => {
            const today = new Date();
            return new Date(today.setDate(today.getDate() - 1));
        };

        const initialValues = {
            available_now: 'yes',
            available_at: new Date(),
            title: 'Title',
            leased: 'yes',
            sorted_photos: [],
            lease_start: getYesterday(),
            street_address_extra: '',
        };

        if (props.post !== undefined) {
            const {
                attributes: {
                    unit: {
                        estimate_price_amount,
                        unitId,
                        common_expenses_amount,
                        street_address,
                        full_address,
                        ...unitProps
                    },
                    ...attributesProps
                },
            } = props.post;

            unitProps.recent_remodel_comment = recentRemodelComment;

            const initialProps = {
                ...unitProps,
                ...attributesProps,
                full_address: full_address ? full_address : street_address,
                common_expenses_amount: common_expenses_amount,
                estimate_price_amount: estimate_price_amount,
            };
            const inicialWithPropsRefactor: any = {};
            Object.keys(initialProps).forEach((key: string) => {
                if (initialProps[key] !== null) inicialWithPropsRefactor[key] = initialProps[key];
            });
            return { ...inicialWithPropsRefactor };
        }
        if (isRoleOwner) {
            return {
                ...initialValues,
            };
        }
    },
    validationSchema: (props: any) => {
        const isVerifier = verifierFormPermissions();
        const toVerify = no_validation;
        const isNewRegisteredUnit = props.newRegisteredUnit
            ? form_new_registered_unit(props.clf)
            : form_new_unit(props.clf);
        let useValidate = !isVerifier ? isNewRegisteredUnit : toVerify;
        return useValidate;
    },

    handleSubmit: (values: ValidationFormInterface, { props }) => {
        if (
            values.janitor_phone_number &&
            values.janitor_phone_number.toString().indexOf(values.country_code) === -1 &&
            values.country_code
        ) {
            values.janitor_phone_number = `${values.country_code}${values.janitor_phone_number}`;
        }
        if (values.parking_spaces === 0) {
            values.parking_space_number = '';
        }

        props.onSubmit({ ...values });
    },
})(InnerForm);

const mapStateToProps = (state: any) => {
    const { status, clf } = state.CLFResource;
    return {
        countriesCodes: state.countriesCodes.codes.data,
        fetchingCodes: state.countriesCodes.fetchingCodes,
        status,
        clf,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCountriesCodes: () => {
            return dispatch(getCountriesCodes());
        },
        onGetCLF: () => {
            return dispatch(getCLF());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostForm);
