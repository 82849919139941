import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import { navBarMenuAction } from '../../reduxActions';
import intl from 'react-intl-universal';
import { listMenu } from '../../../components/navbar/listMenu';
import { toast } from 'react-toastify';
import { copyText } from '../../helpers/copyText';
import { isRoleRenter } from '../../helpers/roleComparison/index';

/**
 * Here it is defined the type of the Navbar, this prop is similar to 'Navbar' but 'NavBarProps' is for the documentation
 * @typedef NavBarPropsTypes
 * @type {(function|string|Array)}
 * @property {function} navBarMenu - is an function.
 * @property {string} redirectToProperty - is a string.
 */

interface NavBarProps {
    navBarMenu: (event: any, key: any) => void;
    redirectToProperty?: string;
}

/**
 * Navbar is a functional component
 *@function
 *@param {NavBarPropsTypes}  navBarMenu - these are the menu options
 *@param {NavBarPropsTypes}  redirectToProperty - redirect the renter to her/his property rented
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const NavbarMenuOptions = ({ navBarMenu, redirectToProperty }: NavBarProps) => {
    const history = useHistory();
    let session = sessionStorage.getItem('session');
    let new_session = session !== null ? JSON.parse(session) : {};
    let dataUser = new_session.attributes !== undefined ? new_session.attributes : {};
    let referralCode = dataUser.referral_code;
    let myProperties: Array<any> = [];
    let searchProperties: Array<any> = [];

    if (isRoleRenter) {
        searchProperties = [{ route: '/advanced-search', name: 'SEARCH_PROPERTIES' }];
    }

    /* function to copy the code to share*/
    const copyCodeToShare = () => {
        copyText(referralCode, 'NOW_SHARE_CODE');
    };

    const [disabledOnClick, setDisabledOnClick] = useState(false);

    const listMenuOptions = [...myProperties, ...searchProperties, ...listMenu];

    return (
        <>
            {listMenuOptions.map((seccions: any, index: number) => (
                <MenuItem
                    key={index}
                    onClick={() => !disabledOnClick && navBarMenu(null, history.push(seccions.route, seccions.state))}
                    className={
                        seccions.route === '/reference'
                            ? disabledOnClick
                                ? 'button-menu-secondary'
                                : 'button-menu'
                            : ''
                    }
                    disableRipple={disabledOnClick}
                >
                    <span className=''>{intl.get(seccions.name)}</span>
                    {seccions.route === '/reference' && (
                        <button
                            className='button-secondary-menu ml-2'
                            onMouseEnter={() => setDisabledOnClick(true)}
                            onMouseLeave={() => setDisabledOnClick(false)}
                            onClick={() => {
                                copyCodeToShare();
                            }}
                        >
                            {intl.get('COPY')}
                        </button>
                    )}
                </MenuItem>
            ))}
        </>
    );
};
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        navBarMenu: (event: any, redirect?: any) => {
            return dispatch(navBarMenuAction(event, 'Same', redirect));
        },
    };
};

export default connect(null, mapDispatchToProps)(NavbarMenuOptions);
