export const getCitiesStart = () => {
    return {
        type: 'GET_CITIES_STARTED',
    };
};

export const getCitiesSuc = (cities: any) => {
    return {
        type: 'GET_CITIES_SUCCESS',
        payload: cities,
    };
};

export const getCitiesError = (error: any) => {
    return {
        type: 'GET_CITIES_ERROR',
        payload: {
            error,
        },
    };
};
