import {
    getTermsAndConditionStatusStart,
    getTermsAndConditionStatusError,
    getTermsAndConditionStatusSuccess,
} from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { getDataGeneral } from '../../helpers/apiConnections';

const getTermsAndConditionStatus = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getTermsAndConditionStatusStart());
        getDataGeneral(`users/${id}/attributes/config_attr`)
            .then((result) => {
                dispatch(getTermsAndConditionStatusSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getTermsAndConditionStatusError(e));
            });
    };
};
export default getTermsAndConditionStatus;
