import React, { useEffect } from 'react'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

type BlankViewProps = {
  onSetTemplateChild: (child: Object) => void
}

const BlankView = ({ onSetTemplateChild }: BlankViewProps) => {
  const history = useHistory()
  const lastLocation = useLastLocation()?.pathname

  useEffect(() => {
    if (sessionStorage.getItem('authorization')) {
      history.replace(`${lastLocation || '/'}`)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let objectNull = Object.create(null)
    onSetTemplateChild(objectNull)
  }, [onSetTemplateChild])

  return (
    <>
      <div className='container-blank-view' />
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child))
    },
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlankView)
