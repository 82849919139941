import React from 'react';
import { Tooltip } from '@material-ui/core';

/**
 * ButtonTooltip is a functional component which renders a tooltip hovering the mouse over the button.
 *@function
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

interface ButtonTooltipProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    className: string;
    textButton: string;
}

const ButtonTooltip: React.FC<ButtonTooltipProps> = ({ title, className, textButton, ...props }) => {
    return (
        <Tooltip title={title} placement='top' arrow>
            <div className={className}>{textButton}</div>
        </Tooltip>
    );
};

export default ButtonTooltip;
