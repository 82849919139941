import {
  setTokeNewPasswordStart,
  setTokeNewPasswordSuccess,
  setTokeNewPasswordError,
} from './types'
import { postData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { getApplicationRole } from '../../../helpers/getApplication'
import { handleError } from '../../../helpers/handleErrors'
import intl from 'react-intl-universal'
const setTokenNewPassword = (sessionData: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(setTokeNewPasswordStart())
    const appRole = getApplicationRole(process.env.REACT_APP_ROLE)
    let userSession = {
      user: {
        email: sessionData.email,
        app: appRole,
      },
    }
    postData('auth/users/reset_password', userSession)
      .then((result: any) => {
        toast(intl.get('EMAIL_SENT'))
        sessionData.history.push(`/forget_password/${sessionData.email}`)
        dispatch(setTokeNewPasswordSuccess())
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(setTokeNewPasswordError(e))
      })
  }
}
export default setTokenNewPassword
