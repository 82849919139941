import { getUserPhoneStart, getUserPhoneSuccess, getUserPhoneError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { getDataGeneral } from '../../helpers/apiConnections';

const getUserPhone = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getUserPhoneStart());
        getDataGeneral(`users/${id}/attributes/config_attr`)
            .then((result) => {
                dispatch(getUserPhoneSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getUserPhoneError(e));
            });
    };
};
export default getUserPhone;
