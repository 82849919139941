import React from 'react'
import CreateNewTourOrVisit from '../../tango-react-base/components/createTourOrVisit/index'

const CreateNewVisit = () => {
  return (
    <>
      <CreateNewTourOrVisit />
    </>
  )
}

export default CreateNewVisit
