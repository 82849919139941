import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { formatDates } from '../../helpers/date';

interface ScheduledVisitsProps {
    visits: Array<any>;
    status: string;
    title?: string;
}
const moment = require('moment');
const ScheduledVisits = ({ visits, status, title }: ScheduledVisitsProps) => {
    return (
        <>
            <h2 className='text text-color-secondary'>{title}</h2>
            <TableContainer className='table'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-header table-border-left'>{intl.get('ADDRESS')}</TableCell>
                            <TableCell className='table-header'>{intl.get('DATE')}</TableCell>
                            <TableCell className='table-header'>{intl.get('SCHEDULE')}</TableCell>
                            <TableCell className='table-header'>{intl.get('AGENT')}</TableCell>
                            <TableCell className='table-header table-border-right'>
                                {intl.get('PHONE_NUMBER')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visits.map((visit: any) => {
                            const { start_time, agent, street_address, post_id } = visit.attributes;

                            let agentName = agent.names.split(' ');
                            agentName = `${agentName[0]} ${agent.surnames}`;
                            return (
                                <>
                                    <TableRow key={visit.id}>
                                        <TableCell className='table-content link-general-app'>
                                            <Link to={`/unit/${post_id}`}>{street_address}</Link>
                                        </TableCell>
                                        <TableCell className='table-content'>
                                            {formatDates({ date: start_time, formatDate: 'medium' })}
                                        </TableCell>
                                        <TableCell className='table-content text-center'>
                                            {formatDates({ date: start_time, formatTime: 'short' })}
                                        </TableCell>
                                        <TableCell className='table-content'>{agentName}</TableCell>
                                        <TableCell className='table-content'>
                                            <a href={`tel:${agent.phone_number}`}>{agent.phone_number}</a>
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {visits.length === 0 && <p className='info'>{intl.get('YOU_DONT_HAVE_VISITS')}</p>}
        </>
    );
};
export default ScheduledVisits;
