import { putTermsAndConditionStart, putTermsAndConditionError, putTermsAndConditionSuccess } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import { putDataGeneral } from '../../helpers/apiConnections';

const acceptTermsAndCondition = () => {
    return (dispatch: any, getState: any) => {
        dispatch(putTermsAndConditionStart());
        putDataGeneral('user/terms_and_condition/accept')
            .then((result) => {
                dispatch(putTermsAndConditionSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(putTermsAndConditionError(e));
            });
    };
};
export default acceptTermsAndCondition;
