export const createBasicUserStart = () => {
  return {
    type: 'CREATE_BASIC_USER_STARTED',
  }
}

export const createBasicUserSuccess = (data: any) => {
  return {
    type: 'CREATE_BASIC_USER_SUCCESS',
    payload: {
      data,
      error: '',
    },
  }
}

export const createBasicUserError = (error: any) => {
  return {
    type: 'CREATE_BASIC_USER_ERROR',
    payload: {
      error,
    },
  }
}
