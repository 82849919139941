export const getOffersRentedStart = (state_delivery_voucher: string) => {
    if (state_delivery_voucher === 'pending') {
        return {
            type: 'GET_OFFERS_RP_STARTED',
        };
    } else {
        return {
            type: 'GET_OFFERS_R_STARTED',
        };
    }
};

export const getOffersRentedSuccess = (state_delivery_voucher: string, result: any) => {
    if (state_delivery_voucher === 'pending') {
        return {
            type: 'GET_OFFERS_RP_SUCCESS',
            payload: result,
        };
    } else {
        return {
            type: 'GET_OFFERS_R_SUCCESS',
            payload: result,
        };
    }
};

export const getOffersRentedError = (state_delivery_voucher: string, error: any) => {
    if (state_delivery_voucher === 'pending') {
        return {
            type: 'GET_OFFERS_RP_ERROR',
            payload: {
                error,
            },
        };
    } else {
        return {
            type: 'GET_OFFERS_R_ERROR',
            payload: {
                error,
            },
        };
    }
};
