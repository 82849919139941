import React from 'react';
import Input from '../input';
import intl from 'react-intl-universal';

/** Similar to InputMachine, but InterfaceTypes is user for documentation
 * @typedef InterfaceTypes
 * @type {(Array|function)}
 * @property {Array} elements - is an Array.
 * @property {function} onChange - is a function.
 * @property {function} onBlur - is a function.
 * @property {Array} errors - is an Array.
 * @property {Array} touched - is an Array.
 */
interface machineInterface {
    elements: Array<any>;
    onChange: Function;
    onBlur: Function;
    errors: Array<any> | any;
    touched: Array<any> | any;
}

/**
 * Render a form with the input elements
 * @example <caption>Example usage of InputMachine.</caption>
 * Create a configuration object with the elements to be rendered
 *    
 * const elements = [
    {
      id: 'full_name',
      label: 'RENTER_FULLNAME',
      required: newRentedView,
      disabled: !canUpdateRenter,
      value: values.full_name,
      messageAdditionalValidation: !values.full_name
        ? intl.get('REQUIRED_FIELD')
        : '',
    },
    {
      id: 'rut',
      label: 'RENTER_RUT',
      maxLength: 12,
      required: newRentedView,
      disabled: !canUpdateRenter,
      value: values.rut ? formatRut(values.rut) : values.rut,
      classNameInput: 'col-lg-3 plm-0 mb-3',
    },
    {
      required:newRentedView,
      disabled:!canUpdateRenter,
      id:'email',
      label:'RENTER_EMAIL',
      type:'email',
      value:values.email,
      info:'RENTER_EMAIL_INFO',
    }
 * ]
 *  Call the component with the configuration object (elements)
 *  <InputMachine  elements={elements} onChange={handleChange} onBlur={handleBlur} errors={errors} touched={touched}/>
 * @returns {React.Component} Returns a React component with the input elements
 */

/**
 * InputMachine is a functional component that renders input elements
 *@function
 *@param {InterfaceTypes} elements - contain the configuration of the input elements
 *@param {InterfaceTypes} onChange - detect the change of the input elements
 *@param {InterfaceTypes} onBlur - detect is the input elements is focused
 *@param {InterfaceTypes} errors - contain the errors of the input elements
 *@param {InterfaceTypes} touched - detect if the input elements is touched
 *@returns {(React.Component)} Returns a react component with a functional component
 */
const InputMachine = ({ elements, onChange, onBlur, errors, touched }: machineInterface) => {
    const renderInputs = () => {
        return (
            <>
                {elements.map((e: any, i) => {
                    return (
                        <Input
                            label={e.label}
                            placeholder_intl={e.placeholder_intl}
                            bottomLabel={e.bottomLabel}
                            id={e.id}
                            placeholder={e.placeholder}
                            onChange={onChange}
                            value={e.value}
                            name={e.name}
                            type={e.type || 'text'}
                            error={errors[e.id]}
                            touched={touched[e.id]}
                            onBlur={onBlur}
                            min={e.min}
                            max={e.max}
                            className={e.className}
                            requiredMessage={intl.get(e.requiredMessage || 'REQUIRED_FIELD')}
                            multiline={e.multiline}
                            disabled={e.disabled}
                            rows={e.rows}
                            maxLength={e.maxLength}
                            variant={e.variant}
                            labelInput={e.labelInput}
                            unit={e.unit}
                            info={e.info}
                            classNameLabel={e.classNameLabel}
                            classNameInput={e.classNameInput}
                            required={e.required}
                            setFieldValue={e.setFieldValue}
                            disabledAutocomplete={e.disabledAutocomplete}
                            messageAdditionalValidation={e.messageAdditionalValidation}
                            children={e.children}
                            justifyCenter={e.justifyCenter}
                            childrenClassName={e.childrenClassName}
                            isVerifier={e.isVerifier}
                            isRequiredVerifier={e.isRequiredVerifier}
                            requiredInstapage={e.requiredInstapage}
                            classNameMultiline={e.classNameMultiline}
                            colorTertiary={e.colorTertiary}
                            classNameFlagContainer={e.classNameFlagContainer}
                        />
                    );
                })}
            </>
        );
    };
    return renderInputs();
};

export default InputMachine;
