import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DialogModal } from '..';
import intl from 'react-intl-universal';
import { isRoleOwner } from '../../helpers/roleComparison/index';
import { useLocation } from 'react-router-dom';
import { acceptTermsAndCondition, getTermsAndConditionStatus } from '../../reduxActions';

/**
 * Here it is defined the type of the PopUpTermsAndCondition, this prop is similar to 'PopUpTermsAndCondition' but 'PopUpPropsTypes' is for the documentation
 * @typedef PopUpPropsTypes
 * @type {(string|number|Function|boolean)}
 * @property {string} termsStatus - is an boolean.
 * @property {Function} onGetTermsAndConditionStatus - is a Function.
 * @property {Function} onAcceptTermsAndCondition - is a Function.
 * @property {boolean} termsAccepted - is a Function.
 * @property {number} userId - is a number.
 * @property {string} status - is a string.
 */
interface PopUpProps {
    onGetTermsAndConditionStatus: Function;
    onAcceptTermsAndCondition: Function;
    userId: number;
    termsAccepted: boolean;
    status: string;
}

/**
 * PopUpTermsAndCondition is a functional component
 *@function
 *@param {PopUpPropsTypes}  termsStatus - returns the status of terms and condition.
 *@param {PopUpPropsTypes}  onGetTermsAndConditionStatus - handle the action to get the terms and condition status
 *@param {PopUpPropsTypes}  onAcceptTermsAndCondition - handle the action to accept the terms and condition
 *@param {PopUpPropsTypes}  termsAccepted - handle the action to accept the terms and condition
 *@param {PopUpPropsTypes}  userId - returns the user id from the session
 *@param {PopUpPropsTypes}  status - returns the status of terms and condition
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const PopUpTermsAndCondition = ({
    onGetTermsAndConditionStatus,
    onAcceptTermsAndCondition,
    termsAccepted,
    userId,
    status,
}: PopUpProps) => {
    const [shownPopup, setShownPopup] = useState(false);

    const handleSubmit = () => {
        onAcceptTermsAndCondition();
    };
    const pathname = useLocation().pathname;
    const isTermsPage = pathname === '/terms-and-conditions';

    useEffect(() => {
        if (status === 'FETCHED' && !isTermsPage) {
            setShownPopup(!termsAccepted);
        }
    }, [termsAccepted, status, isTermsPage]);

    useEffect(() => {
        setTimeout(() => {
            const userIdSession = JSON.parse(sessionStorage.getItem('session') || '0').id;

            if (userIdSession || userId) {
                onGetTermsAndConditionStatus(userId || userIdSession);
            }
        }, 1000);
    }, [userId]);

    const [checked, setChecked] = useState(false);

    const handleChange = ({ target: { checked } }: any) => {
        setChecked(checked);
    };
    const modal = () => (
        <DialogModal title={intl.get('TANGO_TERMS_AND_CONDITIONS')} open={shownPopup} firstPosition>
            <div className='d-flex'>
                <input
                    type='checkbox'
                    id='terms'
                    name='terms'
                    className='mr-2 mt-1'
                    onChange={handleChange}
                    checked={checked}
                />

                <div className='text-left checkbox-terms'>
                    <label htmlFor='terms'>{intl.getHTML('TERM_AND_POLICY')}</label>
                </div>
            </div>

            <button
                className='button-secondary col-md-3 mt-3 float-right font-sm-1'
                onClick={handleSubmit}
                disabled={!checked}
            >
                {intl.get('ACCEPT')}
            </button>
        </DialogModal>
    );

    return modal();
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
    return {
        userId: isRoleOwner ? state.session.user.id : state.session.user.id || state.session.userProfile.id,
        termsAccepted: state.termsAndCondition.user_accept,
        status: state.termsAndCondition.status,
    };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetTermsAndConditionStatus: (id: number) => {
            return dispatch(getTermsAndConditionStatus(id));
        },
        onAcceptTermsAndCondition: () => {
            return dispatch(acceptTermsAndCondition());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpTermsAndCondition);
