import { menuActionShow, menuActionPages } from './types';

const navBarMenuAction = (event: any, key: string, redirect?: any) => {
    switch (key) {
        // navegate in the same page
        case 'Same':
            return (dispatch: any, getState: any) => {
                dispatch(menuActionShow(event));
                redirect !== undefined && redirect();
            };
        // navegate to another page as go to principal admin, renter, owner, etc, depend of rol
        case 'Pages':
            return (dispatch: any, getState: any) => {
                dispatch(menuActionPages(event));
                redirect !== undefined && redirect();
            };
        default:
            break;
    }
};

export default navBarMenuAction;
