import { ChangeVisitStart, ChangeVisitSuc, ChangeVisitError } from './types'
import { putData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../helpers/handleErrors'
var moment = require('moment')
const formatChangeVisit = (block: any) => {
  let data: Array<any> = []
  const date = moment(block[0]).format(`DD-MM-YYYY HH:mm:ss`)
  data.push({
    visit: {
      start_time: date,
    },
  })
  return data[0]
}
const changeVisit = (idVisit: any, history?: any) => {
  return (dispatch: any, getState: any) => {
    const data = formatChangeVisit(getState().changeVisit.hourTaken)
    dispatch(ChangeVisitStart())
    putData(`unit_visits/${idVisit}`, data)
      .then((result: any) => {
        toast(intl.get('VISIT_CHANGED'))
        history && history.push('/home')
        dispatch(ChangeVisitSuc(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(ChangeVisitError(e))
      })
  }
}
export default changeVisit
