import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { tangoWhiteRedLogo, footerIgIcon, footerLinkedinIcon, footerFbIcon } from '../../resources';
import intl from 'react-intl-universal';
import { AccordionItem, DialogModal } from '..';
import FooterForm from './footerForm';
import { useDispatch } from 'react-redux';
import { sendFormFooter } from '../../reduxActions';
import { useWindowSize } from '../../helpers/hookWindowSize';
import { detectMob } from '../../helpers/detectMobile';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        '&$disabled': {
            opacity: 1,
        },
    },
    disabled: {},
});

const FooterHome = () => {
    const [openMainModal, setOpenMainModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const dispatch = useDispatch();
    const onSubmit = (values: any) => {
        let data: Array<Object> = [];
        data.push({
            name: values.name,
            phone_number: values.phone_number,
            email: values.email,
            workPermit: values.workPermit,
            resume: values.resume,
        });
        dispatch(sendFormFooter(data[0]));
    };

    const ModalWorkWithUs = () => (
        <DialogModal title={titleModal} open={openMainModal} handleClose={handleCloseModal} className='mt-11'>
            <FooterForm setOpenMainModal={setOpenMainModal} onSubmit={onSubmit} />
        </DialogModal>
    );

    const modalInfo = (titleModal: string) => {
        setTitleModal(titleModal);
        setOpenMainModal(true);
    };

    const handleCloseModal = () => {
        setOpenMainModal(false);
    };
    const classDivMt3 = 'col-sm-3  col-xl-3 mt-0 mt-xl-4';
    const classDivMt2 = 'col-sm-3  col-xl-2  mt-0 mt-xl-4';
    const classDivMt0 = 'col-sm-12 col-xl-2 mt-0 pt-xl-5';
    const size = useWindowSize();
    const classes = useStyles();

    return (
        <div className='footer-page-container mt-auto p-sm-0'>
            <ModalWorkWithUs />
            <div className='row footer-home container-footer mx-auto'>
                {size.width > 0 && (
                    <>
                        <div className={classDivMt0}>
                            <img
                                src={tangoWhiteRedLogo}
                                alt={intl.get('LOGO_ALT')}
                                className={`${size.width < 768 ? 'small-img-logo' : 'img-logo'}`}
                            ></img>
                        </div>
                        <div className={classDivMt3}>
                            <div className='ul-style-footer'>
                                <AccordionItem
                                    title={intl.get('WHAT_TO_DO_IN_TANGO')}
                                    classNameText={'title-footer'}
                                    border={false}
                                    expanded={size.width >= 540}
                                    showArrow={size.width <= 540}
                                    isDisabled={!detectMob()}
                                    accordionClasses={{
                                        root: classes.root,
                                        disabled: classes.disabled,
                                    }}
                                >
                                    <ul className='p-sm-0'>
                                        <li className='list-unstyled'>
                                            <a href={`${process.env.REACT_APP_OWNER}new-unit-creation`} target='_blank'>
                                                {intl.get('WANT_POST')}
                                            </a>
                                        </li>
                                        <li className='list-unstyled'>
                                            <a href={process.env.REACT_APP_RENTER} target='_blank'>
                                                {intl.get('WANT_LEASE_PROPERTY')}
                                            </a>
                                        </li>
                                        <li className='list-unstyled'>
                                            <a target='_blank' href={process.env.REACT_APP_REFERRED_URL}>
                                                {intl.get('BE_REFERRER')}
                                            </a>
                                        </li>
                                    </ul>
                                </AccordionItem>
                            </div>
                        </div>
                        <div className={classDivMt2}>
                            <div className='ul-style-footer'>
                                <AccordionItem
                                    title={intl.get('US')}
                                    classNameText={'title-footer'}
                                    border={false}
                                    expanded={size.width >= 540}
                                    showArrow={size.width <= 540}
                                    isDisabled={!detectMob()}
                                    accordionClasses={{
                                        root: classes.root,
                                        disabled: classes.disabled,
                                    }}
                                >
                                    <ul className='p-sm-0'>
                                        <li className='list-unstyled'>
                                            <Link
                                                to='/about-us'
                                                target='_blank'
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                }}
                                            >
                                                {intl.get('ABOUT_US')}
                                            </Link>
                                        </li>
                                        <li className='list-unstyled'>
                                            <a onClick={() => modalInfo(intl.get('BE_TANGUERO'))}>
                                                {intl.get('BE_TANGUERO')}
                                            </a>
                                        </li>
                                        <li className='list-unstyled'>
                                            <a onClick={() => modalInfo(intl.get('BE_VERIFIER'))}>
                                                {intl.get('BE_VERIFIER')}
                                            </a>
                                        </li>
                                        <li className='list-unstyled'>
                                            <a onClick={() => modalInfo(intl.get('WORK_WITHUS'))}>
                                                {intl.get('WORK_WITHUS')}
                                            </a>
                                        </li>
                                    </ul>
                                </AccordionItem>
                            </div>
                        </div>
                        <div className={classDivMt2}>
                            <div className='ul-style-footer'>
                                <AccordionItem
                                    title={intl.get('LEGAL_INFORMATION')}
                                    classNameText={'title-footer'}
                                    border={false}
                                    expanded={size.width >= 540}
                                    showArrow={size.width <= 540}
                                    isDisabled={!detectMob()}
                                    accordionClasses={{
                                        root: classes.root,
                                        disabled: classes.disabled,
                                    }}
                                >
                                    <ul className='p-sm-0'>
                                        <li className='list-unstyled'>
                                            <Link to='/terms-and-conditions' target='_blank'>
                                                {intl.get('POLICY_PRIVACY_TITLE_AND_CONDITIONS_TERMS')}
                                            </Link>
                                        </li>

                                        <li className='list-unstyled'>
                                            <a href='https://centroayuda.tango.rent/' target='_blank'>
                                                {intl.get('HELP_CENTER')}
                                            </a>
                                        </li>
                                    </ul>
                                </AccordionItem>
                            </div>
                        </div>
                        <div className={classDivMt3}>
                            <div className={`${size.width >= 540 ? '' : 'row'}`}>
                                <ul className='p-sm-0  mb-4'>
                                    <li
                                        className={`${
                                            size.width >= 540 ? 'mt-2' : ''
                                        } list-unstyled-mobile title-footer`}
                                    >
                                        <h3 className='contact-footer'>{intl.getHTML('CONTACT_US')}</h3>
                                    </li>
                                </ul>
                                <ul className='p-sm-0 link-contact-email'>
                                    <li className='list-unstyled-mobile'>
                                        <a href='mailto:ayuda@tango.rent'>{intl.get('EMAIL_TANGO')}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='rrss'>
                                <ul className='p-sm-0 title-footer list-unstyled-mobile'>{intl.get('OUR_NETWORKS')}</ul>
                                <a href='https://www.facebook.com/tangorent' target='_blank'>
                                    <img loading='lazy' src={footerFbIcon} height='50' width='50' alt='fb-icon' />
                                </a>
                                <a href='https://www.linkedin.com/company/tango-rent/' target='_blank'>
                                    <img
                                        loading='lazy'
                                        src={footerLinkedinIcon}
                                        height='50'
                                        width='50'
                                        alt='linkedin-icon'
                                    />
                                </a>
                                <a href='https://www.instagram.com/tango.rent' target='_blank'>
                                    <img loading='lazy' src={footerIgIcon} height='50' width='50' alt='ig-icon' />
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default FooterHome;
