import React from 'react';
import intl from 'react-intl-universal';

const CalendarColorMeaning = () => {
    const symbologyCircles = [{ text: 'MEANING_1' }, { text: 'MEANING_2' }, { text: 'MEANING_3' }];
    return (
        <div className='row text-left mt-2'>
            <div className='col-12 pl-0'>
                <p className='text-take-calendar text-subtitle'>{intl.get('MEANING_OF_COLORS')}</p>
                <ul className='list-style-none'>
                    {symbologyCircles.map(({ text }, index) => (
                        <li className='my-2' key={index}>
                            <small className={`shape color-${index}`}>17:30</small>
                            <span className='text-basic'>{intl.get(text)}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CalendarColorMeaning;
