import intl from 'react-intl-universal';

export const handleError = (error: any) => {
    const errors = { ...error };
    const { response, request, message } = errors;
    let totalErrors = '';
    if (response) {
        const { status, data } = response;
        const userNotLoggedIn =
            data && data.errors && typeof data !== 'string' && data.errors[0].title === 'Not signed in';

        if (status === 500) return intl.get('INTERNAL_SERVER_ERROR');
        if (status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            return !userNotLoggedIn && response.data.errors[0].detail;
        }
        if (status >= 200 && status < 500 && response.data.errors) {
            if (data.errors.length <= 3 && data.errors.length > 1) {
                data.errors.map((item: any) => {
                    totalErrors += item.detail + ', ';
                });
            } else if (data.errors.length === 1) {
                totalErrors = data.errors[0].detail;
            } else {
                totalErrors = intl.get('DATA_EMPTY_REQUIRED');
            }

            return !userNotLoggedIn && totalErrors;
        } else {
            return intl.get('NOT_FOUND_ERROR');
        }
    } else if (request) {
        return intl.get('CONNECTION_ERROR');
    } else {
        return message;
    }
};
