import React from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { withStyles, InputAdornment, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { isRequired } from '../../helpers/fieldValidations';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { includeInstapagePath } from '../../helpers/routesForInstapage';

interface InputMapProps {
    children?: any;
    className?: string;
    classNameInput?: string;
    classNameLabel?: string;
    disabled: boolean;
    disabledAutocomplete: boolean;
    error: string;
    id: string;
    info?: string;
    justifyCenter?: string;
    label?: string;
    labelInput?: string;
    messageAdditionalValidation?: string;
    onChange: any;
    required?: boolean;
    requiredMessage?: string;
    type: string;
    value: any;
    variant?: boolean;
    placeholder?: string;
}
const notSearchInstapage = includeInstapagePath() && window.location.pathname !== '/search-instapage';
const borderColor = notSearchInstapage ? '#FFFFFF' : '#A8123E';

const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: borderColor,
        },
        '& .MuiTextField-root': {
            color: borderColor,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: borderColor,
        },
        '& label.Mui-focused': {
            color: 'transparent',
        },
        '& label': {
            textAlign: 'center',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: borderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: borderColor,
            },
            '&:hover fieldset': {
                borderColor: borderColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: borderColor,
            },
        },
        '& .MuiFormLabel-root': {
            zIndex: 0,
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: 'small',
            marginLeft: 4,
            position: 'absolute',
            bottom: -20,
        },
    },
})(TextField);

const InputMap = ({
    children,
    className,
    classNameInput = 'col-lg-3 plm-0 ',
    classNameLabel = 'col-md-4 pl-0 mb-1',
    disabledAutocomplete,
    error,
    info,
    justifyCenter = 'align-items-start',
    label,
    labelInput,
    messageAdditionalValidation = '',
    requiredMessage,
    type = 'text',
    value,
    variant = true,
    placeholder,

    ...inputProps
}: InputMapProps) => {
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const isNewUnitCreationView =
        currentLocation === 'new-unit-creation' || currentLocation === 'new-unit-creation-instapage';

    return (
        <Field name={inputProps.id} validate={!!requiredMessage && isRequired(requiredMessage)}>
            {({ field, form }: any) => {
                let variantText: any = variant ? 'outlined' : undefined;
                return (
                    <>
                        <div className={`row input-container d-flex ${justifyCenter} ${className}`}>
                            {label !== undefined && (
                                <div className={classNameLabel}>
                                    <p
                                        className={
                                            notSearchInstapage
                                                ? 'text-white-bold-iframe'
                                                : isNewUnitCreationView
                                                ? 'mb-2 text-color-gray span-weight'
                                                : 'span-text'
                                        }
                                    >
                                        {label !== '' ? intl.getHTML(`${label}`) : label}
                                    </p>
                                    <p className={notSearchInstapage ? 'p-info-label-white' : 'p-info-label'}>
                                        {info !== '' ? intl.getHTML(`${info}`) : info}
                                    </p>
                                </div>
                            )}
                            <div className={classNameInput}>
                                <BootstrapInput
                                    placeholder={placeholder}
                                    label={value ? '' : labelInput}
                                    id={`bootstrap-input-${inputProps.id}`}
                                    error={!!error}
                                    variant={variantText}
                                    size='small'
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    type={type}
                                    value={value ? value : ''}
                                    autoComplete={disabledAutocomplete ? 'off' : undefined}
                                    InputProps={inputProps}
                                />
                                {children}
                                {messageAdditionalValidation && (
                                    <div className='text col-12 pl-0'>
                                        <p
                                            className={`${
                                                notSearchInstapage ? 'text-color-white' : 'text-color-error'
                                            } small m-0`}
                                        >
                                            {messageAdditionalValidation}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

export default InputMap;
