import { putData } from '../../../tango-react-base/helpers/apiConnections';
import { pressNotificationSuc, pressNotificationError, pressNotificationStart } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const pressNotification = (id: string) => {
    return (dispatch: any) => {
        dispatch(pressNotificationStart());
        putData(`auth/events/${id}/read`)
            .then((result) => {
                dispatch(pressNotificationSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(pressNotificationError(e));
            });
    };
};

export default pressNotification;
