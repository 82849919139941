import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../marker';
import Circle from '../circle';

interface MapProps {
    newCenter?: any;
    newZoom: number;
    width?: string;
    height?: string;
    minZoom?: number;
    maxZoom?: number;
    typeMap: string;
    children?: any;
    onDrag?: any;
    onClick?: any;
    onChange?: any;
    center?: any;
    onDragEnd?: any;
}
const Map = ({
    newCenter,
    newZoom,
    width = '50%',
    height = '30vh',
    minZoom = 5,
    maxZoom,
    typeMap,
    children,
    onDrag,
    onClick,
    onChange,
    center,
    onDragEnd,
}: MapProps) => {
    const styles = {
        hide: [
            {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
        ],
    };

    return (
        <div style={{ height, width }} className='phone-map'>
            <GoogleMapReact
                defaultCenter={center}
                defaultZoom={newZoom}
                options={{
                    minZoom,
                    maxZoom,
                    styles: styles.hide,
                }}
                center={newCenter}
                zoom={newZoom}
                onDrag={() => (onDrag ? onDrag() : null)}
                onClick={() => (onClick ? onClick() : null)}
                onChange={({ bounds }) => (onChange ? onChange({ bounds }) : null)}
                onDragEnd={({ bounds }) => (onDragEnd ? onDragEnd({ bounds }) : null)}
            >
                {typeMap === 'marker' && (
                    <Marker lat={newCenter.lat} lng={newCenter.lng} title={`${newCenter.lat},${newCenter.lng}`} />
                )}
                {typeMap === 'circle' && <Circle lat={newCenter.lat} lng={newCenter.lng} />}
                {children}
            </GoogleMapReact>
        </div>
    );
};
export default Map;
