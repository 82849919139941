import { useState, useEffect } from 'react';

/**
 * useIsMobile hook
 * @returns boolean
 * @describe hook that returns if we are in a mobile view or not
 */
export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /.test(navigator.userAgent),
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(
                / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /.test(
                    navigator.userAgent,
                ),
            );
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};
