import getPostsReducer from './getPostsReducer'
import { combineReducers } from 'redux'
import getUnitReducer from './getUnitReducer'
import postValidationReducer from './postValidationReducer'
import calendarReducer from './calendarReducer'
import sessionReducer from './sessionReducer'
import visitReducer from './visitReducer'
import changeVisitReducer from './changeVisitReducer'
import userReducer from './userReducer'
import navBarReducer from './navBarReducer'
import visitFinishedReducer from './visitFinishedReducer'
import notificationReducer from './notificationReducer'
import templateChildReducer from './templateChildReducer'
import countriesCodesReducer from './countriesCodesReducer'
import generateNewTokenReducer from './generateNewTokenReducer'
import createNewVisit from './createNewVisit'
import photosReducer from './photosReducer'
import communesReducer from './communesReducer'
import citiesReducer from './citiesReducer'
import CLFResourceReducer from './CLFResourceReducer'

export default combineReducers({
  visit: visitReducer,
  posts: getPostsReducer,
  unit: getUnitReducer,
  postValidation: postValidationReducer,
  visitCalendar: calendarReducer,
  session: sessionReducer,
  user: userReducer,
  changeVisit: changeVisitReducer,
  navBar: navBarReducer,
  visitFinished: visitFinishedReducer,
  notification: notificationReducer,
  templateChild: templateChildReducer,
  countriesCodes: countriesCodesReducer,
  generateNewToken: generateNewTokenReducer,
  createNewTourOrVisit: createNewVisit,
  photos: photosReducer,
  communes: communesReducer,
  cities: citiesReducer,
  CLFResource: CLFResourceReducer
})
