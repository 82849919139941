import { acceptMediationStart, acceptMediationSuccess, acceptMediationError } from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const acceptMediation = (id: number, setConfirmationPopup: (state: boolean) => void) => {
    return (dispatch: any) => {
        dispatch(acceptMediationStart());
        postData(`renewal_periods/${id}/accept_mediation_price`)
            .then((result) => {
                dispatch(acceptMediationSuccess());
                setConfirmationPopup(true);
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(acceptMediationError(e));
            });
    };
};
export default acceptMediation;
