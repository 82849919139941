import { deleteData } from '../../../tango-react-base/helpers/apiConnections';
import { deletePhotoStart, deletePhotoSuccess, deletePhotoError } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';

const deletePhoto = (id: any, post_id: number, type: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(deletePhotoStart(id, type));
        deleteData(`photos/${id}?post_id=${post_id}`)
            .then((result) => {
                dispatch(deletePhotoSuccess(result, type));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(deletePhotoError(e, type));
            });
    };
};
export default deletePhoto;
