export const templateChildStart = () => {
    return {
        type: 'TEMPLATE_CHILD_STARTED',
    };
};

export const templateChildSuc = (childen: any, backTo?: string) => {
    return {
        type: 'TEMPLATE_CHILD_SUCCESS',
        payload: { childen, backTo },
    };
};

export const templateChildError = (error: any) => {
    return {
        type: 'TEMPLATE_CHILD_ERROR',
        payload: {
            error,
        },
    };
};
