import React, { useState, useEffect } from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import intl from 'react-intl-universal';
import Input from '../input/index';
import SwitchString from '../switchString/index';
import { BOOLEAN_OPTIONS } from '../../../tango-react-base/helpers/selectOptions/options';
import { deleteLogo, uploadLogo } from '../../resources';
import DialogModal from '../dialogModal/index';
import { toast } from 'react-toastify';
import FormCosignee from './formValidates';
import Select from '../select/index';
import { connect } from 'react-redux';
import { getCountriesCodes } from '../../reduxActions';

interface FooterProps {
    disabled?: boolean;
    setOpenMainModal: any;
    onSubmit?: any;
    countriesCodes: any;
    fetchingCodes: string;
    onGetCountriesCodes: () => void;
}
type DataProps = {
    name: string;
    phone_number: string;
    email: string;
    workPermit: string;
    resume: any;
    country_code: string;
};

const InnerForm: React.FunctionComponent<FooterProps & FormikProps<DataProps>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    disabled = false,
    setOpenMainModal,
    countriesCodes,
    fetchingCodes,
    onGetCountriesCodes,
}) => {
    const [namePDF, setNamePDF] = useState('');
    const [showMoreActive, setShowMoreActive] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(true);

    const handlePDF = (e: any) => {
        if (
            e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
            e.target.files[0].type !== 'application/pdf' &&
            e.target.files[0].type !== 'application/msword'
        ) {
            toast(intl.get('INVALID_FORMAT'));
            return;
        }
        setNamePDF(e.target.files[0].name);
        const file = e.target.files[0];
        setFieldValue('resume', file);
    };

    const handleClearInput = () => {
        setNamePDF('');
        setFieldValue('resume', '');
    };
    const renderFullText = () => {
        setShowMoreActive(true);
        return <small>{intl.get('ACEPT_FORM')}</small>;
    };

    useEffect(() => {
        onGetCountriesCodes();
    }, []);

    const handleCloseModal = () => {
        setModalOpen(false);
        setOpenMainModal(false);
    };
    const ModalThankYou = () => (
        <DialogModal title={intl.get('THANK_YOU_MODAL')} open={modalOpen} handleClose={handleCloseModal}>
            <div className='col-12 p-0 text-center'>
                {intl.getHTML('THANK_YOU_TANGO', { name: values.name })}
                <p className='text-right font-weight-bold'>{intl.get('TEAM_TANGO')}</p>
            </div>
        </DialogModal>
    );

    return (
        <div className='text-left'>
            <h3>{intl.get('JOB_OPPORTUNITY')}</h3>
            <p className='d-none d-md-block'>{intl.get('SEND_YOUR_INFO')}</p>

            <>
                <>
                    <div className='template-line my-0'></div>

                    <Form className='p-1 ps-sm-0'>
                        <ModalThankYou />
                        <>
                            <div>
                                <h4 className='mt-3'>{intl.get('INFO_CONTACT')}</h4>
                                <Input
                                    id='name'
                                    label={'NAME'}
                                    type='text'
                                    required={true}
                                    value={values.name}
                                    error={errors.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.name}
                                    requiredMessage='Name is required'
                                    disabled={disabled}
                                    className='my-4'
                                    classNameInput='col-md-6 p-sm-0'
                                />

                                <div className={`col-md-12 plm-0 text-left p-0`}>
                                    <div className='row col-12 p-0 text-left'>
                                        <div className='col-12 p-0'>
                                            <label className='text-color-secondary'>
                                                {intl.get('PHONE_NUMBER').toUpperCase()}
                                            </label>
                                        </div>
                                        <div className='col-4 p-0'>
                                            {fetchingCodes === 'FETCHED_CODES' ? (
                                                <Select
                                                    options={countriesCodes}
                                                    id='country_code'
                                                    onChange={handleChange}
                                                    value={values.country_code}
                                                    disabled={false}
                                                    classNameInput='col-md-12 col-12 plm-0 pr-0'
                                                    classNameSelect={'col-12 select-no-border'}
                                                    resource={true}
                                                    className='m-0 pr-0'
                                                />
                                            ) : (
                                                <select className='col-12 select-no-border'>
                                                    <option label='---'></option>
                                                </select>
                                            )}

                                            <div className='template-line-input' />
                                        </div>
                                        <div className='col-6 p-0'>
                                            <Input
                                                id='phone_number'
                                                required={true}
                                                type='phone_number'
                                                value={values.phone_number}
                                                error={errors.phone_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched.phone_number}
                                                disabled={disabled}
                                                requiredMessage='Phone number is required'
                                                classNameInput='col-md-12 p-sm-0'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Input
                                    id='email'
                                    label={'EMAIL'}
                                    type='text'
                                    required={true}
                                    value={values.email}
                                    error={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.email}
                                    requiredMessage='Email is required'
                                    disabled={disabled}
                                    className='my-4'
                                    classNameInput='col-md-6 p-sm-0'
                                />
                                <SwitchString
                                    disabled={disabled}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    label={'DOCUMENT_CHILE'}
                                    name='workPermit'
                                    options={BOOLEAN_OPTIONS}
                                    value={values.workPermit}
                                    classNameLabel='pl-0 col-12'
                                    classNameInput='pl-2'
                                />
                                <div className='template-line my-0'></div>
                            </div>
                            <>
                                <h4 className='mt-3'>{intl.get('CV')}</h4>
                                <div>
                                    <label className='filelabel mb-3 p-3 w-100'>
                                        {namePDF.length === 0 ? (
                                            <>
                                                <img src={uploadLogo} alt='uploadLogo' className='upload-logo' />
                                                <br />
                                                <span className='title'>{intl.get('UPLOAD_PDF')}</span>
                                                <input
                                                    className='FileUpload1'
                                                    id='resume'
                                                    name='resume'
                                                    type='file'
                                                    onChange={handlePDF}
                                                    accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <img
                                                        src={deleteLogo}
                                                        alt='deleteLogo'
                                                        className='delete-logo'
                                                        style={{ width: '1.875rem', height: '1.875rem' }}
                                                    />
                                                    <div className='text-center'>
                                                        <span className='title '>{namePDF}</span>
                                                    </div>
                                                </div>
                                                <input
                                                    className='FileUpload1'
                                                    id='resume'
                                                    name='resume'
                                                    type='button'
                                                    onClick={handleClearInput}
                                                />
                                            </>
                                        )}
                                    </label>
                                </div>
                                <div className='template-line my-0'></div>
                            </>
                            <div>
                                {!showMoreActive && (
                                    <>
                                        <small>{intl.get('ACEPT_FORM').slice(0, 190)}...</small>
                                        <small onClick={renderFullText} className='show-more'>
                                            {intl.get('SHOW_MORE')}
                                        </small>
                                    </>
                                )}
                                {showMoreActive && <small>{intl.get('ACEPT_FORM')}</small>}
                            </div>
                            <div className='text-center'>
                                <button
                                    className='button-primary col-md-6 col-12 mb-4 mt-4'
                                    disabled={
                                        values.name && values.resume && values.phone_number && values.email
                                            ? false
                                            : true
                                    }
                                    onClick={() => setModalOpen(true)}
                                >
                                    {intl.get('SEND')}
                                </button>
                            </div>
                        </>
                    </Form>
                </>
            </>
        </div>
    );
};

const FooterForm = withFormik<FooterProps, DataProps>({
    mapPropsToValues: (props: any) => {
        return {
            ...props,
            name: props.name,
            phone_number: props.phone_number,
            email: props.email,
            workPermit: props.workPermit,
            resume: props.resume,
            onSubmit: props.onSubmit,
        };
    },

    validationSchema: FormCosignee,

    handleSubmit(values: DataProps, { props, setSubmitting, setErrors }) {
        if (values.phone_number.toString().indexOf(values.country_code) === -1) {
            values.phone_number = values.country_code + values.phone_number;
        }

        props.onSubmit(values);
    },
})(InnerForm);

const mapStateToProps = (state: any) => {
    return {
        countriesCodes: state.countriesCodes.codes.data,
        fetchingCodes: state.countriesCodes.fetchingCodes,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCountriesCodes: () => {
            return dispatch(getCountriesCodes());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterForm);
