import React, { useEffect, useState, Children } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { changeDay, changeHour, submitCalendar } from '../../reduxActions/calendarActions';
import { nextWeek, lastWeek } from '../../reduxActions/requesterCalendarActions';
import { DialogModal, CircularLoading, NotAvailableVisitInfo } from '..';
import { getBlocks } from '../../reduxActions';
import { backArrow, calendar, forwardArrow } from '../../resources/index';
import { CalendarMobile } from './calendarMobile';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
import { getMonth } from '../../helpers/getMonth';
import { isRoleOwner, isRoleRenter } from '../../helpers/roleComparison/index';
import { useLastLocation } from 'react-router-last-location';
import { cancelPopup, checkPopup } from '../../resources';
import { detectMob } from '../../helpers/detectMobile';
import { includeInstapagePath } from '../../helpers/routesForInstapage';

var moment = require('moment');

type CalendarRefactorProps = {
    onSubmit: any;
    startWeek: any;
    endWeek: any;
    onLastWeek: any;
    onNextWeek: any;
    week: Array<any>;
    blocksTaken: Array<any>;
    hourTaken: Array<any>;
    onChangeDate: any;
    onChangeHour: any;
    onGetBlocks: any;
    blocksAvailable: Array<any>;
    fetchingBlocks: string;
    verifier: string;
    date: string;
    agent: string;
    verifier_data: any;
    agent_data: any;
    unitId: any;
    statusVisit?: string;
    shortRegisterUser?: boolean;
    resultAddNewUnit: any;
    userDetail: any;
    fetchingBlocksSubmitting?: any;
    onShortRegisterSubmitCalendar: (id: any, setOpen: any, idPost: any, userId?: number, history?: any) => void;
    semantic_url?: string;
    full_address?: string;
};

const CalendarRefactor = ({
    onSubmit,
    startWeek,
    endWeek,
    onLastWeek,
    onNextWeek,
    week,
    blocksTaken,
    hourTaken,
    blocksAvailable,
    onChangeDate,
    onChangeHour,
    onGetBlocks,
    fetchingBlocks,
    verifier,
    agent,
    date,
    verifier_data,
    agent_data,
    unitId,
    statusVisit,
    shortRegisterUser,
    onShortRegisterSubmitCalendar,
    resultAddNewUnit,
    userDetail,
    fetchingBlocksSubmitting,
    semantic_url = '',
    full_address = '',
}: CalendarRefactorProps) => {
    let history = useHistory();

    let { id }: any = useParams();

    const [open, setOpen] = useState(false);
    const [showBlocksMorning, setShowBlocksMorning] = useState(false);
    const [showBlocksAfternoon, setShowBlocksAfternoon] = useState(false);
    const [showBlocksNight, setShowBlocksNight] = useState(false);
    const [showPopupShortRegister, setShowPopupShortRegister] = useState(false);
    const isScheduled = statusVisit === 'scheduled';
    const { first_unit_id } = resultAddNewUnit || '';
    const unitIdParam = localStorage.getItem('unitIdParam');
    const ownerIdParam = localStorage.getItem('ownerIdParam');
    const ownerEmailParam = localStorage.getItem('ownerEmailParam');
    const summary = useSelector((state: any) => isRoleOwner && state?.ownerSummary?.summary);
    const { identity_document_present: identityDocumentPresent, profile_completed: profileCompleted } =
        summary?.attributes || {};
    const lastLocation = useLastLocation()?.pathname;

    const dateArray = date.split(' ');

    const buttonClass = 'col-12 toggle-button-hour';

    /* Start the time of day with 8 hours example today 8am */
    const dayDate = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        return initDay.format('DD');
    };

    //Blocks of hours per days
    const blocksDay = (date: any) => {
        const initDay = moment(date).hours(8).minutes(0).seconds(0);
        const endDate = moment(date).hours(21).minutes(0).seconds(0);
        let iterDate = initDay;
        let dateBlocks: Array<any> = [];
        while (iterDate < endDate) {
            dateBlocks.push({
                block_group: {
                    hour: `${iterDate.format('HH:mm')}`,
                    start_time: `${iterDate.format('YYYY-MM-DD HH:mm:ss')}`,
                    end_time: `${iterDate.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}`,
                },
            });
        }
        return dateBlocks;
    };

    let now = moment();
    let tenDays = moment().add(13, 'day');

    useEffect(() => {
        if (fetchingBlocksSubmitting === 'SUBMIT_CALENDAR_SUCCESS' && shortRegisterUser) {
            setShowPopupShortRegister(true);
        }
    }, [fetchingBlocksSubmitting]);

    useEffect(() => {
        onChangeDate(dayDate(now));
        isSelected(dayDate(now));
    }, [fetchingBlocks]);

    let currentDate = moment().format('YYYY-MM-DD');

    /* blocksDate => Its function is to filter the data of blockdays obtaining the blocks of hours and make a
     mapping of all the blocks generating buttons with the hours available for days.
     Only the corresponding blocks will be enabled per hour range.
  */
    const blocksDate = (date: any, blocks: string, blockValidation?: boolean) => {
        const data = blocksDay(date);
        const start_time = moment(date).hours(8).minutes(0).seconds(0);
        const filterData = data.filter((hr: any) => {
            const hour = moment(hr.block_group.start_time);
            let duration = moment.duration(hour.diff(start_time));
            let hours = duration.asHours();
            if (blocks === 'morning') {
                return hours <= 3.5;
            } else if (blocks === 'afternoon') {
                return hours >= 4 && hours <= 8.5;
            } else if (blocks === 'night') {
                return hours >= 9 && hours <= 17;
            }
        });
        if (filterData[0].block_group.end_time >= currentDate && data.length > 0) {
            let count = 0;
            const filterBlockAvailable = filterData.map((block: any) => !isDisabled(block.block_group.start_time));
            let blockComplete: any = filterData.map((block: any, index: number) => {
                return (
                    <>
                        {!isDisabled(block.block_group.start_time) ? (
                            <></>
                        ) : (
                            <ToggleButton
                                key={index}
                                value='check'
                                selected={isSelectedHour(block.block_group.start_time)}
                                onChange={() => {
                                    onChangeHour(block.block_group.start_time);
                                }}
                                disabled={isScheduled}
                                className={`${isScheduled && 'btn-disabled-lighten'} col-12 toggle-button-hour-renter`}
                            >
                                <div className='columns'>{block.block_group.hour}</div>
                            </ToggleButton>
                        )}
                    </>
                );
            });
            blockComplete =
                count === 24 ? <p className='text-left mt-4'>{intl.get('NOT_AVAILABLE_DAY')}</p> : blockComplete;
            if (blockValidation) {
                return filterBlockAvailable;
            } else {
                return blockComplete;
            }
        }
    };

    useEffect(() => {
        if (shortRegisterUser) {
            const shortRegisterUnitId = first_unit_id || unitIdParam;
            const ownerId = userDetail.id || ownerIdParam;
            onGetBlocks(shortRegisterUnitId, history, ownerId);
        } else {
            onGetBlocks(unitId, history);
        }
    }, [history, unitId, onGetBlocks]);

    /* isDisabled=> It filters the available blocks but disabling those that contain the "date"*/
    const isDisabled = (date: any) => {
        const filteredblocksDisabled = blocksAvailable.filter((dateIter: any) => {
            let currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
            let dateAux = moment(dateIter).format('YYYY-MM-DD HH:mm:ss');
            return moment(dateIter).isSame(date) && moment(dateAux).isSameOrAfter(currentDate);
        });
        return filteredblocksDisabled.length > 0;
    };

    const isSelectedHour = (hour: any) => {
        if (hourTaken.length > 0) {
            return hourTaken[0].isSame(moment(hour));
        }
    };

    const isSelected = (date: any) => {
        return blocksTaken === date;
    };

    const viewRedirect = !lastLocation ? '/visit_history' : `${lastLocation}`;
    const handleClose = () => {
        setOpen(false);
        isRoleOwner ? history.push(`/home/pending`) : history.push(viewRedirect);
    };

    const redirectToShortRegister = () => {
        const ownerEmail = userDetail?.attributes?.email || ownerEmailParam;
        setShowPopupShortRegister(false);
        if (history.location.pathname === '/new-unit-creation-instapage') {
            history.push(`/sign-up-instapage?new_register=yes&email=${ownerEmail}`);
        } else {
            history.push(`/sign_up?new_register=yes&email=${ownerEmail}`);
        }
        localStorage.removeItem('ownerEmailParam');
    };
    const DialogModalShortRegister = () => (
        <DialogModal title='' open={showPopupShortRegister} handleClose={redirectToShortRegister}>
            <div className='promotion-text-container'>
                <h1 className='text-left'>{intl.get('VISIT_SCHEDULED_SHORT')}</h1>
                <img src={calendar} alt='calendar-logo' className='p-2' />
                <p className='m-3 text-left'>{intl.get('WE_RECOMMEND_CREATING_ACCOUNT')}</p>
                <button className='button-primary mb-2 col-md-8' onClick={redirectToShortRegister}>
                    {intl.get('CREATE_MY_ACCOUNT')}
                </button>
            </div>
        </DialogModal>
    );

    // DialogModalInfo => It indicates the verifier's email, the time and the day of the visit.
    const DialogModalInfo = () => {
        const modalData = [
            {
                text: 'UPLOAD_ID_DOCUMENT',
                src: identityDocumentPresent ? checkPopup : cancelPopup,
                class: identityDocumentPresent ? 'text-color-tertiary' : 'link-span',
                redirectTo: identityDocumentPresent ? null : '/profile/documents',
                id: '1',
            },
            {
                text: 'COMPLETE_YOUR_PROFILE',
                src: profileCompleted ? checkPopup : cancelPopup,
                class: profileCompleted ? 'text-color-tertiary' : 'link-span',
                redirectTo: profileCompleted ? null : '/profile/form',
                id: '2',
            },
            {
                text: 'CREATE_PROPERTY',
                src: checkPopup,
                class: 'text-color-tertiary',
                redirectTo: null,
                id: '3',
            },
            {
                text: 'SCHEDULE_VISIT_VERIFIER',
                src: checkPopup,
                class: 'text-color-tertiary',
                redirectTo: null,
                id: '4',
            },
        ];

        const renderModalData = (text: string, src: string, classSpan: string, redirectTo: any, id: string) => {
            return (
                <div className='row mb-3 mt-4' key={`modal-data-${id}`}>
                    <div className='col-2'>
                        <img src={src} width='30' alt='check' />
                    </div>
                    <div className='col-10 text-left'>
                        <span className={classSpan} onClick={() => redirectTo && history.push(redirectTo)}>
                            {intl.get(text)}
                        </span>
                    </div>
                </div>
            );
        };
        return (
            <DialogModal
                title={isRoleOwner ? intl.get('VISIT_CONFIRMED') : intl.get('TOUR_CONFIRMED')}
                open={open}
                handleClose={handleClose}
                className={isRoleOwner ? 'with-max-modal-visit' : ''}
            >
                <p>{isRoleOwner ? ownerText : renterText}</p>
                {isRoleOwner && (
                    <>
                        <small>
                            {intl.get('ANY_QUESTION_VERIFIER', {
                                verifier: verifier_data.email,
                            })}
                        </small>
                        <div className='mt-3'>
                            <p className='text-left mb-3 font-weight-bold'>
                                <span>{intl.get('REMIND_YOU')}</span>
                            </p>
                            {modalData.map((data: any) =>
                                renderModalData(data.text, data.src, data.class, data.redirectTo, data.id),
                            )}
                        </div>
                    </>
                )}

                {isRoleRenter && <small>{intl.get('ANY_QUESTION_AGENT', { agent: agent_data.email })}</small>}
            </DialogModal>
        );
    };
    const size = useWindowSize();

    const ownerText =
        verifier !== '' && date !== ''
            ? intl.getHTML('VISIT_VERIFIER', {
                  verifier: verifier,
                  hours: dateArray[0],
                  date: dateArray[1],
              })
            : intl.get('VISIT_VERIFIER_SUCC');

    const renterText =
        agent !== '' && date !== ''
            ? intl.getHTML('VISIT_AGENT', {
                  agent: agent,
                  hours: dateArray[0],
                  date: dateArray[1],
              })
            : intl.get('VISIT_AGENT_SUCC');

    /* 
    handleMorning => shows the morning blocks and disables the rest
    handleAfterNoon => shows afternoon blocks and disables the rest
    handleNight => shows the blocks at night and disables the rest
  */

    const handleMorning = () => {
        setShowBlocksAfternoon(false);
        setShowBlocksMorning(!showBlocksMorning);
        setShowBlocksNight(false);
    };

    const handleAfternoon = () => {
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(!showBlocksAfternoon);
        setShowBlocksNight(false);
    };

    const handleNight = () => {
        setShowBlocksMorning(false);
        setShowBlocksAfternoon(false);
        setShowBlocksNight(!showBlocksNight);
    };

    /* disabledDay => disable the Sunday blocks, those that are greater than
  ten days from today and that are less than the current day */
    let actualDay = moment().subtract(1, 'days');
    const disabledDay = (day: any) => {
        if (day.day() === 0 || day > tenDays || day < actualDay) {
            return true;
        } else {
            return isDisabled(dayDate(day));
        }
    };

    const blockType = [
        {
            name: 'Morning',
            block: '8:00-11:30',
            action: handleMorning,
            show: showBlocksMorning,
        },
        {
            name: 'Afternoon',
            block: '12:00-16:30',
            action: handleAfternoon,
            show: showBlocksAfternoon,
        },
        {
            name: 'Night',
            block: '17:00-19:30',
            action: handleNight,
            show: showBlocksNight,
        },
    ];

    const blocksValidation = blocksAvailable.length !== 0 && fetchingBlocks === 'FETCHED_BLOCKS';

    const handleFetch = [fetchingBlocks];

    const firstDayWeek = week[0];
    const [weekCount, setWeekCount] = useState(1);

    const handleNextWeek = () => {
        if (weekCount === 1 && weekCount <= 2) {
            onNextWeek();
            setWeekCount(weekCount + 1);
        }
    };
    const handleLastWeek = () => {
        if (weekCount > 1) {
            moment(firstDayWeek) >= moment() && onLastWeek();
            setWeekCount(weekCount - 1);
        }
    };

    // size.width >= 768 => renders desktop if it is greater than or equal to 768px
    return size.width >= 768 && !shortRegisterUser ? (
        <>
            <div className='row'>
                {blocksValidation && (
                    <div className='right-calendar-arrow '>
                        <a onClick={() => handleLastWeek()}>
                            <img src={backArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}
                <div className='col-10'>
                    <DialogModalInfo />
                    {blocksAvailable.length > 0 && handleFetch.every((el) => el.includes('FETCHED')) && (
                        <>
                            <h2 className='mb-2 text-left pl-2'>{getMonth(startWeek, endWeek)}</h2>
                            <div className='header-calendar calendar-container'>
                                <section className='row no-wrap week-section align-items-start'>
                                    {week.map((day: any, index: number) => {
                                        return (
                                            <div className='col columns day-column' key={index}>
                                                <div className='d-flex justify-content-center align-items-center div-sec-owner'>
                                                    <span className='word-sp-5 font-sm-1r'>
                                                        {intl.get(day.format('dddd').toUpperCase())} {day.format('DD')}
                                                    </span>
                                                </div>
                                                {blockType.map(({ name, block, action, show }, index: number) => {
                                                    const data = blocksDay(day);
                                                    const start_time = moment(day).hours(8).minutes(0).seconds(0);
                                                    const filterData = data.filter((hr: any) => {
                                                        const hour = moment(hr.block_group.start_time);
                                                        let duration = moment.duration(hour.diff(start_time));
                                                        let hours = duration.asHours();
                                                        if (name.toLowerCase() === 'morning') {
                                                            return hours <= 3.5;
                                                        } else if (name.toLowerCase() === 'afternoon') {
                                                            return hours >= 4 && hours <= 8.5;
                                                        } else if (name.toLowerCase() === 'night') {
                                                            return hours >= 9 && hours <= 17;
                                                        }
                                                    });
                                                    let timeBlocks: any;
                                                    if (
                                                        filterData[0].block_group.end_time >= currentDate &&
                                                        data.length > 0
                                                    ) {
                                                        timeBlocks = filterData.map((block: any, index: number) => {
                                                            if (!isDisabled(block.block_group.start_time)) {
                                                                return 'unavailable';
                                                            } else {
                                                                return 'available';
                                                            }
                                                        });
                                                    }
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ToggleButton
                                                                value='check'
                                                                selected={isSelected(dayDate(day))}
                                                                onClick={action}
                                                                disabled={
                                                                    disabledDay(day) ||
                                                                    !timeBlocks.includes('available')
                                                                }
                                                                onChange={() => {
                                                                    onChangeDate(dayDate(day));
                                                                }}
                                                                className={`${buttonClass} ${
                                                                    disabledDay(day) ||
                                                                    !timeBlocks.includes('available')
                                                                        ? 'btn-disabled'
                                                                        : !show && 'bg-transparent'
                                                                }`}
                                                            >
                                                                <p className='columns btn-day-owner'>
                                                                    <span>{intl.get(name.toUpperCase())}</span>
                                                                    <br />
                                                                    <span>{block}</span>
                                                                </p>
                                                                {!disabledDay(day) &&
                                                                    timeBlocks.includes('available') && (
                                                                        <img
                                                                            src={backArrow}
                                                                            alt='arrow'
                                                                            className='arrow-section ml-2'
                                                                            width='10'
                                                                        />
                                                                    )}
                                                            </ToggleButton>
                                                            <section className={!show ? 'd-none' : ''}>
                                                                {isSelected(dayDate(day)) ? (
                                                                    <div className='list-hours-new'>
                                                                        {show && blocksDate(day, name.toLowerCase())}
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment key={index}></React.Fragment>
                                                                )}
                                                            </section>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </section>
                            </div>
                            <section>
                                <div>
                                    <button
                                        className='button-primary mt-xl-1 w-50'
                                        onClick={() => onSubmit(unitId, setOpen, id)}
                                        disabled={hourTaken.length < 1}
                                    >
                                        {process.env.REACT_APP_API_ROLE_APP === process.env.REACT_APP_RENTEREQUAL
                                            ? intl.get('SCHEDULE_TOUR')
                                            : intl.get('SCHEDULE_VISIT')}
                                    </button>
                                </div>

                                <span className='text-color-secondary small'>
                                    {intl.getHTML('RENTER_HELP_TO_SCHEDULE_VISIT')}
                                </span>
                            </section>
                        </>
                    )}

                    {blocksAvailable.length === 0 && fetchingBlocks === 'FETCHED_BLOCKS' ? (
                        <p>{intl.get('SCHEDULE_NOT_AVAILABLE')}</p>
                    ) : (
                        !isRoleOwner && !isRoleRenter && <NotAvailableVisitInfo className='w-25' />
                    )}

                    {fetchingBlocks !== 'FETCHED_BLOCKS' && <CircularLoading />}
                </div>
                {blocksValidation && (
                    <div className='left-calendar-arrow'>
                        <a onClick={() => handleNextWeek()}>
                            <img src={forwardArrow} alt='arrow' className='arrow-logo' />
                        </a>
                    </div>
                )}
            </div>
        </>
    ) : (
        // CalendarMobile => component rendered in mobile (< 768px width)
        <>
            <DialogModalShortRegister />
            {detectMob() && shortRegisterUser && (
                <h2 className='active-step creation-step p-4'>
                    <span className='step-number'>3.</span>
                    <span className='step-text'>{intl.get('SELECT_VISIT_TIME')}</span>
                </h2>
            )}
            {!detectMob() && shortRegisterUser && (
                <>
                    <div className='template-line mb-1' />
                    <h2 className={`${includeInstapagePath() ? 'text-color-white' : ''} new-subtitle mb-5`}>
                        {intl.get('SCHEDULE_VISIT')}
                    </h2>
                </>
            )}
            <div className='mb-3'>
                <CalendarMobile
                    hourTaken={hourTaken}
                    onSubmit={shortRegisterUser ? onShortRegisterSubmitCalendar : onSubmit}
                    unitId={first_unit_id || unitId}
                    id={resultAddNewUnit ? undefined : id}
                    setOpen={setOpen}
                    fetchingBlocks={fetchingBlocks}
                    blocksAvailable={blocksAvailable}
                    onChangeDate={onChangeDate}
                    ToggleButton={ToggleButton}
                    isSelected={isSelected}
                    dayDate={dayDate}
                    moment={moment}
                    history={history}
                    DialogModalInfo={DialogModalInfo}
                    backArrow={backArrow}
                    blocksDate={blocksDate}
                    blockType={blockType}
                    userId={userDetail.id || ownerIdParam}
                    shortRegisterUser={shortRegisterUser}
                    wasVisitScheduled={showPopupShortRegister}
                    resultAddNewUnit={resultAddNewUnit}
                    currentAC={includeInstapagePath()}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        ...state.calendar,
        ...state.postDetail,
        ...state.addUnit,
        ...state.user,
    };
};

const mapDispatchToProps = (dispatch: any, getState: any) => {
    return {
        onNextWeek: () => {
            return dispatch(nextWeek());
        },
        onLastWeek: () => {
            return dispatch(lastWeek());
        },
        onChangeDate: (date: any) => {
            return dispatch(changeDay(date));
        },
        onChangeHour: (hour: any) => {
            return dispatch(changeHour(hour));
        },
        onGetBlocks: (id?: string, history?: any, shortRegisterUser?: boolean) => {
            const url: string = '?unit_version_id=';
            return dispatch(getBlocks(url, id, history, shortRegisterUser));
        },
        onShortRegisterSubmitCalendar: (id: any, setOpen: any, idPost: any, userId: number, history: any) => {
            return dispatch(submitCalendar(id, setOpen, idPost, userId, history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarRefactor);
