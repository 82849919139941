import React, { useState } from 'react';
import '../../scss/main.scss';
import { withStyles, InputAdornment, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';
import intl from 'react-intl-universal';
interface InputDataShowProps {
    label?: string;
    value: string;
    id: string;
    info?: string;
    justifyCenter?: string;
    className?: string;
    classNameLabel?: string;
    classNameInput?: string;
    disabled: boolean;
    labelInput?: string;
    multiline?: boolean;
    variant?: boolean;
    children?: any;
}
const borderColor = '#A8123E';
const BootstrapInput = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: borderColor,
        },
        '& .MuiTextField-root': {
            color: borderColor,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: borderColor,
        },
        '& label.Mui-focused': {
            color: 'transparent',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: borderColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: borderColor,
            },
            '&:hover fieldset': {
                borderColor: borderColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: borderColor,
            },
        },
        '& .MuiFormLabel-root': {
            zIndex: 0,
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: 'small',
            marginLeft: 4,
            position: 'absolute',
            bottom: -20,
        },
    },
})(TextField);

const InputDataShow = ({
    label,
    value,
    id,
    info,
    className,
    justifyCenter = 'align-items-start',
    classNameLabel = 'col-md-4 col-12 pl-0 mb-1',
    classNameInput = 'col-lg-3 plm-0',
    disabled,
    labelInput,
    multiline = false,
    variant = true,
    children,
    ...inputDataShowProps
}: InputDataShowProps) => {
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();
    const variantText: any = variant ? 'outlined' : undefined;
    return (
        <div className={`row input-container d-flex ${justifyCenter} ${className}`}>
            {label !== undefined && (
                <div className={classNameLabel}>
                    <span className='span-text'>{label !== '' ? intl.getHTML(`${label}`) : label}</span>
                    <p className='p-info-label'>{info !== '' ? intl.getHTML(`${info}`) : info}</p>
                </div>
            )}
            <div className={`${classNameInput}`}>
                <BootstrapInput
                    label={value ? '' : labelInput}
                    id={`bootstrap-input-${id}`}
                    size='small'
                    multiline={multiline}
                    InputLabelProps={{
                        shrink: false,
                    }}
                    disabled={disabled}
                    type='text'
                    value={value ? value : undefined}
                    variant={variantText}
                />
            </div>
            {children}
        </div>
    );
};

export default InputDataShow;
