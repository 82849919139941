const initialState: any = {
  status: null,
  error: null,
  blocks: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CREATE_TOUR_VISITS_STARTED':
      return {
        ...state,
        status: 'STARTED',
      }

    case 'CREATE_TOUR_VISITS_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
      }
    case 'CREATE_TOUR_VISITS_ERROR':
      return {
        ...state,
        status: 'ERROR',
        error: action.payload.error,
        blocks: action.payload.blocks,
      }
    case 'CLEAR_VISITS_TOUR':
      return {
        ...initialState,
      }
    default:
      return state
  }
}
