export const getUnitError = (error: any) => {
  return {
    type: 'GET_UNIT_ERROR',
    payload: {
      error,
    },
  }
}

export const getUnitStart = () => {
  return {
    type: 'GET_UNIT_STARTED',
  }
}

export const getUnitSuccess = (unit: any) => {
  return {
    type: 'GET_UNIT_SUCCESS',
    payload: unit,
  }
}
