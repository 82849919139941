import React from 'react';
import { connect } from 'react-redux';
import { setTemplateChild } from '../../reduxActions';
import intl from 'react-intl-universal';
import TermsAndConditionFile from './file';

type TermAndConditionProps = {
    onSetTemplateChild: (child: Object) => void;
};

const TermAndCondition = ({ onSetTemplateChild }: TermAndConditionProps) => {
    onSetTemplateChild(
        <>
            <h1>{`${intl.get('TERM_CONDITIONS')} ${intl.get('AND')}`}</h1>

            <h1>{intl.get('POLICY_PRIVACY_TITLE')}</h1>
        </>,
    );

    return <TermsAndConditionFile />;
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        onSetTemplateChild: (child: Object) => {
            dispatch(setTemplateChild(child, undefined, true));
        },
    };
};

export default connect(null, mapDispatchToProps)(TermAndCondition);
