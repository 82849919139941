import { useEffect } from 'react';
import { detectMob } from '../detectMobile/index';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

export const useBlankView = (targetRef: any) => {
    const { height }: any = useResizeDetector({ targetRef });
    const cardHeight = (height && detectMob() ? height + 45 : height) * 0.075;
    useEffect(() => {
        const containerBlankView: any = document.querySelector('.container-blank-view');
        if (containerBlankView) {
            containerBlankView.style.height = `${cardHeight}rem`;
        }
    }, [height]);

    return cardHeight;
};
