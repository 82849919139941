import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Field } from 'formik';

interface SelectOptionsProps {
    options: Array<any>;
    onChange: any;
    label?: string;
    id: string;
    value: number | string;
    className?: string;
    disabled?: boolean;
    justifyCenter?: string;
    classNameLabel?: string;
    classNameInput?: string;
    classNameSelect?: string;
    resource?: boolean;
    isCustome?: boolean;
    isDefaultValue?: boolean;
    error?: string;
}

interface FieldProps {
    form: any;
    field: any;
}

const SelectOptions = ({
    options,
    value,
    className,
    classNameLabel = 'col-md-4 col-12 pl-0',
    classNameInput = 'col-md-3 col-12 pl-0',
    label,
    justifyCenter = 'align-items-start',
    classNameSelect,
    resource,
    isCustome,
    error,
    isDefaultValue = false,
    form,
    field,
    ...inputProps
}: SelectOptionsProps & FieldProps) => {
    const [defaultValue, setDefaultValue] = useState(false);

    useEffect(() => {
        if (!value && !defaultValue) {
            form.setFieldValue(inputProps.id, options[0].value);
            setDefaultValue(true);
        }
    }, []);

    return (
        <div className={`row input-container d-flex ${justifyCenter} ${className}`}>
            {label !== undefined && (
                <div className={`${classNameLabel} my-auto`}>
                    <span className='span-text'>{label !== '' ? intl.getHTML(`${label}`) : label}</span>
                </div>
            )}
            <div className={`${classNameInput}`}>
                <select className={`${classNameSelect}`} value={value} {...inputProps}>
                    {options &&
                        options.map((option, index) => (
                            <option
                                className='options-box'
                                value={option.value}
                                key={`${value}-${option.value}-${index}`}
                                disabled={index === 0 && isDefaultValue}
                                hidden={index === 0 && isDefaultValue}
                            >
                                {resource ? option.name : isCustome ? option.value : intl.get(option.label)}
                            </option>
                        ))}
                </select>
                {error && (
                    <div className='text col-12 pl-0'>
                        <p className='text-color-error small m-0'>{intl.get(`${error}`)}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const Select = ({ ...props }: SelectOptionsProps) => {
    return (
        <Field>
            {({ field, form }: any) => {
                return <SelectOptions {...props} form={form} field={field} />;
            }}
        </Field>
    );
};

export default Select;
