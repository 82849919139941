import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { uploadLogo, eyeIcon, deleteLogo, checkLogo } from '../../resources/index';
import intl from 'react-intl-universal';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from 'react-router-dom';

const moment = require('moment');

/**
 * Here it is defined the type of the DocumentValidation Component
 * @typedef DocumentValidationPropsTypes
 * @type {(function|object|string|number}
 * @property {string} index - is a string.
 * @property {function} onAprove - is a function.
 * @property {function} onReject - is a function.
 * @property {object} document - is an object.
 * @property {object} renterDocumentCount - is an object.
 * @property {number} renterProfileIndex - is a number.
 * @property {object} showUploadDocumentsIndex - is an object.
 * @property {function} setShowUploadDocumentsIndex - is a function.
 */

interface DocumentValidationProps {
    index: string;
    onAprove?: any;
    onReject?: any;
    onUpdate?: any;
    document: DocumentProps;
    renterDocumentCount?: any;
    renterProfileIndex?: any;
    showUploadDocumentsIndex?: any;
    disabled?: boolean;
}

/**
 * Here it is defined the type of the DocumentPropsTypes Component
 * @typedef VisitsAllTablePropsTypes
 * @type {(function|object|string|number)}
 * @property {number} id - is a number.
 * @property {string} title - is a string.
 * @property {string} document_type - is a string.
 * @property {string} aasm_state - is a string.
 * @property {string} file_url - is a string.
 * @property {string} created_at - is a string.
 * @property {string} updated_at - is a string.
 * @property {string} approved_by - is a string.
 * @property {string} rejected_by - is a string.
 * @property {string} approved_at - is a Date or string.
 * @property {string} rejected_at - is a Date or string.
 */

interface DocumentProps {
    aasm_state: string;
    approved_at: Date;
    approved_by: any;
    created_at: string;
    document_type: string;
    file_url: string;
    id: number;
    rejected_at: Date;
    rejected_by: any;
    renter_profile_id: number;
    title: string;
}

/**
 * DocumentValidation is a functional component
 * @function
 * @param {DocumentValidationPropsTypes} index - corresponds to the Renter document index.
 * @param {DocumentValidationPropsTypes} onAprove - this function approves a document uploaded by the Renter or Admin.
 * @param {DocumentValidationPropsTypes} onReject - this function rejects a document uploaded by the Renter or Admin.
 * @param {DocumentValidationPropsTypes} document - corresponds to the document data.
 * @param {DocumentValidationPropsTypes} renterDocumentCount - is an object with un total of all types of documents belonging to the one Renter or Co-signer.
 * @param {DocumentValidationPropsTypes} renterProfileIndex - corresponds to the Renter or Co-signer id.
 * @param {DocumentValidationPropsTypes} showUploadDocumentsIndex - corresponds to the index of the document to be displayed uploadLogo.
 * @param {DocumentValidationPropsTypes} setShowUploadDocumentsIndex - this function stores the single document index.
 * @returns {(ReactComponent)} Returns a react component with pending, approved, or rejected documents displayed by the Admin.
 */

const DocumentValidation = ({
    index,
    onAprove,
    onReject,
    onUpdate,
    document: {
        aasm_state,
        approved_at,
        approved_by,
        document_type,
        file_url,
        id,
        rejected_at,
        rejected_by,
        renter_profile_id,
        title,
    },
    disabled,
    renterDocumentCount,
    renterProfileIndex,
    showUploadDocumentsIndex,
}: DocumentValidationProps) => {
    const history = useHistory();
    const currentLocation = history.location.pathname.split('/')[1];
    const isPostView = currentLocation === 'posts' || 'republication';
    const [checkDoc, setCheckDoc] = useState(false);

    const approvedDocument = aasm_state === 'approved';
    const pendingDocument = aasm_state === 'pending';
    const rejectedDocument = aasm_state === 'rejected';

    const renterDocumentState = !isPostView ? renterDocumentCount[document_type] : '';
    const showUploadFile =
        !isPostView && renterDocumentState
            ? !(
                  Object.keys(renterDocumentState).includes('pending') ||
                  Object.keys(renterDocumentState).includes('approved')
              )
            : true;

    const conditionShowUploadDocumentsIndex = !isPostView ? showUploadDocumentsIndex[document_type] === index : '';

    useEffect(() => {
        if (rejectedDocument && !isPostView) showUploadDocumentsIndex[document_type] = index;
    }, []);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const openFileDialog = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const handleRejectFile = () => {
        onReject(id, index, renterProfileIndex, document_type);
        if (!isPostView) showUploadDocumentsIndex[document_type] = index;
    };

    const handleUploadFile = (e: any) => {
        let fileToUpload: File = e.target.files[0];
        let formData: any = new FormData();
        formData.append('renter_document[title]', title);
        formData.append('renter_document[document_type]', document_type);
        formData.append('renter_document[renter_profile_id]', renter_profile_id);
        formData.append('renter_document[file]', fileToUpload);
        formData.append('renter_document[proof_of_income]', true);

        if (document_type === 'ci_both_sides') formData.append('renter_document[data_approval]', '1');
        onUpdate(id, index, formData, renterProfileIndex, document_type);
    };

    const DateApproved = () =>
        approvedDocument || rejectedDocument ? (
            <p className='p-info-label'>
                {moment(approvedDocument ? approved_at : rejected_at).format('DD/MM/YYYY HH:mm:ss')}
            </p>
        ) : null;

    const DocumentStatus = () =>
        approvedDocument || rejectedDocument ? (
            <p className='p-info-label'>
                {approved_by !== null && (
                    <>
                        <span className='color-secondary'>{intl.get(aasm_state.toUpperCase())}</span>
                        {` ${intl.get('BY')} ${`${approved_by.names} ${approved_by.surnames}`}`}
                    </>
                )}

                {rejected_by !== null && (
                    <>
                        <span className='color-secondary'>{intl.get(aasm_state.toUpperCase())}</span>
                        {` ${intl.get('BY')} ${`${rejected_by.names} ${rejected_by.surnames}`}`}
                    </>
                )}
            </p>
        ) : (
            <p className='p-info-label'>
                <span className='color-secondary'>{intl.get(aasm_state.toUpperCase())}</span>
            </p>
        );

    const ValidateSection = () => (
        <div className='row'>
            {pendingDocument && (
                <>
                    {/* ICON EYE */}
                    <div className='col-md-4 col-12 my-auto'>
                        <a href={`${file_url}`} rel='noopener noreferrer' target='_blank'>
                            <div className='tooltip-delete-logo'>
                                <Tooltip title={intl.get('DOWNLOAD_DOCUMENT')}>
                                    <img
                                        src={eyeIcon}
                                        alt='eyeIcon'
                                        className='upload-logo'
                                        onClick={() => {
                                            setCheckDoc(true);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </a>
                    </div>

                    {/* ICON APPROVE */}
                    <div className='col-md-4 col-12 my-auto'>
                        {checkDoc && (
                            <a
                                onClick={() => {
                                    onAprove(id, index, renterProfileIndex, document_type);
                                    setCheckDoc(false);
                                }}
                            >
                                <div className='tooltip-delete-logo'>
                                    <Tooltip title={intl.get('APPROVE')}>
                                        <img src={checkLogo} alt='checkLogo' className='check-logo' />
                                    </Tooltip>
                                </div>
                            </a>
                        )}
                    </div>

                    {/* ICON REJECT */}
                    <div className='col-md-4 col-12 my-auto'>
                        {checkDoc && (
                            <a onClick={handleRejectFile}>
                                <div className='tooltip-delete-logo'>
                                    <Tooltip title={intl.get('REJECT')}>
                                        <img src={deleteLogo} alt='deleteLogo' className='delete-logo' />
                                    </Tooltip>
                                </div>
                            </a>
                        )}
                    </div>
                </>
            )}

            {approvedDocument && (
                <div className='col-md-4 col-12 my-auto'>
                    <img src={checkLogo} alt='checkLogo' className='approved-logo' />
                </div>
            )}

            {(showUploadFile && conditionShowUploadDocumentsIndex) ||
                (rejectedDocument && !disabled && (
                    <div className='col-md-4 col-12 my-auto'>
                        <div className='dropzone-cnt'>
                            {!isPostView ||
                                (rejectedDocument && !disabled && (
                                    <img
                                        src={uploadLogo}
                                        alt='uploadLogo'
                                        className='upload-logo'
                                        onClick={openFileDialog}
                                    />
                                ))}
                            <input
                                ref={fileInputRef}
                                className='dropzone-file-input'
                                type='file'
                                accept='application/pdf'
                                onChange={handleUploadFile}
                            />
                        </div>
                    </div>
                ))}

            {rejectedDocument && !conditionShowUploadDocumentsIndex && !showUploadFile && (
                <div className='col-md-4 col-12 my-auto'>
                    <a href={`${file_url}`} rel='noopener noreferrer' target='_blank'>
                        <div className='tooltip-delete-logo'>
                            <Tooltip title={intl.get('DOWNLOAD_DOCUMENT')}>
                                <img src={eyeIcon} alt='eyeIcon' className='upload-logo' />
                            </Tooltip>
                        </div>
                    </a>
                </div>
            )}
            {rejectedDocument && conditionShowUploadDocumentsIndex && !showUploadFile && (
                <div className='col-md-4 col-12 my-auto'>
                    <a href={`${file_url}`} rel='noopener noreferrer' target='_blank'>
                        <div className='tooltip-delete-logo'>
                            <Tooltip title={intl.get('DOWNLOAD_DOCUMENT')}>
                                <img src={eyeIcon} alt='eyeIcon' className='upload-logo' />
                            </Tooltip>
                        </div>
                    </a>
                </div>
            )}
            {rejectedDocument && !conditionShowUploadDocumentsIndex && showUploadFile && (
                <div className='col-md-4 col-12 my-auto'>
                    <a href={`${file_url}`} rel='noopener noreferrer' target='_blank'>
                        <div className='tooltip-delete-logo'>
                            <Tooltip title={intl.get('DOWNLOAD_DOCUMENT')}>
                                <img src={eyeIcon} alt='eyeIcon' className='upload-logo' />
                            </Tooltip>
                        </div>
                    </a>
                </div>
            )}
        </div>
    );

    return (
        <>
            <TableBody>
                <TableRow>
                    <TableCell className='table-content'>{intl.get(document_type.toUpperCase())}</TableCell>
                    <TableCell className='table-content'>{<ValidateSection />}</TableCell>
                    <TableCell className='table-content'>{<DateApproved />}</TableCell>
                    <TableCell className='table-content'>{<DocumentStatus />}</TableCell>
                </TableRow>
            </TableBody>
        </>
    );
};

export default DocumentValidation;
