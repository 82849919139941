export const getRentedPostError = (error: any) => {
    return {
        type: 'GET_RENTED_POST_ERROR',
        payload: {
            error,
        },
    };
};

export const getRentedPostStart = () => {
    return {
        type: 'GET_RENTED_POST_STARTED',
    };
};

export const getRentedPostSuc = (post: any) => {
    return {
        type: 'GET_RENTED_POST_SUCCESS',
        payload: post,
    };
};
