import {
  SubmitCalendarStart,
  SubmitCalendarSuc,
  SubmitCalendarError,
} from './types'
import { postData, putData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../helpers/handleErrors'
import { isRolePhotographer } from '../../../helpers/roleComparison/index'
import {
  isRoleOwner,
  isRoleTanguero,
  isRoleRenter,
} from '../../../helpers/roleComparison/index'
var moment = require('moment')
const formatblocksTaken = (blocksTaken: Array<any>) => {
  let dateBlocks: Array<any> = []
  blocksTaken.map((innerDate: any) => {
    const startDate = moment(innerDate)
    const endDate = moment(innerDate).add(4, 'hours')
    let iterDate = startDate
    while (iterDate < endDate) {
      dateBlocks.push({
        block: {
          start_time: `${iterDate.format('YYYY-MM-DD HH:mm:ss')}`,
          end_time: `${iterDate
            .add(30, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')}`,
        },
      })
    }
    return null
  })
  return dateBlocks
}

const formatblocksTakenVerifyAndAgent = (
  blocksTaken: Array<any>,
  user_type: string,
) => {
  let dateBlocks: Array<any> = []
  blocksTaken.map((blockTaken: any) => {
    const startDate = moment(blockTaken.date)
    const state = blockTaken.aasm_state
    const blockId = blockTaken.id
    let iterDate = startDate
    let hours = 4
    const afternoon_date = moment(iterDate).hour(12).minutes(0).seconds(0)
    const night_date = moment(iterDate).hour(17).minutes(0).seconds(0)
    if (iterDate.isSame(afternoon_date)) {
      hours = 5
    }
    dateBlocks.push({
      time_block: {
        id: blockId ? parseInt(blockId) : '',
        start_time: `${iterDate.format('YYYY-MM-DD HH:mm:ss')}`,
        end_time: `${iterDate
          .add(hours, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')}`,
        user_type: user_type,
        aasm_state: blockId ? 'available' : '',
      },
    })
    return null
  })
  return dateBlocks
}
const formatblocksTakenOwner = (idTaken: any, idUnit: any, userId?: number) => {
  let dateBlocks: Array<any> = []
  let date: any = moment(idTaken[0]).format('DD-MM-YYYY HH:mm:ss')
  let visit: any = {
    visit: {
      unit_version_id: parseInt(idUnit),
      start_time: date,
    },
  }
  if (userId) visit['visit']['user_id'] = +userId

  dateBlocks.push(visit)
  return dateBlocks[0]
}

const formatblocksTakenRenter = (idTaken: any, idUnit: any, idPost: any) => {
  let dateBlocks: Array<any> = []
  let date: any = moment(idTaken[0]).format('DD-MM-YYYY HH:mm:ss')
  dateBlocks.push({
    visit: {
      unit_version_id: parseInt(idUnit),
      start_time: date,
    },
    post: {
      id: parseInt(idPost),
    },
  })
  return dateBlocks[0]
}

const submitCalendar = (id?: any, setOpen?: any, idPost?: any, userId?: number, history?: any) => {
  return (dispatch: any, getState: any) => {
    const actionData = (action: Function, block: any, url: string) => {
      action(url, block)
        .then((result: any) => {
          dispatch(SubmitCalendarSuc(result.data.data))
          localStorage.removeItem('unitIdParam') 
          localStorage.removeItem('ownerIdParam') 
        })
        .catch((e: any) => {
          toast(handleError(e))
          return dispatch(SubmitCalendarError(e))
        })
    }
    let formatedblocksTaken = []
    if (isRoleTanguero) {
      formatedblocksTaken = formatblocksTakenVerifyAndAgent(
        getState().visitCalendar.blocksTaken,
        'Broker',
      )
    } else if (isRolePhotographer) {
      formatedblocksTaken = formatblocksTakenVerifyAndAgent(
        getState().visitCalendar.blocksTaken,
        'Verifier',
      )
    } else if (isRoleRenter) {
      formatedblocksTaken = formatblocksTakenRenter(
        getState().calendar.hourTaken,
        id,
        idPost,
      )
    } else if (isRoleOwner) {
      formatedblocksTaken = formatblocksTakenOwner(
        getState().calendar.hourTaken,
        id,
        userId,
      )
    } else {
      formatedblocksTaken = formatblocksTaken(
        getState().visitCalendar.blocksTaken,
      )
    }
    if (
      process.env.REACT_APP_API_ROLE_APP ===
      process.env.REACT_APP_RENTEREQUAL ||
      isRoleOwner
    ) {
      dispatch(SubmitCalendarStart(formatedblocksTaken))
      const visitUrl = 'unit_visits'
      const urlToUse = `${userId ? 'user/' : ''}${visitUrl}`

      postData(urlToUse, formatedblocksTaken)
        .then((result: any) => {
          toast(intl.get('VISIT_SCHEDULED'))
          dispatch(SubmitCalendarSuc(result.data.data)) 
          setOpen && !userId && setOpen(true)
        })
        .catch((e: any) => {
          toast(handleError(e))
          return dispatch(SubmitCalendarError(e))
        })
      return null
    } else {
      formatedblocksTaken.map((block: any) => {
        const values: any = Object.values(block)[0]
        dispatch(SubmitCalendarStart(block))

        if (values.aasm_state.length > 0) {
          actionData(putData, block, 'time_blocks/make_unavailable')
        } else {
          actionData(postData, block, 'time_blocks')
          
        }
        return null
      })
    }
  }
}
export default submitCalendar
