import {
  SubmitCalendarStart,
  SubmitCalendarSuc,
  SubmitCalendarError,
} from './types'
import { postData, putData } from '../../../helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../helpers/handleErrors'
var moment = require('moment')
const formatblocksTaken = (blocksTaken: Array<any>, id: any) => {
  let dateBlocks: Array<any> = []
  blocksTaken.map((hourTaken: any) => {
    const date = moment(hourTaken.hour)
    const state = hourTaken.aasm_state
    const blockId = hourTaken.id
    dateBlocks.push({
      time_block: {
        id: blockId ? parseInt(blockId) : '',
        start_time: `${date.format('YYYY-MM-DD HH:mm:ss')}`,
        end_time: `${date.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}`,
        unit_version_id: parseInt(id),
        aasm_state: state ? state : '',
      },
    })
    return null
  })
  return dateBlocks
}

const submitBlocks = (id?: any, history?: any) => {
  return (dispatch: any, getState: any) => {
    const actionData = (action: Function, block: any, url: string) => {
      action(url, block)
        .then((result: any) => {
          dispatch(SubmitCalendarSuc(result))
          history && history.push('/home/available')
        })
        .catch((e: any) => {
          toast(handleError(e))
          return dispatch(SubmitCalendarError(e))
        })
    }

    let formattedblocksTaken = formatblocksTaken(
      getState().visitsCalendar.hoursTaken,
      id,
    )

    formattedblocksTaken.map((block: any) => {
      const values: any = Object.values(block)[0]
      dispatch(SubmitCalendarStart(block))

      if (values.aasm_state.length > 0) {
        actionData(
          putData,
          block,
          `time_blocks/${values.id}/make_unavailable`,
        )
      } else {
        actionData(postData, block, 'time_blocks')
      }
      return null
    })
  }
}
export default submitBlocks
