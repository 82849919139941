import React from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import intl from 'react-intl-universal';
import Hidden from '@material-ui/core/Hidden';
import { DialogModal } from '..';

/**
 * Here it is defined the type of the props for the interface "EnhancedTableHeadTypes"
 * @typedef EnhancedTableHeadTypes
 * @type {(classes|number|array|function)}
 * @property {classes} classes - is a class.
 * @property {number} numSelected - is an number.
 * @property {number} rowCount - is an number.
 * @property {array} listTitle - is an array.
 */

interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    rowCount: number;
    listTitle: Array<any>;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
/**
 *  EnhancedTableHead is a functional component with props.
 * The main function of this component is to render the HeaderTables in general.
 *  @function
 *  @param {EnhancedTableHeadTypes}  classes - It is an array with all the titles of the header
 *  @param {EnhancedTableHeadTypes} numSelected, - It is an array with all id
 *  @param {EnhancedTableHeadTypes} rowCount - It is an array with all rows
 *  @param {EnhancedTableHeadTypes} listTitle - return children with de rows body
 */

const EnhancedTableHead = ({ numSelected, rowCount, listTitle, onSelectAllClick }: EnhancedTableHeadProps) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell className='table-header table-border-left text-secondary' padding='checkbox'>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        color='primary'
                    />
                </TableCell>
                {listTitle.map((title, index) => (
                    <TableCell key={index} className={`table-header ${title.class}`}>
                        {intl.get(title.name)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.text.primary,
                      backgroundColor: lighten(theme.palette.primary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
    }),
);
/**
 * Here it is defined the type of the props for the interface "EnhancedTableToolbarType"
 * @typedef EnhancedTableToolbarTypes
 * @type {(array|number)}
 * @property {number} numSelected - is an number.
 * @property {array} months - is an array.
 */

interface EnhancedTableToolbarProps {
    numSelected: number;
    months?: any;
    years?: any;
}

const EnhancedTableToolbar = ({ numSelected, months, years }: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            {numSelected > 0 ? (
                <Typography className={classes.title} variant='subtitle1' component='div'>
                    {`${intl.get('SELECTED', { num: numSelected })}`}
                </Typography>
            ) : (
                <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
                    {months.map((month: string, index: number, arr: any) =>
                        arr.length > 1 && index === 0
                            ? arr[0] !== arr[1] &&
                              `${intl.get(month)}  ${
                                  years ? (years[1] && years[0] !== years[1] ? years[0] : '') : ''
                              }  ${intl.get('TO').toLowerCase()} `
                            : `${intl.get(month)}   ${
                                  years ? (years[1] && years[0] !== years[1] ? years[1] : years[0]) : ''
                              }`,
                    )}
                </Typography>
            )}
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            paddingTop: 25,
            minWidth: 750,
        },

        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

/**
 * Here the type of each Table is defined, this prop is similar to 'TangoDataProps' but 'TangoDataTypes' is for the documentation
 *  @typedef TangoDataTypes
 *  @type {(string | number)}
 *  @property {array} listTitle - is an Array.
 *  @property {array} listBody - is an Array.
 *  @property {boolean} paginator - is an boolean.
 *  @property {array} months - is an array.
 *  @property {array} years - is an array.
 *  @property {array} selected - is an array.
 *  @property {array} idsPaid - is an array.
 *  @property {function} setSelected - is an function.
 *  @property {function} setUnPay: - is an function.
 */

interface TangoDataProps {
    listTitle: Array<any>;
    listBody: Array<any>;
    paginator?: boolean;
    months?: string[];
    years?: string[];
    selected: string[];
    idsPaid: string[];
    setSelected: (params?: any) => void;
    setUnPay: (params?: any) => void;
}
/**
 * TangoData is a functional component with props.
 * The main function of this component is to render the datatables in general.
 *  @function
 *  @param {TangoDataTypes} listTitle - It is an array with all the titles of the header
 *  @param {TangoDataTypes} listBody - It is an array with all rows
 *  @param {TangoDataTypes} paginator - with or without paging
 *  @param {TangoDataTypes} months - shows selected months
 *  @param {TangoDataTypes} selected - stores selected values
 *  @param {TangoDataTypes} idsPaid - stores the values ​​that are already paid
 *  @param {TangoDataTypes} setSelected - Selected modifier function
 *  @param {TangoDataTypes} setUnPay - Unpay modifier function
 *  @param {TangoDataTypes} years - shows selected years
 *  @returns {(ReactComponent)} Returns a TableMui
 */

const TangoData = ({
    listTitle,
    listBody,
    paginator = true,
    months,
    years,
    idsPaid,
    selected,
    setSelected,
    setUnPay,
}: TangoDataProps) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const hasPaginatorTable = paginator
        ? listBody?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : listBody;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = listBody.reduce((accumulator, currenValue) => {
                if (!idsPaid.includes(currenValue[0])) accumulator.push(currenValue[0]);
                return accumulator;
            }, []);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        if (idsPaid?.indexOf(id) !== -1) {
            setUnPay({
                openModal: true,
                id: id,
            });
            return;
        }

        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => idsPaid?.indexOf(id) !== -1 || selected.indexOf(id) !== -1;

    const emptyRows = listBody.length === 0;

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <EnhancedTableToolbar years={years} months={months} numSelected={selected.length} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        stickyHeader
                        aria-labelledby='tableTitle'
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length + idsPaid.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={listBody.length}
                            listTitle={listTitle}
                        />

                        <TableBody>
                            {hasPaginatorTable?.map((row, index) => {
                                const isItemSelected = isSelected(row[0]);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row[0])}
                                        role='checkbox'
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row[0]}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding='checkbox'>
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>

                                        {Object.values(row).map((value: any, index: number) => (
                                            <>
                                                {index === 0 ? null : index === 1 ? (
                                                    <TableCell component={'th' as any} id={labelId} scope='row'>
                                                        {value}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align='left'>{value}</TableCell>
                                                )}
                                            </>
                                        ))}
                                    </TableRow>
                                );
                            })}
                            {emptyRows && (
                                <TableRow>
                                    <TableCell colSpan={listTitle.length + 1} className='table-content text-center'>
                                        {intl.get('NO_DATA')}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!!listBody?.length && paginator && (
                    <>
                        <Hidden smUp>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component='div'
                                count={listBody.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Hidden>
                        <Hidden smDown>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                component='div'
                                count={listBody.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonText={intl.get('PREVIOUS_PAGE')}
                                labelRowsPerPage={intl.get('ROWS_PER_PAGE')}
                                nextIconButtonText={intl.get('NEXT_PAGE')}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Hidden>
                    </>
                )}
            </Paper>
        </div>
    );
};

export default TangoData;
