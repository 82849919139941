import React, { Suspense } from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { AccordionItem, SwitchString, CheckBoxTango, RadioButtonTango, InputRange, CircularLoading } from '..';
import {
    BOOLEAN_OPTIONS,
    JANITOR_OPTIONS,
    PARKING_LOT_OPTIONS,
    HOME_APPLIANCES_OPTIONS,
    FLOOR_APARTMENT_COUNT_OPTIONS,
    UNIT_TYPE_OPTIONS,
    COMUNITY_TYPE_OPTIONS,
    BEDROOMS_COUNT_OPTIONS,
    BATHROOM_COUNT_OPTIONS,
    ORIENTATION_OPTIONS,
    KITCHEN_OPTIONS,
    THERMALPANELS_OPTIONS,
    COMMON_SPACES_OPTIONS,
    HEATING_OPTIONS,
} from '../../../tango-react-base/helpers/selectOptions/options';

import intl from 'react-intl-universal';
import { FilterAdvancedForm } from './FilterAdvanced.type';
import { useWindowSize } from '../../helpers/hookWindowSize/index';
const FilterAdvanedMobile = React.lazy(() => import('./filterAdvancedMobile'));

interface FilterAdvancedFormProps {
    onSubmit: any;
    idUser?: string;
    children?: any;
    disabled?: boolean;
    onClickSelectFilter: (value: string) => void;
    classFilterContainer?: string;
    showFilterMobile?: any;
    setFilterActiveMobile?: any;
}

const InnerForm: React.FunctionComponent<FilterAdvancedFormProps & FormikProps<FilterAdvancedForm>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    children,
    disabled = false,
    onClickSelectFilter,
    classFilterContainer,
    showFilterMobile,
    onSubmit,
    idUser,
    setFilterActiveMobile,
}) => {
    const BATHROOM_COUNT_OPTIONS_STRING = BATHROOM_COUNT_OPTIONS.map((res) => {
        return {
            label: res.label,
            value: res.value.toString(),
        };
    });

    const BEDROOMS_COUNT_OPTIONS_STRING = BEDROOMS_COUNT_OPTIONS.map((res) => {
        return {
            label: res.label,
            value: res.value.toString(),
        };
    });

    const size = useWindowSize();
    return (
        <>
            {size.width < 577 && showFilterMobile ? (
                <Suspense fallback={<CircularLoading />}>
                    <FilterAdvanedMobile
                        onSubmit={onSubmit}
                        onClickSelectFilter={onClickSelectFilter}
                        children={children}
                        idUser={idUser}
                        disabled={disabled}
                        classFilterContainer={classFilterContainer}
                        setFilterActiveMobile={setFilterActiveMobile}
                    />
                </Suspense>
            ) : (
                <Form className='unit-tango-form mt-sm-2'>
                    {children}
                    <div className={classFilterContainer}>
                        <div
                            className='col-12 p-0 mt-2 text-left border-bottom'
                            onClick={() => onClickSelectFilter('current_price')}
                        >
                            <InputRange
                                id='current_price'
                                value={values.current_price}
                                unit='$'
                                onChange={handleChange}
                                disabled={disabled}
                                className='col-12 d-flex justify-content-center pt-0'
                                label='PRICES'
                                classNameLabel='col-12 p-0'
                                currencyOption
                                currencyOptionBtn
                            />
                        </div>
                        <div
                            className='col-12 p-0 mt-2 text-left border-bottom'
                            onClick={() => onClickSelectFilter('includes_common_expenses')}
                        >
                            <InputRange
                                id='includes_common_expenses'
                                value={values.includes_common_expenses}
                                unit='$'
                                onChange={handleChange}
                                disabled={disabled}
                                className='col-12 d-flex justify-content-center pt-0 '
                                label='COMMON_EXPENSES_FILTER'
                                classNameLabel={'col-12 p-0'}
                                currencyOption
                            />
                        </div>
                        <div
                            className='col-12 p-0 mt-2 text-left border-bottom'
                            onClick={() => onClickSelectFilter('bedroom_count')}
                        >
                            <p className='col-12 span-text pl-3'>{intl.getHTML(`BEDROOMS_NUMBER`)}</p>
                            <CheckBoxTango
                                classNameContainer='col-12 d-flex justify-content-center pt-0 mt-2'
                                maxWidth={true}
                                options={BEDROOMS_COUNT_OPTIONS_STRING}
                                name='bedroom_count'
                                disabled={disabled}
                                cbxType='bedrooms'
                            />
                        </div>
                        <div
                            className='col-12 p-0 mt-2 text-left border-bottom'
                            onClick={() => onClickSelectFilter('bathroom_count')}
                        >
                            <p className='col-12 span-text pl-3'>{intl.getHTML(`BATHROOMS`)}</p>
                            <CheckBoxTango
                                classNameContainer='col-12 d-flex justify-content-center pt-0 mt-2'
                                maxWidth={true}
                                options={BATHROOM_COUNT_OPTIONS_STRING}
                                name='bathroom_count'
                                disabled={disabled}
                                cbxType='bathrooms'
                            />
                        </div>
                        <div
                            className='col-12 p-0 mt-2 text-left border-bottom'
                            onClick={() => onClickSelectFilter('inner_area')}
                        >
                            <InputRange
                                id='inner_area'
                                value={values.inner_area}
                                unit='m'
                                onChange={handleChange}
                                disabled={disabled}
                                className='col-12 d-flex justify-content-center pt-0'
                                label='LABEL_INNER_AREA'
                                classNameLabel='col-12 p-0'
                            />
                        </div>
                        <div className='col-12 p-0 mt-2 text-left' onClick={() => onClickSelectFilter('total_area')}>
                            <InputRange
                                id='total_area'
                                value={values.total_area}
                                unit='m'
                                onChange={handleChange}
                                disabled={disabled}
                                className='col-12 d-flex justify-content-center pt-0'
                                label='LABEL_TOTAL_AREA'
                                classNameLabel='col-12 p-0'
                            />
                        </div>
                        <AccordionItem title={intl.get('TERMS')}>
                            <div className='col-12 p-0'>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('animals')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='animals'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.animals}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label='ACCEPT_ANIMALS'
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left'
                                    onClick={() => onClickSelectFilter('available_now')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='available_now'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.available_now}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label='AVAILABLE_NOW'
                                        classNameInput='pl-4'
                                    />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={intl.get('BASIC_DATA')}>
                            <div className='col-12 p-0'>
                                <div className='col-12 p-0' onClick={() => onClickSelectFilter('unit_type')}>
                                    <p className='col-12 span-text'>{intl.getHTML(`UNIT_TYPE`)}</p>
                                    <RadioButtonTango
                                        disabled={disabled}
                                        id='unit_type'
                                        onChange={handleChange}
                                        options={UNIT_TYPE_OPTIONS}
                                        valueChecked={values.unit_type}
                                        className='d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('community_type')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`COMUNITY_TYPE`)}</p>
                                    <RadioButtonTango
                                        disabled={disabled}
                                        id='community_type'
                                        onChange={handleChange}
                                        options={COMUNITY_TYPE_OPTIONS}
                                        valueChecked={values.community_type}
                                        className='d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('terrace_area')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`LABEL_TERRACE_AREA`)}</p>

                                    <InputRange
                                        id='terrace_area'
                                        value={values.terrace_area}
                                        unit='m'
                                        onChange={handleChange}
                                        disabled={disabled}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        classNameLabel={'col-12 p-0'}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left'
                                    onClick={() => onClickSelectFilter('services')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        disabled={disabled}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        name='services'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.services}
                                        label='SERVICES'
                                        classNameInput='pl-4'
                                    />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={intl.get('UNIT_DETAIL')}>
                            <div className='col-12 p-0'>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('story')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`FLOOR_COUNT_APARTMENT`)}</p>
                                    <RadioButtonTango
                                        id='story'
                                        options={FLOOR_APARTMENT_COUNT_OPTIONS}
                                        valueChecked={Number(values.story)}
                                        onChange={handleChange}
                                        disabled={disabled}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                        range={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('penthouse')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='penthouse'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.penthouse}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'PENTHOUSE'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('orientation')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`ORIENTATION`)}</p>
                                    <CheckBoxTango
                                        classNameContainer='d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                        options={ORIENTATION_OPTIONS}
                                        name='orientation'
                                        disabled={disabled}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('terrace')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='terrace'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.terrace}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'TERRACE'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('private_jacuzzi')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row col-12 d-flex justify-content-start p-0'
                                        name='private_jacuzzi'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.private_jacuzzi}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'PRIVATE_JACUZZI'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('kitchen')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`KITCHEN_OPTION`)}</p>
                                    <RadioButtonTango
                                        id='kitchen'
                                        options={KITCHEN_OPTIONS}
                                        valueChecked={values.kitchen}
                                        disabled={disabled}
                                        onChange={handleChange}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('laundry')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='laundry'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.laundry_room}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'LAUNDRY'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('private_courtyard')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='private_courtyard'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.private_courtyard}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'PRIVATE_COURTYARD'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left'
                                    onClick={() => onClickSelectFilter('private_pool')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='private_pool'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.private_pool}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'PRIVATE_POOL'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={intl.get('EQUIPMENT')}>
                            <div className='col-12 p-0'>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('parking_spaces')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`PARKING_LOT`)}</p>
                                    <RadioButtonTango
                                        id='parking_spaces'
                                        options={PARKING_LOT_OPTIONS}
                                        valueChecked={Number(values.parking_spaces)}
                                        onChange={handleChange}
                                        disabled={disabled}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                        range={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('storage_room')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='storage_room'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.storage_room}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'STORAGE'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('home_appliances')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`HOME_APPLIANCES`)}</p>
                                    <CheckBoxTango
                                        classNameContainer='d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                        options={HOME_APPLIANCES_OPTIONS}
                                        name='home_appliances'
                                        disabled={disabled}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('heating_system')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`HEATING`)}</p>
                                    <RadioButtonTango
                                        options={HEATING_OPTIONS}
                                        id='heating_system'
                                        onChange={handleChange}
                                        valueChecked={values.heating_system}
                                        disabled={disabled}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('insulated_glazing')}
                                >
                                    <p className='col-12 span-text'>{intl.getHTML(`THERMALPANEL`)}</p>
                                    <RadioButtonTango
                                        options={THERMALPANELS_OPTIONS}
                                        id='insulated_glazing'
                                        onChange={handleChange}
                                        valueChecked={values.insulated_glazing}
                                        disabled={disabled}
                                        className='col-12 d-flex justify-content-center pt-0'
                                        maxWidth={true}
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left border-bottom'
                                    onClick={() => onClickSelectFilter('curtains')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='curtains'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.curtains}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'CURTAINS'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                                <div
                                    className='col-12 p-0 mt-2 text-left'
                                    onClick={() => onClickSelectFilter('furnished')}
                                >
                                    <SwitchString
                                        position='center'
                                        classNameContainer='row d-flex justify-content-start p-0'
                                        name='furnished'
                                        options={BOOLEAN_OPTIONS}
                                        value={values.furnished}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={disabled}
                                        label={'FURNISHED'}
                                        classNameInput='pl-4'
                                    />
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={intl.get('COMMON_SPACES')}>
                            <div className='col-12 p-0'>
                                <div className='col-12'>
                                    <div
                                        className='col-12 p-0 mt-2 text-left border-bottom'
                                        onClick={() => onClickSelectFilter('common_spaces')}
                                    >
                                        <p className='col-12 span-text'>{intl.getHTML(`COMMON_SPACES`)}</p>
                                        <CheckBoxTango
                                            classNameContainer='d-flex justify-content-center pt-0'
                                            maxWidth={true}
                                            options={COMMON_SPACES_OPTIONS}
                                            name='common_spaces'
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div
                                        className='col-12 p-0 mt-2 text-left border-bottom'
                                        onClick={() => onClickSelectFilter('janitor')}
                                    >
                                        <p className='col-12 span-text'>{intl.getHTML(`JANITOR`)}</p>
                                        <RadioButtonTango
                                            options={JANITOR_OPTIONS}
                                            id='janitor'
                                            onChange={handleChange}
                                            valueChecked={values.janitor}
                                            disabled={disabled}
                                            className='col-12 d-flex justify-content-center pt-0'
                                            maxWidth={true}
                                        />
                                    </div>
                                    <div
                                        className='col-12 p-0 mt-2 text-left'
                                        onClick={() => onClickSelectFilter('elevator')}
                                    >
                                        <p className='col-12 span-text'>{intl.getHTML('ELEVATOR')}</p>
                                        <SwitchString
                                            position='center'
                                            classNameContainer='row col-12 d-flex justify-content-start p-0 pl-3'
                                            name='elevator'
                                            options={BOOLEAN_OPTIONS}
                                            value={values.elevator}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            disabled={disabled}
                                            classNameInput='pl-4'
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                    </div>
                </Form>
            )}
        </>
    );
};

const PutTokenForms = withFormik<FilterAdvancedFormProps, FilterAdvancedForm>({
    mapPropsToValues: (props: any) => {
        const initialValues = {};
        return { ...initialValues, ...props.params };
    },

    validationSchema: Yup.object().shape({}),

    handleSubmit(values: FilterAdvancedForm, { props, setSubmitting, setErrors }) {
        props.onSubmit(values);
    },
})(InnerForm);

export default PutTokenForms;
