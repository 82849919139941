import { NavBarState } from '../../../tango-react-base/tangoBase'

const initialState: NavBarState = {
  anchorEl: null,
  anchorElPages: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CHANGE_MENU':
      return {
        ...state,
        anchorEl: action.event,
      }
    case 'CHANGE_MENU_NAVEGATE':
      return {
        ...state,
        anchorElPages: action.event,
      }
    default:
      return state
  }
}
