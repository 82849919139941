import { putData } from '../../../tango-react-base/helpers/apiConnections'
import {
  validateUnitStart,
  validateUnitSuccess,
  validateUnitError,
} from './types'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const verifyPost = (values: any, history?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(validateUnitStart())
    const postId = getState().postValidation.post.id
    let queryObject: any = { unit: {}, post: {} }

    delete values.onSubmit
    delete values.children
    delete values.unitId
    delete values.onSaveData
    delete values.disabled
    delete values.savingPost
    delete values.photos
    delete values.unit_documents
    delete values.verifier_id
    delete values.photos_id
    delete values.user_visits
    delete values.user_rents
    delete values.updated_at
    delete values.state_signs_contract
    delete values.semantic_url
    delete values.available_blocks
    delete values.aasm_state
    delete values.videos
    delete values.unit_id
    delete values.id

    const optionalArrayValues = [
      'home_appliances',
      'basic_services_included',
      'common_spaces',
      'orientation',
    ]

    for (const key in values) {
      if (Array.isArray(values[key]) && values[key].length !== 0) {
        values[key] = values[key].reduce(
          (total: string, actual: string) => total + `,${actual}`,
        )
      }
      if (key === 'title' || key === 'description') {
        queryObject['post'][`${key}`] = values[key]
      } else if (key === 'estimate_price_amount') {
        queryObject['post']['price_amount'] = values[key]
        queryObject['unit'][`${key}`] = values[key]
      } else if (key === 'estimate_price_currency') {
        queryObject['post']['price_currency'] = values[key]
        queryObject['unit'][`${key}`] = values[key]
      } else if (
        optionalArrayValues.includes(key) &&
        values[key]?.length === 0
      ) {
        queryObject['unit'][`${key}`] = ''
      } else if (values[key] || values[key] === 0){
          queryObject['unit'][`${key}`] = values[key]
      }
    }
    putData(`posts/${postId}/verify`, queryObject)
      .then((result) => {
        toast(intl.get('UNIT_VERIFIED'))
        dispatch(validateUnitSuccess(result.data))
        history.push('/', { scrollToId: 'pending-list' })
      })
      .catch((e: any) => {
        const errors = { ...e }
        const { response } = errors || ''
        const { status } = response || ''

        if (status === 409) {
          toast(intl.get('PROPERTY_NO_VERIFIED'))
        } else {
          toast(handleError(e))
        }
        return dispatch(validateUnitError(e))
      })
  }
}
export default verifyPost
