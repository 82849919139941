export const finishProccessStart = (id: string) => {
    return {
        type: 'VISIT_FINISHED_STARTED',
        payload: { id },
    };
};

export const finishProccessSuc = (data: any) => {
    return {
        type: 'VISIT_FINISHED_SUCCESS',
        payload: { data },
    };
};

export const finishProccessError = (id: string, error: any) => {
    return {
        type: 'VISIT_FINISHED_ERROR',
        payload: {
            id,
            error,
        },
    };
};
