export const deleteVideoStart = (id: any) => {
  return {
    type: 'DELETE_VIDEO_STARTED',
    payload: { id }
  }
}

export const deleteVideoSuccess = (video:any, id: any) => {
  return {
    type: 'DELETE_VIDEO_SUCCESS',
    payload: { video, id },
  }
}

export const deleteVideoError = (error: any) => {
  return {
    type: 'DELETE_VIDEO_ERROR',
    payload: { error },
  }
}
