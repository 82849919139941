import React, { FC, useEffect } from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import InputMachine from '../inputMachine/index';
import intl from 'react-intl-universal';
import ValidationForm from './validationForm';
import { ButtonLoading } from '..';
import { connect } from 'react-redux';

/** Here it is defined the type of the props for the interface "ShortRegisterProps"
 * @property {string | null} userEmail - is a string that contains the email of the user
 * @property {Function} onSubmit - is a function that is called when the form is submitted
 * @property {string | undefined} fetchUser - is a string that contains the state of fetch user
 * @property {any} imgAvatar - is a img that contains the avatar
 * @property {string | null} token - is a string that contains the token of the user
 * @typedef ShortRegisterProps
 * @type {(function|null|string)}
 */

interface ShortRegisterProps {
    userEmail: string | null | undefined;
    onSubmit: Function;
    fetchUser?: string;
    imgAvatar: any;
    token: string | null;
}

/** Here it is defined the type of the DataShortRegister interface
 * @property {string} email - is a string that contains the email of the user
 * @property {string} password - is a string that contains the password of the user
 * @property {string} repeat_password - is a string that contains the repeat password of the user
 * @property {boolean} form_sended - is a boolean that contains the state of the form
 * @property {string} selected_role - is a string that contains the role of the user
 * @typedef DataShortRegister
 * @type {(function|boolean|string)}
 */

interface DataShortRegister {
    email: string;
    password: string;
    repeat_password: string;
    form_sended?: boolean;
    selected_role?: string;
}

/** Function that returns the short register form
 * This form contains the email, password and repeat password. It also contains a button to submit the form
 * The input email is disable if exist a email.
 * @param {FormikProps<DataShortRegister>} email - is a string that contains the email of the user
 * @param {FormikProps<DataShortRegister>} password - is a string that contains the password of the user
 * @param {FormikProps<DataShortRegister>} repeat_password - is a string that contains the repeat password of the user
 * @param {FormikProps<DataShortRegister>} form_sended - is a boolean that contains the state of the form
 * @param {FormikProps<DataShortRegister>} selected_role - is a string that contains the role of the user
 * @returns {JSX.Element} - return a JSX Component that contains the short register form with formik.
 */

const InnerForm: FC<ShortRegisterProps & FormikProps<DataShortRegister>> = ({
    imgAvatar,
    token = null,
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    fetchUser,
}) => {
    const defaultConfig = {
        className: 'input-container-class p-0',
        classNameInput: 'input-class p-0',
        classNameLabel: 'label-class mb-2',
        colorTertiary: true,
    };

    let { email, password, repeat_password }: DataShortRegister = values;
    const Inputs = [
        {
            id: 'email',
            label: 'EMAIL',
            messageAdditionalValidation: errors.email,
            disabled: email,
            value: email,
            placeholder: intl.get('EMAIL_SAMPLE'),
            ...defaultConfig,
        },
        {
            id: 'password',
            label: 'PASSWORD',
            messageAdditionalValidation: errors.repeat_password,
            value: password,
            placeholder: intl.get('MIN_CHARS_PASSWORD'),
            type: 'password',
            ...defaultConfig,
        },
        {
            id: 'repeat_password',
            label: 'CONFIRM_PASSWORD',
            messageAdditionalValidation: errors.repeat_password,
            value: repeat_password,
            placeholder: intl.get('MIN_CHARS_PASSWORD'),
            type: 'password',
            ...defaultConfig,
        },
    ];

    useEffect(() => {
        if (values.form_sended && !!email && !!password && !!repeat_password) {
            values.form_sended = false;
        }
    }, [values]);

    const isFetching = fetchUser === 'FETCHING';

    return (
        <div className='step-visit '>
            <div className='step-visit__header pt-4'>
                <img src={imgAvatar} alt='Happy man Tango' />
                <div>
                    <p>{intl.get('WE_NEED_THIS_DATA')}</p>
                </div>
            </div>
            <Form className='step-visit__form form-short-register'>
                <InputMachine
                    elements={Inputs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                />
                <div className='MuiFormControl-root MuiTextField-root mt-3'>
                    <button
                        className='button-secondary font-weight-bold'
                        onClick={() => (values.form_sended = true)}
                        disabled={isFetching}
                    >
                        {isFetching ? <ButtonLoading /> : intl.get('SIGN_UP').toLocaleUpperCase()}
                    </button>
                </div>
            </Form>
        </div>
    );
};

/**
 * ShortRegister is a functional component
 * This component is responsible for the short registration of the user. Show a card with the form to register the user
 * The form contains the email, the password and the repeat password inputs. The form is submitted when the button is clicked
 * @function
 * @param {ShortRegisterProps} userEmail - is a string that contains the email of the user
 * @param {ShortRegisterProps} onSubmit - is a function that is called when the form is submitted
 * @param {ShortRegisterProps} fetchUser - is a string that contains the state of fetch user
 * @param {ShortRegisterProps} imgAvatar - is a img that contains the avatar
 * @param {ShortRegisterProps} token - is a string that contains the token of the user
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const ShortRegister = withFormik<ShortRegisterProps, DataShortRegister>({
    mapPropsToValues: (props: ShortRegisterProps) => {
        const { userEmail: email } = props;

        return {
            ...props,
            email: email || '',
            password: '',
            repeat_password: '',
            form_sended: false,
        };
    },

    validationSchema: ValidationForm,

    handleSubmit({ email, password, repeat_password }: DataShortRegister, { props }) {
        const valuesToForm = {
            email,
            password,
            repeat_password,
        };
        props.onSubmit(valuesToForm);
    },
})(InnerForm);

const mapStateToProps = (state: any) => {
    const { fetchUser } = state.user;
    return {
        fetchUser,
    };
};

export default connect(mapStateToProps, null)(ShortRegister);
