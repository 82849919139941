import React from 'react';
import './style.scss';

interface CircleProps {
    lat: number;
    lng: number;
}

const Circle = ({ lat, lng }: CircleProps) => {
    return <div className='circle-container'></div>;
};

export default Circle;
