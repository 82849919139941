import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularLoading = () => {
    return (
        <div>
            <CircularProgress color='primary' />
        </div>
    );
};
export default CircularLoading;
