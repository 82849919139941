export const getNewReferenceStart = () => {
    return {
        type: 'GET_NEW_REFERENCE_STARTED',
    };
};

export const getNewReferenceSuccess = (result: any) => {
    return {
        type: 'GET_NEW_REFERENCE_SUCCESS',
        payload: { result },
    };
};
export const getNewReferenceError = (error: any) => {
    return {
        type: 'GET_NEW_REFERENCE_ERROR',
        payload: {
            error,
        },
    };
};
