import { sendForm, sendFormError, sendFormSuccess } from './types';
import { toast } from 'react-toastify';
import { getDataContact } from '../../helpers/formWork';
import { handleError } from '../../helpers/handleErrors';
const sendFormFooter = (data: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(sendForm());
        const url = 'mails/send_contact_mail';
        let valueWorkPermit = data.workPermit === 'yes' ? true : false;
        let formData: any = new FormData();
        formData.append('requester[email]', data.email);
        formData.append('requester[name]', data.name);
        formData.append('requester[phone_number]', data.phone_number);
        formData.append('requester[work_permit]', valueWorkPermit);
        formData.append('requester[resume]', data.resume);
        getDataContact(url, formData)
            .then((result: any) => {
                dispatch(sendFormSuccess(data.email));
            })
            .catch((e: any) => {
                toast(handleError(e));
                return dispatch(sendFormError(alert));
            });
    };
};
export default sendFormFooter;
