import React, { useState } from 'react';
import '../../scss/main.scss';
import intl from 'react-intl-universal';
import { Field } from 'formik';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

var moment = require('moment');
interface InputProps {
    label?: string;
    placeholder_intl?: string;
    bottomLabel?: string;
    id: string;
    placeholder?: string;
    onChange: any;
    value?: any;
    name?: string;
    error?: string;
    touched?: any;
    onBlur?: any;
    className?: string;
    requiredMessage?: string;
    multiline?: boolean;
    disabled: boolean;
    rows?: number;
    disabledHoursArray?: Array<number>;
    disabledMinutesArray?: Array<number>;
    minuteStep?: number;
}

const Input = ({
    placeholder,
    touched,
    bottomLabel,
    placeholder_intl,
    label,
    className,
    error,
    requiredMessage,
    value,
    disabledHoursArray = [],
    disabledMinutesArray = [],
    minuteStep,
    ...inputProps
}: InputProps) => {
    const [open, setOpen] = useState(false);
    let time = moment().utcOffset(0);
    time.set({ hour: value.split(':')[0], minute: value.split(':')[1] });
    time.toISOString();
    time.format();

    const handleTimeChange = (field: any, form: any, value: string | null) => {
        field.value[inputProps.id] = value;
        form.setFieldValue(value);
    };

    return (
        <Field>
            {({ field, form }: any) => {
                return (
                    <div className={`row input-container input-time${className}`}>
                        <span className='col-12'>{intl.getHTML(`${label}`)}</span>
                        <TimePicker
                            open={open}
                            onOpen={() => setOpen(!open)}
                            onClose={() => setOpen(!open)}
                            focusOnOpen
                            id={`bootstrap-input-${inputProps.id}-hours`}
                            className={`col-12 ${error && touched ? 'input-error' : ''}`}
                            value={time}
                            onChange={(evt) => handleTimeChange(field, form, evt.format('HH:mm'))}
                            showSecond={false}
                            disabled={inputProps.disabled}
                            allowEmpty={false}
                            disabledHours={() => disabledHoursArray}
                            disabledMinutes={() => disabledMinutesArray}
                            minuteStep={minuteStep}
                            hideDisabledOptions
                            addon={() => (
                                <div className='full-width text-center'>
                                    <button className={`btn btn-second my-2`} onClick={() => setOpen(false)}>
                                        {intl.get('CLOSE')}
                                    </button>
                                </div>
                            )}
                        />
                    </div>
                );
            }}
        </Field>
    );
};

export default Input;
